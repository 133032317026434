import React from 'react';

export const CommentSectionIATextInfo = () => {
    return (
        <div><br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        <h5><strong>Utilização - Comentários da I.A.</strong></h5>
            <p>Utilizando o componente <strong>Comentários da I.A</strong>, você pode proporcionar um espaço para discussões e comentários relevantes entre os usuários autorizados para cada versão da sua I.A. generativa de texto.</p>
           
            <hr/>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Compreensão Profunda - Seção de Comentários para I.A. Texto</strong></h5>
            <p>Este documento tem o objetivo de esclarecer o funcionamento da seção de comentários para a I.A. generativa de texto. O componente <strong>Comentários da I.A</strong> permite aos usuários comentar sobre uma versão específica da I.A., controlando o acesso aos comentários por meio de chaves de acesso.</p>
            <hr/>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Comentários Controlados por Chaves de Acesso</strong></h5>
            <p>A seção de comentários é projetada para permitir comentários apenas de usuários autorizados. Isso é alcançado por meio do uso de chaves de acesso exclusivas. Somente usuários com a chave de acesso correspondente à versão da I.A. podem postar comentários.</p>
            <hr/>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Funcionamento</strong></h5>
            <p>O componente <strong>Comentários da I.A</strong> exibe uma lista de comentários feitos por usuários autorizados para a versão específica da I.A. A seção de entrada de texto permite que os usuários autenticados postem novos comentários pressionando "Enter" ou clicando no botão de envio.</p>
            <hr/>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Atualização Automática</strong></h5>
            <p>Os comentários são atualizados automaticamente a cada 3 segundos para manter a seção de comentários em tempo real. Isso permite que os usuários vejam novos comentários assim que eles forem postados.</p>
            <hr/>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5><strong>Responsabilidade e Segurança</strong></h5>
            <p>Lembre-se de que a seção de comentários contém interações entre usuários e suas opiniões. É importante manter um ambiente respeitoso e garantir que apenas usuários autorizados possam participar do diálogo.</p>
           <hr/>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> </div>
    );
};

export default CommentSectionIATextInfo;
