import React from 'react';

const OperationSelect = ({ value, onChange }) => {
  return (
    <select name="operacao_coleta" value={value} onChange={(e) => onChange({ value: e.target.value }, 'operacao_coleta')}>
      <option value="operacaozero"></option>
      <option value="Contagem">Contagem</option>
      <option value="Cumulativo">Cumulativo</option>
    </select>
  );
};

export default OperationSelect;