import { Index } from "./Index";
import { Nav, NavUserMain } from "../../../../components";
export const UserServices = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3" style={{ overflow: 'hidden' }}>
            <NavUserMain />
            <section className="w-100">
                <Index />
            </section>
        </div>
    </>)
}