import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
// Para importar páginas use a mesma sequência de nome das pastas para facilitar à correção
import {
  Artigos,
  RenderArtigos,
  Cadastrar,
  Carrinho,
  Home,
  Login,
  Parcerias,
  Produtos,
  ProductsDetailsPage,
  Search,
  Servicos,
  SobreNos,
  ServicesDetailsPage,
} from "../pages/Public/index.js"
import {
  //Private User
  Atestados,
  Cadastro,
  CadastroPaciente,
  CadastroProfissional,
  CarrinhoPaciente,
  Consultas,
  MarcarConsultas,
  ConsultasMarcadas,
  ConsultasFinalizadas,
  EquipamentosPacientes,
  Exames,
  MarcarExames,
  ExamesMarcados,
  ExamesFinalizados,
  Pacientes, //Home Usuário
  Medicamentos,
  Notificacoes,
  Preferencias,
  ProntuarioPaciente,
  UniversoImersivo,
  Social,
  //Private Profissionais
  AgendaProfissional,
  AtestadosProfissionais,
  AtestadosProfissionaisNovo,
  AtestadosProfissionaisArmazenado,
  AtestadosProfissionaisAtivo,
  EmitirAtestadoPage,
  DashBoardFinanceiro,
  Ecommerce,
  Equipamentos,
  Profissionais,//Home Profissionais
  IA,
  ProspeccaoClientes,
  ImagemIA,
  TextIA,
  MedicacaoProfissional,
  NotificacoesProfissionais,
  ServicosProfissionais,
  Wiki,
  Sprints,
  Configuracoes,
  Projetos,
  ProdutosProfissionais,
  WikiInfo,
  ProdutosCadastrar,
  ProdutosVendidos,
  ProdutosEstoque,
  ProfissionaisServicosAprovados,
  ProfissionaisServicosOrcamento,
  ProfissionaisCadastrarServicos,
  ProfissionaisEditarServicos,
  UserProdutos,
  UserServices,
  VendasProfissionais,
} from "../pages/Private/index.js"
import { AuthProvider } from "../contexts/auth";
import { UserMiddleware } from "../contexts/UserMiddleware.jsx";
import { ProfessionalMiddleware } from "../contexts/ProfessionalMiddleware.jsx";
import NotFound from "./components/NotFound.jsx";
// import Adsense from './components/Adsense'; // Importe o componente Adsense
import CustomHelmet from "./components/CustomHelmet.jsx";
import AnalyticsComponent from "./components/AnalyticsComponent.jsx";
import UrlChecker from "./components/UrlChecker.jsx";
import DuplicateKeysMonitor from "./testes/DuplicateKeysMonitor.jsx";

const AppRoutes = () => {
  return (<>
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <AnalyticsComponent />
          { /*   <Adsense />*/}
          <CustomHelmet />
          <UrlChecker />
          <Routes>
            {/* PÚBLICAS colocar na sequencia de nome das pastas facilitar correção*/}
            <Route exact path="/artigos" element={<Artigos />} />
            <Route exact path="/artigos/:id" element={<RenderArtigos />} />
            <Route exact path="/cadastrar" element={<Cadastrar />} />
            <Route exact path="/carrinho" element={<Carrinho />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/parcerias" element={<Parcerias />} />
            <Route exact path="/produtos" element={<Produtos />} />
            <Route path="/produtos/:products_id" element={<ProductsDetailsPage />} />
            <Route exact path="/servicos" element={<Servicos />} />
            <Route path="/servicos/:services_id" element={<ServicesDetailsPage />} />
            <Route exact path="/sobre" element={<SobreNos />} />
            <Route exact path="/search" element={<Search />} />
            {/* PRIVADAS Usuários colocar na sequencia de nome das pastas facilitar correção*/}
            <Route exact path="/usuario/atestados" element={<UserMiddleware><Atestados /></UserMiddleware>} />
            <Route exact path="/usuario/cadastro" element={<UserMiddleware><Cadastro /></UserMiddleware>} />
            <Route exact path="/usuario/cadastro/usuario" element={<UserMiddleware><CadastroPaciente /></UserMiddleware>} />
            <Route exact path="/usuario/cadastro/profissional" element={<UserMiddleware><CadastroProfissional /></UserMiddleware>} />
            <Route exact path="/usuario/carrinho" element={<UserMiddleware><CarrinhoPaciente /></UserMiddleware>} />
            <Route exact path="/usuario/consultas" element={<UserMiddleware><Consultas /></UserMiddleware>} />
            <Route exact path="/usuario/consultas/marcar" element={<UserMiddleware><MarcarConsultas /></UserMiddleware>} />
            <Route exact path="/usuario/consultas/marcadas" element={<UserMiddleware><ConsultasMarcadas /></UserMiddleware>} />
            <Route exact path="/usuario/consultas/finalizadas" element={<UserMiddleware><ConsultasFinalizadas /></UserMiddleware>} />
            <Route exact path="/usuario/equipamentos" element={<UserMiddleware><EquipamentosPacientes /></UserMiddleware>} />
            <Route exact path="/usuario/exames" element={<UserMiddleware><Exames /></UserMiddleware>} />
            <Route exact path="/usuario/exames/marcar" element={<UserMiddleware><MarcarExames /></UserMiddleware>} />
            <Route exact path="/usuario/exames/marcados" element={<UserMiddleware><ExamesMarcados /></UserMiddleware>} />
            <Route exact path="/usuario/exames/finalizados" element={<UserMiddleware><ExamesFinalizados /></UserMiddleware>} />
            <Route exact path="/usuario" element={<UserMiddleware><Pacientes /></UserMiddleware>} />
            <Route exact path="/usuario/medicamentos" element={<UserMiddleware><Medicamentos /></UserMiddleware>} />
            <Route exact path="/usuario/notificacoes" element={<UserMiddleware><Notificacoes /></UserMiddleware>} />
            <Route exact path="/usuario/produtos" element={<UserMiddleware><UserProdutos /></UserMiddleware>} />
            <Route exact path="/usuario/preferencias" element={<UserMiddleware><Preferencias /></UserMiddleware>} />
            <Route exact path="/usuario/prontuario" element={<UserMiddleware><ProntuarioPaciente /></UserMiddleware>} />
            <Route exact path="/usuario/produtos/:products_id" element={<UserMiddleware><ProductsDetailsPage /></UserMiddleware>} />
            <Route exact path="/usuario/servicos" element={<UserMiddleware><UserServices /></UserMiddleware>} />
            <Route exact path="/usuario/servicos/:services_id" element={<UserMiddleware><ServicesDetailsPage /></UserMiddleware>} />
            <Route exact path="/usuario/social" element={<UserMiddleware><Social /></UserMiddleware>} />
            <Route exact path="/usuario/universoImersivo" element={<UserMiddleware><UniversoImersivo /></UserMiddleware>} />
            {/* PRIVADAS Profissionais colocar na sequencia de nome das pastas facilitar correção*/}
            <Route exact path="/profissionais" element={<ProfessionalMiddleware><Profissionais /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/consultas" element={<ProfessionalMiddleware><Consultas /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/graficos" element={<ProfessionalMiddleware><DashBoardFinanceiro /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/ecommerce" element={<ProfessionalMiddleware><Ecommerce /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/IA" element={<ProfessionalMiddleware><IA /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/IA/text" element={<ProfessionalMiddleware><TextIA /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/IA/imagem" element={<ProfessionalMiddleware><ImagemIA /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/prospeccaoIA" element={<ProfessionalMiddleware><ProspeccaoClientes /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/servicos" element={<ProfessionalMiddleware><ServicosProfissionais /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/servicos/orcamento" element={<ProfessionalMiddleware><ProfissionaisServicosOrcamento /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/servicos/aprovados" element={<ProfessionalMiddleware><ProfissionaisServicosAprovados /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/servicos/finalizados" element={<ProfessionalMiddleware><ServicosProfissionais /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/servicos/cadastrar" element={<ProfessionalMiddleware><ProfissionaisCadastrarServicos /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/servicos/editar" element={<ProfessionalMiddleware><ProfissionaisEditarServicos/></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/vendas" element={<ProfessionalMiddleware><VendasProfissionais /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/produtos" element={<ProfessionalMiddleware><ProdutosProfissionais /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/produtos/cadastrar" element={<ProfessionalMiddleware><ProdutosCadastrar /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/produtos/estoque" element={<ProfessionalMiddleware><ProdutosEstoque /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/produtos/vendidos" element={<ProfessionalMiddleware><ProdutosVendidos /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/equipamentos" element={<ProfessionalMiddleware><Equipamentos /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/agenda" element={<ProfessionalMiddleware><AgendaProfissional /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/medicacao" element={<ProfessionalMiddleware><MedicacaoProfissional /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/atestados" element={<ProfessionalMiddleware><AtestadosProfissionais /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/atestados/novo" element={<ProfessionalMiddleware><AtestadosProfissionaisNovo /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/atestados/ativo" element={<ProfessionalMiddleware><AtestadosProfissionaisAtivo /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/atestados/emitir-atestado" element={<ProfessionalMiddleware><EmitirAtestadoPage /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/atestados/armazenado" element={<ProfessionalMiddleware><AtestadosProfissionaisArmazenado /></ProfessionalMiddleware>} />
            <Route exact path="/profissionais/notificacoes" element={<ProfessionalMiddleware><NotificacoesProfissionais /></ProfessionalMiddleware>} />
            {/* PRIVADAS WIKI*/}
            <Route exact path="/wiki" element={<ProfessionalMiddleware><Wiki /></ProfessionalMiddleware>} />
            <Route exact path="/wiki/info" element={<ProfessionalMiddleware><WikiInfo /></ProfessionalMiddleware>} />
            <Route exact path="/wiki/sprint" element={<ProfessionalMiddleware><Sprints /></ProfessionalMiddleware>} />
            <Route exact path="/wiki/projetos" element={<ProfessionalMiddleware><Projetos /></ProfessionalMiddleware>} />
            <Route exact path="/wiki/configuracoes" element={<ProfessionalMiddleware><Configuracoes /></ProfessionalMiddleware>} />
            {/* Erro rota não existe - jogar na página 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* Bateria de testes localhost */}
          {process.env.NODE_ENV === 'development' &&
            <DuplicateKeysMonitor />
          }
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  </>)
}
export default AppRoutes