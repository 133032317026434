import React from 'react';
import { TitleL4, TitleL3 } from '../../../../../../../../../../../components';
import { Typography, Image, } from 'antd';
const { Paragraph } = Typography;
export const DocumentosSoftwareAnaliseFacialAnalisesLaterais = () => (<>
  <section style={{ marginTop: '20px' }}>
    <TitleL3 level={3}>Análises Laterais</TitleL3>

    <blockquote style={{ backgroundColor: '#fff3cd', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
      <strong>⚠️ IMPORTANTE:</strong> Leia essas informações antes de continuar: A I.A. está aberta para uso pelo cliente.
      <ul>
        <li>
          <img src="https://placehold.co/15x15/f03c15/f03c15.png" alt="Ícone de alerta" style={{ verticalAlign: 'middle' }} />
          <span style={{ color: '#f03c15' }}>Estamos arrecadando [Fotos Laterais] para re-treinar a I.A. - Taxa de generalização muito alta</span>
        </li>
        <li>
          <img src="https://placehold.co/15x15/f03c15/f03c15.png" alt="Ícone de alerta" style={{ verticalAlign: 'middle' }} />
          <span style={{ color: '#f03c15' }}>Exige na maioria das vezes reposição manual dos pontos pelo analista</span>
        </li>
        <li>
          <img src="https://placehold.co/15x15/f03c15/f03c15.png" alt="Ícone de alerta" style={{ verticalAlign: 'middle' }} />
          <span style={{ color: '#f03c15' }}>Caso conheça algum Harmonizador Facial, recomende a I.A. e peça as [Fotos Laterais]</span>
        </li>
      </ul>
    </blockquote>

    <ul>
      <li><strong>Detecção de Pontos Faciais Laterais:</strong> Identificação de pontos de referência no rosto.</li>
      <li><strong>Análise de Características Laterais:</strong> Avaliação de simetria e proporções faciais.</li>
     </ul>

    <div className='row text-center'>

      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/l1.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Pontos Dir.</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/l2.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Linhas Dir.</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/l3.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Pontos Esq.</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/l4.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Linhas Esq.</small>
      </div>


    </div>
    <section style={{ marginTop: '20px' }}>
      <TitleL4 >Casos de Uso</TitleL4>
      <Paragraph>
        <ul>
        <li>Preenchimento naso-glabela e dorso nasal</li>
      <li>Levantamento de ponta nasal</li>
      <li>Preenchimento labial e mentoniano</li>
      <li>Reabilitações de Dimensão Vertical de Oclusão (DVO)</li>
      <li>Lipoaspiração da papada</li>
   </ul>
      </Paragraph>
    </section>
   
  </section>

</>);
export default DocumentosSoftwareAnaliseFacialAnalisesLaterais;
