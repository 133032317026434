import React from 'react';
import { Link } from 'react-router-dom';

export function ButtonEditarCampo(props) {
  return (
    <>
      <Link onClick={props.onClick} href={props.href}><i className='fa fa-edit text-warning mr-2 ml-2'/></Link>
    </>
  );
}

export default ButtonEditarCampo;
