import React from 'react';
import '../styles/ColorPalette.css'
const colors = [
    { name: 'Primary', hex: '#0A66C2' },
    { name: 'Secondary', hex: '#0D47A1' },
    { name: 'Accept', hex: '#4CAF50' },
    { name: 'Accent', hex: '#FFC107' },
    { name: 'Complementary', hex: '#FFFFFF' },
    { name: 'Neutral', hex: '#F5F5F5' }
];
const ColorPalette = () => {
    return (<>
        <div className="color-palette bg-info py-3 mt-3">
            <div className='d-flex justify-content-center align-items-center col-md-2'><p><small>Palete de cores</small></p> </div>
            {colors.map((color, index) => (
                <div key={index} className="color-ball " style={{ backgroundColor: color.hex }}>
                    <small className="color-label">{color.name}</small>
                </div>
            ))}
        </div>
        <div className="color-palette-container mt-5">
            <div className="color-example">
                <div className="color-box primary">
                    <span className="color-label">Primary</span>
                </div>
                <div className="color-description">
                    <h3 className="color-name">#0A66C2</h3>
                    <p className="color-usage">
                        Esta é a cor principal utilizada para elementos de destaque, como botões de ação e links importantes.
                        Por exemplo, um botão de "Enviar" em um formulário pode usar esta cor.
                    </p>
                    <p className='text-justify'>Significado: A cor azul é frequentemente associada à confiança, calma, profissionalismo e segurança. É uma cor que inspira confiança e é frequentemente utilizada em ambientes corporativos e tecnológicos.
                        Psicologia: O azul é uma cor fria que pode ter um efeito calmante, reduzindo a ansiedade e promovendo a clareza mental. Também é associada à honestidade e lealdade, o que pode ajudar a estabelecer uma relação de confiança com os usuários.
                        Exemplo de uso: Botões de ação importantes, como "Enviar", e títulos principais de seções.</p>
                </div>
            </div>

            <div className="color-example">
                <div className="color-box secondary">
                    <span className="color-label">Secondary</span>
                </div>
                <div className="color-description">
                    <h3 className="color-name">#094DA1</h3>
                    <p className="color-usage">
                        A cor secundária é utilizada para suportar visualmente a cor primária, como barras de navegação e fundos de seções.
                        Por exemplo, a barra de navegação de um site pode usar esta cor.
                    </p>
                    <p className='text-justify'>Significado: Uma variação mais escura do azul, essa cor também está associada à autoridade, seriedade e responsabilidade. Ela pode ser usada para complementar a cor primária, adicionando profundidade e contraste.
                        Psicologia: A tonalidade mais escura do azul transmite uma sensação de poder e seriedade, reforçando a confiança e a estabilidade.
                        Exemplo de uso: Barras de navegação, fundos de cabeçalhos e seções que precisam de destaque visual sem serem tão proeminentes quanto os elementos primários.</p>
                </div>
            </div>
            <div className="color-example">
                    <div className="color-box accept">
                        <span className="color-label">Accept</span>
                    </div>
                    <div className="color-description">
                        <h3 className="color-name">#4CAF50</h3>
                        <p className="color-usage">
                            Esta cor é usada para indicar sucesso, confirmações e ações bem-sucedidas. Pode ser utilizada para mensagens de sucesso, ícones de verificação e outros elementos que indicam aprovação.
                        </p>
                        <p className='text-justify'>
                            Significado: O verde é associado ao crescimento, harmonia, frescor e segurança. É uma cor que sugere estabilidade e resistência.
                            Psicologia: O verde tem um efeito calmante e pode ajudar a aliviar o estresse. Ele é frequentemente usado para indicar que tudo está bem e funcionando corretamente.
                            Exemplo de uso: Mensagens de sucesso, ícones de verificação e outros elementos que indicam aprovação.
                        </p>
                    </div>
                </div>

            <div className="color-example">
                <div className="color-box accent">
                    <span className="color-label">Accent</span>
                </div>
                <div className="color-description">
                    <h3 className="color-name">#F06A33</h3>
                    <p className="color-usage">
                        O acento é utilizado para destaques e chamadas à ação que precisam se destacar do restante do conteúdo.
                        Por exemplo, um botão de "Alerta" pode usar esta cor.
                    </p>
                    <p className='text-justify'>Significado: A cor amarela é associada à felicidade, otimismo, e energia. É uma cor que chama a atenção e pode criar um sentimento de urgência.
                        Psicologia: O amarelo é uma cor quente e estimulante que pode aumentar a energia e a positividade. No entanto, deve ser usado com moderação, pois em excesso pode causar agitação.
                        Exemplo de uso: Botões de "Comprar agora", ícones de notificação e outros elementos que precisam se destacar e chamar a atenção imediata dos usuários.</p>
                </div>
            </div>

            <div className="color-example">
                <div className="color-box complementary">
                    <span className="color-label">Complementary</span>
                </div>
                <div className="color-description">
                    <h3 className="color-name">#B3DDFC</h3>
                    <p className="color-usage">
                        A cor complementar é usada para textos principais sobre fundos escuros e para áreas onde a legibilidade é crucial.
                        Por exemplo, o fundo de um card pode usar esta cor para destacar o conteúdo principal.
                    </p>
                    <p className='text-justify'>Significado: A cor branca é associada à pureza, simplicidade, e clareza. É frequentemente usada para criar uma sensação de espaço e limpeza.
                        Psicologia: O branco pode ajudar a destacar outras cores e tornar o design mais arejado e organizado. É uma cor neutra que pode ser usada para criar equilíbrio e neutralidade.
                        Exemplo de uso: Fundo de cards, texto principal sobre fundos escuros, e áreas onde a legibilidade é crucial.</p>
                </div>
            </div>

            <div className="color-example">
                <div className="color-box neutral">
                    <span className="color-label">Neutral</span>
                </div>
                <div className="color-description">
                    <h3 className="color-name">#F4F4F4</h3>
                    <p className="color-usage">
                        O neutro é utilizado para fundos de seções, divisórias e áreas que requerem um toque sutil sem distrair.
                        Por exemplo, o fundo de um rodapé pode usar esta cor.
                    </p>
                    <p className='text-justify'>Significado: Tons neutros como o cinza claro são associados à simplicidade, modernidade, e sofisticação. Eles ajudam a criar um fundo discreto que não distrai.
                        Psicologia: O cinza claro transmite uma sensação de calma e equilíbrio. É uma cor versátil que pode complementar quase qualquer outra cor, ajudando a destacar elementos mais vibrantes sem competir com eles.
                        Exemplo de uso: Fundo de rodapés, divisórias de conteúdo, e seções que requerem um toque sutil sem distrair.</p>
                </div>
            </div>
        </div>
    </>);
};

export default ColorPalette;
