import React from 'react';

const ProntuarioSelectProfissionalCBOInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>ProntuarioSelectProfissionalCBO</strong></h5>
      <p>
        O componente <strong>ProntuarioSelectProfissionalCBO</strong> permite que você selecione um profissional CBO (Classificação Brasileira de Ocupações) a partir de uma lista. Ele fornece uma interface de busca conveniente para encontrar o profissional desejado. Abaixo está um guia sobre como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong>Selecionando um Profissional</strong></li>
        <p>
          Quando você abrir o componente, você verá um campo de seleção. Comece a digitar o nome do profissional que deseja selecionar. O componente fornecerá sugestões à medida que você digita.
        </p>

        <li><strong>Escolhendo um Profissional</strong></li>
        <p>
          À medida que você digita, as opções disponíveis serão exibidas em uma lista suspensa. Você pode clicar em um profissional na lista para selecioná-lo.
        </p>

        <li><strong>Busca Avançada</strong></li>
        <p>
          Se você não encontrar o profissional desejado na lista, você pode continuar digitando para refinar sua pesquisa. O componente buscará correspondências com base no que você digitou.
        </p>

        <li><strong>Conclusão</strong></li>
        <p>
          Depois de escolher o profissional desejado, ele será exibido no campo de seleção. Você pode continuar usando o componente conforme necessário.
        </p>
      </ol>
      <hr />
      <p>
        Agora você está pronto para usar o componente <strong>ProntuarioSelectProfissionalCBO</strong> para selecionar profissionais CBO de forma fácil e eficaz. Certifique-se de escolher o profissional correto e usar a busca avançada, se necessário.
      </p>
    </div>
  );
};

export default ProntuarioSelectProfissionalCBOInfo;
