import React from 'react';
export const TestarConfiguracoesAviso = () => {
    return (
        <>
            <div className='col-md-12'>
                <h5 className='font-weight-bold mt-3'><strong>Configurações de aviso
                </strong></h5>
                <div className="col-md-12 d-flex justify-content-center align-items-center py-3 mb-3">
                    <button className="btn btn-success" onClick={'handleSaveConfiguracao'}>
                        Testar Aviso Adicional Separado
                    </button>
                </div>
            </div>
        </>);
};
export default TestarConfiguracoesAviso;