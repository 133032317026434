/*import React, { useState } from 'react';
import { baseURL } from '../../../../../../services/api';
import "../Configuracoes/Configuracoes.css"


export const Analyze = () => {
    const [directoryPath, setDirectoryPath] = useState('');
    const [results, setResults] = useState(null);
    const [imagePaths, setImagePaths] = useState([]);
    const analyzeDirectory = async () => {
        try {
            const response = await fetch(`${baseURL}analyze-directory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ directoryPath })
            });
            const data = await response.json();
            setResults(data);
            setImagePaths(data.imagePaths); // Atualiza os caminhos das imagens
        } catch (error) {
            console.error('Error analyzing directory:', error);
        }
    };

    return (
     <div className="App">
            <h1 className='analize-titulo'>Analisar Diretório</h1>
            <div className="wrapper-analise">
            <div className='input-analise'>
            <input
                type="text"
                placeholder="Insira o caminho do diretório..."
                className="configuracao-main-content-input"
                value={directoryPath}
                onChange={(e) => setDirectoryPath(e.target.value)}

            />
            </div>
            <button  className="btn btn-primary button-analisar"onClick={analyzeDirectory}>Analisar</button>
            {results && (
                <div>
                    <p>Total Lines: {results.lines}</p>
                    <p>Total Files: {results.totalFiles}</p>
                    <p>Total Images: {results.images}</p>
                    <p>Image Paths:</p>
                    <ul>
                        {imagePaths.map((path, index) => (
                            <li key={index}>{path}</li>
                        ))}
                    </ul>
                </div>
            )}</div>
        </div>
    );
}

export default Analyze;*/
import React, { useState } from 'react';
import { baseURL } from '../../../../../../services/api';
import "../Configuracoes/Configuracoes.css";

export const Analyze = () => {
    const [directoryPath, setDirectoryPath] = useState('');
    const [results, setResults] = useState(null);
    const [imagePaths, setImagePaths] = useState([]);
    
    const analyzeDirectory = async () => {
        try {
            const response = await fetch(`${baseURL}analyze-directory`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ directoryPath })
            });
            const data = await response.json();
            setResults(data);
            setImagePaths(data.imagePaths); // Atualiza os caminhos das imagens
        } catch (error) {
            console.error('Error analyzing directory:', error);
        }
    };

    return (
        <div className="App">
            <h1 className='analize-titulo'>Analisar Diretório</h1>
            <div className="wrapper-analise">
                <div className='input-analise'>
                    <input
                        type="text"
                        placeholder="Insira o caminho do diretório..."
                        className="configuracao-main-content-input"
                        value={directoryPath}
                        onChange={(e) => setDirectoryPath(e.target.value)}
                    />
                </div>
                <button className="btn btn-primary button-analisar" onClick={analyzeDirectory}>Analisar</button>
            </div>
            {results && (
                <div className="results">
                    <p>Total Lines: {results.lines}</p>
                    <p>Total Files: {results.totalFiles}</p>
                    <p>Total Images: {results.images}</p>
                    <p>Image Paths:</p>
                    <ul>
                        {imagePaths.map((path, index) => (
                            <li key={index}>{path}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Analyze;
