import React from 'react';
const ConfiguracoesRapidas = () => {

  return (
    <div className="mt-3">
     <h2 className='text-center font-weight-bold'>Configurações rápidas</h2>
    </div>
  );
};

export default ConfiguracoesRapidas;
