import React, { useState } from 'react';
import images from './images'; // Importe o objeto images
import '../styles/ChartImage.css'

export const chartTypes = [
  { type: 'Bar', label: 'Bar Chart', imageSrc: images.imageBC },
  { type: 'Line', label: 'Line Chart', imageSrc: images.imageLC },
  { type: 'Pie', label: 'Pie Chart', imageSrc: images.imagePC },
  { type: 'Area', label: 'Area Chart', imageSrc: images.imageAC },
  { type: 'Donut', label: 'Donut Chart', imageSrc: images.imageDNC },
  { type: 'Scatter', label: 'Scatter Plot', imageSrc: images.imageSP },
  { type: 'Bubble', label: 'Bubble Chart', imageSrc: images.imageBUC },
  { type: 'Radar', label: 'Radar Chart', imageSrc: images.imageRC },
  { type: 'Mixed', label: 'Mixed Chart', imageSrc: images.imageMX },

  // { type: 'Doughnut', label: 'Doughnut Chart', imageSrc: images.imageDC },
  // { type: 'PolarArea', label: 'Polar Area Chart', imageSrc: images.imagePAC },
  // { type: 'StackedBar', label: 'Stacked Bar Chart', imageSrc: images.imageSBC },
  // { type: 'StackedArea', label: 'Stacked Area Chart', imageSrc: images.imageSAC },
  // { type: 'GroupedBar', label: 'Grouped Bar Chart', imageSrc: images.imageGC },
  // { type: 'Candlestick', label: 'Candlestick Chart', imageSrc: images.imageCC },
  // { type: 'Waterfall', label: 'Waterfall Chart', imageSrc: images.imageWC },
  // { type: 'BoxPlot', label: 'Box Plot', imageSrc: images.imageBP },
  // Add other chart types here
];

const ChartSelection = ({ onChartCreate, chartTypes }) => {
  const [isDragging, setIsDragging] = useState(false);
  const handleDragStart = (event, chartType) => {
    setIsDragging(true);
    event.dataTransfer.setData('text/plain', chartType);
  };
  const handleDragEnd = () => {
    setIsDragging(false);
  };
  const handleChartCreate = (chartType) => {
    onChartCreate(chartType);
  };
  return (
    <div className="row">
      {chartTypes.map((chart) => (
        <div
          key={chart.type}
          className={`col-md-3 mt-2 ${isDragging ? 'dragging' : ''}`}
          draggable
          onDragStart={(e) => handleDragStart(e, chart.type)}
          onDragEnd={handleDragEnd}
          onClick={() => handleChartCreate(chart.type)}
        >
          <img src={chart.imageSrc} alt={chart.label} className="w-100" />
          <div><strong>{chart.label}</strong></div>
        </div>
      ))}
    </div>
  );
};

export default ChartSelection;

