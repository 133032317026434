import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, ButtonRemoverCampo, ButtonEditarCampo, MaisInfoMenosInfo } from '../../../../../../components';
import ProntuarioPressaoArterialInfo from '../info/ProntuarioPressaoArterialInfo';

export const ProntuarioPressaoArterial = () => {
  const [pressao, setPressao] = useState('');
  const [pressaoArterialLista, setPressaoArterialLista] = useState([]);
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState('');

  const handleOpcaoSelecionada = (e) => {
    setOpcaoSelecionada(e.target.value);
    setPressao('');
  };

  const handlePressaoChange = (e) => {
    setPressao(e.target.value);
  };

  const handleAdicionarPressaoArterial = () => {
    if (pressao.trim() !== '') {
      const dataHora = new Date().toLocaleString();
      const novaLeitura = `${pressao} mmHg - ${dataHora}`;
      if (indiceEditando === -1) {
        setPressaoArterialLista([...pressaoArterialLista, novaLeitura]);
      } else {
        const novaLista = [...pressaoArterialLista];
        novaLista[indiceEditando] = novaLeitura;
        setPressaoArterialLista(novaLista);
        setIndiceEditando(-1);
      }
      setPressao('');
    }
  };

  const removerPressaoArterial = (index) => {
    const novaLista = [...pressaoArterialLista];
    novaLista.splice(index, 1);
    setPressaoArterialLista(novaLista);
  };

  const handleEditarPressaoArterial = (index) => {
    const leituraEditando = pressaoArterialLista[index];
    const pressaoEditando = leituraEditando.split(' ')[0];
    setPressao(pressaoEditando);
    setIndiceEditando(index);
  };

  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        Sua pressão é:</h5>
      <label>
        <input type="radio" name='pressaoArterial' value='Normal' onChange={handleOpcaoSelecionada} /> Normal
      </label>
      <br />
      <label>
        <input type="radio" name='pressaoArterial' value='Alta' onChange={handleOpcaoSelecionada} /> Alta
      </label>
      <br />
      <label>
        <input type="radio" name='pressaoArterial' value='Baixa' onChange={handleOpcaoSelecionada} /> Baixa
      </label>
      <br />
      <label>
        <input type="radio" name='pressaoArterial' value='Controlada com medicamento' onChange={handleOpcaoSelecionada} /> Controlada com medicamento
      </label>
      {opcaoSelecionada !== '' && (
        <div>
          <h6>Adicionar Leitura de Pressão Arterial:</h6>
          <input
            type="text"
            value={pressao}
            onChange={handlePressaoChange}
            placeholder="Pressão arterial (por exemplo, 120/80 mmHg)"
          />
          <ButtonAdicionarCampo type={'button'} onClick={handleAdicionarPressaoArterial} />
        </div>
      )}
      {pressaoArterialLista.length > 0 && (
        <div>
          <h6>Leituras de Pressão Arterial Adicionadas:</h6>
          <ul>
            {pressaoArterialLista.map((leitura, index) => (
              <li key={index}>{leitura}
                <ButtonRemoverCampo onClick={() => removerPressaoArterial(index)} />
                <ButtonEditarCampo onClick={() => handleEditarPressaoArterial(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>

     <MaisInfoMenosInfo text={<ProntuarioPressaoArterialInfo />}></MaisInfoMenosInfo>
    </>
  );
};

export default ProntuarioPressaoArterial;
