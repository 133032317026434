import React from 'react';
import { TitleL1 } from '../../../../../components';

export const ApresentacaoParceriasInfo = () => {
    return (<>

        <TitleL1>Metodologias Ágeis de Gerenciamento</TitleL1>
        <h5>Metodologias ágeis de gerenciamento, também conhecidas como Agile,
            são abordagens flexíveis e colaborativas para gerenciar projetos e equipes de
            desenvolvimento. Elas são projetadas para melhorar a eficiência, a transparência
            e a qualidade do trabalho, especialmente em projetos complexos de software, mas podem
            ser aplicadas em uma variedade de contextos. Vamos falar um pouco mais sobre algumas
            das principais metodologias ágeis utlizadas aqui:
        </h5>

        <ol>
            <li>
                <h5 className='mt-5 font-weight-bold'><strong>Scrum</strong></h5>
                <p>O Scrum é uma das metodologias ágeis mais populares</p>
                <ul>
                    <li>Divide o projeto em ciclos curtos chamados "sprints", geralmente de 2 a 4 semanas</li>
                    <li>As equipes se organizam em "times Scrum" que incluem um Scrum Master e um Product Owner</li>
                    <li>Reuniões diárias de curta duração (Daily Scrum) mantêm todos atualizados sobre o progresso</li>
                    <li>O Scrum enfatiza a transparência, a colaboração e a adaptação contínua</li>
                </ul>
            </li>
            <li>
                <h5 className='mt-5 font-weight-bold'><strong>Kanban</strong></h5>
                <p>O Kanban é uma abordagem baseada em visualização do trabalho</p>
                <ul>
                    <li>As tarefas são representadas como cartões em um quadro Kanban, movendo-se de uma coluna (estado) para outra à medida que progridem</li>
                    <li>O foco está na limitação do trabalho em progresso (WIP) para evitar sobrecarga de tarefas</li>
                    <li>O Kanban é altamente adaptável e pode ser usado em conjunto com outras metodologias</li>
                </ul>
            </li>
            <li>
                <h5 className='mt-5 font-weight-bold'><strong>Extreme Programming (XP)</strong></h5>
                <p>O XP é uma metodologia que enfatiza práticas de desenvolvimento de software de alta qualidade</p>
                <ul>
                    <li>Inclui práticas como programação em pares, testes automatizados, integração contínua e revisões de código</li>
                    <li>Valoriza a comunicação próxima entre desenvolvedores e clientes</li>
                    <li>Promove a entrega de software funcional em curtos intervalos de tempo</li>
                </ul>
            </li>
            <li>
                <h5 className='mt-5 font-weight-bold'><strong>Lean</strong></h5>
                <p>O Lean se baseia nos princípios da manufatura enxuta e visa eliminar desperdícios em processos</p>
                <ul>
                    <li>Concentra-se na entrega de valor ao cliente e na otimização de fluxos de trabalho</li>
                    <li>O Lean enfatiza a melhoria contínua, a simplificação de processos e a redução de atividades que não agregam valor</li>
                </ul>
            </li>
            <li>
                <h5 className='mt-5 font-weight-bold'><strong>Dynamic Systems Development Method (DSDM)</strong></h5>
                <p>O DSDM é uma metodologia ágil que se concentra na entrega de sistemas de informação em um prazo e orçamento fixos</p>
                <ul>
                    <li>Utiliza uma abordagem iterativa e incremental</li>
                    <li>Coloca ênfase na colaboração próxima com os stakeholders e na priorização de requisitos</li>
                </ul>
            </li>
            <li>
                <h5 className='mt-5 font-weight-bold'><strong>Feature Driven Development (FDD)</strong></h5>
                <p>O FDD é uma metodologia que se concentra na entrega de recursos ou funcionalidades específicas</p>
                <ul>
                    <li>Divide o projeto em "modelos de domínio" que representam partes do sistema</li>
                    <li>É uma abordagem estruturada para o desenvolvimento ágil</li>
                </ul>
            </li>
        </ol>
        <p>Cada uma dessas metodologias tem suas próprias características e é mais adequada para diferentes contextos e equipes. A escolha da metodologia depende das necessidades do projeto e da cultura da equipe, mas todas compartilham o compromisso com a adaptabilidade, colaboração e entrega de valor ao cliente</p>
    </>
    );
};
export default ApresentacaoParceriasInfo;