import { useEffect } from 'react';

export const UrlChecker = () => {
  useEffect(() => {
    // Função para clicar em um elemento
    const clickElement = (selector) => {
      const element = document.querySelector(selector);
      if (element) {
        element.click();
      }
    };

    // Verifica a URL
    const checkUrlAndClick = () => {
      const url = new URL(window.location.href); // Usa a URL atual
      const hash = url.hash; // Obtém o hash da URL
      const search = url.search; // Obtém a parte da consulta (?...)

      if (hash) {
        // Remove qualquer parte após o '?' no hash
        const hashWithoutParams = hash.split('?')[0];
        const hashes = hashWithoutParams.split('#').filter(h => h);

        // Clica nos elementos correspondentes
        hashes.forEach((h, index) => {
          setTimeout(() => {
            clickElement(`#${h}`);
          }, index * 500); // Adiciona um atraso para cada clique subsequente
        });

        // Atualiza a URL mantendo a parte do hash e search
        const newUrl = `${url.pathname}${hashWithoutParams}${search}`;
        window.history.replaceState(null, '', newUrl);
      }
    };

    // Verifica a URL inicial
    checkUrlAndClick();

    // Adiciona um evento de escuta para mudanças no hash da URL
    window.addEventListener('hashchange', checkUrlAndClick);

    // Remove o evento de escuta ao desmontar o componente
    return () => {
      window.removeEventListener('hashchange', checkUrlAndClick);
    };
  }, []);

  return null;
};

export default UrlChecker;
