// Arquivo de direcionamento completo para pré-operações
// Em operação fazer a função de trocar preferências de cor dos card e ou ainda posição 
// Estudar para ver como vai ser feito até pro próprio whitelabel 


export const ModuloVisualConvencional = () => {
    return (
        <>
            <div className=' w-100' >
               
                <div className="py-3">
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                                {/* Complexo A - sintonia NAV */}
                                <h5 className="text-dark text-justify">Classic <br/>Easy<br/>Easy Icone</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}