import React from 'react';
import { TitleL2, TitleL3 } from '../../../../../components';


export const MissionCards = () => {
  return (<>
    <TitleL2 className='mt-5'>Definição das Missões </TitleL2>
    <div className='row' style={{ padding: '20px', marginTop: '15px', textAlign: 'justify' }}>
      <div className='col-md-4'>
        <TitleL3 level={4}>Como fazemos o bem sempre !?</TitleL3>
        <p>
          Na THOT I.A. CORP., fazemos o bem sempre identificando as necessidades sociais e ambientais.
          Desenvolvemos iniciativas que promovem o bem-estar da comunidade e a sustentabilidade,
          com foco em práticas que geram um impacto positivo a longo prazo.
          Nossa missão é garantir que cada ação que tomamos contribua para um mundo melhor.
        </p>
      </div>
      <div className='col-md-4'>
        <TitleL3 level={4}>Quando nos comprometemos com a responsabilidade social !?</TitleL3>
        <p>
          Estamos comprometidos com a responsabilidade social em todas as nossas operações,
          desde a fundação da THOT I.A. CORP.. Esse compromisso é contínuo,
          envolvendo a implementação de práticas sustentáveis e o envolvimento com a comunidade
          de forma ativa e consistente, todos os dias.
          Acreditamos que é nosso dever apoiar a sociedade em momentos de necessidade.
        </p></div>
      <div className='col-md-4'>
        <TitleL3 level={4}>Onde impactamos !?</TitleL3>
        <p>
          Nossas iniciativas têm impacto tanto localmente quanto globalmente.
          Trabalhamos em parceria com organizações comunitárias, ONGs e outras entidades para
          promover a sustentabilidade e o bem-estar social.
          Estamos presentes nas comunidades onde atuamos, fazendo a diferença por meio de projetos e ações que visam um futuro melhor para todos.
        </p>
      </div>
    </div>
  </>);
};

export default MissionCards;
