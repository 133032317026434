import React from 'react';

const ProntuarioProblemasCoracaoInfo = () => {
  return (
    <div>
      <h5>
       Usando o Componente <strong>Problemas Coração</strong>
      </h5>
      <p>
        O componente  <strong>Problemas Coração</strong> permite que você registre seus problemas no coração. Você pode adicionar, editar e remover queixas conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong>Marque se tem ou teve algum problema de coração</strong>
          <p>
            Quando você abrir o componente, você verá a pergunta "Tem ou teve algum problema de coração?" e três opções: "Sim," "Não" e "Não Sei." Marque a opção correspondente para indicar se você tem ou teve algum problema de coração.
          </p>
        </li>
        <li>
          <strong> Adicionar problemas de coração</strong>
          <p>
            Se você marcar "Sim," uma seção adicional aparecerá onde você pode adicionar problemas de coração. Digite o nome do problema de coração no campo de texto abaixo de "Se Sim, quais?" e clique no botão "Adicionar Problema de Coração" para inserir o problema na lista.
          </p>
        </li>
        <li><strong>Editar problemas de coração</strong>
          <p>
            Se você quiser editar um problema de coração existente na lista, clique no botão "Editar" ao lado dele. Isso preencherá o campo de texto com o nome do problema de coração para edição. Faça as alterações necessárias e clique no botão "Adicionar Problema de Coração" para salvar as alterações.
          </p>
        </li>
        <li><strong>Remover problemas de coração</strong>
          <p>
            Se você desejar remover um problema de coração da lista, clique no botão "Remover" ao lado dele. O problema de coração será excluído da lista.
          </p>
        </li>
        <li><strong>          Finalização</strong>
          <p>
            Depois de adicionar, editar ou remover problemas de coração, você pode continuar usando o componente conforme necessário. Quando terminar, você pode clicar no botão "ADICIONAR PROBLEMA DE CORAÇÃO" na parte inferior do componente para concluir o processo.
          </p>
        </li>
      </ol>
      <hr />
      <p>
        Certifique-se de seguir os passos corretamente e de adicionar/editar/remover problemas de coração conforme necessário para manter um registro preciso das informações médicas.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> 
    </div>
  );
};

export default ProntuarioProblemasCoracaoInfo;
