import moment from 'moment';
import { axiosHeadersToken } from '../../../../contexts';
import { baseURL } from '../../../../api';
export const fetchPatientData = async (id, isCadastrarPage, setName, setCpf, setDataNascimento, setEmail, setLatitude, setLongitude, setPhone) => {
  if (!isCadastrarPage) {
    try {
      const response = await axiosHeadersToken().get(`${baseURL}get_user/${id}`);
      const userData = response.data.user;
      setName(userData.name);
      setCpf(userData.cpf);
      setDataNascimento(moment(userData.dataNascimento).format('YYYY-MM-DD'));
      setEmail(userData.email);
      setLatitude(userData.latitude);
      setLongitude(userData.longitude);
      setPhone(userData.phone);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error('Erro de autenticação. Faça login novamente.');
      } else {
        throw new Error('Erro ao buscar dados do paciente.');
      }
    }
  }
};