import { Breadcrumb } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
function TypingEffect(props) {
  const [typedText, setTypedText] = useState('');
  useEffect(() => {
    let i = 0;
    const timer = setInterval(() => {
      if (i < props.text.length) {
        const nextChar = props.text[i];
        setTypedText(prevState => prevState + nextChar);
        i++;
      } else {
        clearInterval(timer);
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, [props.text]);

  return (
    <span style={props.style}>
      {typedText}
    </span>
  );
}
const formatPageTitle = (title) => {
  title = title.trim().replace(/^\//, '');
  return title.split('/');
};
export function NavBreadcrumb(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const formattedTitle = formatPageTitle(location.pathname);
  const handlePartClick = (index) => {
    const relativePath = '/' + formattedTitle.slice(0, index + 1).join('/');
    navigate(relativePath, { replace: true });
  };
  
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };

  return (<>
    <Breadcrumb className="breadcrumb" style={{ maxHeight: '30px', overflow: "hidden", marginTop: '-12px', fontFamily: 'Arial, Helvetica', paddingLeft: '0px' }}>
      <i style={{ marginTop: '4px' }} className={`fa-${props.icon}`}>&nbsp; &nbsp; </i>
      {formattedTitle.map((part, index) => (
        <Breadcrumb.Item
          key={index}
          style={{ cursor: 'pointer', color: '#1890ff', fontFamily: 'Arial, Helvetica' }}
          onClick={() => handlePartClick(index)}
        >
          <TypingEffect
            style={{ textTransform: 'capitalize', color: 'inherit', padding: '0px', fontFamily: 'Arial, Helvetica',  cursor: 'pointer' }}
            text={part}
            onFinished={props.onFinished}
          />
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
    {screenWidth >= 1058 && <hr style={{ marginTop: '-12px', marginBottom: '8px' }} />}
  </>);
}
export default NavBreadcrumb;