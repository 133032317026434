import React from 'react';

const FotoFaceInfo = () => {
  return (
    <div className="text-justify col-md-12">
      <h5 className='font-weight-bold'>Foto da Face - Dicas e Recomendações</h5>
      <p>
        No componente<strong> Foto Face</strong>, valorizamos sua privacidade e controle sobre as fotos em seu perfil. Aqui estão algumas dicas e informações importantes sobre como suas fotos são exibidas:
      </p>
      <hr />
      <h5 className='font-weight-bold'>Foto de Perfil:</h5>
      <p>
        Sua foto de perfil será visível para o público em geral. Para causar uma boa impressão, siga estas recomendações:
      </p>
      <ol className="ml-3 mt-3">
        <li><strong>Identificação Fácil:</strong> Use uma foto com as mesmas regras de um 3/4 de identidade, o rosto centralizado em um fundo homogeneo que permita que as pessoas o identifiquem facilmente. Evite maquiagem, óculos, chapéus ou outros artefatos que possam obscurecer seu rosto.</li>
        <li><strong>Enquadramento Adequado:</strong> Certifique-se de que sua foto esteja bem enquadrada, com seu rosto centralizado.</li>
        <li><strong>Fundo Homogeneo:</strong> Escolha um local que contenha um fundo homogeneo.</li>
        <li><strong>Iluminação Natural:</strong> Escolha uma foto tirada em boa iluminação natural, pois isso realça sua aparência.</li>
        <li><strong>Resolução Adequada:</strong> Use uma foto de alta resolução para garantir que sua imagem seja nítida e clara.</li>
        <li><strong>Atualização e Armazenamento:</strong> Atualize a foto ao menos 1 vez por ano, deixando armazenada as anteriores, servir de análise em diversas ocasiões.</li>
      </ol>
      <hr />
      <h5 className='font-weight-bold'>Outras Fotos:</h5>
      <p>
        Todas as outras fotos em seu perfil serão visíveis apenas para você. No entanto, você pode querer seguir as mesmas recomendações para garantir que suas fotos pessoais também estejam ótimas.
        Lembre-se de que suas fotos pessoais são privadas e serão mantidas dessa forma. Se você deseja compartilhar fotos específicas com outras pessoas, você pode configurar permissões de visualização individualmente.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
      <h5 className='font-weight-bold'>Foto da Face - Como Utilizar</h5>
      <p>
        No componente<strong> Foto Face</strong>, existe icones de ação em cima das fotos são eles:
      </p>
      <ol className="ml-3 mt-3">
        <li><strong>Ícone de Lixeira</strong> Excluir a foto. <i className="fa fa-trash text-danger" aria-hidden="true"></i></li>
        <li><strong>Ícone de UP</strong> Torna a foto a do perfil.<i className="fa fa-arrow-up text-success" aria-hidden="true"></i></li>
        <li><strong>Ícone de Refresh</strong> Só aparece quando uma foto é excluida. Recupera a foto excluida antes de atualizar o sistema.<i className="fa fa-refresh text-warning" aria-hidden="true"></i></li>
       
      </ol>
      <hr />
    </div>
  );
};

export default FotoFaceInfo;
