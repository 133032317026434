import React, { useState, useEffect } from 'react';

function TablePontosFrontais({ landmarks, setLandmarks, pontoDestacado, setPontoDestacado }) {
  const [landmarksIniciais, setLandmarksIniciais] = useState([]);

  useEffect(() => {
    if (Array.isArray(landmarks)) {
      setLandmarksIniciais(landmarks.map(l => ({ ...l }))); // Faz uma cópia profunda
    }
  }, [landmarks]);

  return (<>
    <div className='text-justify mb-3'>
      <li>Para alterar um ponto facial é só clicar em cima dele e arastar, quando estiver no lugar desejado solte.  </li>
      <li>Para isolar pontos clique na tabela e volte na imagem</li>
    </div>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Ponto</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>X (Atual)</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Y (Atual)</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Z (Atual)</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Diferença</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>X (Original)</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Y (Original)</th>
          <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>Z (Original)</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(landmarks) && landmarks.map((landmark, index) => {
          let cor = 'black';
          if (pontoDestacado === index) {
            cor = 'blue'; // Cor de destaque
          }

          const originalLandmark = landmarksIniciais[index];

          const x = landmark.x !== undefined ? landmark.x.toFixed(2) : 'N/A';
          const y = landmark.y !== undefined ? landmark.y.toFixed(2) : 'N/A';
          const z = landmark.z !== undefined ? landmark.z.toFixed(2) : 'N/A';

          const originalX = originalLandmark?.x !== undefined ? originalLandmark.x.toFixed(2) : 'N/A';
          const originalY = originalLandmark?.y !== undefined ? originalLandmark.y.toFixed(2) : 'N/A';
          const originalZ = originalLandmark?.z !== undefined ? originalLandmark.z.toFixed(2) : 'N/A';

          const diferencaX = landmark.x !== originalLandmark?.x ? 'Alterado' : 'Nenhuma';
          const diferencaY = landmark.y !== originalLandmark?.y ? 'Alterado' : 'Nenhuma';
          const diferencaZ = landmark.z !== originalLandmark?.z ? 'Alterado' : 'Nenhuma';

          const diferenca = (diferencaX === 'Alterado' || diferencaY === 'Alterado' || diferencaZ === 'Alterado')
            ? 'Alterado'
            : 'Nenhuma';

          return (
            <tr
              key={index}
              style={{
                cursor: 'pointer',
                backgroundColor: pontoDestacado === index ? '#f8d7da' : 'transparent',
              }}
              onClick={() => {
                if (pontoDestacado === index) {
                  setPontoDestacado(null); // Remove o destaque
                } else {
                  setPontoDestacado(index); // Destaca o ponto
                }
              }}
            >
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {index + 1}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {x}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {y}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {z}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {diferenca === 'Alterado' ? (
                  <span style={{ color: 'red' }}>Alterado</span>
                ) : (
                  'Nenhuma'
                )}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {originalX}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {originalY}
              </td>
              <td style={{ padding: '8px', border: '1px solid #ddd', color: cor }}>
                {originalZ}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </>);
}

export default TablePontosFrontais;
