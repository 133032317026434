import React from "react";
import { Link } from "react-router-dom";
import './SideMenu.css'

export function SideMenu({ link, title }) {
  return (
    <span className="left-menu-item">
      <Link to={link}>{title}</Link>
    </span>
  )
}