import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../../../../../../../services/api.js';
import { AuthContext } from '../../../../../../../../contexts/auth.jsx';
import { MaisInfoMenosInfo } from '../../../../../../../../components/index.js';
import { Link } from 'react-router-dom';
import { Image } from 'antd';

export const VinculosProfissional = () => {
    const { getUserId } = useContext(AuthContext);
    const [professionalUnitData, setProfessionalUnitData] = useState([]);

    useEffect(() => {
        const storedProfessionalUnitData = localStorage.getItem('professionalUnitData');
        if (storedProfessionalUnitData === 'undefined') {
            setProfessionalUnitData([]);
        } else {
            const parsedData = JSON.parse(storedProfessionalUnitData);
            setProfessionalUnitData(parsedData);
        }
    }, []);
    const handleExcludeProfessionalUnit = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const id = getUserId();
            const response = await axios.put(
                `${baseURL}updateProfessionalUnitState/${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                alert(`Unidade Profissional com ID ${id} marcada como excluída na API.`);
                const updatedData = professionalUnitData.filter((professionalUnit) => professionalUnit.id !== id);
                localStorage.setItem('professionalUnitData', JSON.stringify(updatedData));
            }
        } catch (error) {
            alert(id);
            alert('Erro ao atualizar o estado da unidade profissional:', error);
        }
    };
    const handleDriveProfessionalUnit = (item) => {
        try {
            const companyInfo = {
                ...item.company,
                ...item.company_unit,
            };
            localStorage.setItem('drive', JSON.stringify(companyInfo));
            alert(`Informações da empresa e cargo adicionadas ao localStorage com a chave "drive".`);
            localStorage.setItem('cadastroProfissional', 'SIM');
        } catch (error) {
            alert('Erro ao adicionar informações ao localStorage:', error);
        }
    };
    return (
        <>
           {professionalUnitData !== null && <>   <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5 className='font-weight-bold'><strong>Vínculos Profissionais</strong></h5>
            <div>
                {professionalUnitData !== null && professionalUnitData.map((item) => (
                    <div className='bg-light rounded mt-1 col-md-12' key={item.id}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <Image className='w-100 mt-1 rounded' src={item.company_unit.imageUrls[0]} alt="Professional Image" />
                                </div>
                                <div className='col-md-10 mt-1'>
                                    <small className='font-weight-bold'>Nome da Empresa:<small> {item.company.company_name}</small></small><br />
                                    <small className='font-weight-bold'>Cargo:<small> {item.company_unit.professional_type}</small></small><br />
                                    <Link onClick={() => handleDriveProfessionalUnit(item)}><i className='fa fa-suitcase text-dark mr-2 ml-2'></i></Link>
                                    <Link onClick={() => handleDriveProfessionalUnit(item.id)}><i className='fa fa-edit text-warning mr-2 ml-2'></i></Link>
                                    <Link onClick={() => handleExcludeProfessionalUnit(item.id)}><i className='fa fa-trash text-danger mr-2 ml-2'></i></Link>
                                    <hr />
                                    <MaisInfoMenosInfo title={'Mais info'} text={<>
                                        <p>Id: {item.id}</p>
                                        <p>Professional Id: {item.company_unit.professional_id}</p>
                                        <p>Company Unit Id: {item.company_unit.company_unit_id}</p>
                                        <p>Convite Id: {item.company_unit.convite_id}</p>
                                        <p>State: {item.company_unit.state}</p>
                                    </>} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
           </> }
        </>);
};

export default VinculosProfissional;
