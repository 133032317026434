import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const ButtonCadastrar = (props) => {
  const location = useLocation();
  const { search } = location;
  const userStr = localStorage.getItem('user');
  let userType = ''; // Define um valor padrão para userType
  if (userStr) {
    const userObj = JSON.parse(userStr);
    userType = userObj.user_type; // Atribuição de userType dentro do bloco condicional7a
  }
  return (<>
    {!userStr && <>
      <div className='text-center d-flex col-md-12 mt-3 justify-content-center' style={{ ...props.style }}>

        <div>
          <Link className='btn btn-primary rounded-circle py-3 mr-3 ml-3 mb-2'
            style={{ boxShadow: "0px 4px 4px 0px #8094BF, inset  0px 0px 6px 0px #8094BF", cursor: 'pointer' }}
            to={'../cadastrar' + search}>
            <i className='fa fa-user-plus mr-3 ml-3' style={{ scale: '2' }}></i></Link><br />
          Cadastrar
        </div>
        <div>
          <Link className='btn btn-primary rounded-circle py-3 mr-3 ml-3 mb-2 '
            style={{ boxShadow: "0px 4px 4px 0px #8094BF, inset  0px 0px 6px 0px #8094BF", cursor: 'pointer', ...props.style }}
            to={'../login' + search}>
            <i className='fa fa-user-circle mr-3 ml-3' style={{ scale: '2' }}></i></Link><br />
          Entrar
        </div>
      </div>
      <div className='col-md-12 mt-3 text-center'>
        <small style={{ ...props.style }}>
        {props.text && <> {props.text} </>}
        {!props.text &&   'Aproveite o máximo do sistema de IA integrado cadastre-se ou entre'}
          </small>
      </div>
    </>}
    {userType === 'profissional' &&
      <div className='text-center mt-3'>
        <Link className='btn btn-secondary rounded-circle py-3 mr-3 ml-3 mb-2'
          style={{ boxShadow: "0px 4px 4px 0px #8094BF, inset  0px 0px 6px 0px #8094BF", cursor: 'pointer', ...props.style }}
          to={'../profissionais' + search}>
          <i className='fa fa-rocket mr-3 ml-3'></i></Link><br />
        <small style={{ ...props.style }}>Comece Agora</small>
      </div>
    }
    {userType === 'paciente' &&
      <div className='text-center mt-3'>
        <Link className='btn btn-secondary rounded-circle py-3 mr-3 ml-3 mb-2'
          style={{ boxShadow: "0px 4px 4px 0px #8094BF, inset  0px 0px 6px 0px #8094BF" }}
          to={'../usuario/cadastro/profissional' + search}>
          <i className='fa fa-rocket mr-3 ml-3'></i></Link><br />
        <small>Comece Agora</small>
      </div>
    }
  </>);
};
export default ButtonCadastrar;