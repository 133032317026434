import React from 'react';
const ProntuarioExpectativaTratamentoInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Expectativa Tratamento</strong></h5>
      <p>
        O componente <strong>Expectativa Tratamento</strong> permite que você adicione e gerencie as expectativas de tratamento em seu prontuário. Este guia judará a utilizar este componente de forma eficaz.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong>Abra o componente</strong></li>
        <p>
          Ao acessar o componente, você verá a seção "Expectativas de Tratamento". Aqui, você pode adicionar, editar ou remover as expectativas de tratamento relacionadas a um paciente.
        </p>

        <li><strong>Adicionar Expectativas</strong></li>
        <p>
          Para adicionar uma nova expectativa de tratamento, clique no botão "Adicionar Expectativa". Isso abrirá um campo de texto onde você pode digitar a nova expectativa. Após inserir a expectativa, clique no botão "Salvar" para adicioná-la à lista.
        </p>

        <li><strong>Editar Expectativas</strong></li>
        <p>
          Se você deseja editar uma expectativa existente, clique no botão "Editar" ao lado dela. O campo de texto será preenchido com a expectativa atual. Realize as edições necessárias e clique em "Salvar" para atualizar a expectativa.
        </p>

        <li><strong>Remover Expectativas</strong></li>
        <p>
          Para remover uma expectativa de tratamento da lista, clique no botão "Remover" ao lado dela. A expectativa será excluída.
        </p>

        <li><strong>Conclusão</strong></li>
        <p>
          Após adicionar, editar ou remover as expectativas de tratamento, você pode continuar utilizando o componente conforme necessário. Lembre-se de clicar em "Salvar" ou "Atualizar" para confirmar suas ações.
        </p>
      </ol>
      <hr />
      <p>
        Agora você está pronto para usar o componente <strong>Expectativa Tratamento</strong> para gerenciar as expectativas de tratamento de forma eficiente. Certifique-se de adicionar/editar/remover as expectativas conforme necessário para manter um registro preciso.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> 

    </div>
  );
};

export default ProntuarioExpectativaTratamentoInfo;
