import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { ReactComponent as RobotIcon } from './help.svg'; // Substitua pelo caminho do seu arquivo SVG

export const ButtonHelp = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <div style={{ position: 'fixed', bottom: '10px', right: '10px', zIndex:'1000' }}> {/* Mudando para a direita */}
      <Tooltip 
        title="Precisa de ajuda? Clique aqui" 
        visible={visible} 
        onMouseEnter={() => setVisible(true)} 
        onMouseLeave={() => setVisible(false)}
      >
        <Button 
          shape="circle" 
          className='btn-success'
          style={{
          
            color: '#fff',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -4px 5px rgba(255, 255, 255, 0.4)', // Sombra externa e interna
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
           width: '80px', // Ajuste o tamanho do botão aqui
            height: '80px', // Ajuste o tamanho do botão aqui
          }}
          onClick={props.onClick}
        >
          <RobotIcon width="40" height="40" /> {/* Controle do tamanho do SVG */}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ButtonHelp;
