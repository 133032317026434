import React, { useEffect, useRef, useState, useMemo } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/addons/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { baseURL } from '../../../../services/api';

export function UniversoImersivo() {
  const containerRef = useRef();
  const stlPath = `${baseURL}UniversoImersivo`; // Substitua pelo caminho real da sua rota Node.js

  const [color, setColor] = useState('#00ff00'); // Cor inicial
  const [lightIntensity, setLightIntensity] = useState(1); // Intensidade da luz inicial

  const meshControls = useRef(null);
  const light = useRef(null);

  const scene = useMemo(() => new THREE.Scene(), []); // Criando a cena uma única vez com useMemo

  useEffect(() => {
    const container = containerRef.current;
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();

    // Defina o tamanho do canvas de renderização para corresponder ao tamanho da janela do navegador
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    // Remova barras de rolagem
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    const loader = new STLLoader();
    loader.load(stlPath, (geometry) => {
      const material = new THREE.MeshPhongMaterial({ color });
      const modelMesh = new THREE.Mesh(geometry, material);
      scene.add(modelMesh);

      const animate = () => {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };

      animate();
    });

    // Adicione uma luz direcional para criar sombras
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    // Adicione uma luz ambiente para iluminar o modelo
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    // Adicione uma luz direcional frontal
    const frontLight = new THREE.DirectionalLight(0xffffff, lightIntensity);
    frontLight.position.set(0, 0, -1); // Aponta diretamente para a frente do objeto
    scene.add(frontLight);
    light.current = frontLight;

    // Adicione OrbitControls para permitir interações de câmera
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = true;
    meshControls.current = controls;

    // Atualize a distância da câmera
    camera.position.z = 5;

    // Limpar recursos quando o componente for desmontado
    return () => {
      const modelMesh = scene.getObjectByName('modelMesh');
      if (modelMesh) {
        modelMesh.geometry.dispose();
        modelMesh.material.dispose();
        scene.remove(modelMesh);
      }
      renderer.dispose();
    };
  }, [stlPath, lightIntensity, color, scene]);

  // Função para atualizar a cor do modelo em tempo real
  const handleColorChange = (newColor) => {
    setColor(newColor);

    // Atualize a cor do material do objeto 3D em tempo real
    const modelMesh = scene.getObjectByName('modelMesh');
    if (modelMesh) {
      modelMesh.material.color.set(newColor);
    }
  };

  // Função para controlar a intensidade da luz frontal
  const handleLightIntensity = (action) => {
    setLightIntensity((prevIntensity) => {
      switch (action) {
        case 'increase':
          return Math.min(prevIntensity + 0.1, 1); // Aumente a intensidade, mas limite-a a 1
        case 'decrease':
          return Math.max(prevIntensity - 0.1, 0); // Diminua a intensidade, mas limite-a a 0
        default:
          return prevIntensity;
      }
    });

    // Atualize a intensidade da luz em tempo real
    if (light.current) {
      light.current.intensity = lightIntensity;
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div>
        <input type="color" value={color} onChange={(e) => handleColorChange(e.target.value)} />
      </div>
      <div>
        <button onClick={() => handleLightIntensity('increase')}>Increase Light</button>
        <button onClick={() => handleLightIntensity('decrease')}>Decrease Light</button>
      </div>
      <div ref={containerRef} style={{ width: '100%', height: '100%', overflow: 'hidden' }}></div>
    </div>
  );
}

export default UniversoImersivo;
