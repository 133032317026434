import React from 'react';

export const MasterCadastroCompanyInfo = () => {
    return (
        <div>
               <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <h5 className='font-weight-bold'><strong>Master Cadastro Compania</strong></h5>
            <p>O componente "Master Cadastro Compania" é uma ferramenta poderosa para gerenciar uma rede de empresas. Ele oferece recursos abrangentes para controlar informações de várias empresas, seus funcionários, clientes e aspectos financeiros, entre outros.</p>
            <hr />
            <br />
            <h5 className='font-weight-bold'><strong>Recursos Principais</strong></h5>
            <ol className='ml-3'>
                <li><strong>Cadastro de Empresas:</strong> Registre e gerencie detalhes da empresa na rede.</li>
                <li><strong>Funcionários:</strong> Associe funcionários à empresa e acompanhe suas informações de contato, cargos e históricos.</li>
                <li><strong>Clientes:</strong> Mantenha um registro de clientes relacionados a cada empresa.</li>
                <li><strong>Dashboard Financeiro:</strong> Acesse um painel financeiro abrangente que oferece insights sobre finanças, receitas e despesas.</li>
                <li><strong>Relatórios Personalizados:</strong> Gere relatórios personalizados para avaliar o desempenho das empresas e tomar decisões informadas.</li>
                <li><strong>Integrações:</strong> Integre com outras ferramentas para facilitar a gestão e análise de dados.</li>
            </ol>
            <hr />
            <br />
            <h5 className='font-weight-bold'><strong>Benefícios</strong></h5>
            <p>Usar o "Master Cadastro Compania" traz diversos benefícios, incluindo:</p>
            <ol className='ml-3'>
                <li>Melhoria na eficiência operacional ao centralizar informações de várias empresas.</li>
                <li>Tomada de decisões estratégicas baseadas em dados financeiros precisos.</li>
                <li>Facilitação da comunicação e colaboração entre funcionários e clientes.</li>
                <li>Aumento da produtividade ao automatizar tarefas de gerenciamento.</li>
                <li>Melhoria na satisfação do cliente ao manter um registro organizado de suas informações.</li>
            </ol>
            <hr />
            <br />
            <p>Com o "Master Cadastro Compania", você estará no controle total de sua rede de empresas, melhorando a eficiência e impulsionando o sucesso financeiro.</p>
        </div>
    );
};

export default MasterCadastroCompanyInfo;
