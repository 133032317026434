import React, { useContext, useEffect, useRef, useState, useCallback  } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Html5QrcodeScanner } from "html5-qrcode";
import { baseURL } from '../../../../../../../../services/api';
import { AuthContext } from '../../../../../../../../contexts/auth';
import { axiosHeadersToken } from '../../../../../../../../services/contexts';
import { MaisInfoMenosInfo, TitleL1 } from '../../../../../../../../components';
const BarcodeScanner = ({ onScanSuccess, onScanError, productInfo, setProductInfo, scannedQRCode, setScannedQRCode }) => {
  const qrcodeRegionId = "html5qr-code-full-region";
  const html5QrcodeScannerRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const { getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();

  const fetchProductInfo = useCallback(async (barcode) => {
    try {
      const response = await axiosHeadersToken().get(`${baseURL}get_products_info_barcode/${professional_id}/${barcode}`);
      const data = response.data; // Usar response.data para acessar os dados
      setProductInfo(data);
      setLoading(false);
      console.log('chamei a API ', barcode);
    } catch (error) {
      console.error('Error fetching product info:', error);
    }
  }, [professional_id, setProductInfo]);

  useEffect(() => {
    const createConfig = (props) => {
      const config = {};
      if (props.fps) {
        config.fps = props.fps;
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox;
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
      }
      return config;
    };

    const config = createConfig({});
    const verbose = false;

    if (!onScanSuccess || !onScanError) {
      throw new Error("onScanSuccess and onScanError are required callbacks.");
    }

    html5QrcodeScannerRef.current = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );

    html5QrcodeScannerRef.current.render(
      (result) => {
        setScannedQRCode(result);
        setLoading(true);
        fetchProductInfo(result);
        onScanSuccess(result);
      },
      onScanError
    );

    return () => {
      if (html5QrcodeScannerRef.current) {
        html5QrcodeScannerRef.current.clear().catch(error => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
      }
    };
  }, [onScanSuccess, onScanError, fetchProductInfo, setScannedQRCode]);

  return (
    <div>
      <div id={qrcodeRegionId} />
      {loading &&<><FaSpinner className="spin" />  <p>Loading product information...</p></>}
      {productInfo && (<>
        <MaisInfoMenosInfo text={<><div>
          <TitleL1>Product Information</TitleL1>
          {scannedQRCode && (
            <p>Scanned QR Code: {scannedQRCode}</p>
          )}
          {Object.entries(productInfo).map(([key, value]) => (
            <p key={key}>{key}: {value}</p>
          ))}
        </div>
        </>} />
      </>)}
    </div>
  );
};

export default BarcodeScanner;
