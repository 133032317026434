import React from 'react';
import { TitleL4, TitleL3 } from '../../../../../../../../../../../components';
import { Typography, Image, } from 'antd';
const { Paragraph } = Typography;
export const DocumentosSoftwareAnaliseFacialAnalisesFrontais = () => (<>
  <TitleL3>Análises Frontais</TitleL3>
  <div>

    <TitleL4>Entrada frontal</TitleL4>
    <div className='row text-center'>

      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/test.jpg')} alt="Exemplo 2" width={100} height={100} /><br />
        <small>Foto frontal</small>
      </div>
    </div>
    <ul>
      <li><strong>Detecção de Pontos Faciais Frontais:</strong> Identificação de pontos de referência no rosto.</li>
      <li><strong>Análise de Características Frontais:</strong> Avaliação de simetria e proporções faciais.</li>
      <li><strong>Sugestões de Harmonização Frontais:</strong> Recomendações para maquiagem, óculos e procedimentos estéticos.</li>
    </ul>
    <div className='row text-center'>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f1.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Pontos</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f2.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Terço H</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f3.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Quintos V</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f4.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Sobrancelha H</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f5.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Sobrancelha V</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f6.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Rugas Manchas</small>

      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f7.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Olhos N. P. Malar</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f8.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Olhos Impl.</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f9.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Boca e Mento</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f10.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Boca V</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f11.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Ang. Mand. Men.</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f12.png')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Terço Inf.</small>
      </div>
      <div className='col-md-3 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/f13.png')} alt="Exemplo 2" width={150} height={50} /><br />
        <small>Espelhamento</small>
      </div>
    </div>
    <section style={{ marginTop: '20px' }}>
      <TitleL4 >Casos de Uso</TitleL4>
      <Paragraph>
        <ul>
          <li><strong>Identificação de Pontos Faciais Frontais: </strong>Identifica pontos de referência no rosto para outras análises.</li>
          <li><strong>Análise de Características Frontais: </strong>Avalia simetria e proporções faciais para entender as características do rosto.</li>
          <li><strong>Sugestões de Harmonização Frontais: </strong>Gera recomendações para maquiagem, escolha de óculos e procedimentos estéticos.</li>
          <li><strong>Análise de Pontos: </strong>Verifica a distribuição e precisão dos pontos detectados.</li>
          <li><strong>Análise do Terço Horizontal (H): </strong>Divide o rosto em terços horizontais para análise de proporções.</li>
          <li><strong>Análise dos Quintos Verticais (V): </strong>Divide o rosto em quintos verticais para verificar simetria.</li>
          <li><strong>Análise da Sobrancelha Horizontal (H): </strong>Examina o alinhamento horizontal das sobrancelhas.</li>
          <li><strong>Análise da Sobrancelha Vertical (V): </strong>Avalia a posição vertical das sobrancelhas em relação aos olhos.</li>
          <li><strong>Detecção de Rugas e Manchas: </strong>Identifica rugas e manchas na pele para análise estética.</li>
          <li><strong>Análise dos Olhos na Região Nasal e Zigomática (N. P. Malar): </strong>Examina a área ao redor dos olhos e a posição nasal.</li>
          <li><strong>Implantação dos Olhos: </strong>Avalia a posição e alinhamento dos olhos.</li>
          <li><strong>Análise de Boca e Mento: </strong>Avalia proporções e simetria entre a boca e o queixo.</li>
          <li><strong>Análise da Posição Vertical da Boca (V): </strong>Verifica o posicionamento vertical da boca.</li>
          <li><strong>Ângulo Mandibular e Região do Mento: </strong>Avalia o ângulo mandibular e a área do queixo.</li>
          <li><strong>Análise do Terço Inferior (Inf.): </strong>Examina o terço inferior do rosto para análise de simetria e proporções.</li>
          <li><strong>Espelhamento: </strong>Realiza a simetria por espelhamento do rosto para visualizar variações simétricas.</li>
        </ul>
      </Paragraph>
    </section>
  </div>
</>);
export default DocumentosSoftwareAnaliseFacialAnalisesFrontais;
