import React from 'react';
import { useEffect, useState } from "react";
import { baseURL } from "../../../../../services/api";
import axios from "axios";
import { ButtonCadastrar, MaisInfoMenosInfo, TitleL1, TitleL2 } from "../../../../../components";
import DadosDeParceriasInfo from "../info/DadosDeParceriasInfo";
export const DadosDeProjetos = () => {
    const [parceriasCadastradas, setParceriasCadastradas] = useState({
        parceriasCadastradas: 0,
        FeedBack: 0,
        EmDesenvolvimento: 0,
        Resolvidas: 0
    });
    const [mediaDiasResposta, setMediaDiasResposta] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}get_partnerships`);
                const data = response.data;
                const quantidadeParcerias = (data.active || 0) + (data.feedback || 0) + (data.development || 0) + (data.resolved || 0);
                const feedbackCount = data.feedback || 0;
                const emDesenvolvimentoCount = data.development || 0;
                const resolvidasCount = data.resolved || 0;
                setParceriasCadastradas({
                    parceriasCadastradas: quantidadeParcerias,
                    FeedBack: feedbackCount,
                    EmDesenvolvimento: emDesenvolvimentoCount,
                    Resolvidas: resolvidasCount
                });
            } catch (error) {
                setParceriasCadastradas({
                    parceriasCadastradas: 0,
                    FeedBack: 0,
                    EmDesenvolvimento: 0,
                    Resolvidas: 0
                });
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const obterMediaDiasResposta = async () => {
            try {
                const response = await axios.get(`${baseURL}get_partnerships_report_average`);
                const mediaDiasResposta = response.data.mediaDiasResposta || 0;
                setMediaDiasResposta(mediaDiasResposta);
            } catch (error) {
                console.error('Erro ao obter a média de dias de resposta:', error);
                setMediaDiasResposta(0);
            }
        };
        obterMediaDiasResposta();
    }, []);
    return (<>
        <section className='mr-3 ml-3'style={{ minHeight: '95dvh' }}>
            <p className='text-center'>  <img
                src={require('../assets/ImagensDoSistema.png')}
                alt="Liberdade Financeira"

                style={{ maxHeight: '280px', maxWidth: '580px', minHeight: "280px" }}
                className=' col-md-12'
            />
            </p>
            <TitleL1>Dados de Projetos</TitleL1 >

            <h5 className='text-justify'>Aqui mostramos o andamento dos projetos já cadastradas no sistema
                como uma forma de informativo em tempo real para o publico geral.
                O intuito é que todos possam acompanhar nossos resultados de forma simples e horizontal, fazendo a divisão de responsabilidade. O simples é sempre genial!
            </h5><br />
            <h5 className='font-weight-bold mt-3'> <strong>Painel de Resolução de Projetos</strong></h5>
            <div className='mb-5 d-flex flex-wrap justify-content-center'>
                <div className="col-md-3 mt-3 rounded text-center py-4"                   >
                    <div className='border py-2 rounded'>
                        <i className="fa-3x">🗳️</i>
                        <h3 className="font-weight-bold py-3">{parceriasCadastradas.parceriasCadastradas}</h3>
                        <TitleL2>Cadastros</TitleL2>
                    </div>
                </div>
                <div className="col-md-3 mt-3 rounded text-center py-4"
                >   <div className='border py-2 rounded'>
                        <i className="fa-3x">📬</i>
                        <h3 className="font-weight-bold py-3">{parceriasCadastradas.FeedBack}</h3>
                        <TitleL2>FeedBack</TitleL2>
                    </div>
                </div>
                <div className="col-md-3 mt-3 rounded text-center py-4"
                >   <div className='border py-2 rounded'>
                        <i className="fa-3x">⚙️</i>
                        <h3 className="font-weight-bold py-3">{parceriasCadastradas.EmDesenvolvimento}</h3>
                        <TitleL2>Em Dev.</TitleL2>
                    </div>
                </div>
                <div className="col-md-3 mt-3 rounded text-center py-4"
                >   <div className='border py-2 rounded'>
                        <i className="fa-3x">📨</i>
                        <h3 className="font-weight-bold py-3">{parceriasCadastradas.Resolvidas}</h3>
                        <TitleL2>Resolvidos</TitleL2>
                    </div>
                </div>
                <div className="col-md-3 mt-3 rounded text-center  py-4">
                    <div className='border py-2 rounded'>
                        <i className="fa-3x">🗓️</i>
                        <h3 className="font-weight-bold py-3">{mediaDiasResposta}</h3>
                        <TitleL2>Média Dias Resp.</TitleL2>
                    </div>
                </div>
            </div>
            <div className='py-4 text-center'>
                <MaisInfoMenosInfo text={<>
                    <DadosDeParceriasInfo
                        parceriasCadastradas={parceriasCadastradas.parceriasCadastradas}
                        feedBack={parceriasCadastradas.FeedBack}
                        EmDesenvolvimento={parceriasCadastradas.EmDesenvolvimento}
                        Resolvidas={parceriasCadastradas.Resolvidas}
                        mediaDiasResposta={mediaDiasResposta}
                    /></>} />
            </div>
            <ButtonCadastrar/>
        </section>
    </>)
}