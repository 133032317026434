import { TitleL2 } from "../../../../../components"
import { MdBook } from "react-icons/md";
export const ReconhecimentosCard = (props) => {
    return (<>
        <div className="col-md-4 mt-2"
            style={{
                padding: '5px',
            }}>
            <div className="border rounded">
                <div className="card text-center ">
                    <div className=" justify-content-center align-items-center d-flex mx-auto rounded" style={{
                        maxHeight: '180px',
                        minHeight: '180px',
                        maxWidth: '180px',
                        minWidth: '180px',
                    }}>
                        <img src={props.imageSource} className="card-img-top rounded py-3" alt={props.name} />
                    </div>
                    <div className="card-body">
                        <TitleL2>{props.name}</TitleL2>
                        <p className="card-text text-justify " style={{
                            overflow: "hidden",
                            marginBottom: "8px",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            width: '100%',
                        }}>{props.title}</p>
                        <div className="row justify-content-center">
                            <a className='text-primary '
                                href={props.href}
                                target={'_blank'} rel={'noopener noreferrer'}
                                style={{ cursor: 'pointer' }}>
                                <h5 className='text-center '><i className='fa fa-trophy fa-3x mx-auto text-warning'></i></h5>
                                <b> &nbsp;link</b>
                            </a>
                            <a className=' text-center text-primary '
                                href={props.href_dois}
                                style={{ cursor: 'pointer' }}>
                                <h5 className='text-center '> <MdBook style={{ scale: '4', marginTop: '22px', marginBottom: '18px', marginLeft: '30px' }} /></h5>
                                <b className="text-center ml-3"> &nbsp;link</b>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}