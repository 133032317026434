import { baseURL } from "../../../../../api";
import { axiosHeadersToken } from '../../../../../contexts';
export const fetchUserPhoto = async (id, setImageUrl) => {
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  // alert(user.imageUrlsUser)
  // Verifica se user.imageUrlUser não é null antes de buscar a imagem
  if (user.imageUrlsUser !== null) {
    try {
      const response = await axiosHeadersToken().get(`${baseURL}user/${id}/photo`, {
        responseType: 'blob' // Define o tipo de resposta como blob
      });
      const imageUrl = URL.createObjectURL(response.data);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error('Erro ao buscar a imagem do usuário', error);
    }
  }
};
