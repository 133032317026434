import React from 'react';
import { useLocation } from 'react-router-dom';
import { baseURL } from '../../services/api';

const CustomHelmet = () => {
  const location = useLocation();

  React.useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `
      function atualizarTagsOG(data) {
        document.querySelector('meta[property="og:title"]').setAttribute('content', data.company_name);
        document.querySelector('meta[property="og:description"]').setAttribute('content', data.products_name + ' - ' + data.product_description);
        document.querySelector('meta[property="og:image"]').setAttribute('content', data.products_image);
        document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
        document.querySelector('link[itemprop="url"]').setAttribute('href', data.products_image);
        document.querySelector('span[itemprop="image"] link[itemprop="url"]').setAttribute('href', data.products_image);
        document.querySelector('meta[property="twitter:image"]').setAttribute('content', data.products_image);
        document.getElementById('ogImg').setAttribute('href', data.products_image);
        document.getElementById('ogImgUrl').setAttribute('content', data.products_image);
      }
      function chamarAPI(productId) {
        axios.get('${baseURL}get_products_id/' + productId)
          .then(response => {
            atualizarTagsOG(response.data);
          })
          .catch(error => {
            console.error('Erro ao chamar a API:', error);
          });
      }
      function verificarURL() {
        const url = window.location.href;
        if (url.includes('/produtos/')) {
          const productId = url.split('/produtos/')[1];
          chamarAPI(productId);
          return true;
        }
        return false;
      }
      document.addEventListener('DOMContentLoaded', () => {
        verificarURL();
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

};

export default CustomHelmet;
