import React from 'react';

const ProntuarioEstaEmTratamentoInfo = () => {
  return (
    <div>

      <h5>Usando o Componente <strong> Tratamento Médico</strong></h5>
      <p>
        O componente <strong>Tratamento Médico</strong> permite que você registre suas queixas principais de saúde. Você pode adicionar, editar e remover queixas conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr/>
      <ol className='ml-3'>
        <li><strong>Marcar se está em tratamento médico</strong>
        </li>
        <p>
          Quando você abrir este componente, você verá a pergunta "Está atualmente em tratamento médico?" e duas opções: "Sim" e "Não". Marque a opção que corresponde à situação do paciente.
        </p>
        <li><strong> Adicionar informações de tratamento</strong>
        </li>
        <p>
          Se você marcar "Sim", uma seção adicional aparecerá onde você pode adicionar informações sobre o tratamento. Digite os detalhes do tratamento, como o nome do medicamento ou procedimento, no campo de texto fornecido.
        </p>
        <li><strong>Adicionar ou editar tratamentos</strong>
          <p>
            Você pode adicionar tratamentos clicando no botão "Adicionar" após inserir os detalhes. Para editar tratamentos existentes, clique no botão "Editar" ao lado do tratamento que deseja modificar.
          </p>
        </li>
        <li><strong>Remover tratamentos</strong>
          <p>
            Se você deseja remover um tratamento da lista, clique no botão "Remover" correspondente.
          </p>
        </li>
        <li><strong>Finalização</strong>
        </li>
        <p>
          Após adicionar, editar ou remover informações de tratamento, você pode clicar no botão "Enviar Informações" para concluir o registro das informações de tratamento médico do paciente.
        </p>
      </ol>
      <hr/>
      <p>
        Agora você está pronto para usar o componente <strong> Tratamento Médico</strong> para registrar seus tratamentos médicos. Certifique-se de adicionar/editar/remover os tratamentos médicos conforme necessário.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br /> 
    </div>
  );
};

export default ProntuarioEstaEmTratamentoInfo;
