import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import ProfessionalUnitCard from './ProfessionalUnitCard';
import { baseURL } from '../../../../../../../services/api';
import { AuthContext } from '../../../../../../../contexts/auth';
export const CadastrarProfissionalUnit = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [filteredProfessionals, setFilteredProfessionals] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const { getUserId, getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();
  const user_id = getUserId()
  const fetchProfessionals = useCallback(() => {
    const token = localStorage.getItem('token');
    axios
      .get(`${baseURL}get_professional`, {
        params: {
          page: currentPage,
          type: selectedType,
          city: selectedCity,
          state: selectedState,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const { data } = response;
        const professionalsToShow = data.professionals;
        const totalPages = data.totalPages;
        setProfessionals(professionalsToShow);
        setFilteredProfessionals(professionalsToShow.slice(0, 10));
        setTotalPages(totalPages);
      })
      .catch(error => {
        console.error(error);
      });
  }, [currentPage, selectedType, selectedCity, selectedState]);

  useEffect(() => {
    fetchProfessionals();
  }, [fetchProfessionals]);
  const cadastrarProfissionalUnit = async (professionalId, professionalType, selectedCBOId ) => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${baseURL}post_professional_unit/${user_id}/${professional_id}`, {
        professionals: [
          {
            professional_id: professionalId,
            professional_type: professionalType,
            professional_cbo: selectedCBOId,
            company_unit_id: 1, 
          },
        ],
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert(response.data.message);
      setIsLoading(false);
    } catch (error) {
      alert('Erro ao cadastrar o profissional');
      setIsLoading(false);
    }
  };
  const handleTypeChange = e => {
    setSelectedType(e.target.value);
    setCurrentPage(1);
  };
  const handleCityChange = e => {
    setSelectedCity(e.target.value);
    setCurrentPage(1);
  };
  const handleStateChange = e => {
    setSelectedState(e.target.value);
    setCurrentPage(1);
  };
  const handleDelete = id => {
  };
  const handleStatusChange = id => {
  };
  const handleEdit = updatedData => {
  };
  const handleAddProfessional = (professionalId, professionalType, selectedCBOId) => {
    cadastrarProfissionalUnit(professionalId, professionalType, selectedCBOId);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleFilter = () => {
    const filtered = professionals.filter(professional => {
      if (
        (selectedType === '' || professional.titulo === selectedType) &&
        (selectedCity === '' || professional.city === selectedCity) &&
        (selectedState === '' || professional.state === selectedState) &&
        (professional.professional_name.toLowerCase().includes(searchText.toLowerCase()))
      ) {
        return true;
      }
      return false;
    });
    setFilteredProfessionals(filtered.slice(0, 12));
    setTotalPages(Math.ceil(filtered.length / 12));
    setCurrentPage(1);
  };
  const handleSearchTextChange = e => {
    setSearchText(e.target.value);
    setCurrentPage(1);
    handleFilter();
  };
  const getUniqueValues = (property) => {
    return [...new Set(professionals.map((professional) => professional[property]))];
  };
  return (<>
    <div className="text-center d-flex justify-content-center mt-5 col-lg-12 boxFooter bg-primary TridimencionalSupLat">
      <h5 className="text-white mt-2">
        <b>{props.title}</b>
      </h5>
    </div>
    <div className="py-3 CardTHOT bg-light">
      <div className=' input-group-append'>
        <input
          type="text"
          placeholder="Buscar profissionais"
          value={searchText}
          onChange={handleSearchTextChange}
        />
        <select className="custom-select text-center border border-dark mx-auto font-weight-bold rounded" value={selectedType} onChange={handleTypeChange}>
          <option value="">Selecione o Tipo</option>
          {getUniqueValues('titulo').map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        <select className="custom-select text-center border border-dark mx-auto font-weight-bold rounded" value={selectedCity} onChange={handleCityChange}>
          <option value="">Selecione a Cidade</option>
          {getUniqueValues('city').map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
        <select className="custom-select text-center border border-dark mx-auto font-weight-bold rounded" value={selectedState} onChange={handleStateChange}>
          <option value="">Selecione o Estado</option>
          {getUniqueValues('state').map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-12 mt-2">
        <div className="d-flex flex-wrap justify-content-center">
          {filteredProfessionals.map((professional, index) => (
            <ProfessionalUnitCard
              key={`${professional.professional_id}-${index}`}
              professional={professional}
              onDelete={handleDelete}
              onStatusChange={handleStatusChange}
              onEdit={handleEdit}
              onAddProfessional={handleAddProfessional}
            />
          ))}
        </div>
      </div>
      <div className="col-md-12 text-center mt-4">
        <button className='btn btn-primary' onClick={handlePreviousPage} disabled={currentPage === 1 || isLoading}>
          {isLoading ? 'Buscando...' : 'Anterior'}
        </button>
        <span className='mr-1 ml-1 text-dark'>{currentPage}</span>
        <button className='btn btn-primary' onClick={handleNextPage} disabled={currentPage === totalPages || isLoading}>
          {isLoading ? 'Buscando...' : 'Próxima'}
        </button>
        <div className='mt-2'>
          <small>Página atual: {currentPage}</small>
          <small className='ml-2'>Total Pag.: {totalPages}</small>
        </div>
      </div>
    </div>
    <div className="boxHeader text-center mb-5 d-flex justify-content-center align-items-center bg-primary TridimencionalInfLagerLat">
      <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
        <b>...</b>
      </h5>
    </div>
  </>);
};
export default CadastrarProfissionalUnit;