import React, { useState } from "react";
import { Link } from "react-router-dom";

import unorm from 'unorm'; // Biblioteca para normalização Unicode

export const NomePatologias = ({ setCentralFolderName, filtro }) => {
    const [resultadosPorPagina] = useState(10);
    const [nomePatologias] = useState(
        [
            "A00 - Cólera",
            "A00.0 - Cólera Devida a Vibrio Cholerae 01, Biótipo Cholerae",
            "A00.1 - Cólera Devida a Vibrio Cholerae 01, Biótipo El Tor",
            "A00.9 - Cólera Não Especificada",
            "A01 - Febres Tifóide e Paratifóide",
            "A01.0 - Febre Tifóide",
            "A01.1 - Febre Paratifóide A",
            "A01.2 - Febre Paratifóide B",
            "A01.3 - Febre Paratifóide C",
            "A01.4 - Febre Paratifóide Não Especificada",
            "A02 - Outras Infecções Por Salmonella",
            "A02.0 - Enterite Por Salmonela",
            "A02.1 - Septicemia Por Salmonela",
            "A02.2 - Infecções Localizadas Por Salmonela",
            "A02.8 - Outras Infecções Especificadas Por Salmonela",
            "A02.9 - Infecção Não Especificada Por Salmonela",
            "A03 - Shiguelose",
            "A03.0 - Shiguelose Devida a Shigella Dysenteriae",
            "A03.1 - Shiguelose Devida a Shigella Flexneri",
            "A03.2 - Shiguelose Devida a Shigella Boydii",
            "A03.3 - Shiguelose Devida a Shigella Sonnei",
            "A03.8 - Outras Shigueloses",
            "A03.9 - Shiguelose Não Especificada",
            "A04 - Outras Infecções Intestinais Bacterianas",
            "A04.0 - Infecção Por Escherichia Coli Enteropatogênica",
            "A04.1 - Infecção Por Escherichia Coli Enterotoxigênica",
            "A04.2 - Infecção Por Escherichia Coli Enteroinvasiva",
            "A04.3 - Infecção Por Escherichia Coli Enterohemorrágica",
            "A04.4 - Outras Infecções Intestinais Por Escherichia Coli",
            "A04.5 - Enterite Por Campylobacter",
            "A04.6 - Enterite Devida a Yersinia Enterocolítica",
            "A04.7 - Enterocolite Devida a Clostridium Difficile",
            "A04.8 - Outras Infecções Bacterianas Intestinais Especificadas",
            "A04.9 - Infecção Intestinal Bacteriana Não Especificada",
            "A05 - Outras Intoxicações Alimentares Bacterianas, Não Classificadas em Outra Parte",
            "A05.0 - Intoxicação Alimentar Estafilocócica",
            "A05.1 - Botulismo",
            "A05.2 - Intoxicação Alimentar Devida a Clostridium Perfringens (Clostridium Welchii)",
            "A05.3 - Intoxicação Alimentar Devida a Vibrio Parahemolyticus",
            "A05.4 - Intoxicação Alimentar Devida a Bacillus Cereus",
            "A05.8 - Outras Intoxicações Alimentares Bacterianas Especificadas",
            "A05.9 - Intoxicação Alimentar Bacteriana Não Especificada",
            "A06 - Amebíase",
            "A06.0 - Disenteria Amebiana Aguda",
            "A06.1 - Amebíase Intestinal Crônica",
            "A06.2 - Colite Amebiana Não-disentérica",
            "A06.3 - Ameboma Intestinal",
            "A06.4 - Abscesso Amebiano do Fígado",
            "A06.5 - Abscesso Amebiano do Pulmão",
            "A06.6 - Abscesso Amebiano do Cérebro",
            "A06.7 - Amebíase Cutânea",
            "A06.8 - Infecção Amebiana de Outras Localizações",
            "A06.9 - Amebíase Não Especificada",
            "A07 - Outras Doenças Intestinais Por Protozoários",
            "A07.0 - Balantidíase",
            "A07.1 - Giardíase (lamblíase)",
            "A07.2 - Criptosporidiose",
            "A07.3 - Isosporíase",
            "A07.8 - Outras Doenças Intestinais Especificadas Por Protozoários",
            "A07.9 - Doença Intestinal Não Especificada Por Protozoários",
            "A08 - Infecções Intestinais Virais, Outras e as Não Especificadas",
            "A08.0 - Enterite Por Rotavírus",
            "A08.1 - Gastroenteropatia Aguda Pelo Agente de Norwalk",
            "A08.2 - Enterite Por Adenovírus",
            "A08.3 - Outras Enterites Virais",
            "A08.4 - Infecção Intestinal Devida a Vírus Não Especificado",
            "A08.5 - Outras Infecções Intestinais Especificadas",
            "A09 - Diarréia e Gastroenterite de Origem Infecciosa Presumível",
            "A15 - Tuberculose Respiratória, Com Confirmação Bacteriológica e Histológica",
            "A15.0 - Tuberculose Pulmonar, Com Confirmação Por Exame Microscópico da Expectoração, Com ou Sem Cultura",
            "A15.1 - Tuberculose Pulmonar, Com Confirmação Somente Por Cultura",
            "A15.2 - Tuberculose Pulmonar, Com Confirmação Histológica",
            "A15.3 - Tuberculose Pulmonar, Com Confirmação Por Meio Não Especificado",
            "A15.4 - Tuberculose Dos Gânglios Intratorácicos, Com Confirmação Bacteriológica e Histológica",
            "A15.5 - Tuberculose da Laringe, da Traquéia e Dos Brônquios, Com Confirmação Bacteriológica e Histológica",
            "A15.6 - Pleuris Tuberculoso, Com Confirmação Bacteriológica e Histológica",
            "A15.7 - Tuberculose Primária Das Vias Respiratórias, Com Confirmação Bacteriológica e Histológica",
            "A15.8 - Outras Formas de Tuberculose Das Vias Respiratórias, Com Confirmação Bacteriológica e Histológica",
            "A15.9 - Tuberculose Não Especificada Das Vias Respiratórias, Com Confirmação Bacteriológica e Histológica",
            "A16 - Tuberculose Das Vias Respiratórias, Sem Confirmação Bacteriológica ou Histológica",
            "A16.0 - Tuberculose Pulmonar Com Exames Bacteriológico e Histológico Negativos",
            "A16.1 - Tuberculose Pulmonar, Sem Realização de Exame Bacteriológico ou Histológico",
            "A16.2 - Tuberculose Pulmonar, Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A16.3 - Tuberculose Dos Gânglios Intratorácicos, Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A16.4 - Tuberculose da Laringe, da Traquéia e Dos Brônquios, Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A16.5 - Pleurisia Tuberculosa, Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A16.7 - Tuberculosa Respiratória Primária Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A16.8 - Outras Formas de Tuberculose Das Vias Respiratórias, Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A16.9 - Tuberculose Respiratória, Não Especificada, Sem Menção de Confirmação Bacteriológica ou Histológica",
            "A17 - Tuberculose do Sistema Nervoso",
            "A17.0 - Meningite Tuberculosa",
            "A17.1 - Tuberculoma Meníngeo",
            "A17.8 - Outras Tuberculoses do Sistema Nervoso",
            "A17.9 - Tuberculose Não Especificada do Sistema Nervoso",
            "A18 - Tuberculose de Outros Órgãos",
            "A18.0 - Tuberculose Óssea e Das Articulações",
            "A18.1 - Tuberculose do Aparelho Geniturinário",
            "A18.2 - Linfadenopatia Tuberculosa Periférica",
            "A18.3 - Tuberculose do Intestino, do Peritônio e Dos Gânglios Mesentéricos",
            "A18.4 - Tuberculose de Pele e do Tecido Celular Subcutâneo",
            "A18.5 - Tuberculose do Olho",
            "A18.6 - Tuberculose do Ouvido",
            "A18.7 - Tuberculose Das Supra-renais",
            "A18.8 - Tuberculose de Outros Órgãos Especificados",
            "A19 - Tuberculose Miliar",
            "A19.0 - Tuberculose Miliar Aguda de Localização Única e Especificada",
            "A19.1 - Tuberculose Miliar Aguda de Múltiplas Localizações",
            "A19.2 - Tuberculose Miliar Aguda Não Especificada",
            "A19.8 - Outras Tuberculoses Miliares",
            "A19.9 - Tuberculose Miliar Não Especificada",
            "A20 - Peste",
            "A20.0 - Peste Bubônica",
            "A20.1 - Peste Celulocutânea",
            "A20.2 - Peste Pneumônica",
            "A20.3 - Peste Meníngea",
            "A20.7 - Peste Septicêmica",
            "A20.8 - Outras Formas de Peste",
            "A20.9 - Peste, Forma Não Especificada",
            "A21 - Tularemia",
            "A21.0 - Tularemia Ulceroglandular",
            "A21.1 - Tularemia Oculoglandular",
            "A21.2 - Tularemia Pulmonar",
            "A21.3 - Tularemia Gastrointestinal",
            "A21.7 - Tularemia Generalizada",
            "A21.8 - Outras Formas de Tularemia",
            "A21.9 - Tularemia, Forma Não Especificada",
            "A22 - Carbúnculo",
            "A22.0 - Carbúnculo Cutâneo",
            "A22.1 - Carbúnculo Pulmonar",
            "A22.2 - Carbúnculo Gastrointestinal",
            "A22.7 - Septicemia Carbunculosa",
            "A22.8 - Outras Formas de Carbúnculo",
            "A22.9 - Carbúnculo, Forma Não Especificada",
            "A23 - Brucelose",
            "A23.0 - Brucelose Por Brucella Melitensis",
            "A23.1 - Brucelose Por Brucella Abortus",
            "A23.2 - Brucelose Por Brucella Suis",
            "A23.3 - Brucelose Por Brucella Canis",
            "A23.8 - Outras Bruceloses",
            "A23.9 - Brucelose Não Especificada",
            "A24 - Mormo e Melioidose",
            "A24.0 - Mormo",
            "A24.1 - Melioidose Aguda e Fulminante",
            "A24.2 - Melioidose Subaguda e Crônica",
            "A24.3 - Outras Melioidoses",
            "A24.4 - Melioidose Não Especificada",
            "A25 - Febres Transmitidas Por Mordedura de Rato",
            "A25.0 - Espirilose",
            "A25.1 - Estreptobacilose",
            "A25.9 - Febre Transmitida Por Mordedura de Rato, Tipo Não Especificado",
            "A26 - Erisipelóide",
            "A26.0 - Erisipelóide Cutâneo",
            "A26.7 - Septicemia Por Erysipelothrix",
            "A26.8 - Outras Formas de Erisipelóide",
            "A26.9 - Erisipelóide Não Especificado",
            "A27 - Leptospirose",
            "A27.0 - Leptopirose Icterohemorrágica",
            "A27.8 - Outras Formas de Leptospirose",
            "A27.9 - Leptospirose Não Especificada",
            "A28 - Outras Doenças Bacterianas Zoonóticas Não Classificadas em Outra Parte",
            "A28.0 - Pasteurelose",
            "A28.1 - Doença Por Arranhadura do Gato",
            "A28.2 - Yersiniose Extra-intestinal",
            "A28.8 - Outras Doenças Bacterianas Zoonóticas Especificadas Não Classificadas em Outra Parte",
            "A28.9 - Doença Bacteriana Zoonótica Não Especificada",
            "A30 - Hanseníase (doença de Hansen) (lepra)",
            "A30.0 - Hanseníase (lepra) Indeterminada",
            "A30.1 - Hanseníase (lepra) Tuberculóide",
            "A30.2 - Hanseníase (lepra) Tuberculóide Borderline",
            "A30.3 - Hanseníase (lepra) Dimorfa",
            "A30.4 - Hanseníase (lepra) Lepromatosa Borderline",
            "A30.5 - Hanseníase (lepra) Lepromatosa",
            "A30.8 - Outras Formas de Hanseníase (lepra)",
            "A30.9 - Hanseníase (lepra) Não Especificada",
            "A31 - Infecções Devidas a Outras Micobactérias",
            "A31.0 - Infecção Pulmonar Micobacteriana",
            "A31.1 - Infecção Cutânea Micobacteriana",
            "A31.8 - Outras Infecções Micobacterianas",
            "A31.9 - Infecção Micobacteriana Não Especificada",
            "A32 - Listeriose (listeríase)",
            "A32.0 - Listeriose Cutânea",
            "A32.1 - Meningite e Meningoencefalite Por Listéria",
            "A32.7 - Septicemia Listeriótica",
            "A32.8 - Outras Formas de Listeriose",
            "A32.9 - Listeriose Não Especificada",
            "A33 - Tétano do Recém-nascido (neonatal)",
            "A34 - Tétano Obstétrico",
            "A35 - Outros Tipos de Tétano",
            "A36 - Difteria",
            "A36.0 - Difteria Faríngea",
            "A36.1 - Difteria Nasofaríngea",
            "A36.2 - Difteria Laríngea",
            "A36.3 - Difteria Cutânea",
            "A36.8 - Outras Formas de Difteria",
            "A36.9 - Difteria Não Especificada",
            "A37 - Coqueluche",
            "A37.0 - Coqueluche Por Bordetella Pertussis",
            "A37.1 - Coqueluche Por Bordetella Parapertussis",
            "A37.8 - Coqueluche Por Outras Espécies da Bordetella",
            "A37.9 - Coqueluche Não Especificada",
            "A38 - Escarlatina",
            "A39 - Infecção Meningogócica",
            "A39.0 - Meningite Meningocócica",
            "A39.1 - Síndrome de Waterhouse-Friderichsen",
            "A39.2 - Meningococcemia Aguda",
            "A39.3 - Meningococcemia Crônica",
            "A39.4 - Meningococcemia Não Especificada",
            "A39.5 - Cardite Por Meningococos",
            "A39.8 - Outras Infecções Por Meningococos",
            "A39.9 - Infecção Meningocócica Não Especificada",
            "A40 - Septicemia Estreptocócica",
            "A40.0 - Septicemia Por Streptococcus do Grupo A",
            "A40.1 - Septicemia Por Streptococcus do Grupo B",
            "A40.2 - Septicemia Por Streptococcus do Grupo D",
            "A40.3 - Septicemia Por Streptococcus Pneumonia",
            "A40.8 - Outras Septicemias Estreptocócicas",
            "A40.9 - Septicemia Estreptocócica Não Especificada",
            "A41 - Outras Septicemias",
            "A41.0 - Septicemia Por Staphylococcus Aureus",
            "A41.1 - Septicemia Por Outros Estafilococos Especificados",
            "A41.2 - Septicemia Por Estafilococos Não Especificados",
            "A41.3 - Septicemia Por Haemophilus Influenzae",
            "A41.4 - Septicemia Por Anaeróbios",
            "A41.5 - Septicemia Por Outros Microorganismos Gram-negativos",
            "A41.8 - Outras Septicemias Especificadas",
            "A41.9 - Septicemia Não Especificada",
            "A42 - Actinomicose",
            "A42.0 - Actinomicose Pulmonar",
            "A42.1 - Actinomicose Abdominal",
            "A42.2 - Actinomicose Cervicofacial",
            "A42.7 - Septicemia Actinomicótica",
            "A42.8 - Outras Formas de Actinomicose",
            "A42.9 - Actinomicose Não Especificada",
            "A43 - Nocardiose",
            "A43.0 - Nocardiose Pulmonar",
            "A43.1 - Nocardiose Cutânea",
            "A43.8 - Outras Formas de Nocardiose",
            "A43.9 - Nocardiose Não Especificada",
            "A44 - Bartonelose",
            "A44.0 - Bartonelose Sistêmica",
            "A44.1 - Bartonelose Cutânea e Cutâneo-mucosa",
            "A44.8 - Outras Formas de Bartonelose",
            "A44.9 - Bartonelose Não Especificada",
            "A46 - Erisipela",
            "A48 - Outras Doenças Bacterianas Não Classificadas em Outra Parte",
            "A48.0 - Gangrena Gasosa",
            "A48.1 - Doença Dos Legionários",
            "A48.2 - Doença Dos Legionários Não-pneumônica (febre de Pontiac)",
            "A48.3 - Síndrome do Choque Tóxico",
            "A48.4 - Febre Purpúrica do Brasil",
            "A48.8 - Outras Doenças Bacterianas Especificadas",
            "A49 - Infecção Bacteriana de Localização Não Especificada",
            "A49.0 - Infecção Estafilocócica de Localização Não Especificada",
            "A49.1 - Infecção Estreptocócica de Localização Não Especificada",
            "A49.2 - Infecção Por Haemophilus Influenzae de Localização Não Especificada",
            "A49.3 - Infecção Por Mycoplasma de Localização Não Especificada",
            "A49.8 - Outras Infecções Bacterianas de Localização Não Especificada",
            "A49.9 - Infecção Bacteriana Não Especificada",
            "A50 - Sífilis Congênita",
            "A50.0 - Sífilis Congênita Precoce Sintomática",
            "A50.1 - Sífilis Congênita Precoce, Forma Latente",
            "A50.2 - Sífilis Congênita Precoce Não Especificada",
            "A50.3 - Oculopatia Sifilítica Congênita Tardia",
            "A50.4 - Neurossífilis Congênita Tardia (neurossífilis Juvenil)",
            "A50.5 - Outras Formas Tardias e Sintomáticas da Sífilis Congênita",
            "A50.6 - Sífilis Congênita Tardia Latente",
            "A50.7 - Sífilis Congênita Tardia Não Especificada",
            "A50.9 - Sífilis Congênita Não Especificada",
            "A51 - Sífilis Precoce",
            "A51.0 - Sífilis Genital Primária",
            "A51.1 - Sífilis Anal Primária",
            "A51.2 - Sífilis Primária de Outras Localizações",
            "A51.3 - Sífilis Secundária da Pele e Das Mucosas",
            "A51.4 - Outras Formas de Sífilis Secundária",
            "A51.5 - Sífilis Precoce Latente",
            "A51.9 - Sífilis Precoce Não Especificada",
            "A52 - Sífilis Tardia",
            "A52.0 - Sífilis Cardiovascular",
            "A52.1 - Neurossífilis Sintomática",
            "A52.2 - Neurossífilis Assintomática",
            "A52.3 - Neurossífilis Não Especificada",
            "A52.7 - Outras Formas de Sífilis Tardia Sintomática",
            "A52.8 - Sífilis Tardia Latente",
            "A52.9 - Sífilis Tardia Não Especificada",
            "A53 - Outras Formas e as Não Especificadas da Sífilis",
            "A53.0 - Sífilis Latente, Não Especificada se Recente ou Tardia",
            "A53.9 - Sífilis Não Especificada",
            "A54 - Infecção Gonocócica",
            "A54.0 - Infecção Gonocócica do Trato Geniturinário Inferior, Sem Abscesso Periuretral ou Das Glândulas Acessórias",
            "A54.1 - Infecção Gonocócica do Trato Geniturinário Inferior, Com Abscesso Periuretral ou Das Glândulas Acessórias",
            "A54.2 - Pelviperitonite Gonocócica e Outras Infecções Geniturinárias Gonocócicas",
            "A54.3 - Infecção Gonocócica do Olho",
            "A54.4 - Infecção Gonocócica do Sistema Músculo-esquelético",
            "A54.5 - Faringite Gonocócica",
            "A54.6 - Infecção Gonocócica do Ânus ou do Reto",
            "A54.8 - Outras Infecções Gonocócicas",
            "A54.9 - Infecção Gonocócica Não Especificada",
            "A55 - Linfogranuloma (venéreo) Por Clamídia",
            "A56 - Outras Infecções Causadas Por Clamídias Transmitidas Por Via Sexual",
            "A56.0 - Infecções Por Clamídias do Trato Geniturinário Inferior",
            "A56.1 - Infecção Por Clamídias, Pelviperitonial e de Outros Órgãos Geniturinários",
            "A56.2 - Infecção Por Clamídias do Trato Geniturinário, Localização Não Especificada",
            "A56.3 - Infecção do Ânus e do Reto Por Clamídias",
            "A56.4 - Infecção da Faringe Por Clamídias",
            "A56.8 - Infecção Por Clamídias Transmitida Por Via Sexual, de Outras Localizações",
            "A57 - Cancro Mole",
            "A58 - Granuloma Inguinal",
            "A59 - Tricomoníase",
            "A59.0 - Tricomoníase Urogenital",
            "A59.8 - Outras Localizações de Tricomoníase",
            "A59.9 - Tricomoníase Não Especificada",
            "A60 - Infecções Anogenitais Pelo Vírus do Herpes (herpes Simples)",
            "A60.0 - Infecção Dos Órgãos Genitais e do Trato Geniturinário Pelo Vírus do Herpes",
            "A60.1 - Infecção da Margem Cutânea do Ânus e do Reto Pelo Vírus do Herpes",
            "A60.9 - Infecção Anogenital Não Especificada Pelo Vírus do Herpes",
            "A63 - Outras Doenças de Transmissão Predominantemente Sexual, Não Classificadas em Outra Parte",
            "A63.0 - Verrugas Anogenitais (venéreas)",
            "A63.8 - Outras Doenças Especificadas de Transmissão Predominantemente Sexual",
            "A64 - Doenças Sexualmente Transmitidas, Não Especificadas",
            "A65 - Sífilis Não-venérea",
            "A66 - Bouba",
            "A66.0 - Lesões Iniciais da Bouba",
            "A66.1 - Papilomas Múltiplos e Bouba Plantar Úmida (cravo de Bouba)",
            "A66.2 - Outras Lesões Cutâneas Precoces da Bouba",
            "A66.3 - Hiperceratose Devida a Bouba",
            "A66.4 - Gomas e Úlceras Devidas à Bouba",
            "A66.5 - Gangosa",
            "A66.6 - Lesões Osteoarticulares Devidas à Bouba",
            "A66.7 - Outras Manifestações da Bouba",
            "A66.8 - Bouba Latente",
            "A66.9 - Bouba Não Especificada",
            "A67 - Pinta (carate)",
            "A67.0 - Lesões Primárias da Pinta",
            "A67.1 - Lesões Intermediárias da Pinta",
            "A67.2 - Lesões Tardias da Pinta",
            "A67.3 - Lesões Mistas da Pinta",
            "A67.9 - Pinta Não Especificada",
            "A68 - Febres Recorrentes (Borrelioses)",
            "A68.0 - Febre Recorrente Transmitida Por Piolhos",
            "A68.1 - Febre Recorrente Transmitida Por Carrapatos",
            "A68.9 - Febre Recorrente Não Especificada",
            "A69 - Outras Infecções Por Espiroquetas",
            "A69.0 - Estomatite Ulcerativa Necrotizante",
            "A69.1 - Outras Infecções de Vincent",
            "A69.2 - Doença de Lyme",
            "A69.8 - Outras Infecções Especificadas Por Espiroquetas",
            "A69.9 - Infecção Por Espiroqueta, Não Especificada",
            "A70 - Infecções Causadas Por Clamídia Psittaci",
            "A71 - Tracoma",
            "A71.0 - Fase Inicial do Tracoma",
            "A71.1 - Fase Ativa do Tracoma",
            "A71.9 - Tracoma Não Especificado",
            "A74 - Outras Doenças Causadas Por Clamídias",
            "A74.0 - Conjuntivite Causada Por Clamídias",
            "A74.8 - Outras Doenças Causadas Por Clamídias",
            "A74.9 - Infecção Causada Por Clamídias Não Especificada",
            "A75 - Tifo Exantemático",
            "A75.0 - Tifo Epidêmico Transmitido Por Piolhos Devido a Rickettsia Prowazekii",
            "A75.1 - Tifo Recrudescente (doença de Brill)",
            "A75.2 - Tifo Por Rickettsia Typhi",
            "A75.3 - Tifo Por Rickettsia Tsutsugamuchi",
            "A75.9 - Tifo Não Especificado",
            "A77 - Febre Maculosa (rickettsioses Transmitidas Por Carrapatos)",
            "A77.0 - Febre Maculosa Por Rickettsia Richettsii",
            "A77.1 - Febre Maculosa Por Rickettsia Conorii",
            "A77.2 - Febre Maculosa Devida à Rickettsia Siberica",
            "A77.3 - Febre Maculosa Devida à Rickettsia Australis",
            "A77.8 - Outras Febres Maculosas",
            "A77.9 - Febre Maculosa Não Especificada",
            "A78 - Febre Q",
            "A79 - Outras Rickettsioses",
            "A79.0 - Febre Das Trincheiras",
            "A79.1 - Rickettsiose Variceliforme Devida à Rickettsia Akari",
            "A79.8 - Outros Tipos de Rickettsioses Especificadas",
            "A79.9 - Rickettsiose Não Especificada",
            "A80 - Poliomielite Aguda",
            "A80.0 - Poliomielite Paralítica Aguda, Associada ao Vírus Vacinal",
            "A80.1 - Poliomielite Paralítica Aguda, Vírus Selvagem Importado",
            "A80.2 - Poliomielite Paralítica Aguda, Vírus Selvagem Indígena",
            "A80.3 - Poliomielites Paralíticas Agudas, Outras e Não Especificadas",
            "A80.4 - Poliomielite Aguda Não-paralítica",
            "A80.9 - Poliomielite Aguda Não Especificada",
            "A81 - Infecções Por Vírus Atípicos do Sistema Nervoso Central",
            "A81.0 - Doença de Creutzfeldt-Jakob",
            "A81.1 - Panencefalite Esclerosante Subaguda",
            "A81.2 - Leucoencefalopatia Multifocal Progressiva",
            "A81.8 - Outras Infecções Por Vírus Atípicos do Sistema Nervoso Central",
            "A81.9 - Infecção Não Especificada do Sistema Nervosos Central Por Vírus Atípicos",
            "A82 - Raiva",
            "A82.0 - Raiva Silvestre",
            "A82.1 - Raiva Urbana",
            "A82.9 - Raiva Não Especificada",
            "A83 - Encefalite Por Vírus Transmitidos Por Mosquitos",
            "A83.0 - Encefalite Japonesa",
            "A83.1 - Encefalite Eqüina Ocidental",
            "A83.2 - Encefalite Eqüina Oriental",
            "A83.3 - Encefalite de St. Louis",
            "A83.4 - Encefalite Australiana",
            "A83.5 - Encefalite da Califórnia",
            "A83.6 - Doença Pelo Vírus de Rocio",
            "A83.8 - Outras Encefalites Por Vírus Transmitidas Por Mosquitos",
            "A83.9 - Encefalite Não Especificada Por Vírus Transmitida Por Mosquitos",
            "A84 - Encefalite Por Vírus Transmitido Por Carrapatos",
            "A84.0 - Encefalite da Taiga (encefalite Vernoestival Russa)",
            "A84.1 - Encefalite da Europa Central Transmitida Por Carrapatos",
            "A84.8 - Outras Encefalites Por Vírus Transmitidas Por Carrapatos",
            "A84.9 - Encefalite Não Especificada Por Vírus Transmitida Por Carrapatos",
            "A85 - Outras Encefalites Virais, Não Classificadas em Outra Parte",
            "A85.0 - Encefalite Por Enterovírus",
            "A85.1 - Encefalite Por Adenovírus",
            "A85.2 - Encefalite Por Vírus Transmitido Por Artrópodes, Não Especificada",
            "A85.8 - Outras Encefalites Virais Especificadas",
            "A86 - Encefalite Viral, Não Especificada",
            "A87 - Meningite Viral",
            "A87.0 - Meningite Por Enterovírus",
            "A87.1 - Meningite Por Adenovírus",
            "A87.2 - Coriomeningite Linfocitária",
            "A87.8 - Outras Meningites Virais",
            "A87.9 - Meningite Viral Não Especificada",
            "A88 - Outras Infecções Virais do Sistema Nervoso Central Não Classificadas em Outra Parte",
            "A88.0 - Febre Exantemática Por Enterovírus (exantema de Boston)",
            "A88.1 - Vertigem Epidêmica",
            "A88.8 - Outras Infecções Virais Especificadas do Sistema Nervoso Central",
            "A89 - Infecções Virais Não Especificadas do Sistema Nervoso Central",
            "A90 - Dengue (dengue Clássico)",
            "A91 - Febre Hemorrágica Devida ao Vírus do Dengue",
            "A92 - Outras Febres Virais Transmitidas Por Mosquitos",
            "A92.0 - Febre de Chikungunya",
            "A92.1 - Febre de O'nyong-nyong",
            "A92.2 - Febre Eqüina Venezuelana",
            "A92.3 - Infecção Pelo Vírus West Nile",
            "A92.4 - Febre do Vale do Rift",
            "A92.8 - Outras Febres Virais Especificadas Transmitidas Por Mosquitos",
            "A92.9 - Febre Viral Transmitida Por Mosquitos, Não Especificada",
            "A93 - Outras Febres Por Vírus Transmitidas Por Artrópodes Não Classificadas em Outra Parte",
            "A93.0 - Febre de Oropouche",
            "A93.1 - Febre Por Flebótomos",
            "A93.2 - Febre do Colorado Transmitida Por Carrapatos",
            "A93.8 - Outras Febres Virais Especificadas Transmitidas Por Artrópodes",
            "A94 - Febre Viral Transmitida Por Artrópodes, Não Especificada",
            "A95 - Febre Amarela",
            "A95.0 - Febre Amarela Silvestre",
            "A95.1 - Febre Amarela Urbana",
            "A95.9 - Febre Amarela Não Especificada",
            "A96 - Febre Hemorrágica Por Arenavírus",
            "A96.0 - Febre Hemorrágica de Junin",
            "A96.1 - Febre Hemorrágica de Machupo",
            "A96.2 - Febre de Lassa",
            "A96.8 - Outras Febres Hemorrágicas Por Arenavírus",
            "A96.9 - Febre Hemorrágica Por Arenavírus, Não Especificada",
            "A98 - Outras Febres Hemorrágicas Por Vírus, Não Classificadas em Outra Parte",
            "A98.0 - Febre Hemorrágica da Criméia (do Congo)",
            "A98.1 - Febre Hemorrágica de Omsk",
            "A98.2 - Doença da Floresta de Kyasanur",
            "A98.3 - Doença de Marburg",
            "A98.4 - Doença Pelo Vírus Ebola",
            "A98.5 - Febre Hemorrágica Com Síndrome Renal",
            "A98.8 - Outras Febres Hemorrágicas Especificadas Por Vírus",
            "A99 - Febres Hemorrágicas Virais Não Especificadas",
            "B00 - Infecções Pelo Vírus do Herpes (herpes Simples)",
            "B00.0 - Eczema Herpético",
            "B00.1 - Dermatite Vesicular Devido ao Vírus do Herpes",
            "B00.2 - Gengivoestomatite e Faringoamigdalite Devida ao Vírus do Herpes",
            "B00.3 - Meningite Devida ao Vírus do Herpes",
            "B00.4 - Encefalite Devida ao Vírus do Herpes",
            "B00.5 - Afecções Oculares Devidas ao Vírus do Herpes",
            "B00.7 - Doença Disseminada Devida ao Vírus do Herpes",
            "B00.8 - Outras Formas de Infecção Devida ao Vírus do Herpes",
            "B00.9 - Infecção Não Especificada Devida ao Vírus do Herpes",
            "B01 - Varicela (Catapora)",
            "B01.0 - Meningite Por Varicela",
            "B01.1 - Encefalite Por Varicela",
            "B01.2 - Pneumopatia Varicelosa",
            "B01.8 - Varicela Com Outras Complicações",
            "B01.9 - Varicela Sem Complicação",
            "B02 - Herpes Zoster (Zona)",
            "B02.0 - Encefalite Pelo Vírus do Herpes Zoster",
            "B02.1 - Meningite Pelo Vírus do Herpes Zoster",
            "B02.2 - Herpes Zoster Acompanhado de Outras Manifestações Neurológicas",
            "B02.3 - Herpes Zoster Oftálmico",
            "B02.7 - Herpes Zoster Disseminado",
            "B02.8 - Herpes Zoster Com Outras Complicações",
            "B02.9 - Herpes Zoster Sem Complicação",
            "B03 - Varíola",
            "B04 - Varíola Dos Macacos (Monkeypox)",
            "B05 - Sarampo",
            "B05.0 - Sarampo Complicado Por Encefalite",
            "B05.1 - Sarampo Complicado Por Meningite",
            "B05.2 - Sarampo Complicado Por Pneumonia",
            "B05.3 - Sarampo Complicado Por Otite Média",
            "B05.4 - Sarampo Com Complicações Intestinais",
            "B05.8 - Sarampo Com Outras Complicações",
            "B05.9 - Sarampo Sem Complicação",
            "B06 - Rubéola",
            "B06.0 - Rubéola Com Complicações Neurológicas",
            "B06.8 - Rubéola Com Outras Complicações",
            "B06.9 - Rubéola Sem Complicação",
            "B07 - Verrugas de Origem Viral",
            "B08 - Outras Infecções Virais Caracterizadas Por Lesões da Pele e Das Membranas Mucosas, Não Classificadas em Outra Parte",
            "B08.0 - Outras Infecções Por Ortopoxvírus",
            "B08.1 - Molusco Contagioso",
            "B08.2 - Exantema Súbito (sexta Doença)",
            "B08.3 - Eritema Infeccioso (quinta Doença)",
            "B08.4 - Estomatite Vesicular Devida a Enterovírus Com Exantema",
            "B08.5 - Faringite Vesicular Devida a Enterovírus",
            "B08.8 - Outras Infecções Virais Especificadas Caracterizadas Por Lesões de Pele e Das Membranas Mucosas",
            "B09 - Infecção Viral Não Especificada Caracterizada Por Lesões da Pele e Membranas Mucosas",
            "B15 - Hepatite Aguda A",
            "B15.0 - Hepatite A Com Coma Hepático",
            "B15.9 - Hepatite A Sem Coma Hepático",
            "B16 - Hepatite Aguda B",
            "B16.0 - Hepatite Aguda B Com Agente Delta (co-infecção), Com Coma Hepático",
            "B16.1 - Hepatite Aguda B Com Agente Delta, (co-infecção), Sem Coma Hepático",
            "B16.2 - Hepatite Aguda B Sem Agente Delta, Com Coma Hepático",
            "B16.9 - Hepatite Aguda B Sem Agente Delta e Sem Coma Hepático",
            "B17 - Outras Hepatites Virais Agudas",
            "B17.0 - (Super)infecção Delta Aguda de Portador de Hepatite B",
            "B17.1 - Hepatite Aguda C",
            "B17.2 - Hepatite Aguda E",
            "B17.8 - Outras Hepatites Virais Agudas Especificadas",
            "B18 - Hepatite Viral Crônica",
            "B18.0 - Hepatite Viral Crônica B Com Agente Delta",
            "B18.1 - Hepatite Crônica Viral B Sem Agente Delta",
            "B18.2 - Hepatite Viral Crônica C",
            "B18.8 - Outras Hepatites Crônicas Virais",
            "B18.9 - Hepatite Viral Crônica Não Especificada",
            "B19 - Hepatite Viral Não Especificada",
            "B19.0 - Hepatite Viral, Não Especificada, Com Coma",
            "B19.9 - Hepatite Viral, Não Especificada, Sem Coma",
            "B20 - Doença Pelo Vírus da Imunodeficiência Humana (HIV), Resultando em Doenças Infecciosas e Parasitárias",
            "B20.0 - Doença Pelo HIV Resultando em Infecções Micobacterianas",
            "B20.1 - Doença Pelo HIV Resultando em Outras Infecções Bacterianas",
            "B20.2 - Doença Pelo HIV Resultando em Doença Citomegálica",
            "B20.3 - Doença Pelo HIV Resultando em Outras Infecções Virais",
            "B20.4 - Doença Pelo HIV Resultando em Candidíase",
            "B20.5 - Doença Pelo HIV Resultando em Outras Micoses",
            "B20.6 - Doença Pelo HIV Resultando em Pneumonia Por Pneumocystis Jirovecii",
            "B20.7 - Doença Pelo HIV Resultando em Infecções Múltiplas",
            "B20.8 - Doença Pelo HIV Resultando em Outras Doenças Infecciosas e Parasitárias",
            "B20.9 - Doença Pelo HIV Resultando em Doença Infecciosa ou Parasitária Não Especificada",
            "B21 - Doença Pelo Vírus da Imunodeficiência Humana (HIV), Resultando em Neoplasias Malignas",
            "B21.0 - Doença Pelo HIV Resultando em Sarcoma de Kaposi",
            "B21.1 - Doença Pelo HIV Resultando em Linfoma de Burkitt",
            "B21.2 - Doença Pelo HIV Resultando em Outros Tipos de Linfoma Não-Hodgkin",
            "B21.3 - Doença Pelo HIV Resultando em Outras Neoplasias Malignas Dos Tecidos Linfático, Hematopoético e Correlatos",
            "B21.7 - Doença Pelo HIV Resultando em Múltiplas Neoplasias Malignas",
            "B21.8 - Doença Pelo HIV Resultando em Outras Neoplasias Malignas",
            "B21.9 - Doença Pelo HIV Resultando em Neoplasia Maligna Não Especificada",
            "B22 - Doença Pelo Vírus da Imunodeficiência Humana (HIV) Resultando em Outras Doenças Especificadas",
            "B22.0 - Doença Pelo HIV Resultando em Encefalopatia",
            "B22.1 - Doença Pelo HIV Resultando em Pneumonite Intersticial Linfática",
            "B22.2 - Doença Pelo HIV Resultando em Síndrome de Emaciação",
            "B22.7 - Doença Pelo HIV Resultando em Doenças Múltiplas Classificadas em Outra Parte",
            "B23 - Doença Pelo Vírus da Imunodeficiência Humana (HIV) Resultando em Outras Doenças",
            "B23.0 - Síndrome de Infecção Aguda Pelo HIV",
            "B23.1 - Doença Pelo HIV Resultando em Linfadenopatias Generalizadas (persistentes)",
            "B23.2 - Doença Pelo HIV Resultando em Anomalias Hematológicas e Imunológicas Não Classificadas em Outra Parte",
            "B23.8 - Doença Pelo HIV Resultando em Outra Afecções Especificadas",
            "B24 - Doença Pelo Vírus da Imunodeficiência Humana (HIV) Não Especificada",
            "B25 - Doença Por Citomegalovírus",
            "B25.0 - Pneumonite Por Citomegalovírus",
            "B25.1 - Hepatite Por Citomegalovírus",
            "B25.2 - Pancreatite Por Citomegalovírus",
            "B25.8 - Outras Doenças Por Citomegalovírus",
            "B25.9 - Doença Não Especificada Por Citomegalovírus",
            "B26 - Caxumba (Parotidite Epidêmica)",
            "B26.0 - Orquite Por Caxumba (parotidite Epidêmica)",
            "B26.1 - Meningite Por Caxumba (parotidite Epidêmica)",
            "B26.2 - Encefalite Por Caxumba (parotidite Epidêmica)",
            "B26.3 - Pancreatite Por Caxumba (parotidite Epidêmica)",
            "B26.8 - Caxumba (parotidite Epidêmica) Com Outras Complicações",
            "B26.9 - Caxumba (parotidite Epidêmica) Sem Complicações",
            "B27 - Mononucleose Infecciosa",
            "B27.0 - Mononucleose Pelo Vírus Herpes Gama",
            "B27.1 - Mononucleose Por Citomegalovírus",
            "B27.8 - Outras Mononucleoses Infecciosas",
            "B27.9 - Mononucleose Infecciosa Não Especificada",
            "B30 - Conjuntivite Viral",
            "B30.0 - Ceratoconjuntivite Devida a Adenovírus",
            "B30.1 - Conjuntivite Devida a Adenovírus",
            "B30.2 - Faringoconjuntivite Viral",
            "B30.3 - Conjuntivite Hemorrágica Aguda Endêmica (por Enterovírus)",
            "B30.8 - Outras Conjuntivites Virais",
            "B30.9 - Conjuntivite Viral Não Especificada",
            "B33 - Outras Doenças Por Vírus Não Classificada em Outra Parte",
            "B33.0 - Mialgia Epidêmica",
            "B33.1 - Doença de Ross River",
            "B33.2 - Cardite Viral",
            "B33.3 - Infecção Por Retrovírus Não Classificada em Outra Parte",
            "B33.4 - Síndrome (cardio-)pulmonar Por Hantavírus (SPH) (SCPH)",
            "B33.8 - Outras Doenças Especificadas Por Vírus",
            "B34 - Doenças Por Vírus, de Localização Não Especificada",
            "B34.0 - Infecção Por Adenovírus de Localização Não Especificada",
            "B34.1 - Infecção Por Enterovírus de Localização Não Especificada",
            "B34.2 - Infecção Por Coronavírus de Localização Não Especificada",
            "B34.3 - Infecção Por Parvovírus de Localização Não Especificada",
            "B34.4 - Infecção Por Papovavírus de Localização Não Especificada",
            "B34.8 - Outras Infecções Por Vírus de Localização Não Especificada",
            "B34.9 - Infecção Viral Não Especificada",
            "B35 - Dermatofitose",
            "B35.0 - Tinha da Barba e do Couro Cabeludo",
            "B35.1 - Tinha Das Unhas",
            "B35.2 - Tinha da Mão",
            "B35.3 - Tinha Dos Pés",
            "B35.4 - Tinha do Corpo",
            "B35.5 - Tinha Imbricada",
            "B35.6 - Tinea Cruris",
            "B35.8 - Outras Dermatofitoses",
            "B35.9 - Dermatofitose Não Especificada",
            "B36 - Outras Micoses Superficiais",
            "B36.0 - Pitiríase Versicolor",
            "B36.1 - Tinha Negra",
            "B36.2 - Piedra Branca",
            "B36.3 - Piedra Negra",
            "B36.8 - Outras Micoses Superficiais Especificadas",
            "B36.9 - Micose Superficial Não Especificada",
            "B37 - Candidíase",
            "B37.0 - Estomatite Por Candida",
            "B37.1 - Candidíase Pulmonar",
            "B37.2 - Candidíase da Pele e Das Unhas",
            "B37.3 - Candidíase da Vulva e da Vagina",
            "B37.4 - Candidíase de Outras Localizações Urogenitais",
            "B37.5 - Meningite Por Candida",
            "B37.6 - Endocardite Por Candida",
            "B37.7 - Septicemia Por Candida",
            "B37.8 - Candidíase de Outras Localizações",
            "B37.9 - Candidíase Não Especificada",
            "B38 - Coccidioidomicose",
            "B38.0 - Coccidioidomicose Pulmonar Aguda",
            "B38.1 - Coccidioidomicose Pulmonar Crônica",
            "B38.2 - Coccidioidomicose Pulmonar Não Especificada",
            "B38.3 - Coccidioidomicose Cutânea",
            "B38.4 - Meningite Por Coccidioidomicose",
            "B38.7 - Coccidioidomicose Disseminada",
            "B38.8 - Outras Formas de Coccidioidomicose",
            "B38.9 - Coccidioidomicose Não Especificada",
            "B39 - Histoplasmose",
            "B39.0 - Histoplasmose Pulmonar Aguda Por Histoplasma Capsulatum",
            "B39.1 - Histoplasmose Pulmonar Crônica Por Histoplasma Capsulatum",
            "B39.2 - Histoplasmose Pulmonar Não Especificada Por Histoplasma Capsulatum",
            "B39.3 - Histoplasmose Disseminada Por Histoplasma Capsulatum",
            "B39.4 - Histoplasmose Não Especificada Por Histoplasma Capsulatum",
            "B39.5 - Histoplasmose Por Histoplasma Duboisii",
            "B39.9 - Histoplasmose Não Especificada",
            "B40 - Blastomicose",
            "B40.0 - Blastomicose Pulmonar Aguda",
            "B40.1 - Blastomicose Pulmonar Crônica",
            "B40.2 - Blastomicose Pulmonar Não Especificada",
            "B40.3 - Blastomicose Cutânea",
            "B40.7 - Blastomicose Disseminada",
            "B40.8 - Outras Formas de Blastomicose",
            "B40.9 - Blastomicose Não Especificada",
            "B41 - Paracoccidioidomicose",
            "B41.0 - Paracoccidioidomicose Pulmonar",
            "B41.7 - Paracoccidioidomicose Disseminada",
            "B41.8 - Outras Formas de Paracoccidioidomicose",
            "B41.9 - Paracoccidioidomicose Não Especificada",
            "B42 - Esporotricose",
            "B42.0 - Esporotricose Pulmonar",
            "B42.1 - Esporotricose Linfocutânea",
            "B42.7 - Esporotricose Disseminada",
            "B42.8 - Outras Formas de Esporotricose",
            "B42.9 - Esporotricose Não Especificada",
            "B43 - Cromomicose e Abscesso Feomicótico",
            "B43.0 - Cromomicose Cutânea",
            "B43.1 - Abscesso Cerebral Feomicótico",
            "B43.2 - Abscesso e Cisto Feomicótico Subcutâneos",
            "B43.8 - Outras Formas de Cromomicose",
            "B43.9 - Cromomicose Não Especificada",
            "B44 - Aspergilose",
            "B44.0 - Aspergilose Pulmonar Invasiva",
            "B44.1 - Outras Aspergiloses Pulmonares",
            "B44.2 - Aspergilose Amigdaliana",
            "B44.7 - Aspergilose Disseminada",
            "B44.8 - Outras Formas de Aspergilose",
            "B44.9 - Aspergilose Não Especificada",
            "B45 - Criptococose",
            "B45.0 - Criptococose Pulmonar",
            "B45.1 - Criptococose Cerebral",
            "B45.2 - Criptococose Cutânea",
            "B45.3 - Criptococose Óssea",
            "B45.7 - Criptococose Disseminada",
            "B45.8 - Outras Formas de Criptococose",
            "B45.9 - Criptococose Não Especificada",
            "B46 - Zigomicose",
            "B46.0 - Mucormicose Pulmonar",
            "B46.1 - Mucormicose Rinocerebral",
            "B46.2 - Mucormicose Gastrointestinal",
            "B46.3 - Mucormicose Cutânea",
            "B46.4 - Mucormicose Disseminada",
            "B46.5 - Mucormicose Não Especificada",
            "B46.8 - Outras Zigomicoses",
            "B46.9 - Zigomicose Não Especificada",
            "B47 - Micetoma",
            "B47.0 - Eumicetoma",
            "B47.1 - Actinomicetoma",
            "B47.9 - Micetoma Não Especificado",
            "B48 - Outras Micoses, Não Classificadas em Outra Parte",
            "B48.0 - Lobomicose",
            "B48.1 - Rinosporidiose",
            "B48.2 - Alesqueriose",
            "B48.3 - Geotricose",
            "B48.4 - Penicilose",
            "B48.7 - Micoses Oportunistas",
            "B48.8 - Outras Micoses Especificadas",
            "B49 - Micose Não Especificada",
            "B50 - Malária Por Plasmodium Falciparum",
            "B50.0 - Malária Por Plasmodium Falciparum Com Complicações Cerebrais",
            "B50.8 - Outras Formas Graves e Complicadas de Malária Por Plasmodium Falciparum",
            "B50.9 - Malária Não Especificada Por Plasmodium Falciparum",
            "B51 - Malária Por Plasmodium Vivax",
            "B51.0 - Malária Por Plasmodium Vivax Com Rotura do Baço",
            "B51.8 - Malária Por Plasmodium Vivax Com Outras Complicações",
            "B51.9 - Malária Por Plasmodium Vivax Sem Complicações",
            "B52 - Malária Por Plasmodium Malariae",
            "B52.0 - Malária Por Plasmodium Malariae Com Nefropatia",
            "B52.8 - Malária Por Plasmodium Malariae Com Outras Complicações",
            "B52.9 - Malária Por Plasmodium Malariae Sem Complicações",
            "B53 - Outras Formas de Malária Confirmadas Por Exames Parasitológicos",
            "B53.0 - Malária Por Plasmodium Ovale",
            "B53.1 - Malária Por Plasmódios de Macacos",
            "B53.8 - Outras Formas de Malárias Com Confirmação Parasitológica, Não Classificadas em Outra Parte",
            "B54 - Malária Não Especificada",
            "B55 - Leishmaniose",
            "B55.0 - Leishmaniose Visceral",
            "B55.1 - Leishmaniose Cutânea",
            "B55.2 - Leishmaniose Cutâneo-mucosa",
            "B55.9 - Leishmaniose Não Especificada",
            "B56 - Tripanossomíase Africana",
            "B56.0 - Tripanossomíase Por Trypanosoma Gambiense",
            "B56.1 - Tripanossomíase Por Trypanosoma Rhodesiense",
            "B56.9 - Tripanossomíase Africana Não Especificada",
            "B57 - Doença de Chagas",
            "B57.0 - Forma Aguda da Doença de Chagas, Com Comprometimento Cardíaco",
            "B57.1 - Forma Aguda da Doença de Chagas, Sem Comprometimento Cardíaco",
            "B57.2 - Doença de Chagas (crônica) Com Comprometimento Cardíaco",
            "B57.3 - Doença de Chagas (crônica) Com Comprometimento do Aparelho Digestivo",
            "B57.4 - Doença de Chagas (crônica) Com Comprometimento do Sistema Nervoso",
            "B57.5 - Doença de Chagas (crônica) Com Comprometimento de Outros Órgãos",
            "B58 - Toxoplasmose",
            "B58.0 - Oculopatia Por Toxoplasma",
            "B58.1 - Hepatite Por Toxoplasma",
            "B58.2 - Meningoencefalite Por Toxoplasma",
            "B58.3 - Toxoplasmose Pulmonar",
            "B58.8 - Toxoplasmose Com Comprometimento de Outros Órgãos",
            "B58.9 - Toxoplasmose Não Especificada",
            "B59 - Pneumocistose",
            "B60 - Outras Doenças Devidas a Protozoários, Não Classificadas em Outra Parte",
            "B60.0 - Babesiose",
            "B60.1 - Acantamebíase",
            "B60.2 - Naegleríase",
            "B60.8 - Outras Doenças Especificadas Devidas a Protozoários",
            "B64 - Doença Não Especificada Devida a Protozoários",
            "B65 - Esquistossomose (bilharziose) (Schistosomíase)",
            "B65.0 - Esquistossomose Devida ao Schistosoma Haematobium (esquistossomose Urinária)",
            "B65.1 - Esquistossomose Devida ao Schistosoma Mansoni (esquistossomose Intestinal)",
            "B65.2 - Esquistossomose Devida ao Schistosoma Japonicum",
            "B65.3 - Dermatite Por Cercárias",
            "B65.8 - Outras Esquistossomoses",
            "B65.9 - Esquistossomose Não Especificada",
            "B66 - Outras Infestações Por Trematódeos",
            "B66.0 - Opistorquíase",
            "B66.1 - Clonorquíase",
            "B66.2 - Dicrocelíase",
            "B66.3 - Fasciolíase",
            "B66.4 - Paragonimíase",
            "B66.5 - Fasciolopsíase",
            "B66.8 - Outras Infestações Por Trematódeos Especificados",
            "B66.9 - Infecção Não Especificada Por Trematódeo",
            "B67 - Equinococose",
            "B67.0 - Infestação Hepática Por Echinococcus Granulosus",
            "B67.1 - Infestação Pulmonar Por Echinococcus Granulosus",
            "B67.2 - Infestação Óssea Por Echinococcus Granulosus",
            "B67.3 - Infestações Por Echinmococcus Granulosus, Outras e de Localizações Múltiplas",
            "B67.4 - Infestação Não Especificada Por Echinococcus Granulosus",
            "B67.5 - Infestação Hepática Por Echinococcus Multilocularis",
            "B67.6 - Infecções Por Echinococcus Multilocularis, Outras e de Localizações Múltiplas",
            "B67.7 - Infestação Não Especificada Por Echinococcus Multilocularis",
            "B67.8 - Infestação Hepática Não Especificada, Por Echinococcus",
            "B67.9 - Infestações Por Echinococcus, Outras e as Não Especificadas",
            "B68 - Infestação Por Taenia",
            "B68.0 - Infestação Por Taenia Solium",
            "B68.1 - Infestação Por Taenia Saginata",
            "B68.9 - Infestação Não Especificada Por Taenia",
            "B69 - Cisticercose",
            "B69.0 - Cisticercose do Sistema Nervoso Central",
            "B69.1 - Cisticercose do Olho",
            "B69.8 - Cisticercose de Outras Localizações",
            "B69.9 - Cisticercose Não Especificada",
            "B70 - Difilobotríase e Esparganose",
            "B70.0 - Difilobotríase",
            "B70.1 - Esparganose",
            "B71 - Outras Infestações Por Cestóides",
            "B71.0 - Infestação Por Hymenolepis",
            "B71.1 - Infestação Por Dipylidium",
            "B71.8 - Outras Infestações Especificadas Por Cestóides",
            "B71.9 - Infestação Não Especificada Por Cestóides",
            "B72 - Dracontíase",
            "B73 - Oncocercose",
            "B74 - Filariose",
            "B74.0 - Filariose Por Wuchereria Bancrofti",
            "B74.1 - Filariose Por Brugia Malayi",
            "B74.2 - Filariose Por Brugia Timori",
            "B74.3 - Loaíase",
            "B74.4 - Mansonelose",
            "B74.8 - Outras Filarioses",
            "B74.9 - Filariose Não Especificada",
            "B75 - Triquinose",
            "B76 - Ancilostomíase",
            "B76.0 - Ancilostomose",
            "B76.1 - Necatoríase",
            "B76.8 - Outras Ancilostomíases",
            "B76.9 - Ancilostomíase Não Especificada",
            "B77 - Ascaridíase",
            "B77.0 - Ascaridíase Com Complicações Intestinais",
            "B77.8 - Ascaridíase Com Outras Complicações",
            "B77.9 - Ascaridíase Não Especificada",
            "B78 - Estrongiloidíase",
            "B78.0 - Estrongiloidíase Intestinal",
            "B78.1 - Estrongiloidíase Cutânea",
            "B78.7 - Estrongiloidíase Disseminada",
            "B78.9 - Estrongiloidíase Não Especificada",
            "B79 - Tricuríase",
            "B80 - Oxiuríase",
            "B81 - Outras Helmintíases Intestinais, Não Classificadas em Outra Parte",
            "B81.0 - Anisaquíase",
            "B81.1 - Capilaríase Intestinal",
            "B81.2 - Tricostrongilose",
            "B81.3 - Angiostrongilíase Intestinal",
            "B81.4 - Helmintíases Intestinais Mistas",
            "B81.8 - Outras Helmintíases Intestinais Especificadas",
            "B82 - Parasitose Intestinal Não Especificada",
            "B82.0 - Helmintíase Intestinal Não Especificada",
            "B82.9 - Parasitose Intestinal Não Especificada",
            "B83 - Outras Helmintíases",
            "B83.0 - Larva Migrans Visceral",
            "B83.1 - Gnatostomíase",
            "B83.2 - Angrostrongilíase Devida a Parastrongylus Cantonensis",
            "B83.3 - Singamose",
            "B83.4 - Hirudiníase Interna",
            "B83.8 - Outras Helmintíases Especificadas",
            "B83.9 - Helmintíase Não Especificada",
            "B85 - Pediculose e Ftiríase",
            "B85.0 - Pediculose Devida a Pediculus Humanus Capitis",
            "B85.1 - Pediculose Devida a Pediculus Humanus Corporis",
            "B85.2 - Pediculose Não Especificada",
            "B85.3 - Ftiríase",
            "B85.4 - Pediculose e Ftiríase Mista",
            "B86 - Escabiose (sarna)",
            "B87 - Miíase",
            "B87.0 - Miíase Cutânea",
            "B87.1 - Miíase Das Feridas",
            "B87.2 - Miíase Ocular",
            "B87.3 - Miíase Nasofaríngea",
            "B87.4 - Miíase Auricular",
            "B87.8 - Miíase de Outras Localizações",
            "B87.9 - Miíase Não Especificada",
            "B88 - Outras Infestações",
            "B88.0 - Outras Acaríases",
            "B88.1 - Tungíase (infestação Pela Pulga da Areia)",
            "B88.2 - Outras Infestações Por Artrópodos",
            "B88.3 - Hirudiníase Externa",
            "B88.8 - Outras Infestações Especificadas",
            "B88.9 - Infestação Não Especificada",
            "B89 - Doença Parasitária Não Especificada",
            "B90 - Seqüelas de Tuberculose",
            "B90.0 - Seqüelas de Tuberculose do Sistema Nervoso Central",
            "B90.1 - Seqüelas de Tuberculose Geniturinária",
            "B90.2 - Seqüelas de Tuberculose Óssea e Das Articulações",
            "B90.8 - Seqüelas de Tuberculose de Outros Órgãos",
            "B90.9 - Seqüelas de Tuberculose Das Vias Respiratórias e de Órgãos Não Especificados",
            "B91 - Seqüelas de Poliomielite",
            "B92 - Seqüelas de Hanseníase (lepra)",
            "B94 - Seqüelas de Outras Doenças Infecciosas e Parasitárias e Das Não Especificadas",
            "B94.0 - Seqüelas de Tracoma",
            "B94.1 - Seqüelas de Encefalite Viral",
            "B94.2 - Seqüelas de Hepatite Viral",
            "B94.8 - Seqüelas de Outras Doenças Infecciosas e Parasitárias Especificadas",
            "B94.9 - Seqüelas de Doença Infecciosa ou Parasitária Não Especificada",
            "B95 - Estreptococos e Estafilococos Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.0 - Estreptococo do Grupo A, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.1 - Estreptococo do Grupo B, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.2 - Estreptococo do Grupo D, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.3 - Streptococcus Pneumoniae, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.4 - Outros Estreptococos, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.5 - Estreptococo Não Especificado, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.6 - Staphylococcus Aureus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.7 - Outros Estafilococos Como Causa de Doenças Classificadas em Outros Capítulos",
            "B95.8 - Estafilococo Não Especificado, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96 - Outros Agentes Bacterianos, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.0 - Mycoplasma Pneumoniae (M. Pneumoniae), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.1 - Klebsiella Pneumoniae (M. Pneumoniae), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.2 - Escherichia Coli (E. Coli), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.3 - Haemophilus Influenzae (H. Influenzae), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.4 - Proteus (mirabilis) (morganii), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.5 - Pseudomonas (aeruginosa) (mallei) (pseudomallei), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.6 - Bacillus Fragilis (B. Fragilis), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.7 - Clostridium Perfringens (C. Perfringens), Como Causa de Doenças Classificadas em Outros Capítulos",
            "B96.8 - Outros Agentes Bacterianos Especificados, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97 - Vírus Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.0 - Adenovírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.1 - Enterovírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.2 - Coronavírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.3 - Retrovírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.4 - Vírus Sincicial Respiratório, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.5 - Reovírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.6 - Parvovírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.7 - Papilomavírus, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B97.8 - Outros Agentes Virais, Como Causa de Doenças Classificadas em Outros Capítulos",
            "B99 - Doenças Infecciosas, Outras e as Não Especificadas",
            "C00 - Neoplasia Maligna do Lábio",
            "C00.0 - Neoplasia Maligna do Lábio Superior Externo",
            "C00.1 - Neoplasia Maligna do Lábio Inferior Externo",
            "C00.2 - Neoplasia Maligna do Lábio Externo, Não Especificado",
            "C00.3 - Neoplasia Maligna do Lábio Superior, Face Interna",
            "C00.4 - Neoplasia Maligna do Lábio Inferior, Face Interna",
            "C00.5 - Neoplasia Maligna do Lábio, Sem Especificação, Face Interna",
            "C00.6 - Neoplasia Maligna da Comissura Labial",
            "C00.8 - Neoplasia Maligna do Lábio Com Lesão Invasiva",
            "C00.9 - Neoplasia Maligna do Lábio, Não Especificado",
            "C01 - Neoplasia Maligna da Base da Língua",
            "C02 - Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Língua",
            "C02.0 - Neoplasia Maligna da Face Dorsal da Língua",
            "C02.1 - Neoplasia Maligna da Borda da Língua",
            "C02.2 - Neoplasia Maligna da Face Ventral da Língua",
            "C02.3 - Neoplasia Maligna de Dois Terços Anteriores da Língua, Parte Não Especificada",
            "C02.4 - Neoplasia Maligna da Amígdala Lingual",
            "C02.8 - Neoplasia Maligna da Língua Com Lesão Invasiva",
            "C02.9 - Neoplasia Maligna da Língua, Não Especificada",
            "C03 - Neoplasia Maligna da Gengiva",
            "C03.0 - Neoplasia Maligna da Gengiva Superior",
            "C03.1 - Neoplasia Maligna da Gengiva Inferior",
            "C03.9 - Neoplasia Maligna da Gengiva, Não Especificada",
            "C04 - Neoplasia Maligna do Assoalho da Boca",
            "C04.0 - Neoplasia Maligna do Assoalho Anterior da Boca",
            "C04.1 - Neoplasia Maligna do Assoalho Lateral da Boca",
            "C04.8 - Neoplasia Maligna do Assoalho da Boca Com Lesão Invasiva",
            "C04.9 - Neoplasia Maligna do Assoalho da Boca, Não Especificado",
            "C05 - Neoplasia Maligna do Palato",
            "C05.0 - Neoplasia Maligna do Palato Duro",
            "C05.1 - Neoplasia Maligna do Palato Mole",
            "C05.2 - Neoplasia Maligna da Úvula",
            "C05.8 - Neoplasia Maligna do Palato Com Lesão Invasiva",
            "C05.9 - Neoplasia Maligna do Palato, Não Especificado",
            "C06 - Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Boca",
            "C06.0 - Neoplasia Maligna da Mucosa Oral",
            "C06.1 - Neoplasia Maligna do Vestíbulo da Boca",
            "C06.2 - Neoplasia Maligna da Área Retromolar",
            "C06.8 - Neoplasia Maligna de Outras Partes e de Partes Não Especificadas da Boca Com Lesão Invasiva",
            "C06.9 - Neoplasia Maligna da Boca, Não Especificada",
            "C07 - Neoplasia Maligna da Glândula Parótida",
            "C08 - Neoplasia Maligna de Outras Glândulas Salivares Maiores e as Não Especificadas",
            "C08.0 - Neoplasia Maligna da Glândula Submandibular",
            "C08.1 - Neoplasia Maligna da Glândula Sublingual",
            "C08.8 - Neoplasia Maligna Das Glândulas Salivares Maiores Com Lesão Invasiva",
            "C08.9 - Neoplasia Maligna da Glândula Salivar Maior, Não Especificada",
            "C09 - Neoplasia Maligna da Amígdala",
            "C09.0 - Neoplasia Maligna da Fossa Amigdaliana",
            "C09.1 - Neoplasia Maligna do Pilar Amigdaliano (anterior) (posterior)",
            "C09.8 - Neoplasia Maligna da Amígdala Com Lesão Invasiva",
            "C09.9 - Neoplasia Maligna da Amígdala, Não Especificada",
            "C10 - Neoplasia Maligna da Orofaringe",
            "C10.0 - Neoplasia Maligna da Valécula",
            "C10.1 - Neoplasia Maligna da Face Anterior da Epiglote",
            "C10.2 - Neoplasia Maligna da Parede Lateral da Orofaringe",
            "C10.3 - Neoplasia Maligna da Parede Posterior da Orofaringe",
            "C10.4 - Neoplasia Maligna da Fenda Branquial",
            "C10.8 - Neoplasia Maligna da Orofaringe Com Lesão Invasiva",
            "C10.9 - Neoplasia Maligna da Orofaringe, Não Especificada",
            "C11 - Neoplasia Maligna da Nasofaringe",
            "C11.0 - Neoplasia Maligna da Parede Superior da Nasofaringe",
            "C11.1 - Neoplasia Maligna da Parede Posterior da Nasofaringe",
            "C11.2 - Neoplasia Maligna da Parede Lateral da Nasofaringe",
            "C11.3 - Neoplasia Maligna da Parede Anterior da Nasofaringe",
            "C11.8 - Neoplasia Maligna da Nasofaringe Com Lesão Invasiva",
            "C11.9 - Neoplasia Maligna da Nasofaringe, Não Especificada",
            "C12 - Neoplasia Maligna do Seio Piriforme",
            "C13 - Neoplasia Maligna da Hipofaringe",
            "C13.0 - Neoplasia Maligna da Região Pós-cricóidea",
            "C13.1 - Neoplasia Maligna da Prega Ariepiglótica, Face Hipofaríngea",
            "C13.2 - Neoplasia Maligna da Parede Posterior da Hipofaringe",
            "C13.8 - Neoplasia Maligna da Hipofaringe Com Lesão Invasiva",
            "C13.9 - Neoplasia Maligna da Hipofaringe, Não Especificada",
            "C14 - Neoplasia Maligna de Outras Localizações e de Localizações Mal Definida, do Lábio, Cavidade Oral e Faringe",
            "C14.0 - Neoplasia Maligna da Faringe, Não Especificada",
            "C14.2 - Neoplasia Maligna do Anel de Waldeyer",
            "C14.8 - Neoplasia Maligna do Lábio, Cavidade Oral e Faringe Com Lesão Invasiva",
            "C15 - Neoplasia Maligna do Esôfago",
            "C15.0 - Neoplasia Maligna da Porção Cervical do Esôfago (esôfago Cervical)",
            "C15.1 - Neoplasia Maligna da Porção Torácica do Esôfago (esôfago Torácico)",
            "C15.2 - Neoplasia Maligna da Porção Abdominal do Esôfago (esôfago Abdominal)",
            "C15.3 - Neoplasia Maligna do Terço Superior do Esôfago",
            "C15.4 - Neoplasia Maligna do Terço Médio do Esôfago",
            "C15.5 - Neoplasia Maligna do Terço Inferior do Esôfago",
            "C15.8 - Neoplasia Maligna do Esôfago Com Lesão Invasiva",
            "C15.9 - Neoplasia Maligna do Esôfago, Não Especificado",
            "C16 - Neoplasia Maligna do Estômago",
            "C16.0 - Neoplasia Maligna da Cárdia",
            "C16.1 - Neoplasia Maligna do Fundo do Estômago",
            "C16.2 - Neoplasia Maligna do Corpo do Estômago",
            "C16.3 - Neoplasia Maligna do Antro Pilórico",
            "C16.4 - Neoplasia Maligna do Piloro",
            "C16.5 - Neoplasia Maligna da Pequena Curvatura do Estômago, Não Especificada",
            "C16.6 - Neoplasia Maligna da Grande Curvatura do Estômago, Não Especificada",
            "C16.8 - Neoplasia Maligna do Estômago Com Lesão Invasiva",
            "C16.9 - Neoplasia Maligna do Estômago, Não Especificado",
            "C17 - Neoplasia Maligna do Intestino Delgado",
            "C17.0 - Neoplasia Maligna do Duodeno",
            "C17.1 - Neoplasia Maligna do Jejuno",
            "C17.2 - Neoplasia Maligna do Íleo",
            "C17.3 - Neoplasia Maligna do Divertículo de Meckel",
            "C17.8 - Neoplasia Maligna do Intestino Delgado Com Lesão Invasiva",
            "C17.9 - Neoplasia Maligna do Intestino Delgado, Não Especificado",
            "C18 - Neoplasia Maligna do Cólon",
            "C18.0 - Neoplasia Maligna do Ceco",
            "C18.1 - Neoplasia Maligna do Apêndice (vermiforme)",
            "C18.2 - Neoplasia Maligna do Cólon Ascendente",
            "C18.3 - Neoplasia Maligna da Flexura (ângulo) Hepática(o)",
            "C18.4 - Neoplasia Maligna do Cólon Transverso",
            "C18.5 - Neoplasia Maligna da Flexura (ângulo) Esplênica(o)",
            "C18.6 - Neoplasia Maligna do Cólon Descendente",
            "C18.7 - Neoplasia Maligna do Cólon Sigmóide",
            "C18.8 - Neoplasia Maligna do Cólon Com Lesão Invasiva",
            "C18.9 - Neoplasia Maligna do Cólon, Não Especificado",
            "C19 - Neoplasia Maligna da Junção Retossigmóide",
            "C20 - Neoplasia Maligna do Reto",
            "C21 - Neoplasia Maligna do Ânus e do Canal Anal",
            "C21.0 - Neoplasia Maligna do Ânus, Não Especificado",
            "C21.1 - Neoplasia Maligna do Canal Anal",
            "C21.2 - Neoplasia Maligna da Zona Cloacogênica",
            "C21.8 - Neoplasia Maligna do Reto, Ânus e do Canal Anal Com Lesão Invasiva",
            "C22 - Neoplasia Maligna do Fígado e Das Vias Biliares Intra-hepáticas",
            "C22.0 - Carcinoma de Células Hepáticas",
            "C22.1 - Carcinoma de Vias Biliares Intra-hepáticas",
            "C22.2 - Hepatoblastoma",
            "C22.3 - Angiossarcoma do Fígado",
            "C22.4 - Outros Sarcomas do Fígado",
            "C22.7 - Outros Carcinomas Especificados do Fígado",
            "C22.9 - Neoplasia Maligna do Fígado, Não Especificada",
            "C23 - Neoplasia Maligna da Vesícula Biliar",
            "C24 - Neoplasia Maligna de Outras Partes, e de Partes Não Especificadas Das Vias Biliares",
            "C24.0 - Neoplasia Maligna Das Vias Biliares Extra-hepáticas",
            "C24.1 - Neoplasia Maligna da Ampola de Vater",
            "C24.8 - Neoplasia Maligna Das Vias Biliares Com Lesão Invasiva",
            "C24.9 - Neoplasia Maligna da Via Biliar, Não Especificada",
            "C25 - Neoplasia Maligna do Pâncreas",
            "C25.0 - Neoplasia Maligna da Cabeça do Pâncreas",
            "C25.1 - Neoplasia Maligna do Corpo do Pâncreas",
            "C25.2 - Neoplasia Maligna da Cauda do Pâncreas",
            "C25.3 - Neoplasia Maligna do Canal Pancreático",
            "C25.4 - Neoplasia Maligna do Pâncreas Endócrino",
            "C25.7 - Neoplasia Maligna de Outras Partes do Pâncreas",
            "C25.8 - Neoplasia Maligna do Pâncreas Com Lesão Invasiva",
            "C25.9 - Neoplasia Maligna do Pâncreas, Não Especificado",
            "C26 - Neoplasia Maligna de Outros Órgãos Digestivos e de Localizações Mal Definidas no Aparelho Digestivo",
            "C26.0 - Neoplasia Maligna do Trato Intestinal, Parte Não Especificada",
            "C26.1 - Neoplasia Maligna do Baço",
            "C26.8 - Neoplasia Maligna do Aparelho Digestivo Com Lesão Invasiva",
            "C26.9 - Neoplasia Maligna de Localizações Mal Definidas Dentro do Aparelho Digestivo",
            "C30 - Neoplasia Maligna da Cavidade Nasal e do Ouvido Médio",
            "C30.0 - Neoplasia Maligna da Cavidade Nasal",
            "C30.1 - Neoplasia Maligna do Ouvido Médio",
            "C31 - Neoplasia Maligna Dos Seios da Face",
            "C31.0 - Neoplasia Maligna do Seio Maxilar",
            "C31.1 - Neoplasia Maligna do Seio Etmoidal",
            "C31.2 - Neoplasia Maligna do Seio Frontal",
            "C31.3 - Neoplasia Maligna do Seio Esfenoidal",
            "C31.8 - Neoplasia Maligna Dos Seios da Face Com Lesão Invasiva",
            "C31.9 - Neoplasia Maligna do Seio da Face, Não Especificado",
            "C32 - Neoplasia Maligna da Laringe",
            "C32.0 - Neoplasia Maligna da Glote",
            "C32.1 - Neoplasia Maligna da Região Supraglótica",
            "C32.2 - Neoplasia Maligna da Região Subglótica",
            "C32.3 - Neoplasia Maligna Das Cartilagens da Laringe",
            "C32.8 - Neoplasia Maligna da Laringe Com Lesão Invasiva",
            "C32.9 - Neoplasia Maligna da Laringe, Não Especificada",
            "C33 - Neoplasia Maligna da Traquéia",
            "C34 - Neoplasia Maligna Dos Brônquios e Dos Pulmões",
            "C34.0 - Neoplasia Maligna do Brônquio Principal",
            "C34.1 - Neoplasia Maligna do Lobo Superior, Brônquio ou Pulmão",
            "C34.2 - Neoplasia Maligna do Lobo Médio, Brônquio ou Pulmão",
            "C34.3 - Neoplasia Maligna do Lobo Inferior, Brônquio ou Pulmão",
            "C34.8 - Neoplasia Maligna Dos Brônquios e Dos Pulmões Com Lesão Invasiva",
            "C34.9 - Neoplasia Maligna Dos Brônquios ou Pulmões, Não Especificado",
            "C37 - Neoplasia Maligna do Timo",
            "C38 - Neoplasia Maligna do Coração, Mediastino e Pleura",
            "C38.0 - Neoplasia Maligna do Coração",
            "C38.1 - Neoplasia Maligna do Mediastino Anterior",
            "C38.2 - Neoplasia Maligna do Mediastino Posterior",
            "C38.3 - Neoplasia Maligna do Mediastino, Porção Não Especificada",
            "C38.4 - Neoplasia Maligna da Pleura",
            "C38.8 - Neoplasia Maligna do Coração, Mediastino e Pleura Com Lesão Invasiva",
            "C39 - Neoplasia Maligna de Outras Localizações e de Localizações Mal Definidas do Aparelho Respiratório e Dos Órgãos Intratorácicos",
            "C39.0 - Neoplasia Maligna do Trato Respiratório Superior, Porção Não Especificada",
            "C39.8 - Neoplasia Maligna do Aparelho Respiratório e Dos Órgãos Intratorácicos Com Lesão Invasiva",
            "C39.9 - Neoplasia Maligna de Localizações Mal Definidas do Aparelho Respiratório",
            "C40 - Neoplasia Maligna Dos Ossos e Cartilagens Articulares Dos Membros",
            "C40.0 - Neoplasia Maligna da Omoplata (escápula) e Ossos Longos Dos Membros Superiores",
            "C40.1 - Neoplasia Maligna Dos Ossos Curtos Dos Membros Superiores",
            "C40.2 - Neoplasia Maligna Dos Ossos Longos Dos Membros Inferiores",
            "C40.3 - Neoplasia Maligna Dos Ossos Curtos Dos Membros Inferiores",
            "C40.8 - Neoplasia Maligna Dos Ossos e Cartilagens Articulares Dos Membros Com Lesão Invasiva",
            "C40.9 - Neoplasia Maligna Dos Ossos e Cartilagens Articulares de Membro Não Especificado",
            "C41 - Neoplasia Maligna Dos Ossos e Das Cartilagens Articulares de Outras Localizações e de Localizações Não Especificadas",
            "C41.0 - Neoplasia Maligna Dos Ossos do Crânio e da Face",
            "C41.1 - Neoplasia Maligna da Mandíbula",
            "C41.2 - Neoplasia Maligna da Coluna Vertebral",
            "C41.3 - Neoplasia Maligna Das Costelas, Esterno e Clavícula",
            "C41.4 - Neoplasia Maligna Dos Ossos da Pelve, Sacro e Cóccix",
            "C41.8 - Neoplasia Maligna Dos Ossos e Das Cartilagens Articulares Com Lesão Invasiva",
            "C41.9 - Neoplasia Maligna Dos Ossos e Cartilagens Articulares, Não Especificados",
            "C43 - Melanoma Maligno da Pele",
            "C43.0 - Melanoma Maligno do Lábio",
            "C43.1 - Melanoma Maligno da Pálpebra, Incluindo as Comissuras Palpebrais",
            "C43.2 - Melanoma Maligno da Orelha e do Conduto Auditivo Externo",
            "C43.3 - Melanoma Maligno de Outras Partes e Partes Não Especificadas da Face",
            "C43.4 - Melanoma Maligno do Couro Cabeludo e do Pescoço",
            "C43.5 - Melanoma Maligno do Tronco",
            "C43.6 - Melanoma Maligno do Membro Superior, Incluindo Ombro",
            "C43.7 - Melanoma Maligno do Membro Inferior, Incluindo Quadril",
            "C43.8 - Melanoma Maligno Invasivo da Pele",
            "C43.9 - Melanoma Maligno de Pele, Não Especificado",
            "C44 - Outras Neoplasias Malignas da Pele",
            "C44.0 - Neoplasia Maligna da Pele do Lábio",
            "C44.1 - Neoplasia Maligna da Pele da Pálpebra, Incluindo o Canto",
            "C44.2 - Neoplasia Maligna da Pele da Orelha e do Conduto Auditivo Externo",
            "C44.3 - Neoplasia Maligna da Pele de Outras Partes e de Partes Não Especificadas da Face",
            "C44.4 - Neoplasia Maligna da Pele do Couro Cabeludo e do Pescoço",
            "C44.5 - Neoplasia Maligna da Pele do Tronco",
            "C44.6 - Neoplasia Maligna da Pele do Membro Superior, Incluindo Ombro",
            "C44.7 - Neoplasia Maligna da Pele do Membro Inferior, Incluindo Quadril",
            "C44.8 - Neoplasia Maligna da Pele Com Lesão Invasiva",
            "C44.9 - Neoplasia Maligna da Pele, Não Especificada",
            "C45 - Mesotelioma",
            "C45.0 - Mesotelioma da Pleura",
            "C45.1 - Mesotelioma do Peritônio",
            "C45.2 - Mesotelioma do Pericárdio",
            "C45.7 - Mesotelioma de Outras Localizações",
            "C45.9 - Mesotelioma, Não Especificado",
            "C46 - Sarcoma de Kaposi",
            "C46.0 - Sarcoma de Kaposi da Pele",
            "C46.1 - Sarcoma de Kaposi de Tecidos Moles",
            "C46.2 - Sarcoma de Kaposi do Palato",
            "C46.3 - Sarcoma de Kaposi Dos Gânglios Linfáticos",
            "C46.7 - Sarcoma de Kaposi de Outras Localizações",
            "C46.8 - Sarcoma de Kaposi de Múltiplos Órgãos",
            "C46.9 - Sarcoma de Kaposi, Não Especificado",
            "C47 - Neoplasia Maligna Dos Nervos Periféricos e do Sistema Nervoso Autônomo",
            "C47.0 - Neoplasia Maligna Dos Nervos Periféricos da Cabeça, Face e Pescoço",
            "C47.1 - Neoplasia Maligna Dos Nervos Periféricos Dos Membros Superiores, Incluindo Ombro",
            "C47.2 - Neoplasia Maligna Dos Nervos Periféricos Dos Membros Inferiores, Incluindo Quadril",
            "C47.3 - Neoplasia Maligna Dos Nervos Periféricos do Tórax",
            "C47.4 - Neoplasia Maligna Dos Nervos Periféricos do Abdome",
            "C47.5 - Neoplasia Maligna Dos Nervos Periféricos da Pelve",
            "C47.6 - Neoplasia Maligna Dos Nervos Periféricos do Tronco",
            "C47.8 - Neoplasia Maligna Dos Nervos Periféricos e do Sistema Nervoso Autônomo Com Lesão Invasiva",
            "C47.9 - Neoplasia Maligna Dos Nervos Periféricos e Sistema Nervoso Autônomo, Não Especificados",
            "C48 - Neoplasia Maligna Dos Tecidos Moles do Retroperitônio e do Peritônio",
            "C48.0 - Neoplasia Maligna do Retroperitônio",
            "C48.1 - Neoplasia Maligna de Partes Especificadas do Peritônio",
            "C48.2 - Neoplasia Maligna do Peritônio",
            "C48.8 - Neoplasia Maligna Dos Tecidos Moles do Retroperitônio e do Peritônio Com Lesão Invasiva",
            "C49 - Neoplasia Maligna do Tecido Conjuntivo e de Outros Tecidos Moles",
            "C49.0 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles da Cabeça, Face e Pescoço",
            "C49.1 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles Dos Membros Superiores, Incluindo Ombro",
            "C49.2 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles Dos Membros Inferiores, Incluindo Quadril",
            "C49.3 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles do Tórax",
            "C49.4 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles do Abdome",
            "C49.5 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles da Pelve",
            "C49.6 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles do Tronco",
            "C49.8 - Neoplasia Maligna do Tecido Conjuntivo e Dos Tecidos Moles Com Lesão Invasiva",
            "C49.9 - Neoplasia Maligna do Tecido Conjuntivo e Tecidos Moles, Não Especificados",
            "C50 - Neoplasia Maligna da Mama",
            "C50.0 - Neoplasia Maligna do Mamilo e Aréola",
            "C50.1 - Neoplasia Maligna da Porção Central da Mama",
            "C50.2 - Neoplasia Maligna do Quadrante Superior Interno da Mama",
            "C50.3 - Neoplasia Maligna do Quadrante Inferior Interno da Mama",
            "C50.4 - Neoplasia Maligna do Quadrante Superior Externo da Mama",
            "C50.5 - Neoplasia Maligna do Quadrante Inferior Externo da Mama",
            "C50.6 - Neoplasia Maligna da Porção Axilar da Mama",
            "C50.8 - Neoplasia Maligna da Mama Com Lesão Invasiva",
            "C50.9 - Neoplasia Maligna da Mama, Não Especificada",
            "C51 - Neoplasia Maligna da Vulva",
            "C51.0 - Neoplasia Maligna Dos Grandes Lábios",
            "C51.1 - Neoplasia Maligna Dos Pequenos Lábios",
            "C51.2 - Neoplasia Maligna do Clitóris",
            "C51.8 - Neoplasia Maligna da Vulva Com Lesão Invasiva",
            "C51.9 - Neoplasia Maligna da Vulva, Não Especificada",
            "C52 - Neoplasia Maligna da Vagina",
            "C53 - Neoplasia Maligna do Colo do Útero",
            "C53.0 - Neoplasia Maligna do Endocérvix",
            "C53.1 - Neoplasia Maligna do Exocérvix",
            "C53.8 - Neoplasia Maligna do Colo do Útero Com Lesão Invasiva",
            "C53.9 - Neoplasia Maligna do Colo do Útero, Não Especificado",
            "C54 - Neoplasia Maligna do Corpo do Útero",
            "C54.0 - Neoplasia Maligna do Istmo do Útero",
            "C54.1 - Neoplasia Maligna do Endométrio",
            "C54.2 - Neoplasia Maligna do Miométrio",
            "C54.3 - Neoplasia Maligna do Fundo do Útero",
            "C54.8 - Neoplasia Maligna do Corpo do Útero Com Lesão Invasiva",
            "C54.9 - Neoplasia Maligna do Corpo do Útero, Não Especificado",
            "C55 - Neoplasia Maligna do Útero, Porção Não Especificada",
            "C56 - Neoplasia Maligna do Ovário",
            "C57 - Neoplasia Maligna de Outros Órgãos Genitais Femininos e Dos Não Especificados",
            "C57.0 - Neoplasia Maligna da Trompa de Falópio",
            "C57.1 - Neoplasia Maligna do Ligamento Largo",
            "C57.2 - Neoplasia Maligna do Ligamento Redondo",
            "C57.3 - Neoplasia Maligna do Paramétrio",
            "C57.4 - Neoplasia Maligna Dos Anexos Uterinos",
            "C57.7 - Neoplasia Maligna de Outras Partes Especificadas Dos Órgãos Genitais Femininos",
            "C57.8 - Neoplasia Maligna Dos Órgãos Genitais Femininos Com Lesão Invasiva",
            "C57.9 - Neoplasia Maligna de Órgão Genital Feminino, Não Especificado",
            "C58 - Neoplasia Maligna da Placenta",
            "C60 - Neoplasia Maligna do Pênis",
            "C60.0 - Neoplasia Maligna do Prepúcio",
            "C60.1 - Neoplasia Maligna da Glande",
            "C60.2 - Neoplasia Maligna do Corpo do Pênis",
            "C60.8 - Neoplasia Maligna do Pênis Com Lesão Invasiva",
            "C60.9 - Neoplasia Maligna do Pênis, Não Especificado",
            "C61 - Neoplasia Maligna da Próstata",
            "C62 - Neoplasia Maligna Dos Testículos",
            "C62.0 - Neoplasia Maligna do Testículo Criptorquídico",
            "C62.1 - Neoplasia Maligna do Testículo Tópico",
            "C62.9 - Neoplasia Maligna do Testículo, Sem Outras Especificações",
            "C63 - Neoplasia Maligna de Outros Órgãos Genitais Masculinos e Dos Não Especificados",
            "C63.0 - Neoplasia Maligna do Epidídimo",
            "C63.1 - Neoplasia Maligna do Cordão Espermático",
            "C63.2 - Neoplasia Maligna do Escroto",
            "C63.7 - Neoplasia Maligna de Outros Órgãos Genitais Masculinos Especificados",
            "C63.8 - Neoplasia Maligna Dos Órgãos Genitais Masculinos Com Lesão Invasiva",
            "C63.9 - Neoplasia Maligna de Órgão Genital Masculino, Não Especificado",
            "C64 - Neoplasia Maligna do Rim, Exceto Pelve Renal",
            "C65 - Neoplasia Maligna da Pelve Renal",
            "C66 - Neoplasia Maligna Dos Ureteres",
            "C67 - Neoplasia Maligna da Bexiga",
            "C67.0 - Neoplasia Maligna do Trígono da Bexiga",
            "C67.1 - Neoplasia Maligna da Cúpula da Bexiga",
            "C67.2 - Neoplasia Maligna da Parede Lateral da Bexiga",
            "C67.3 - Neoplasia Maligna da Parede Anterior da Bexiga",
            "C67.4 - Neoplasia Maligna da Parede Posterior da Bexiga",
            "C67.5 - Neoplasia Maligna do Colo da Bexiga",
            "C67.6 - Neoplasia Maligna do Orifício Uretérico",
            "C67.7 - Neoplasia Maligna do Úraco",
            "C67.8 - Neoplasia Maligna da Bexiga Com Lesão Invasiva",
            "C67.9 - Neoplasia Maligna da Bexiga, Sem Outra Especificações",
            "C68 - Neoplasia Maligna de Outros Órgãos Urinários e Dos Não Especificados",
            "C68.0 - Neoplasia Maligna da Uretra",
            "C68.1 - Neoplasia Maligna da Glândula Parauretral",
            "C68.8 - Neoplasia Maligna Dos Órgãos Urinários Com Lesão Invasiva",
            "C68.9 - Neoplasia Maligna de Órgão Urinário, Não Especificado",
            "C69 - Neoplasia Maligna do Olho e Anexos",
            "C69.0 - Neoplasia Maligna da Conjuntiva",
            "C69.1 - Neoplasia Maligna da Córnea",
            "C69.2 - Neoplasia Maligna da Retina",
            "C69.3 - Neoplasia Maligna da Coróide",
            "C69.4 - Neoplasia Maligna do Corpo Ciliar",
            "C69.5 - Neoplasia Maligna da Glândula e Canal Lacrimal",
            "C69.6 - Neoplasia Maligna da Órbita",
            "C69.8 - Neoplasia Maligna do Olho e Anexos Com Lesão Invasiva",
            "C69.9 - Neoplasia Maligna do Olho, Não Especificado",
            "C70 - Neoplasia Maligna Das Meninges",
            "C70.0 - Neoplasia Maligna Das Meninges Cerebrais",
            "C70.1 - Neoplasia Maligna Das Meninges Espinhais",
            "C70.9 - Neoplasia Maligna da Meninge, Não Especificada",
            "C71 - Neoplasia Maligna do Encéfalo",
            "C71.0 - Neoplasia Maligna do Cérebro, Exceto Lobos e Ventrículos",
            "C71.1 - Neoplasia Maligna do Lobo Frontal",
            "C71.2 - Neoplasia Maligna do Lobo Temporal",
            "C71.3 - Neoplasia Maligna do Lobo Parietal",
            "C71.4 - Neoplasia Maligna do Lobo Occipital",
            "C71.5 - Neoplasia Maligna do Ventrículo Cerebral",
            "C71.6 - Neoplasia Maligna do Cerebelo",
            "C71.7 - Neoplasia Maligna do Tronco Cerebral",
            "C71.8 - Neoplasia Maligna do Encéfalo Com Lesão Invasiva",
            "C71.9 - Neoplasia Maligna do Encéfalo, Não Especificado",
            "C72 - Neoplasia Maligna da Medula Espinhal, Dos Nervos Cranianos e de Outras Partes do Sistema Nervoso Central",
            "C72.0 - Neoplasia Maligna da Medula Espinhal",
            "C72.1 - Neoplasia Maligna da Cauda Eqüina",
            "C72.2 - Neoplasia Maligna do Nervo Olfativo",
            "C72.3 - Neoplasia Maligna do Nervo Óptico",
            "C72.4 - Neoplasia Maligna do Nervo Acústico",
            "C72.5 - Neoplasia Maligna de Outros Nervos Cranianos e os Não Especificados",
            "C72.8 - Neoplasia Maligna do Encéfalo e de Outras Partes do Sistema Nervoso Central Com Lesão Invasiva",
            "C72.9 - Neoplasia Maligna do Sistema Nervoso Central, Não Especificado",
            "C73 - Neoplasia Maligna da Glândula Tireóide",
            "C74 - Neoplasia Maligna da Glândula Supra-renal (Glândula Adrenal)",
            "C74.0 - Neoplasia Maligna do Córtex da Supra-renal",
            "C74.1 - Neoplasia Maligna da Medula da Supra-renal",
            "C74.9 - Neoplasia Maligna da Glândula Supra-renal, Não Especificada",
            "C75 - Neoplasia Maligna de Outras Glândulas Endócrinas e de Estruturas Relacionadas",
            "C75.0 - Neoplasia Maligna da Glândula Paratireóide",
            "C75.1 - Neoplasia Maligna da Glândula Hipófise (pituitária)",
            "C75.2 - Neoplasia Maligna do Conduto Craniofaríngeo",
            "C75.3 - Neoplasia Maligna da Glândula Pineal",
            "C75.4 - Neoplasia Maligna do Corpo Carotídeo",
            "C75.5 - Neoplasia Maligna do Corpo Aórtico e Outros Paragânglios",
            "C75.8 - Neoplasia Maligna Com Comprometimento Pluriglandular, Sem Outra Especificação",
            "C75.9 - Neoplasia Maligna de Glândula Endócrina, Não Especificada",
            "C76 - Neoplasia Maligna de Outras Localizações e de Localizações Mal Definidas",
            "C76.0 - Neoplasia Maligna da Cabeça, Face e Pescoço",
            "C76.1 - Neoplasia Maligna do Tórax",
            "C76.2 - Neoplasia Maligna do Abdome",
            "C76.3 - Neoplasia Maligna da Pelve",
            "C76.4 - Neoplasia Maligna do Membro Superior",
            "C76.5 - Neoplasia Maligna do Membro Inferior",
            "C76.7 - Neoplasia Maligna de Outras Localizações Mal Definidas",
            "C76.8 - Neoplasia Maligna de Outras Localizações e Das Mal Definidas Com Lesão Invasiva",
            "C77 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos",
            "C77.0 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos da Cabeça, Face e Pescoço",
            "C77.1 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos Intratorácicos",
            "C77.2 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos Intra-abdominais",
            "C77.3 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos Axilares e Dos Membros Superiores",
            "C77.4 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos Inguinais e Dos Membros Inferiores",
            "C77.5 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos Intrapélvicos",
            "C77.8 - Neoplasia Maligna Secundária e Não Especificada Dos Gânglios Linfáticos de Múltiplas Regiões",
            "C77.9 - Neoplasia Maligna Secundária e Não Especificada de Gânglio Linfático, Não Especificado",
            "C78 - Neoplasia Maligna Secundária Dos Órgãos Respiratórios e Digestivos",
            "C78.0 - Neoplasia Maligna Secundária Dos Pulmões",
            "C78.1 - Neoplasia Maligna Secundária do Mediastino",
            "C78.2 - Neoplasia Maligna Secundária da Pleura",
            "C78.3 - Neoplasia Maligna Secundária de Outros Órgãos Respiratórios e Não Especificados",
            "C78.4 - Neoplasia Maligna Secundária do Intestino Delgado",
            "C78.5 - Neoplasia Maligna Secundária do Intestino Grosso e do Reto",
            "C78.6 - Neoplasia Maligna Secundária do Retroperitônio e do Peritônio",
            "C78.7 - Neoplasia Maligna Secundária do Fígado",
            "C78.8 - Neoplasia Maligna Secundária de Outros Órgãos Digestivos e Não Especificados",
            "C79 - Neoplasia Maligna Secundária de Outras Localizações",
            "C79.0 - Neoplasia Maligna Secundária do Rim e da Pelve Renal",
            "C79.1 - Neoplasia Maligna Secundária da Bexiga, de Outro Órgão Urinário e Não Especificado",
            "C79.2 - Neoplasia Maligna Secundária da Pele",
            "C79.3 - Neoplasia Maligna Secundária do Encéfalo e Das Meninges Cerebrais",
            "C79.4 - Neoplasia Maligna Secundária de Outras Partes do Sistema Nervoso e Não Especificadas",
            "C79.5 - Neoplasia Maligna Secundária Dos Ossos e da Medula Óssea",
            "C79.6 - Neoplasia Maligna Secundária do Ovário",
            "C79.7 - Neoplasia Maligna Secundária Das Glândulas Supra-renais (adrenais)",
            "C79.8 - Neoplasia Maligna Secundária de Outra Localização Especificada",
            "C80 - Neoplasia Maligna, Sem Especificação de Localização",
            "C81 - Doença de Hodgkin",
            "C81.0 - Doença de Hodgkin, Predominância Linfocítica",
            "C81.1 - Doença de Hodgkin, Esclerose Nodular",
            "C81.2 - Doença de Hodgkin, Celularidade Mista",
            "C81.3 - Doença de Hodgkin, Depleção Linfocítica",
            "C81.7 - Outra Forma da Doença de Hodgkin",
            "C81.9 - Doença de Hodgkin, Não Especificada",
            "C82 - Linfoma Não-Hodgkin, Folicular (nodular)",
            "C82.0 - Linfoma Não-Hodgkin, Pequenas Células Clivadas, Folicular",
            "C82.1 - Linfoma Não-Hodgkin, Misto, de Pequenas e Grandes Células Clivadas, Folicular",
            "C82.2 - Linfoma Não-Hodgkin, Grandes Células, Folicular",
            "C82.7 - Outros Tipos de Linfoma Não-Hodgkin, Folicular",
            "C82.9 - Linfoma Não-Hodgkin, Folicular, Não Especificado",
            "C83 - Linfoma Não-Hodgkin Difuso",
            "C83.0 - Linfoma Não-Hodgkin Difuso, Pequenas Células (difuso)",
            "C83.1 - Linfoma Não-Hodgkin Difuso, Pequenas Células Clivadas (difuso)",
            "C83.2 - Linfoma Não-Hodgkin Difuso, Misto, de Pequenas e Grandes Células (difuso)",
            "C83.3 - Linfoma Não-Hodgkin Difuso, Grandes Células (difuso)",
            "C83.4 - Linfoma Não-Hodgkin Difuso, Imunoblástico (difuso)",
            "C83.5 - Linfoma Não-Hodgkin Difuso, Linfoblástico (difuso)",
            "C83.6 - Linfoma Não-Hodgkin Difuso, Indiferenciado (difuso)",
            "C83.7 - Tumor de Burkitt",
            "C83.8 - Outros Tipos de Linfoma Não-Hodgkin Difuso",
            "C83.9 - Linfoma Não-Hodgkin Difuso, Não Especificado",
            "C84 - Linfomas de Células T Cutâneas e Periféricas",
            "C84.0 - Micose Fungóide",
            "C84.1 - Doença de Sézary",
            "C84.2 - Linfoma da Zona T",
            "C84.3 - Linfoma Linfoepitelióide",
            "C84.4 - Linfoma de Células T, Periférico",
            "C84.5 - Outros Linfomas de Células T e os Não Especificados",
            "C85 - Linfoma Não-Hodgkin de Outros Tipos e de Tipo Não Especificado",
            "C85.0 - Linfossarcoma",
            "C85.1 - Linfoma de Células B, Não Especificado",
            "C85.7 - Outros Tipos Especificados de Linfoma Não-Hodgkin",
            "C85.9 - Linfoma Não-Hodgkin de Tipo Não Especificado",
            "C88 - Doenças Imunoproliferativas Malignas",
            "C88.0 - Macroglobulinemia de Waldenström",
            "C88.1 - Doença de Cadeia Pesada Alfa",
            "C88.2 - Doença de Cadeia Pesada Gama",
            "C88.3 - Doença Imunoproliferativa do Intestino Delgado",
            "C88.7 - Outras Doenças Imunoproliferativas Malignas",
            "C88.9 - Doença Imunoproliferativa Maligna, Não Especificada",
            "C90 - Mieloma Múltiplo e Neoplasias Malignas de Plasmócitos",
            "C90.0 - Mieloma Múltiplo",
            "C90.1 - Leucemia Plasmocitária",
            "C90.2 - Plasmocitoma Extramedular",
            "C91 - Leucemia Linfóide",
            "C91.0 - Leucemia Linfoblástica Aguda",
            "C91.1 - Leucemia Linfocítica Crônica",
            "C91.2 - Leucemia Linfocítica Subaguda",
            "C91.3 - Leucemia Pró-linfocítica",
            "C91.4 - Leucemia de Células Pilosas",
            "C91.5 - Leucemia de Células T do Adulto",
            "C91.7 - Outras Leucemias Linfóides",
            "C91.9 - Leucemia Linfóide, Não Especificada",
            "C92 - Leucemia Mielóide",
            "C92.0 - Leucemia Mielóide Aguda",
            "C92.1 - Leucemia Mielóide Crônica",
            "C92.2 - Leucemia Mielóide Subaguda",
            "C92.3 - Sarcoma Mielóide",
            "C92.4 - Leucemia Pró-mielocítica Aguda",
            "C92.5 - Leucemia Mielomonocítica Aguda",
            "C92.7 - Outras Leucemias Mielóides",
            "C92.9 - Leucemia Mielóide, Não Especificada",
            "C93 - Leucemia Monocítica",
            "C93.0 - Leucemia Monocítica Aguda",
            "C93.1 - Leucemia Monocítica Crônica",
            "C93.2 - Leucemia Monocítica Subaguda",
            "C93.7 - Outras Leucemias Monocíticas",
            "C93.9 - Leucemia Monocítica, Não Especificada",
            "C94 - Outras Leucemias de Células de Tipo Especificado",
            "C94.0 - Eritremia e Eritroleucemia Agudas",
            "C94.1 - Eritremia Crônica",
            "C94.2 - Leucemia Megacarioblástica Aguda",
            "C94.3 - Leucemia de Mastócitos",
            "C94.4 - Panmielose Aguda",
            "C94.5 - Mielofibrose Aguda",
            "C94.7 - Outras Leucemias Especificadas",
            "C95 - Leucemia de Tipo Celular Não Especificado",
            "C95.0 - Leucemia Aguda de Tipo Celular Não Especificado",
            "C95.1 - Leucemia Crônica de Tipo Celular Não Especificado",
            "C95.2 - Leucemia Subaguda de Tipo Celular Não Especificado",
            "C95.7 - Outras Leucemias de Tipo Celular Não Especificado",
            "C95.9 - Leucemia Não Especificada",
            "C96 - Outras Neoplasias Malignas e as Não Especificadas Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos",
            "C96.0 - Doença de Letterer-Siwe",
            "C96.1 - Histiocitose Maligna",
            "C96.2 - Tumor Maligno de Mastócitos",
            "C96.3 - Linfoma Histiocítico Verdadeiro",
            "C96.7 - Outras Neoplasias Malignas Especificadas Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos",
            "C96.9 - Neoplasia Maligna Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos, Não Especificada",
            "C97 - Neoplasias Malignas de Localizações Múltiplas Independentes (primárias)",
            "D00 - Carcinoma in Situ da Cavidade Oral, do Esôfago e do Estômago",
            "D00.0 - Carcinoma in Situ Dos Lábios, Cavidade Oral e Faringe",
            "D00.1 - Carcinoma in Situ do Esôfago",
            "D00.2 - Carcinoma in Situ do Estômago",
            "D01 - Carcinoma in Situ de Outros Órgãos Digestivos",
            "D01.0 - Carcinoma in Situ do Cólon",
            "D01.1 - Carcinoma in Situ da Junção Retossigmóide",
            "D01.2 - Carcinoma in Situ do Reto",
            "D01.3 - Carcinoma in Situ do Ânus e Canal Anal",
            "D01.4 - Carcinoma in Situ de Outras Partes do Intestino e as Não Especificadas",
            "D01.5 - Carcinoma in Situ do Fígado, Vesícula Biliar e Vias Biliares",
            "D01.7 - Carcinoma in Situ de Outros Órgãos Especificados do Aparelho Digestivo",
            "D01.9 - Carcinoma in Situ de Órgãos Digestivos, Não Especificado",
            "D02 - Carcinoma in Situ do Ouvido Médio e do Aparelho Respiratório",
            "D02.0 - Carcinoma in Situ da Laringe",
            "D02.1 - Carcinoma in Situ da Traquéia",
            "D02.2 - Carcinoma in Situ Dos Brônquios e Pulmões",
            "D02.3 - Carcinoma in Situ de Outras Partes do Aparelho Respiratório",
            "D02.9 - Carcinoma in Situ do Aparelho Respiratório, Não Especificado",
            "D03 - Melanoma in Situ",
            "D03.0 - Melanoma in Situ do Lábio",
            "D03.1 - Melanoma in Situ da Pálpebra, Incluindo o Canto",
            "D03.2 - Melanoma in Situ da Orelha e do Conduto Auditivo Externo",
            "D03.3 - Melanoma in Situ de Outras Partes, e de Partes Não Especificadas da Face",
            "D03.4 - Melanoma in Situ do Couro Cabeludo e do Pescoço",
            "D03.5 - Melanoma in Situ do Tronco",
            "D03.6 - Melanoma in Situ Dos Membros Superiores, Incluindo Ombro",
            "D03.7 - Melanoma in Situ Dos Membros Inferiores, Incluindo Quadril",
            "D03.8 - Melanoma in Situ de Outras Localizações",
            "D03.9 - Melanoma in Situ, Não Especificado",
            "D04 - Carcinoma in Situ da Pele",
            "D04.0 - Carcinoma in Situ da Pele do Lábio",
            "D04.1 - Carcinoma in Situ da Pele da Pálpebra, Incluindo o Canto",
            "D04.2 - Carcinoma in Situ da Pele da Orelha e do Conduto Auditivo Externo",
            "D04.3 - Carcinoma in Situ da Pele de Outras Partes e de Partes Não Especificadas da Face",
            "D04.4 - Carcinoma in Situ da Pele do Couro Cabeludo e do Pescoço",
            "D04.5 - Carcinoma in Situ da Pele do Tronco",
            "D04.6 - Carcinoma in Situ da Pele Dos Membros Superiores, Incluindo Ombro",
            "D04.7 - Carcinoma in Situ da Pele Dos Membros Inferiores, Incluindo Quadril",
            "D04.8 - Carcinoma in Situ da Pele de Outras Localizações",
            "D04.9 - Carcinoma in Situ da Pele, Não Especificada",
            "D05 - Carcinoma in Situ da Mama",
            "D05.0 - Carcinoma Lobular in Situ",
            "D05.1 - Carcinoma Intraductal in Situ",
            "D05.7 - Outros Carcinomas in Situ",
            "D05.9 - Carcinoma in Situ da Mama, Não Especificado",
            "D06 - Carcinoma in Situ do Colo do Útero (cérvix)",
            "D06.0 - Carcinoma in Situ do Endocérvix",
            "D06.1 - Carcinoma in Situ do Exocérvix",
            "D06.7 - Carcinoma in Situ de Outras Partes do Colo do Útero",
            "D06.9 - Carcinoma in Situ do Colo do Útero, Não Especificado",
            "D07 - Carcinoma in Situ de Outros Órgãos Genitais e Dos Não Especificados",
            "D07.0 - Carcinoma in Situ do Endométrio",
            "D07.1 - Carcinoma in Situ da Vulva",
            "D07.2 - Carcinoma in Situ da Vagina",
            "D07.3 - Carcinoma in Situ de Outros Órgãos Genitais Femininos e os Não Especificados",
            "D07.4 - Carcinoma in Situ do Pênis",
            "D07.5 - Carcinoma in Situ da Próstata",
            "D07.6 - Carcinoma in Situ de Outros Órgãos Genitais Masculinos e os Não Especificados",
            "D09 - Carcinoma in Situ de Outras Localizações e Das Não Especificadas",
            "D09.0 - Carcinoma in Situ da Bexiga",
            "D09.1 - Carcinoma in Situ de Outros Órgãos Urinários e os Não Especificados",
            "D09.2 - Carcinoma in Situ do Olho",
            "D09.3 - Carcinoma in Situ da Tireóide e de Outras Glândulas Endócrinas",
            "D09.7 - Carcinoma in Situ de Outras Localizações Especificadas",
            "D09.9 - Carcinoma in Situ, Não Especificado",
            "D10 - Neoplasia Benigna da Boca e da Faringe",
            "D10.0 - Neoplasia Benigna Dos Lábios",
            "D10.1 - Neoplasia Benigna da Língua",
            "D10.2 - Neoplasia Benigna do Assoalho da Boca",
            "D10.3 - Neoplasia Benigna de Outras Partes da Boca e as Não Especificadas",
            "D10.4 - Neoplasia Benigna da Amígdala",
            "D10.5 - Neoplasia Benigna de Outras Partes da Orofaringe",
            "D10.6 - Neoplasia Benigna da Nasofaringe",
            "D10.7 - Neoplasia Benigna da Hipofaringe",
            "D10.9 - Neoplasia Benigna da Faringe, Não Especificada",
            "D11 - Neoplasia Benigna de Glândulas Salivares Maiores",
            "D11.0 - Neoplasia Benigna da Glândula Parótida",
            "D11.7 - Neoplasia Benigna de Outras Glândulas Salivares Maiores",
            "D11.9 - Neoplasia Benigna da Glândula Salivar Maior, Não Especificada",
            "D12 - Neoplasia Benigna do Cólon, Reto, Canal Anal e Ânus",
            "D12.0 - Neoplasia Benigna do Ceco",
            "D12.1 - Neoplasia Benigna do Apêndice (vermiforme)",
            "D12.2 - Neoplasia Benigna do Cólon Ascendente",
            "D12.3 - Neoplasia Benigna do Cólon Transverso",
            "D12.4 - Neoplasia Benigna do Cólon Descendente",
            "D12.5 - Neoplasia Benigna do Cólon Sigmóide",
            "D12.6 - Neoplasia Benigna do Cólon, Não Especificada",
            "D12.7 - Neoplasia Benigna da Junção Retossigmóide",
            "D12.8 - Neoplasia Benigna do Reto",
            "D12.9 - Neoplasia Benigna do Canal Anal e Ânus",
            "D13 - Neoplasia Benigna de Outras Partes e de Partes Mal Definidas do Aparelho Digestivo",
            "D13.0 - Neoplasia Benigna do Esôfago",
            "D13.1 - Neoplasia Benigna do Estômago",
            "D13.2 - Neoplasia Benigna do Duodeno",
            "D13.3 - Neoplasia Benigna de Outras Partes e Partes Não Especificadas do Intestino Delgado",
            "D13.4 - Neoplasia Benigna do Fígado",
            "D13.5 - Neoplasia Benigna Das Vias Biliares Extra-hepáticas",
            "D13.6 - Neoplasia Benigna do Pâncreas",
            "D13.7 - Neoplasia Benigna do Pâncreas Endócrino",
            "D13.9 - Neoplasia Benigna de Localizações Mal Definidas do Aparelho Digestivo",
            "D14 - Neoplasia Benigna do Ouvido Médio e do Aparelho Respiratório",
            "D14.0 - Neoplasia Benigna do Ouvido Médio, Cavidade Nasal e Seios Paranasais",
            "D14.1 - Neoplasia Benigna da Laringe",
            "D14.2 - Neoplasia Benigna da Traquéia",
            "D14.3 - Neoplasia Benigna Dos Brônquios e Pulmão",
            "D14.4 - Neoplasia Benigna do Aparelho Respiratório, Não Especificado",
            "D15 - Neoplasia Benigna de Outros Órgãos Intratorácicos e Dos Não Especificados",
            "D15.0 - Neoplasia Benigna do Timo",
            "D15.1 - Neoplasia Benigna do Coração",
            "D15.2 - Neoplasia Benigna do Mediastino",
            "D15.7 - Neoplasia Benigna de Outros Órgãos Intratorácicos Especificados",
            "D15.9 - Neoplasia Benigna de Órgão Intratorácico, Não Especificado",
            "D16 - Neoplasia Benigna de Osso e de Cartilagem Articular",
            "D16.0 - Neoplasia Benigna da Omoplata (escápula) e Ossos Longos Dos Membros Superiores",
            "D16.1 - Neoplasia Benigna Dos Ossos Curtos Dos Membros Superiores",
            "D16.2 - Neoplasia Benigna Dos Ossos Longos Dos Membros Inferiores",
            "D16.3 - Neoplasia Benigna Dos Ossos Curtos Dos Membros Inferiores",
            "D16.4 - Neoplasia Benigna Dos Ossos do Crânio e da Face",
            "D16.5 - Neoplasia Benigna do Osso da Mandíbula",
            "D16.6 - Neoplasia Benigna da Coluna Vertebral",
            "D16.7 - Neoplasia Benigna Das Costelas, do Esterno e da Clavícula",
            "D16.8 - Neoplasia Benigna Dos Ossos Pélvicos, Sacro e Cóccix",
            "D16.9 - Neoplasia Benigna do Osso e Cartilagem Articular, Não Especificado",
            "D17 - Neoplasia Lipomatosa Benigna",
            "D17.0 - Neoplasia Lipomatosa Benigna da Pele e do Tecido Subcutâneo da Cabeça, Face e Pescoço",
            "D17.1 - Neoplasia Lipomatosa Benigna da Pele e Tecido Subcutâneo do Tronco",
            "D17.2 - Neoplasia Lipomatosa Benigna da Pele e Tecido Subcutâneo Dos Membros",
            "D17.3 - Neoplasia Lipomatosa Benigna da Pele e Tecido Subcutâneo de Outras Localizações e de Localizações Não Especificadas",
            "D17.4 - Neoplasia Lipomatosa Benigna de Órgãos Intratorácicos",
            "D17.5 - Neoplasia Lipomatosa Benigna de Órgãos Intra-abdominais",
            "D17.6 - Neoplasia Lipomatosa Benigna do Cordão Espermático",
            "D17.7 - Neoplasia Lipomatosa Benigna de Outras Localizações",
            "D17.9 - Neoplasia Lipomatosa Benigna de Localização Não Especificada",
            "D18 - Hemangioma e Linfangioma de Qualquer Localização",
            "D18.0 - Hemangioma de Qualquer Localização",
            "D18.1 - Linfangioma de Qualquer Localização",
            "D19 - Neoplasia Benigna de Tecido Mesotelial",
            "D19.0 - Neoplasia Benigna do Tecido Mesotelial da Pleura",
            "D19.1 - Neoplasia Benigna do Tecido Mesotelial do Peritônio",
            "D19.7 - Neoplasia Benigna do Tecido Mesotelial de Outras Localizações",
            "D19.9 - Neoplasia Benigna do Tecido Mesotelial, Não Especificado",
            "D20 - Neoplasia Benigna de Tecido Mole do Retroperitônio e do Peritônio",
            "D20.0 - Neoplasia Benigna do Retroperitônio",
            "D20.1 - Neoplasia Benigna do Peritônio",
            "D21 - Outras Neoplasias Benignas do Tecido Conjuntivo e de Outros Tecidos Moles",
            "D21.0 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles da Cabeça, Face e Pescoço",
            "D21.1 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles Dos Membros Superiores, Incluindo Ombro",
            "D21.2 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles Dos Membros Inferiores, Incluindo Quadril",
            "D21.3 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles do Tórax",
            "D21.4 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles do Abdome",
            "D21.5 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles da Pelve",
            "D21.6 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles do Tronco, Não Especificado",
            "D21.9 - Neoplasia Benigna do Tecido Conjuntivo e Outros Tecidos Moles, Sem Outra Especificação",
            "D22 - Nevos Melanocíticos",
            "D22.0 - Nevo Melanocítico do Lábio",
            "D22.1 - Nevo Melanocítico da Pálpebra, Incluindo o Canto",
            "D22.2 - Nevo Melanocítico da Orelha e do Conduto Auditivo Externo",
            "D22.3 - Nevo Melanocítico de Outras Partes e de Partes Não Especificadas da Face",
            "D22.4 - Nevo Melanocítico do Couro Cabeludo e do Pescoço",
            "D22.5 - Nevo Melanocítico do Tronco",
            "D22.6 - Nevo Melanocítico Dos Membros Superiores, Incluindo Ombro",
            "D22.7 - Nevo Melanocítico Dos Membros Inferiores, Incluindo Quadril",
            "D22.9 - Nevo Melanocítico, Não Especificado",
            "D23 - Outras Neoplasias Benignas da Pele",
            "D23.0 - Neoplasia Benigna da Pele Dos Lábios",
            "D23.1 - Neoplasia Benigna da Pele da Pálpebra, Incluindo o Canto",
            "D23.2 - Neoplasia Benigna da Pele da Orelha e do Conduto Auditivo Externo",
            "D23.3 - Neoplasia Benigna da Pele de Outras Partes e de Partes Não Especificadas da Face",
            "D23.4 - Neoplasia Benigna da Pele do Couro Cabeludo e do Pescoço",
            "D23.5 - Neoplasia Benigna da Pele do Tronco",
            "D23.6 - Neoplasia Benigna da Pele Dos Membros Superiores, Incluindo o Ombro",
            "D23.7 - Neoplasia Benigna da Pele Dos Membros Inferiores, Incluindo o Quadril",
            "D23.9 - Neoplasia Benigna da Pele, Não Especificada",
            "D24 - Neoplasia Benigna da Mama",
            "D25 - Leiomioma do Útero",
            "D25.0 - Leiomioma Submucoso do Útero",
            "D25.1 - Leiomioma Intramural do Útero",
            "D25.2 - Leiomioma Subseroso do Útero",
            "D25.9 - Leiomioma do Útero, Não Especificado",
            "D26 - Outras Neoplasias Benignas do Útero",
            "D26.0 - Neoplasia Benigna do Colo do Útero",
            "D26.1 - Neoplasia Benigna do Corpo do Útero",
            "D26.7 - Neoplasia Benigna de Outras Partes do Útero",
            "D26.9 - Neoplasia Benigna do Útero, Não Especificado",
            "D27 - Neoplasia Benigna do Ovário",
            "D28 - Neoplasia Benigna de Outros Órgãos Genitais Femininos e de Órgãos Não Especificados",
            "D28.0 - Neoplasia Benigna da Vulva",
            "D28.1 - Neoplasia Benigna da Vagina",
            "D28.2 - Neoplasia Benigna Das Trompas e Ligamentos Uterinos",
            "D28.7 - Neoplasia Benigna de Outros Órgãos Genitais Femininos Especificados",
            "D28.9 - Neoplasia Benigna de Órgão Genital Feminino, Não Especificado",
            "D29 - Neoplasia Benigna Dos Órgãos Genitais Masculinos",
            "D29.0 - Neoplasia Benigna do Pênis",
            "D29.1 - Neoplasia Benigna da Próstata",
            "D29.2 - Neoplasia Benigna Dos Testículos",
            "D29.3 - Neoplasia Benigna do Epidídimo",
            "D29.4 - Neoplasia Benigna do Escroto",
            "D29.7 - Neoplasia Benigna de Outros Órgãos Genitais Masculinos",
            "D29.9 - Neoplasia Benigna de Órgão Genital Masculino, Não Especificado",
            "D30 - Neoplasia Benigna Dos Órgãos Urinários",
            "D30.0 - Neoplasia Benigna do Rim",
            "D30.1 - Neoplasia Benigna da Pelve Renal",
            "D30.2 - Neoplasia Benigna do Ureter",
            "D30.3 - Neoplasia Benigna da Bexiga",
            "D30.4 - Neoplasia Benigna da Uretra",
            "D30.7 - Neoplasia Benigna de Outros Órgãos Urinários",
            "D30.9 - Neoplasia Benigna de Órgão Urinário, Não Especificado",
            "D31 - Neoplasia Benigna do Olho e Anexos",
            "D31.0 - Neoplasia Benigna da Conjuntiva",
            "D31.1 - Neoplasia Benigna da Córnea",
            "D31.2 - Neoplasia Benigna da Retina",
            "D31.3 - Neoplasia Benigna da Coróide",
            "D31.4 - Neoplasia Benigna do Corpo Ciliar",
            "D31.5 - Neoplasia Benigna Das Glândulas e Dos Canais Lacrimais",
            "D31.6 - Neoplasia Benigna da Órbita, Não Especificada",
            "D31.9 - Neoplasia Benigna do Olho, Não Especificado",
            "D32 - Neoplasia Benigna Das Meninges",
            "D32.0 - Neoplasia Benigna Das Meninges Cerebrais",
            "D32.1 - Neoplasia Benigna Das Meninges Espinhais",
            "D32.9 - Neoplasia Benigna Das Meninges, Não Especificada",
            "D33 - Neoplasia Benigna do Encéfalo e de Outras Partes do Sistema Nervoso Central",
            "D33.0 - Neoplasia Benigna do Encéfalo, Supratentorial",
            "D33.1 - Neoplasia Benigna do Encéfalo, Infratentorial",
            "D33.2 - Neoplasia Benigna do Encéfalo, Não Especificado",
            "D33.3 - Neoplasia Benigna Dos Nervos Cranianos",
            "D33.4 - Neoplasia Benigna da Medula Espinhal",
            "D33.7 - Neoplasia Benigna de Outras Partes Especificadas do Sistema Nervoso Central",
            "D33.9 - Neoplasia Benigna do Sistema Nervoso Central, Não Especificado",
            "D34 - Neoplasia Benigna da Glândula Tireóide",
            "D35 - Neoplasia Benigna de Outras Glândulas Endócrinas e Das Não Especificadas",
            "D35.0 - Neoplasia Benigna da Glândula Supra-renal (adrenal)",
            "D35.1 - Neoplasia Benigna da Glândula Paratireóide",
            "D35.2 - Neoplasia Benigna da Glândula Hipófise (pituitária)",
            "D35.3 - Neoplasia Benigna do Conduto Craniofaríngeo",
            "D35.4 - Neoplasia Benigna da Glândula Pineal",
            "D35.5 - Neoplasia Benigna do Corpo Carotídeo",
            "D35.6 - Neoplasia Benigna Dos Corpos Aórticos e Outros Paragânglios",
            "D35.7 - Neoplasia Benigna de Outras Glândulas Endócrinas Especificadas",
            "D35.8 - Neoplasia Benigna Com Comprometimento Pluriglandular",
            "D35.9 - Neoplasia Benigna de Glândula Endócrina, Não Especificada",
            "D36 - Neoplasia Benigna de Outras Localizações e de Localizações Não Especificadas",
            "D36.0 - Neoplasia Benigna Dos Gânglios Linfáticos (linfonodos)",
            "D36.1 - Neoplasia Benigna Dos Nervos Periféricos e Sistema Nervoso Autônomo",
            "D36.7 - Neoplasia Benigna de Outras Localizações Especificadas",
            "D36.9 - Neoplasia Benigna de Localização Não Especificada",
            "D37 - Neoplasia de Comportamento Incerto ou Desconhecido da Cavidade Oral e Dos Órgãos Digestivos",
            "D37.0 - Neoplasia de Comportamento Incerto ou Desconhecido do Lábio, Cavidade Oral e Faringe",
            "D37.1 - Neoplasia de Comportamento Incerto ou Desconhecido do Estômago",
            "D37.2 - Neoplasia de Comportamento Incerto ou Desconhecido do Intestino Delgado",
            "D37.3 - Neoplasia de Comportamento Incerto ou Desconhecido do Apêndice",
            "D37.4 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Cólons",
            "D37.5 - Neoplasia de Comportamento Incerto ou Desconhecido do Reto",
            "D37.6 - Neoplasia de Comportamento Incerto ou Desconhecido do Fígado, Vesícula Biliar e Vias Biliares",
            "D37.7 - Neoplasia de Comportamento Incerto ou Desconhecido de Outros Órgãos Digestivos",
            "D37.9 - Neoplasia de Comportamento Incerto ou Desconhecido de Órgão Digestivo, Não Especificado",
            "D38 - Neoplasia de Comportamento Incerto ou Desconhecido do Ouvido Médio e Dos Órgãos Respiratórios e Intratorácicos",
            "D38.0 - Neoplasia de Comportamento Incerto ou Desconhecido da Laringe",
            "D38.1 - Neoplasia de Comportamento Incerto ou Desconhecido da Traquéia, Brônquios e Pulmão",
            "D38.2 - Neoplasia de Comportamento Incerto ou Desconhecido da Pleura",
            "D38.3 - Neoplasia de Comportamento Incerto ou Desconhecido do Mediastino",
            "D38.4 - Neoplasia de Comportamento Incerto ou Desconhecido do Timo",
            "D38.5 - Neoplasia de Comportamento Incerto ou Desconhecido de Outros Órgãos Respiratórios",
            "D38.6 - Neoplasia de Comportamento Incerto ou Desconhecido de Órgão Respiratório, Não Especificado",
            "D39 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Órgãos Genitais Femininos",
            "D39.0 - Neoplasia de Comportamento Incerto ou Desconhecido do Útero",
            "D39.1 - Neoplasia de Comportamento Incerto ou Desconhecido do Ovário",
            "D39.2 - Neoplasia de Comportamento Incerto ou Desconhecido da Placenta",
            "D39.7 - Neoplasia de Comportamento Incerto ou Desconhecido de Outros Órgãos Genitais Femininos",
            "D39.9 - Neoplasia de Comportamento Incerto ou Desconhecido de Órgão Genital Feminino, Não Especificado",
            "D40 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Órgãos Genitais Masculinos",
            "D40.0 - Neoplasia de Comportamento Incerto ou Desconhecido da Próstata",
            "D40.1 - Neoplasia de Comportamento Incerto ou Desconhecido do Testículo",
            "D40.7 - Neoplasia de Comportamento Incerto ou Desconhecido de Outros Órgãos Genitais Masculinos",
            "D40.9 - Neoplasia de Comportamento Incerto ou Desconhecido de Órgão Genital Masculino, Não Especificado",
            "D41 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Órgãos Urinários",
            "D41.0 - Neoplasia de Comportamento Incerto ou Desconhecido do Rim",
            "D41.1 - Neoplasia de Comportamento Incerto ou Desconhecido da Pelve Renal",
            "D41.2 - Neoplasia de Comportamento Incerto ou Desconhecido do Ureter",
            "D41.3 - Neoplasia de Comportamento Incerto ou Desconhecido da Uretra",
            "D41.4 - Neoplasia de Comportamento Incerto ou Desconhecido da Bexiga",
            "D41.7 - Neoplasia de Comportamento Incerto ou Desconhecido de Outros Órgãos Urinários",
            "D41.9 - Neoplasia de Comportamento Incerto ou Desconhecido de Órgão Urinário, Não Especificado",
            "D42 - Neoplasia de Comportamento Incerto ou Desconhecido Das Meninges",
            "D42.0 - Neoplasia de Comportamento Incerto ou Desconhecido Das Meninges Cerebrais",
            "D42.1 - Neoplasia de Comportamento Incerto ou Desconhecido Das Meninges Espinhais",
            "D42.9 - Neoplasia de Comportamento Incerto ou Desconhecido de Meninges Não Especificadas",
            "D43 - Neoplasia de Comportamento Incerto ou Desconhecido do Encéfalo e do Sistema Nervoso Central",
            "D43.0 - Neoplasia de Comportamento Incerto ou Desconhecido do Encéfalo, Supratentorial",
            "D43.1 - Neoplasia de Comportamento Incerto ou Desconhecido do Encéfalo, Infratentorial",
            "D43.2 - Neoplasia de Comportamento Incerto ou Desconhecido do Encéfalo, Não Especificado",
            "D43.3 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Nervos Cranianos",
            "D43.4 - Neoplasia de Comportamento Incerto ou Desconhecido da Medula Espinhal",
            "D43.7 - Neoplasia de Comportamento Incerto ou Desconhecido de Outras Partes do Sistema Nervoso Central",
            "D43.9 - Neoplasia de Comportamento Incerto ou Desconhecido do Sistema Nervoso Central, Não Especificado",
            "D44 - Neoplasia de Comportamento Incerto ou Desconhecido Das Glândulas Endócrinas",
            "D44.0 - Neoplasia de Comportamento Incerto ou Desconhecido da Glândula Tireóide",
            "D44.1 - Neoplasia de Comportamento Incerto ou Desconhecido da Glândula Supra-renal (adrenal)",
            "D44.2 - Neoplasia de Comportamento Incerto ou Desconhecido da Glândula Paratireóide",
            "D44.3 - Neoplasia de Comportamento Incerto ou Desconhecido da Glândula Hipófise (pituitária)",
            "D44.4 - Neoplasia de Comportamento Incerto ou Desconhecido do Conduto Craniofaríngeo",
            "D44.5 - Neoplasia de Comportamento Incerto ou Desconhecido da Glândula Pineal",
            "D44.6 - Neoplasia de Comportamento Incerto ou Desconhecido do Corpo Carotídeo",
            "D44.7 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Corpos Aórticos e Outros Paragânglios",
            "D44.8 - Neoplasia de Comportamento Incerto ou Desconhecido Com Comprometimento Pluriglandular",
            "D44.9 - Neoplasia de Comportamento Incerto ou Desconhecido de Glândula Endócrina, Não Especificada",
            "D45 - Policitemia Vera",
            "D46 - Síndromes Mielodisplásicas",
            "D46.0 - Anemia Refratária Sem Sideroblastos",
            "D46.1 - Anemia Refratária Com Sideroblastos",
            "D46.2 - Anemia Refratária Com Excesso de Blastos",
            "D46.3 - Anemia Refratária Com Excesso de Blastos Com Transformação",
            "D46.4 - Anemia Refratária, Não Especificada",
            "D46.7 - Outras Síndromes Mielodisplásicas",
            "D46.9 - Síndrome Mielodisplásica, Não Especificada",
            "D47 - Outras Neoplasias de Comportamento Incerto ou Desconhecido Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos",
            "D47.0 - Tumores de Comportamento Incerto ou Desconhecido de Mastócitos e Células Histiocíticas",
            "D47.1 - Doença Mieloproliferativa Crônica",
            "D47.2 - Gamopatia Monoclonal",
            "D47.3 - Trombocitemia Essencial (hemorrágica)",
            "D47.7 - Outras Neoplasias Especificadas de Comportamento Incerto ou Desconhecido Dos Tecidos Linfático, Hematopoético e Tecidos Relacionados",
            "D47.9 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Tecidos Linfático, Hematopoético e Tecidos Correlatos, Não Especificada",
            "D48 - Neoplasia de Comportamento Incerto ou Desconhecido de Outras Localizações e de Localizações Não Especificadas",
            "D48.0 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Ossos e Cartilagens Articulares",
            "D48.1 - Neoplasia de Comportamento Incerto ou Desconhecido do Tecido Conjuntivo e Outros Tecidos Moles",
            "D48.2 - Neoplasia de Comportamento Incerto ou Desconhecido Dos Nervos Periféricos e do Sistema Nervoso Periférico",
            "D48.3 - Neoplasia de Comportamento Incerto ou Desconhecido do Retroperitônio",
            "D48.4 - Neoplasia de Comportamento Incerto ou Desconhecido do Peritônio",
            "D48.5 - Neoplasia de Comportamento Incerto ou Desconhecido da Pele",
            "D48.6 - Neoplasia de Comportamento Incerto ou Desconhecido da Mama",
            "D48.7 - Neoplasia de Comportamento Incerto ou Desconhecido de Outras Localizações Especificadas",
            "D48.9 - Neoplasia de Comportamento Incerto ou Desconhecido Sem Outra Especificação",
            "D50 - Anemia Por Deficiência de Ferro",
            "D50.0 - Anemia Por Deficiência de Ferro Secundária à Perda de Sangue (crônica)",
            "D50.1 - Disfagia Sideropênica",
            "D50.8 - Outras Anemias Por Deficiência de Ferro",
            "D50.9 - Anemia Por Deficiência de Ferro Não Especificada",
            "D51 - Anemia Por Deficiência de Vitamina B12",
            "D51.0 - Anemia Por Deficiência de Vitamina B12 Devida à Deficiência de Fator Intrínseco",
            "D51.1 - Anemia Por Deficiência de Vitamina B12 Devida à Má-absorção Seletiva de Vitamina B12 Com Proteinúria",
            "D51.2 - Deficiência de Transcobalamina II",
            "D51.3 - Outras Anemias Por Deficiência de Vitamina B12 na Dieta",
            "D51.8 - Outras Anemias Por Deficiência de Vitamina B12",
            "D51.9 - Anemia Por Deficiência de Vitamina B12 Não Especificada",
            "D52 - Anemia Por Deficiência de Folato",
            "D52.0 - Anemia Por Deficiência de Folato na Dieta",
            "D52.1 - Anemia Por Deficiência de Folato Induzida Por Drogas",
            "D52.8 - Outras Anemias Por Deficiência de Folato",
            "D52.9 - Anemia Por Deficiência de Folato Não Especificada",
            "D53 - Outras Anemias Nutricionais",
            "D53.0 - Anemia Por Deficiência de Proteínas",
            "D53.1 - Outras Anemias Megaloblásticas Não Classificadas em Outras Partes",
            "D53.2 - Anemia Escorbútica",
            "D53.8 - Outras Anemias Nutricionais Especificadas",
            "D53.9 - Anemia Nutricional Não Especificada",
            "D55 - Anemia Devida a Transtornos Enzimáticos",
            "D55.0 - Anemia Devida à Deficiência de Glicose-6-fosfato-desidrogenase (G-6-PD)",
            "D55.1 - Anemia Devida a Outros Transtornos do Metabolismo do Glutation",
            "D55.2 - Anemia Devida a Transtornos Das Enzimas Glicolíticas",
            "D55.3 - Anemia Devida a Transtornos do Metabolismo de Nucleotídios",
            "D55.8 - Outras Anemias Devidas a Transtornos Enzimáticos",
            "D55.9 - Anemia Devida a Transtorno Enzimático Não Especificada",
            "D56 - Talassemia",
            "D56.0 - Talassemia Alfa",
            "D56.1 - Talassemia Beta",
            "D56.2 - Talassemia Delta-beta",
            "D56.3 - Estigma Talassêmico",
            "D56.4 - Persistência Hereditária de Hemoglobina Fetal",
            "D56.8 - Outras Talassemias",
            "D56.9 - Talassemia Não Especificada",
            "D57 - Transtornos Falciformes",
            "D57.0 - Anemia Falciforme Com Crise",
            "D57.1 - Anemia Falciforme Sem Crise",
            "D57.2 - Transtornos Falciformes Heterozigóticos Duplos",
            "D57.3 - Estigma Falciforme",
            "D57.8 - Outros Transtornos Falciformes",
            "D58 - Outras Anemias Hemolíticas Hereditárias",
            "D58.0 - Esferocitose Hereditária",
            "D58.1 - Eliptocitose Hereditária",
            "D58.2 - Outras Hemoglobinopatias",
            "D58.8 - Outras Anemias Hemolíticas Hereditárias Especificadas",
            "D58.9 - Anemia Hemolítica Hereditária Não Especificada",
            "D59 - Anemia Hemolítica Adquirida",
            "D59.0 - Anemia Hemolítica Auto-imune Induzida Por Droga",
            "D59.1 - Outras Anemias Hemolíticas Auto-imunes",
            "D59.2 - Anemia Hemolítica Não-auto-imune Induzida Por Drogas",
            "D59.3 - Síndrome Hemolítico-urêmica",
            "D59.4 - Outras Anemias Hemolíticas Não-autoimunes",
            "D59.5 - Hemoglobinúria Paroxística Noturna (Marchiafava-Micheli)",
            "D59.6 - Hemoglobinúria Devida à Hemólise Por Outras Causas Externas",
            "D59.8 - Outras Anemias Hemolíticas Adquiridas",
            "D59.9 - Anemia Hemolítica Adquirida Não Especificada",
            "D60 - Aplasia Pura da Série Vermelha, Adquirida (eritroblastopenia)",
            "D60.0 - Aplasia Pura Adquirida Crônica da Série Vermelha",
            "D60.1 - Aplasia Pura Adquirida Transitória da Série Vermelha",
            "D60.8 - Outras Aplasias Puras Adquiridas da Série Vermelha",
            "D60.9 - Aplasia Pura Adquirida, Não Especificada, da Série Vermelha",
            "D61 - Outras Anemias Aplásticas",
            "D61.0 - Anemia Aplástica Constitucional",
            "D61.1 - Anemia Aplástica Induzida Por Drogas",
            "D61.2 - Anemia Aplástica Devida a Outros Agentes Externos",
            "D61.3 - Anemia Aplástica Idiopática",
            "D61.8 - Outras Anemias Aplásticas Especificadas",
            "D61.9 - Anemia Aplástica Não Especificada",
            "D62 - Anemia Aguda Pós-hemorrágica",
            "D63 - Anemia em Doenças Crônicas Classificadas em Outra Parte",
            "D63.0 - Anemia em Neoplasias",
            "D63.8 - Anemia em Outras Doenças Classificadas em Outra Parte",
            "D64 - Outras Anemias",
            "D64.0 - Anemia Sideroblástica Hereditária",
            "D64.1 - Anemia Sideroblástica Secundária a Doença",
            "D64.2 - Anemia Sideroblástica Secundária ao Uso de Drogas e a Toxinas",
            "D64.3 - Outras Anemias Sideroblásticas",
            "D64.4 - Anemia Diseritropoética Congênita",
            "D64.8 - Outras Anemias Especificadas",
            "D64.9 - Anemia Não Especificada",
            "D65 - Coagulação Intravascular Disseminada (síndrome de Desfibrinação)",
            "D66 - Deficiência Hereditária do Fator VIII",
            "D67 - Deficiência Hereditária do Fator IX",
            "D68 - Outros Defeitos da Coagulação",
            "D68.0 - Doença de Von Willebrand",
            "D68.1 - Deficiência Hereditária de Fator XI",
            "D68.2 - Deficiência Hereditária de Outros Fatores de Coagulação",
            "D68.3 - Transtorno Hemorrágico Devido a Anticoagulantes Circulantes",
            "D68.4 - Deficiência Adquirida de Fator de Coagulação",
            "D68.8 - Outros Defeitos Especificados da Coagulação",
            "D68.9 - Defeito de Coagulação Não Especificado",
            "D69 - Púrpura e Outras Afecções Hemorrágicas",
            "D69.0 - Púrpura Alérgica",
            "D69.1 - Defeitos Qualitativos Das Plaquetas",
            "D69.2 - Outras Púrpuras Não-trombocitopênicas",
            "D69.3 - Púrpura Trombocitopênica Idiopática",
            "D69.4 - Outra Trombocitopenia Primária",
            "D69.5 - Trombocitopenia Secundária",
            "D69.6 - Trombocitopenia Não Especificada",
            "D69.8 - Outras Afecções Hemorrágicas Especificadas",
            "D69.9 - Afecção Hemorrágica Não Especificada",
            "D70 - Agranulocitose",
            "D71 - Transtornos Funcionais Dos Neutrófilos Polimorfonucleares",
            "D72 - Outros Transtornos Dos Glóbulos Brancos",
            "D72.0 - Anomalias Genéticas Dos Leucócitos",
            "D72.1 - Eosinofilia",
            "D72.8 - Outros Transtornos Especificados Dos Glóbulos Brancos",
            "D72.9 - Transtornos Não Especificados Dos Glóbulos Brancos",
            "D73 - Doenças do Baço",
            "D73.0 - Hipoesplenismo",
            "D73.1 - Hiperesplenismo",
            "D73.2 - Esplenomegalia Congestiva Crônica",
            "D73.3 - Abscesso do Baço",
            "D73.4 - Cisto do Baço",
            "D73.5 - Infarto do Baço",
            "D73.8 - Outras Doenças do Baço",
            "D73.9 - Doença Não Especificada do Baço",
            "D74 - Metemoglobinemia",
            "D74.0 - Metemoglobinemia Congênita",
            "D74.8 - Outras Metemoglobinemias",
            "D74.9 - Metemoglobinemia Não Especificada",
            "D75 - Outras Doenças do Sangue e Dos Órgãos Hematopoéticos",
            "D75.0 - Eritrocitose Familiar",
            "D75.1 - Policitemia Secundária",
            "D75.2 - Trombocitose Essencial",
            "D75.8 - Outras Doenças Especificadas do Sangue e Dos Órgãos Hematopoéticos",
            "D75.9 - Doença Não Especificada do Sangue e Dos Órgãos Hematopoéticos",
            "D76 - Algumas Doenças Que Envolvem o Tecido Linforreticular e o Sistema Reticulohistiocítico",
            "D76.0 - Histiocitose Das Células de Langerhans Não Classificadas em Outra Parte",
            "D76.1 - Linfohistiocitose Hemofagocítica",
            "D76.2 - Síndrome Hematofagocítica Associada à Infecção",
            "D76.3 - Outras Síndromes Histiocíticas",
            "D77 - Outros Transtornos do Sangue e Dos Órgãos Hematopoéticos em Doenças Classificadas em Outra Parte",
            "D80 - Imunodeficiência Com Predominância de Defeitos de Anticorpos",
            "D80.0 - Hipogamaglobulinemia Hereditária",
            "D80.1 - Hipogamaglobulinemia Não Familiar",
            "D80.2 - Deficiência Seletiva de Imunoglobulina A (IgA)",
            "D80.3 - Deficiência Seletiva de Subclasses de Imunoglobulina G (IgG)",
            "D80.4 - Deficiência Seletiva de Imunoglobulina M (IgM)",
            "D80.5 - Imunodeficiência Com Aumento de Imunoglobulina M (IgM)",
            "D80.6 - Deficiência de Anticorpos Com Imunoglobulinas Próximas do Normal ou Com Hiperimunoglobulinemia",
            "D80.7 - Hipogamaglobulinemia Transitória da Infância",
            "D80.8 - Outras Imunodeficiências Com Predominância de Defeitos de Anticorpos",
            "D80.9 - Imunodeficiência Não Especificada Com Predominância de Defeitos de Anticorpos",
            "D81 - Deficiências Imunitárias Combinadas",
            "D81.0 - Imunodeficiência Combinada Grave (SCID) Com Disgenesia Reticular",
            "D81.1 - Imunodeficiência Combinada Grave (SCID) Com Números Baixos de Células T e B",
            "D81.2 - Imunodeficiência Combinada Grave (SCID) Com Números Baixos ou Normais de Células B",
            "D81.3 - Deficiência de Adenosina-deaminase (ADA)",
            "D81.4 - Síndrome de Nezelof",
            "D81.5 - Deficiência de Purina-nucleosídeo Fosforilase (PNP)",
            "D81.6 - Deficiência Major Classe I do Complexo de Histocompatibilidade",
            "D81.7 - Deficiência Major Classe II do Complexo de Histocompatibilidade",
            "D81.8 - Outras Deficiências Imunitárias Combinadas",
            "D81.9 - Deficiências Imunitárias Combinadas Não Especificadas",
            "D82 - Imunodeficiência Associada Com Outros Defeitos major",
            "D82.0 - Síndrome de Wiskott-Aldrich",
            "D82.1 - Síndrome de Di George",
            "D82.2 - Imunodeficiência Com Encurtamento de Membros",
            "D82.3 - Imunodeficiência Que se Segue à Resposta Hereditária Defeituosa ao Vírus de Epstein-Barr (EB)",
            "D82.4 - Síndrome da Hiperimunoglobulina E (IgE)",
            "D82.8 - Imunodeficiências Associadas Com Outros Defeitos major Especificados",
            "D82.9 - Imunodeficiência Associada Com Defeitos major Não Especificados",
            "D83 - Imunodeficiência Comum Variável",
            "D83.0 - Imunodeficiência Comum Variável Com Predominância de Anormalidades do Número e da Função Das Células B",
            "D83.1 - Imunodeficiência Comum Variável Com Predominância de Transtornos Imunorregulatórios de Células T",
            "D83.2 - Imunodeficiência Comum Variável Com Auto-anticorpos às Células B ou T",
            "D83.8 - Outras Imunodeficiências Comuns Variáveis",
            "D83.9 - Imunodeficiência Comum Variável Não Especificada",
            "D84 - Outras Imunodeficiências",
            "D84.0 - Defeito do Antígeno-1 da Função de Linfócito (LFA-1)",
            "D84.1 - Defeitos no Sistema Complemento",
            "D84.8 - Outras Imunodeficiências Especificadas",
            "D84.9 - Imunodeficiência Não Especificada",
            "D86 - Sarcoidose",
            "D86.0 - Sarcoidose do Pulmão",
            "D86.1 - Sarcoidose Dos Gânglios Linfáticos",
            "D86.2 - Sarcoidose do Pulmão Com Sarcoidose Dos Gânglios Linfáticos",
            "D86.3 - Sarcoidose da Pele",
            "D86.8 - Sarcoidose de Outros Locais Especificados e Locais Combinados",
            "D86.9 - Sarcoidose Não Especificada",
            "D89 - Outros Transtornos Que Comprometem o Mecanismo Imunitário Não Classificados em Outra Parte",
            "D89.0 - Hipergamaglobulinemia Policlonal",
            "D89.1 - Crioglobulinemia",
            "D89.2 - Hipergamaglobulinemia Não Especificada",
            "D89.8 - Outros Transtornos Especificados Que Comprometem o Mecanismo Imunitário Não Classificados em Outra Parte",
            "D89.9 - Transtornos Não Especificados Que Comprometem o Mecanismo Imunitário",
            "E00 - Síndrome de Deficiência Congênita de Iodo",
            "E00.0 - Síndrome da Deficiência Congênita de Iodo do Tipo Neurológico",
            "E00.1 - Síndrome da Deficiência Congênita de Iodo do Tipo Mixedematoso",
            "E00.2 - Síndrome da Deficiência Congênita de Iodo do Tipo Misto",
            "E00.9 - Síndrome da Deficiência Congênita de Iodo, Não Especificada",
            "E01 - Transtornos Tireoidianos e Afecções Associadas, Relacionados à Deficiência de Iodo",
            "E01.0 - Bócio (endêmico) Difuso Por Deficiência de Iodo",
            "E01.1 - Bócio (endêmico) Multinodular Por Deficiência de Iodo",
            "E01.2 - Bócio (endêmico) Não Especificado, Por Deficiência de Iodo",
            "E01.8 - Outros Transtornos Tireoidianos e Afecções Associadas, Relacionados Com a Deficiência de Iodo",
            "E02 - Hipotireoidismo Subclínico Por Deficiência de Iodo",
            "E03 - Outros Hipotireoidismos",
            "E03.0 - Hipotireoidismo Congênito Com Bócio Difuso",
            "E03.1 - Hipotireoidismo Congênito Sem Bócio",
            "E03.2 - Hipotireoidismo Devido a Medicamentos e Outras Substâncias Exógenas",
            "E03.3 - Hipotireoidismo Pós-infeccioso",
            "E03.4 - Atrofia (adquirida) da Tireóide",
            "E03.5 - Coma Mixedematoso",
            "E03.8 - Outros Hipotireoidismos Especificados",
            "E03.9 - Hipotireoidismo Não Especificado",
            "E04 - Outros Bócios Não-tóxicos",
            "E04.0 - Bócio Não-tóxico Difuso",
            "E04.1 - Bócio Não-tóxico Uninodular",
            "E04.2 - Bócio Não-tóxico Multinodular",
            "E04.8 - Outro Bócio Não-tóxico Especificado",
            "E04.9 - Bócio Não-tóxico, Não Especificado",
            "E05 - Tireotoxicose (hipertireoidismo)",
            "E05.0 - Tireotoxicose Com Bócio Difuso",
            "E05.1 - Tireotoxicose Com Bócio Tóxico Uninodular",
            "E05.2 - Tireotoxicose Com Bócio Tóxico Multinodular",
            "E05.3 - Tireotoxicose Causada Por Tecido Tireoidiano Ectópico",
            "E05.4 - Tireotoxicose Factícia",
            "E05.5 - Crise ou tempestade Tireotóxica",
            "E05.8 - Outras Tireotoxicoses",
            "E05.9 - Tireotoxicose Não Especificada",
            "E06 - Tireoidite",
            "E06.0 - Tireoidite Aguda",
            "E06.1 - Tireoidite Subaguda",
            "E06.2 - Tireoidite Crônica Com Tireotoxicose Transitória",
            "E06.3 - Tireoidite Auto-imune",
            "E06.4 - Tireoidite Induzida Por Droga",
            "E06.5 - Outras Tireoidites Crônicas",
            "E06.9 - Tireoidite Não Especificada",
            "E07 - Outros Transtornos da Tireóide",
            "E07.0 - Hipersecreção de Calcitonina",
            "E07.1 - Bócio Disormonogênico",
            "E07.8 - Outros Transtornos Especificados da Tireóide",
            "E07.9 - Transtorno Não Especificado da Tireóide",
            "E10 - Diabetes Mellitus Insulino-dependente",
            "E10.0 - Diabetes Mellitus Insulino-dependente - Com Coma",
            "E10.1 - Diabetes Mellitus Insulino-dependente - Com Cetoacidose",
            "E10.2 - Diabetes Mellitus Insulino-dependente - Com Complicações Renais",
            "E10.3 - Diabetes Mellitus Insulino-dependente - Com Complicações Oftálmicas",
            "E10.4 - Diabetes Mellitus Insulino-dependente - Com Complicações Neurológicas",
            "E10.5 - Diabetes Mellitus Insulino-dependente - Com Complicações Circulatórias Periféricas",
            "E10.6 - Diabetes Mellitus Insulino-dependente - Com Outras Complicações Especificadas",
            "E10.7 - Diabetes Mellitus Insulino-dependente - Com Complicações Múltiplas",
            "E10.8 - Diabetes Mellitus Insulino-dependente - Com Complicações Não Especificadas",
            "E10.9 - Diabetes Mellitus Insulino-dependente - Sem Complicações",
            "E11 - Diabetes Mellitus Não-insulino-dependente",
            "E11.0 - Diabetes Mellitus Não-insulino-dependente - Com Coma",
            "E11.1 - Diabetes Mellitus Não-insulino-dependente - Com Cetoacidose",
            "E11.2 - Diabetes Mellitus Não-insulino-dependente - Com Complicações Renais",
            "E11.3 - Diabetes Mellitus Não-insulino-dependente - Com Complicações Oftálmicas",
            "E11.4 - Diabetes Mellitus Não-insulino-dependente - Com Complicações Neurológicas",
            "E11.5 - Diabetes Mellitus Não-insulino-dependente - Com Complicações Circulatórias Periféricas",
            "E11.6 - Diabetes Mellitus Não-insulino-dependente - Com Outras Complicações Especificadas",
            "E11.7 - Diabetes Mellitus Não-insulino-dependente - Com Complicações Múltiplas",
            "E11.8 - Diabetes Mellitus Não-insulino-dependente - Com Complicações Não Especificadas",
            "E11.9 - Diabetes Mellitus Não-insulino-dependente - Sem Complicações",
            "E12 - Diabetes Mellitus Relacionado Com a Desnutrição",
            "E12.0 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Coma",
            "E12.1 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Cetoacidose",
            "E12.2 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Complicações Renais",
            "E12.3 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Complicações Oftálmicas",
            "E12.4 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Complicações Neurológicas",
            "E12.5 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Complicações Circulatórias Periféricas",
            "E12.6 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Outras Complicações Especificadas",
            "E12.7 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Complicações Múltiplas",
            "E12.8 - Diabetes Mellitus Relacionado Com a Desnutrição - Com Complicações Não Especificadas",
            "E12.9 - Diabetes Mellitus Relacionado Com a Desnutrição - Sem Complicações",
            "E13 - Outros Tipos Especificados de Diabetes Mellitus",
            "E13.0 - Outros Tipos Especificados de Diabetes Mellitus - Com Coma",
            "E13.1 - Outros Tipos Especificados de Diabetes Mellitus - Com Cetoacidose",
            "E13.2 - Outros Tipos Especificados de Diabetes Mellitus - Com Complicações Renais",
            "E13.3 - Outros Tipos Especificados de Diabetes Mellitus - Com Complicações Oftálmicas",
            "E13.4 - Outros Tipos Especificados de Diabetes Mellitus - Com Complicações Neurológicas",
            "E13.5 - Outros Tipos Especificados de Diabetes Mellitus - Com Complicações Circulatórias Periféricas",
            "E13.6 - Outros Tipos Especificados de Diabetes Mellitus - Com Outras Complicações Especificadas",
            "E13.7 - Outros Tipos Especificados de Diabetes Mellitus - Com Complicações Múltiplas",
            "E13.8 - Outros Tipos Especificados de Diabetes Mellitus - Com Complicações Não Especificadas",
            "E13.9 - Outros Tipos Especificados de Diabetes Mellitus - Sem Complicações",
            "E14 - Diabetes Mellitus Não Especificado",
            "E14.0 - Diabetes Mellitus Não Especificado - Com Coma",
            "E14.1 - Diabetes Mellitus Não Especificado - Com Cetoacidose",
            "E14.2 - Diabetes Mellitus Não Especificado - Com Complicações Renais",
            "E14.3 - Diabetes Mellitus Não Especificado - Com Complicações Oftálmicas",
            "E14.4 - Diabetes Mellitus Não Especificado - Com Complicações Neurológicas",
            "E14.5 - Diabetes Mellitus Não Especificado - Com Complicações Circulatórias Periféricas",
            "E14.6 - Diabetes Mellitus Não Especificado - Com Outras Complicações Especificadas",
            "E14.7 - Diabetes Mellitus Não Especificado - Com Complicações Múltiplas",
            "E14.8 - Diabetes Mellitus Não Especificado - Com Complicações Não Especificadas",
            "E14.9 - Diabetes Mellitus Não Especificado - Sem Complicações",
            "E15 - Coma Hipoglicêmico Não-diabético",
            "E16 - Outros Transtornos da Secreção Pancreática Interna",
            "E16.0 - Hipoglicemia Induzida Por Droga Sem Coma",
            "E16.1 - Outra Hipoglicemia",
            "E16.2 - Hipoglicemia Não Especificada",
            "E16.3 - Aumento da Secreção de Glucagon",
            "E16.4 - Secreção Anormal de Gastrina",
            "E16.8 - Outros Transtornos Especificados da Secreção Pancreática Interna",
            "E16.9 - Transtorno Não Especificado da Secreção Pancreática Interna",
            "E20 - Hipoparatireoidismo",
            "E20.0 - Hipoparatireoidismo Idiopático",
            "E20.1 - Pseudohipoparatireoidismo",
            "E20.8 - Outro Hipoparatireoidismo",
            "E20.9 - Hipoparatireoidismo Não Especificado",
            "E21 - Hiperparatireoidismo e Outros Transtornos da Glândula Paratireóide",
            "E21.0 - Hiperparatireoidismo Primário",
            "E21.1 - Hiperparatireoidismo Secundário Não Classificado em Outra Parte",
            "E21.2 - Outro Hiperparatireoidismo",
            "E21.3 - Hiperparatireoidismo Não Especificado",
            "E21.4 - Outros Transtornos Especificados da Glândula Paratireóide",
            "E21.5 - Transtorno Não Especificado da Glândula Paratireóide",
            "E22 - Hiperfunção da Hipófise",
            "E22.0 - Acromegalia e Gigantismo Hipofisário",
            "E22.1 - Hiperprolactinemia",
            "E22.2 - Síndrome da Secreção Inadequada de Hormônio Antidiurético",
            "E22.8 - Outras Hiperfunções da Hipófise",
            "E22.9 - Hiperfunção Não Especificada da Hipófise",
            "E23 - Hipofunção e Outros Transtornos da Hipófise",
            "E23.0 - Hipopituitarismo",
            "E23.1 - Hipopituitarismo Induzido Por Droga",
            "E23.2 - Diabetes Insípido",
            "E23.3 - Disfunção Hipotalâmica Não Classificada em Outra Parte",
            "E23.6 - Outros Transtornos da Hipófise",
            "E23.7 - Transtorno Não Especificado da Hipófise",
            "E24 - Síndrome de Cushing",
            "E24.0 - Síndrome de Cushing Dependente da Hipófise",
            "E24.1 - Síndrome de Nelson",
            "E24.2 - Síndrome de Cushing Induzida Por Droga",
            "E24.3 - Síndrome do ACTH Ectópico",
            "E24.4 - Síndrome de Pseudo-Cushing Induzida Pelo Álcool",
            "E24.8 - Outra Síndrome de Cushing",
            "E24.9 - Síndrome de Cushing Não Especificada",
            "E25 - Transtornos Adrenogenitais",
            "E25.0 - Transtornos Adrenogenitais Congênitos Associados à Deficiência Enzimática",
            "E25.8 - Outros Transtornos Adrenogenitais",
            "E25.9 - Transtorno Adrenogenital Não Especificado",
            "E26 - Hiperaldosteronismo",
            "E26.0 - Hiperaldosteronismo Primário",
            "E26.1 - Hiperaldosteronismo Secundário",
            "E26.8 - Outro Hiperaldosteronismo",
            "E26.9 - Hiperaldosteronismo Não Especificado",
            "E27 - Outros Transtornos da Glândula Supra-renal",
            "E27.0 - Outros Excessos de Atividade Adrenocortical",
            "E27.1 - Insuficiência Adrenocortical Primária",
            "E27.2 - Crise Addisoniana",
            "E27.3 - Insuficiência Adrenocortical Induzida Por Drogas",
            "E27.4 - Outras Insuficiências Adrenocorticais e as Não Especificadas",
            "E27.5 - Hiperfunção Adrenomedular",
            "E27.8 - Outros Transtornos Especificados da Supra-renal",
            "E27.9 - Transtorno Não Especificado da Supra-renal",
            "E28 - Disfunção Ovariana",
            "E28.0 - Excesso de Estrógeno",
            "E28.1 - Excesso de Andrógenos",
            "E28.2 - Síndrome do Ovário Policístico",
            "E28.3 - Insuficiência Ovariana Primária",
            "E28.8 - Outra Disfunção Ovariana",
            "E28.9 - Disfunção Ovariana Não Especificada",
            "E29 - Disfunção Testicular",
            "E29.0 - Hiperfunção Testicular",
            "E29.1 - Hipofunção Testicular",
            "E29.8 - Outra Disfunção Testicular",
            "E29.9 - Disfunção Testicular Não Especificada",
            "E30 - Transtornos da Puberdade Não Classificados em Outra Parte",
            "E30.0 - Puberdade Retardada",
            "E30.1 - Puberdade Precoce",
            "E30.8 - Outros Transtornos da Puberdade",
            "E30.9 - Transtorno Não Especificado da Puberdade",
            "E31 - Disfunção Poliglandular",
            "E31.0 - Insuficiência Poliglandular Auto-imune",
            "E31.1 - Hiperfunção Poliglandular",
            "E31.8 - Outra Disfunção Poliglandular",
            "E31.9 - Disfunção Poliglandular Não Especificada",
            "E32 - Doenças do Timo",
            "E32.0 - Hiperplasia Persistente do Timo",
            "E32.1 - Abscesso do Timo",
            "E32.8 - Outras Doenças do Timo",
            "E32.9 - Doença do Timo Não Especificada",
            "E34 - Outros Transtornos Endócrinos",
            "E34.0 - Síndrome Carcinóide",
            "E34.1 - Outra Hipersecreção de Hormônios Intestinais",
            "E34.2 - Secreção Hormonal Ectópica, Não Classificada em Outra Parte",
            "E34.3 - Nanismo, Não Classificado em Outra Parte",
            "E34.4 - Alta Estatura Constitucional",
            "E34.5 - Síndrome de Resistência a Andrógenos",
            "E34.8 - Outros Transtornos Endócrinos Especificados",
            "E34.9 - Transtorno Endócrino Não Especificado",
            "E35 - Transtornos Das Glândulas Endócrinas em Doenças Classificadas em Outra Parte",
            "E35.0 - Transtornos da Glândula Tireóide em Doenças Classificadas em Outra Parte",
            "E35.1 - Transtornos Das Supra-renais em Doenças Classificadas em Outra Parte",
            "E35.8 - Transtornos de Outras Glândulas Endócrinas em Doenças Classificadas em Outra Parte",
            "E40 - Kwashiorkor",
            "E41 - Marasmo Nutricional",
            "E42 - Kwashiorkor Marasmático",
            "E43 - Desnutrição Protéico-calórica Grave Não Especificada",
            "E44 - Desnutrição Protéico-calórica de Graus Moderado e Leve",
            "E44.0 - Desnutrição Protéico-calórica Moderada",
            "E44.1 - Desnutrição Protéico-calórica Leve",
            "E45 - Atraso do Desenvolvimento Devido à Desnutrição Protéico-calórica",
            "E46 - Desnutrição Protéico-calórica Não Especificada",
            "E50 - Deficiência de Vitamina A",
            "E50.0 - Deficiência de Vitamina A Com Xerose Conjuntival",
            "E50.1 - Deficiência de Vitamina A Com Mancha de Bitot e Xerose Conjuntival",
            "E50.2 - Deficiência de Vitamina A Com Xerose da Córnea",
            "E50.3 - Deficiência de Vitamina A Com Ulceração e Xerose da Córnea",
            "E50.4 - Deficiência de Vitamina A Com Ceratomalácia",
            "E50.5 - Deficiência de Vitamina A Com Cegueira Noturna",
            "E50.6 - Deficiência de Vitamina A Com Cicatrizes Xeroftálmicas da Córnea",
            "E50.7 - Outras Manifestações Oculares Devidas a Deficiência de Vitamina A",
            "E50.8 - Outras Manifestações Devidas a Deficiência de Vitamina A",
            "E50.9 - Deficiência Não Especificada de Vitamina A",
            "E51 - Deficiência de Tiamina",
            "E51.1 - Beribéri",
            "E51.2 - Encefalopatia de Wernicke",
            "E51.8 - Outras Manifestações da Deficiência de Tiamina",
            "E51.9 - Deficiência Não Especificada de Tiamina",
            "E52 - Deficiência de Niacina (pelagra)",
            "E53 - Deficiência de Outras Vitaminas do Grupo B",
            "E53.0 - Deficiência de Riboflavina",
            "E53.1 - Deficiência de Piridoxina",
            "E53.8 - Deficiência de Outras Vitaminas Especificadas do Grupo B",
            "E53.9 - Deficiência Não Especificada de Vitamina B",
            "E54 - Deficiência de Ácido Ascórbico",
            "E55 - Deficiência de Vitamina D",
            "E55.0 - Raquitismo Ativo",
            "E55.9 - Deficiência Não Especificada de Vitamina D",
            "E56 - Outras Deficiências Vitamínicas",
            "E56.0 - Deficiência de Vitamina E",
            "E56.1 - Deficiência de Vitamina K",
            "E56.8 - Deficiência de Outras Vitaminas",
            "E56.9 - Deficiência Vitamínica Não Especificada",
            "E58 - Deficiência de Cálcio da Dieta",
            "E59 - Deficiência de Selênio da Dieta",
            "E60 - Deficiência de Zinco da Dieta",
            "E61 - Deficiência de Outros Elementos Nutrientes",
            "E61.0 - Deficiência de Cobre",
            "E61.1 - Deficiência de Ferro",
            "E61.2 - Deficiência de Magnésio",
            "E61.3 - Deficiência de Manganês",
            "E61.4 - Deficiência de Cromo",
            "E61.5 - Deficiência de Molibdênio",
            "E61.6 - Deficiência de Vanádio",
            "E61.7 - Deficiência de Múltiplos Elementos Nutrientes",
            "E61.8 - Deficiência de Outros Elementos Nutrientes Especificados",
            "E61.9 - Deficiência de Elementos Nutrientes Não Especificados",
            "E63 - Outras Deficiências Nutricionais",
            "E63.0 - Deficiência de Ácido Graxo Essencial",
            "E63.1 - Desequilíbrio de Constituintes da Ingestão de Alimentos",
            "E63.8 - Outras Deficiências Nutricionais Especificadas",
            "E63.9 - Deficiência Nutricional Não Especificada",
            "E64 - Seqüelas de Desnutrição e de Outras Deficiências Nutricionais",
            "E64.0 - Seqüelas de Desnutrição Protéico-calórica",
            "E64.1 - Seqüelas da Deficiência de Vitamina A",
            "E64.2 - Seqüelas da Deficiência de Vitamina C",
            "E64.3 - Seqüelas do Raquitismo",
            "E64.8 - Seqüelas de Outras Deficiências Nutricionais",
            "E64.9 - Seqüelas de Deficiência Nutricional Não Especificada",
            "E65 - Adiposidade Localizada",
            "E66 - Obesidade",
            "E66.0 - Obesidade Devida a Excesso de Calorias",
            "E66.1 - Obesidade Induzida Por Drogas",
            "E66.2 - Obesidade Extrema Com Hipoventilação Alveolar",
            "E66.8 - Outra Obesidade",
            "E66.9 - Obesidade Não Especificada",
            "E67 - Outras Formas de Hiperalimentação",
            "E67.0 - Hipervitaminose A",
            "E67.1 - Hipercarotenemia",
            "E67.2 - Síndrome de Megavitamina B6",
            "E67.3 - Hipervitaminose D",
            "E67.8 - Outras Formas Especificadas de Hiperalimentação",
            "E68 - Seqüelas de Hiperalimentação",
            "E70 - Distúrbios do Metabolismo de Aminoácidos Aromáticos",
            "E70.0 - Fenilcetonúria Clássica",
            "E70.1 - Outras Hiperfenilalaninemias",
            "E70.2 - Distúrbios do Metabolismo da Tirosina",
            "E70.3 - Albinismo",
            "E70.8 - Outros Distúrbios do Metabolismo de Aminoácidos Aromáticos",
            "E70.9 - Distúrbio Não Especificado do Metabolismo de Aminoácidos Aromáticos",
            "E71 - Distúrbios do Metabolismo de Aminoácidos de Cadeia Ramificada e do Metabolismo Dos Ácidos Graxos",
            "E71.0 - Doença da Urina em Xarope de Ácer (ou Bordo) (Maple-syrup Urine Disease)",
            "E71.1 - Outros Distúrbios do Metabolismo de Aminoácidos de Cadeia Ramificada",
            "E71.2 - Distúrbio Não Especificado do Metabolismo de Aminoácidos de Cadeia Ramificada",
            "E71.3 - Distúrbios do Metabolismo de Ácidos Graxos",
            "E72 - Outros Distúrbios do Metabolismo de Aminoácidos",
            "E72.0 - Distúrbios do Transporte de Aminoácidos",
            "E72.1 - Distúrbios do Metabolismo Dos Aminoácidos Que Contêm Enxofre",
            "E72.2 - Distúrbios do Metabolismo do Ciclo da Uréia",
            "E72.3 - Distúrbios do Metabolismo da Lisina e da Hidroxilisina",
            "E72.4 - Distúrbios do Metabolismo da Ornitina",
            "E72.5 - Distúrbios do Metabolismo da Glicina",
            "E72.8 - Outros Distúrbios Especificados do Metabolismo Dos Aminoácidos",
            "E72.9 - Distúrbio Não Especificado do Metabolismo Dos Aminoácidos",
            "E73 - Intolerância à Lactose",
            "E73.0 - Deficiência Congênita de Lactase",
            "E73.1 - Deficiência Secundária de Lactase",
            "E73.8 - Outras Intolerâncias à Lactose",
            "E73.9 - Intolerância à Lactose, Não Especificada",
            "E74 - Outros Distúrbios do Metabolismo de Carboidratos",
            "E74.0 - Doença de Depósito de Glicogênio",
            "E74.1 - Distúrbios do Metabolismo da Frutose",
            "E74.2 - Distúrbios do Metabolismo da Galactose",
            "E74.3 - Outros Distúrbios da Absorção Intestinal de Carboidratos",
            "E74.4 - Distúrbios do Metabolismo do Piruvato e da Gliconeogênese",
            "E74.8 - Outros Distúrbios Especificados do Metabolismo de Carboidratos",
            "E74.9 - Distúrbio Não Especificado do Metabolismo de Carboidratos",
            "E75 - Distúrbios do Metabolismo de Esfingolípides e Outros Distúrbios de Depósito de Lípides",
            "E75.0 - Gangliosidose GM2",
            "E75.1 - Outras Gangliosidoses",
            "E75.2 - Outras Esfingolipidoses",
            "E75.3 - Esfingolipidose Não Especificada",
            "E75.4 - Lipofuscinose Neuronal Ceróide",
            "E75.5 - Outros Distúrbios do Depósito de Lípides",
            "E75.6 - Distúrbio Não Especificado do Depósito de Lípides",
            "E76 - Distúrbios do Metabolismo do Glicosaminoglicano",
            "E76.0 - Mucopolissacaridose do Tipo I",
            "E76.1 - Mucopolissacaridose do Tipo II",
            "E76.2 - Outras Mucopolissacaridoses",
            "E76.3 - Mucopolissacaridose Não Especificada",
            "E76.8 - Outros Distúrbios do Metabolismo do Glicosaminoglicano",
            "E76.9 - Distúrbio Não Especificado do Metabolismo do Glicosaminoglicano",
            "E77 - Distúrbios do Metabolismo de Glicoproteínas",
            "E77.0 - Defeitos na Modificação Pós-translacional de Enzimas Lisossômicas",
            "E77.1 - Defeitos na Degradação Das Glicoproteínas",
            "E77.8 - Outros Distúrbios do Metabolismo de Glicoproteínas",
            "E77.9 - Distúrbio Não Especificado do Metabolismo de Glicoproteínas",
            "E78 - Distúrbios do Metabolismo de Lipoproteínas e Outras Lipidemias",
            "E78.0 - Hipercolesterolemia Pura",
            "E78.1 - Hipergliceridemia Pura",
            "E78.2 - Hiperlipidemia Mista",
            "E78.3 - Hiperquilomicronemia",
            "E78.4 - Outras Hiperlipidemias",
            "E78.5 - Hiperlipidemia Não Especificada",
            "E78.6 - Deficiências de Lipoproteínas",
            "E78.8 - Outros Distúrbios do Metabolismo de Lipoproteínas",
            "E78.9 - Distúrbio Não Especificado do Metabolismo de Lipoproteínas",
            "E79 - Distúrbios do Metabolismo de Purina e Pirimidina",
            "E79.0 - Hiperuricemia Sem Sinais de Artrite Inflamatória e de Doença Com Tofos",
            "E79.1 - Síndrome de Lesch-Nyhan",
            "E79.8 - Outros Distúrbios do Metabolismo da Purina e Pirimidina",
            "E79.9 - Distúrbio Não Especificado do Metabolismo da Purina e Pirimidina",
            "E80 - Distúrbios do Metabolismo da Porfirina e da Bilirrubina",
            "E80.0 - Porfiria Hereditária Eritropoética",
            "E80.1 - Porfiria Cutânea Tardia",
            "E80.2 - Outras Porfirias",
            "E80.3 - Defeitos da Catalase e da Peroxidase",
            "E80.4 - Síndrome de Gilbert",
            "E80.5 - Síndrome da Crigler-Najjar",
            "E80.6 - Outros Distúrbios do Metabolismo da Bilirrubina",
            "E80.7 - Distúrbio Não Especificado do Metabolismo da Bilirrubina",
            "E83 - Distúrbios do Metabolismo de Minerais",
            "E83.0 - Distúrbios do Metabolismo do Cobre",
            "E83.1 - Doença do Metabolismo do Ferro",
            "E83.2 - Distúrbios do Metabolismo do Zinco",
            "E83.3 - Distúrbios do Metabolismo do Fósforo",
            "E83.4 - Distúrbios do Metabolismo do Magnésio",
            "E83.5 - Distúrbios do Metabolismo do Cálcio",
            "E83.8 - Outros Distúrbios do Metabolismo Mineral",
            "E83.9 - Distúrbio Não Especificado do Metabolismo Mineral",
            "E84 - Fibrose Cística",
            "E84.0 - Fibrose Cística Com Manifestações Pulmonares",
            "E84.1 - Fibrose Cística Com Manifestações Intestinais",
            "E84.8 - Fibrose Cística Com Outras Manifestações",
            "E84.9 - Fibrose Cística Não Especificada",
            "E85 - Amiloidose",
            "E85.0 - Amiloidose Heredofamiliar Não-neuropática",
            "E85.1 - Amiloidose Heredofamiliar Neuropática",
            "E85.2 - Amiloidose Heredofamiliar Não Especificada",
            "E85.3 - Amiloidose Sistêmica Secundária",
            "E85.4 - Amiloidose Limitada a Órgãos",
            "E85.8 - Outras Amiloidoses",
            "E85.9 - Amiloidose Não Especificada",
            "E86 - Depleção de Volume",
            "E87 - Outros Transtornos do Equilíbrio Hidroeletrolítico e Ácido-básico",
            "E87.0 - Hiperosmolaridade e Hipernatremia",
            "E87.1 - Hiposmolaridade e Hiponatremia",
            "E87.2 - Acidose",
            "E87.3 - Alcalose",
            "E87.4 - Distúrbio Misto do Equilíbrio Ácido-básico",
            "E87.5 - Hiperpotassemia",
            "E87.6 - Hipopotassemia",
            "E87.7 - Sobrecarga de Líquidos",
            "E87.8 - Outros Transtornos do Equilíbrio Hidroeletrolítico Não Classificados em Outra Parte",
            "E88 - Outros Distúrbios Metabólicos",
            "E88.0 - Distúrbios do Metabolismo Das Proteínas Plasmáticas Não Classificados em Outra Parte",
            "E88.1 - Lipodistrofia Não Classificada em Outra Parte",
            "E88.2 - Lipomatose Não Classificada em Outra Parte",
            "E88.8 - Outros Distúrbios Especificados do Metabolismo",
            "E88.9 - Distúrbio Metabólico Não Especificado",
            "E89 - Transtornos Endócrinos e Metabólicos Pós-procedimentos, Não Classificados em Outra Parte",
            "E89.0 - Hipotireoidismo Pós-procedimento",
            "E89.1 - Hipoinsulinemia Pós-procedimento",
            "E89.2 - Hipoparatireoidismo Pós-procedimento",
            "E89.3 - Hipopituitarismo Pós-procedimento",
            "E89.4 - Insuficiência Ovariana Pós-procedimento",
            "E89.5 - Hipofunção Testicular Pós-procedimento",
            "E89.6 - Hipofunção Adrenocortical(-medular) Pós-procedimento",
            "E89.8 - Outros Transtornos Endócrinos e Metabólicos Pós-procedimento",
            "E89.9 - Transtornos Endócrinos e Metabólicos Não Especificados Pós-procedimento",
            "E90 - Transtornos Nutricionais e Metabólicos em Doenças Classificadas em Outra Parte",
            "F00 - Demência na Doença de Alzheimer",
            "F00.0 - Demência na Doença de Alzheimer de Início Precoce",
            "F00.1 - Demência na Doença de Alzheimer de Início Tardio",
            "F00.2 - Demência na Doença de Alzheimer, Forma Atípica ou Mista",
            "F00.9 - Demência Não Especificada na Doença de Alzheimer",
            "F01 - Demência Vascular",
            "F01.0 - Demência Vascular de Início Agudo",
            "F01.1 - Demência Por Infartos Múltiplos",
            "F01.2 - Demência Vascular Subcortical",
            "F01.3 - Demência Vascular Mista, Cortical e Subcortical",
            "F01.8 - Outra Demência Vascular",
            "F01.9 - Demência Vascular Não Especificada",
            "F02 - Demência em Outras Doenças Classificadas em Outra Parte",
            "F02.0 - Demência da Doença de Pick",
            "F02.1 - Demência na Doença de Creutzfeldt-Jakob",
            "F02.2 - Demência na Doença de Huntington",
            "F02.3 - Demência na Doença de Parkinson",
            "F02.4 - Demência na Doença Pelo Vírus da Imunodeficiência Humana (HIV)",
            "F02.8 - Demência em Outras Doenças Especificadas Classificadas em Outra Parte",
            "F03 - Demência Não Especificada",
            "F04 - Síndrome Amnésica Orgânica Não Induzida Pelo Álcool ou Por Outras Substâncias Psicoativas",
            "F05 - Delirium Não Induzido Pelo Álcool ou Por Outras Substâncias Psicoativas",
            "F05.0 - Delirium Não Superposto a Uma Demência, Assim Descrito",
            "F05.1 - Delirium Superposto a Uma Demência",
            "F05.8 - Outro Delirium",
            "F05.9 - Delirium Não Especificado",
            "F06 - Outros Transtornos Mentais Devidos a Lesão e Disfunção Cerebral e a Doença Física",
            "F06.0 - Alucinose Orgânica",
            "F06.1 - Estado Catatônico Orgânico",
            "F06.2 - Transtorno Delirante Orgânico (tipo Esquizofrênico)",
            "F06.3 - Transtornos do Humor (afetivos) Orgânicos",
            "F06.4 - Transtornos da Ansiedade Orgânicos",
            "F06.5 - Transtorno Dissociativo Orgânico",
            "F06.6 - Transtorno de Labilidade Emocional (astênico) Orgânico",
            "F06.7 - Transtorno Cognitivo Leve",
            "F06.8 - Outros Transtornos Mentais Especificados Devidos a Uma Lesão e Disfunção Cerebral e a Uma Doença Física",
            "F06.9 - Transtorno Mental Não Especificado Devido a Uma Lesão e Disfunção Cerebral e a Uma Doença Física",
            "F07 - Transtornos de Personalidade e do Comportamento Devidos a Doença, a Lesão e a Disfunção Cerebral",
            "F07.0 - Transtorno Orgânico da Personalidade",
            "F07.1 - Síndrome Pós-encefalítica",
            "F07.2 - Síndrome Pós-traumática",
            "F07.8 - Outros Transtornos Orgânicos da Personalidade e do Comportamento Devidos a Doença Cerebral, Lesão e Disfunção",
            "F07.9 - Transtorno Orgânico Não Especificado da Personalidade e do Comportamento Devido a Doença Cerebral, Lesão e Disfunção",
            "F09 - Transtorno Mental Orgânico ou Sintomático Não Especificado",
            "F10 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool",
            "F10.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Intoxicação Aguda",
            "F10.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Uso Nocivo Para a Saúde",
            "F10.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Síndrome de Dependência",
            "F10.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Síndrome (estado) de Abstinência",
            "F10.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Síndrome de Abstinência Com Delirium",
            "F10.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Transtorno Psicótico",
            "F10.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Síndrome Amnésica",
            "F10.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F10.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Outros Transtornos Mentais ou Comportamentais",
            "F10.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Álcool - Transtorno Mental ou Comportamental Não Especificado",
            "F11 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos",
            "F11.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Intoxicação Aguda",
            "F11.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Uso Nocivo Para a Saúde",
            "F11.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Síndrome de Dependência",
            "F11.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Síndrome (estado) de Abstinência",
            "F11.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Síndrome de Abstinência Com Delirium",
            "F11.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Transtorno Psicótico",
            "F11.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Síndrome Amnésica",
            "F11.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F11.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Outros Transtornos Mentais ou Comportamentais",
            "F11.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Opiáceos - Transtorno Mental ou Comportamental Não Especificado",
            "F12 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides",
            "F12.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Intoxicação Aguda",
            "F12.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Uso Nocivo Para a Saúde",
            "F12.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Síndrome de Dependência",
            "F12.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Síndrome (estado) de Abstinência",
            "F12.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Síndrome de Abstinência Com Delirium",
            "F12.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Transtorno Psicótico",
            "F12.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Síndrome Amnésica",
            "F12.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F12.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Outros Transtornos Mentais ou Comportamentais",
            "F12.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Canabinóides - Transtorno Mental ou Comportamental Não Especificado",
            "F13 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos",
            "F13.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Intoxicação Aguda",
            "F13.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Uso Nocivo Para a Saúde",
            "F13.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Síndrome de Dependência",
            "F13.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Síndrome (estado) de Abstinência",
            "F13.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Síndrome de Abstinência Com Delirium",
            "F13.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Transtorno Psicótico",
            "F13.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Síndrome Amnésica",
            "F13.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F13.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Outros Transtornos Mentais ou Comportamentais",
            "F13.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Sedativos e Hipnóticos - Transtorno Mental ou Comportamental Não Especificado",
            "F14 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína",
            "F14.0 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Intoxicação Aguda",
            "F14.1 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Uso Nocivo Para a Saúde",
            "F14.2 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Síndrome de Dependência",
            "F14.3 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Síndrome (estado) de Abstinência",
            "F14.4 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Síndrome de Abstinência Com Delirium",
            "F14.5 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Transtorno Psicótico",
            "F14.6 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Síndrome Amnésica",
            "F14.7 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F14.8 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Outros Transtornos Mentais ou Comportamentais",
            "F14.9 - Transtornos Mentais e Comportamentais Devidos ao Uso da Cocaína - Transtorno Mental ou Comportamental Não Especificado",
            "F15 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína",
            "F15.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Intoxicação Aguda",
            "F15.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Uso Nocivo Para a Saúde",
            "F15.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Síndrome de Dependência",
            "F15.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Síndrome (estado) de Abstinência",
            "F15.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Síndrome de Abstinência Com Delirium",
            "F15.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Transtorno Psicótico",
            "F15.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Síndrome Amnésica",
            "F15.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F15.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Outros Transtornos Mentais ou Comportamentais",
            "F15.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Outros Estimulantes, Inclusive a Cafeína - Transtorno Mental ou Comportamental Não Especificado",
            "F16 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos",
            "F16.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Intoxicação Aguda",
            "F16.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Uso Nocivo Para a Saúde",
            "F16.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Síndrome de Dependência",
            "F16.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Síndrome (estado) de Abstinência",
            "F16.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Síndrome de Abstinência Com Delirium",
            "F16.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Transtorno Psicótico",
            "F16.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Síndrome Amnésica",
            "F16.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F16.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Outros Transtornos Mentais ou Comportamentais",
            "F16.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Alucinógenos - Transtorno Mental ou Comportamental Não Especificado",
            "F17 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo",
            "F17.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Intoxicação Aguda",
            "F17.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Uso Nocivo Para a Saúde",
            "F17.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Síndrome de Dependência",
            "F17.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Síndrome (estado) de Abstinência",
            "F17.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Síndrome de Abstinência Com Delirium",
            "F17.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Transtorno Psicótico",
            "F17.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Síndrome Amnésica",
            "F17.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F17.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Outros Transtornos Mentais ou Comportamentais",
            "F17.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Fumo - Transtorno Mental ou Comportamental Não Especificado",
            "F18 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis",
            "F18.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Intoxicação Aguda",
            "F18.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Uso Nocivo Para a Saúde",
            "F18.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Síndrome de Dependência",
            "F18.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Síndrome (estado) de Abstinência",
            "F18.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Síndrome de Abstinência Com Delirium",
            "F18.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Transtorno Psicótico",
            "F18.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Síndrome Amnésica",
            "F18.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F18.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Outros Transtornos Mentais ou Comportamentais",
            "F18.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Solventes Voláteis - Transtorno Mental ou Comportamental Não Especificado",
            "F19 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas",
            "F19.0 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Intoxicação Aguda",
            "F19.1 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Uso Nocivo Para a Saúde",
            "F19.2 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Síndrome de Dependência",
            "F19.3 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Síndrome (estado) de Abstinência",
            "F19.4 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Síndrome de Abstinência Com Delirium",
            "F19.5 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Transtorno Psicótico",
            "F19.6 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Síndrome Amnésica",
            "F19.7 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Transtorno Psicótico Residual ou de Instalação Tardia",
            "F19.8 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Outros Transtornos Mentais ou Comportamentais",
            "F19.9 - Transtornos Mentais e Comportamentais Devidos ao Uso de Múltiplas Drogas e ao Uso de Outras Substâncias Psicoativas - Transtorno Mental ou Comportamental Não Especificado",
            "F20 - Esquizofrenia",
            "F20.0 - Esquizofrenia Paranóide",
            "F20.1 - Esquizofrenia Hebefrênica",
            "F20.2 - Esquizofrenia Catatônica",
            "F20.3 - Esquizofrenia Indiferenciada",
            "F20.4 - Depressão Pós-esquizofrênica",
            "F20.5 - Esquizofrenia Residual",
            "F20.6 - Esquizofrenia Simples",
            "F20.8 - Outras Esquizofrenias",
            "F20.9 - Esquizofrenia Não Especificada",
            "F21 - Transtorno Esquizotípico",
            "F22 - Transtornos Delirantes Persistentes",
            "F22.0 - Transtorno Delirante",
            "F22.8 - Outros Transtornos Delirantes Persistentes",
            "F22.9 - Transtorno Delirante Persistente Não Especificado",
            "F23 - Transtornos Psicóticos Agudos e Transitórios",
            "F23.0 - Transtorno Psicótico Agudo Polimorfo, Sem Sintomas Esquizofrênicos",
            "F23.1 - Transtorno Psicótico Agudo Polimorfo, Com Sintomas Esquizofrênicos",
            "F23.2 - Transtorno Psicótico Agudo de Tipo Esquizofrênico (schizophrenia-like)",
            "F23.3 - Outros Transtornos Psicóticos Agudos, Essencialmente Delirantes",
            "F23.8 - Outros Transtornos Psicóticos Agudos e Transitórios",
            "F23.9 - Transtorno Psicótico Agudo e Transitório Não Especificado",
            "F24 - Transtorno Delirante Induzido",
            "F25 - Transtornos Esquizoafetivos",
            "F25.0 - Transtorno Esquizoafetivo do Tipo Maníaco",
            "F25.1 - Transtorno Esquizoafetivo do Tipo Depressivo",
            "F25.2 - Transtorno Esquizoafetivo do Tipo Misto",
            "F25.8 - Outros Transtornos Esquizoafetivos",
            "F25.9 - Transtorno Esquizoafetivo Não Especificado",
            "F28 - Outros Transtornos Psicóticos Não-orgânicos",
            "F29 - Psicose Não-orgânica Não Especificada",
            "F30 - Episódio Maníaco",
            "F30.0 - Hipomania",
            "F30.1 - Mania Sem Sintomas Psicóticos",
            "F30.2 - Mania Com Sintomas Psicóticos",
            "F30.8 - Outros Episódios Maníacos",
            "F30.9 - Episódio Maníaco Não Especificado",
            "F31 - Transtorno Afetivo Bipolar",
            "F31.0 - Transtorno Afetivo Bipolar, Episódio Atual Hipomaníaco",
            "F31.1 - Transtorno Afetivo Bipolar, Episódio Atual Maníaco Sem Sintomas Psicóticos",
            "F31.2 - Transtorno Afetivo Bipolar, Episódio Atual Maníaco Com Sintomas Psicóticos",
            "F31.3 - Transtorno Afetivo Bipolar, Episódio Atual Depressivo Leve ou Moderado",
            "F31.4 - Transtorno Afetivo Bipolar, Episódio Atual Depressivo Grave Sem Sintomas Psicóticos",
            "F31.5 - Transtorno Afetivo Bipolar, Episódio Atual Depressivo Grave Com Sintomas Psicóticos",
            "F31.6 - Transtorno Afetivo Bipolar, Episódio Atual Misto",
            "F31.7 - Transtorno Afetivo Bipolar, Atualmente em Remissão",
            "F31.8 - Outros Transtornos Afetivos Bipolares",
            "F31.9 - Transtorno Afetivo Bipolar Não Especificado",
            "F32 - Episódios Depressivos",
            "F32.0 - Episódio Depressivo Leve",
            "F32.1 - Episódio Depressivo Moderado",
            "F32.2 - Episódio Depressivo Grave Sem Sintomas Psicóticos",
            "F32.3 - Episódio Depressivo Grave Com Sintomas Psicóticos",
            "F32.8 - Outros Episódios Depressivos",
            "F32.9 - Episódio Depressivo Não Especificado",
            "F33 - Transtorno Depressivo Recorrente",
            "F33.0 - Transtorno Depressivo Recorrente, Episódio Atual Leve",
            "F33.1 - Transtorno Depressivo Recorrente, Episódio Atual Moderado",
            "F33.2 - Transtorno Depressivo Recorrente, Episódio Atual Grave Sem Sintomas Psicóticos",
            "F33.3 - Transtorno Depressivo Recorrente, Episódio Atual Grave Com Sintomas Psicóticos",
            "F33.4 - Transtorno Depressivo Recorrente, Atualmente em Remissão",
            "F33.8 - Outros Transtornos Depressivos Recorrentes",
            "F33.9 - Transtorno Depressivo Recorrente Sem Especificação",
            "F34 - Transtornos de Humor (afetivos) Persistentes",
            "F34.0 - Ciclotimia",
            "F34.1 - Distimia",
            "F34.8 - Outros Transtornos do Humor (afetivos) Persistentes",
            "F34.9 - Transtorno do Humor (afetivo) Persistente Não Especificado",
            "F38 - Outros Transtornos do Humor (afetivos)",
            "F38.0 - Outros Transtornos do Humor (afetivos) Isolados",
            "F38.1 - Outros Transtornos do Humor (afetivos) Recorrentes",
            "F38.8 - Outros Transtornos Especificados do Humor (afetivos)",
            "F39 - Transtorno do Humor (afetivo) Não Especificado",
            "F40 - Transtornos Fóbico-ansiosos",
            "F40.0 - Agorafobia",
            "F40.1 - Fobias Sociais",
            "F40.2 - Fobias Específicas (isoladas)",
            "F40.8 - Outros Transtornos Fóbico-ansiosos",
            "F40.9 - Transtorno Fóbico-ansioso Não Especificado",
            "F41 - Outros Transtornos Ansiosos",
            "F41.0 - Transtorno de Pânico (ansiedade Paroxística Episódica)",
            "F41.1 - Ansiedade Generalizada",
            "F41.2 - Transtorno Misto Ansioso e Depressivo",
            "F41.3 - Outros Transtornos Ansiosos Mistos",
            "F41.8 - Outros Transtornos Ansiosos Especificados",
            "F41.9 - Transtorno Ansioso Não Especificado",
            "F42 - Transtorno Obsessivo-compulsivo",
            "F42.0 - Transtorno Obsessivo-compulsivo Com Predominância de Idéias ou de Ruminações Obsessivas",
            "F42.1 - Transtorno Obsessivo-compulsivo Com Predominância de Comportamentos Compulsivos (rituais Obsessivos)",
            "F42.2 - Transtorno Obsessivo-compulsivo, Forma Mista, Com Idéias Obsessivas e Comportamentos Compulsivos",
            "F42.8 - Outros Transtornos Obsessivo-compulsivos",
            "F42.9 - Transtorno Obsessivo-compulsivo Não Especificado",
            "F43 - Reações ao stress Grave e Transtornos de Adaptação",
            "F43.0 - Reação Aguda ao stress",
            "F43.1 - Estado de stress Pós - traumático",
            "F43.2 - Transtornos de Adaptação",
            "F43.8 - Outras Reações ao stress Grave",
            "F43.9 - Reação Não Especificada a um stress Grave",
            "F44 - Transtornos Dissociativos (de Conversão)",
            "F44.0 - Amnésia Dissociativa",
            "F44.1 - Fuga Dissociativa",
            "F44.2 - Estupor Dissociativo",
            "F44.3 - Estados de Transe e de Possessão",
            "F44.4 - Transtornos Dissociativos do Movimento",
            "F44.5 - Convulsões Dissociativas",
            "F44.6 - Anestesia e Perda Sensorial Dissociativas",
            "F44.7 - Transtorno Dissociativo Misto (de Conversão)",
            "F44.8 - Outros Transtornos Dissociativos (de Conversão)",
            "F44.9 - Transtorno Dissociativo (de Conversão) Não Especificado",
            "F45 - Transtornos Somatoformes",
            "F45.0 - Transtorno de Somatização",
            "F45.1 - Transtorno Somatoforme Indiferenciado",
            "F45.2 - Transtorno Hipocondríaco",
            "F45.3 - Transtorno Neurovegetativo Somatoforme",
            "F45.4 - Transtorno Doloroso Somatoforme Persistente",
            "F45.8 - Outros Transtornos Somatoformes",
            "F45.9 - Transtorno Somatoforme Não Especificado",
            "F48 - Outros Transtornos Neuróticos",
            "F48.0 - Neurastenia",
            "F48.1 - Síndrome de Despersonalização-desrealização",
            "F48.8 - Outros Transtornos Neuróticos Especificados",
            "F48.9 - Transtorno Neurótico Não Especificado",
            "F50 - Transtornos da Alimentação",
            "F50.0 - Anorexia Nervosa",
            "F50.1 - Anorexia Nervosa Atípica",
            "F50.2 - Bulimia Nervosa",
            "F50.3 - Bulimia Nervosa Atípica",
            "F50.4 - Hiperfagia Associada a Outros Distúrbios Psicológicos",
            "F50.5 - Vômitos Associados a Outros Distúrbios Psicológicos",
            "F50.8 - Outros Transtornos da Alimentação",
            "F50.9 - Transtorno de Alimentação Não Especificado",
            "F51 - Transtornos Não-orgânicos do Sono Devidos a Fatores Emocionais",
            "F51.0 - Insônia Não-orgânica",
            "F51.1 - Hipersonia Não-orgânica",
            "F51.2 - Transtorno do Ciclo Vigília-sono Devido a Fatores Não-orgânicos",
            "F51.3 - Sonambulismo",
            "F51.4 - Terrores Noturnos",
            "F51.5 - Pesadelos",
            "F51.8 - Outros Transtornos do Sono Devidos a Fatores Não-orgânicos",
            "F51.9 - Transtorno do Sono Devido a Fatores Não-orgânicos Não Especificados",
            "F52 - Disfunção Sexual, Não Causada Por Transtorno ou Doença Orgânica",
            "F52.0 - Ausência ou Perda do Desejo Sexual",
            "F52.1 - Aversão Sexual e Ausência de Prazer Sexual",
            "F52.2 - Falha de Resposta Genital",
            "F52.3 - Disfunção Orgásmica",
            "F52.4 - Ejaculação Precoce",
            "F52.5 - Vaginismo Não-orgânico",
            "F52.6 - Dispareunia Não-orgânica",
            "F52.7 - Apetite Sexual Excessivo",
            "F52.8 - Outras Disfunções Sexuais Não Devidas a Transtorno ou à Doença Orgânica",
            "F52.9 - Disfunção Sexual Não Devida a Transtorno ou à Doença Orgânica, Não Especificada",
            "F53 - Transtornos Mentais e Comportamentais Associados ao Puerpério, Não Classificados em Outra Parte",
            "F53.0 - Transtornos Mentais e Comportamentais Leves Associados ao Puerpério Não Classificados em Outra Parte",
            "F53.1 - Transtornos Mentais e Comportamentais Graves Associados ao Puerpério Não Classificados em Outra Parte",
            "F53.8 - Outros Transtornos Mentais e Comportamentais Associados ao Puerpério Não Classificados em Outra Parte",
            "F53.9 - Transtorno Mental e Comportamental Associado ao Puerpério, Não Especificado",
            "F54 - Fatores Psicológicos ou Comportamentais Associados a Doença ou a Transtornos Classificados em Outra Parte",
            "F55 - Abuso de Substâncias Que Não Produzem Dependência",
            "F59 - Síndromes Comportamentais Associados a Transtornos Das Funções Fisiológicas e a Fatores Físicos, Não Especificadas",
            "F60 - Transtornos Específicos da Personalidade",
            "F60.0 - Personalidade Paranóica",
            "F60.1 - Personalidade Esquizóide",
            "F60.2 - Personalidade Dissocial",
            "F60.3 - Transtorno de Personalidade Com Instabilidade Emocional",
            "F60.4 - Personalidade Histriônica",
            "F60.5 - Personalidade Anancástica",
            "F60.6 - Personalidade Ansiosa (esquiva)",
            "F60.7 - Personalidade Dependente",
            "F60.8 - Outros Transtornos Específicos da Personalidade",
            "F60.9 - Transtorno Não Especificado da Personalidade",
            "F61 - Transtornos Mistos da Personalidade e Outros Transtornos da Personalidade",
            "F62 - Modificações Duradouras da Personalidade Não Atribuíveis a Lesão ou Doença Cerebral",
            "F62.0 - Modificação Duradoura da Personalidade Após Uma Experiência Catastrófica",
            "F62.1 - Modificação Duradoura da Personalidade Após Doença Psiquiátrica",
            "F62.8 - Outras Modificações Duradouras da Personalidade",
            "F62.9 - Modificação Duradoura da Personalidade, Não Especificada",
            "F63 - Transtornos Dos Hábitos e Dos Impulsos",
            "F63.0 - Jogo Patológico",
            "F63.1 - Piromania",
            "F63.2 - Roubo Patológico (cleptomania)",
            "F63.3 - Tricotilomania",
            "F63.8 - Outros Transtornos Dos Hábitos e Dos Impulsos",
            "F63.9 - Transtorno Dos Hábitos e Impulsos, Não Especificado",
            "F64 - Transtornos da Identidade Sexual",
            "F64.0 - Transexualismo",
            "F64.1 - Travestismo Bivalente",
            "F64.2 - Transtorno de Identidade Sexual na Infância",
            "F64.8 - Outros Transtornos da Identidade Sexual",
            "F64.9 - Transtorno Não Especificado da Identidade Sexual",
            "F65 - Transtornos da Preferência Sexual",
            "F65.0 - Fetichismo",
            "F65.1 - Travestismo Fetichista",
            "F65.2 - Exibicionismo",
            "F65.3 - Voyeurismo",
            "F65.4 - Pedofilia",
            "F65.5 - Sadomasoquismo",
            "F65.6 - Transtornos Múltiplos da Preferência Sexual",
            "F65.8 - Outros Transtornos da Preferência Sexual",
            "F65.9 - Transtorno da Preferência Sexual, Não Especificado",
            "F66 - Transtornos Psicológicos e Comportamentais Associados ao Desenvolvimento Sexual e à Sua Orientação",
            "F66.0 - Transtorno da Maturação Sexual",
            "F66.1 - Orientação Sexual Egodistônica",
            "F66.2 - Transtorno do Relacionamento Sexual",
            "F66.8 - Outros Transtornos do Desenvolvimento Psicossexual",
            "F66.9 - Transtorno do Desenvolvimento Sexual, Não Especificado",
            "F68 - Outros Transtornos da Personalidade e do Comportamento do Adulto",
            "F68.0 - Sintomas Físicos Aumentados Por Fatores Psicológicos",
            "F68.1 - Produção Deliberada ou Simulação de Sintomas ou de Incapacidades, Físicas ou Psicológicas (transtorno Fictício)",
            "F68.8 - Outros Transtornos Especificados da Personalidade e do Comportamento do Adulto",
            "F69 - Transtorno da Personalidade e do Comportamento do Adulto, Não Especificado",
            "F70 - Retardo Mental Leve",
            "F70.0 - Retardo Mental Leve - Menção de Ausência de ou de Comprometimento Mínimo do Comportamento",
            "F70.1 - Retardo Mental Leve - Comprometimento Significativo do Comportamento, Requerendo Vigilância ou Tratamento",
            "F70.8 - Retardo Mental Leve - Outros Comprometimentos do Comportamento",
            "F70.9 - Retardo Mental Leve - Sem Menção de Comprometimento do Comportamento",
            "F71 - Retardo Mental Moderado",
            "F71.0 - Retardo Mental Moderado - Menção de Ausência de ou de Comprometimento Mínimo do Comportamento",
            "F71.1 - Retardo Mental Moderado - Comprometimento Significativo do Comportamento, Requerendo Vigilância ou Tratamento",
            "F71.8 - Retardo Mental Moderado - Outros Comprometimentos do Comportamento",
            "F71.9 - Retardo Mental Moderado - Sem Menção de Comprometimento do Comportamento",
            "F72 - Retardo Mental Grave",
            "F72.0 - Retardo Mental Grave - Menção de Ausência de ou de Comprometimento Mínimo do Comportamento",
            "F72.1 - Retardo Mental Grave - Comprometimento Significativo do Comportamento, Requerendo Vigilância ou Tratamento",
            "F72.8 - Retardo Mental Grave - Outros Comprometimentos do Comportamento",
            "F72.9 - Retardo Mental Grave - Sem Menção de Comprometimento do Comportamento",
            "F73 - Retardo Mental Profundo",
            "F73.0 - Retardo Mental Profundo - Menção de Ausência de ou de Comprometimento Mínimo do Comportamento",
            "F73.1 - Retardo Mental Profundo - Comprometimento Significativo do Comportamento, Requerendo Vigilância ou Tratamento",
            "F73.8 - Retardo Mental Profundo - Outros Comprometimentos do Comportamento",
            "F73.9 - Retardo Mental Profundo - Sem Menção de Comprometimento do Comportamento",
            "F78 - Outro Retardo Mental",
            "F78.0 - Outro Retardo Mental - Menção de Ausência de ou de Comprometimento Mínimo do Comportamento",
            "F78.1 - Outro Retardo Mental - Comprometimento Significativo do Comportamento, Requerendo Vigilância ou Tratamento",
            "F78.8 - Outro Retardo Mental - Outros Comprometimentos do Comportamento",
            "F78.9 - Outro Retardo Mental - Sem Menção de Comprometimento do Comportamento",
            "F79 - Retardo Mental Não Especificado",
            "F79.0 - Retardo Mental Não Especificado - Menção de Ausência de ou de Comprometimento Mínimo do Comportamento",
            "F79.1 - Retardo Mental Não Especificado - Comprometimento Significativo do Comportamento, Requerendo Vigilância ou Tratamento",
            "F79.8 - Retardo Mental Não Especificado - Outros Comprometimentos do Comportamento",
            "F79.9 - Retardo Mental Não Especificado - Sem Menção de Comprometimento do Comportamento",
            "F80 - Transtornos Específicos do Desenvolvimento da Fala e da Linguagem",
            "F80.0 - Transtorno Específico da Articulação da Fala",
            "F80.1 - Transtorno Expressivo de Linguagem",
            "F80.2 - Transtorno Receptivo da Linguagem",
            "F80.3 - Afasia Adquirida Com Epilepsia (síndrome de Landau-Kleffner)",
            "F80.8 - Outros Transtornos de Desenvolvimento da Fala ou da Linguagem",
            "F80.9 - Transtorno Não Especificado do Desenvolvimento da Fala ou da Linguagem",
            "F81 - Transtornos Específicos do Desenvolvimento Das Habilidades Escolares",
            "F81.0 - Transtorno Específico de Leitura",
            "F81.1 - Transtorno Específico da Soletração",
            "F81.2 - Transtorno Específico da Habilidade em Aritmética",
            "F81.3 - Transtorno Misto de Habilidades Escolares",
            "F81.8 - Outros Transtornos do Desenvolvimento Das Habilidades Escolares",
            "F81.9 - Transtorno Não Especificado do Desenvolvimento Das Habilidades Escolares",
            "F82 - Transtorno Específico do Desenvolvimento Motor",
            "F83 - Transtornos Específicos Misto do Desenvolvimento",
            "F84 - Transtornos Globais do Desenvolvimento",
            "F84.0 - Autismo Infantil",
            "F84.1 - Autismo Atípico",
            "F84.2 - Síndrome de Rett",
            "F84.3 - Outro Transtorno Desintegrativo da Infância",
            "F84.4 - Transtorno Com Hipercinesia Associada a Retardo Mental e a Movimentos Estereotipados",
            "F84.5 - Síndrome de Asperger",
            "F84.8 - Outros Transtornos Globais do Desenvolvimento",
            "F84.9 - Transtornos Globais Não Especificados do Desenvolvimento",
            "F88 - Outros Transtornos do Desenvolvimento Psicológico",
            "F89 - Transtorno do Desenvolvimento Psicológico Não Especificado",
            "F90 - Transtornos Hipercinéticos",
            "F90.0 - Distúrbios da Atividade e da Atenção",
            "F90.1 - Transtorno Hipercinético de Conduta",
            "F90.8 - Outros Transtornos Hipercinéticos",
            "F90.9 - Transtorno Hipercinético Não Especificado",
            "F91 - Distúrbios de Conduta",
            "F91.0 - Distúrbio de Conduta Restrito ao Contexto Familiar",
            "F91.1 - Distúrbio de Conduta Não-socializado",
            "F91.2 - Distúrbio de Conduta do Tipo Socializado",
            "F91.3 - Distúrbio Desafiador e de Oposição",
            "F91.8 - Outros Transtornos de Conduta",
            "F91.9 - Transtorno de Conduta Não Especificado",
            "F92 - Transtornos Mistos de Conduta e Das Emoções",
            "F92.0 - Distúrbio Depressivo de Conduta",
            "F92.8 - Outros Transtornos Mistos da Conduta e Das Emoções",
            "F92.9 - Transtorno Misto da Conduta e Das Emoções Não Especificado",
            "F93 - Transtornos Emocionais Com Início Especificamente na Infância",
            "F93.0 - Transtorno Ligado à Angústia de Separação",
            "F93.1 - Transtorno Fóbico Ansioso da Infância",
            "F93.2 - Distúrbio de Ansiedade Social da Infância",
            "F93.3 - Transtorno de Rivalidade Entre Irmãos",
            "F93.8 - Outros Transtornos Emocionais da Infância",
            "F93.9 - Transtorno Emocional da Infância Não Especificado",
            "F94 - Transtornos do Funcionamento Social Com Início Especificamente Durante a Infância ou a Adolescência",
            "F94.0 - Mutismo Eletivo",
            "F94.1 - Distúrbio Reativo de Vinculação da Infância",
            "F94.2 - Transtorno de Fixação da Infância, Com Desinibição",
            "F94.8 - Outros Transtornos do Funcionamento Social na Infância",
            "F94.9 - Transtorno do Funcionamento Social da Infância Não Especificado",
            "F95 - Tiques",
            "F95.0 - Tique Transitório",
            "F95.1 - Tique Motor ou Vocal Crônico",
            "F95.2 - Tiques Vocais e Motores Múltiplos Combinados (doença de Gilles de la Tourette)",
            "F95.8 - Outros Tiques",
            "F95.9 - Tique Não Especificado",
            "F98 - Outros Transtornos Comportamentais e Emocionais Com Início Habitualmente Durante a Infância ou a Adolescência",
            "F98.0 - Enurese de Origem Não-orgânica",
            "F98.1 - Encoprese de Origem Não-orgânica",
            "F98.2 - Transtorno de Alimentação na Infância",
            "F98.3 - Pica do Lactente ou da Criança",
            "F98.4 - Estereotipias Motoras",
            "F98.5 - Gagueira (tartamudez)",
            "F98.6 - Linguagem Precipitada",
            "F98.8 - Outros Transtornos Comportamentais e Emocionais Especificados Com Início Habitualmente na Infância ou Adolescência",
            "F98.9 - Transtornos Comportamentais e Emocionais Não Especificados Com Início Habitualmente na Infância ou Adolescência",
            "F99 - Transtorno Mental Não Especificado em Outra Parte",
            "G00 - Meningite Bacteriana Não Classificada em Outra Parte",
            "G00.0 - Meningite Por Haemophilus",
            "G00.1 - Meningite Pneumocócica",
            "G00.2 - Meningite Estreptocócica",
            "G00.3 - Meningite Estafilocócica",
            "G00.8 - Outras Meningites Bacterianas",
            "G00.9 - Meningite Bacteriana Não Especificada",
            "G01 - Meningite em Doenças Bacterianas Classificadas em Outra Parte",
            "G02 - Meningite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G02.0 - Meningite em Doenças Virais Classificadas em Outra Parte",
            "G02.1 - Meningite em Micoses",
            "G02.8 - Meningite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G03 - Meningite Devida a Outras Causas e a Causas Não Especificadas",
            "G03.0 - Meningite Não-piogênica",
            "G03.1 - Meningite Crônica",
            "G03.2 - Meningite Recorrente Benigna (Mollaret)",
            "G03.8 - Meningite Devida a Outras Causas Especificadas",
            "G03.9 - Meningite Não Especificada",
            "G04 - Encefalite, Mielite e Encefalomielite",
            "G04.0 - Encefalite Aguda Disseminada",
            "G04.1 - Paraplegia Espástica Tropical",
            "G04.2 - Meningoencefalite e Meningomielite Bacterianas Não Classificadas em Outra Parte",
            "G04.8 - Outras Encefalites, Mielites e Encefalomielites",
            "G04.9 - Encefalite, Mielite e Encefalomielite Não Especificada",
            "G05 - Encefalite, Mielite e Encefalomielite em Doenças Classificadas em Outra Parte",
            "G05.0 - Encefalite, Mielite e Encefalomielite em Doenças Bacterianas Classificadas em Outra Parte",
            "G05.1 - Encefalite, Mielite e Encefalomielite em Doenças Virais Classificadas em Outra Parte",
            "G05.2 - Encefalite, Mielite e Encefalomielite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G05.8 - Encefalite, Mielite e Encefalomielite em Outras Doenças Classificadas em Outra Parte",
            "G06 - Abscesso e Granuloma Intracranianos e Intra-raquidianos",
            "G06.0 - Abscesso e Granuloma Intracranianos",
            "G06.1 - Abscesso e Granuloma Intra-raquidianos",
            "G06.2 - Abscesso Extradural e Subdural Não Especificados",
            "G07 - Abscesso e Granuloma Intracranianos e Intraspinais em Doenças Classificadas em Outra Parte",
            "G08 - Flebite e Tromboflebite Intracranianas e Intra-raquidianas",
            "G09 - Seqüelas de Doenças Inflamatórias do Sistema Nervoso Central",
            "G10 - Doença de Huntington",
            "G11 - Ataxia Hereditária",
            "G11.0 - Ataxia Congênita Não-progressiva",
            "G11.1 - Ataxia Cerebelar de Início Precoce",
            "G11.2 - Ataxia Cerebelar de Início Tardio",
            "G11.3 - Ataxia Cerebelar Com Déficit na Reparação do DNA",
            "G11.4 - Paraplegia Espástica Hereditária",
            "G11.8 - Outras Ataxias Hereditárias",
            "G11.9 - Ataxia Hereditária Não Especificada",
            "G12 - Atrofia Muscular Espinal e Síndromes Correlatas",
            "G12.0 - Atrofia Muscular Espinal Infantil Tipo I (Werdnig-Hoffman)",
            "G12.1 - Outras Atrofias Musculares Espinais Hereditárias",
            "G12.2 - Doença do Neurônio Motor",
            "G12.8 - Outras Atrofias Musculares Espinais e Síndromes Musculares Correlatas",
            "G12.9 - Atrofia Muscular Espinal Não Especificada",
            "G13 - Atrofias Sistêmicas Que Afetam Principalmente o Sistema Nervoso Central em Doenças Classificadas em Outra Parte",
            "G13.0 - Neuromiopatia e Neuropatia Paraneoplásicas",
            "G13.1 - Outra Atrofia Sistêmica Que Afeta Primariamente o Sistema Nervoso Central em Doenças Neoplásicas",
            "G13.2 - Atrofia Sistêmica Que Afeta Primariamente o Sistema Nervoso Central no Mixedema",
            "G13.8 - Atrofia Sistêmica Que Afeta Primariamente o Sistema Nervoso Central em Outras Doenças Classificadas em Outra Parte",
            "G20 - Doença de Parkinson",
            "G21 - Parkinsonismo Secundário",
            "G21.0 - Síndrome Maligna Dos Neurolépticos",
            "G21.1 - Outras Formas de Parkinsonismo Secundário Induzido Por Drogas",
            "G21.2 - Parkinsonismo Secundário Devido a Outros Agentes Externos",
            "G21.3 - Parkinsonismo Pós-encefalítico",
            "G21.8 - Outras Formas de Parkinsonismo Secundário",
            "G21.9 - Parkinsonismo Secundário Não Especificado",
            "G22 - Parkinsonismo em Doenças Classificadas em Outra Parte",
            "G23 - Outras Doenças Degenerativas Dos Gânglios da Base",
            "G23.0 - Doença de Hallervorden-Spatz",
            "G23.1 - Oftalmoplegia Supranuclear Progressiva (Steele-Richardson-Olszewski)",
            "G23.2 - Degeneração Estrionígrica",
            "G23.8 - Outras Doenças Degenerativas Especificadas Dos Gânglios da Base",
            "G23.9 - Doença Degenerativa Dos Gânglios da Base, Não Especificada",
            "G24 - Distonia",
            "G24.0 - Distonia Induzida Por Drogas",
            "G24.1 - Distonia Familiar Idiopática",
            "G24.2 - Distonia Não-familiar Idiopática",
            "G24.3 - Torcicolo Espasmódico",
            "G24.4 - Distonia Orofacial Idiopática",
            "G24.5 - Blefaroespasmo",
            "G24.8 - Outras Distonias",
            "G24.9 - Distonia Não Especificada",
            "G25 - Outras Doenças Extrapiramidais e Transtornos Dos Movimentos",
            "G25.0 - Tremor Essencial",
            "G25.1 - Tremor Induzido Por Drogas",
            "G25.2 - Outras Formas Especificadas de Tremor",
            "G25.3 - Mioclonia",
            "G25.4 - Coréia Induzida Por Droga",
            "G25.5 - Outras Formas de Coréia",
            "G25.6 - Tiques Induzidos Por Droga e Outros Tipos de Origem Orgânica",
            "G25.8 - Outras Doenças Extrapiramidais e Transtornos Dos Movimentos, Especificados",
            "G25.9 - Doenças Extrapiramidais e Transtornos Dos Movimentos, Não Especificados",
            "G26 - Doenças Extrapiramidais e Transtornos Dos Movimentos em Doenças Classificadas em Outra Parte",
            "G30 - Doença de Alzheimer",
            "G30.0 - Doença de Alzheimer de Início Precoce",
            "G30.1 - Doença de Alzheimer de Início Tardio",
            "G30.8 - Outras Formas de Doença de Alzheimer",
            "G30.9 - Doença de Alzheimer Não Especificada",
            "G31 - Outras Doenças Degenerativas do Sistema Nervoso Não Classificadas em Outra Parte",
            "G31.0 - Atrofia Cerebral Circunscrita",
            "G31.1 - Degeneração Cerebral Senil, Não Classificadas em Outra Parte",
            "G31.2 - Degeneração do Sistema Nervoso Devida ao Álcool",
            "G31.8 - Outras Doenças Degenerativas Especificadas do Sistema Nervoso",
            "G31.9 - Doença Degenerativa do Sistema Nervoso, Não Especificada",
            "G32 - Outros Transtornos Degenerativos do Sistema Nervoso em Doenças Classificadas em Outra Parte",
            "G32.0 - Degeneração Combinada Subaguda da Medula Espinal em Doenças Classificadas em Outra Parte",
            "G32.8 - Outros Transtornos Degenerativos Especificados do Sistema Nervoso em Doenças Classificadas em Outra Parte",
            "G35 - Esclerose Múltipla",
            "G36 - Outras Desmielinizações Disseminadas Agudas",
            "G36.0 - Neuromielite Óptica (doença de Devic)",
            "G36.1 - Leucoencefalite Hemorrágica Aguda e Subaguda (Hurst)",
            "G36.8 - Outras Desmielinizações Disseminadas Agudas Especificadas",
            "G36.9 - Desmielinização Disseminada Aguda Não Especificada",
            "G37 - Outras Doenças Desmielinizantes do Sistema Nervoso Central",
            "G37.0 - Esclerose Difusa",
            "G37.1 - Desmielinização Central do Corpo Caloso",
            "G37.2 - Mielinólise Central da Ponte",
            "G37.3 - Mielite Transversa Aguda em Doenças Desmielinizantes do Sistema Nervoso Central",
            "G37.4 - Mielite Subaguda Necrótica",
            "G37.5 - Esclerose Concêntrica (Baló)",
            "G37.8 - Outras Doenças Desmielinizantes Especificadas do Sistema Nervoso Central",
            "G37.9 - Doença Desmielinizante do Sistema Nervoso Central, Não Especificada",
            "G40 - Epilepsia",
            "G40.0 - Epilepsia e Síndromes Epilépticas Idiopáticas Definidas Por Sua Localização (focal) (parcial) Com Crises de Início Focal",
            "G40.1 - Epilepsia e Síndromes Epilépticas Sintomáticas Definidas Por Sua Localização (focal) (parcial) Com Crises Parciais Simples",
            "G40.2 - Epilepsia e Síndromes Epilépticas Sintomáticas Definidas Por Sua Localização (focal) (parcial) Com Crises Parciais Complexas",
            "G40.3 - Epilepsia e Síndromes Epilépticas Generalizadas Idiopáticas",
            "G40.4 - Outras Epilepsias e Síndromes Epilépticas Generalizadas",
            "G40.5 - Síndromes Epilépticas Especiais",
            "G40.6 - Crise de Grande Mal, Não Especificada (com ou Sem Pequeno Mal)",
            "G40.7 - Pequeno Mal Não Especificado, Sem Crises de Grande Mal",
            "G40.8 - Outras Epilepsias",
            "G40.9 - Epilepsia, Não Especificada",
            "G41 - Estado de Mal Epiléptico",
            "G41.0 - Estado de Grande Mal Epiléptico",
            "G41.1 - Estado de Pequeno Mal Epiléptico",
            "G41.2 - Estado de Mal Epiléptico Parcial Complexo",
            "G41.8 - Outros Estados de Mal Epiléptico",
            "G41.9 - Estado de Mal Epiléptico, Não Especificado",
            "G43 - Enxaqueca",
            "G43.0 - Enxaqueca Sem Aura (enxaqueca Comum)",
            "G43.1 - Enxaqueca Com Aura (enxaqueca Clássica)",
            "G43.2 - Estado de Mal Enxaquecoso",
            "G43.3 - Enxaqueca Complicada",
            "G43.8 - Outras Formas de Enxaqueca",
            "G43.9 - Enxaqueca, Sem Especificação",
            "G44 - Outras Síndromes de Algias Cefálicas",
            "G44.0 - Síndrome de cluster-headache",
            "G44.1 - Cefaléia Vascular, Não Classificada em Outra Parte",
            "G44.2 - Cefaléia Tensional",
            "G44.3 - Cefaléia Crônica Pós-traumática",
            "G44.4 - Cefaléia Induzida Por Drogas, Não Classificada em Outra Parte",
            "G44.8 - Outras Síndromes de Cefaléia Especificadas",
            "G45 - Acidentes Vasculares Cerebrais Isquêmicos Transitórios e Síndromes Correlatas",
            "G45.0 - Síndrome da Artéria Vértebro-basilar",
            "G45.1 - Síndrome da Artéria Carotídea (hemisférica)",
            "G45.2 - Síndrome Das Artérias Pré-cerebrais, Múltiplas e Bilaterais",
            "G45.3 - Amaurose Fugaz",
            "G45.4 - Amnésia Global Transitória",
            "G45.8 - Outros Acidentes Isquêmicos Cerebrais Transitórios e Síndromes Correlatas",
            "G45.9 - Isquemia Cerebral Transitória Não Especificada",
            "G46 - Síndromes Vasculares Cerebrais Que Ocorrem em Doenças Cerebrovasculares",
            "G46.0 - Síndrome da Artéria Cerebral Média",
            "G46.1 - Síndrome da Artéria Cerebral Anterior",
            "G46.2 - Síndrome da Artéria Cerebral Posterior",
            "G46.3 - Síndromes Vasculares do Tronco Cerebral",
            "G46.4 - Síndrome Vascular Cerebelar",
            "G46.5 - Síndrome Lacunar Motora Pura",
            "G46.6 - Síndrome Lacunar Sensorial Pura",
            "G46.7 - Outras Síndromes Lacunares",
            "G46.8 - Outras Síndromes Vasculares Cerebrais em Doenças Cerebrovasculares",
            "G47 - Distúrbios do Sono",
            "G47.0 - Distúrbios do Início e da Manutenção do Sono (insônias)",
            "G47.1 - Distúrbios do Sono Por Sonolência Excessiva (hipersonia)",
            "G47.2 - Distúrbios do Ciclo Vigília-sono",
            "G47.3 - Apnéia de Sono",
            "G47.4 - Narcolepsia e Cataplexia",
            "G47.8 - Outros Distúrbios do Sono",
            "G47.9 - Distúrbio do Sono, Não Especificado",
            "G50 - Transtornos do Nervo Trigêmeo",
            "G50.0 - Nevralgia do Trigêmeo",
            "G50.1 - Dor Facial Atípica",
            "G50.8 - Outros Transtornos do Nervo Trigêmeo",
            "G50.9 - Transtorno Não Especificado do Nervo Trigêmeo",
            "G51 - Transtornos do Nervo Facial",
            "G51.0 - Paralisia de Bell",
            "G51.1 - Ganglionite Geniculada",
            "G51.2 - Síndrome de Melkersson",
            "G51.3 - Espasmo Hemifacial Clônico",
            "G51.4 - Mioquimia Facial",
            "G51.8 - Outros Transtornos do Nervo Facial",
            "G51.9 - Transtorno Não Especificado do Nervo Facial",
            "G52 - Transtornos de Outros Nervos Cranianos",
            "G52.0 - Transtornos do Nervo Olfatório",
            "G52.1 - Transtornos do Nervo Glossofaríngeo",
            "G52.2 - Transtornos do Nervo Vago",
            "G52.3 - Transtornos do Nervo Hipoglosso",
            "G52.7 - Transtornos de Múltiplos Nervos Cranianos",
            "G52.8 - Transtornos de Outros Nervos Cranianos Especificados",
            "G52.9 - Transtorno de Nervo Craniano Não Especificado",
            "G53 - Transtornos Dos Nervos Cranianos em Doenças Classificadas em Outra Parte",
            "G53.0 - Nevralgia Pós-zoster",
            "G53.1 - Paralisias de Múltiplos Nervos Cranianos em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G53.2 - Paralisias de Múltiplos Nervos Cranianos na Sarcoidose",
            "G53.3 - Paralisias de Múltiplos Nervos Cranianos em Doenças Neoplásicas",
            "G53.8 - Outros Transtornos de Nervos Cranianos em Outras Doenças Classificadas em Outra Parte",
            "G54 - Transtornos Das Raízes e Dos Plexos Nervosos",
            "G54.0 - Transtornos do Plexo Braquial",
            "G54.1 - Transtornos do Plexo Lombossacral",
            "G54.2 - Transtornos Das Raízes Cervicais Não Classificadas em Outra Parte",
            "G54.3 - Transtornos Das Raízes Torácicas Não Classificadas em Outra Parte",
            "G54.4 - Transtornos Das Raízes Lombossacras Não Classificadas em Outra Parte",
            "G54.5 - Amiotrofia Nevrálgica",
            "G54.6 - Síndrome Dolorosa do Membro Fantasma",
            "G54.7 - Síndrome do Membro Fantasma Sem Manifestação Dolorosa",
            "G54.8 - Outros Transtornos Das Raízes e Dos Plexos Nervosos",
            "G54.9 - Transtorno Não Especificado Das Raízes e Dos Plexos Nervosos",
            "G55 - Compressões Das Raízes e Dos Plexos Nervosos em Doenças Classificadas em Outra Parte",
            "G55.0 - Compressões Das Raízes e Dos Plexos Nervosos em Doenças Neoplásicas",
            "G55.1 - Compressões Das Raízes e Dos Plexos Nervosos em Transtornos Dos Discos Intervertebrais",
            "G55.2 - Compressões Das Raízes e Dos Plexos Nervosos na Espondilose",
            "G55.3 - Compressões Das Raízes e Dos Plexos Nervosos em Outras Dorsopatias",
            "G55.8 - Compressões Das Raízes e Dos Plexos Nervosos em Outras Doenças Classificadas em Outra Parte",
            "G56 - Mononeuropatias Dos Membros Superiores",
            "G56.0 - Síndrome do Túnel do Carpo",
            "G56.1 - Outras Lesões do Nervo Mediano",
            "G56.2 - Lesões do Nervo Cubital (ulnar)",
            "G56.3 - Lesão do Nervo Radial",
            "G56.4 - Causalgia",
            "G56.8 - Outras Mononeuropatias Dos Membros Superiores",
            "G56.9 - Mononeuropatia Dos Membros Superiores, Não Especificada",
            "G57 - Mononeuropatias Dos Membros Inferiores",
            "G57.0 - Lesão do Nervo Ciático",
            "G57.1 - Meralgia Parestésica",
            "G57.2 - Lesão do Nervo Femoral",
            "G57.3 - Lesão do Nervo Poplíteo Lateral",
            "G57.4 - Lesão do Nervo Poplíteo Medial",
            "G57.5 - Síndrome do Túnel do Tarso",
            "G57.6 - Lesão do Nervo Plantar",
            "G57.8 - Outras Mononeuropatias Dos Membros Inferiores",
            "G57.9 - Mononeuropatia Dos Membros Inferiores, Não Especificada",
            "G58 - Outras Mononeuropatias",
            "G58.0 - Neuropatia Intercostal",
            "G58.7 - Mononeurite Múltipla",
            "G58.8 - Outras Mononeuropatias Especificadas",
            "G58.9 - Mononeuropatia Não Especificada",
            "G59 - Mononeuropatias em Doenças Classificadas em Outra Parte",
            "G59.0 - Mononeuropatia Diabética",
            "G59.8 - Outras Mononeuropatias em Doenças Classificadas em Outra Parte",
            "G60 - Neuropatia Hereditária e Idiopática",
            "G60.0 - Neuropatia Hereditária Motora e Sensorial",
            "G60.1 - Doença de Refsum",
            "G60.2 - Neuropatia Associada a Ataxia Hereditária",
            "G60.3 - Neuropatia Progressiva Idiopática",
            "G60.8 - Outras Neuropatias Hereditárias e Idiopáticas",
            "G60.9 - Neuropatia Hereditária e Idiopática Não Especificada",
            "G61 - Polineuropatia Inflamatória",
            "G61.0 - Síndrome de Guillain-Barré",
            "G61.1 - Neuropatia Sérica",
            "G61.8 - Outras Polineuropatias Inflamatórias",
            "G61.9 - Polineuropatia Inflamatória Não Especificada",
            "G62 - Outras Polineuropatias",
            "G62.0 - Polineuropatia Induzida Por Drogas",
            "G62.1 - Polineuropatia Alcoólica",
            "G62.2 - Polineuropatia Devida a Outros Agentes Tóxicos",
            "G62.8 - Outras Polineuropatias Especificadas",
            "G62.9 - Polineuropatia Não Especificada",
            "G63 - Polineuropatia em Doenças Classificadas em Outra Parte",
            "G63.0 - Polineuropatia em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G63.1 - Polineuropatia em Doenças Neoplásicas",
            "G63.2 - Polineuropatia Diabética",
            "G63.3 - Polineuropatia em Outras Doenças Endócrinas e Metabólicas",
            "G63.4 - Polineuropatia em Deficiências Nutricionais",
            "G63.5 - Polineuropatia em Doenças Sistêmicas do Tecido Conjuntivo",
            "G63.6 - Polineuropatia em Outros Transtornos Osteomusculares",
            "G63.8 - Polineuropatia em Outras Doenças Classificadas em Outra Parte",
            "G64 - Outros Transtornos do Sistema Nervoso Periférico",
            "G70 - Miastenia Gravis e Outros Transtornos Neuromusculares",
            "G70.0 - Miastenia Gravis",
            "G70.1 - Transtornos Mioneurais Tóxicos",
            "G70.2 - Miastenia Congênita e do Desenvolvimento",
            "G70.8 - Outros Transtornos Mioneurais Especificados",
            "G70.9 - Transtorno Mioneural Não Especificado",
            "G71 - Transtornos Primários Dos Músculos",
            "G71.0 - Distrofia Muscular",
            "G71.1 - Transtornos Miotônicos",
            "G71.2 - Miopatias Congênitas",
            "G71.3 - Miopatia Mitocondrial Não Classificada em Outra Parte",
            "G71.8 - Outros Transtornos Primários Dos Músculos",
            "G71.9 - Transtorno Muscular Primário Não Especificado",
            "G72 - Outras Miopatias",
            "G72.0 - Miopatia Induzida Por Drogas",
            "G72.1 - Miopatia Alcoólica",
            "G72.2 - Miopatia Devida a Outros Agentes Tóxicos",
            "G72.3 - Paralisia Periódica",
            "G72.4 - Miopatia Inflamatória Não Classificada em Outra Parte",
            "G72.8 - Outras Miopatias Especificadas",
            "G72.9 - Miopatia Não Especificada",
            "G73 - Transtornos da Junção Mioneural e Dos Músculos em Doenças Classificadas em Outra Parte",
            "G73.0 - Síndromes Miastênicas em Doenças Endócrinas",
            "G73.1 - Síndrome de Eaton-Lambert",
            "G73.2 - Outras Síndromes Miastênicas em Doenças Neoplásicas",
            "G73.3 - Síndromes Miastênicas em Outras Doenças Classificadas em Outra Parte",
            "G73.4 - Miopatia em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G73.5 - Miopatia em Doenças Endócrinas",
            "G73.6 - Miopatia em Doenças Metabólicas",
            "G73.7 - Miopatia em Outras Doenças Classificadas em Outra Parte",
            "G80 - Paralisia Cerebral",
            "G80.0 - Paralisia Cerebral Quadriplágica Espástica",
            "G80.1 - Paralisia Cerebral Diplégica Espástica",
            "G80.2 - Paralisia Cerebral Hemiplégica Espástica",
            "G80.3 - Paralisia Cerebral Discinética",
            "G80.4 - Paralisia Cerebral Atáxica",
            "G80.8 - Outras Formas de Paralisia Cerebral",
            "G80.9 - Paralisia Cerebral Não Especificada",
            "G81 - Hemiplegia",
            "G81.0 - Hemiplegia Flácida",
            "G81.1 - Hemiplegia Espástica",
            "G81.9 - Hemiplegia Não Especificada",
            "G82 - Paraplegia e Tetraplegia",
            "G82.0 - Paraplegia Flácida",
            "G82.1 - Paraplegia Espástica",
            "G82.2 - Paraplegia Não Especificada",
            "G82.3 - Tetraplegia Flácida",
            "G82.4 - Tetraplegia Espástica",
            "G82.5 - Tetraplegia Não Especificada",
            "G83 - Outras Síndromes Paralíticas",
            "G83.0 - Diplegia Dos Membros Superiores",
            "G83.1 - Monoplegia do Membro Inferior",
            "G83.2 - Monoplegia do Membro Superior",
            "G83.3 - Monoplegia Não Especificada",
            "G83.4 - Síndrome da Cauda Eqüina",
            "G83.8 - Outras Síndromes Paralíticas Especificadas",
            "G83.9 - Síndrome Paralítica Não Especificada",
            "G90 - Transtornos do Sistema Nervoso Autônomo",
            "G90.0 - Neuropatia Autonômica Periférica Idiopática",
            "G90.1 - Disautonomia Familiar (Síndrome de Riley-Day)",
            "G90.2 - Síndrome de Horner",
            "G90.3 - Degeneração Multissistêmica",
            "G90.4 - Disreflexia Autonômica",
            "G90.8 - Outros Transtornos do Sistema Nervoso Autônomo",
            "G90.9 - Transtorno Não Especificado do Sistema Nervoso Autônomo",
            "G91 - Hidrocefalia",
            "G91.0 - Hidrocefalia Comunicante",
            "G91.1 - Hidrocefalia Obstrutiva",
            "G91.2 - Hidrocefalia de Pressão Normal",
            "G91.3 - Hidrocefalia Pós-traumática Não Especificada",
            "G91.8 - Outras Formas de Hidrocefalia",
            "G91.9 - Hidrocefalia Não Especificada",
            "G92 - Encefalopatia Tóxica",
            "G93 - Outros Transtornos do Encéfalo",
            "G93.0 - Cistos Cerebrais",
            "G93.1 - Lesão Encefálica Anóxica, Não Classificada em Outra Parte",
            "G93.2 - Hipertensão Intracraniana Benigna",
            "G93.3 - Síndrome da Fadiga Pós-viral",
            "G93.4 - Encefalopatia Não Especificada",
            "G93.5 - Compressão do Encéfalo",
            "G93.6 - Edema Cerebral",
            "G93.7 - Síndrome de Reye",
            "G93.8 - Outros Transtornos Especificados do Encéfalo",
            "G93.9 - Transtorno Não Especificado do Encéfalo",
            "G94 - Outros Transtornos do Encéfalo em Doenças Classificadas em Outra Parte",
            "G94.0 - Hidrocefalia em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "G94.1 - Hidrocefalia em Doenças Neoplásicas",
            "G94.2 - Hidrocefalia em Outras Doenças Classificadas em Outra Parte",
            "G94.8 - Outros Transtornos Encefálicos Especificados em Doenças Classificadas em Outra Parte",
            "G95 - Outras Doenças da Medula Espinal",
            "G95.0 - Siringomielia e Siringobulbia",
            "G95.1 - Mielopatias Vasculares",
            "G95.2 - Compressão Não Especificada de Medula Espinal",
            "G95.8 - Outras Doenças Especificadas da Medula Espinal",
            "G95.9 - Doença Não Especificada da Medula Espinal",
            "G96 - Outros Transtornos do Sistema Nervoso Central",
            "G96.0 - Fístula Liquórica",
            "G96.1 - Transtornos Das Meninges Não Classificados em Outra Parte",
            "G96.8 - Outros Transtornos Especificados do Sistema Nervoso Central",
            "G96.9 - Transtorno Não Especificado do Sistema Nervoso Central",
            "G97 - Transtornos Pós-procedimento do Sistema Nervoso Não Classificados em Outra Parte",
            "G97.0 - Perda de Líquor Resultante de Punção Espinal",
            "G97.1 - Outra Reação à Punção Espinal e Lombar",
            "G97.2 - Hipotensão Intracraniana Conseqüente à Derivação Ventricular",
            "G97.8 - Outros Transtornos Pós-procedimento do Sistema Nervoso",
            "G97.9 - Transtorno Pós-procedimento do Sistema Nervoso, Não Especificado",
            "G98 - Outros Transtornos do Sistema Nervoso Não Classificados em Outra Parte",
            "G99 - Outros Transtornos do Sistema Nervoso em Doenças Classificadas em Outra Parte",
            "G99.0 - Neuropatia Autonômica em Doenças Endócrinas e Metabólicas",
            "G99.1 - Outros Transtornos do Sistema Nervoso Autônomo em Doenças Classificadas em Outra Parte",
            "G99.2 - Mielopatia em Doenças Classificadas em Outra Parte",
            "G99.8 - Outros Transtornos Especificados do Sistema Nervoso em Doenças Classificadas em Outra Parte",
            "H00 - Hordéolo e Calázio",
            "H00.0 - Hordéolo e Outras Inflamações Profundas Das Pálpebras",
            "H00.1 - Calázio",
            "H01 - Outras Inflamações da Pálpebra",
            "H01.0 - Blefarite",
            "H01.1 - Dermatoses Não Infecciosas da Pálpebra",
            "H01.8 - Outras Inflamações Especificadas da Pálpebra",
            "H01.9 - Inflamação Não Especificada da Pálpebra",
            "H02 - Outros Transtornos da Pálpebra",
            "H02.0 - Entrópio e Triquíase da Pálpebra",
            "H02.1 - Ectrópio da Pálpebra",
            "H02.2 - Lagoftalmo",
            "H02.3 - Blefarocalásia",
            "H02.4 - Ptose da Pálpebra",
            "H02.5 - Outros Transtornos Que Afetam a Função da Pálpebra",
            "H02.6 - Xantelasma da Pálpebra",
            "H02.7 - Outros Transtornos Degenerativos da Pálpebra e da Área Periocular",
            "H02.8 - Outros Transtornos Especificados Das Pálpebras",
            "H02.9 - Transtorno Não Especificado da Pálpebra",
            "H03 - Transtornos da Pálpebra em Doenças Classificadas em Outras Partes",
            "H03.0 - Infestação Parasitária da Pálpebra em Doenças Classificadas em Outra Parte",
            "H03.1 - Comprometimento da Pálpebra em Outras Doenças Infecciosas Classificadas em Outra Parte",
            "H03.8 - Comprometimento da Pálpebra em Outras Doenças Classificadas em Outras Partes",
            "H04 - Transtornos do Aparelho Lacrimal",
            "H04.0 - Dacrioadenite",
            "H04.1 - Outros Transtornos da Glândula Lacrimal",
            "H04.2 - Epífora",
            "H04.3 - Inflamação Aguda e Não Especificada Dos Canais Lacrimais",
            "H04.4 - Inflamação Crônica Dos Canais Lacrimais",
            "H04.5 - Estenose e Insuficiência Dos Canais Lacrimais",
            "H04.6 - Outras Alterações Nos Canais Lacrimais",
            "H04.8 - Outros Transtornos do Aparelho Lacrimal",
            "H04.9 - Transtorno Não Especificado do Aparelho Lacrimal",
            "H05 - Transtornos da Órbita",
            "H05.0 - Inflamação Aguda da Órbita",
            "H05.1 - Transtornos Inflamatórios Crônicos da Órbita",
            "H05.2 - Afecções Exoftálmicas",
            "H05.3 - Deformidade da Órbita",
            "H05.4 - Enoftalmia",
            "H05.5 - Corpo Estranho (antigo) Retido Conseqüente a Ferimento Perfurante da Órbita",
            "H05.8 - Outros Transtornos da Órbita",
            "H05.9 - Transtorno Não Especificado da Órbita",
            "H06 - Transtornos do Aparelho Lacrimal e da Órbita em Doenças Classificadas em Outra Parte",
            "H06.0 - Transtornos do Aparelho Lacrimal em Doenças Classificadas em Outra Parte",
            "H06.1 - Infestação Parasitária da Órbita em Doenças Classificadas em Outra Parte",
            "H06.2 - Exoftalmo Distireoídeo",
            "H06.3 - Outros Transtornos da Órbita em Doenças Classificadas em Outra Parte",
            "H10 - Conjuntivite",
            "H10.0 - Conjuntivite Mucopurulenta",
            "H10.1 - Conjuntivite Aguda Atópica",
            "H10.2 - Outras Conjuntivites Agudas",
            "H10.3 - Conjuntivite Aguda Não Especificada",
            "H10.4 - Conjuntivite Crônica",
            "H10.5 - Blefaroconjuntivite",
            "H10.8 - Outras Conjuntivites",
            "H10.9 - Conjuntivite Não Especificada",
            "H11 - Outros Transtornos da Conjuntiva",
            "H11.0 - Pterígio",
            "H11.1 - Degenerações e Depósitos da Conjuntiva",
            "H11.2 - Cicatrizes da Conjuntiva",
            "H11.3 - Hemorragia Conjuntival",
            "H11.4 - Outros Transtornos Vasculares e Cistos Conjuntivais",
            "H11.8 - Outros Transtornos Especificados da Conjuntiva",
            "H11.9 - Transtorno Não Especificado da Conjuntiva",
            "H13 - Transtornos da Conjuntiva em Doenças Classificadas em Outra Parte",
            "H13.0 - Infestação Por Filárias na Conjuntiva",
            "H13.1 - Conjuntivite em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H13.2 - Conjuntivite em Outras Doenças Classificadas em Outra Parte",
            "H13.3 - Penfigóide Ocular",
            "H13.8 - Outros Transtornos da Conjuntiva em Doença Classificadas em Outra Parte",
            "H15 - Transtornos da Esclerótica",
            "H15.0 - Esclerite",
            "H15.1 - Episclerite",
            "H15.8 - Outros Transtornos da Esclerótica",
            "H15.9 - Transtorno Não Especificado da Esclerótica",
            "H16 - Ceratite",
            "H16.0 - Úlcera de Córnea",
            "H16.1 - Outras Ceratites Superficiais Sem Conjuntivite",
            "H16.2 - Ceratoconjuntivite",
            "H16.3 - Ceratites Intersticial e Profunda",
            "H16.4 - Neovascularização da Córnea",
            "H16.8 - Outras Ceratites",
            "H16.9 - Ceratite Não Especificada",
            "H17 - Cicatrizes e Opacidades da Córnea",
            "H17.0 - Leucoma Aderente",
            "H17.1 - Outra Opacidade Central da Córnea",
            "H17.8 - Outras Cicatrizes e Opacidades da Córnea",
            "H17.9 - Cicatriz e Opacidade Não Especificadas da Córnea",
            "H18 - Outros Transtornos da Córnea",
            "H18.0 - Pigmentações e Depósitos da Córnea",
            "H18.1 - Ceratopatia Bolhosa",
            "H18.2 - Outros Edemas da Córnea",
            "H18.3 - Alterações Nas Membranas da Córnea",
            "H18.4 - Degenerações da Córnea",
            "H18.5 - Distrofias Hereditárias da Córnea",
            "H18.6 - Ceratocone",
            "H18.7 - Outras Deformidades da Córnea",
            "H18.8 - Outros Transtornos Especificados da Córnea",
            "H18.9 - Transtorno Não Especificado da Córnea",
            "H19 - Transtorno da Esclerótica e da Córnea em Doenças Classificadas em Outra Parte",
            "H19.0 - Esclerite e Episclerite em Doenças Classificadas em Outra Parte",
            "H19.1 - Ceratite e Ceratoconjuntivite Pelo Vírus do Herpes Simples",
            "H19.2 - Ceratite e Ceratoconjuntivite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H19.3 - Ceratite e Ceratoconjuntivite em Outras Doenças Classificadas em Outra Parte",
            "H19.8 - Outros Transtornos de Esclerótica e da Córnea em Doenças Classificadas em Outra Parte",
            "H20 - Iridociclite",
            "H20.0 - Iridociclite Aguda e Subaguda",
            "H20.1 - Iridociclite Crônica",
            "H20.2 - Iridociclite Induzida Pelo Cristalino",
            "H20.8 - Outras Iridociclites",
            "H20.9 - Iridociclite Não Especificada",
            "H21 - Outros Transtornos da Íris e do Corpo Ciliar",
            "H21.0 - Hifema",
            "H21.1 - Outros Transtornos Vasculares da Íris e do Corpo Ciliar",
            "H21.2 - Degenerações da Íris e do Corpo Ciliar",
            "H21.3 - Cistos da Íris, do Corpo Ciliar e da Câmara Anterior",
            "H21.4 - Membranas Pupilares",
            "H21.5 - Outras Aderências e Roturas da Íris e do Corpo Ciliar",
            "H21.8 - Outros Transtornos Especificados da Íris e do Corpo Ciliar",
            "H21.9 - Transtorno Não Especificado da Íris e do Corpo Ciliar",
            "H22 - Transtornos da Íris e do Corpo Ciliar em Doenças Classificadas em Outra Parte",
            "H22.0 - Iridociclite em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H22.1 - Iridociclite em Outras Doenças Classificadas em Outra Parte",
            "H22.8 - Outros Transtornos da Íris e do Corpo Ciliar em Doenças Classificadas em Outra Parte",
            "H25 - Catarata Senil",
            "H25.0 - Catarata Senil Incipiente",
            "H25.1 - Catarata Senil Nuclear",
            "H25.2 - Catarata Senil Tipo Morgagni",
            "H25.8 - Outras Cataratas Senis",
            "H25.9 - Catarata Senil Não Especificada",
            "H26 - Outras Cataratas",
            "H26.0 - Catarata Infantil, Juvenil e Pré-senil",
            "H26.1 - Catarata Traumática",
            "H26.2 - Catarata Complicada",
            "H26.3 - Catarata Induzida Por Drogas",
            "H26.4 - Pós-catarata",
            "H26.8 - Outras Cataratas Especificadas",
            "H26.9 - Catarata Não Especificada",
            "H27 - Outros Transtornos do Cristalino",
            "H27.0 - Afacia",
            "H27.1 - Deslocamento do Cristalino",
            "H27.8 - Outros Transtornos Especificados do Cristalino",
            "H27.9 - Transtorno Não Especificado do Cristalino",
            "H28 - Catarata e Outros Transtornos do Cristalino em Doenças Classificadas em Outra Parte",
            "H28.0 - Catarata Diabética",
            "H28.1 - Catarata em Outras Doenças Endócrinas, Nutricionais e Metabólicas",
            "H28.2 - Catarata em Outras Doenças Classificadas em Outra Parte",
            "H28.8 - Outros Transtorno do Cristalino em Doenças Classificadas em Outra Parte",
            "H30 - Inflamação Coriorretiniana",
            "H30.0 - Inflamação Coriorretiniana Focal",
            "H30.1 - Inflamação Corrorretiniana Disseminada",
            "H30.2 - Ciclite Posterior",
            "H30.8 - Outras Inflamações Coriorretinianas",
            "H30.9 - Inflamação Não Especificada da Coróide e da Retina",
            "H31 - Outros Transtornos da Coróide",
            "H31.0 - Cicatrizes Coriorretinianas",
            "H31.1 - Degeneração da Coróide",
            "H31.2 - Distrofia Hereditária da Coróide",
            "H31.3 - Hemorragia e Rotura da Coróide",
            "H31.4 - Descolamento da Coróide",
            "H31.8 - Outros Transtornos Especificados da Coróide",
            "H31.9 - Transtorno Não Especificado da Coróide",
            "H32 - Transtornos Coriorretinianos em Doenças Classificadas em Outra Parte",
            "H32.0 - Inflamação Coriorretiniana em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H32.8 - Outros Transtornos Coriorretinianos em Doenças Classificadas em Outra Parte",
            "H33 - Descolamentos e Defeitos da Retina",
            "H33.0 - Descolamento da Retina Com Defeito Retiniano",
            "H33.1 - Retinosquise e Cistos da Retina",
            "H33.2 - Descolamento Seroso da Retina",
            "H33.3 - Defeitos da Retina Sem Descolamento",
            "H33.4 - Descolamento da Retina Por Tração",
            "H33.5 - Outros Descolamentos da Retina",
            "H34 - Oclusões Vasculares da Retina",
            "H34.0 - Oclusão Arterial Retiniana Transitória",
            "H34.1 - Oclusão da Artéria Retiniana Central",
            "H34.2 - Outras Oclusões da Artéria Retiniana",
            "H34.8 - Outras Oclusões Vasculares Retinianas",
            "H34.9 - Oclusão Vascular Retiniana Não Especificada",
            "H35 - Outros Transtornos da Retina",
            "H35.0 - Retinopatias de Fundo e Alterações Vasculares da Retina",
            "H35.1 - Retinopatia da Prematuridade",
            "H35.2 - Outras Retinopatias Proliferativas",
            "H35.3 - Degeneração da Mácula e do Pólo Posterior",
            "H35.4 - Degenerações Periféricas da Retina",
            "H35.5 - Distrofias Hereditárias da Retina",
            "H35.6 - Hemorragia Retiniana",
            "H35.7 - Separação Das Camadas da Retina",
            "H35.8 - Outros Transtornos Especificados da Retina",
            "H35.9 - Transtorno da Retina Não Especificado",
            "H36 - Transtornos da Retina em Doenças Classificadas em Outra Parte",
            "H36.0 - Retinopatia Diabética",
            "H36.8 - Outros Transtornos Retinianos em Doenças Classificadas em Outra Parte",
            "H40 - Glaucoma",
            "H40.0 - Suspeita de Glaucoma",
            "H40.1 - Glaucoma Primário de Ângulo Aberto",
            "H40.2 - Glaucoma Primário de Ângulo Fechado",
            "H40.3 - Glaucoma Secundário a Traumatismo Ocular",
            "H40.4 - Glaucoma Secundário a Inflamação Ocular",
            "H40.5 - Glaucoma Secundário a Outros Transtornos do Olho",
            "H40.6 - Glaucoma Secundário a Drogas",
            "H40.8 - Outro Glaucoma",
            "H40.9 - Glaucoma Não Especificado",
            "H42 - Glaucoma em Doenças Classificadas em Outra Parte",
            "H42.0 - Glaucoma em Doenças Endócrinas, Nutricionais e Metabólicas Classificadas em Outra Parte",
            "H42.8 - Glaucoma em Outras Doenças Classificadas em Outra Parte",
            "H43 - Transtornos do Humor Vítreo",
            "H43.0 - Prolapso do Humor Vítreo",
            "H43.1 - Hemorragia do Humor Vítreo",
            "H43.2 - Depósitos Cristalinos no Humor Vítreo",
            "H43.3 - Outras Opacidades do Vítreo",
            "H43.8 - Outros Transtornos do Humor Vítreo",
            "H43.9 - Transtorno Não Especificado do Humor Vítreo",
            "H44 - Transtornos do Globo Ocular",
            "H44.0 - Endoftalmite Purulenta",
            "H44.1 - Outras Endoftalmites",
            "H44.2 - Miopia Degenerativa",
            "H44.3 - Outros Transtornos Degenerativos do Globo Ocular",
            "H44.4 - Hipotonia do Olho",
            "H44.5 - Afecções Degenerativas do Globo Ocular",
            "H44.6 - Corpo Estranho Retido (antigo) Intra-ocular de Natureza Magnética",
            "H44.7 - Corpo Estranho Retido (antigo) Intra-ocular de Natureza Não-magnética",
            "H44.8 - Outros Transtornos do Globo Ocular",
            "H44.9 - Transtorno Não Especificado do Globo Ocular",
            "H45 - Transtornos do Humor Vítreo e do Globo Ocular em Doenças Classificadas em Outra Parte",
            "H45.0 - Hemorragia Vítrea em Doenças Classificadas em Outra Parte",
            "H45.1 - Endoftalmite em Doenças Classificadas em Outra Parte",
            "H45.8 - Outros Transtornos do Humor Vítreo e do Globo Ocular em Doenças Classificadas em Outra Parte",
            "H46 - Neurite Óptica",
            "H47 - Outros Transtornos do Nervo Óptico e Das Vias Ópticas",
            "H47.0 - Transtornos do Nervo Óptico Não Classificados em Outra Parte",
            "H47.1 - Papiledema Não Especificado",
            "H47.2 - Atrofia Óptica",
            "H47.3 - Outros Transtornos do Disco Óptico",
            "H47.4 - Transtornos do Quiasma Óptico",
            "H47.5 - Transtornos de Outras Vias Ópticas",
            "H47.6 - Transtornos do Córtex Visual",
            "H47.7 - Transtornos Não Especificados Das Vias Ópticas",
            "H48 - Transtornos do Nervo Óptico (segundo Par) e Das Vias Ópticas em Doenças Classificadas em Outra Parte",
            "H48.0 - Atrofia Óptica em Doenças Classificadas em Outra Parte",
            "H48.1 - Neurite Retrobulbar em Doenças Classificadas em Outra Parte",
            "H48.8 - Outros Transtornos do Nervo Óptico e Das Vias Ópticas em Doenças Classificadas em Outra Parte",
            "H49 - Estrabismo Paralítico",
            "H49.0 - Paralisia do Terceiro Par (oculomotor)",
            "H49.1 - Paralisia do Quarto Par (troclear)",
            "H49.2 - Paralisia do Sexto Par (abducente)",
            "H49.3 - Oftalmoplegia Total (externa)",
            "H49.4 - Oftalmoplegia Externa Progressiva",
            "H49.8 - Outros Estrabismos Paralíticos",
            "H49.9 - Estrabismo Paralítico Não Especificado",
            "H50 - Outros Estrabismos",
            "H50.0 - Estrabismo Convergente Concomitante",
            "H50.1 - Estrabismo Divergente Concomitante",
            "H50.2 - Estrabismo Vertical",
            "H50.3 - Heterotropia Intermitente",
            "H50.4 - Outras Heterotropias e as Não Especificadas",
            "H50.5 - Heteroforia",
            "H50.6 - Estrabismo Mecânico",
            "H50.8 - Outros Estrabismos Especificados",
            "H50.9 - Estrabismo Não Especificado",
            "H51 - Outros Transtornos do Movimento Binocular",
            "H51.0 - Paralisia do Olhar Conjugado",
            "H51.1 - Insuficiência ou Excesso de Convergência",
            "H51.2 - Oftalmoplegia Internuclear",
            "H51.8 - Outros Transtornos Especificados do Movimento Binocular",
            "H51.9 - Transtorno Não Especificado do Movimento Binocular",
            "H52 - Transtornos da Refração e da Acomodação",
            "H52.0 - Hipermetropia",
            "H52.1 - Miopia",
            "H52.2 - Astigmatismo",
            "H52.3 - Anisometropia e Aniseiconia",
            "H52.4 - Presbiopia",
            "H52.5 - Transtornos da Acomodação",
            "H52.6 - Outros Transtornos da Refração",
            "H52.7 - Transtorno Não Especificado da Refração",
            "H53 - Distúrbios Visuais",
            "H53.0 - Ambliopia Por Anopsia",
            "H53.1 - Distúrbios Visuais Subjetivos",
            "H53.2 - Diplopia",
            "H53.3 - Outros Transtornos da Visão Binocular",
            "H53.4 - Defeitos do Campo Visual",
            "H53.5 - Deficiências da Visão Cromática",
            "H53.6 - Cegueira Noturna",
            "H53.8 - Outros Distúrbios Visuais",
            "H53.9 - Distúrbio Visual Não Especificado",
            "H54 - Cegueira e Visão Subnormal",
            "H54.0 - Cegueira, Ambos os Olhos",
            "H54.1 - Cegueira em um Olho e Visão Subnormal em Outro",
            "H54.2 - Visão Subnormal de Ambos os Olhos",
            "H54.3 - Perda Não Qualificada da Visão em Ambos os Olhos",
            "H54.4 - Cegueira em um Olho",
            "H54.5 - Visão Subnormal em um Olho",
            "H54.6 - Perda Não Qualificada da Visão em um Olho",
            "H54.7 - Perda Não Especificada da Visão",
            "H55 - Nistagmo e Outros Movimentos Irregulares do Olho",
            "H57 - Outros Transtornos do Olho e Anexos",
            "H57.0 - Anomalias da Função Pupilar",
            "H57.1 - Dor Ocular",
            "H57.8 - Outros Transtornos Especificados do Olho e Anexos",
            "H57.9 - Transtorno Não Especificado do Olho e Anexos",
            "H58 - Outros Transtornos do Olho e Anexos em Doenças Classificadas em Outra Parte",
            "H58.0 - Anomalias da Função Pupilar em Doenças Classificadas em Outra Parte",
            "H58.1 - Distúrbios Visuais em Doenças Classificadas em Outra Parte",
            "H58.8 - Outros Transtornos Especificados do Olho e Anexos em Doenças Classificadas em Outra Parte",
            "H59 - Transtornos do Olho e Anexos Pós-procedimento Não Classificados em Outra Parte",
            "H59.0 - Ceropatia (bolhosa Afácica) Subseqüente a Cirurgia de Catarata",
            "H59.8 - Outros Transtornos do Olho e Anexos Pós-procedimentos",
            "H59.9 - Transtorno Não Especificado do Olho e Anexos Pós-procedimento",
            "H60 - Otite Externa",
            "H60.0 - Abscesso do Ouvido Externo",
            "H60.1 - Celulite do Ouvido Externo",
            "H60.2 - Otite Externa Maligna",
            "H60.3 - Outras Otites Externas Infecciosas",
            "H60.4 - Colesteatoma do Ouvido Externo",
            "H60.5 - Otite Externa Aguda Não-infecciosa",
            "H60.8 - Outras Otites Externas",
            "H60.9 - Otite Externa Não Especificada",
            "H61 - Outros Transtornos do Ouvido Externo",
            "H61.0 - Pericondrite do Pavilhão da Orelha",
            "H61.1 - Transtornos Não-infecciosos do Pavilhão da Orelha",
            "H61.2 - Cerume Impactado",
            "H61.3 - Estenose Adquirida do Conduto Auditivo Externo",
            "H61.8 - Outros Transtornos Especificados do Ouvido Externo",
            "H61.9 - Transtorno Não Especificado do Ouvido Externo",
            "H62 - Transtornos do Ouvido Externo em Doenças Classificadas em Outra Parte",
            "H62.0 - Otite Externa em Doenças Bacterianas Classificadas em Outra Parte",
            "H62.1 - Otite Externa em Doenças Virais Classificadas em Outra Parte",
            "H62.2 - Otite Externa em Micoses",
            "H62.3 - Otite Externa em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H62.4 - Otite Externa em Outras Doenças Classificadas em Outra Parte",
            "H62.8 - Outros Transtornos do Ouvido Externo em Doenças Classificadas em Outra Parte",
            "H65 - Otite Média Não-supurativa",
            "H65.0 - Otite Média Aguda Serosa",
            "H65.1 - Outras Otites Médias Agudas Não-supurativas",
            "H65.2 - Otite Média Serosa Crônica",
            "H65.3 - Otite Média Mucóide Crônica",
            "H65.4 - Outras Otites Médias Crônicas Não-supurativas",
            "H65.9 - Otite Média Não-supurativa, Não Especificada",
            "H66 - Otite Média Supurativa e as Não Especificadas",
            "H66.0 - Otite Média Aguda Supurativa",
            "H66.1 - Otite Média Tubotimpânica Supurativa Crônica",
            "H66.2 - Otite Média Ático-antral Supurativa Crônica",
            "H66.3 - Outras Otites Médias Supurativas Crônicas",
            "H66.4 - Otite Média Supurativa Não Especificada",
            "H66.9 - Otite Média Não Especificada",
            "H67 - Otite Média em Doenças Classificadas em Outra Parte",
            "H67.0 - Otite Média em Doenças Bacterianas Classificadas em Outra Parte",
            "H67.1 - Otite Média em Doenças Virais Classificadas em Outra Parte",
            "H67.8 - Otite Média em Outras Doenças Classificadas em Outra Parte",
            "H68 - Salpingite e Obstrução da Trompa de Eustáquio",
            "H68.0 - Salpingite da Trompa de Eustáquio",
            "H68.1 - Obstrução da Trompa de Eustáquio",
            "H69 - Outros Transtornos da Trompa de Eustáquio",
            "H69.0 - Trompa de Eustáquio Aberta (patente) (patulosa)",
            "H69.8 - Outros Transtornos Especificados da Trompa de Eustáquio",
            "H69.9 - Transtorno Não Especificado da Trompa de Eustáquio",
            "H70 - Mastoidite e Afecções Correlatas",
            "H70.0 - Mastoidite Aguda",
            "H70.1 - Mastoidite Crônica",
            "H70.2 - Petrosite",
            "H70.8 - Outras Mastoidites e Afecções Relacionadas Com a Mastoidite",
            "H70.9 - Mastoidite Não Especificada",
            "H71 - Colesteatoma do Ouvido Médio",
            "H72 - Perfuração da Membrana do Tímpano",
            "H72.0 - Perfuração Central da Membrana do Tímpano",
            "H72.1 - Perfuração Ática da Membrana do Tímpano",
            "H72.2 - Outras Perfurações Marginais da Membrana do Tímpano",
            "H72.8 - Outras Perfurações da Membrana do Tímpano",
            "H72.9 - Perfuração Não Especificada da Membrana do Tímpano",
            "H73 - Outros Transtornos da Membrana do Tímpano",
            "H73.0 - Miringite Aguda",
            "H73.1 - Miringite Crônica",
            "H73.8 - Outros Transtornos Especificados da Membrana do Tímpano",
            "H73.9 - Transtorno Não Especificado da Membrana do Tímpano",
            "H74 - Outros Transtornos do Ouvido Médio e da Mastóide",
            "H74.0 - Timpanosclerose",
            "H74.1 - Doença Adesiva do Ouvido Médio",
            "H74.2 - Descontinuidade e Deslocamento Dos Ossículos do Ouvido",
            "H74.3 - Outras Anormalidades Adquiridas Dos Ossículos do Ouvido",
            "H74.4 - Pólipo do Ouvido Médio",
            "H74.8 - Outros Transtornos Especificados do Ouvido Médio e da Mastóide",
            "H74.9 - Transtorno Não Especificado do Ouvido Médio e da Mastóide",
            "H75 - Outros Transtornos do Ouvido Médio e da Mastóide em Doenças Classificadas em Outra Parte",
            "H75.0 - Mastoidite em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H75.8 - Outros Transtornos Especificados do Ouvido Médio e da Mastóide em Doenças Classificadas em Outra Parte",
            "H80 - Otosclerose",
            "H80.0 - Otosclerose Que Compromete a Janela Oval, Não-obliterante",
            "H80.1 - Otosclerose Que Compromete a Janela Oval, Obliterante",
            "H80.2 - Otosclerose da Cóclea",
            "H80.8 - Outras Otoscleroses",
            "H80.9 - Otosclerose Não Especificada",
            "H81 - Transtornos da Função Vestibular",
            "H81.0 - Doença de Ménière",
            "H81.1 - Vertigem Paroxística Benigna",
            "H81.2 - Neuronite Vestibular",
            "H81.3 - Outras Vertigens Periféricas",
            "H81.4 - Vertigem de Origem Central",
            "H81.8 - Outros Transtornos da Função Vestibular",
            "H81.9 - Transtornos Não Especificados da Função Vestibular",
            "H82 - Síndromes Vertiginosas em Doenças Classificadas em Outra Parte",
            "H83 - Outros Transtornos do Ouvido Interno",
            "H83.0 - Labirintite",
            "H83.1 - Fístula do Labirinto",
            "H83.2 - Disfunção do Labirinto",
            "H83.3 - Efeitos do Ruído Sobre o Ouvido Interno",
            "H83.8 - Outros Transtornos Especificados do Ouvido Interno",
            "H83.9 - Transtorno Não Especificado do Ouvido Interno",
            "H90 - Perda de Audição Por Transtorno de Condução Eou Neuro-sensorial",
            "H90.0 - Perda de Audição Bilateral Devida a Transtorno de Condução",
            "H90.1 - Perda de Audição Unilateral Por Transtorno de Condução, Sem Restrição de Audição Contralateral",
            "H90.2 - Perda Não Especificada de Audição Devida a Transtorno de Condução",
            "H90.3 - Perda de Audição Bilateral Neuro-sensorial",
            "H90.4 - Perda de Audição Unilateral Neuro-sensorial, Sem Restrição de Audição Contralateral",
            "H90.5 - Perda de Audição Neuro-sensorial Não Especificada",
            "H90.6 - Perda de Audição Bilateral Mista, de Condução e Neuro-sensorial",
            "H90.7 - Perda de Audição Unilateral Mista, de Condução e Neuro-sensorial, Sem Restrição de Audição Contralateral",
            "H90.8 - Perda de Audição Mista, de Condução e Neuro-sensorial, Não Especificada",
            "H91 - Outras Perdas de Audição",
            "H91.0 - Perda de Audição Ototóxica",
            "H91.1 - Presbiacusia",
            "H91.2 - Perda de Audição Súbita Idiopática",
            "H91.3 - Surdo-mudez Não Classificada em Outra Parte",
            "H91.8 - Outras Perdas de Audição Especificadas",
            "H91.9 - Perda Não Especificada de Audição",
            "H92 - Otalgia e Secreção Auditiva",
            "H92.0 - Otalgia",
            "H92.1 - Otorréia",
            "H92.2 - Otorragia",
            "H93 - Outros Transtornos do Ouvido Não Classificados em Outra Parte",
            "H93.0 - Transtornos Degenerativos e Vasculares do Ouvido",
            "H93.1 - Tinnitus",
            "H93.2 - Outras Percepções Auditivas Anormais",
            "H93.3 - Transtornos do Nervo Acústico",
            "H93.8 - Outros Transtornos Especificados do Ouvido",
            "H93.9 - Transtorno Não Especificado do Ouvido",
            "H94 - Outros Transtornos do Ouvido em Doenças Classificadas em Outra Parte",
            "H94.0 - Neurite Acústica em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "H94.8 - Outros Transtornos Especificados do Ouvido em Doenças Classificadas em Outra Parte",
            "H95 - Transtornos do Ouvido e da Apófise Mastóide Pós-procedimentos, Não Classificados em Outra Parte",
            "H95.0 - Colesteatoma Recorrente da Cavidade Pós-mastoidectomia",
            "H95.1 - Outros Transtornos Conseqüentes à Mastoidectomia",
            "H95.8 - Outros Transtornos do Ouvido e da Apófise Mastóide Pós-procedimento",
            "H95.9 - Transtorno Não Especificado do Ouvido e da Apófise Mastóide Pós-procedimento",
            "I00 - Febre Reumática Sem Menção de Comprometimento do Coração",
            "I01 - Febre Reumática Com Comprometimento do Coração",
            "I01.0 - Pericardite Reumática Aguda",
            "I01.1 - Endocardite Reumática Aguda",
            "I01.2 - Miocardite Reumática Aguda",
            "I01.8 - Outras Formas de Doença Cardíaca Reumática Aguda",
            "I01.9 - Doença Cardíaca Reumática Aguda Não Especificada",
            "I02 - Coréia Reumática",
            "I02.0 - Coréia Reumática Com Comprometimento Cardíaco",
            "I02.9 - Coréia Reumática Sem Comprometimento Cardíaco",
            "I05 - Doenças Reumáticas da Valva Mitral",
            "I05.0 - Estenose Mitral",
            "I05.1 - Insuficiência Mitral Reumática",
            "I05.2 - Estenose Mitral Com Insuficiência",
            "I05.8 - Outras Doenças da Valva Mitral",
            "I05.9 - Doença Não Especificada da Valva Mitral",
            "I06 - Doenças Reumáticas da Valva Aórtica",
            "I06.0 - Estenose Aórtica Reumática",
            "I06.1 - Insuficiência Aórtica Reumática",
            "I06.2 - Estenose Aórtica Reumática Com Insuficiência",
            "I06.8 - Outras Doenças Reumáticas da Valva Aórtica",
            "I06.9 - Doença Reumática da Valva Aórtica, Não Especificada",
            "I07 - Doenças Reumáticas da Valva Tricúspide",
            "I07.0 - Estenose Tricúspide",
            "I07.1 - Insuficiência Tricúspide",
            "I07.2 - Estenose Tricúspide Com Insuficiência",
            "I07.8 - Outras Doenças da Valva Tricúspide",
            "I07.9 - Doença Não Especificada da Valva Tricúspide",
            "I08 - Doenças de Múltiplas Valvas",
            "I08.0 - Transtornos de Valvas Mitral e Aórtica",
            "I08.1 - Transtornos de Ambas as Valvas Mitral e Tricúspide",
            "I08.2 - Transtornos de Ambas as Valvas Aórtica e Tricúspide",
            "I08.3 - Transtornos Das Valvas Mitral, Aórtica e Tricúspide",
            "I08.8 - Outras Doenças de Múltiplas Valvas",
            "I08.9 - Doença Não Especificada de Múltiplas Valvas",
            "I09 - Outras Doenças Reumáticas do Coração",
            "I09.0 - Miocardite Reumática",
            "I09.1 - Doenças Reumáticas do Endocárdio, Valva Não Especificada",
            "I09.2 - Pericardite Reumática Crônica",
            "I09.8 - Outras Doenças Reumáticas Especificadas do Coração",
            "I09.9 - Doença Cardíaca Reumática Não Especificada",
            "I10 - Hipertensão Essencial (primária)",
            "I11 - Doença Cardíaca Hipertensiva",
            "I11.0 - Doença Cardíaca Hipertensiva Com Insuficiência Cardíaca (congestiva)",
            "I11.9 - Doença Cardíaca Hipertensiva Sem Insuficiência Cardíaca (congestiva)",
            "I12 - Doença Renal Hipertensiva",
            "I12.0 - Doença Renal Hipertensiva Com Insuficiência Renal",
            "I12.9 - Doença Renal Hipertensiva Sem Insuficiência Renal",
            "I13 - Doença Cardíaca e Renal Hipertensiva",
            "I13.0 - Doença Cardíaca e Renal Hipertensiva Com Insuficiência Cardíaca (congestiva)",
            "I13.1 - Doença Cardíaca e Renal Hipertensiva Com Insuficiência Renal",
            "I13.2 - Doença Cardíaca e Renal Hipertensiva Com Insuficiência Cardíaca (congestiva) e Insuficiência Renal",
            "I13.9 - Doença Cardíaca e Renal Hipertensiva, Não Especificada",
            "I15 - Hipertensão Secundária",
            "I15.0 - Hipertensão Renovascular",
            "I15.1 - Hipertensão Secundária a Outras Afecções Renais",
            "I15.2 - Hipertensão Secundária a Afecções Endócrinas",
            "I15.8 - Outras Formas de Hipertensão Secundária",
            "I15.9 - Hipertensão Secundária, Não Especificada",
            "I20 - Angina Pectoris",
            "I20.0 - Angina Instável",
            "I20.1 - Angina Pectoris Com Espasmo Documentado",
            "I20.8 - Outras Formas de Angina Pectoris",
            "I20.9 - Angina Pectoris, Não Especificada",
            "I21 - Infarto Agudo do Miocárdio",
            "I21.0 - Infarto Agudo Transmural da Parede Anterior do Miocárdio",
            "I21.1 - Infarto Agudo Transmural da Parede Inferior do Miocárdio",
            "I21.2 - Infarto Agudo Transmural do Miocárdio de Outras Localizações",
            "I21.3 - Infarto Agudo Transmural do Miocárdio, de Localização Não Especificada",
            "I21.4 - Infarto Agudo Subendocárdico do Miocárdio",
            "I21.9 - Infarto Agudo do Miocárdio Não Especificado",
            "I22 - Infarto do Miocárdio Recorrente",
            "I22.0 - Infarto do Miocárdio Recorrente da Parede Anterior",
            "I22.1 - Infarto do Miocárdio Recorrente da Parede Inferior",
            "I22.8 - Infarto do Miocárdio Recorrente de Outras Localizações",
            "I22.9 - Infarto do Miocárdio Recorrente de Localização Não Especificada",
            "I23 - Algumas Complicações Atuais Subseqüentes ao Infarto Agudo do Miocárdio",
            "I23.0 - Hemopericárdio Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.1 - Comunicação Interatrial Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.2 - Comunicação Interventricular Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.3 - Ruptura da Parede do Coração Sem Ocorrência de Hemopericárdio Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.4 - Ruptura de Cordoalhas Tendíneas Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.5 - Ruptura de Músculos Papilares Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.6 - Trombose de Átrio, Aurícula e Ventrículo Como Complicação Atual Subseqüente ao Infarto Agudo do Miocárdio",
            "I23.8 - Outras Complicações Atuais Subseqüentes ao Infarto Agudo do Miocárdio",
            "I24 - Outras Doenças Isquêmicas Agudas do Coração",
            "I24.0 - Trombose Coronária Que Não Resulta em Infarto do Miocárdio",
            "I24.1 - Síndrome de Dressler",
            "I24.8 - Outras Formas de Doença Isquêmica Aguda do Coração",
            "I24.9 - Doença Isquêmica Aguda do Coração Não Especificada",
            "I25 - Doença Isquêmica Crônica do Coração",
            "I25.0 - Doença Cardiovascular Aterosclerótica, Descrita Desta Maneira",
            "I25.1 - Doença Aterosclerótica do Coração",
            "I25.2 - Infarto Antigo do Miocárdio",
            "I25.3 - Aneurisma Cardíaco",
            "I25.4 - Aneurisma de Artéria Coronária",
            "I25.5 - Miocardiopatia Isquêmica",
            "I25.6 - Isquemia Miocárdica Silenciosa",
            "I25.8 - Outras Formas de Doença Isquêmica Crônica do Coração",
            "I25.9 - Doença Isquêmica Crônica do Coração Não Especificada",
            "I26 - Embolia Pulmonar",
            "I26.0 - Embolia Pulmonar Com Menção de Cor Pulmonale Agudo",
            "I26.9 - Embolia Pulmonar Sem Menção de Cor Pulmonale Agudo",
            "I27 - Outras Formas de Doença Cardíaca Pulmonar",
            "I27.0 - Hipertensão Pulmonar Primária",
            "I27.1 - Cardiopatia Cifoescoliótica",
            "I27.2 - Outra Hipertensão Pulmonar Secundária",
            "I27.8 - Outras Doenças Pulmonares do Coração Especificadas",
            "I27.9 - Cardiopatia Pulmonar Não Especificada",
            "I28 - Outras Doenças Dos Vasos Pulmonares",
            "I28.0 - Fístula Arteriovenosa de Vasos Pulmonares",
            "I28.1 - Aneurisma da Artéria Pulmonar",
            "I28.8 - Outras Doenças Especificadas Dos Vasos Pulmonares",
            "I28.9 - Doença Não Especificada Dos Vasos Pulmonares",
            "I30 - Pericardite Aguda",
            "I30.0 - Pericardite Aguda Idiopática Não Específica",
            "I30.1 - Pericardite Infecciosa",
            "I30.8 - Outras Formas de Pericardite Aguda",
            "I30.9 - Pericardite Aguda Não Especificada",
            "I31 - Outras Doenças do Pericárdio",
            "I31.0 - Pericardite Adesiva Crônica",
            "I31.1 - Pericardite Constritiva Crônica",
            "I31.2 - Hemopericárdio Não Classificado em Outra Parte",
            "I31.3 - Derrame Pericárdico (não-inflamatório)",
            "I31.8 - Outras Doenças Especificadas do Pericárdio",
            "I31.9 - Doença Não Especificada do Pericárdio",
            "I32 - Pericardite em Doenças Classificadas em Outra Parte",
            "I32.0 - Pericardite em Doenças Bacterianas Classificadas em Outra Parte",
            "I32.1 - Pericardite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "I32.8 - Pericardite em Outras Doenças Classificadas em Outra Parte",
            "I33 - Endocardite Aguda e Subaguda",
            "I33.0 - Endocardite Infecciosa Aguda e Subaguda",
            "I33.9 - Endocardite Aguda Não Especificada",
            "I34 - Transtornos Não-reumáticos da Valva Mitral",
            "I34.0 - Insuficiência (da Valva) Mitral",
            "I34.1 - Prolapso (da Valva) Mitral",
            "I34.2 - Estenose (da Valva) Mitral, Não-reumática",
            "I34.8 - Outros Transtornos Não-reumáticos da Valva Mitral",
            "I34.9 - Transtornos Não-reumáticos da Valva Mitral, Não Especificados",
            "I35 - Transtornos Não-reumáticos da Valva Aórtica",
            "I35.0 - Estenose (da Valva) Aórtica",
            "I35.1 - Insuficiência (da Valva) Aórtica",
            "I35.2 - Estenose (da Valva) Aórtica Com Insuficiência",
            "I35.8 - Outros Transtornos da Valva Aórtica",
            "I35.9 - Transtornos Não Especificados da Valva Aórtica",
            "I36 - Transtornos Não-reumáticos da Valva Tricúspide",
            "I36.0 - Estenose (da Valva) Tricúspide Não-reumática",
            "I36.1 - Insuficiência (da Valva) Tricúspide Não-reumática",
            "I36.2 - Estenose (da Valva) Tricúspide Com Insuficiência, Não-reumática",
            "I36.8 - Outros Transtornos Não-reumáticos da Valva Tricúspide",
            "I36.9 - Transtornos Não-reumáticos da Valva Tricúspide, Não Especificados",
            "I37 - Transtornos da Valva Pulmonar",
            "I37.0 - Estenose da Valva Pulmonar",
            "I37.1 - Insuficiência da Valva Pulmonar",
            "I37.2 - Estenose da Valva Pulmonar Com Insuficiência",
            "I37.8 - Outros Transtornos da Valva Pulmonar",
            "I37.9 - Transtornos Não Especificados da Valva Pulmonar",
            "I38 - Endocardite de Valva Não Especificada",
            "I39 - Endocardite e Transtornos Valvulares Cardíacos em Doenças Classificadas em Outra Parte",
            "I39.0 - Transtornos da Valva Mitral em Doenças Classificadas em Outra Parte",
            "I39.1 - Transtornos da Valva Aórtica em Doenças Classificadas em Outra Parte",
            "I39.2 - Transtornos da Valva Tricúspide em Doenças Classificadas em Outra Parte",
            "I39.3 - Transtornos da Valva Pulmonar em Doenças Classificadas em Outra Parte",
            "I39.4 - Transtornos de Múltiplas Valvas em Doenças Classificadas em Outra Parte",
            "I39.8 - Endocardite, de Valva Não Especificada, em Doenças Classificadas em Outra Parte",
            "I40 - Miocardite Aguda",
            "I40.0 - Miocardite Infecciosa",
            "I40.1 - Miocardite Isolada",
            "I40.8 - Outras Miocardites Agudas",
            "I40.9 - Miocardite Aguda Não Especificada",
            "I41 - Miocardite em Doenças Classificadas em Outra Parte",
            "I41.0 - Miocardite em Doenças Bacterianas Classificadas em Outra Parte",
            "I41.1 - Miocardite em Doenças Virais Classificadas em Outra Parte",
            "I41.2 - Miocardite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "I41.8 - Miocardite em Outras Doenças Classificadas em Outra Parte",
            "I42 - Cardiomiopatias",
            "I42.0 - Cardiomiopatia Dilatada",
            "I42.1 - Cardiomiopatia Obstrutiva Hipertrófica",
            "I42.2 - Outras Cardiomiopatias Hipertróficas",
            "I42.3 - Doença Endomiocárdica (eosinofílica)",
            "I42.4 - Fibroelastose Endocárdica",
            "I42.5 - Outras Cardiomiopatias Restritivas",
            "I42.6 - Cardiomiopatia Alcoólica",
            "I42.7 - Cardiomiopatia Devida a Drogas e Outros Agentes Externos",
            "I42.8 - Outras Cardiomiopatias",
            "I42.9 - Cardiomiopatia Não Especificada",
            "I43 - Cardiomiopatia em Doenças Classificadas em Outra Parte",
            "I43.0 - Cardiomiopatia em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "I43.1 - Cardiomiopatia em Doenças Metabólicas",
            "I43.2 - Cardiomiopatia em Doenças Nutricionais",
            "I43.8 - Cardiomiopatia em Outras Doenças Classificadas em Outra Parte",
            "I44 - Bloqueio Atrioventricular e do Ramo Esquerdo",
            "I44.0 - Bloqueio Atrioventricular de Primeiro Grau",
            "I44.1 - Bloqueio Atrioventricular de Segundo Grau",
            "I44.2 - Bloqueio Atrioventricular Total",
            "I44.3 - Outras Formas de Bloqueio Atrioventricular e as Não Especificadas",
            "I44.4 - Bloqueio do Fascículo Anterior Esquerdo",
            "I44.5 - Bloqueio do Fascículo Posterior Esquerdo",
            "I44.6 - Outras Formas de Bloqueio Fascicular e as Não Especificadas",
            "I44.7 - Bloqueio de Ramo Esquerdo Não Especificado",
            "I45 - Outros Transtornos de Condução",
            "I45.0 - Bloqueio Fascicular Direito",
            "I45.1 - Outras Formas de Bloqueio de Ramo Direito e as Não Especificadas",
            "I45.2 - Bloqueio Bifascicular",
            "I45.3 - Bloqueio Trifascicular",
            "I45.4 - Bloqueio Intraventricular Não Específico",
            "I45.5 - Outras Formas Especificadas de Bloqueio Cardíaco",
            "I45.6 - Síndrome de Pré-excitação",
            "I45.8 - Outros Transtornos Especificados da Condução",
            "I45.9 - Transtorno de Condução Não Especificado",
            "I46 - Parada Cardíaca",
            "I46.0 - Parada Cardíaca Com Ressuscitação Bem Sucedida",
            "I46.1 - Morte Súbita (de Origem) Cardíaca, Descrita Desta Forma",
            "I46.9 - Parada Cardíaca Não Especificada",
            "I47 - Taquicardia Paroxística",
            "I47.0 - Arritmia Ventricular Por Reentrada",
            "I47.1 - Taquicardia Supraventricular",
            "I47.2 - Taquicardia Ventricular",
            "I47.9 - Taquicardia Paroxística Não Especificada",
            "I48 - Flutter e Fibrilação Atrial",
            "I49 - Outras Arritmias Cardíacas",
            "I49.0 - Flutter e Fibrilação Ventricular",
            "I49.1 - Despolarização Atrial Prematura",
            "I49.2 - Despolarização Juncional Prematura",
            "I49.3 - Despolarização Ventricular Prematura",
            "I49.4 - Outras Formas de Despolarização Prematura e as Não Especificadas",
            "I49.5 - Síndrome do nó Sinusal",
            "I49.8 - Outras Arritmias Cardíacas Especificadas",
            "I49.9 - Arritmia Cardíaca Não Especificada",
            "I50 - Insuficiência Cardíaca",
            "I50.0 - Insuficiência Cardíaca Congestiva",
            "I50.1 - Insuficiência Ventricular Esquerda",
            "I50.9 - Insuficiência Cardíaca Não Especificada",
            "I51 - Complicações de Cardiopatias e Doenças Cardíacas Mal Definidas",
            "I51.0 - Defeito Adquirido de Septo Cardíaco",
            "I51.1 - Ruptura de Cordoalha Tendínea, Não Classificada em Outra Parte",
            "I51.2 - Ruptura de Músculo Papilar, Não Classificada em Outra Parte",
            "I51.3 - Trombose Intracardíaca, Não Classificada em Outra Parte",
            "I51.4 - Miocardite Não Especificada",
            "I51.5 - Degeneração Miocárdica",
            "I51.6 - Doença Cardiovascular Não Especificada",
            "I51.7 - Cardiomegalia",
            "I51.8 - Outras Doenças Mal Definidas do Coração",
            "I51.9 - Doença Não Especificada do Coração",
            "I52 - Outras Afecções Cardíacas em Doenças Classificadas em Outra Parte",
            "I52.0 - Outras Afecções Cardíacas em Doenças Bacterianas Classificadas em Outra Parte",
            "I52.1 - Outras Afecções Cardíacas em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "I52.8 - Outras Afecções Cardíacas em Outras Doenças Classificadas em Outra Parte",
            "I60 - Hemorragia Subaracnóide",
            "I60.0 - Hemorragia Subaracnóide Proveniente do Sifão e da Bifurcação da Carótida",
            "I60.1 - Hemorragia Subaracnóide Proveniente da Artéria Cerebral Média",
            "I60.2 - Hemorragia Subaracnóide Proveniente da Artéria Comunicante Anterior",
            "I60.3 - Hemorragia Subaracnóide Proveniente da Artéria Comunicante Posterior",
            "I60.4 - Hemorragia Subaracnóide Proveniente da Artéria Basilar",
            "I60.5 - Hemorragia Subaracnóide Proveniente da Artéria Vertebral",
            "I60.6 - Hemorragia Subaracnóide Proveniente de Outras Artérias Intracranianas",
            "I60.7 - Hemorragia Subaracnóide Proveniente de Artéria Intracraniana Não Especificada",
            "I60.8 - Outras Hemorragias Subaracnóides",
            "I60.9 - Hemorragia Subaracnóide Não Especificada",
            "I61 - Hemorragia Intracerebral",
            "I61.0 - Hemorragia Intracerebral Hemisférica Subcortical",
            "I61.1 - Hemorragia Intracerebral Hemisférica Cortical",
            "I61.2 - Hemorragia Intracerebral Hemisférica Não Especificada",
            "I61.3 - Hemorragia Intracerebral do Tronco Cerebral",
            "I61.4 - Hemorragia Intracerebral Cerebelar",
            "I61.5 - Hemorragia Intracerebral Intraventricular",
            "I61.6 - Hemorragia Intracerebral de Múltiplas Localizações",
            "I61.8 - Outras Hemorragias Intracerebrais",
            "I61.9 - Hemorragia Intracerebral Não Especificada",
            "I62 - Outras Hemorragias Intracranianas Não-traumáticas",
            "I62.0 - Hemorragia Subdural (aguda) (não-traumática)",
            "I62.1 - Hemorragia Extradural Não-traumática",
            "I62.9 - Hemorragia Intracraniana (não-traumática) Não Especificada",
            "I63 - Infarto Cerebral",
            "I63.0 - Infarto Cerebral Devido a Trombose de Artérias Pré-cerebrais",
            "I63.1 - Infarto Cerebral Devido a Embolia de Artérias Pré-cerebrais",
            "I63.2 - Infarto Cerebral Devido a Oclusão ou Estenose Não Especificadas de Artérias Pré-cerebrais",
            "I63.3 - Infarto Cerebral Devido a Trombose de Artérias Cerebrais",
            "I63.4 - Infarto Cerebral Devido a Embolia de Artérias Cerebrais",
            "I63.5 - Infarto Cerebral Devido a Oclusão ou Estenose Não Especificadas de Artérias Cerebrais",
            "I63.6 - Infarto Cerebral Devido a Trombose Venosa Cerebral Não-piogênica",
            "I63.8 - Outros Infartos Cerebrais",
            "I63.9 - Infarto Cerebral Não Especificado",
            "I64 - Acidente Vascular Cerebral, Não Especificado Como Hemorrágico ou Isquêmico",
            "I65 - Oclusão e Estenose de Artérias Pré-cerebrais Que Não Resultam em Infarto Cerebral",
            "I65.0 - Oclusão e Estenose da Artéria Vertebral",
            "I65.1 - Oclusão e Estenose da Artéria Basilar",
            "I65.2 - Oclusão e Estenose da Artéria Carótida",
            "I65.3 - Oclusão e Estenose de Artérias Pré-cerebrais Múltiplas e Bilaterais",
            "I65.8 - Oclusão e Estenose de Outra Artéria Pré-cerebral",
            "I65.9 - Oclusão e Estenose de Artérias Pré-cerebrais Não Especificadas",
            "I66 - Oclusão e Estenose de Artérias Cerebrais Que Não Resultam em Infarto Cerebral",
            "I66.0 - Oclusão e Estenose da Artéria Cerebral Média",
            "I66.1 - Oclusão e Estenose da Artéria Cerebral Anterior",
            "I66.2 - Oclusão e Estenose da Artéria Cerebral Posterior",
            "I66.3 - Oclusão e Estenose de Artérias Cerebelares",
            "I66.4 - Oclusão e Estenose de Artérias Cerebrais, Múltiplas e Bilaterais",
            "I66.8 - Oclusão e Estenose de Outra Artéria Cerebral",
            "I66.9 - Oclusão e Estenose de Artéria Cerebral Não Especificada",
            "I67 - Outras Doenças Cerebrovasculares",
            "I67.0 - Dissecção de Artérias Cerebrais, Sem Ruptura",
            "I67.1 - Aneurisma Cerebral Não-roto",
            "I67.2 - Aterosclerose Cerebral",
            "I67.3 - Leucoencefalopatia Vascular Progressiva",
            "I67.4 - Encefalopatia Hipertensiva",
            "I67.5 - Doença de Moyamoya",
            "I67.6 - Trombose Não-piogênica do Sistema Venoso Intracraniano",
            "I67.7 - Arterite Cerebral Não Classificada em Outra Parte",
            "I67.8 - Outras Doenças Cerebrovasculares Especificadas",
            "I67.9 - Doença Cerebrovascular Não Especificada",
            "I68 - Transtornos Cerebrovasculares em Doenças Classificadas em Outra Parte",
            "I68.0 - Angiopatia Cerebral Amiloidótica",
            "I68.1 - Arterite Cerebral em Doenças Infecciosas e Parasitárias",
            "I68.2 - Arterite Cerebral em Outras Doenças Classificadas em Outra Parte",
            "I68.8 - Outros Transtornos Cerebrovasculares em Doenças Classificadas em Outra Parte",
            "I69 - Seqüelas de Doenças Cerebrovasculares",
            "I69.0 - Seqüelas de Hemorragia Subaracnoídea",
            "I69.1 - Seqüelas de Hemorragia Intracerebral",
            "I69.2 - Seqüelas de Outras Hemorragias Intracranianas Não Traumáticas",
            "I69.3 - Seqüelas de Infarto Cerebral",
            "I69.4 - Seqüelas de Acidente Vascular Cerebral Não Especificado Como Hemorrágico ou Isquêmico",
            "I69.8 - Seqüelas de Outras Doenças Cerebrovasculares e Das Não Especificadas",
            "I70 - Aterosclerose",
            "I70.0 - Aterosclerose da Aorta",
            "I70.1 - Aterosclerose da Artéria Renal",
            "I70.2 - Aterosclerose Das Artérias Das Extremidades",
            "I70.8 - Aterosclerose de Outras Artérias",
            "I70.9 - Aterosclerose Generalizada e a Não Especificada",
            "I71 - Aneurisma e Dissecção da Aorta",
            "I71.0 - Aneurisma Dissecante da Aorta (qualquer Porção)",
            "I71.1 - Aneurisma da Aorta Torácica, Roto",
            "I71.2 - Aneurisma da Aorta Torácica, Sem Menção de Ruptura",
            "I71.3 - Aneurisma da Aorta Abdominal, Roto",
            "I71.4 - Aneurisma da Aorta Abdominal, Sem Menção de Ruptura",
            "I71.5 - Aneurisma da Aorta Tóraco-abdominal, Roto",
            "I71.6 - Aneurisma da Aorta Toráco-abdominal, Sem Menção de Ruptura",
            "I71.8 - Aneurisma da Aorta de Localização Não Especificada, Roto",
            "I71.9 - Aneurisma Aórtico de Localização Não Especificada, Sem Menção de Ruptura",
            "I72 - Outros Aneurismas",
            "I72.0 - Aneurisma da Artéria Carótida",
            "I72.1 - Aneurisma de Artéria Dos Membros Superiores",
            "I72.2 - Aneurisma da Artéria Renal",
            "I72.3 - Aneurisma de Artéria Ilíaca",
            "I72.4 - Aneurisma de Artéria Dos Membros Inferiores",
            "I72.8 - Aneurisma de Outras Artérias Especificadas",
            "I72.9 - Aneurisma de Localização Não Especificada",
            "I73 - Outras Doenças Vasculares Periféricas",
            "I73.0 - Síndrome de Raynaud",
            "I73.1 - Tromboangeíte Obliterante (doença de Buerger)",
            "I73.8 - Outras Doenças Vasculares Periféricas Especificadas",
            "I73.9 - Doenças Vasculares Periféricas Não Especificada",
            "I74 - Embolia e Trombose Arteriais",
            "I74.0 - Embolia e Trombose da Aorta Abdominal",
            "I74.1 - Embolia e Trombose de Outras Porções da Aorta e Das Não Especificadas",
            "I74.2 - Embolia e Trombose de Artérias Dos Membros Superiores",
            "I74.3 - Embolia e Trombose de Artérias Dos Membros Inferiores",
            "I74.4 - Embolia e Trombose de Artérias Dos Membros Não Especificadas",
            "I74.5 - Embolia e Trombose da Artéria Ilíaca",
            "I74.8 - Embolia e Trombose de Outras Artérias",
            "I74.9 - Embolia e Trombose de Artéria Não Especificada",
            "I77 - Outras Afecções Das Artérias e Arteríolas",
            "I77.0 - Fístula Arteriovenosa Adquirida",
            "I77.1 - Estenose de Artéria",
            "I77.2 - Ruptura de Artéria",
            "I77.3 - Displasia Fibromuscular Arterial",
            "I77.4 - Síndrome de Compressão da Artéria Celíaca",
            "I77.5 - Necrose de Artéria",
            "I77.6 - Arterite Não Especificada",
            "I77.8 - Outras Afecções Especificadas Das Artérias e Das Arteríolas",
            "I77.9 - Afecções de Artérias e Arteríolas, Não Especificadas",
            "I78 - Doenças Dos Capilares",
            "I78.0 - Telangiectasia Hemorrágica Hereditária",
            "I78.1 - Nevo Não-neoplásico",
            "I78.8 - Outras Doenças Dos Capilares",
            "I78.9 - Doenças Não Especificadas Dos Capilares",
            "I79 - Transtornos Das Artérias, Das Arteríolas e Dos Capilares em Doenças Classificadas em Outra Parte",
            "I79.0 - Aneurisma da Aorta em Doenças Classificadas em Outra Parte",
            "I79.1 - Aortite em Doenças Classificadas em Outra Parte",
            "I79.2 - Angiopatia Periférica em Doenças Classificadas em Outra Parte",
            "I79.8 - Outros Transtornos Das Artérias, Das Arteríolas e Dos Capilares em Doenças Classificadas em Outra Parte",
            "I80 - Flebite e Tromboflebite",
            "I80.0 - Flebite e Tromboflebite Dos Vasos Superficiais Dos Membros Inferiores",
            "I80.1 - Flebite e Tromboflebite da Veia Femural",
            "I80.2 - Flebite e Tromboflebite de Outros Vasos Profundos Dos Membros Inferiores",
            "I80.3 - Flebite e Tromboflebite Dos Membros Inferiores, Não Especificada",
            "I80.8 - Flebite e Tromboflebite de Outras Localizações",
            "I80.9 - Flebite e Tromboflebite de Localização Não Especificada",
            "I81 - Trombose da Veia Porta",
            "I82 - Outra Embolia e Trombose Venosas",
            "I82.0 - Síndrome de Budd-Chiari",
            "I82.1 - Tromboflebite Migratória",
            "I82.2 - Embolia e Trombose de Veia Cava",
            "I82.3 - Embolia e Trombose de Veia Renal",
            "I82.8 - Embolia e Trombose de Outras Veias Especificadas",
            "I82.9 - Embolia e Trombose Venosas de Veia Não Especificada",
            "I83 - Varizes Dos Membros Inferiores",
            "I83.0 - Varizes Dos Membros Inferiores Com Úlcera",
            "I83.1 - Varizes Dos Membros Inferiores Com Inflamação",
            "I83.2 - Varizes Dos Membros Inferiores Com Úlcera e Inflamação",
            "I83.9 - Varizes Dos Membros Inferiores Sem Úlcera ou Inflamação",
            "I84 - Hemorróidas",
            "I84.0 - Hemorróidas Internas Trombosadas",
            "I84.1 - Hemorróidas Internas Com Outras Complicações",
            "I84.2 - Hemorróidas Internas Sem Complicações",
            "I84.3 - Hemorróidas Externas Trombosadas",
            "I84.4 - Hemorróidas Externas Com Outras Complicações",
            "I84.5 - Hemorróidas Externas Sem Complicação",
            "I84.6 - Plicomas Hemorroidários Residuais",
            "I84.7 - Hemorróidas Trombosadas, Não Especificadas",
            "I84.8 - Hemorróidas Não Especificadas Com Outras Complicações",
            "I84.9 - Hemorróidas Sem Complicações, Não Especificadas",
            "I85 - Varizes Esofagianas",
            "I85.0 - Varizes Esofagianas Sangrantes",
            "I85.9 - Varizes Esofagianas Sem Sangramento",
            "I86 - Varizes de Outras Localizações",
            "I86.0 - Varizes Sublinguais",
            "I86.1 - Varizes Escrotais",
            "I86.2 - Varizes Pélvicas",
            "I86.3 - Varizes da Vulva",
            "I86.4 - Varizes Gástricas",
            "I86.8 - Varizes de Outras Localizações Especificadas",
            "I87 - Outros Transtornos Das Veias",
            "I87.0 - Síndrome Pós-flebite",
            "I87.1 - Compressão Venosa",
            "I87.2 - Insuficiência Venosa (crônica) (periférica)",
            "I87.8 - Outros Transtornos Venosos Especificados",
            "I87.9 - Transtorno Venoso Não Especificado",
            "I88 - Linfadenite Inespecífica",
            "I88.0 - Linfadenite Mesentérica Não Específica",
            "I88.1 - Linfadenite Crônica, Exceto a Mesentérica",
            "I88.8 - Outras Linfadenites Inespecíficas",
            "I88.9 - Linfadenite Não Especificada",
            "I89 - Outros Transtornos Não-infecciosos Dos Vasos Linfáticos e Dos Gânglios Linfáticos",
            "I89.0 - Linfedema Não Classificado em Outra Parte",
            "I89.1 - Linfangite",
            "I89.8 - Outros Transtornos Não-infecciosos, Especificados, Dos Vasos Linfáticos e Dos Gânglios Linfáticos",
            "I89.9 - Transtornos Não-infecciosos Dos Vasos Linfáticos e Dos Gânglios Linfáticos, Não Especificados",
            "I95 - Hipotensão",
            "I95.0 - Hipotensão Idiopática",
            "I95.1 - Hipotensão Ortostática",
            "I95.2 - Hipotensão Devida a Drogas",
            "I95.8 - Outros Tipos de Hipotensão",
            "I95.9 - Hipotensão Não Especificada",
            "I97 - Transtornos do Aparelho Circulatório, Subseqüentes a Procedimentos Não Classificados em Outra Parte",
            "I97.0 - Síndrome Pós-cardiotomia",
            "I97.1 - Outros Distúrbios Funcionais Subseqüentes à Cirurgia Cardíaca",
            "I97.2 - Síndrome do Linfedema Pós-mastectomia",
            "I97.8 - Outros Transtornos do Aparelho Circulatório Subseqüentes a Procedimentos Não Classificados em Outra Parte",
            "I97.9 - Transtornos do Aparelho Circulatório, Subseqüentes a Procedimento Não Especificado",
            "I98 - Outros Transtornos do Aparelho Circulatório em Doenças Classificadas em Outra Parte",
            "I98.0 - Sífilis Cardiovascular",
            "I98.1 - Transtornos Cardiovasculares em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "I98.2 - Varizes Esofagianas em Doenças Classificadas em Outra Parte",
            "I98.8 - Outros Transtornos Especificados do Aparelho Circulatório em Doenças Classificadas em Outra Parte",
            "I99 - Outros Transtornos do Aparelho Circulatório e os Não Especificados",
            "J00 - Nasofaringite Aguda (resfriado Comum)",
            "J01 - Sinusite Aguda",
            "J01.0 - Sinusite Maxilar Aguda",
            "J01.1 - Sinusite Frontal Aguda",
            "J01.2 - Sinusite Etmoidal Aguda",
            "J01.3 - Sinusite Esfenoidal Aguda",
            "J01.4 - Pansinusite Aguda",
            "J01.8 - Outras Sinusites Agudas",
            "J01.9 - Sinusite Aguda Não Especificada",
            "J02 - Faringite Aguda",
            "J02.0 - Faringite Estreptocócica",
            "J02.8 - Faringite Aguda Devida a Outros Microorganismos Especificados",
            "J02.9 - Faringite Aguda Não Especificada",
            "J03 - Amigdalite Aguda",
            "J03.0 - Amigdalite Estreptocócica",
            "J03.8 - Amigdalite Aguda Devida a Outros Microorganismos Especificados",
            "J03.9 - Amigdalite Aguda Não Especificada",
            "J04 - Laringite e Traqueíte Agudas",
            "J04.0 - Laringite Aguda",
            "J04.1 - Traqueíte Aguda",
            "J04.2 - Laringotraqueíte Aguda",
            "J05 - Laringite Obstrutiva Aguda (crupe) e Epiglotite",
            "J05.0 - Laringite Obstrutiva Aguda (crupe)",
            "J05.1 - Epiglotite Aguda",
            "J06 - Infecções Agudas Das Vias Aéreas Superiores de Localizações Múltiplas e Não Especificadas",
            "J06.0 - Laringofaringite Aguda",
            "J06.8 - Outras Infecções Agudas Das Vias Aéreas Superiores de Localizações Múltiplas",
            "J06.9 - Infecção Aguda Das Vias Aéreas Superiores Não Especificada",
            "J09 - Influenza (gripe) Devida a Vírus Identificado da Gripe Aviária",
            "J10 - Influenza Devida a Outro Vírus da Influenza (gripe) Identificado",
            "J10.0 - Influenza Com Pneumonia Devida a Outro Vírus da Influenza (gripe) Identificado",
            "J10.1 - Influenza Com Outras Manifestações Respiratórias, Devida a Outro Vírus da Influenza (gripe) Identificado",
            "J10.8 - Influenza Com Outras Manifestações, Devida a Outro Vírus da Influenza (gripe) Identificado",
            "J11 - Influenza (gripe) Devida a Vírus Não Identificado",
            "J11.0 - Influenza (gripe) Com Pneumonia, Devida a Vírus Não Identificado",
            "J11.1 - Influenza (gripe) Com Outras Manifestações Respiratórias, Devida a Vírus Não Identificado",
            "J11.8 - Influenza (gripe) Com Outras Manifestações, Devida a Vírus Não Identificado",
            "J12 - Pneumonia Viral Não Classificada em Outra Parte",
            "J12.0 - Pneumonia Devida a Adenovírus",
            "J12.1 - Pneumonia Devida a Vírus Respiratório Sincicial",
            "J12.2 - Pneumonia Devida à Parainfluenza",
            "J12.8 - Outras Pneumonias Virais",
            "J12.9 - Pneumonia Viral Não Especificada",
            "J13 - Pneumonia Devida a Streptococcus Pneumoniae",
            "J14 - Pneumonia Devida a Haemophilus Infuenzae",
            "J15 - Pneumonia Bacteriana Não Classificada em Outra Parte",
            "J15.0 - Pneumonia Devida à Klebsiella Pneumoniae",
            "J15.1 - Pneumonia Devida a Pseudomonas",
            "J15.2 - Pneumonia Devida a Staphylococcus",
            "J15.3 - Pneumonia Devida a Streptococcus do Grupo B",
            "J15.4 - Pneumonia Devida a Outros Estreptococos",
            "J15.5 - Pneumonia Devida a Escherichia Coli",
            "J15.6 - Pneumonia Devida a Outras Bactérias Aeróbicas Gram-negativas",
            "J15.7 - Pneumonia Devida a Mycoplasma Pneumoniae",
            "J15.8 - Outras Pneumonias Bacterianas",
            "J15.9 - Pneumonia Bacteriana Não Especificada",
            "J16 - Pneumonia Devida a Outros Microorganismos Infecciosos Especificados Não Classificados em Outra Parte",
            "J16.0 - Pneumonia Devida a Clamídias",
            "J16.8 - Pneumonia Devida a Outros Microorganismos Infecciosos Especificados",
            "J17 - Pneumonia em Doenças Classificadas em Outra Parte",
            "J17.0 - Pneumonia em Doenças Bacterianas Classificadas em Outra Parte",
            "J17.1 - Pneumonia em Doenças Virais Classificadas em Outra Parte",
            "J17.2 - Pneumonia em Micoses Classificadas em Outra Parte",
            "J17.3 - Pneumonia em Doenças Parasitárias Classificadas em Outra Parte",
            "J17.8 - Pneumonia em Outras Doenças Classificadas em Outra Parte",
            "J18 - Pneumonia Por Microorganismo Não Especificada",
            "J18.0 - Broncopneumonia Não Especificada",
            "J18.1 - Pneumonia Lobar Não Especificada",
            "J18.2 - Pneumonia Hipostática Não Especificada",
            "J18.8 - Outras Pneumonias Devidas a Microorganismos Não Especificados",
            "J18.9 - Pneumonia Não Especificada",
            "J20 - Bronquite Aguda",
            "J20.0 - Bronquite Aguda Devida a Mycoplasma Pneumoniae",
            "J20.1 - Bronquite Aguda Devida a Haemophilus Influenzae",
            "J20.2 - Bronquite Aguda Devida a Estreptococos",
            "J20.3 - Bronquite Aguda Devida a Vírus Coxsackie",
            "J20.4 - Bronquite Aguda Devida a Vírus Parainfluenza",
            "J20.5 - Bronquite Aguda Devida a Vírus Sincicial Respiratório",
            "J20.6 - Bronquite Aguda Devida a Rinovírus",
            "J20.7 - Bronquite Aguda Devida a Echovírus",
            "J20.8 - Bronquite Aguda Devida a Outros Microorganismos Especificados",
            "J20.9 - Bronquite Aguda Não Especificada",
            "J21 - Bronquiolite Aguda",
            "J21.0 - Bronquiolite Aguda Devida a Vírus Sincicial Respiratório",
            "J21.8 - Bronquiolite Aguda Devida a Outros Microorganismos Especificados",
            "J21.9 - Bronquite Aguda Não Especificada",
            "J22 - Infecções Agudas Não Especificada Das Vias Aéreas Inferiores",
            "J30 - Rinite Alérgica e Vasomotora",
            "J30.0 - Rinite Vasomotora",
            "J30.1 - Rinite Alérgica Devida a Pólen",
            "J30.2 - Outras Rinites Alérgicas Sazonais",
            "J30.3 - Outras Rinites Alérgicas",
            "J30.4 - Rinite Alérgica Não Especificada",
            "J31 - Rinite, Nasofaringite e Faringite Crônicas",
            "J31.0 - Rinite Crônica",
            "J31.1 - Nasofaringite Crônica",
            "J31.2 - Faringite Crônica",
            "J32 - Sinusite Crônica",
            "J32.0 - Sinusite Maxilar Crônica",
            "J32.1 - Sinusite Frontal Crônica",
            "J32.2 - Sinusite Etmoidal Crônica",
            "J32.3 - Sinusite Esfenoidal Crônica",
            "J32.4 - Pansinusite Crônica",
            "J32.8 - Outras Sinusites Crônicas",
            "J32.9 - Sinusite Crônica Não Especificada",
            "J33 - Pólipo Nasal",
            "J33.0 - Pólipo da Cavidade Nasal",
            "J33.1 - Degeneração Polipóide do Seio Paranasal",
            "J33.8 - Outros Pólipos do Seio Paranasal",
            "J33.9 - Pólipo Nasal Não Especificado",
            "J34 - Outros Transtornos do Nariz e Dos Seios Paranasais",
            "J34.0 - Abscesso, Furúnculo e Antraz do Nariz",
            "J34.1 - Cisto e Mucocele do Nariz e do Seio Paranasal",
            "J34.2 - Desvio do Septo Nasal",
            "J34.3 - Hipertrofia Dos Cornetos Nasais",
            "J34.8 - Outros Transtornos Especificados do Nariz e Dos Seios Paranasais",
            "J35 - Doenças Crônicas Das Amígdalas e Das Adenóides",
            "J35.0 - Amigdalite Crônica",
            "J35.1 - Hipertrofia Das Amígdalas",
            "J35.2 - Hipertrofia Das Adenóides",
            "J35.3 - Hipertrofia Das Amígdalas Com Hipertrofia Das Adenóides",
            "J35.8 - Outras Doenças Crônicas Das Amígdalas e Das Adenóides",
            "J35.9 - Doenças Das Amígdalas e Das Adenóides Não Especificadas",
            "J36 - Abscesso Periamigdaliano",
            "J37 - Laringite e Laringotraqueíte Crônicas",
            "J37.0 - Laringite Crônica",
            "J37.1 - Laringotraqueíte Crônica",
            "J38 - Doenças Das Cordas Vocais e da Laringe Não Classificadas em Outra Parte",
            "J38.0 - Paralisia Das Cordas Vocais e da Laringe",
            "J38.1 - Pólipo Das Cordas Vocais e da Laringe",
            "J38.2 - Nódulos Das Cordas Vocais",
            "J38.3 - Outras Doenças Das Cordas Vocais",
            "J38.4 - Edema da Laringe",
            "J38.5 - Espasmo da Laringe",
            "J38.6 - Estenose da Laringe",
            "J38.7 - Outras Doenças da Laringe",
            "J39 - Outras Doenças Das Vias Aéreas Superiores",
            "J39.0 - Abscesso Retrofaríngeo e Parafaríngeo",
            "J39.1 - Outros Abscessos da Faringe",
            "J39.2 - Outras Doenças da Faringe",
            "J39.3 - Reação de Hipersensibilidade Das Vias Aéreas Superiores de Localização Não Especificada",
            "J39.8 - Outras Doenças Especificadas Das Vias Aéreas Superiores",
            "J39.9 - Doença Não Especificada Das Vias Aéreas Superiores",
            "J40 - Bronquite Não Especificada Como Aguda ou Crônica",
            "J41 - Bronquite Crônica Simples e a Mucopurulenta",
            "J41.0 - Bronquite Crônica Simples",
            "J41.1 - Bronquite Crônica Mucopurulenta",
            "J41.8 - Bronquite Crônica Mista, Simples e Mucopurulenta",
            "J42 - Bronquite Crônica Não Especificada",
            "J43 - Enfisema",
            "J43.0 - Síndrome de MacLeod",
            "J43.1 - Enfisema Panlobular",
            "J43.2 - Enfisema Centrolobular",
            "J43.8 - Outras Formas de Enfisema",
            "J43.9 - Enfisema Não Especificado",
            "J44 - Outras Doenças Pulmonares Obstrutivas Crônicas",
            "J44.0 - Doença Pulmonar Obstrutiva Crônica Com Infecção Respiratória Aguda do Trato Respiratório Inferior",
            "J44.1 - Doença Pulmonar Obstrutiva Crônica Com Exacerbação Aguda Não Especificada",
            "J44.8 - Outras Formas Especificadas de Doença Pulmonar Obstrutiva Crônica",
            "J44.9 - Doença Pulmonar Obstrutiva Crônica Não Especificada",
            "J45 - Asma",
            "J45.0 - Asma Predominantemente Alérgica",
            "J45.1 - Asma Não-alérgica",
            "J45.8 - Asma Mista",
            "J45.9 - Asma Não Especificada",
            "J46 - Estado de Mal Asmático",
            "J47 - Bronquectasia",
            "J60 - Pneumoconiose Dos Mineiros de Carvão",
            "J61 - Pneumoconiose Devida a Amianto (asbesto) e Outras Fibras Minerais",
            "J62 - Pneumoconiose Devida a Poeira Que Contenham Sílica",
            "J62.0 - Pneumoconiose Devida a pó de Talco",
            "J62.8 - Pneumoconiose Devida a Outras Poeiras Que Contenham Sílica",
            "J63 - Pneumoconiose Devida a Outras Poeiras Inorgânicas",
            "J63.0 - Aluminose (do Pulmão)",
            "J63.1 - Fibrose (do Pulmão) Causada Por Bauxita",
            "J63.2 - Beriliose",
            "J63.3 - Fibrose (do Pulmão) Causada Por Grafite",
            "J63.4 - Siderose",
            "J63.5 - Estanose",
            "J63.8 - Pneumoconiose Devida a Outras Poeira Inorgânicas Especificados",
            "J64 - Pneumoconiose Não Especificada",
            "J65 - Pneumoconiose Associada Com Tuberculose",
            "J66 - Doenças Das Vias Aéreas Devida a Poeiras Orgânicas Específicas",
            "J66.0 - Bissinose",
            "J66.1 - Doença Dos Cardadores de Linho",
            "J66.2 - Canabinose",
            "J66.8 - Doenças Das Vias Aéreas Devida a Outras Poeiras Orgânicas Específicas",
            "J67 - Pneumonite de Hipersensibilidade Devida a Poeiras Orgânicas",
            "J67.0 - Pulmão de Fazendeiro",
            "J67.1 - Bagaçose",
            "J67.2 - Pulmão Dos Criadores de Pássaros",
            "J67.3 - Suberose",
            "J67.4 - Pulmão Dos Trabalhadores do Malte",
            "J67.5 - Pulmão Dos Que Trabalham Com Cogumelos",
            "J67.6 - Pulmão Dos Cortadores de Casca do Bordo",
            "J67.7 - Doença Pulmonar Devida Aos Sistemas de ar Condicionado e de Umidificação do ar",
            "J67.8 - Pneumonites de Hipersensibilidade, Devidas a Outras Poeiras Orgânicas",
            "J67.9 - Pneumonite de Hipersensibilidade Devida a Poeira Orgânica Não Especificada",
            "J68 - Afecções Respiratórias Devidas a Inalação de Produtos Químicos, Gases, Fumaças e Vapores",
            "J68.0 - Bronquite e Pneumonite Devida a Produtos Químicos, Gases, Fumaças e Vapores",
            "J68.1 - Edema Pulmonar Devido a Produtos Químicos, Gases, Fumaças e Vapores",
            "J68.2 - Inflamação Das Vias Aéreas Superiores Devida a Produtos Químicos, Gases, Fumaças e Vapores, Não Classificada em Outra Parte",
            "J68.3 - Outras Afecções Respiratórias Agudas e Subagudas Devidas a Produtos Químicos, Gases, Fumaças e Vapores",
            "J68.4 - Afecções Respiratórias Crônicas Devidas a Produtos Químicos, Gases, Fumaças e Vapores",
            "J68.8 - Outras Afecções Respiratórias Devida a Produtos Químicos, Gases, Fumaças e Vapores",
            "J68.9 - Afecção Respiratória Não Especificada Devida a Produtos Químicos, Gases, Fumaça e Vapores",
            "J69 - Pneumonite Devida a Sólidos e Líquidos",
            "J69.0 - Pneumonite Devida a Alimento ou Vômito",
            "J69.1 - Pneumonite Devida a Óleos e Essências",
            "J69.8 - Pneumonite Devida a Outros Sólidos e Líquidos",
            "J70 - Afecções Respiratórias Devida a Outros Agentes Externos",
            "J70.0 - Manifestações Pulmonares Agudas Devidas à Radiação",
            "J70.1 - Manifestações Pulmonares Crônicas e Outras, Devidas a Radiação",
            "J70.2 - Transtornos Pulmonares Intersticiais Agudos, Induzidos Por Droga",
            "J70.3 - Transtornos Pulmonares Intersticiais Crônicos Induzidos Por Droga",
            "J70.4 - Transtornos Pulmonar Intersticial Não Especificado Induzido Por Droga",
            "J70.8 - Afecções Respiratórias Devidas a Outros Agentes Externos Especificados",
            "J70.9 - Afecções Respiratórias Devidas a Agentes Externos Não Especificados",
            "J80 - Síndrome do Desconforto Respiratório do Adulto",
            "J81 - Edema Pulmonar, Não Especificado de Outra Forma",
            "J82 - Eosinofilia Pulmonar, Não Classificada em Outra Parte",
            "J84 - Outras Doenças Pulmonares Intersticiais",
            "J84.0 - Afecções Alveolares e Parieto-alveolares",
            "J84.1 - Outras Doenças Pulmonares Intersticiais Com Fibrose",
            "J84.8 - Outras Doenças Pulmonares Intersticiais Especificadas",
            "J84.9 - Doença Pulmonar Intersticial Não Especificadas",
            "J85 - Abscesso do Pulmão e do Mediastino",
            "J85.0 - Gangrena e Necrose do Pulmão",
            "J85.1 - Abscesso do Pulmão Com Pneumonia",
            "J85.2 - Abscesso do Pulmão Sem Pneumonia",
            "J85.3 - Abscesso do Mediastino",
            "J86 - Piotórax",
            "J86.0 - Piotórax Com Fístula",
            "J86.9 - Piotórax Sem Fístula",
            "J90 - Derrame Pleural Não Classificado em Outra Parte",
            "J91 - Derrame Pleural em Afecções Classificadas em Outra Parte",
            "J92 - Placas Pleurais",
            "J92.0 - Placas Pleurais Com Presença de Amianto (asbesto)",
            "J92.9 - Placas Pleurais Sem Presença de Amianto (asbesto)",
            "J93 - Pneumotórax",
            "J93.0 - Pneumotórax de Tensão, Espontâneo",
            "J93.1 - Outras Formas de Pneumotórax Espontâneo",
            "J93.8 - Outros Tipos de Pneumotórax, Especificados",
            "J93.9 - Pneumotórax Não Especificado",
            "J94 - Outras Afecções Pleurais",
            "J94.0 - Derrame Quiloso ou Quiliforme",
            "J94.1 - Fibrotórax",
            "J94.2 - Hemotórax",
            "J94.8 - Outras Afecções Pleurais Especificadas",
            "J94.9 - Afecção Pleural Não Especificada",
            "J95 - Afecções Respiratórias Pós-procedimentos Não Classificadas em Outra Parte",
            "J95.0 - Mau Funcionamento de Traqueostomia",
            "J95.1 - Insuficiência Pulmonar Aguda Subsequente a Cirurgia Torácica",
            "J95.2 - Insuficiência Pulmonar Aguda Subsequente a Cirurgia Não Torácica",
            "J95.3 - Insuficiência Pulmonar Crônica Pós-cirúrgica",
            "J95.4 - Síndrome de Mendelson",
            "J95.5 - Estenose Subglótica Pós-procedimento",
            "J95.8 - Outros Transtornos Respiratórios Pós-procedimentos",
            "J95.9 - Transtornos Respiratórios Pós-procedimentos Não Especificados",
            "J96 - Insuficiência Respiratória Não Classificada de Outra Parte",
            "J96.0 - Insuficiência Respiratória Aguda",
            "J96.1 - Insuficiência Respiratória Crônica",
            "J96.9 - Insuficiência Respiratória Não Especificada",
            "J98 - Outros Transtornos Respiratórios",
            "J98.0 - Outras Doenças Dos Brônquios Não Classificadas em Outra Parte",
            "J98.1 - Colapso Pulmonar",
            "J98.2 - Enfisema Intersticial",
            "J98.3 - Enfisema Compensatório",
            "J98.4 - Outros Transtornos Pulmonares",
            "J98.5 - Doenças do Mediastino Não Classificadas em Outra Parte",
            "J98.6 - Transtornos do Diafragma",
            "J98.8 - Outros Transtornos Respiratórios Especificados",
            "J98.9 - Transtorno Respiratório Não Especificados",
            "J99 - Transtornos Respiratórios em Doenças Classificadas em Outra Parte",
            "J99.0 - Doença Pulmonar Reumatóide",
            "J99.1 - Transtornos Respiratórios em Outras Doenças Sistêmicas do Tecido Conjuntivo Classificadas em Outra Parte",
            "J99.8 - Transtornos Respiratórios em Outras Doenças Classificadas em Outra Parte",
            "K00 - Distúrbios do Desenvolvimento e da Erupção Dos Dentes",
            "K00.0 - Anodontia",
            "K00.1 - Dentes Supranumerários",
            "K00.2 - Anomalias do Tamanho e da Forma Dos Dentes",
            "K00.3 - Dentes Manchados",
            "K00.4 - Distúrbios na Formação Dos Dentes",
            "K00.5 - Anomalias Hereditárias da Estrutura Dentária Não Classificadas em Outra Parte",
            "K00.6 - Distúrbios da Erupção Dentária",
            "K00.7 - Síndrome da Erupção Dentária",
            "K00.8 - Outros Distúrbios do Desenvolvimento Dos Dentes",
            "K00.9 - Distúrbio Não Especificado do Desenvolvimento Dentário",
            "K01 - Dentes Inclusos e Impactados",
            "K01.0 - Dentes Inclusos",
            "K01.1 - Dentes Impactados",
            "K02 - Cárie Dentária",
            "K02.0 - Cáries Limitadas ao Esmalte",
            "K02.1 - Cáries da Dentina",
            "K02.2 - Cárie do Cemento",
            "K02.3 - Cáries Dentárias Estáveis",
            "K02.4 - Odontoclasia",
            "K02.8 - Outras Cáries Dentárias",
            "K02.9 - Cárie Dentária, Sem Outra Especificação",
            "K03 - Outras Doenças Dos Tecidos Dentários Duros",
            "K03.0 - Atrito Dentário Excessivo",
            "K03.1 - Abrasão Dentária",
            "K03.2 - Erosão Dentária",
            "K03.3 - Reabsorção Patológica Dos Dentes",
            "K03.4 - Hipercementose",
            "K03.5 - Ancilose Dentária",
            "K03.6 - Depósitos Nos Dentes",
            "K03.7 - Alterações Pós-eruptivas da Cor Dos Tecidos Duros Dos Dentes",
            "K03.8 - Outras Doenças Especificadas Dos Tecidos Duros Dos Dentes",
            "K03.9 - Doença Dos Tecidos Duros Dos Dentes, Não Especificada",
            "K04 - Doenças da Polpa e Dos Tecidos Periapicais",
            "K04.0 - Pulpite",
            "K04.1 - Necrose da Polpa",
            "K04.2 - Degeneração da Polpa",
            "K04.3 - Formação Anormal de Tecidos Duros na Polpa",
            "K04.4 - Periodontite Apical Aguda de Origem Pulpar",
            "K04.5 - Periodontite Apical Crônica",
            "K04.6 - Abscesso Periapical Com Fístula",
            "K04.7 - Abscesso Periapical Sem Fístula",
            "K04.8 - Cisto Radicular",
            "K04.9 - Outras Doenças da Polpa e Dos Tecidos Periapicais e as Não Especificadas",
            "K05 - Gengivite e Doenças Periodontais",
            "K05.0 - Gengivite Aguda",
            "K05.1 - Gengivite Crônica",
            "K05.2 - Periodontite Aguda",
            "K05.3 - Periodontite Crônica",
            "K05.4 - Periodontose",
            "K05.5 - Outras Doenças Periodontais",
            "K05.6 - Doença Periodontal, Sem Outra Especificação",
            "K06 - Outros Transtornos da Gengiva e do Rebordo Alveolar Sem Dentes",
            "K06.0 - Retração Gengival",
            "K06.1 - Hiperplasia Gengival",
            "K06.2 - Lesões da Gengiva e do Rebordo Alveolar Sem Dentes, Associadas a Traumatismos",
            "K06.8 - Outros Transtornos Especificados da Gengiva e do Rebordo Alveolar Sem Dentes",
            "K06.9 - Transtorno da Gengiva e do Rebordo Alveolar Sem Dentes Sem Outra Especificação",
            "K07 - Anomalias Dentofaciais (inclusive a Maloclusão)",
            "K07.0 - Anomalias Importantes (major) do Tamanho da Mandíbula",
            "K07.1 - Anomalias da Relação Entre a Mandíbula Com a Base do Crânio",
            "K07.2 - Anomalias da Relação Entre as Arcadas Dentárias",
            "K07.3 - Anomalias da Posição Dos Dentes",
            "K07.4 - Maloclusão, Não Especificada",
            "K07.5 - Anormalidades Dentofaciais Funcionais",
            "K07.6 - Transtornos da Articulação Temporomandibular",
            "K07.8 - Outras Anomalias Dentofaciais",
            "K07.9 - Anomalia Dentofacial, Sem Outra Especificação",
            "K08 - Outros Transtornos Dos Dentes e de Suas Estruturas de Sustentação",
            "K08.0 - Exfoliação Dos Dentes Devida a Causas Sistêmicas",
            "K08.1 - Perda de Dentes Devida a Acidente, Extração ou a Doenças Periodontais Localizadas",
            "K08.2 - Atrofia do Rebordo Alveolar Sem Dentes",
            "K08.3 - Raiz Dentária Retida",
            "K08.8 - Outros Transtornos Especificados Dos Dentes e Das Estruturas de Sustentação",
            "K08.9 - Transtorno Dos Dentes e de Suas Estruturas de Sustentação, Sem Outra Especificação",
            "K09 - Cistos da Região Bucal Não Classificados em Outra Parte",
            "K09.0 - Cistos Odontogênicos de Desenvolvimento",
            "K09.1 - Cistos de Desenvolvimento (não-odontogênicos) da Região Bucal",
            "K09.2 - Outros Cistos Das Mandíbulas",
            "K09.8 - Outros Cistos da Região Oral Não Classificados em Outra Parte",
            "K09.9 - Cistos da Região Oral, Sem Outras Especificações",
            "K10 - Outras Doenças Dos Maxilares",
            "K10.0 - Transtornos do Desenvolvimento Dos Maxilares",
            "K10.1 - Granuloma Central de Células Gigantes",
            "K10.2 - Afecções Inflamatórias Dos Maxilares",
            "K10.3 - Alveolite Maxilar",
            "K10.8 - Outras Doenças Especificadas Dos Maxilares",
            "K10.9 - Doença Dos Maxilares, Sem Outra Especificação",
            "K11 - Doenças Das Glândulas Salivares",
            "K11.0 - Atrofia de Glândula Salivar",
            "K11.1 - Hipertrofia de Glândula Salivar",
            "K11.2 - Sialadenite",
            "K11.3 - Abscesso de Glândula Salivar",
            "K11.4 - Fístula de Glândula Salivar",
            "K11.5 - Sialolitíase",
            "K11.6 - Mucocele de Glândula Salivar",
            "K11.7 - Alterações da Secreção Salivar",
            "K11.8 - Outras Doenças Das Glândulas Salivares",
            "K11.9 - Doença de Glândula Salivar, Sem Outra Especificação",
            "K12 - Estomatite e Lesões Correlatas",
            "K12.0 - Aftas Bucais Recidivantes",
            "K12.1 - Outras Formas de Estomatite",
            "K12.2 - Celulite e Abscesso da Boca",
            "K13 - Outras Doenças do Lábio e da Mucosa Oral",
            "K13.0 - Doenças Dos Lábios",
            "K13.1 - Mordedura da Mucosa Das Bochechas e Dos Lábios",
            "K13.2 - Leucoplasia e Outras Afecções do Epitélio Oral, Inclusive da Língua",
            "K13.3 - Leucoplasia Pilosa",
            "K13.4 - Lesões Granulomatosas e Granulomatóides da Mucosa Oral",
            "K13.5 - Fibrose Oral Submucosa",
            "K13.6 - Hiperplasia Irritativa da Mucosa Oral",
            "K13.7 - Outras Lesões e as Não Especificadas da Mucosa Oral",
            "K14 - Doenças da Língua",
            "K14.0 - Glossite",
            "K14.1 - Língua Geográfica",
            "K14.2 - Glossite Rombóide Mediana",
            "K14.3 - Hipertrofia Das Papilas Linguais",
            "K14.4 - Atrofia Das Papilas Linguais",
            "K14.5 - Língua Escrotal",
            "K14.6 - Glossodínia",
            "K14.8 - Outras Doenças da Língua",
            "K14.9 - Doença da Língua, Sem Outra Especificação",
            "K20 - Esofagite",
            "K21 - Doença de Refluxo Gastroesofágico",
            "K21.0 - Doença de Refluxo Gastroesofágico Com Esofagite",
            "K21.9 - Doença de Refluxo Gastroesofágico Sem Esofagite",
            "K22 - Outras Doenças do Esôfago",
            "K22.0 - Acalásia do Cárdia",
            "K22.1 - Úlcera do Esôfago",
            "K22.2 - Obstrução do Esôfago",
            "K22.3 - Perfuração do Esôfago",
            "K22.4 - Discinesia do Esôfago",
            "K22.5 - Divertículo do Esôfago Adquirido",
            "K22.6 - Síndrome da Laceração Hemorrágica Gastroesofágica",
            "K22.7 - Esôfago de Barret",
            "K22.8 - Outras Doenças Especificadas do Esôfago",
            "K22.9 - Doença do Esôfago, Sem Outra Especificação",
            "K23 - Transtornos do Esôfago em Doenças Classificadas em Outra Parte",
            "K23.0 - Esofagite Tuberculosa",
            "K23.1 - Megaesôfago na Doença de Chagas",
            "K23.8 - Transtornos do Esôfago em Outras Doenças Classificadas em Outra Parte",
            "K25 - Úlcera Gástrica",
            "K25.0 - Úlcera Gástrica - Aguda Com Hemorragia",
            "K25.1 - Úlcera Gástrica - Aguda Com Perfuração",
            "K25.2 - Úlcera Gástrica - Aguda Com Hemorragia e Perfuração",
            "K25.3 - Úlcera Gástrica - Aguda Sem Hemorragia ou Perfuração",
            "K25.4 - Úlcera Gástrica - Crônica ou Não Especificada Com Hemorragia",
            "K25.5 - Úlcera Gástrica - Crônica ou Não Especificada Com Perfuração",
            "K25.6 - Úlcera Gástrica - Crônica ou Não Especificada Com Hemorragia e Perfuração",
            "K25.7 - Úlcera Gástrica - Crônica Sem Hemorragia ou Perfuração",
            "K25.9 - Úlcera Gástrica - Não Especificada Como Aguda ou Crônica, Sem Hemorragia ou Perfuração",
            "K26 - Úlcera Duodenal",
            "K26.0 - Úlcera Duodenal - Aguda Com Hemorragia",
            "K26.1 - Úlcera Duodenal - Aguda Com Perfuração",
            "K26.2 - Úlcera Duodenal - Aguda Com Hemorragia e Perfuração",
            "K26.3 - Úlcera Duodenal - Aguda Sem Hemorragia ou Perfuração",
            "K26.4 - Úlcera Duodenal - Crônica ou Não Especificada Com Hemorragia",
            "K26.5 - Úlcera Duodenal - Crônica ou Não Especificada Com Perfuração",
            "K26.6 - Úlcera Duodenal - Crônica ou Não Especificada Com Hemorragia e Perfuração",
            "K26.7 - Úlcera Duodenal - Crônica Sem Hemorragia ou Perfuração",
            "K26.9 - Úlcera Duodenal - Não Especificada Como Aguda ou Crônica, Sem Hemorragia ou Perfuração",
            "K27 - Úlcera Péptica de Localização Não Especificada",
            "K27.0 - Úlcera Péptica de Localização Não Especificada - Aguda Com Hemorragia",
            "K27.1 - Úlcera Péptica de Localização Não Especificada - Aguda Com Perfuração",
            "K27.2 - Úlcera Péptica de Localização Não Especificada - Aguda Com Hemorragia e Perfuração",
            "K27.3 - Úlcera Péptica de Localização Não Especificada - Aguda Sem Hemorragia ou Perfuração",
            "K27.4 - Úlcera Péptica de Localização Não Especificada - Crônica ou Não Especificada Com Hemorragia",
            "K27.5 - Úlcera Péptica de Localização Não Especificada - Crônica ou Não Especificada Com Perfuração",
            "K27.6 - Úlcera Péptica de Localização Não Especificada - Crônica ou Não Especificada Com Hemorragia e Perfuração",
            "K27.7 - Úlcera Péptica de Localização Não Especificada - Crônica Sem Hemorragia ou Perfuração",
            "K27.9 - Úlcera Péptica de Localização Não Especificada - Não Especificada Como Aguda ou Crônica, Sem Hemorragia ou Perfuração",
            "K28 - Úlcera Gastrojejunal",
            "K28.0 - Úlcera Gastrojejunal - Aguda Com Hemorragia",
            "K28.1 - Úlcera Gastrojejunal - Aguda Com Perfuração",
            "K28.2 - Úlcera Gastrojejunal - Aguda Com Hemorragia e Perfuração",
            "K28.3 - Úlcera Gastrojejunal - Aguda Sem Hemorragia ou Perfuração",
            "K28.4 - Úlcera Gastrojejunal - Crônica ou Não Especificada Com Hemorragia",
            "K28.5 - Úlcera Gastrojejunal - Crônica ou Não Especificada Com Perfuração",
            "K28.6 - Úlcera Gastrojejunal - Crônica ou Não Especificada Com Hemorragia e Perfuração",
            "K28.7 - Úlcera Gastrojejunal - Crônica Sem Hemorragia ou Perfuração",
            "K28.9 - Úlcera Gastrojejunal - Não Especificada Como Aguda ou Crônica, Sem Hemorragia ou Perfuração",
            "K29 - Gastrite e Duodenite",
            "K29.0 - Gastrite Hemorrágica Aguda",
            "K29.1 - Outras Gastrites Agudas",
            "K29.2 - Gastrite Alcoólica",
            "K29.3 - Gastrite Superficial Crônica",
            "K29.4 - Gastrite Atrófica Crônica",
            "K29.5 - Gastrite Crônica, Sem Outra Especificação",
            "K29.6 - Outras Gastrites",
            "K29.7 - Gastrite Não Especificada",
            "K29.8 - Duodenite",
            "K29.9 - Gastroduodenite, Sem Outra Especificação",
            "K30 - Dispepsia",
            "K31 - Outras Doenças do Estômago e do Duodeno",
            "K31.0 - Dilatação Aguda do Estômago",
            "K31.1 - Estenose Pilórica Hipertrófica do Adulto",
            "K31.2 - Estenose e Estreitamento em Ampulheta do Estômago",
            "K31.3 - Espasmo do Piloro Não Classificado em Outra Parte",
            "K31.4 - Divertículo Gástrico",
            "K31.5 - Obstrução do Duodeno",
            "K31.6 - Fístula do Estômago e do Duodeno",
            "K31.7 - Pólipo do Estômago e do Duodeno",
            "K31.8 - Outras Doenças Especificadas do Estômago e do Duodeno",
            "K31.9 - Doenças do Estômago e do Duodeno, Sem Outra Especificação",
            "K35 - Apendicite Aguda",
            "K35.0 - Apendicite Aguda Com Peritonite Generalizada",
            "K35.1 - Apendicite Aguda Com Abscesso Peritonial",
            "K35.9 - Apendicite Aguda Sem Outra Especificação",
            "K36 - Outras Formas de Apendicite",
            "K37 - Apendicite, Sem Outras Especificações",
            "K38 - Outras Doenças do Apêndice",
            "K38.0 - Hiperplasia do Apêndice",
            "K38.1 - Concreções Apendiculares",
            "K38.2 - Divertículo do Apêndice",
            "K38.3 - Fístula do Apêndice",
            "K38.8 - Outras Doenças Especificadas do Apêndice",
            "K38.9 - Doença do Apêndice, Sem Outra Especificação",
            "K40 - Hérnia Inguinal",
            "K40.0 - Hérnia Inguinal Bilateral, Com Obstrução, Sem Gangrena",
            "K40.1 - Hérnia Inguinal Bilateral, Com Gangrena",
            "K40.2 - Hérnia Inguinal Bilateral, Sem Obstrução ou Gangrena",
            "K40.3 - Hérnia Inguinal Unilateral ou Não Especificada, Com Obstrução Sem Gangrena",
            "K40.4 - Hérnia Inguinal Unilateral ou Não Especificada, Com Gangrena",
            "K40.9 - Hérnia Inguinal Unilateral ou Não Especificada, Sem Obstrução ou Gangrena",
            "K41 - Hérnia Femoral",
            "K41.0 - Hérnia Femoral Bilateral, Com Obstrução, Sem Gangrena",
            "K41.1 - Hérnia Femoral Bilateral, Com Gangrena",
            "K41.2 - Hérnia Femoral Bilateral, Sem Obstrução ou Gangrena",
            "K41.3 - Hérnia Femoral Unilateral ou Não Especificada, Com Obstrução, Sem Gangrena",
            "K41.4 - Hérnia Femoral Unilateral ou Não Especificada, Com Gangrena",
            "K41.9 - Hérnia Femoral Unilateral ou Não Especificada, Sem Obstrução ou Gangrena",
            "K42 - Hérnia Umbilical",
            "K42.0 - Hérnia Umbilical Com Obstrução, Sem Gangrena",
            "K42.1 - Hérnia Umbilical Com Gangrena",
            "K42.9 - Hérnia Umbilical Sem Obstrução ou Gangrena",
            "K43 - Hérnia Ventral",
            "K43.0 - Hérnia Ventral Com Obstrução, Sem Gangrena",
            "K43.1 - Hérnia Ventral Com Gangrena",
            "K43.9 - Hérnia Ventral Sem Obstrução ou Gangrena",
            "K44 - Hérnia Diafragmática",
            "K44.0 - Hérnia Diafragmática Com Obstrução, Sem Gangrena",
            "K44.1 - Hérnia Diafragmática Com Gangrena",
            "K44.9 - Hérnia Diafragmática Sem Obstrução ou Gangrena",
            "K45 - Outras Hérnias Abdominais",
            "K45.0 - Outras Hérnias Abdominais Especificadas, Com Obstrução, Sem Gangrena",
            "K45.1 - Outras Hérnias Abdominais Especificadas, Com Gangrena",
            "K45.8 - Outras Hérnias Abdominais Especificadas, Sem Obstrução ou Gangrena",
            "K46 - Hérnia Abdominal Não Especificada",
            "K46.0 - Hérnia Abdominal Não Especificada, Com Obstrução, Sem Gangrena",
            "K46.1 - Hérnia Abdominal Não Especificada Com Gangrena",
            "K46.9 - Hérnia Abdominal Não Especificada, Sem Obstrução ou Gangrena",
            "K50 - Doença de Crohn (enterite Regional)",
            "K50.0 - Doença de Crohn do Intestino Delgado",
            "K50.1 - Doença de Crohn do Intestino Grosso",
            "K50.8 - Outra Forma de Doença de Crohn",
            "K50.9 - Doença de Crohn de Localização Não Especificada",
            "K51 - Colite Ulcerativa",
            "K51.0 - Enterocolite Ulcerativa (crônica)",
            "K51.1 - Ileocolite Ulcerativa (crônica)",
            "K51.2 - Proctite Ulcerativa (crônica)",
            "K51.3 - Retossigmoidite Ulcerativa (crônica)",
            "K51.4 - Pseudopolipose do Cólon",
            "K51.5 - Proctocolite Mucosa",
            "K51.8 - Outras Colites Ulcerativas",
            "K51.9 - Colite Ulcerativa, Sem Outra Especificação",
            "K52 - Outras Gastroenterites e Colites Não-infecciosas",
            "K52.0 - Gastroenterite e Colite Devida à Radiação",
            "K52.1 - Gastroenterite e Colite Tóxicas",
            "K52.2 - Gastroenterite e Colite Alérgicas ou Ligadas à Dieta",
            "K52.8 - Outras Gastroenterites e Colites Especificadas, Não-infecciosas",
            "K52.9 - Gastroenterite e Colite Não-infecciosas, Não Especificadas",
            "K55 - Transtornos Vasculares do Intestino",
            "K55.0 - Transtornos Vasculares Agudos do Intestino",
            "K55.1 - Transtornos Vasculares Crônicos do Intestino",
            "K55.2 - Angiodisplasia do Cólon",
            "K55.8 - Outros Transtornos Vasculares do Intestino",
            "K55.9 - Transtorno Vascular do Intestino, Sem Outra Especificação",
            "K56 - Íleo Paralítico e Obstrução Intestinal Sem Hérnia",
            "K56.0 - Íleo Paralítico",
            "K56.1 - Intussuscepção",
            "K56.2 - Volvo",
            "K56.3 - Íleo Biliar",
            "K56.4 - Outras Obstruções do Intestino",
            "K56.5 - Aderências Intestinais (bridas) Com Obstrução",
            "K56.6 - Outras Formas de Obstrução Intestinal, e as Não Especificadas",
            "K56.7 - Íleo, Não Especificado",
            "K57 - Doença Diverticular do Intestino",
            "K57.0 - Doença Diverticular do Intestino Delgado Com Perfuração e Abscesso",
            "K57.1 - Doença Diverticular do Intestino Delgado Sem Perfuração ou Abscesso",
            "K57.2 - Doença Diverticular do Intestino Grosso Com Perfuração e Abscesso",
            "K57.3 - Doença Diverticular do Intestino Grosso Sem Perfuração ou Abscesso",
            "K57.4 - Doença Diverticular Concomitante Dos Intestinos Delgado e Grosso Com Perfuração e Abscesso",
            "K57.5 - Doença Diverticular Concomitante Dos Intestinos Delgado e Grosso Sem Perfuração ou Abscesso",
            "K57.8 - Doença Diverticular do Intestino, de Localização Não Especificada, Com Perfuração e Abscesso",
            "K57.9 - Doença Diverticular do Intestino, de Localização Não Especificada, Sem Perfuração ou Abscesso",
            "K58 - Síndrome do Cólon Irritável",
            "K58.0 - Síndrome do Cólon Irritável Com Diarréia",
            "K58.9 - Síndrome do Cólon Irritável Sem Diarréia",
            "K59 - Outros Transtornos Funcionais do Intestino",
            "K59.0 - Constipação",
            "K59.1 - Diarréia Funcional",
            "K59.2 - Cólon Neurogênico Não Classificado em Outra Parte",
            "K59.3 - Megacólon Não Classificado em Outra Parte",
            "K59.4 - Espasmo Anal",
            "K59.8 - Outros Transtornos Funcionais Especificados do Intestino",
            "K59.9 - Transtorno Intestinal Funcional, Não Especificado",
            "K60 - Fissura e Fístula Das Regiões Anal e Retal",
            "K60.0 - Fissura Anal Aguda",
            "K60.1 - Fissura Anal Crônica",
            "K60.2 - Fissura Anal, Não Especificada",
            "K60.3 - Fístula Anal",
            "K60.4 - Fístula Retal",
            "K60.5 - Fístula Anorretal",
            "K61 - Abscesso Das Regiões Anal e Retal",
            "K61.0 - Abscesso Anal",
            "K61.1 - Abscesso Retal",
            "K61.2 - Abscesso Anorretal",
            "K61.3 - Abscesso Ísquio-retal",
            "K61.4 - Abscesso Intra-esfincteriano",
            "K62 - Outras Doenças do Reto e do Ânus",
            "K62.0 - Pólipo Anal",
            "K62.1 - Pólipo Retal",
            "K62.2 - Prolapso Anal",
            "K62.3 - Prolapso Retal",
            "K62.4 - Estenose do Ânus e do Reto",
            "K62.5 - Hemorragia do Ânus e do Reto",
            "K62.6 - Úlcera do Ânus e do Reto",
            "K62.7 - Proctite Por Radiação",
            "K62.8 - Outras Doenças Especificadas do Ânus e do Reto",
            "K62.9 - Doença do Ânus e do Reto, Sem Outra Especificação",
            "K63 - Outras Doenças do Intestino",
            "K63.0 - Abscesso do Intestino",
            "K63.1 - Perfuração do Intestino (não-traumática)",
            "K63.2 - Fístula do Intestino",
            "K63.3 - Úlcera do Intestino",
            "K63.4 - Enteroptose",
            "K63.5 - Pólipo do Cólon",
            "K63.8 - Outras Doenças Especificadas do Intestino",
            "K63.9 - Doença do Intestino, Sem Outra Especificação",
            "K65 - Peritonite",
            "K65.0 - Peritonite Aguda",
            "K65.8 - Outras Peritonites",
            "K65.9 - Peritonite, Sem Outras Especificações",
            "K66 - Outros Transtornos do Peritônio",
            "K66.0 - Aderências Peritoniais",
            "K66.1 - Hemoperitônio",
            "K66.8 - Outras Afecções Especificadas do Peritônio",
            "K66.9 - Afecções do Peritônio, Sem Outra Especificação",
            "K67 - Comprometimento do Peritônio, em Doenças Infecciosas Classificadas em Outra Parte",
            "K67.0 - Peritonite Por Clamídias",
            "K67.1 - Peritonite Gonocócica",
            "K67.2 - Peritonite Sifilítica",
            "K67.3 - Peritonite Tuberculosa",
            "K67.8 - Outras Formas de Comprometimento do Peritônio em Doenças Infecciosas Classificadas em Outra Parte",
            "K70 - Doença Alcoólica do Fígado",
            "K70.0 - Fígado Gorduroso Alcoólico",
            "K70.1 - Hepatite Alcoólica",
            "K70.2 - Fibrose e Esclerose Alcoólicas do Fígado",
            "K70.3 - Cirrose Hepática Alcoólica",
            "K70.4 - Insuficiência Hepática Alcoólica",
            "K70.9 - Doença Alcoólica do Fígado, Sem Outra Especificação",
            "K71 - Doença Hepática Tóxica",
            "K71.0 - Doença Hepática Tóxica Com Colestase",
            "K71.1 - Doença Hepática Tóxica Com Necrose Hepática",
            "K71.2 - Doença Hepática Tóxica Com Hepatite Aguda",
            "K71.3 - Doença Hepática Tóxica Com Hepatite Crônica Persistente",
            "K71.4 - Doença Hepática Tóxica Com Hepatite Crônica Lobular",
            "K71.5 - Doença Hepática Tóxica Com Hepatite Crônica Ativa",
            "K71.6 - Doença Hepática Tóxica Com Hepatite Não Classificada em Outra Parte",
            "K71.7 - Doença Hepática Tóxica Com Fibrose e Cirrose Hepáticas",
            "K71.8 - Doença Hepática Tóxica Com Outros Transtornos do Fígado",
            "K71.9 - Doença Hepática Tóxica, Sem Outra Especificação",
            "K72 - Insuficiência Hepática Não Classificada em Outra Parte",
            "K72.0 - Insuficiência Hepática Aguda e Subaguda",
            "K72.1 - Insuficiência Hepática Crônica",
            "K72.9 - Insuficiência Hepática, Sem Outras Especificações",
            "K73 - Hepatite Crônica Não Classificada em Outra Parte",
            "K73.0 - Hepatite Crônica Persistente, Não Classificada em Outra Parte",
            "K73.1 - Hepatite Crônica Lobular, Não Classificada em Outra Parte",
            "K73.2 - Hepatite Crônica Ativa, Não Classificada em Outra Parte",
            "K73.8 - Outras Hepatites Crônicas Não Classificada em Outra Parte",
            "K73.9 - Hepatite Crônica, Sem Outra Especificação",
            "K74 - Fibrose e Cirrose Hepáticas",
            "K74.0 - Fibrose Hepática",
            "K74.1 - Esclerose Hepática",
            "K74.2 - Fibrose Hepática Com Esclerose Hepática",
            "K74.3 - Cirrose Biliar Primária",
            "K74.4 - Cirrose Biliar Secundária",
            "K74.5 - Cirrose Biliar, Sem Outra Especificação",
            "K74.6 - Outras Formas de Cirrose Hepática e as Não Especificadas",
            "K75 - Outras Doenças Inflamatórias do Fígado",
            "K75.0 - Abscesso Hepático",
            "K75.1 - Flebite da Veia Porta",
            "K75.2 - Hepatite Reativa Não-específica",
            "K75.3 - Hepatite Granulomatosa Não Classificada em Outra Parte",
            "K75.4 - Hepatite Autoimune",
            "K75.8 - Outras Doenças Hepáticas Inflamatórias Especificadas",
            "K75.9 - Doença Hepática Inflamatória, Sem Outra Especificação",
            "K76 - Outras Doenças do Fígado",
            "K76.0 - Degeneração Gordurosa do Fígado Não Classificada em Outra Parte",
            "K76.1 - Congestão Passiva Crônica do Fígado",
            "K76.2 - Necrose Hemorrágica Central do Fígado",
            "K76.3 - Infarto do Fígado",
            "K76.4 - Peliose Hepática",
            "K76.5 - Doença Hepática Veno-oclusiva",
            "K76.6 - Hipertensão Portal",
            "K76.7 - Síndrome Hepatorrenal",
            "K76.8 - Outras Doenças Especificadas do Fígado",
            "K76.9 - Doença Hepática, Sem Outra Especificação",
            "K77 - Transtornos do Fígado em Doenças Classificadas em Outra Parte",
            "K77.0 - Transtornos Hepáticos em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "K77.8 - Transtornos Hepáticos em Outras Doenças Classificadas em Outra Parte",
            "K80 - Colelitíase",
            "K80.0 - Calculose da Vesícula Biliar Com Colicistite Aguda",
            "K80.1 - Calculose da Vesícula Biliar Com Outras Formas de Colecistite",
            "K80.2 - Calculose da Vesícula Biliar Sem Colecistite",
            "K80.3 - Calculose de Via Biliar Com Colangite",
            "K80.4 - Calculose de Via Biliar Com Colecistite",
            "K80.5 - Calculose de Via Biliar Sem Colangite ou Colecistite",
            "K80.8 - Outras Colelitíases",
            "K81 - Colecistite",
            "K81.0 - Colecistite Aguda",
            "K81.1 - Colecistite Crônica",
            "K81.8 - Outras Colecistites",
            "K81.9 - Colecistite, Sem Outra Especificação",
            "K82 - Outras Doenças da Vesícula Biliar",
            "K82.0 - Obstrução da Vesícula Biliar",
            "K82.1 - Hidropsia da Vesícula Biliar",
            "K82.2 - Perfuração da Vesícula Biliar",
            "K82.3 - Fístula da Vesícula Biliar",
            "K82.4 - Colesterolose da Vesícula Biliar",
            "K82.8 - Outras Doenças Especificadas da Vesícula Biliar",
            "K82.9 - Doença da Vesícula Biliar, Sem Outra Especificação",
            "K83 - Outras Doenças Das Vias Biliares",
            "K83.0 - Colangite",
            "K83.1 - Obstrução de Via Biliar",
            "K83.2 - Perfuração de Via Biliar",
            "K83.3 - Fístula de Via Biliar",
            "K83.4 - Espasmo do Esfíncter de Oddi",
            "K83.5 - Cisto Biliar",
            "K83.8 - Outras Doenças Especificadas Das Vias Biliares",
            "K83.9 - Doença Das Vias Biliares, Sem Outra Especificação",
            "K85 - Pancreatite Aguda",
            "K85.0 - Pancreatite Aguda Idiopática",
            "K85.1 - Pancreatite Aguda Biliar",
            "K85.2 - Pancreatite Aguda Induzida Por Álcool",
            "K85.3 - Pancreatite Aguda Induzida Por Droga",
            "K85.8 - Outras Pancreatites Agudas",
            "K85.9 - Pancreatite Aguda, Não Especificada",
            "K86 - Outras Doenças do Pâncreas",
            "K86.0 - Pancreatite Crônica Induzida Por Álcool",
            "K86.1 - Outras Pancreatites Crônicas",
            "K86.2 - Cisto do Pâncreas",
            "K86.3 - Pseudocisto do Pâncreas",
            "K86.8 - Outras Doenças Especificadas do Pâncreas",
            "K86.9 - Doença do Pâncreas, Sem Outra Especificação",
            "K87 - Transtornos da Vesícula Biliar, Das Vias Biliares e do Pâncreas em Doenças Classificadas em Outra Parte",
            "K87.0 - Transtornos da Vesícula Biliar e Das Vias Biliares em Doenças Classificadas em Outra Parte",
            "K87.1 - Transtornos do Pâncreas em Doenças Classificadas em Outra Parte",
            "K90 - Má-absorção Intestinal",
            "K90.0 - Doença Celíaca",
            "K90.1 - Espru Tropical",
            "K90.2 - Síndrome da Alça Cega Não Classificada em Outra Parte",
            "K90.3 - Esteatorréia Pancreática",
            "K90.4 - Má-absorção Devida a Intolerância Não Classificada em Outra Parte",
            "K90.8 - Outras Formas de Má-absorção Intestinal",
            "K90.9 - Má-absorção Intestinal, Sem Outra Especificação",
            "K91 - Transtornos do Aparelho Digestivo Pós-procedimentos, Não Classificados em Outra Parte",
            "K91.0 - Vômitos Pós-cirurgia Gastrointestinal",
            "K91.1 - Síndromes Pós-cirurgia Gástrica",
            "K91.2 - Má-absorção Pós-cirúrgica Não Classificada em Outra Parte",
            "K91.3 - Obstrução Intestinal Pós-operatória",
            "K91.4 - Mau Funcionamento de Colostomia e Enterostomia",
            "K91.5 - Síndrome Pós-colecistectomia",
            "K91.8 - Outros Transtornos do Aparelho Digestivo, Pós-cirúrgicos, Não Classificados em Outra Parte",
            "K91.9 - Transtorno do Aparelho Digestivo Pós Procedimento",
            "K92 - Outras Doenças do Aparelho Digestivo",
            "K92.0 - Hematêmese",
            "K92.1 - Melena",
            "K92.2 - Hemorragia Gastrointestinal, Sem Outra Especificação",
            "K92.8 - Outras Doenças Especificadas do Aparelho Digestivo",
            "K92.9 - Doença do Aparelho Digestivo, Sem Outra Especificação",
            "K93 - Transtornos de Outros Órgãos Digestivos em Doenças Classificadas em Outra Parte",
            "K93.0 - Transtornos Devido a Tuberculose do Intestino, do Peritônio e Dos Gânglios do Mesentério",
            "K93.1 - Megacólon na Doença de Chagas",
            "K93.8 - Transtornos de Outros Órgãos Digestivos Especificados em Doenças Classificadas em Outra Parte",
            "L00 - Síndrome da Pele Escaldada Estafilocócica do Recém-nascido",
            "L01 - Impetigo",
            "L01.0 - Impetigo (qualquer Localização) (qualquer Microorganismo)",
            "L01.1 - Impetiginização de Outras Dermatoses",
            "L02 - Abscesso Cutâneo, Furúnculo e Antraz",
            "L02.0 - Abscesso Cutâneo, Furúnculo e Antraz da Face",
            "L02.1 - Abscesso Cutâneo, Furúnculo e Antraz do Pescoço",
            "L02.2 - Abscesso Cutâneo, Furúnculo e Antraz do Tronco",
            "L02.3 - Abscesso Cutâneo, Furúnculo e Antraz da Nádega",
            "L02.4 - Abscesso Cutâneo, Furúnculo e Antraz Do(s) Membro(s)",
            "L02.8 - Abscesso Cutâneo, Furúnculo e Antraz de Outras Localizações",
            "L02.9 - Abscesso Cutâneo, Furúnculo e Antraz de Localização Não Especificada",
            "L03 - Celulite (Flegmão)",
            "L03.0 - Celulite de Dedos Das Mãos e Dos Pés",
            "L03.1 - Celulite de Outras Partes Do(s) Membro(s)",
            "L03.2 - Celulite da Face",
            "L03.3 - Celulite do Tronco",
            "L03.8 - Celulite de Outros Locais",
            "L03.9 - Celulite Não Especificada",
            "L04 - Linfadenite Aguda",
            "L04.0 - Linfadenite Aguda de Face, Cabeça e Pescoço",
            "L04.1 - Linfadenite Aguda do Tronco",
            "L04.2 - Linfadenite Aguda de Membro Superior",
            "L04.3 - Linfadenite Aguda de Membro Inferior",
            "L04.8 - Linfadenite Aguda de Outras Localizações",
            "L04.9 - Linfadenite Aguda de Localização Não Especificada",
            "L05 - Cisto Pilonidal",
            "L05.0 - Cisto Pilonidal Com Abscesso",
            "L05.9 - Cisto Pilonidal Sem Abscesso",
            "L08 - Outras Infecções Localizadas da Pele e do Tecido Subcutâneo",
            "L08.0 - Piodermite",
            "L08.1 - Eritrasma",
            "L08.8 - Outras Infecções Localizadas, Especificadas, da Pele e do Tecido Subcutâneo",
            "L08.9 - Infecção Localizada da Pele e do Tecido Subcutâneo, Não Especificada",
            "L10 - Pênfigo",
            "L10.0 - Pênfigo Vulgar",
            "L10.1 - Pênfigo Vegetante",
            "L10.2 - Pênfigo Foliáceo",
            "L10.3 - Pênfigo Brasileiro (fogo Selvagem)",
            "L10.4 - Pênfigo Eritematoso",
            "L10.5 - Pênfigo Induzido Por Drogas",
            "L10.8 - Outras Formas de Pênfigo",
            "L10.9 - Pênfigo, Não Especificado",
            "L11 - Outras Afecções Acantolíticas",
            "L11.0 - Ceratose Folicular Adquirida",
            "L11.1 - Dermatose Acantolítica Transitória (Grover)",
            "L11.8 - Outras Afecções Acantolíticas Especificadas",
            "L11.9 - Afecções Acantolíticas Não Especificadas",
            "L12 - Penfigóide",
            "L12.0 - Penfigóide Bolhoso",
            "L12.1 - Penfigóide Cicatricial",
            "L12.2 - Doença Bolhosa Crônica da Infância",
            "L12.3 - Epidermólise Bolhosa Adquirida",
            "L12.8 - Outras Formas de Penfigóide",
            "L12.9 - Penfigóide, Não Especificado",
            "L13 - Outras Afecções Bolhosas",
            "L13.0 - Dermatite Herpetiforme",
            "L13.1 - Dermatite Pustular Subcorneana",
            "L13.8 - Outras Afecções Bolhosas Especificadas",
            "L13.9 - Doença Bolhosa, Não Especificada",
            "L14 - Afecções Bolhosas em Doenças Classificadas em Outra Parte",
            "L20 - Dermatite Atópica",
            "L20.0 - Prurigo de Besnier",
            "L20.8 - Outras Dermatites Atópicas",
            "L20.9 - Dermatite Atópica, Não Especificada",
            "L21 - Dermatite Seborréica",
            "L21.0 - Seborréia do Couro Cabeludo",
            "L21.1 - Dermatite Seborréica Infantil",
            "L21.8 - Outras Dermatites Seborréicas",
            "L21.9 - Dermatite Seborréica, Não Especificada",
            "L22 - Dermatite Das Fraldas",
            "L23 - Dermatites Alérgicas de Contato",
            "L23.0 - Dermatite Alérgica de Contato Devida a Metais",
            "L23.1 - Dermatite Alérgica de Contato Devida a Adesivos",
            "L23.2 - Dermatite Alérgica de Contato Devida a Cosméticos",
            "L23.3 - Dermatite Alérgica de Contato Devido a Drogas em Contato Com a Pele",
            "L23.4 - Dermatite Alérgica de Contato Devida a Corantes",
            "L23.5 - Dermatite Alérgica de Contato Devida a Outros Produtos Químicos",
            "L23.6 - Dermatite Alérgica de Contato Devida a Alimentos em Contato Com a Pele",
            "L23.7 - Dermatite Alérgica de Contato Devido a Plantas, Exceto Alimentos",
            "L23.8 - Dermatite Alérgica de Contato Devido a Outros Agentes",
            "L23.9 - Dermatite Alérgica de Contato, de Causa Não Especificada",
            "L24 - Dermatites de Contato Por Irritantes",
            "L24.0 - Dermatite de Contato Por Irritantes Devida a Detergentes",
            "L24.1 - Dermatite de Contato Por Irritantes Devido a Óleos e Gorduras",
            "L24.2 - Dermatite de Contato Por Irritantes Devida a Solventes",
            "L24.3 - Dermatite de Contato Por Irritantes Devida a Cosméticos",
            "L24.4 - Dermatite de Contato Por Irritantes Devida a Drogas em Contato Com a Pele",
            "L24.5 - Dermatite de Contato Por Irritantes Devida a Outros Produtos Químicos",
            "L24.6 - Dermatite de Contato Por Irritantes Devida a Alimentos em Contato Com a Pele",
            "L24.7 - Dermatite de Contato Por Irritantes Devido a Plantas, Exceto Alimentos",
            "L24.8 - Dermatite de Contato Por Irritantes Devido a Outros Agentes",
            "L24.9 - Dermatite de Contato Por Irritantes, de Causa Não Especificada",
            "L25 - Dermatite de Contato Não Especificada",
            "L25.0 - Dermatite de Contato Não Especificada Devida a Cosméticos",
            "L25.1 - Dermatite de Contato Não Especificada Devida a Drogas em Contato Com a Pele",
            "L25.2 - Dermatite de Contato Não Especificada Devida a Corantes",
            "L25.3 - Dermatite de Contato Não Especificada Devida a Outros Produtos Químicos",
            "L25.4 - Dermatite de Contato Não Especificada Devida a Alimentos em Contato Com a Pele",
            "L25.5 - Dermatite de Contato Não Especificada Devida a Plantas, Exceto Alimentos",
            "L25.8 - Dermatite de Contato Não Especificada Devida a Outros Agentes",
            "L25.9 - Dermatite de Contato Não Especificada, de Causa Não Especificada",
            "L26 - Dermatite Esfoliativa",
            "L27 - Dermatite Devida a Substâncias de Uso Interno",
            "L27.0 - Erupção Cutânea Generalizada Devida a Drogas e Medicamentos",
            "L27.1 - Erupção Cutânea Localizada Devida a Drogas e Medicamentos",
            "L27.2 - Dermatite Devida à Ingestão de Alimentos",
            "L27.8 - Dermatite Devida a Outras Substâncias de Uso Interno",
            "L27.9 - Dermatite Devida a Substância Não Especificada de Uso Interno",
            "L28 - Líquen Simples Crônico e Prurigo",
            "L28.0 - Líquen Simples Crônico",
            "L28.1 - Prurigo Nodular",
            "L28.2 - Outras Formas de Prurigo",
            "L29 - Prurido",
            "L29.0 - Prurido Anal",
            "L29.1 - Prurido Escrotal",
            "L29.2 - Prurido Vulvar",
            "L29.3 - Prurido Anogenital, Não Especificado",
            "L29.8 - Outras Formas de Prurido",
            "L29.9 - Prurido Não Especificado",
            "L30 - Outras Dermatites",
            "L30.0 - Dermatite Numular",
            "L30.1 - Disidrose (pompholyx)",
            "L30.2 - Autossensibilização Cutânea",
            "L30.3 - Dermatite Infectada",
            "L30.4 - Intertrigo Eritematoso",
            "L30.5 - Pitiríase Alba",
            "L30.8 - Outras Dermatites Especificadas",
            "L30.9 - Dermatite Não Especificada",
            "L40 - Psoríase",
            "L40.0 - Psoríase Vulgar",
            "L40.1 - Psoríase Pustulosa Generalizada",
            "L40.2 - Acrodermatite Contínua",
            "L40.3 - Pustulose Palmar e Plantar",
            "L40.4 - Psoríase Gutata",
            "L40.5 - Artropatia Psoriásica",
            "L40.8 - Outras Formas de Psoríase",
            "L40.9 - Psoríase Não Especificada",
            "L41 - Parapsoríase",
            "L41.0 - Pitiríase Liquenóide e Varioliforme Aguda",
            "L41.1 - Pitiríase Liquenóide Crônica",
            "L41.2 - Papulose Linfomatóide",
            "L41.3 - Parapsoríase de Pequenas Placas",
            "L41.4 - Parapsoríase de Grandes Placas",
            "L41.5 - Parapsoríase Retiforme",
            "L41.8 - Outras Parapsoríases",
            "L41.9 - Parapsoríase, Não Especificada",
            "L42 - Pitiríase Rósea",
            "L43 - Líquen Plano",
            "L43.0 - Líquen Plano Hipertrófico",
            "L43.1 - Líquen Plano Bolhoso",
            "L43.2 - Reação Liquenóide a Droga",
            "L43.3 - Líquen Plano Subagudo (ativo)",
            "L43.8 - Outras Formas de Líquen Plano",
            "L43.9 - Líquen Plano, Não Especificado",
            "L44 - Outras Afecções Pápulo-descamativas",
            "L44.0 - Pitiríase Rubra Pilar",
            "L44.1 - Líquen Nítido",
            "L44.2 - Líquen Estriado",
            "L44.3 - Líquen Rubro Moniliforme",
            "L44.4 - Acrodermatite Papular Infantil (Síndrome de Gianotti-Crosti)",
            "L44.8 - Outras Afecções Pápulo-descamativas Especificadas",
            "L44.9 - Afecções Pápulo-descamativas, Não Especificadas",
            "L45 - Afecções Pápulo-descamativas em Doenças Classificadas em Outra Parte",
            "L50 - Urticária",
            "L50.0 - Urticária Alérgica",
            "L50.1 - Urticária Idiopática",
            "L50.2 - Urticária Devida a Frio e a Calor",
            "L50.3 - Urticária Dermatográfica",
            "L50.4 - Urticária Vibratória",
            "L50.5 - Urticária Colinérgica",
            "L50.6 - Urticária de Contato",
            "L50.8 - Outras Urticárias",
            "L50.9 - Urticária Não Especificada",
            "L51 - Eritema Polimorfo (eritema Multiforme)",
            "L51.0 - Eritema Multiforme Não Bolhoso",
            "L51.1 - Eritema Multiforme Bolhoso",
            "L51.2 - Necrólise Epidérmica Tóxica (Síndrome de Lyell)",
            "L51.8 - Outras Formas de Eritema Multiforme",
            "L51.9 - Eritema Multiforme, Não Especificado",
            "L52 - Eritema Nodoso",
            "L53 - Outras Afecções Eritematosas",
            "L53.0 - Eritema Tóxico",
            "L53.1 - Eritema Anular Centrífugo",
            "L53.2 - Eritema Marginado",
            "L53.3 - Outras Formas de Eritema Figurado Crônico",
            "L53.8 - Outras Afecções Eritematosas Especificadas",
            "L53.9 - Afecções Eritematosas, Não Especificadas",
            "L54 - Eritema em Doenças Classificadas em Outra Parte",
            "L54.0 - Eritema Marginado na Febre Reumática Aguda",
            "L54.8 - Eritema em Outras Doenças Classificadas em Outra Parte",
            "L55 - Queimadura Solar",
            "L55.0 - Queimadura Solar de Primeiro Grau",
            "L55.1 - Queimadura Solar de Segundo Grau",
            "L55.2 - Queimadura Solar de Terceiro Grau",
            "L55.8 - Outras Queimaduras Solares",
            "L55.9 - Queimadura Solar, Não Especificada",
            "L56 - Outras Alterações Agudas da Pele Devidas a Radiação Ultravioleta",
            "L56.0 - Resposta Fototóxica a Drogas",
            "L56.1 - Resposta Fotoalérgica a Drogas",
            "L56.2 - Dermatite Por Fotocontato (dermatite do Berloque)",
            "L56.3 - Urticária Solar",
            "L56.4 - Erupção Polimorfa à Luz",
            "L56.8 - Outras Alterações Agudas Especificadas da Pele Devidas a Radiação Ultravioleta",
            "L56.9 - Alterações Agudas da Pele Devidas a Radiação Ultravioleta, Não Especificadas",
            "L57 - Alterações da Pele Devidas à Exposição Crônica à Radiação Não Ionizante",
            "L57.0 - Ceratose Actínica",
            "L57.1 - Reticulóide Actínica",
            "L57.2 - Cútis Romboidal da Nuca",
            "L57.3 - Poiquilodermia de Civatte",
            "L57.4 - Cútis Laxa Senil",
            "L57.5 - Granuloma Actínico",
            "L57.8 - Outras Alterações da Pele Devidas a Exposição Crônica à Radiação Não-ionizante",
            "L57.9 - Alterações da Pele Devidas a Exposição Crônica a Radiação Não-ionizante, Não Especificadas",
            "L58 - Radiodermatite",
            "L58.0 - Radiodermatite Aguda",
            "L58.1 - Radiodermatite Crônica",
            "L58.9 - Radiodermatite, Não Especificada",
            "L59 - Outras Afecções da Pele e do Tecido Subcutâneo Relacionadas Com a Radiação",
            "L59.0 - Eritema Devido ao Calor ou ao Fogo (dermatite ab Igne)",
            "L59.8 - Outras Afecções Especificadas da Pele e do Tecido Subcutâneo Relacionadas Com a Radiação",
            "L59.9 - Afecções da Pele e do Tecido Subcutâneo Relacionadas Com a Radiação, Não Especificadas",
            "L60 - Afecções Das Unhas",
            "L60.0 - Unha Encravada",
            "L60.1 - Onicólise",
            "L60.2 - Onicogrifose",
            "L60.3 - Distrofia Ungueal",
            "L60.4 - Linhas de Beau",
            "L60.5 - Síndrome Das Unhas Amareladas",
            "L60.8 - Outras Afecções Das Unhas",
            "L60.9 - Afecções Das Unhas, Não Especificadas",
            "L62 - Afecções Das Unhas em Doenças Classificadas em Outra Parte",
            "L62.0 - Paquidermoperiostose Com Unhas Hipocráticas (em Vidro de Relógio)",
            "L62.8 - Afecções Das Unhas em Outras Doenças Classificadas em Outra Parte",
            "L63 - Alopécia Areata",
            "L63.0 - Alopécia Total (capitis)",
            "L63.1 - Alopécia Universal",
            "L63.2 - Ofíase",
            "L63.8 - Outras Formas de Alopécia Areata",
            "L63.9 - Alopécia Areata, Não Especificada",
            "L64 - Alopécia Androgênica",
            "L64.0 - Alopécia Androgênica Induzida Por Droga",
            "L64.8 - Outras Alopécias Androgênicas",
            "L64.9 - Alopécia Androgênica, Não Especificada",
            "L65 - Outras Formas Não Cicatriciais da Perda de Cabelos ou Pêlos",
            "L65.0 - Eflúvio Telógeno",
            "L65.1 - Eflúvio Anágeno",
            "L65.2 - Alopécia Mucinosa",
            "L65.8 - Outras Formas, Especificadas, Não Cicatriciais, de Perda de Cabelos ou Pêlos",
            "L65.9 - Perda de Cabelos ou Pêlos Não Cicatriciais, Não Especificada",
            "L66 - Alopécia Cicatricial (perda de Cabelos ou Pêlos, Cicatricial)",
            "L66.0 - Pseudopelada",
            "L66.1 - Líquen Planopilar",
            "L66.2 - Foliculite Descalvante",
            "L66.3 - Perifoliculite Abscedante da Cabeça",
            "L66.4 - Foliculite Uleritematosa Reticulada",
            "L66.8 - Outras Formas de Alopécia Cicatricial",
            "L66.9 - Alopécia Cicatricial, Não Especificada",
            "L67 - Anormalidades da Cor e do Pedículo Dos Cabelos e Dos Pêlos",
            "L67.0 - Tricorrexe Nodosa",
            "L67.1 - Variações da Cor Dos Cabelos ou Pêlos",
            "L67.8 - Outras Anormalidades da Cor e do Pedículo Dos Cabelos ou Pêlos",
            "L67.9 - Anormalidades da Cor e do Pedículo Dos Cabelos e Dos Pêlos, Não Especificadas",
            "L68 - Hipertricose",
            "L68.0 - Hirsutismo",
            "L68.1 - Hipertricose Lanuginosa Adquirida",
            "L68.2 - Hipertricose Localizada",
            "L68.3 - Politríquia",
            "L68.8 - Outras Formas de Hipertricose",
            "L68.9 - Hipertricose, Não Especificada",
            "L70 - Acne",
            "L70.0 - Acne Vulgar",
            "L70.1 - Acne Conglobata",
            "L70.2 - Acne Varioliforme",
            "L70.3 - Acne Tropical",
            "L70.4 - Acne Infantil",
            "L70.5 - Acne Escoriada Das Mulheres Jovens",
            "L70.8 - Outras Formas de Acne",
            "L70.9 - Acne, Não Especificada",
            "L71 - Rosácea",
            "L71.0 - Dermatite Perioral",
            "L71.1 - Rinofima",
            "L71.8 - Outras Formas de Rosácea",
            "L71.9 - Rosácea, Não Especificada",
            "L72 - Cistos Foliculares da Pele e do Tecido Subcutâneo",
            "L72.0 - Cisto Epidérmico",
            "L72.1 - Cisto Triquilemal",
            "L72.2 - Esteatocistoma Múltiplo",
            "L72.8 - Outras Formas de Cistos Foliculares da Pele e do Tecido Subcutâneo",
            "L72.9 - Cisto Folicular, Não Especificado da Pele e do Tecido Subcutâneo",
            "L73 - Outras Afecções Foliculares",
            "L73.0 - Acne Quelóide",
            "L73.1 - Pseudofoliculite da Barba",
            "L73.2 - Hidradenite Supurativa",
            "L73.8 - Outras Afecções Foliculares Especificadas",
            "L73.9 - Afecções Foliculares, Não Especificadas",
            "L74 - Afecções Das Glândulas Sudoríparas Écrinas",
            "L74.0 - Miliária Rubra",
            "L74.1 - Miliária Cristalina",
            "L74.2 - Miliária Profunda",
            "L74.3 - Miliária, Não Especificada",
            "L74.4 - Anidrose",
            "L74.8 - Outras Afecções Das Glândulas Sudoríparas Écrinas",
            "L74.9 - Afecções Das Glândulas Sudoríparas Écrinas, Não Especificadas",
            "L75 - Afecções Das Glândulas Sudoríparas Apócrinas",
            "L75.0 - Bromidrose",
            "L75.1 - Cromidrose",
            "L75.2 - Miliária Apócrina",
            "L75.8 - Outras Afecções Das Glândulas Sudoríparas Apócrinas",
            "L75.9 - Afecções de Glândulas Sudoríparas Apócrinas, Não Especificadas",
            "L80 - Vitiligo",
            "L81 - Outros Transtornos da Pigmentação",
            "L81.0 - Hiperpigmentação Pós-inflamatória",
            "L81.1 - Cloasma",
            "L81.2 - Sardas",
            "L81.3 - Manchas Café-com-leite",
            "L81.4 - Outras Formas de Hiperpigmentação Pela Melanina",
            "L81.5 - Leucodermia Não Classificada em Outra Parte",
            "L81.6 - Outros Transtornos Ligados à Diminuição da Formação de Melanina",
            "L81.7 - Dermatose Purpúrica Pigmentada",
            "L81.8 - Outros Transtornos Especificados da Pigmentação",
            "L81.9 - Transtorno da Pigmentação, Não Especificado",
            "L82 - Ceratose Seborréica",
            "L83 - Acantose Nigricans",
            "L84 - Calos e Calosidades",
            "L85 - Outras Formas de Espessamento Epidérmico",
            "L85.0 - Ictiose Adquirida",
            "L85.1 - Ceratose Adquirida (ceratodermia) Palmar e Plantar",
            "L85.2 - Ceratose Punctata (palmar e Plantar)",
            "L85.3 - Xerose Cutânea",
            "L85.8 - Outras Formas de Espessamento Epidérmico",
            "L85.9 - Espessamento Epidérmico, Não Especificado",
            "L86 - Ceratodermia em Doenças Classificadas em Outra Parte",
            "L87 - Transtornos da Eliminação Transepidérmica",
            "L87.0 - Ceratose Folicular e Parafolicular Penetrante na Pele (Síndrome de Kyrle)",
            "L87.1 - Colagenose Perfurante Reativa",
            "L87.2 - Elastose Perfurante Serpiginosa",
            "L87.8 - Outros Transtornos da Eliminação Transepidérmica",
            "L87.9 - Transtorno da Eliminação Transepidérmica, Não Especificado",
            "L88 - Piodermite Gangrenosa",
            "L89 - Úlcera de Decúbito",
            "L90 - Afecções Atróficas da Pele",
            "L90.0 - Líquen Escleroso e Atrófico",
            "L90.1 - Anetodermia de Schweninger-Buzzi",
            "L90.2 - Anetodermia de Jadassohn-Pellizzari",
            "L90.3 - Atrofodermia de Pasini e Pierini",
            "L90.4 - Acrodermatite Crônica Atrófica",
            "L90.5 - Cicatrizes e Fibrose Cutânea",
            "L90.6 - Estrias Atróficas",
            "L90.8 - Outras Afecções Atróficas da Pele",
            "L90.9 - Afecções Atróficas da Pele, Não Especificadas",
            "L91 - Afecções Hipertróficas da Pele",
            "L91.0 - Cicatriz Quelóide",
            "L91.8 - Outras Afecções Hipertróficas da Pele",
            "L91.9 - Afecções Hipertróficas da Pele, Não Especificadas",
            "L92 - Afecções Granulomatosas da Pele e do Tecido Subcutâneo",
            "L92.0 - Granuloma Anular",
            "L92.1 - Necrobiose Lipoídica Não Classificada em Outra Parte",
            "L92.2 - Granuloma Facial (granuloma Eosinófilo da Pele)",
            "L92.3 - Granuloma de Corpo Estranho da Pele e do Tecido Subcutâneo",
            "L92.8 - Outras Afecções Granulomatosas da Pele e do Tecido Subcutâneo",
            "L92.9 - Afecções Granulomatosas da Pele e do Tecido Subcutâneo, Não Especificados",
            "L93 - Lúpus Eritematoso",
            "L93.0 - Lúpus Eritematoso Discóide",
            "L93.1 - Lúpus Eritematoso Cutâneo Subagudo",
            "L93.2 - Outras Formas de Lúpus Eritematoso Localizado",
            "L94 - Outras Afecções Localizadas do Tecido Conjuntivo",
            "L94.0 - Esclerodermia Localizada (morféia)",
            "L94.1 - Esclerodermia Linear",
            "L94.2 - Calcinose Cutânea",
            "L94.3 - Esclerodactilia",
            "L94.4 - Pápulas de Gottron",
            "L94.5 - Poiquilodermia Vascular Atrófica",
            "L94.6 - Ainhum",
            "L94.8 - Outras Afecções Localizadas do Tecido Conjuntivo, Especificadas",
            "L94.9 - Afecções Localizadas do Tecido Conjuntivo, Não Especificadas",
            "L95 - Vasculite Limitada a Pele Não Classificadas em Outra Parte",
            "L95.0 - Vasculite Livedóide",
            "L95.1 - Eritema Elevado Diutino (duradouro)",
            "L95.8 - Outras Vasculites Limitadas a Pele",
            "L95.9 - Vasculites Limitadas a Pele, Não Especificadas",
            "L97 - Úlcera Dos Membros Inferiores Não Classificada em Outra Parte",
            "L98 - Outras Afecções da Pele e do Tecido Subcutâneo Não Classificadas em Outra Parte",
            "L98.0 - Granuloma Piogênico",
            "L98.1 - Dermatite Factícia",
            "L98.2 - Dermatose Neutrofílica Febril (Sweet)",
            "L98.3 - Celulite Eosinofílica (Wells)",
            "L98.4 - Úlcera Crônica da Pele, Não Classificada em Outra Parte",
            "L98.5 - Mucinose da Pele",
            "L98.6 - Outras Afecções Infiltrativas da Pele e do Tecido Subcutâneo",
            "L98.8 - Outras Afecções Especificadas da Pele e do Tecido Subcutâneo",
            "L98.9 - Afecções da Pele e do Tecido Subcutâneo, Não Especificados",
            "L99 - Outras Afecções da Pele e do Tecido Subcutâneo em Doenças Classificadas em Outra Parte",
            "L99.0 - Amiloidose Cutânea",
            "L99.8 - Outras Afecções Especificadas da Pele e do Tecido Subcutâneo em Doenças Classificadas em Outra Parte",
            "M00 - Artrite Piogênica",
            "M00.0 - Artrite e Pioliartrite Estafilocócicas",
            "M00.1 - Artrite e Poliartrite Pneumocócicas",
            "M00.2 - Outras Artrites e Poliartrites Estreptocócicas",
            "M00.8 - Artrite e Poliartrite Devidas a Outro Agente Bacteriano Especificado",
            "M00.9 - Artrite Piogênica, Não Especificada",
            "M01 - Infecções Diretas da Articulação em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "M01.0 - Artrite Meningocócica",
            "M01.1 - Artrite Tuberculosa",
            "M01.2 - Artrite na Doença de Lyme",
            "M01.3 - Artrite em Outras Doenças Bacterianas Classificadas em Outra Parte",
            "M01.4 - Artrite na Rubéola",
            "M01.5 - Artrite em Outras Doenças Virais Classificadas em Outra Parte",
            "M01.6 - Artrite em Micoses",
            "M01.8 - Artrite em Outras Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "M02 - Artropatias Reacionais",
            "M02.0 - Artropatia Pós-derivação Intestinal",
            "M02.1 - Artropatia Pós-desintérica",
            "M02.2 - Artropatia Pós-imunização",
            "M02.3 - Doença de Reiter",
            "M02.8 - Outras Artropatias Reacionais",
            "M02.9 - Artropatia Reacional Não Especificada",
            "M03 - Artropatias Pós-infecciosas e Reacionais em Doenças Infecciosas Classificadas em Outra Parte",
            "M03.0 - Artrite Pós-meningocócica",
            "M03.1 - Artropatia Pós-infecciosa na Sífilis",
            "M03.2 - Outras Artropatias Pós-infecciosas em Doenças Classificadas em Outra Parte",
            "M03.6 - Artropatia Reacional em Outras Doenças Classificadas em Outra Parte",
            "M05 - Artrite Reumatóide Soro-positiva",
            "M05.0 - Síndrome de Felty",
            "M05.1 - Doença Reumatóide do Pulmão",
            "M05.2 - Vasculite Reumatóide",
            "M05.3 - Artrite Reumatóide Com Comprometimento de Outros Órgãos e Sistemas",
            "M05.8 - Outras Artrites Reumatóides Soro-positivas",
            "M05.9 - Artrite Reumatóide Soro-positiva Não Especificada",
            "M06 - Outras Artrites Reumatóides",
            "M06.0 - Artrite Reumatóide Soro-negativa",
            "M06.1 - Doença de Still do Adulto",
            "M06.2 - Bursite Reumatóide",
            "M06.3 - Nódulo Reumatóide",
            "M06.4 - Poliartropatia Inflamatória",
            "M06.8 - Outras Artrites Reumatóides Especificadas",
            "M06.9 - Artrite Reumatóide Não Especificada",
            "M07 - Artropatias Psoriásicas e Enteropáticas",
            "M07.0 - Artropatia Psoriásica Interfalangiana Distal",
            "M07.1 - Artrite Mutilante",
            "M07.2 - Espondilite Psoriásica",
            "M07.3 - Outras Artropatias Psoriásicas",
            "M07.4 - Artropatia na Doença de Crohn (enterite Regional)",
            "M07.5 - Artropatia na Colite Ulcerativa",
            "M07.6 - Outras Artropatias Enteropáticas",
            "M08 - Artrite Juvenil",
            "M08.0 - Artrite Reumatóide Juvenil",
            "M08.1 - Espondilite Ancilosante Juvenil",
            "M08.2 - Artrite Juvenil Com Início Sistêmico",
            "M08.3 - Poliartrite Juvenil (soro-negativa)",
            "M08.4 - Artrite Juvenil Pauciarticular",
            "M08.8 - Outras Artrites Juvenis",
            "M08.9 - Artrite Juvenil Não Especificada",
            "M09 - Artrite Juvenil em Doenças Classificadas em Outra Parte",
            "M09.0 - Artrite Juvenil na Psoríase",
            "M09.1 - Artrite Juvenil na Doença de Chron (enterite Regional)",
            "M09.2 - Artrite Juvenil na Colite Ulcerativa",
            "M09.8 - Artrite Juvenil em Outras Doenças Classificadas em Outra Parte",
            "M10 - Gota",
            "M10.0 - Gota Idiopática",
            "M10.1 - Gota Induzida Por Chumbo",
            "M10.2 - Gota Induzida Por Drogas",
            "M10.3 - Gota Devida à Disfunção Renal",
            "M10.4 - Outra Gota Secundária",
            "M10.9 - Gota, Não Especificada",
            "M11 - Outras Artropatias Por Deposição de Cristais",
            "M11.0 - Doença Por Deposição de Hidroxiapatita",
            "M11.1 - Condrocalcinose Familiar",
            "M11.2 - Outras Condrocalcinoses",
            "M11.8 - Outras Artropatias Especificadas Por Deposição de Cristais",
            "M11.9 - Artropatia Por Deposição de Cristais, Não Especificada",
            "M12 - Outras Artropatias Especificadas",
            "M12.0 - Artropatia Pós-reumática Crônica (Jaccoud)",
            "M12.1 - Doença de Kashin-Beck",
            "M12.2 - Sinovite Vilonodular (pigmentada)",
            "M12.3 - Reumatismo Palindrômico",
            "M12.4 - Hidrartrose Intermitente",
            "M12.5 - Artropatia Traumática",
            "M12.8 - Outras Artropatias Específicas Não Classificadas em Outra Parte",
            "M13 - Outras Artrites",
            "M13.0 - Poliartrite Não Especificada",
            "M13.1 - Monoartrites Não Classificadas em Outra Parte",
            "M13.8 - Outras Artrites Especificadas",
            "M13.9 - Artrite Não Especificada",
            "M14 - Artropatias em Outras Doenças Classificadas em Outra Parte",
            "M14.0 - Artropatia Gotosa Devida a Defeitos Enzimáticos e a Outras Doenças Hereditárias",
            "M14.1 - Artropatia Por Depósito de Cristais em Outras Doenças Metabólicas Classificadas em Outra Parte",
            "M14.2 - Artropatia Diabética",
            "M14.3 - Dermatoartrite Lipóide",
            "M14.4 - Artropatia na Amiloidose",
            "M14.5 - Artropatias em Outras Doenças Endócrinas, Nutricionais e Metabólicas",
            "M14.6 - Artropatia Neuropática",
            "M14.8 - Artropatias em Outras Doenças Especificadas Classificadas em Outra Parte",
            "M15 - Poliartrose",
            "M15.0 - (Osteo)artrose Primária Generalizada",
            "M15.1 - Nódulos de Heberden (com Artropatia)",
            "M15.2 - Nódulos de Bouchard (com Artropatia)",
            "M15.3 - Artrose Múltipla Secundária",
            "M15.4 - (Osteo)artrose Erosiva",
            "M15.8 - Outras Poliartroses",
            "M15.9 - Poliartrose Não Especificada",
            "M16 - Coxartrose (artrose do Quadril)",
            "M16.0 - Coxartrose Primária Bilateral",
            "M16.1 - Outras Coxartroses Primárias",
            "M16.2 - Coxartrose Bilateral Resultante de Displasia",
            "M16.3 - Outras Coxartroses Displásicas",
            "M16.4 - Coxartrose Bilateral Pós-traumática",
            "M16.5 - Outras Coxartroses Pós-traumáticas",
            "M16.6 - Outras Coxartroses Secundárias Bilaterais",
            "M16.7 - Outras Coxartroses Secundárias",
            "M16.9 - Coxartrose Não Especificada",
            "M17 - Gonartrose (artrose do Joelho)",
            "M17.0 - Gonartrose Primária Bilateral",
            "M17.1 - Outras Gonartroses Primárias",
            "M17.2 - Gonartrose Pós-traumática Bilateral",
            "M17.3 - Outras Gonartroses Pós-traumática",
            "M17.4 - Outras Gonartroses Secundárias Bilaterais",
            "M17.5 - Outras Gonartroses Secundárias",
            "M17.9 - Gonartrose Não Especificada",
            "M18 - Artrose da Primeira Articulação Carpometacarpiana",
            "M18.0 - Artrose Primária Bilateral Das Primeiras Articulações Carpometacarpianas",
            "M18.1 - Outras Artroses Primárias da Primeira Articulação Carpometacarpiana",
            "M18.2 - Artrose Pós-traumática Bilateral da Primeira Articulação Carpometacarpiana",
            "M18.3 - Outras Artroses Pós-traumáticas da Primeira Articulação Carpometacarpiana",
            "M18.4 - Outras Cartroses Secundárias Bilaterais Das Primeiras Articulações Carpometacarpianas",
            "M18.5 - Outras Artroses Secundárias da Primeira Articulação Carpometacarpiana",
            "M18.9 - Artrose Não Especificada da Primeira Articulação Carpometacarpiana",
            "M19 - Outras Artroses",
            "M19.0 - Artrose Primária de Outras Articulações",
            "M19.1 - Artrose Pós-traumática de Outras Articulações",
            "M19.2 - Outras Artroses Secundárias",
            "M19.8 - Outras Artroses Especificadas",
            "M19.9 - Artrose Não Especificada",
            "M20 - Deformidades Adquiridas Dos Dedos Das Mãos e Dos Pés",
            "M20.0 - Deformidade(s) Do(s) Dedo(s) Das Mãos",
            "M20.1 - Hallux Valgo (adquirido)",
            "M20.2 - Hallux Rigidus",
            "M20.3 - Outra Deformidade do Hallux (adquirida)",
            "M20.4 - Dedo(s) do pé em Malho (adquirido)",
            "M20.5 - Outras Deformidades (adquiridas) Do(s) Dedo(s) Dos Pés",
            "M20.6 - Deformidade Adquirida Não Especificada de Dedo(s) do pé",
            "M21 - Outras Deformidades Adquiridas Dos Membros",
            "M21.0 - Deformidade em Valgo Não Classificada em Outra Parte",
            "M21.1 - Deformidade em Varo Não Classificada em Outra Parte",
            "M21.2 - Deformidade em Flexão",
            "M21.3 - Mão (pulso) ou pé Pendente (adquirido)",
            "M21.4 - Pé Chato (pé Plano) (adquirido)",
            "M21.5 - Mão e pé em Garra e Mão e pé Tortos Adquiridos",
            "M21.6 - Outras Deformidades Adquiridas do Tornozelo e do pé",
            "M21.7 - Desigualdade (adquirida) do Comprimento Dos Membros",
            "M21.8 - Outras Deformidades Adquiridas Especificadas Dos Membros",
            "M21.9 - Deformidade Adquirida Não Especificada de Membro",
            "M22 - Transtornos da Rótula (patela)",
            "M22.0 - Deslocamento Recidivante da Rótula",
            "M22.1 - Subluxação Recidivante da Rótula",
            "M22.2 - Transtornos Femuropatelares",
            "M22.3 - Outros Desarranjos da Rótula",
            "M22.4 - Condromalácia da Rótula",
            "M22.8 - Outros Transtornos da Rótula",
            "M22.9 - Transtorno da Rótula, Não Especificado",
            "M23 - Transtornos Internos Dos Joelhos",
            "M23.0 - Menisco Cístico",
            "M23.1 - Menisco Discóide (congênito)",
            "M23.2 - Transtorno do Menisco Devido à Ruptura ou Lesão Antiga",
            "M23.3 - Outros Transtornos do Menisco",
            "M23.4 - Corpo Flutuante no Joelho",
            "M23.5 - Instabilidade Crônica do Joelho",
            "M23.6 - Outras Rupturas Espontâneas de Ligamento(s) do Joelho",
            "M23.8 - Outros Transtornos Internos do Joelho",
            "M23.9 - Transtorno Interno Não Especificado do Joelho",
            "M24 - Outros Transtornos Articulares Específicos",
            "M24.0 - Corpo Flutuante em Articulação",
            "M24.1 - Outros Transtornos Das Cartilagens Articulares",
            "M24.2 - Transtornos de Ligamentos",
            "M24.3 - Deslocamento e Subluxação Patológicas de Articulação, Não Classificada em Outra Parte",
            "M24.4 - Deslocamento e Subluxação de Articulação Recidivantes",
            "M24.5 - Contratura Articular",
            "M24.6 - Ancilose Articular",
            "M24.7 - Protusão do Acetábulo",
            "M24.8 - Outros Transtornos Articulares Específicos, Não Classificados em Outra Parte",
            "M24.9 - Desarranjo Articular Não Especificado",
            "M25 - Outros Transtornos Articulares Não Classificados em Outra Parte",
            "M25.0 - Hemartrose",
            "M25.1 - Fístula Articular",
            "M25.2 - Flail Joint",
            "M25.3 - Outras Instabilidades Articulares",
            "M25.4 - Derrame Articular",
            "M25.5 - Dor Articular",
            "M25.6 - Rigidez Articular Não Classificada em Outra Parte",
            "M25.7 - Osteofito",
            "M25.8 - Outros Transtornos Articulares Especificados",
            "M25.9 - Transtorno Articular Não Especificado",
            "M30 - Poliarterite Nodosa e Afecções Correlatas",
            "M30.0 - Poliarterite Nodosa",
            "M30.1 - Poliarterite Com Comprometimento Pulmonar (Churg-Strauss)",
            "M30.2 - Poliarterite Juvenil",
            "M30.3 - Síndrome de Linfonodos Mucocutâneos (Kawasaki)",
            "M30.8 - Outras Afecções Comuns na Poliarterite Nodosa",
            "M31 - Outras Vasculopatias Necrotizantes",
            "M31.0 - Angeíte de Hipersensibilidade",
            "M31.1 - Microangiopatia Trombótica",
            "M31.2 - Granuloma da Linha Média Letal",
            "M31.3 - Granulomatose de Wegener",
            "M31.4 - Síndrome do Arco Aórtico (Takayasu)",
            "M31.5 - Arterite de Células Gigantes Com Polimialgia Reumática",
            "M31.6 - Outras Arterites de Células Gigantes",
            "M31.7 - Poliangeíte Microscópica",
            "M31.8 - Outras Vasculopatias Necrotizantes Especificadas",
            "M31.9 - Vasculopatia Necrotizante Não Especificada",
            "M32 - Lúpus Eritematoso Disseminado (sistêmico)",
            "M32.0 - Lúpus Eritematoso Disseminado (sistêmico) Induzido Por Drogas",
            "M32.1 - Lúpus Eritematoso Disseminado (sistêmico) Com Comprometimento de Outros Órgãos e Sistemas",
            "M32.8 - Outras Formas de Lúpus Eritematoso Disseminado (sistêmico)",
            "M32.9 - Lúpus Eritematoso Disseminado (sistêmico) Não Especificado",
            "M33 - Dermatopoliomiosite",
            "M33.0 - Dermatomiosite Juvenil",
            "M33.1 - Outras Dermatomiosites",
            "M33.2 - Polimiosite",
            "M33.9 - Dermatopolimiosite Não Especificada",
            "M34 - Esclerose Sistêmica",
            "M34.0 - Esclerose Sistêmica Progressiva",
            "M34.1 - Síndrome CR(E)ST",
            "M34.2 - Esclerose Sistêmica Induzida Por Droga e Substâncias Químicas",
            "M34.8 - Outras Formas de Esclerose Sistêmica",
            "M34.9 - Esclerose Sistêmica Não Especificada",
            "M35 - Outras Afecções Sistêmicas do Tecido Conjuntivo",
            "M35.0 - Síndrome Seca (Sjögren)",
            "M35.1 - Outras Síndromes Superpostas",
            "M35.2 - Doença de Behçet",
            "M35.3 - Polimialgia Reumática",
            "M35.4 - Fasciíte (eosinofílica) Difusa",
            "M35.5 - Fibroesclerose Multifocal",
            "M35.6 - Paniculite Recidivante (Weber-Christian)",
            "M35.7 - Síndrome de Hipermobilidade",
            "M35.8 - Outro Comprometimento Sistêmico Especificado do Tecido Conjuntivo",
            "M35.9 - Comprometimento Sistêmico Não Especificado do Tecido Conjuntivo",
            "M36 - Doenças Sistêmicas do Tecido Conjuntivo em Doenças Classificadas em Outra Parte",
            "M36.0 - Dermato(poli)miosite em Doenças Neoplásicas",
            "M36.1 - Artropatia em Doenças Neoplásicas Classificadas",
            "M36.2 - Artropatia Hemofílica",
            "M36.3 - Artropatias em Outras Doenças Hematológicas",
            "M36.4 - Artropatia Associada a Reações de Hipersensibilidade Classificadas em Outra Parte",
            "M36.8 - Doenças Sistêmicas do Tecido Conjuntivo em Outras Doenças Classificadas em Outra Parte",
            "M40 - Cifose e Lordose",
            "M40.0 - Cifose Postural",
            "M40.1 - Outras Cifoses Secundárias",
            "M40.2 - Outras Cifoses e as Não Especificadas",
            "M40.3 - Síndrome da Retificação da Coluna Vertebral",
            "M40.4 - Outras Lordoses",
            "M40.5 - Lordose Não Especificada",
            "M41 - Escoliose",
            "M41.0 - Escoliose Idiopática Infantil",
            "M41.1 - Escoliose Idiopática Juvenil",
            "M41.2 - Outras Escolioses Idiopáticas",
            "M41.3 - Escoliose Toracogênica",
            "M41.4 - Escoliose Neuromuscular",
            "M41.5 - Outras Escolioses Secundárias",
            "M41.8 - Outras Formas de Escoliose",
            "M41.9 - Escoliose Não Especificada",
            "M42 - Osteocondrose da Coluna Vertebral",
            "M42.0 - Osteocondrose Vertebral Juvenil",
            "M42.1 - Osteocondrose Vertebral do Adulto",
            "M42.9 - Osteocondrose Vertebral, Não Especificada",
            "M43 - Outras Dorsopatias Deformantes",
            "M43.0 - Espondilólise",
            "M43.1 - Espondilolistese",
            "M43.2 - Outras Fusões da Coluna Vertebral",
            "M43.3 - Subluxação Atlanto-axial Recidivante Com Mielopatia",
            "M43.4 - Outras Subluxações Atlanto-axiais Recidivantes",
            "M43.5 - Outras Subluxações Vertebrais Recidivantes",
            "M43.6 - Torcicolo",
            "M43.8 - Outras Dorsopatias Deformantes Especificadas",
            "M43.9 - Dorsopatia Deformante, Não Especificada",
            "M45 - Espondilite Ancilosante",
            "M46 - Outras Espondilopatias Inflamatórias",
            "M46.0 - Entesopatia Vertebral",
            "M46.1 - Sacroileíte Não Classificada em Outra Parte",
            "M46.2 - Osteomielite Das Vértebras",
            "M46.3 - Infecção (piogênica) do Disco Intervertebral",
            "M46.4 - Discite Não Especificada",
            "M46.5 - Outras Espondilopatias Infecciosas",
            "M46.8 - Outras Espondilopatias Inflamatórias Especificadas",
            "M46.9 - Espondilopatia Inflamatória Não Especificada",
            "M47 - Espondilose",
            "M47.0 - Síndromes de Compressão da Artéria Espinhal Anterior ou Vertebral Anterior",
            "M47.1 - Outras Espondiloses Com Mielopatia",
            "M47.2 - Outras Espondiloses Com Radiculopatias",
            "M47.8 - Outras Espondiloses",
            "M47.9 - Espondilose Não Especificada",
            "M48 - Outras Espondilopatias",
            "M48.0 - Estenose da Coluna Vertebral",
            "M48.1 - Hiperostose Ancilosante (Forestier)",
            "M48.2 - Kissing Spine",
            "M48.3 - Espondilopatia Traumática",
            "M48.4 - Fratura de Fadiga de Vértebra",
            "M48.5 - Vértebra Colapsada Não Classificada em Outra Parte",
            "M48.8 - Outras Espondilopatias Especificadas",
            "M48.9 - Espondilopatia Não Especificada",
            "M49 - Espondilopatias em Doenças Classificadas em Outra Parte",
            "M49.0 - Tuberculose da Coluna Vertebral",
            "M49.1 - Espondilite Por Brucella",
            "M49.2 - Espondilite Por Enterobactérias",
            "M49.3 - Espondilopatia em Outras Doenças Infeciosas e Parasitárias Classificadas em Outra Parte",
            "M49.4 - Espondilopatia Neuropática",
            "M49.5 - Vértebra Colapsada em Doenças Classificadas em Outra Parte",
            "M49.8 - Espondilopatia em Outras Doenças Classificadas em Outra Parte",
            "M50 - Transtornos Dos Discos Cervicais",
            "M50.0 - Transtorno do Disco Cervical Com Mielopatia",
            "M50.1 - Transtorno do Disco Cervical Com Radiculopatia",
            "M50.2 - Outro Deslocamento de Disco Cervical",
            "M50.3 - Outra Degeneração de Disco Cervical",
            "M50.8 - Outros Transtornos de Discos Cervicais",
            "M50.9 - Transtorno Não Especificado de Disco Cervical",
            "M51 - Outros Transtornos de Discos Intervertebrais",
            "M51.0 - Transtornos de Discos Lombares e de Outros Discos Intervertebrais Com Mielopatia",
            "M51.1 - Transtornos de Discos Lombares e de Outros Discos Intervertebrais Com Radiculopatia",
            "M51.2 - Outros Deslocamentos Discais Intervertebrais Especificados",
            "M51.3 - Outra Degeneração Especificada de Disco Intervertebral",
            "M51.4 - Nódulos de Schmorl",
            "M51.8 - Outros Transtornos Especificados de Discos Intervertebrais",
            "M51.9 - Transtorno Não Especificado de Disco Intervertebral",
            "M53 - Outras Dorsopatias Não Classificadas em Outra Parte",
            "M53.0 - Síndrome Cervicocraniana",
            "M53.1 - Síndrome Cervicobraquial",
            "M53.2 - Instabilidades da Coluna Vertebral",
            "M53.3 - Transtornos Sacroccígeos Não Classificados em Outra Parte",
            "M53.8 - Outras Dorsopatias Especificadas",
            "M53.9 - Dorsopatia Não Especificada",
            "M54 - Dorsalgia",
            "M54.0 - Paniculite Atingindo Regiões do Pescoço e do Dorso",
            "M54.1 - Radiculopatia",
            "M54.2 - Cervicalgia",
            "M54.3 - Ciática",
            "M54.4 - Lumbago Com Ciática",
            "M54.5 - Dor Lombar Baixa",
            "M54.6 - Dor na Coluna Torácica",
            "M54.8 - Outra Dorsalgia",
            "M54.9 - Dorsalgia Não Especificada",
            "M60 - Miosite",
            "M60.0 - Miosite Infecciosa",
            "M60.1 - Miosite Intersticial",
            "M60.2 - Granuloma de Corpo Estranho no Tecido Mole Não Classificado em Outra Parte",
            "M60.8 - Outras Miosites",
            "M60.9 - Miosite Não Especificada",
            "M61 - Calcificação e Ossificação do Músculo",
            "M61.0 - Miosite Ossificante Traumática",
            "M61.1 - Miosite Ossificante Progressiva",
            "M61.2 - Calcificação e Ossificação Paralítica de Músculo",
            "M61.3 - Calcificação e Ossificação de Músculo Associadas Com Queimaduras",
            "M61.4 - Outra Calcificação de Músculo",
            "M61.5 - Outra Ossificação de Músculo",
            "M61.9 - Calcificação e Ossificação de Músculo Não Especificada",
            "M62 - Outros Transtornos Musculares",
            "M62.0 - Diástase de Músculo",
            "M62.1 - Outras Rupturas Musculares (não-traumáticas)",
            "M62.2 - Infarto Isquêmico do Músculo",
            "M62.3 - Síndrome de Imobilidade (paraplégica)",
            "M62.4 - Contratura de Músculo",
            "M62.5 - Perda e Atrofia Muscular Não Classificadas em Outra Parte",
            "M62.6 - Distensão Muscular",
            "M62.8 - Outros Transtornos Musculares Especificados",
            "M62.9 - Transtorno Muscular Não Especificado",
            "M63 - Transtornos de Músculo em Doenças Classificadas em Outra Parte",
            "M63.0 - Miosite em Doenças Bacterianas Classificadas em Outra Parte",
            "M63.1 - Miosite em Doenças Infecciosas Causadas Por Protozoários e Parasitas Classificadas em Outra Parte",
            "M63.2 - Miosite em Outras Doenças Infecciosas Classificadas em Outra Parte",
            "M63.3 - Miosite na Sarcoidose",
            "M63.8 - Outros Transtornos Musculares em Doenças Classificadas em Outra Parte",
            "M65 - Sinovite e Tenossinovite",
            "M65.0 - Abscesso da Bainha Tendínea",
            "M65.1 - Outras (teno)sinovites Infecciosas",
            "M65.2 - Tendinite Calcificada",
            "M65.3 - Dedo em Gatilho",
            "M65.4 - Tenossinovite Estilóide Radial (de Quervain)",
            "M65.8 - Outras Sinovites e Tenossinovites",
            "M65.9 - Sinovite e Tenossinovite Não Especificadas",
            "M66 - Ruptura Espontânea de Sinóvia e de Tendão",
            "M66.0 - Ruptura de Cisto Poplíteo",
            "M66.1 - Ruptura de Sinóvia",
            "M66.2 - Ruptura Espontânea de Tendões Extensores",
            "M66.3 - Ruptura Espontânea de Tendões Flexores",
            "M66.4 - Ruptura Espontânea de Outros Tendões",
            "M66.5 - Ruptura Espontânea de Tendões Não Especificados",
            "M67 - Outros Transtornos Das Sinóvias e Dos Tendões",
            "M67.0 - Tendão de Aquiles Curto (adquirido)",
            "M67.1 - Outra Contratura de Tendão (bainha)",
            "M67.2 - Hipertrofia Sinovial Não Classificada em Outra Parte",
            "M67.3 - Sinovite Transitória",
            "M67.4 - Gânglios",
            "M67.8 - Outros Transtornos Especificados da Sinóvia e do Tendão",
            "M67.9 - Transtorno Não Especificado da Sinóvia e do Tendão",
            "M68 - Transtorno de Sinóvias e de Tendões em Doenças Classificadas em Outra Parte",
            "M68.0 - Sinovite e Tenossinovite em Doenças Bacterianas Classificadas em Outra Parte",
            "M68.8 - Outros Transtornos de Sinóvias e de Tendões em Doenças Classificadas em Outra Parte",
            "M70 - Transtornos Dos Tecidos Moles Relacionados Com o Uso, Uso Excessivo e Pressão",
            "M70.0 - Sinovite Crepitante Crônica da Mão e do Punho",
            "M70.1 - Bursite da Mão",
            "M70.2 - Bursite do Olécrano",
            "M70.3 - Outras Bursites do Cotovelo",
            "M70.4 - Bursite Pré-patelar",
            "M70.5 - Outras Bursites do Joelho",
            "M70.6 - Bursite Trocantérica",
            "M70.7 - Outras Bursites do Quadril",
            "M70.8 - Outros Transtornos Dos Tecidos Moles Relacionados Com o Uso, Uso Excessivo e Pressão",
            "M70.9 - Transtorno Não Especificado Dos Tecidos Moles Relacionados Com o Uso, Uso Excessivo e Pressão",
            "M71 - Outras Bursopatias",
            "M71.0 - Abscesso de Bolsa Sinovial",
            "M71.1 - Outras Bursites Infecciosas",
            "M71.2 - Cisto Sinovial do Espaço Poplíteo (Baker)",
            "M71.3 - Outros Cistos de Bolsa Sinovial",
            "M71.4 - Depósito de Cálcio em Bolsa Sinovial",
            "M71.5 - Outras Bursites Não Classificadas em Outra Parte",
            "M71.8 - Outras Bursopatias Especificadas",
            "M71.9 - Bursopatia Não Especificada",
            "M72 - Transtornos Fibroblásticos",
            "M72.0 - Fibromatose de Fáscia Palmar (Dupuytren)",
            "M72.1 - Coxins Interfalângicos (nó Dos Dedos)",
            "M72.2 - Fibromatose da Fáscia Plantar",
            "M72.4 - Fibromatose Pseudossarcomatosa",
            "M72.6 - Fasciíte Necrosante",
            "M72.8 - Outros Transtornos Fibroblásticos",
            "M72.9 - Transtorno Fibroblástico Não Especificado",
            "M73 - Transtornos Dos Tecidos Moles em Doenças Classificadas em Outra Parte",
            "M73.0 - Bursite Gonocócica",
            "M73.1 - Bursite Sifilítica",
            "M73.8 - Outros Transtornos Dos Tecidos Moles em Outras Doenças Classificadas em Outra Parte",
            "M75 - Lesões do Ombro",
            "M75.0 - Capsulite Adesiva do Ombro",
            "M75.1 - Síndrome do Manguito Rotador",
            "M75.2 - Tendinite Bicepital",
            "M75.3 - Tendinite Calcificante do Ombro",
            "M75.4 - Síndrome de Colisão do Ombro",
            "M75.5 - Bursite do Ombro",
            "M75.8 - Outras Lesões do Ombro",
            "M75.9 - Lesão Não Especificada do Ombro",
            "M76 - Entesopatias Dos Membros Inferiores, Excluindo pé",
            "M76.0 - Tendinite Glútea",
            "M76.1 - Tendinite do Psoas",
            "M76.2 - Esporão da Crista Ilíaca",
            "M76.3 - Síndrome da Faixa Iliotibial",
            "M76.4 - Bursite Tibial Colateral (Pellegrini-Stieda)",
            "M76.5 - Tendinite Patelar",
            "M76.6 - Tendinite Aquileana",
            "M76.7 - Tendinite do Perôneo",
            "M76.8 - Outras Entesopatias do Membro Inferior, Excluindo o pé",
            "M76.9 - Entesopatia do Membro Inferior Não Especificada",
            "M77 - Outras Entesopatias",
            "M77.0 - Epicondilite Medial",
            "M77.1 - Epicondilite Lateral",
            "M77.2 - Periartrite do Punho",
            "M77.3 - Esporão do Calcâneo",
            "M77.4 - Metatarsalgia",
            "M77.5 - Outra Entesopatia do pé",
            "M77.8 - Outras Entesopatias Não Classificadas em Outra Parte",
            "M77.9 - Entesopatia Não Especificada",
            "M79 - Outros Transtornos Dos Tecidos Moles, Não Classificados em Outra Parte",
            "M79.0 - Reumatismo Não Especificado",
            "M79.1 - Mialgia",
            "M79.2 - Nevralgia e Neurite Não Especificadas",
            "M79.3 - Paniculite Não Especificada",
            "M79.4 - Hipertrofia do Coxim Gorduroso (infrapatelar)",
            "M79.5 - Corpo Estranho Residual no Tecido Mole",
            "M79.6 - Dor em Membro",
            "M79.7 - Fibromialgia",
            "M79.8 - Outros Transtornos Especificados Dos Tecidos Moles",
            "M79.9 - Transtorno Dos Tecidos Moles Não Especificado",
            "M80 - Osteoporose Com Fratura Patológica",
            "M80.0 - Osteoporose Pós-menopáusica Com Fratura Patológica",
            "M80.1 - Osteoporose Pós-ooforectomia Com Fratura Patológica",
            "M80.2 - Osteoporose de Desuso Com Fratura Patológica",
            "M80.3 - Osteoporose Por Má-absorção Pós-cirúrgica Com Fratura Patológica",
            "M80.4 - Osteoporose Induzida Por Drogas Com Fratura Patológica",
            "M80.5 - Osteoporose Idiopática Com Fratura Patológica",
            "M80.8 - Outras Osteoporoses Com Fratura Patológica",
            "M80.9 - Osteoporose Não Especificada Com Fratura Patológica",
            "M81 - Osteoporose Sem Fratura Patológica",
            "M81.0 - Osteoporose Pós-menopáusica",
            "M81.1 - Osteoporose Pós-ooforectomia",
            "M81.2 - Osteoporose de Desuso",
            "M81.3 - Osteoporose Devida à Má-absorção Pós-cirúrgica",
            "M81.4 - Osteoporose Induzida Por Drogas",
            "M81.5 - Osteoporose Idiopática",
            "M81.6 - Osteoporose Localizada (Lequesne)",
            "M81.8 - Outras Osteoporoses",
            "M81.9 - Osteoporose Não Especificada",
            "M82 - Osteoporose em Doenças Classificadas em Outra Parte",
            "M82.0 - Osteoporose na Mielomatose Múltipla",
            "M82.1 - Osteoporose em Distúrbios Endócrinos",
            "M82.8 - Osteoporose em Outras Doenças Classificadas em Outra Parte",
            "M83 - Osteomalácia do Adulto",
            "M83.0 - Osteomalácia Puerperal",
            "M83.1 - Osteomalácia Senil",
            "M83.2 - Osteomalácia do Adulto Devida a Má-absorção",
            "M83.3 - Osteomalácia do Adulto Devido à Desnutrição",
            "M83.4 - Doença Óssea Pelo Alumínio",
            "M83.5 - Outras Osteomalácias do Adulto Induzidas Por Drogas",
            "M83.8 - Outra Osteomalácia do Adulto",
            "M83.9 - Osteomalácia Não Especificada do Adulto",
            "M84 - Transtornos da Continuidade do Osso",
            "M84.0 - Defeito de Consolidação da Fratura",
            "M84.1 - Ausência de Consolidação da Fratura (pseudo-artrose)",
            "M84.2 - Atraso de Consolidação de Fratura",
            "M84.3 - Fratura de Fadiga(stress) Não Classificada em Outra Parte",
            "M84.4 - Fratura Patológica Não Classificada em Outra Parte",
            "M84.8 - Outros Transtornos da Continuidade do Osso",
            "M84.9 - Transtorno Não Especificado da Continuidade do Osso",
            "M85 - Outros Transtornos da Densidade e da Estrutura Ósseas",
            "M85.0 - Displasia Fibrosa (monostótica)",
            "M85.1 - Fluorose Esquelética",
            "M85.2 - Hiperostose do Crânio",
            "M85.3 - Osteíte Condensante",
            "M85.4 - Cisto Ósseo Solitário",
            "M85.5 - Cisto Ósseo Aneurismático",
            "M85.6 - Outro Cisto Ósseo",
            "M85.8 - Outros Transtornos Especificados da Densidade e da Estrutura Ósseas",
            "M85.9 - Transtorno Não Especificado da Densidade e da Estrutura Ósseas",
            "M86 - Osteomielite",
            "M86.0 - Osteomielite Aguda Hematogênica",
            "M86.1 - Outra Osteomielite Aguda",
            "M86.2 - Osteomielite Subaguda",
            "M86.3 - Osteomielite Crônica Multifocal",
            "M86.4 - Osteomielite Crônica Com Seio Drenante",
            "M86.5 - Outra Osteomielite Crônica Hematogênica",
            "M86.6 - Outra Osteomielite Crônica",
            "M86.8 - Outra Osteomielite",
            "M86.9 - Osteomielite Não Especificada",
            "M87 - Osteonecrose",
            "M87.0 - Necrose Asséptica Idiopática do Osso",
            "M87.1 - Osteonecrose Devida a Drogas",
            "M87.2 - Osteonecrose Devida a Traumatismo Anterior",
            "M87.3 - Outras Osteonecroses Secundárias",
            "M87.8 - Outras Osteonecroses",
            "M87.9 - Osteonecrose Não Especificada",
            "M88 - Doença de Paget do Osso (osteíte Deformante)",
            "M88.0 - Doença de Paget do Crânio",
            "M88.8 - Doença de Paget de Outros Ossos",
            "M88.9 - Doença de Paget de Osso Não Especificado",
            "M89 - Outros Transtornos Ósseos",
            "M89.0 - Algoneurodistrofia",
            "M89.1 - Parada de Crescimento Epifisário",
            "M89.2 - Outros Transtornos do Desenvolvimento e do Crescimento Ósseo",
            "M89.3 - Hipertrofia Óssea",
            "M89.4 - Outras Osteoartropatias Hipertróficas",
            "M89.5 - Osteolise",
            "M89.6 - Osteopatia Pós-poliomielite",
            "M89.8 - Outros Transtornos Especificados do Osso",
            "M89.9 - Transtorno Não Especificado do Osso",
            "M90 - Osteopatias em Doenças Classificadas em Outra Parte",
            "M90.0 - Tuberculose Óssea",
            "M90.1 - Periostite em Outras Doenças Infecciosas Classificadas em Outra Parte",
            "M90.2 - Osteopatia em Outras Doenças Infecciosas Classificadas em Outra Parte",
            "M90.3 - Osteonecrose em mal Dos Caixões",
            "M90.4 - Osteonecrose Devida à Hemoglobinopatia",
            "M90.5 - Osteonecrose em Outras Doenças Classificadas em Outra Parte",
            "M90.6 - Osteíte Deformante em Doenças Neoplásicas",
            "M90.7 - Fratura Óssea em Doenças Neoplásicas",
            "M90.8 - Osteopatia em Outras Doenças Classificadas em Outra Parte",
            "M91 - Osteocondrose Juvenil do Quadril e da Pelve",
            "M91.0 - Osteocondrose Juvenil da Pelve",
            "M91.1 - Osteocondrose Juvenil da Cabeça do Fêmur (Legg-Calvé-Perthes)",
            "M91.2 - Coxa Plana",
            "M91.3 - Pseudocoxalgia",
            "M91.8 - Outras Osteocondroses Juvenis do Quadril e da Pelve",
            "M91.9 - Osteocondrose Juvenil Não Especificada do Quadril e da Pelve",
            "M92 - Outras Osteocondroses Juvenis",
            "M92.0 - Osteocondrose Juvenil do Úmero",
            "M92.1 - Osteocondrose Juvenil do Rádio e do Cúbito (ulna)",
            "M92.2 - Osteocondrose Juvenil da Mão",
            "M92.3 - Outras Osteocondroses Juvenis do Membro Superior",
            "M92.4 - Osteocondrose Juvenil da Rótula (patela)",
            "M92.5 - Osteocondrose Juvenil da Tíbia e Perônio (fíbula)",
            "M92.6 - Osteocondrose Juvenil do Tarso",
            "M92.7 - Osteocondrose Juvenil do Metatarso",
            "M92.8 - Outras Osteocondroses Juvenis Especificadas",
            "M92.9 - Osteocondrose Juvenil, Não Especificada",
            "M93 - Outras Osteocondropatias",
            "M93.0 - Luxação (não-traumática) da Epífise Superior do Fêmur",
            "M93.1 - Doença de Kienböck do Adulto",
            "M93.2 - Osteocondrite Dissecante",
            "M93.8 - Outras Osteocondropatias, Especificadas",
            "M93.9 - Osteocondropatias, Não Especificada",
            "M94 - Outros Transtornos Das Cartilagens",
            "M94.0 - Síndrome da Junção Condrocostal (Tietze)",
            "M94.1 - Policondrite Recidivante",
            "M94.2 - Condromalácia",
            "M94.3 - Condrolise",
            "M94.8 - Outros Transtornos Especificados da Cartilagem",
            "M94.9 - Transtornos Não Especificados da Cartilagem",
            "M95 - Outras Deformidades Adquiridas do Sistema Osteomuscular e do Tecido Conjuntivo",
            "M95.0 - Deformidade Adquirida do Nariz",
            "M95.1 - Orelha em Couve-flor",
            "M95.2 - Outras Deformidades Adquiridas da Cabeça",
            "M95.3 - Deformidade Adquirida do Pescoço",
            "M95.4 - Deformidade Adquirida do Tórax e Das Costelas",
            "M95.5 - Deformidade Adquirida da Pelve",
            "M95.8 - Outras Deformidades Adquiridas Especificadas do Sistema Osteomuscular",
            "M95.9 - Deformidade Adquirida do Sistema Osteomuscular Não Especificada",
            "M96 - Transtornos Osteomusculares Pós-procedimentos Não Classificados em Outra Parte",
            "M96.0 - Pseudo-artrose Após Fusão ou Artrodese",
            "M96.1 - Síndrome Pós-laminectomia Não Classificada em Outra Parte",
            "M96.2 - Cifose Pós-radiação",
            "M96.3 - Cifose Pós-laminectomia",
            "M96.4 - Lordose Pós-cirúrgica",
            "M96.5 - Escoliose Pós-radiação",
            "M96.6 - Fratura de Osso Subsequente a Implante Ortopédico, Prótese Articular ou Placa Óssea",
            "M96.8 - Outros Transtornos Osteomusculares Pós-procedimentos",
            "M96.9 - Transtorno Osteomuscular Não Especificado Pós-procedimento",
            "M99 - Lesões Biomecânicas Não Classificadas em Outra Parte",
            "M99.0 - Disfunção Segmentar e Somática",
            "M99.1 - Complexo de Subluxação (vertebral)",
            "M99.2 - Estenose de Subluxação do Canal Medular",
            "M99.3 - Estenose Óssea do Canal Medular",
            "M99.4 - Estenose de Tecido Conjuntivo do Canal Medular",
            "M99.5 - Estenose de Disco Intervertebral do Canal Medular",
            "M99.6 - Estenose Óssea e Subluxação Dos Forames Intervertebrais",
            "M99.7 - Estenose de Tecido Conjuntivo e do Disco Dos Forames Intervertebrais",
            "M99.8 - Outras Lesões Biomecânicas",
            "M99.9 - Lesão Biomecânica Não Especificada",
            "N00 - Síndrome Nefrítica Aguda",
            "N00.0 - Síndrome Nefrítica Aguda - Anormalidade Glomerular Minor",
            "N00.1 - Síndrome Nefrítica Aguda - Lesões Glomerulares Focais e Segmentares",
            "N00.2 - Síndrome Nefrítica Aguda - Glomerulonefrite Membranosa Difusa",
            "N00.3 - Síndrome Nefrítica Aguda - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N00.4 - Síndrome Nefrítica Aguda - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N00.5 - Síndrome Nefrítica Aguda - Glomerulonefrite Mesangiocapilar Difusa",
            "N00.6 - Síndrome Nefrítica Aguda - Doença de Depósito Denso",
            "N00.7 - Síndrome Nefrítica Aguda - Glomerulonefrite Difusa em Crescente",
            "N00.8 - Síndrome Nefrítica Aguda - Outras",
            "N00.9 - Síndrome Nefrítica Aguda - Não Especificada",
            "N01 - Síndrome Nefrítica Rapidamente Progressiva",
            "N01.0 - Síndrome Nefrítica Rapidamente Progressiva - Anormalidade Glomerular Minor",
            "N01.1 - Síndrome Nefrítica Rapidamente Progressiva - Lesões Glomerulares Focais e Segmentares",
            "N01.2 - Síndrome Nefrítica Rapidamente Progressiva - Glomerulonefrite Membranosa Difusa",
            "N01.3 - Síndrome Nefrítica Rapidamente Progressiva - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N01.4 - Síndrome Nefrítica Rapidamente Progressiva - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N01.5 - Síndrome Nefrítica Rapidamente Progressiva - Glomerulonefrite Mesangiocapilar Difusa",
            "N01.6 - Síndrome Nefrítica Rapidamente Progressiva - Doença de Depósito Denso",
            "N01.7 - Síndrome Nefrítica Rapidamente Progressiva - Glomerulonefrite Difusa em Crescente",
            "N01.8 - Síndrome Nefrítica Rapidamente Progressiva - Outras",
            "N01.9 - Síndrome Nefrítica Rapidamente Progressiva - Não Especificada",
            "N02 - Hematúria Recidivante e Persistente",
            "N02.0 - Hematúria Recidivante e Persistente - Anormalidade Glomerular Minor",
            "N02.1 - Hematúria Recidivante e Persistente - Lesões Glomerulares Focais e Segmentares",
            "N02.2 - Hematúria Recidivante e Persistente - Glomerulonefrite Membranosa Difusa",
            "N02.3 - Hematúria Recidivante e Persistente - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N02.4 - Hematúria Recidivante e Persistente - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N02.5 - Hematúria Recidivante e Persistente - Glomerulonefrite Mesangiocapilar Difusa",
            "N02.6 - Hematúria Recidivante e Persistente - Doença de Depósito Denso",
            "N02.7 - Hematúria Recidivante e Persistente - Glomerulonefrite Difusa em Crescente",
            "N02.8 - Hematúria Recidivante e Persistente - Outras",
            "N02.9 - Hematúria Recidivante e Persistente - Não Especificada",
            "N03 - Síndrome Nefrítica Crônica",
            "N03.0 - Síndrome Nefrítica Crônica - Anormalidade Glomerular Minor",
            "N03.1 - Síndrome Nefrítica Crônica - Lesões Glomerulares Focais e Segmentares",
            "N03.2 - Síndrome Nefrítica Crônica - Glomerulonefrite Membranosa Difusa",
            "N03.3 - Síndrome Nefrítica Crônica - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N03.4 - Síndrome Nefrítica Crônica - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N03.5 - Síndrome Nefrítica Crônica - Glomerulonefrite Mesangiocapilar Difusa",
            "N03.6 - Síndrome Nefrítica Crônica - Doença de Depósito Denso",
            "N03.7 - Síndrome Nefrítica Crônica - Glomerulonefrite Difusa em Crescente",
            "N03.8 - Síndrome Nefrítica Crônica - Outras",
            "N03.9 - Síndrome Nefrítica Crônica - Não Especificada",
            "N04 - Síndrome Nefrótica",
            "N04.0 - Síndrome Nefrótica - Anormalidade Glomerular Minor",
            "N04.1 - Síndrome Nefrótica - Lesões Glomerulares Focais e Segmentares",
            "N04.2 - Síndrome Nefrótica - Glomerulonefrite Membranosa Difusa",
            "N04.3 - Síndrome Nefrótica - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N04.4 - Síndrome Nefrótica - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N04.5 - Síndrome Nefrótica - Glomerulonefrite Mesangiocapilar Difusa",
            "N04.6 - Síndrome Nefrótica - Doença de Depósito Denso",
            "N04.7 - Síndrome Nefrótica - Glomerulonefrite Difusa em Crescente",
            "N04.8 - Síndrome Nefrótica - Outras",
            "N04.9 - Síndrome Nefrótica - Não Especificada",
            "N05 - Síndrome Nefrítica Não Especificada",
            "N05.0 - Síndrome Nefrítica Não Especificada - Anormalidade Glomerular Minor",
            "N05.1 - Síndrome Nefrítica Não Especificada - Lesões Glomerulares Focais e Segmentares",
            "N05.2 - Síndrome Nefrítica Não Especificada - Glomerulonefrite Membranosa Difusa",
            "N05.3 - Síndrome Nefrítica Não Especificada - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N05.4 - Síndrome Nefrítica Não Especificada - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N05.5 - Síndrome Nefrítica Não Especificada - Glomerulonefrite Mesangiocapilar Difusa",
            "N05.6 - Síndrome Nefrítica Não Especificada - Doença de Depósito Denso",
            "N05.7 - Síndrome Nefrítica Não Especificada - Glomerulonefrite Difusa em Crescente",
            "N05.8 - Síndrome Nefrítica Não Especificada - Outras",
            "N05.9 - Síndrome Nefrítica Não Especificada - Não Especificada",
            "N06 - Proteinúria Isolada Com Lesão Morfológica Especificada",
            "N06.0 - Proteinúria Isolada Com Lesão Morfológica Especificada - Anormalidade Glomerular Minor",
            "N06.1 - Proteinúria Isolada Com Lesão Morfológica Especificada - Lesões Glomerulares Focais e Segmentares",
            "N06.2 - Proteinúria Isolada Com Lesão Morfológica Especificada - Glomerulonefrite Membranosa Difusa",
            "N06.3 - Proteinúria Isolada Com Lesão Morfológica Especificada - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N06.4 - Proteinúria Isolada Com Lesão Morfológica Especificada - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N06.5 - Proteinúria Isolada Com Lesão Morfológica Especificada - Glomerulonefrite Mesangiocapilar Difusa",
            "N06.6 - Proteinúria Isolada Com Lesão Morfológica Especificada - Doença de Depósito Denso",
            "N06.7 - Proteinúria Isolada Com Lesão Morfológica Especificada - Glomerulonefrite Difusa em Crescente",
            "N06.8 - Proteinúria Isolada Com Lesão Morfológica Especificada - Outras",
            "N06.9 - Proteinúria Isolada Com Lesão Morfológica Especificada - Não Especificada",
            "N07 - Nefropatia Hereditária Não Classificada em Outra Parte",
            "N07.0 - Nefropatia Hereditária Não Classificada em Outra Parte - Anormalidade Glomerular Minor",
            "N07.1 - Nefropatia Hereditária Não Classificada em Outra Parte - Lesões Glomerulares Focais e Segmentares",
            "N07.2 - Nefropatia Hereditária Não Classificada em Outra Parte - Glomerulonefrite Membranosa Difusa",
            "N07.3 - Nefropatia Hereditária Não Classificada em Outra Parte - Glomerulonefrite Proliferativa Mesangial Difusa",
            "N07.4 - Nefropatia Hereditária Não Classificada em Outra Parte - Glomerulonefrite Proliferativa Endocapilar Difusa",
            "N07.5 - Nefropatia Hereditária Não Classificada em Outra Parte - Glomerulonefrite Mesangiocapilar Difusa",
            "N07.6 - Nefropatia Hereditária Não Classificada em Outra Parte - Doença de Depósito Denso",
            "N07.7 - Nefropatia Hereditária Não Classificada em Outra Parte - Glomerulonefrite Difusa em Crescente",
            "N07.8 - Nefropatia Hereditária Não Classificada em Outra Parte - Outras",
            "N07.9 - Nefropatia Hereditária Não Classificada em Outra Parte - Não Especificada",
            "N08 - Transtornos Glomerulares em Doenças Classificadas em Outra Parte",
            "N08.0 - Transtornos Glomerulares em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "N08.1 - Transtornos Glomerulares em Doenças Neoplásicas",
            "N08.2 - Transtornos Glomerulares em Doenças do Sangue e Transtornos Imunitários",
            "N08.3 - Transtornos Glomerulares no Diabetes Mellitus",
            "N08.4 - Transtornos Glomerulares em Doenças Endócrinas, Nutricionais e Metabólicas",
            "N08.5 - Transtornos Glomerulares em Doenças Sistêmicas do Tecido Conjuntivo",
            "N08.8 - Transtornos Glomerulares em Outras Doenças Classificadas em Outra Parte",
            "N10 - Nefrite Túbulo-intersticial Aguda",
            "N11 - Nefrite Túbulo-intersticial Crônica",
            "N11.0 - Pielonefrite Não-obstrutiva Crônica Associada a Refluxo",
            "N11.1 - Pielonefrite Obstrutiva Crônica",
            "N11.8 - Outras Nefrites Túbulo-intersticiais Crônicas",
            "N11.9 - Nefrite Túbulo-intersticial Crônica Não Especificada",
            "N12 - Nefrite Túbulo-intersticial Não Especificada se Aguda ou Crônica",
            "N13 - Uropatia Obstrutiva e Por Refluxo",
            "N13.0 - Hidronefrose Com Obstrução da Junção Uretero-pélvica",
            "N13.1 - Hidronefrose Com Estreitamento de Ureter Não Classificada em Outra Parte",
            "N13.2 - Hidronefrose Com Obstrução Por Calculose Renal e Ureteral",
            "N13.3 - Outras Hidronefroses e as Não Especificadas",
            "N13.4 - Hidroureter",
            "N13.5 - Torção e Estreitamento do Ureter Sem Hidronefrose",
            "N13.6 - Pionefrose",
            "N13.7 - Uropatia Associada a Refluxo Vésico-ureteral",
            "N13.8 - Outras Uropatias Obstrutivas e Por Refluxo",
            "N13.9 - Uropatia Obstrutiva e Por Refluxo Não Especificada",
            "N14 - Afecções Tubulares e Túbulo-intersticiais Induzidas Por Drogas ou Metais Pesados",
            "N14.0 - Nefropatia Induzida Por Analgésico",
            "N14.1 - Nefropatia Induzida Por Outras Drogas, Medicamentos e Substâncias Biológicas",
            "N14.2 - Nefropatia Induzida Por Drogas, Medicamentos e Substâncias Biológicas Não Especificadas",
            "N14.3 - Nefropatia Induzida Por Metais Pesados",
            "N14.4 - Nefropatia Tóxica Não Classificada em Outra Parte",
            "N15 - Outras Doenças Renais Túbulo-intersticiais",
            "N15.0 - Nefropatia Dos Bálcãs",
            "N15.1 - Abscesso Renal e Perinefrético",
            "N15.8 - Outras Doenças Renais Túbulo-intersticiais Especificadas",
            "N15.9 - Doença Renal Túbulo-intersticial Não Especificada",
            "N16 - Transtornos Renais Túbulo-intersticiais em Doenças Classificadas em Outra Parte",
            "N16.0 - Transtornos Renais Túbulo-intersticiais em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "N16.1 - Transtornos Renais Túbulo-intersticiais em Doenças Neoplásicas",
            "N16.2 - Transtornos Renais Túbulo-intersticiais em Doenças do Sangue e Transtornos Imunitários",
            "N16.3 - Transtornos Renais Túbulo-intersticiais em Doenças Metabólicas",
            "N16.4 - Transtornos Renais Túbulo-intersticiais em Doenças do Tecido Conjuntivo",
            "N16.5 - Transtornos Renais Túbulo-intersticiais em Rejeição a Transplantes",
            "N16.8 - Transtornos Renais Túbulo-intersticiais em Outras Doenças Classificadas em Outra Parte",
            "N17 - Insuficiência Renal Aguda",
            "N17.0 - Insuficiência Renal Aguda Com Necrose Tubular",
            "N17.1 - Insuficiência Renal Aguda Com Necrose Cortical Aguda",
            "N17.2 - Insuficiência Renal Aguda Com Necrose Medular",
            "N17.8 - Outro Tipo de Insuficiência Renal Aguda",
            "N17.9 - Insuficiência Renal Aguda Não Especificada",
            "N18 - Insuficiência Renal Crônica",
            "N18.0 - Doença Renal em Estádio Final",
            "N18.8 - Outra Insuficiência Renal Crônica",
            "N18.9 - Insuficiência Renal Crônica Não Especificada",
            "N19 - Insuficiência Renal Não Especificada",
            "N20 - Calculose do Rim e do Ureter",
            "N20.0 - Calculose do Rim",
            "N20.1 - Calculose do Ureter",
            "N20.2 - Calculose do Rim Com Cálculo do Ureter",
            "N20.9 - Calculose Urinária, Não Especificada",
            "N21 - Calculose do Trato Urinário Inferior",
            "N21.0 - Calculose na Bexiga",
            "N21.1 - Cálculo Uretral",
            "N21.8 - Outros Cálculos do Trato Urinário Inferior",
            "N21.9 - Cálculo do Trato Urinário Inferior, Porção Não Especificada",
            "N22 - Calculose do Trato Urinário Inferior em Doenças Classificadas em Outra Parte",
            "N22.0 - Cálculo Urinário na Esquistossomose (bilharziose) (schistosomíase)",
            "N22.8 - Calculose do Trato Urinário em Outras Doenças Classificadas em Outra Parte",
            "N23 - Cólica Nefrética Não Especificada",
            "N25 - Transtornos Resultantes de Função Renal Tubular Alterada",
            "N25.0 - Osteodistrofia Renal",
            "N25.1 - Diabetes Insípido Nefrogênico",
            "N25.8 - Outros Transtornos Resultantes de Função Renal Tubular Alterada",
            "N25.9 - Transtornos Resultante de Função Tubular Alterada",
            "N26 - Rim Contraído, Não Especificado",
            "N27 - Hipoplasia Renal de Causa Desconhecida",
            "N27.0 - Hipoplasia Renal Unilateral",
            "N27.1 - Hipoplasia Renal Bilateral",
            "N27.9 - Hipoplasia Renal Não Especificada",
            "N28 - Outros Transtornos do Rim e do Ureter Não Classificado em Outra Parte",
            "N28.0 - Isquemia e Infarto Renal",
            "N28.1 - Cisto do Rim, Adquirido",
            "N28.8 - Outros Transtornos Especificados do Rim e do Ureter",
            "N28.9 - Transtorno Não Especificado do Rim e do Ureter",
            "N29 - Outros Transtornos do Rim e do Ureter em Doenças Classificadas em Outra Parte",
            "N29.0 - Sífilis Tardia Renal",
            "N29.1 - Outros Transtornos do Rim e do Ureter em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "N29.8 - Outros Transtornos do Rim e do Ureter em Outras Doenças Classificadas em Outra Parte",
            "N30 - Cistite",
            "N30.0 - Cistite Aguda",
            "N30.1 - Cistite Intersticial (crônica)",
            "N30.2 - Outras Cistites Crônicas",
            "N30.3 - Trigonite",
            "N30.4 - Cistite Por Radiação",
            "N30.8 - Outras Cistites",
            "N30.9 - Cistite, Não Especificada",
            "N31 - Disfunções Neuromusculares da Bexiga Não Classificados em Outra Parte",
            "N31.0 - Bexiga Neuropática Não-inibida Não Classificada em Outra Parte",
            "N31.1 - Bexiga Neuropática Reflexa Não Classificada em Outra Parte",
            "N31.2 - Bexiga Neuropática Flácida Não Classificada em Outra Parte",
            "N31.8 - Outra Disfunção Neuromuscular da Bexiga",
            "N31.9 - Disfunção Neuromuscular Não Especificada da Bexiga",
            "N32 - Outros Transtornos da Bexiga",
            "N32.0 - Obstrução do Colo da Bexiga",
            "N32.1 - Fístula Êntero-vesical",
            "N32.2 - Fístula Vesical Não Classificada em Outra Parte",
            "N32.3 - Divertículo da Bexiga",
            "N32.4 - Ruptura da Bexiga, Não-traumática",
            "N32.8 - Outros Transtornos Especificados da Bexiga",
            "N32.9 - Transtorno Não Especificado da Bexiga",
            "N33 - Transtornos da Bexiga em Doenças Classificadas em Outra Parte",
            "N33.0 - Cistite Tuberculosa",
            "N33.8 - Transtornos da Bexiga em Outras Doenças Classificadas em Outra Parte",
            "N34 - Uretrite e Síndrome Uretral",
            "N34.0 - Abscesso Uretral",
            "N34.1 - Uretrites Não Específicas",
            "N34.2 - Outras Uretrites",
            "N34.3 - Síndrome Uretral, Não Especificada",
            "N35 - Estenose da Uretra",
            "N35.0 - Estenose Uretral Pós-traumática",
            "N35.1 - Estenose Uretral Pós-infecciosa Não Classificada em Outra Parte",
            "N35.8 - Outra Estenose (estreitamento) Uretral",
            "N35.9 - Estenose (estreitamento) Uretral Não Especificada(o)",
            "N36 - Outros Transtornos da Uretra",
            "N36.0 - Fístula Uretral",
            "N36.1 - Divertículo Uretral",
            "N36.2 - Carúncula Uretral",
            "N36.3 - Prolapso da Mucosa Uretral",
            "N36.8 - Outros Transtornos Especificados da Uretra",
            "N36.9 - Transtorno Não Especificado da Uretra",
            "N37 - Transtornos da Uretra em Doenças Classificadas em Outra Parte",
            "N37.0 - Uretrite em Doenças Classificadas em Outra Parte",
            "N37.8 - Outros Transtornos da Uretra em Doenças Classificadas em Outra Parte",
            "N39 - Outros Transtornos do Trato Urinário",
            "N39.0 - Infecção do Trato Urinário de Localização Não Especificada",
            "N39.1 - Proteinúria Persistente Não Especificada",
            "N39.2 - Proteinúria Ortostática Não Especificada",
            "N39.3 - Incontinência de Tensão(stress)",
            "N39.4 - Outras Incontinências Urinárias Especificadas",
            "N39.8 - Outros Transtornos Especificados do Aparelho Urinário",
            "N39.9 - Transtornos Não Especificados do Aparelho Urinário",
            "N40 - Hiperplasia da Próstata",
            "N41 - Doenças Inflamatórias da Próstata",
            "N41.0 - Prostatite Aguda",
            "N41.1 - Prostatite Crônica",
            "N41.2 - Abscesso da Próstata",
            "N41.3 - Prostatocistite",
            "N41.8 - Outras Doenças Inflamatórias da Próstata",
            "N41.9 - Doença Inflamatória Não Especificada da Próstata",
            "N42 - Outras Afecções da Próstata",
            "N42.0 - Cálculo da Próstata",
            "N42.1 - Congestão e Hemorragia da Próstata",
            "N42.2 - Atrofia da Próstata",
            "N42.8 - Outras Afecções Especificadas da Próstata",
            "N42.9 - Afecção Não Especificada da Próstata",
            "N43 - Hidrocele e Espermatocele",
            "N43.0 - Hidrocele Encistada",
            "N43.1 - Hidrocele Infectada",
            "N43.2 - Outra Hidrocele",
            "N43.3 - Hidrocele Não Especificada",
            "N43.4 - Espermatocele",
            "N44 - Torção do Testículo",
            "N45 - Orquite e Epididimite",
            "N45.0 - Orquite, Epididimite e Epidídimo-orquite, Com Menção de Abscesso",
            "N45.9 - Orquite, Epididimite e Epidídimo-orquite, Sem Menção de Abscesso",
            "N46 - Infertilidade Masculina",
            "N47 - Hipertrofia do Prepúcio, Fimose e Parafimose",
            "N48 - Outros Transtornos do Pênis",
            "N48.0 - Leucoplasia do Pênis",
            "N48.1 - Balanopostite",
            "N48.2 - Outros Transtornos Inflamatórios do Pênis",
            "N48.3 - Priapismo",
            "N48.4 - Impotência de Origem Orgânica",
            "N48.5 - Úlcera do Pênis",
            "N48.6 - Induratio Penis Plastica",
            "N48.8 - Outros Transtornos Especificados do Pênis",
            "N48.9 - Transtorno Não Especificado do Pênis",
            "N49 - Transtornos Inflamatórios de Órgãos Genitais Masculinos, Não Classificados em Outra Parte",
            "N49.0 - Transtornos Inflamatórios da Vesícula Seminal",
            "N49.1 - Transtornos Inflamatórios do Cordão Espermático, Túnica Vaginal e Vasos Deferentes",
            "N49.2 - Transtorno Inflamatório do Escroto",
            "N49.8 - Transtornos Inflamatórios de Outros Órgãos Genitais Masculinos Especificados",
            "N49.9 - Transtorno Inflamatório de Órgão Genital Masculino, Não Especificado",
            "N50 - Outros Transtornos Dos Órgãos Genitais Masculinos",
            "N50.0 - Atrofia do Testículo",
            "N50.1 - Transtornos Vasculares Dos Órgãos Genitais Masculinos",
            "N50.8 - Outros Transtornos Especificados Dos Órgãos Genitais Masculinos",
            "N50.9 - Transtorno Não Especificado Dos Órgãos Genitais Masculinos",
            "N51 - Transtornos Dos Órgãos Genitais Masculinos em Doenças Classificadas em Outra Parte",
            "N51.0 - Transtornos da Próstata em Doenças Classificadas em Outra Parte",
            "N51.1 - Transtornos do Testículo e do Epidídimo em Doenças Classificadas em Outra Parte",
            "N51.2 - Balanite em Doenças Classificadas em Outra Parte",
            "N51.8 - Outros Transtornos Dos Órgãos Genitais Masculinos em Doenças Classificadas em Outra Parte",
            "N60 - Displasias Mamárias Benignas",
            "N60.0 - Cisto Solitário da Mama",
            "N60.1 - Mastopatia Cística Difusa",
            "N60.2 - Fibroadenose da Mama",
            "N60.3 - Fibrosclerose da Mama",
            "N60.4 - Ectasia de Dutos Mamários",
            "N60.8 - Outras Displasias Mamárias Benignas",
            "N60.9 - Displasia Mamária Benigna Não Especificada",
            "N61 - Transtornos Inflamatórios da Mama",
            "N62 - Hipertrofia da Mama",
            "N63 - Nódulo Mamário Não Especificado",
            "N64 - Outras Doenças da Mama",
            "N64.0 - Fissura e Fístula do Mamilo",
            "N64.1 - Necrose Gordurosa da Mama",
            "N64.2 - Atrofia da Mama",
            "N64.3 - Galactorréia Não-associada ao Parto",
            "N64.4 - Mastodinia",
            "N64.5 - Outros Sintomas e Sinais da Mama",
            "N64.8 - Outros Transtornos Especificados da Mama",
            "N64.9 - Transtorno da Mama Não Especificado",
            "N70 - Salpingite e Ooforite",
            "N70.0 - Salpingite e Ooforite Agudas",
            "N70.1 - Salpingite e Ooforite Crônicas",
            "N70.9 - Salpingite e Ooforite Não Especificadas",
            "N71 - Doença Inflamatória do Útero, Exceto o Colo",
            "N71.0 - Doença Inflamatória Aguda do Útero",
            "N71.1 - Doença Inflamatória Crônica do Útero",
            "N71.9 - Doença Inflamatória Não Especificada do Útero",
            "N72 - Doença Inflamatória do Colo do Útero",
            "N73 - Outras Doenças Inflamatórias Pélvicas Femininas",
            "N73.0 - Parametrite e Celulite Pélvicas Agudas",
            "N73.1 - Parametrite e Celulite Pélvicas Crônicas",
            "N73.2 - Parametrite e Celulite Pélvicas Não Especificadas",
            "N73.3 - Pelviperitonite Aguda Feminina",
            "N73.4 - Pelviperitonite Crônica Feminina",
            "N73.5 - Pelviperitonite Não Especificada Feminina",
            "N73.6 - Aderências Pelviperitonais Femininas",
            "N73.8 - Outras Doenças Inflamatórias Especificadas da Pelve Feminina",
            "N73.9 - Doença Inflamatória Não Especificada da Pelve Feminina",
            "N74 - Transtornos Inflamatórios da Pelve Feminina em Doenças Classificadas em Outra Parte",
            "N74.0 - Tuberculose do Colo do Útero",
            "N74.1 - Tuberculose da Pelve Feminina",
            "N74.2 - Sífilis Pélvica Feminina",
            "N74.3 - Infecção Gonocócica Pélvica Feminina",
            "N74.4 - Infecção Pélvica Feminina Por Clamídia",
            "N74.8 - Inflamação Pélvica Feminina em Outras Doenças Classificadas em Outra Parte",
            "N75 - Doenças da Glândula de Bartholin",
            "N75.0 - Cisto da Glândula de Bartholin",
            "N75.1 - Abscesso da Glândula de Bartholin",
            "N75.8 - Outras Doenças da Glândula de Bartholin",
            "N75.9 - Doença Não Especificada da Glândula de Bartholin",
            "N76 - Outras Afecções Inflamatórias da Vagina e da Vulva",
            "N76.0 - Vaginite Aguda",
            "N76.1 - Vaginite Subaguda e Crônica",
            "N76.2 - Vulvite Aguda",
            "N76.3 - Vulvite Subaguda e Crônica",
            "N76.4 - Abscesso Vulvar",
            "N76.5 - Ulceração Vaginal",
            "N76.6 - Ulceração Vulvar",
            "N76.8 - Outras Inflamações Especificadas da Vagina e da Vulva",
            "N77 - Ulceração e Inflamação Vulvovaginais em Doenças Classificadas em Outra Parte",
            "N77.0 - Ulceração da Vulva em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "N77.1 - Vaginite, Vulvite e Vulvovaginite em Doenças Infecciosas e Parasitárias Classificadas em Outra Parte",
            "N77.8 - Ulceração e Inflamação Vulvovaginais em Outras Doenças Classificadas em Outra Parte",
            "N80 - Endometriose",
            "N80.0 - Endometriose do Útero",
            "N80.1 - Endometriose do Ovário",
            "N80.2 - Endometriose da Trompa de Falópio",
            "N80.3 - Endometriose do Peritônio Pélvico",
            "N80.4 - Endometriose do Septo Retovaginal e da Vagina",
            "N80.5 - Endometriose do Intestino",
            "N80.6 - Endometriose em Cicatriz Cutânea",
            "N80.8 - Outra Endometriose",
            "N80.9 - Endometriose Não Especificada",
            "N81 - Prolapso Genital Feminino",
            "N81.0 - Uretrocele Feminina",
            "N81.1 - Cistocele",
            "N81.2 - Prolapso Uterovaginal Incompleto",
            "N81.3 - Prolapso Uterovaginal Completo",
            "N81.4 - Prolapso Uterovaginal Não Especificado",
            "N81.5 - Enterocele Vaginal",
            "N81.6 - Retocele",
            "N81.8 - Outro Prolapso Genital Feminino",
            "N81.9 - Prolapso Genital Feminino Não Especificado",
            "N82 - Fístulas do Trato Genital Feminino",
            "N82.0 - Fístula Vesicovaginal",
            "N82.1 - Outras Fístulas do Trato Geniturinário Feminino",
            "N82.2 - Fístula Vagina-intestino Delgado",
            "N82.3 - Fístula Vagina-cólon",
            "N82.4 - Outras Fístulas Genito-intestinais Femininas",
            "N82.5 - Fístula Genitocutânea Feminina",
            "N82.8 - Outras Fístulas do Trato Genital Feminino",
            "N82.9 - Fístulas Não Especificadas do Trato Genital Feminino",
            "N83 - Transtornos Não-inflamatórios do Ovário, da Trompa de Falópio e do Ligamento Largo",
            "N83.0 - Cisto Folicular do Ovário",
            "N83.1 - Cisto do Corpo Lúteo",
            "N83.2 - Outros Cistos Ovarianos e os Não Especificados",
            "N83.3 - Atrofia Adquirida do Ovário e da Trompa de Falópio",
            "N83.4 - Prolapso e Hérnia do Ovário e da Trompa de Falópio",
            "N83.5 - Torção do Ovário, do Pedículo Ovariano e da Trompa de Falópio",
            "N83.6 - Hematossalpinge",
            "N83.7 - Hematoma do Ligamento Largo",
            "N83.8 - Outros Transtornos Não-inflamatórios do Ovário, da Trompa de Falópio e do Ligamento Largo",
            "N83.9 - Transtornos Não-inflamatórios do Ovário, da Trompa de Falópio e do Ligamento Largo, Não Especificados",
            "N84 - Pólipo do Trato Genital Feminino",
            "N84.0 - Pólipo do Corpo do Útero",
            "N84.1 - Pólipo do Colo do Útero",
            "N84.2 - Pólipo da Vagina",
            "N84.3 - Pólipo da Vulva",
            "N84.8 - Pólipo de Outras Partes do Trato Genital Feminino",
            "N84.9 - Pólipo do Trato Genital Feminino Não Especificado",
            "N85 - Outros Transtornos Não-inflamatórios do Útero, Exceto do Colo do Útero",
            "N85.0 - Hiperplasia Glandular Endometrial",
            "N85.1 - Hiperplasia Adenomatosa Endometrial",
            "N85.2 - Hipertrofia do Útero",
            "N85.3 - Subinvolução do Útero",
            "N85.4 - Posição Anormal do Útero",
            "N85.5 - Inversão do Útero",
            "N85.6 - Sinéquias Intra-uterinas",
            "N85.7 - Hematometra",
            "N85.8 - Outros Transtornos Não-inflamatórios Especificados do Útero",
            "N85.9 - Transtornos Não-inflamatórios do Útero, Não Especificados",
            "N86 - Erosão e Ectrópio do Colo do Útero",
            "N87 - Displasia do Colo do Útero",
            "N87.0 - Displasia Cervical Leve",
            "N87.1 - Displasia Cervical Moderada",
            "N87.2 - Displasia Cervical Grave, Não Classificada em Outra Parte",
            "N87.9 - Displasia do Colo do Útero, Não Especificada",
            "N88 - Outros Transtornos Não-inflamatórios do Colo do Útero",
            "N88.0 - Leucoplasia do Colo do Útero",
            "N88.1 - Laceração Antiga do Colo do Útero",
            "N88.2 - Estreitamento e Estenose do Colo do Útero",
            "N88.3 - Incompetência do Colo do Útero",
            "N88.4 - Alongamento Hipertrófico do Colo do Útero",
            "N88.8 - Outros Transtornos Não-inflamatórios Especificados do Colo do Útero",
            "N88.9 - Transtorno Não-inflamatório e Não Especificado do Colo do Útero",
            "N89 - Outros Transtornos Não-inflamatórios da Vagina",
            "N89.0 - Displasia Vaginal Leve",
            "N89.1 - Displasia Vaginal Moderada",
            "N89.2 - Displasia Vaginal Grave, Não Classificada em Outra Parte",
            "N89.3 - Displasia da Vagina, Não Especificada",
            "N89.4 - Leucoplasia da Vagina",
            "N89.5 - Estreitamento e Atresia da Vagina",
            "N89.6 - Anel Himenal Apertado",
            "N89.7 - Hematocolpos",
            "N89.8 - Outros Transtornos Não-inflamatórios Especificados da Vagina",
            "N89.9 - Transtorno Não-inflamatório da Vagina, Não Especificado",
            "N90 - Outros Transtornos Não-inflamatórios da Vulva e do Períneo",
            "N90.0 - Displasia Vulvar Leve",
            "N90.1 - Displasia Vulvar Moderada",
            "N90.2 - Displasia Vulvar Grave, Não Classificada em Outra Parte",
            "N90.3 - Displasia de Vulva, Não Especificada",
            "N90.4 - Leucoplasia de Vulva",
            "N90.5 - Atrofia da Vulva",
            "N90.6 - Hipertrofia da Vulva",
            "N90.7 - Cisto Vulvar",
            "N90.8 - Outros Transtornos Não-inflamatórios Especificados da Vulva e do Períneo",
            "N90.9 - Transtorno Não-inflamatório e Não Especificado da Vulva e do Períneo",
            "N91 - Menstruação Ausente, Escassa e Pouco Freqüente",
            "N91.0 - Amenorréia Primária",
            "N91.1 - Amenorréia Secundária",
            "N91.2 - Amenorréia, Não Especificada",
            "N91.3 - Oligomenorréia Primária",
            "N91.4 - Oligomenorréia Secundária",
            "N91.5 - Oligomenorréia, Não Especificada",
            "N92 - Menstruação Excessiva, Freqüente e Irregular",
            "N92.0 - Menstruação Excessiva e Freqüente Com Ciclo Regular",
            "N92.1 - Menstruação Excessiva e Freqüente Com Ciclo Irregular",
            "N92.2 - Menstruação Excessiva na Puberdade",
            "N92.3 - Sangramento da Ovulação",
            "N92.4 - Sangramento Abundante na Pré-menopausa",
            "N92.5 - Outros Tipos Especificados de Irregularidade da Menstruação",
            "N92.6 - Menstruação Irregular, Não Especificada",
            "N93 - Outros Sangramentos Anormais do Útero e da Vagina",
            "N93.0 - Sangramentos Pós-coito ou de Contato",
            "N93.8 - Outros Sangramentos Anormais Especificados do Útero e da Vagina",
            "N93.9 - Sangramento Anormal do Útero ou da Vagina, Não Especificado",
            "N94 - Dor e Outras Afecções Associadas Com os Órgãos Genitais Femininos e Com o Ciclo Menstrual",
            "N94.0 - Ovulação Dolorosa (Mittelschmerz)",
            "N94.1 - Dispareunia",
            "N94.2 - Vaginismo",
            "N94.3 - Síndrome de Tensão Pré-menstrual",
            "N94.4 - Dismenorréia Primária",
            "N94.5 - Dismenorréia Secundária",
            "N94.6 - Dismenorréia Não Especificada",
            "N94.8 - Outras Afecções Especificadas Associadas Com os Órgãos Genitais Femininos e Com o Ciclo Menstrual",
            "N94.9 - Afecções Não Especificadas Associadas Com os Órgãos Genitais Femininos e Com o Ciclo Menstrual",
            "N95 - Transtornos da Menopausa e da Perimenopausa",
            "N95.0 - Sangramento Pós-menopausa",
            "N95.1 - Estado da Menopausa e do Climatério Feminino",
            "N95.2 - Vaginite Atrófica Pós-menopausa",
            "N95.3 - Condições Associadas Com a Menopausa Artificial",
            "N95.8 - Outros Transtornos Especificados da Menopausa e da Perimenopausa",
            "N95.9 - Transtorno Não Especificado da Menopausa e da Perimenopausa",
            "N96 - Abortamento Habitual",
            "N97 - Infertilidade Feminina",
            "N97.0 - Infertilidade Feminina Associada à Anovulação",
            "N97.1 - Infertilidade Feminina de Origem Tubária",
            "N97.2 - Infertilidade Feminina de Origem Uterina",
            "N97.3 - Infertilidade Feminina de Origem Cervical",
            "N97.4 - Infertilidade Feminina Associada à Fatores do Parceiro",
            "N97.8 - Infertilidade Feminina de Outra Origem",
            "N97.9 - Infertilidade Feminina Não Especificada",
            "N98 - Complicações Associadas à Fecundação Artificial",
            "N98.0 - Infecção Associada à Inseminação Artificial",
            "N98.1 - Hiperestimulação Dos Ovários",
            "N98.2 - Complicações Relacionadas Com a Tentativa de Introdução do Óvulo Fecundado Artificialmente (in Vitro)",
            "N98.3 - Complicações Relacionadas Com a Tentativa de Transferência do Embrião",
            "N98.8 - Outras Complicações Associadas à Fecundação Artificial",
            "N98.9 - Complicações Não Especificadas Associadas à Fecundação Artificial",
            "N99 - Transtornos do Trato Geniturinário Pós-procedimentos Não Classificados em Outra Parte",
            "N99.0 - Insuficiência Renal Pós-procedimentos",
            "N99.1 - Estreitamento de Uretra Pós-procedimentos",
            "N99.2 - Aderências Pós-operatórias da Vagina",
            "N99.3 - Prolapso de Cúpula de Vagina Pós-histerectomia",
            "N99.4 - Aderências do Peritônio Pélvico Pós-procedimentos",
            "N99.5 - Mau Funcionamento de Abertura Externa (estoma) do Trato Urinário",
            "N99.8 - Outros Transtornos Pós-procedimentos do Aparelho Geniturinário",
            "N99.9 - Transtorno Pós-procedimento Não Especificado do Aparelho Geniturinário",
            "O00 - Gravidez Ectópica",
            "O00.0 - Gravidez Abdominal",
            "O00.1 - Gravidez Tubária",
            "O00.2 - Gravidez Ovariana",
            "O00.8 - Outras Formas de Gravidez Ectópica",
            "O00.9 - Gravidez Ectópica, Não Especificada",
            "O01 - Mola Hidatiforme",
            "O01.0 - Mola Hidatiforme Clássica",
            "O01.1 - Mola Hidatiforme Incompleta ou Parcial",
            "O01.9 - Mola Hidatiforme Não Especificada",
            "O02 - Outros Produtos Anormais da Concepção",
            "O02.0 - Ovo Claro e Mola Não-hidatiforme",
            "O02.1 - Aborto Retido",
            "O02.8 - Outros Produtos Anormais da Concepção Especificados",
            "O02.9 - Produto Anormal da Concepção, Não Especificado",
            "O03 - Aborto Espontâneo",
            "O03.0 - Aborto Espontâneo - Incompleto, Complicado Por Infecção do Trato Genital ou Dos Órgãos Pélvicos",
            "O03.1 - Aborto Espontâneo - Incompleto, Complicado Por Hemorragia Excessiva ou Tardia",
            "O03.2 - Aborto Espontâneo - Incompleto, Complicado Por Embolia",
            "O03.3 - Aborto Espontâneo - Incompleto, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O03.4 - Aborto Espontâneo - Incompleto, Sem Complicações",
            "O03.5 - Aborto Espontâneo - Completo ou Não Especificado, Complicado Por Infecções do Trato Genital ou Dos Órgãos Pélvicos",
            "O03.6 - Aborto Espontâneo - Completo ou Não Especificado, Complicado Por Hemorragia Excessiva ou Tardia",
            "O03.7 - Aborto Espontâneo - Completo ou Não Especificado, Complicado Por Embolia",
            "O03.8 - Aborto Espontâneo - Completo ou Não Especificado, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O03.9 - Aborto Espontâneo - Completo ou Não Especificado, Sem Complicações",
            "O04 - Aborto Por Razões Médicas e Legais",
            "O04.0 - Aborto Por Razões Médicas e Legais - Incompleto, Complicado Por Infecção do Trato Genital ou Dos Órgãos Pélvicos",
            "O04.1 - Aborto Por Razões Médicas e Legais - Incompleto, Complicado Por Hemorragia Excessiva ou Tardia",
            "O04.2 - Aborto Por Razões Médicas e Legais - Incompleto, Complicado Por Embolia",
            "O04.3 - Aborto Por Razões Médicas e Legais - Incompleto, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O04.4 - Aborto Por Razões Médicas e Legais - Incompleto, Sem Complicações",
            "O04.5 - Aborto Por Razões Médicas e Legais - Completo ou Não Especificado, Complicado Por Infecções do Trato Genital ou Dos Órgãos Pélvicos",
            "O04.6 - Aborto Por Razões Médicas e Legais - Completo ou Não Especificado, Complicado Por Hemorragia Excessiva ou Tardia",
            "O04.7 - Aborto Por Razões Médicas e Legais - Completo ou Não Especificado, Complicado Por Embolia",
            "O04.8 - Aborto Por Razões Médicas e Legais - Completo ou Não Especificado, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O04.9 - Aborto Por Razões Médicas e Legais - Completo ou Não Especificado, Sem Complicações",
            "O05 - Outros Tipos de Aborto",
            "O05.0 - Outros Tipos de Aborto - Incompleto, Complicado Por Infecção do Trato Genital ou Dos Órgãos Pélvicos",
            "O05.1 - Outros Tipos de Aborto - Incompleto, Complicado Por Hemorragia Excessiva ou Tardia",
            "O05.2 - Outros Tipos de Aborto - Incompleto, Complicado Por Embolia",
            "O05.3 - Outros Tipos de Aborto - Incompleto, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O05.4 - Outros Tipos de Aborto - Incompleto, Sem Complicações",
            "O05.5 - Outros Tipos de Aborto - Completo ou Não Especificado, Complicado Por Infecções do Trato Genital ou Dos Órgãos Pélvicos",
            "O05.6 - Outros Tipos de Aborto - Completo ou Não Especificado, Complicado Por Hemorragia Excessiva ou Tardia",
            "O05.7 - Outros Tipos de Aborto - Completo ou Não Especificado, Complicado Por Embolia",
            "O05.8 - Outros Tipos de Aborto - Completo ou Não Especificado, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O05.9 - Outros Tipos de Aborto - Completo ou Não Especificado, Sem Complicações",
            "O06 - Aborto Não Especificado",
            "O06.0 - Aborto Não Especificado - Incompleto, Complicado Por Infecção do Trato Genital ou Dos Órgãos Pélvicos",
            "O06.1 - Aborto Não Especificado - Incompleto, Complicado Por Hemorragia Excessiva ou Tardia",
            "O06.2 - Aborto Não Especificado - Incompleto, Complicado Por Embolia",
            "O06.3 - Aborto Não Especificado - Incompleto, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O06.4 - Aborto Não Especificado - Incompleto, Sem Complicações",
            "O06.5 - Aborto Não Especificado - Completo ou Não Especificado, Complicado Por Infecções do Trato Genital ou Dos Órgãos Pélvicos",
            "O06.6 - Aborto Não Especificado - Completo ou Não Especificado, Complicado Por Hemorragia Excessiva ou Tardia",
            "O06.7 - Aborto Não Especificado - Completo ou Não Especificado, Complicado Por Embolia",
            "O06.8 - Aborto Não Especificado - Completo ou Não Especificado, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O06.9 - Aborto Não Especificado - Completo ou Não Especificado, Sem Complicações",
            "O07 - Falha de Tentativa de Aborto",
            "O07.0 - Falha de Aborto Provocado Por Razões Médicas, Complicado Por Infecção do Trato Genital ou Dos Órgãos Pélvicos",
            "O07.1 - Falha de Aborto Provocado Por Razões Médicas, Complicado Por Hemorragia Tardia ou Excessiva",
            "O07.2 - Falha de Aborto Provocado Por Razões Médicas, Complicado Por Embolia",
            "O07.3 - Falha de Aborto Provocado Por Razões Médicas, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O07.4 - Falha de Aborto Provocado Por Razões Médicas, Sem Complicações",
            "O07.5 - Outras Formas, e as Não Especificadas, de Falha na Provocação de Aborto, Complicadas Por Infecção do Trato Genital e Por Infecção Dos Órgãos Pélvicos",
            "O07.6 - Outras Formas, e as Não Especificadas, de Falha na Provocação de Aborto, Complicadas Por Hemorragia Tardia ou Excessiva",
            "O07.7 - Outras Formas, e as Não Especificadas, de Falha na Provocação de Aborto, Complicadas Por Embolia",
            "O07.8 - Outras Formas, e as Não Especificadas, de Falha na Provocação de Aborto, Com Outras Complicações ou Com Complicações Não Especificadas",
            "O07.9 - Outras Formas, e as Não Especificadas, de Falha na Provocação de Aborto, Sem Complicação",
            "O08 - Complicações Conseqüentes a Aborto e Gravidez Ectópica ou Molar",
            "O08.0 - Infecção do Trato Genital e Dos Órgãos Pélvicos Conseqüente a Aborto e Gravidez Ectópica e Molar",
            "O08.1 - Hemorragia Tardia ou Excessiva Conseqüente a Aborto e a Gravidez Ectópica e Molar",
            "O08.2 - Embolia Conseqüente a Aborto e a Gravidez Ectópica e Molar",
            "O08.3 - Choque Conseqüente a Aborto e a Gravidez Ectópica e Molar",
            "O08.4 - Insuficiência Renal Conseqüente a Aborto e a Gravidez Ectópica e Molar",
            "O08.5 - Distúrbios Metabólicos Conseqüentes a Aborto e a Gravidez Ectópica e Molar",
            "O08.6 - Lesão a Órgãos e a Tecidos Pélvicos Conseqüentes a Aborto e a Gravidez Ectópica e Molar",
            "O08.7 - Outras Complicações Venosas Conseqüentes a Aborto e a Gravidez Ectópica e Molar",
            "O08.8 - Outras Complicações Conseqüentes a Aborto e a Gravidez Ectópica e Molar",
            "O08.9 - Complicações Conseqüentes a Aborto e Gravidez Ectópica e Molar, Não Especificadas",
            "O10 - Hipertensão Pré-existente Complicando a Gravidez, o Parto e o Puerpério",
            "O10.0 - Hipertensão Essencial Pré-existente Complicando a Gravidez, o Parto e o Puerpério",
            "O10.1 - Doença Cardíaca Hipertensiva Pré-existente Complicando a Gravidez, o Parto e o Puerpério",
            "O10.2 - Doença Renal Hipertensiva Pré-existente Complicando a Gravidez, o Parto e o Puerpério",
            "O10.3 - Doença Cardíaca e Renal Hipertensiva Pré-existente Complicando a Gravidez, o Parto e o Puerpério",
            "O10.4 - Hipertensão Secundária Pré-existente Complicando a Gravidez, o Parto e o Puerpério",
            "O10.9 - Hipertensão Pré-existente Não Especificada, Complicando a Gravidez, o Parto e o Puerpério",
            "O11 - Distúrbio Hipertensivo Pré-existente Com Proteinúria Superposta",
            "O12 - Edema e Proteinúria Gestacionais (induzidos Pela Gravidez), Sem Hipertensão",
            "O12.0 - Edema Gestacional",
            "O12.1 - Proteinúria Gestacional",
            "O12.2 - Edema Gestacional Com Proteinúria",
            "O13 - Hipertensão Gestacional (induzida Pela Gravidez) Sem Proteinúria Significativa",
            "O14 - Hipertensão Gestacional (induzida Pela Gravidez) Com Proteinúria Significativa",
            "O14.0 - Pré-eclâmpsia Moderada",
            "O14.1 - Pré-eclâmpsia Grave",
            "O14.9 - Pré-eclâmpsia Não Especificada",
            "O15 - Eclâmpsia",
            "O15.0 - Eclâmpsia na Gravidez",
            "O15.1 - Eclâmpsia no Trabalho de Parto",
            "O15.2 - Eclâmpsia no Puerpério",
            "O15.9 - Eclâmpsia Não Especificada Quanto ao Período",
            "O16 - Hipertensão Materna Não Especificada",
            "O20 - Hemorragia do Início da Gravidez",
            "O20.0 - Ameaça de Aborto",
            "O20.8 - Outras Hemorragias do Início da Gravidez",
            "O20.9 - Hemorragia do Início da Gravidez, Não Especificada",
            "O21 - Vômitos Excessivos na Gravidez",
            "O21.0 - Hiperêmese Gravídica Leve",
            "O21.1 - Hiperêmese Gravídica Com Distúrbio Metabólico",
            "O21.2 - Vômitos Tardios da Gravidez",
            "O21.8 - Outras Formas de Vômitos Complicando a Gravidez",
            "O21.9 - Vômitos da Gravidez, Não Especificados",
            "O22 - Complicações Venosas na Gravidez",
            "O22.0 - Varizes Dos Membros Inferiores na Gravidez",
            "O22.1 - Varizes Genitais na Gravidez",
            "O22.2 - Tromboflebite Superficial na Gravidez",
            "O22.3 - Flebotrombose Profunda na Gravidez",
            "O22.4 - Hemorróidas na Gravidez",
            "O22.5 - Trombose Venosa Cerebral na Gravidez",
            "O22.8 - Outras Complicações Venosas na Gravidez",
            "O22.9 - Complicações Venosas na Gravidez, Não Especificadas",
            "O23 - Infecções do Trato Geniturinário na Gravidez",
            "O23.0 - Infecções do Rim na Gravidez",
            "O23.1 - Infecções da Bexiga na Gravidez",
            "O23.2 - Infecções da Uretra na Gravidez",
            "O23.3 - Infecções de Outras Partes do Trato Urinário na Gravidez",
            "O23.4 - Infecção Não Especificada do Trato Urinário na Gravidez",
            "O23.5 - Infecções do Trato Genital na Gravidez",
            "O23.9 - Outras Infecções e as Não Especificadas do Trato Urinário na Gravidez",
            "O24 - Diabetes Mellitus na Gravidez",
            "O24.0 - Diabetes Mellitus Pré-existente, Insulino-dependente",
            "O24.1 - Diabetes Mellitus Pré-existente, Não-insulino-dependente",
            "O24.2 - Diabetes Mellitus Pré-existente, Relacionado Com a Desnutrição",
            "O24.3 - Diabetes Mellitus Pré-existente, Não Especificado",
            "O24.4 - Diabetes Mellitus Que Surge Durante a Gravidez",
            "O24.9 - Diabetes Mellitus na Gravidez, Não Especificado",
            "O25 - Desnutrição na Gravidez",
            "O26 - Assistência Materna Por Outras Complicações Ligadas Predominantemente à Gravidez",
            "O26.0 - Ganho Excessivo de Peso na Gravidez",
            "O26.1 - Ganho de Peso Insuficiente na Gravidez",
            "O26.2 - Assistência à Gravidez Por Motivo de Abortamento Habitual",
            "O26.3 - Retenção de Dispositivo Intra-uterino Contraceptivo (DIU) na Gravidez",
            "O26.4 - Herpes Gestacional",
            "O26.5 - Síndrome da Hipotensão Materna",
            "O26.6 - Transtornos do Fígado na Gravidez, no Parto e no Puerpério",
            "O26.7 - Subluxação da Sínfise (púbica) na Gravidez, Parto e Puerpério",
            "O26.8 - Outras Afecções Especificadas, Ligadas a Gravidez",
            "O26.9 - Afecções Ligadas a Gravidez, Não Especificadas",
            "O28 - Achados Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.0 - Achados Hematológicos Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.1 - Achados Bioquímicos Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.2 - Achados Citológicos Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.3 - Achados Ultrassonográficos Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.4 - Achados Radiológicos Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.5 - Achados Cromossômicos e Genéticos Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.8 - Outros Achados Anormais do Rastreamento(screening) Antenatal da Mãe",
            "O28.9 - Achados Anormais do Rastreamento(screening) Antenatal da Mãe, Não Especificados",
            "O29 - Complicações de Anestesia Administrada Durante a Gravidez",
            "O29.0 - Complicações Pulmonares de Anestesia Administrada Durante a Gravidez",
            "O29.1 - Complicações Cardíacas de Anestesia Administrada Durante a Gravidez",
            "O29.2 - Complicações Relativas ao Sistema Nervoso Central Devidas a Anestesia Administrada Durante a Gravidez",
            "O29.3 - Reação Tóxica Causada Por Anestesia Local Administrada Durante a Gravidez",
            "O29.4 - Cefaléia Causada Por Anestesia Raquidiana ou Peridural Administradas Durante a Gravidez",
            "O29.5 - Outras Complicações de Anestesia Raquidiana ou Peridural Administradas Durante a Gravidez",
            "O29.6 - Falha ou Dificuldade na Entubação Durante a Gravidez",
            "O29.8 - Outras Complicações de Anestesia Durante a Gravidez",
            "O29.9 - Complicações de Anestesia Durante a Gravidez, Não Especificadas",
            "O30 - Gestação Múltipla",
            "O30.0 - Gravidez Dupla",
            "O30.1 - Gravidez Trigemelar",
            "O30.2 - Gravidez Quadrigemelar",
            "O30.8 - Outras Gestações Múltiplas",
            "O30.9 - Gestação Múltipla, Não Especificada",
            "O31 - Complicações Específicas de Gestação Múltipla",
            "O31.0 - Feto Papiráceo",
            "O31.1 - Continuação da Gravidez Após Aborto de um ou Mais Fetos",
            "O31.2 - Continuação da Gravidez Após a Morte Intra-uterina de um ou Mais Fetos",
            "O31.8 - Outras Complicações Específicas de Gestação Múltipla",
            "O32 - Assistência Prestada à Mãe Por Motivo de Apresentação Anormal, Conhecida ou Suspeitada, do Feto",
            "O32.0 - Assistência Prestada à Mãe Por Posição Fetal Instável",
            "O32.1 - Assistência Prestada à Mãe Por Apresentação Pélvica",
            "O32.2 - Assistência Prestada à Mãe Por Posição Fetal Transversa e Oblíqua",
            "O32.3 - Assistência Prestada à Mãe Por Apresentação de Face, Mento e Fronte",
            "O32.4 - Assistência Prestada à Mãe Por Polo Cefálico Alto em Gestação a Termo",
            "O32.5 - Assistência Prestada à Mãe Por Gestação Múltipla Com Apresentação Anormal de um ou Mais Fetos",
            "O32.6 - Assistência Prestada à Mãe Por Apresentação Composta",
            "O32.8 - Assistência Prestada à Mãe Por Outras Formas de Apresentação Anormal do Feto",
            "O32.9 - Assistência Prestada à Mãe Por Apresentação Anormal do Feto, Não Especificada",
            "O33 - Assistência Prestada à Mãe Por Uma Desproporção Conhecida ou Suspeita",
            "O33.0 - Assistência Prestada à Mãe Por Uma Desproporção Devida à Deformidade da Pelve Óssea Materna",
            "O33.1 - Assistência Prestada à Mãe Por Uma Desproporção Devida à Contração Geral da Pelve",
            "O33.2 - Assistência Prestada à Mãe Por Uma Desproporção Devida à Contração do Estreito Superior da Pelve",
            "O33.3 - Assistência Prestada à Mãe Por Uma Desproporção Devida à Contração do Estreito Inferior da Pelve",
            "O33.4 - Assistência Prestada à Mãe Por Uma Desproporção de Origem Mista, Materna e Fetal",
            "O33.5 - Assistência Prestada à Mãe Por Uma Desproporção Devida a Feto Excepcionalmente Grande",
            "O33.6 - Assistência Prestada à Mãe Por Uma Desproporção Devida a Feto Hidrocefálico",
            "O33.7 - Assistência Prestada à Mãe Por Uma Desproporção Devida a Outras Deformidades Fetais",
            "O33.8 - Assistência Prestada à Mãe Por Uma Desproporção de Outra Origem",
            "O33.9 - Assistência Prestada à Mãe Por Uma Desproporção, Não Especificada",
            "O34 - Assistência Prestada à Mãe Por Anormalidade, Conhecida ou Suspeita, Dos Órgãos Pélvicos Maternos",
            "O34.0 - Assistência à Mãe Por Malformação Congênita do Útero",
            "O34.1 - Assistência Prestada à Mãe Por Tumor do Corpo do Útero",
            "O34.2 - Assistência Prestada à Mãe Por Cicatriz Uterina Devida a Uma Cirurgia Anterior",
            "O34.3 - Assistência Prestada à Mãe Por Incompetência Cervical",
            "O34.4 - Assistência Prestada à Mãe Por Outras Anormalidades do Colo do Útero",
            "O34.5 - Assistência Prestada à Mãe Por Outras Anormalidades do Útero Gravídico",
            "O34.6 - Assistência Prestada à Mãe Por Anormalidade da Vagina",
            "O34.7 - Assistência Prestada à Mãe Por Anormalidade da Vulva e do Períneo",
            "O34.8 - Assistência Prestada à Mãe Por Outras Anormalidades Dos Órgãos Pélvicos",
            "O34.9 - Assistência Prestada à Mãe Por Anormalidade de Órgãos Pélvicos, Não Especificada",
            "O35 - Assistência Prestada à Mãe Por Anormalidade e Lesão Fetais, Conhecidas ou Suspeitadas",
            "O35.0 - Assistência Prestada à Mãe Por Malformação do Sistema Nervoso Central do Feto (suspeitada)",
            "O35.1 - Assistência Prestada à Mãe Por Anormalidade Cromossômica (suspeitada) do Feto",
            "O35.2 - Assistência Prestada à Mãe Por Doença Hereditária (suspeitada) do Feto",
            "O35.3 - Assistência Prestada à Mãe Por Lesão Fetal (suspeitada) Causada Por Doença Viral Materna",
            "O35.4 - Assistência Prestada à Mãe Por Lesão (suspeitada) Causada ao Feto Por Alcoolismo Materno",
            "O35.5 - Assistência Prestada à Mãe Por Lesão Fetal (suspeitada) Causada Por Drogas",
            "O35.6 - Assistência Prestada à Mãe Por Lesão Fetal (suspeitada) Causada Por Radiação",
            "O35.7 - Assistência Prestada à Mãe Por Lesão Fetal (suspeitada) Causada Por Outros Procedimentos Médicos",
            "O35.8 - Assistência Prestada à Mãe Por Outras Anormalidades ou Lesões Fetais (suspeitadas)",
            "O35.9 - Assistência Prestada à Mãe Por Lesão ou Anormalidade Fetais (suspeitadas), Não Especificadas",
            "O36 - Assistência Prestada à Mãe Por Outros Problemas Fetais Conhecidos ou Suspeitados",
            "O36.0 - Assistência Prestada à Mãe Por Isoimunização Rh",
            "O36.1 - Assistência Prestada à Mãe Por Outros Tipos de Isoimunização",
            "O36.2 - Assistência Prestada à Mãe Por Hidropsia Fetal",
            "O36.3 - Assistência Prestada à Mãe Por Sinais de Hipóxia Fetal",
            "O36.4 - Assistência Prestada à Mãe Por Morte Intra-uterina",
            "O36.5 - Assistência Prestada à Mãe Por Insuficiência de Crescimento Fetal",
            "O36.6 - Assistência Prestada à Mãe Por Crescimento Fetal Excessivo",
            "O36.7 - Assistência Prestada à Mãe Por Feto Viável em Gravidez Abdominal",
            "O36.8 - Assistência Prestada à Mãe Por Outros Problemas Fetais Especificados",
            "O36.9 - Assistência Prestada à Mãe Por Problema Fetal Não Especificado",
            "O40 - Polihidrâmnio",
            "O41 - Outros Transtornos Das Membranas e do Líquido Amniótico",
            "O41.0 - Oligohidrâmnio",
            "O41.1 - Infecção do Saco Amniótico e Das Membranas",
            "O41.8 - Outros Transtornos Especificados do Líquido Amniótico e Das Membranas",
            "O41.9 - Transtornos do Líquido Amniótico e Das Membranas Não Especificados",
            "O42 - Ruptura Prematura de Membranas",
            "O42.0 - Ruptura Prematura de Membranas, Com Início do Trabalho de Parto Dentro de 24 Horas",
            "O42.1 - Ruptura Prematura de Membranas, Com Início do Trabalho de Parto Depois Das Primeiras 24 Horas",
            "O42.2 - Ruptura Prematura de Membranas, Com Trabalho de Parto Retardado Por Terapêutica",
            "O42.9 - Ruptura Prematura de Membranas, Não Especificada",
            "O43 - Transtornos da Placenta",
            "O43.0 - Síndromes de Transfusão Placentária",
            "O43.1 - Malformação da Placenta",
            "O43.8 - Outros Transtornos da Placenta",
            "O43.9 - Transtorno da Placenta, Não Especificado",
            "O44 - Placenta Prévia",
            "O44.0 - Placenta Prévia Especificada Como Sem Hemorragia",
            "O44.1 - Placenta Prévia Com Hemorragia",
            "O45 - Descolamento Prematuro da Placenta (abruptio Placentae)",
            "O45.0 - Descolamento Prematuro da Placenta Com Deficiência de Coagulação",
            "O45.8 - Outro Descolamento Prematuro da Placenta",
            "O45.9 - Descolamento Prematuro da Placenta, Não Especificado",
            "O46 - Hemorragia Anteparto Não Classificada em Outra Parte",
            "O46.0 - Hemorragia Anteparto Com Deficiência de Coagulação",
            "O46.8 - Outra Forma de Hemorragia Anteparto",
            "O46.9 - Hemorragia Anteparto, Não Especificada",
            "O47 - Falso Trabalho de Parto",
            "O47.0 - Falso Trabalho de Parto Antes de se Completarem 37 Semanas de Gestação",
            "O47.1 - Falso Trabalho de Parto na 37ª Semana Completa ou Depois Dela",
            "O47.9 - Falso Trabalho de Parto, Não Especificado",
            "O48 - Gravidez Prolongada",
            "O60 - Trabalho de Parto Pré-termo",
            "O60.0 - Trabalho de Parto Pré-termo Sem Parto",
            "O60.1 - Trabalho de Parto Pré-termo Com Parto Pré-termo",
            "O60.2 - Trabalho de Parto Pré-termo Com Parto a Termo",
            "O61 - Falha na Indução do Trabalho de Parto",
            "O61.0 - Falha na Indução Médica do Trabalho de Parto",
            "O61.1 - Falha na Indução Instrumental do Trabalho de Parto",
            "O61.8 - Outras Formas de Falha na Indução do Trabalho de Parto",
            "O61.9 - Falha na Indução do Trabalho de Parto, Não Especificada",
            "O62 - Anormalidades da Contração Uterina",
            "O62.0 - Contrações Iniciais Inadequadas",
            "O62.1 - Inércia Uterina Secundária",
            "O62.2 - Outras Formas de Inércia Uterina",
            "O62.3 - Trabalho de Parto Precipitado",
            "O62.4 - Contrações Uterinas Hipertônicas, Incoordenadas ou Prolongadas",
            "O62.8 - Outras Anormalidades da Contração Uterina",
            "O62.9 - Anormalidades da Contração Uterina, Não Especificadas",
            "O63 - Trabalho de Parto Prolongado",
            "O63.0 - Prolongamento do Primeiro Período (dilatação)",
            "O63.1 - Prolongamento do Segundo Período (expulsão)",
            "O63.2 - Parto Retardado do Segundo Gêmeo, do Terceiro Gêmeo Etc",
            "O63.9 - Trabalho de Parto Prolongado, Não Especificado",
            "O64 - Obstrução do Trabalho de Parto Devida à Má-posição ou Má-apresentação do Feto",
            "O64.0 - Obstrução do Trabalho de Parto Devida à Rotação Incompleta do Polo Cefálico Fetal",
            "O64.1 - Obstrução do Trabalho de Parto Devida à Apresentação Pélvica",
            "O64.2 - Obstrução do Trabalho de Parto Devida à Apresentação de Face",
            "O64.3 - Obstrução do Trabalho de Parto Devida à Apresentação de Fronte",
            "O64.4 - Obstrução do Trabalho de Parto Devida à Apresentação de Ombro",
            "O64.5 - Obstrução do Trabalho de Parto Devida à Apresentação Complexa",
            "O64.8 - Obstrução do Trabalho de Parto Devida a Outras Formas de Má-apresentação e Má-posição",
            "O64.9 - Obstrução do Trabalho de Parto Devida a Má-posição ou Má-apresentação, Não Especificadas",
            "O65 - Obstrução do Trabalho de Parto Devida a Anormalidade Pélvica da Mãe",
            "O65.0 - Obstrução do Trabalho de Parto Devida a Deformidade Pélvica",
            "O65.1 - Obstrução do Trabalho de Parto Devida à Contração Geral da Pelve",
            "O65.2 - Obstrução do Trabalho de Parto Devida à Contração do Estreito Superior",
            "O65.3 - Obstrução do Trabalho de Parto Devida à Contração do Estreito Inferior ou da Cavidade Mediana",
            "O65.4 - Obstrução do Trabalho de Parto Devida a Desproporção Feto-pélvica, Não Especificada",
            "O65.5 - Obstrução do Trabalho de Parto Devida a Anormalidade Dos Órgãos Pélvicos Maternos",
            "O65.8 - Obstrução do Trabalho de Parto Devida a Outras Anormalidades Pélvicas Maternas",
            "O65.9 - Obstrução do Trabalho de Parto Devida a Anormalidades Pélvicas Maternas Não Especificadas",
            "O66 - Outras Formas de Obstrução do Trabalho de Parto",
            "O66.0 - Obstrução do Trabalho de Parto Devido a Distócia de Ombro",
            "O66.1 - Obstrução do Trabalho de Parto Por Colisão de Gêmeos",
            "O66.2 - Obstrução do Trabalho de Parto Por Feto Excepcionalmente Grande",
            "O66.3 - Obstrução do Trabalho de Parto Devido a Outras Anormalidades do Feto",
            "O66.4 - Falha de Tentativa de Trabalho de Parto, Não Especificada",
            "O66.5 - Falha de Aplicação de Fórceps e Vácuo-extrator, Não Especificada",
            "O66.8 - Outras Formas de Obstrução do Trabalho de Parto, Especificadas",
            "O66.9 - Obstrução do Trabalho de Parto, Não Especificado",
            "O67 - Trabalho de Parto e Parto Complicados Por Hemorragia Intraparto Não Classificados em Outra Parte",
            "O67.0 - Hemorragia Intraparto Com Deficiência de Coagulação",
            "O67.8 - Outras Hemorragias Intraparto",
            "O67.9 - Hemorragia Intraparto Não Especificada",
            "O68 - Trabalho de Parto e Parto Complicados Por Sofrimento Fetal",
            "O68.0 - Trabalho de Parto e Parto Complicados Por Anormalidade da Freqüência Cardíaca Fetal",
            "O68.1 - Trabalho de Parto e Parto Complicados Por Mecônio no Líquido Amniótico",
            "O68.2 - Trabalho de Parto e Parto Complicados Por Anormalidade na Freqüência Cardíaca Fetal Com Mecônio no Líquido Amniótico",
            "O68.3 - Trabalho de Parto e Parto Complicados Por Evidências Bioquímicas de Sofrimento Fetal",
            "O68.8 - Trabalho de Parto e Parto Complicados Por Outras Evidências de Sofrimento Fetal",
            "O68.9 - Trabalho de Parto e Parto Complicados Por Sofrimento Fetal, Não Especificado",
            "O69 - Trabalho de Parto e Parto Complicados Por Anormalidade do Cordão Umbilical",
            "O69.0 - Trabalho de Parto e Parto Complicados Por Prolapso do Cordão",
            "O69.1 - Trabalho de Parto e Parto Complicados Por Circular de Cordão, Com Compressão",
            "O69.2 - Trabalho de Parto e Parto Complicados Por Outros Enovelamentos do Cordão",
            "O69.3 - Trabalho de Parto e Parto Complicados Por Cordão Curto",
            "O69.4 - Trabalho de Parto e Parto Complicados Por Vasa Praevia",
            "O69.5 - Trabalho de Parto e Parto Complicados Por Lesão Vascular do Cordão",
            "O69.8 - Trabalho de Parto e Parto Complicados Por Outras Complicações do Cordão",
            "O69.9 - Trabalho de Parto e Parto Complicados Por Complicações do Cordão, Não Especificadas",
            "O70 - Laceração do Períneo Durante o Parto",
            "O70.0 - Laceração de Períneo de Primeiro Grau Durante o Parto",
            "O70.1 - Laceração de Períneo de Segundo Grau Durante o Parto",
            "O70.2 - Laceração de Períneo de Terceiro Grau Durante o Parto",
            "O70.3 - Laceração de Períneo de Quarto Grau Durante o Parto",
            "O70.9 - Laceração de Períneo Durante o Parto, Não Especificada",
            "O71 - Outros Traumatismos Obstétricos",
            "O71.0 - Ruptura do Útero Antes do Início do Trabalho de Parto",
            "O71.1 - Ruptura do Útero Durante o Trabalho de Parto",
            "O71.2 - Inversão do Útero Pós-parto",
            "O71.3 - Laceração Obstétrica do Colo do Útero",
            "O71.4 - Laceração Vaginal Alta Isolada, de Origem Obstétrica",
            "O71.5 - Outras Lesões Obstétricas Aos Órgãos Pélvicos",
            "O71.6 - Lesão Obstétrica Das Articulações e Dos Ligamentos Pélvicos",
            "O71.7 - Hematoma Obstétrico da Pelve",
            "O71.8 - Outros Traumatismos Obstétricos Especificados",
            "O71.9 - Traumatismo Obstétrico, Não Especificado",
            "O72 - Hemorragia Pós-parto",
            "O72.0 - Hemorragia do Terceiro Estágio",
            "O72.1 - Outras Hemorragias do Pós-parto Imediato",
            "O72.2 - Hemorragias Pós-parto, Tardias e Secundárias",
            "O72.3 - Deficiências de Coagulação Pós-parto",
            "O73 - Retenção da Placenta e Das Membranas, Sem Hemorragias",
            "O73.0 - Retenção da Placenta Sem Hemorragia",
            "O73.1 - Retenção de Partes da Placenta ou Das Membranas, Sem Hemorragia",
            "O74 - Complicações de Anestesia Durante o Trabalho de Parto e o Parto",
            "O74.0 - Pneumonite Aspirativa Devida a Anestesia Durante o Trabalho de Parto e o Parto",
            "O74.1 - Outras Complicações Pulmonares Devidas a Anestesia Durante o Trabalho de Parto e no Parto",
            "O74.2 - Complicações Cardíacas Devidas a Anestesia Durante o Trabalho de Parto e no Parto",
            "O74.3 - Complicações do Sistema Nervoso Central Devidas a Anestesia Durante o Trabalho de Parto e no Parto",
            "O74.4 - Reação Tóxica a Anestesia Local Durante Trabalho de Parto e o Parto",
            "O74.5 - Cefaléia Pós-anestesia Raquidiana e Peridural, Durante o Trabalho de Parto e Parto",
            "O74.6 - Outras Complicações de Anestesia Raquidiana ou Peridural, Durante o Trabalho de Parto e Parto",
            "O74.7 - Falha ou Dificuldade de Entubação Durante o Trabalho de Parto e Parto",
            "O74.8 - Outras Complicações de Anestesia Durante o Trabalho de Parto e Parto",
            "O74.9 - Complicações de Anestesia Durante o Trabalho de Parto e Parto, Não Especificadas",
            "O75 - Outras Complicações do Trabalho de Parto e do Parto Não Classificadas em Outra Parte",
            "O75.0 - Sofrimento Materno Durante o Trabalho de Parto e o Parto",
            "O75.1 - Choque Durante ou Subseqüente ao Trabalho de Parto e o Parto",
            "O75.2 - Febre Durante o Trabalho de Parto Não Classificada em Outra Parte",
            "O75.3 - Outras Infecções Durante o Trabalho de Parto",
            "O75.4 - Outras Complicações de Procedimentos ou de Cirurgia Obstétrica",
            "O75.5 - Demora do Parto Após Ruptura Artificial Das Membranas",
            "O75.6 - Demora do Parto Após Ruptura Espontânea ou Não Especificada Das Membranas",
            "O75.7 - Parto Vaginal Subseqüente a Cesariana Anterior",
            "O75.8 - Outras Complicações Especificadas do Trabalho de Parto e do Parto",
            "O75.9 - Complicações do Trabalho de Parto e do Parto, Não Especificadas",
            "O80 - Parto Único Espontâneo",
            "O80.0 - Parto Espontâneo Cefálico",
            "O80.1 - Parto Espontâneo Pélvico",
            "O80.8 - Outros Tipos de Parto Único Espontâneo",
            "O80.9 - Parto Único Espontâneo, Não Especificado",
            "O81 - Parto Único Por Fórceps ou Vácuo-extrator",
            "O81.0 - Parto Por Fórceps Baixo",
            "O81.1 - Parto Por Fórceps da Cavidade Mediana",
            "O81.2 - Fórceps da Cavidade Mediana Com Rotação",
            "O81.3 - Outros Tipos de Parto Por Fórceps e os Não Especificados",
            "O81.4 - Parto Por Vácuo-extrator",
            "O81.5 - Parto Por Combinação de Fórceps e Vácuo-extrator",
            "O82 - Parto Único Por Cesariana",
            "O82.0 - Parto Por Cesariana Eletiva",
            "O82.1 - Parto Por Cesariana de Emergência",
            "O82.2 - Parto Por Cesariana Para Histerectomia",
            "O82.8 - Outros Tipos de Parto Único Por Cesariana",
            "O82.9 - Parto Por Cesariana, Não Especificada",
            "O83 - Outros Tipos de Parto Único Assistido",
            "O83.0 - Extração Pélvica",
            "O83.1 - Outros Tipos de Parto Pélvico Assistido",
            "O83.2 - Outras Formas de Parto Com o Auxílio de Manipulação",
            "O83.3 - Parto de Gravidez Intra-abdominal Com Feto Viável",
            "O83.4 - Cirurgia Destrutiva Para Facilitar o Parto",
            "O83.8 - Outros Tipos Especificados de Parto Único Assistido",
            "O83.9 - Parto Único Assistido, Não Especificado",
            "O84 - Parto Múltiplo",
            "O84.0 - Parto Múltiplo, Todos Espontâneos",
            "O84.1 - Parto Múltiplo, Todos Por Fórceps ou Vácuo-extrator",
            "O84.2 - Parto Múltiplo, Todos Por Cesariana",
            "O84.8 - Outro Tipo de Parto Múltiplo",
            "O84.9 - Parto Múltiplo, Não Especificado",
            "O85 - Infecção Puerperal",
            "O86 - Outras Infecções Puerperais",
            "O86.0 - Infecção da Incisão Cirúrgica de Origem Obstétrica",
            "O86.1 - Outras Infecções Dos Órgãos Genitais Subseqüentes ao Parto",
            "O86.2 - Infecção Das Vias Urinárias Subseqüentes ao Parto",
            "O86.3 - Outras Infecções Das Vias Genitourinárias Subseqüentes ao Parto",
            "O86.4 - Febre de Origem Desconhecida Subseqüente ao Parto",
            "O86.8 - Outras Infecções Puerperais Especificadas",
            "O87 - Complicações Venosas no Puerpério",
            "O87.0 - Tromboflebite Superficial no Puerpério",
            "O87.1 - Tromboflebite Profunda no Puerpério",
            "O87.2 - Hemorróidas no Puerpério",
            "O87.3 - Trombose Venosa Cerebral no Puerpério",
            "O87.8 - Outras Complicações Venosas no Puerpério",
            "O87.9 - Complicações Venosas no Puerpério, Não Especificadas",
            "O88 - Embolia de Origem Obstétrica",
            "O88.0 - Embolia Gasosa de Origem Obstétrica",
            "O88.1 - Embolia Amniótica",
            "O88.2 - Embolia Obstétrica Por Coágulo de Sangue",
            "O88.3 - Embolia Séptica ou Piêmica de Origem Obstétrica",
            "O88.8 - Outras Embolias de Origem Obstétrica",
            "O89 - Complicações da Anestesia Administrada Durante o Puerpério",
            "O89.0 - Complicações Pulmonares da Anestesia Durante o Puerpério",
            "O89.1 - Complicações Cardíacas Devidas a Anestesia Durante o Puerpério",
            "O89.2 - Complicações Relativas ao Sistema Nervoso Central Devidas a Anestesia Durante o Puerpério",
            "O89.3 - Reação Tóxica a Uma Anestesia Local Durante o Puerpério",
            "O89.4 - Cefaléia Provocada Por Uma Anestesia Raquidiana ou Peridural, Durante o Puerpério",
            "O89.5 - Outras Complicações de Anestesia Raquidiana ou Peridural, Durante o Puerpério",
            "O89.6 - Falha na ou Dificuldade de Entubação, Durante o Puerpério",
            "O89.8 - Outras Complicações da Anestesia Durante o Puerpério",
            "O89.9 - Complicação Devida a Anestesia, Durante o Puerpério, Não Especificada",
            "O90 - Complicações do Puerpério Não Classificadas em Outra Parte",
            "O90.0 - Ruptura da Incisão de Cesariana",
            "O90.1 - Ruptura da Incisão Obstétrica, no Períneo",
            "O90.2 - Hematoma da Incisão Obstétrica",
            "O90.3 - Cardiomiopatia no Puerpério",
            "O90.4 - Insuficiência Renal Aguda do Pós-parto",
            "O90.5 - Tireoidite do Pós-parto",
            "O90.8 - Outras Complicações do Puerpério, Não Classificadas em Outra Parte",
            "O90.9 - Complicação do Puerpério Não Especificada",
            "O91 - Infecções Mamárias Associadas ao Parto",
            "O91.0 - Infecção do Mamilo Associada ao Parto",
            "O91.1 - Abscesso da Mama Associada ao Parto",
            "O91.2 - Mastite Não Purulenta Associada ao Parto",
            "O92 - Outras Afecções da Mama e da Lactação Associadas ao Parto",
            "O92.0 - Mamilo Retraído Associado ao Parto",
            "O92.1 - Fissuras do Mamilo Associadas ao Parto",
            "O92.2 - Outras Afecções da Mama, e as Não Especificadas, Associadas ao Parto",
            "O92.3 - Agalactia",
            "O92.4 - Hipogalactia",
            "O92.5 - Suspensão da Lactação",
            "O92.6 - Galactorréia",
            "O92.7 - Outros Distúrbios da Lactação e os Não Especificados",
            "O94 - Seqüelas de Complicações da Gravidez, Parto e Puerpério",
            "O95 - Morte Obstétrica de Causa Não Especificada",
            "O96 - Morte, Por Qualquer Causa Obstétrica, Que Ocorre Mais de 42 Dias, Mas Menos de 1 Ano, Após o Parto",
            "O97 - Morte Por Seqüelas de Causas Obstétricas Diretas",
            "O98 - Doenças Infecciosas e Parasitárias Maternas Classificáveis em Outra Parte Mas Que Compliquem a Gravidez, o Parto e o Puerpério",
            "O98.0 - Tuberculose Complicando a Gravidez, o Parto e o Puerpério",
            "O98.1 - Sífilis Complicando a Gravidez, o Parto e o Puerpério",
            "O98.2 - Gonorréia Complicando a Gravidez, o Parto e o Puerpério",
            "O98.3 - Outras Infecções em Que a Via de Transmissão é Predominantemente Sexual, Complicando a Gravidez, o Parto e o Puerpério",
            "O98.4 - Hepatite Viral Complicando a Gravidez, o Parto e o Puerpério",
            "O98.5 - Outras Doenças Virais Complicando a Gravidez, o Parto e o Puerpério",
            "O98.6 - Doenças Causadas Por Protozoários Complicando a Gravidez, o Parto e o Puerpério",
            "O98.8 - Outras Doenças Infecciosas e Parasitárias Maternas Complicando a Gravidez, o Parto e o Puerpério",
            "O98.9 - Doenças Infecciosas e Parasitárias Maternas, Não Especificadas, Complicando a Gravidez, o Parto e o Puerpério",
            "O99 - Outras Doenças da Mãe, Classificadas em Outra Parte, Mas Que Complicam a Gravidez o Parto e o Puerpério",
            "O99.0 - Anemia Complicando a Gravidez, o Parto e o Puerpério",
            "O99.1 - Outras Doenças do Sangue e Dos Órgãos Hematopoéticos e Alguns Transtornos Que Comprometem o Sistema Imunológico, Complicando a Gravidez, o Parto e o Puerpério",
            "O99.2 - Doenças Endócrinas, Nutricionais e Metabólicas Complicando a Gravidez, o Parto e o Puerpério",
            "O99.3 - Transtornos Mentais e Doenças do Sistema Nervoso Complicando a Gravidez, o Parto e o Puerpério",
            "O99.4 - Doenças do Aparelho Circulatório Complicando a Gravidez, o Parto e o Puerpério",
            "O99.5 - Doenças do Aparelho Respiratório Complicando a Gravidez, o Parto e o Puerpério",
            "O99.6 - Doenças do Aparelho Digestivo Complicando a Gravidez, o Parto e o Puerpério",
            "O99.7 - Doenças da Pele e do Tecido Subcutâneo Complicando a Gravidez, o Parto e o Puerpério",
            "O99.8 - Outras Doenças e Afecções Especificadas Complicando a Gravidez, o Parto e o Puerpério",
            "P00 - Feto e Recém-nascido Afetados Por Afecções Maternas, Não Obrigatoriamente Relacionadas Com a Gravidez Atual",
            "P00.0 - Feto e Recém-nascido Afetados Por Transtornos Maternos Hipertensivos",
            "P00.1 - Feto e Recém-nascido Afetados Por Doenças Maternas Renais e Das Vias Urinárias",
            "P00.2 - Feto e Recém-nascido Afetados Por Doenças Infecciosas e Parasitárias da Mãe",
            "P00.3 - Feto e Recém-nascido Afetados Por Outras Doenças Circulatórias e Respiratórias Maternas",
            "P00.4 - Feto e Recém-nascido Afetados Por Transtornos Nutricionais Maternos",
            "P00.5 - Feto e Recém-nascido Afetados Por Traumatismo Materno",
            "P00.6 - Feto e Recém-nascido Afetados Por Intervenção Cirúrgica na Mãe",
            "P00.7 - Feto e Recém-nascido Afetados Por Outros Procedimentos Médicos na Mãe, Não Classificados em Outra Parte",
            "P00.8 - Feto e Recém-nascido Afetados Por Outras Afecções Maternas",
            "P00.9 - Feto e Recém-nascido Afetados Por Afecção Materna Não Especificada",
            "P01 - Feto e Recém-nascido Afetados Por Complicações Maternas da Gravidez",
            "P01.0 - Feto e Recém-nascido Afetados Por Incompetência do Colo Uterino",
            "P01.1 - Feto e Recém-nascido Afetados Por Ruptura Prematura Das Membranas",
            "P01.2 - Feto e Recém-nascido Afetados Por Oligohidrâmnio",
            "P01.3 - Feto e Recém-nascido Afetados Por Polihidrâmnio",
            "P01.4 - Feto e Recém-nascido Afetados Por Gravidez Ectópica",
            "P01.5 - Feto e Recém-nascido Afetados Por Gravidez Múltipla",
            "P01.6 - Feto e Recém-nascido Afetados Por Morte Materna",
            "P01.7 - Feto e Recém-nascido Afetados Por Apresentação Anormal Antes do Trabalho de Parto",
            "P01.8 - Feto e Recém-nascido Afetados Por Outras Complicações Maternas da Gravidez",
            "P01.9 - Feto e Recém-nascido Afetados Por Afecções Maternas da Gravidez, Não Especificadas",
            "P02 - Feto e Recém-nascido Afetados Por Complicações da Placenta, do Cordão Umbilical e Das Membranas",
            "P02.0 - Feto e Recém-nascido Afetados Por Placenta Prévia",
            "P02.1 - Feto e Recém-nascido Afetados Por Outras Formas de Descolamento da Placenta e Hemorragia",
            "P02.2 - Feto e Recém-nascido Afetados Por Outras Anormalidades Morfológicas e Funcionais da Placenta e as Não Especificadas",
            "P02.3 - Feto e Recém-nascido Afetados Por Síndromes de Transfusão Placentária",
            "P02.4 - Feto e Recém-nascido Afetados Por Prolapso de Cordão Umbilical",
            "P02.5 - Feto e Recém-nascido Afetados Por Outras Compressões do Cordão Umbilical",
            "P02.6 - Feto e Recém-nascido Afetados Por Outras Afecções do Cordão Umbilical e as Não Especificadas",
            "P02.7 - Feto e Recém-nascido Afetados Por Corioamnionite",
            "P02.8 - Feto e Recém-nascido Afetados Por Outras Anormalidades Das Membranas",
            "P02.9 - Feto e Recém-nascido Afetados Por Anormalidade Não Especificada Das Membranas",
            "P03 - Feto e Recém-nascido Afetados Por Outras Complicações do Trabalho de Parto e do Parto",
            "P03.0 - Feto e Recém-nascido Afetados Por Parto e Extração Pélvicas",
            "P03.1 - Feto e Recém-nascido Afetados Por Outras Apresentações Anormais, Má-posições e Desproporções Durante o Trabalho de Parto e o Parto",
            "P03.2 - Feto e Recém-nascido Afetados Por Parto Por Fórceps",
            "P03.3 - Feto e Recém-nascido Afetados Por Parto Por Vácuo-extrator (ventosa)",
            "P03.4 - Feto e Recém-nascido Afetados Por Parto Por Cesariana",
            "P03.5 - Feto e Recém-nascido Afetados Por Parto Precipitado",
            "P03.6 - Feto e Recém-nascido Afetados Por Contrações Uterinas Anormais",
            "P03.8 - Feto e Recém-nascido Afetados Por Outras Complicações Especificadas do Trabalho de Parto e do Parto",
            "P03.9 - Feto e Recém-nascido Afetados Por Complicações Não Especificadas do Trabalho de Parto e do Parto",
            "P04 - Feto e Recém-nascido Afetados Por Influências Nocivas Transmitidas ao Feto Via Placenta ou Leite Materno",
            "P04.0 - Feto e Recém-nascido Afetados Por Anestesia e Analgesia Materna Durante a Gravidez, Trabalho de Parto e Parto",
            "P04.1 - Feto e Recém-nascido Afetados Por Outros Medicamentos Utilizados Pela Mãe",
            "P04.2 - Feto e Recém-nascido Afetados Pelo Uso de Fumo Pela Mãe",
            "P04.3 - Feto e Recém-nascido Afetados Pelo Uso de Álcool Pela Mãe",
            "P04.4 - Feto e Recém-nascido Afetados Pelo Uso de Drogas Que Causam Dependência Pela Mãe",
            "P04.5 - Feto e Recém-nascido Afetados Por Uso Materno de Substâncias Químicas Dos Alimentos",
            "P04.6 - Feto e Recém-nascido Afetados Pela Exposição da Mãe a Substâncias Químicas do Meio Ambiente",
            "P04.8 - Feto e Recém-nascido Afetados Por Outras Influências Nocivas Maternas",
            "P04.9 - Feto e Recém-nascido Afetados Por Influências Nocivas Maternas Não Especificadas",
            "P05 - Crescimento Fetal Retardado e Desnutrição Fetal",
            "P05.0 - Recém-nascido de Baixo Peso Para a Idade Gestacional",
            "P05.1 - Pequeno Para a Idade Gestacional",
            "P05.2 - Desnutrição Fetal Sem Menção de Peso e Comprimento Baixos Para a Idade Gestacional",
            "P05.9 - Retardo Não Especificado do Crescimento Fetal",
            "P07 - Transtornos Relacionados Com a Gestação de Curta Duração e Peso Baixo ao Nascer Não Classificados em Outra Parte",
            "P07.0 - Recém-nascido Com Peso Muito Baixo",
            "P07.1 - Outros Recém-nascidos de Peso Baixo",
            "P07.2 - Imaturidade Extrema",
            "P07.3 - Outros Recém-nascidos de Pré-termo",
            "P08 - Transtornos Relacionados Com a Gestação Prolongada e Peso Elevado ao Nascer",
            "P08.0 - Recém-nascido de Tamanho Excessivamente Grande",
            "P08.1 - Outros Recém-nascidos Grandes Para a Idade Gestacional",
            "P08.2 - Recém-nascido Pós-termo, Não-grande Para a Idade Gestacional",
            "P10 - Laceração Intracraniana e Hemorragia Devidas a Traumatismo de Parto",
            "P10.0 - Hemorragia Subdural Devida a Traumatismo de Parto",
            "P10.1 - Hemorragia Cerebral Devida a Traumatismo de Parto",
            "P10.2 - Hemorragia Intraventricular Devida a Traumatismo de Parto",
            "P10.3 - Hemorragia Subaracnoídea Devida a Traumatismo de Parto",
            "P10.4 - Ruptura Tentorial Devida a Traumatismo de Parto",
            "P10.8 - Outras Lacerações e Hemorragias Intracranianas Devidas a Traumatismo de Parto",
            "P10.9 - Laceração e Hemorragia Intracranianas Não Especificadas Devidas a Traumatismo de Parto",
            "P11 - Outros Traumatismos de Parto do Sistema Nervoso Central",
            "P11.0 - Edema Cerebral Devido a Traumatismo de Parto",
            "P11.1 - Outras Lesões Cerebrais Especificadas Devidas a Traumatismo de Parto",
            "P11.2 - Lesão Cerebral Não Especificada Devida a Traumatismo de Parto",
            "P11.3 - Traumatismo de Parto do Nervo Facial",
            "P11.4 - Traumatismo de Parto de Outros Nervos Cranianos",
            "P11.5 - Traumatismo de Parto da Coluna e da Medula Espinhal",
            "P11.9 - Traumatismo de Parto Não Especificado do Sistema Nervoso Central",
            "P12 - Lesão do Couro Cabeludo Devida a Traumatismo de Parto",
            "P12.0 - Céfalo-hematoma Devido a Traumatismo de Parto",
            "P12.1 - Chignon Devido a Traumatismo de Parto",
            "P12.2 - Hemorragia Subaponeurótica Epicraniana Devida a Traumatismo de Parto",
            "P12.3 - Esmagamento do Couro Cabeludo Devido a Traumatismo de Parto",
            "P12.4 - Lesão Por Monitorização do Couro Cabeludo do Recém-nascido",
            "P12.8 - Outras Lesões do Couro Cabeludo Devidas a Traumatismo de Parto",
            "P12.9 - Lesão Não Especificada do Couro Cabeludo Devida a Traumatismo de Parto",
            "P13 - Lesões do Esqueleto Devidas a Traumatismo de Parto",
            "P13.0 - Fratura de Crânio Devida a Traumatismo de Parto",
            "P13.1 - Outras Lesões Cranianas Devidas a Traumatismo de Parto",
            "P13.2 - Lesão do Fêmur Devida a Traumatismo de Parto",
            "P13.3 - Lesão de Outros Ossos Longos Devida a Traumatismo de Parto",
            "P13.4 - Fratura da Clavícula Devida a Traumatismo de Parto",
            "P13.8 - Lesões de Outras Partes do Esqueleto Devidas a Traumatismo de Parto",
            "P13.9 - Lesões Não Especificadas do Esqueleto Devidas a Traumatismo de Parto",
            "P14 - Lesões ao Nascer do Sistema Nervoso Periférico",
            "P14.0 - Paralisia de Erb Devida a Traumatismo de Parto",
            "P14.1 - Paralisia de Klumpke Devida a Traumatismo de Parto",
            "P14.2 - Paralisia do Nervo Frênico Devida a Traumatismo de Parto",
            "P14.3 - Outras Lesões do Plexo Braquial Devidas a Traumatismo de Parto",
            "P14.8 - Outras Lesões de Outras Partes do Sistema Nervoso Periférico Devidas a Traumatismo de Parto",
            "P14.9 - Lesão Não Especificada do Sistema Nervoso Periférico Devida a Traumatismo de Parto",
            "P15 - Outros Traumatismos de Parto",
            "P15.0 - Lesão do Fígado Devida a Traumatismo de Parto",
            "P15.1 - Lesão do Baço Devida a Traumatismo de Parto",
            "P15.2 - Lesão do Esternomastóide Devida a Traumatismo de Parto",
            "P15.3 - Lesão Dos Olhos Devida a Traumatismo de Parto",
            "P15.4 - Lesão da Face ao Nascer",
            "P15.5 - Lesão Dos Órgãos Genitais Externos, Devida a Traumatismo de Parto",
            "P15.6 - Necrose de Gordura Subcutânea Devida a Traumatismo de Parto",
            "P15.8 - Outros Traumatismos de Parto Especificados",
            "P15.9 - Traumatismo de Parto Não Especificado",
            "P20 - Hipóxia Intra-uterina",
            "P20.0 - Hipóxia Intra-uterina Diagnosticada Antes do Início do Trabalho de Parto",
            "P20.1 - Hipóxia Intra-uterina Diagnosticada Durante o Trabalho de Parto e o Parto",
            "P20.9 - Hipóxia Intra-uterina Não Especificada",
            "P21 - Asfixia ao Nascer",
            "P21.0 - Asfixia Grave ao Nascer",
            "P21.1 - Asfixia Leve ou Moderada ao Nascer",
            "P21.9 - Asfixia ao Nascer, Não Especificada",
            "P22 - Desconforto (angústia) Respiratório(a) do Recém-nascido",
            "P22.0 - Síndrome da Angústia Respiratória do Recém-nascido",
            "P22.1 - Taquipnéia Transitória do Recém-nascido",
            "P22.8 - Outros Desconfortos Respiratórios do Recém-nascido",
            "P22.9 - Desconforto Respiratório Não Especificado do Recém-nascido",
            "P23 - Pneumonia Congênita",
            "P23.0 - Pneumonia Congênita Devida a Agente Viral",
            "P23.1 - Pneumonia Congênita Devida a Clamídia",
            "P23.2 - Pneumonia Congênita Devida a Estafilococo",
            "P23.3 - Pneumonia Congênita Devida a Estreptococo do Grupo B",
            "P23.4 - Pneumonia Congênita Devida a Escherichia Coli",
            "P23.5 - Pneumonia Congênita Devida a Pseudomonas",
            "P23.6 - Pneumonia Congênita Devida a Outros Agentes Bacterianos",
            "P23.8 - Pneumonia Congênita Devida a Outros Organismos",
            "P23.9 - Pneumonia Congênita Não Especificada",
            "P24 - Síndrome de Aspiração Neonatal",
            "P24.0 - Aspiração Neonatal de Mecônio",
            "P24.1 - Aspiração Neonatal de Líquido Amniótico e Muco",
            "P24.2 - Aspiração Neonatal de Sangue",
            "P24.3 - Aspiração Neonatal de Leite e Alimento Regurgitados",
            "P24.8 - Outras Síndromes de Aspiração Neonatais",
            "P24.9 - Síndrome de Aspiração Neonatal Não Especificada",
            "P25 - Enfisema Intersticial e Afecções Correlatas Originadas no Período Perinatal",
            "P25.0 - Enfisema Intersticial Originado no Período Perinatal",
            "P25.1 - Pneumotórax Originado no Período Perinatal",
            "P25.2 - Pneumomediastino Originado no Período Perinatal",
            "P25.3 - Pneumopericárdio Originado no Período Perinatal",
            "P25.8 - Outras Afecções Relacionadas Com o Enfisema Intersticial Originadas no Período Perinatal",
            "P26 - Hemorragia Pulmonar Originada no Período Perinatal",
            "P26.0 - Hemorragia Traqueobrônquica Originada no Período Perinatal",
            "P26.1 - Hemorragia Pulmonar Maciça Originada no Período Perinatal",
            "P26.8 - Outras Hemorragias Pulmonares Originadas no Período Perinatal",
            "P26.9 - Hemorragia Pulmonar Não Especificada Originada no Período Perinatal",
            "P27 - Doença Respiratória Crônica Originada no Período Perinatal",
            "P27.0 - Síndrome de Wilson-Mikity",
            "P27.1 - Displasia Broncopulmonar Originada no Período Perinatal",
            "P27.8 - Outras Doenças Respiratórias Crônicas Originadas no Período Perinatal",
            "P27.9 - Doença Respiratória Crônica Não Especificada Originada no Período Perinatal",
            "P28 - Outras Afecções Respiratórias Originadas no Período Perinatal",
            "P28.0 - Atelectasia Primária do Recém-nascido",
            "P28.1 - Outras Atelectasias do Recém-nascido e as Não Especificadas",
            "P28.2 - Crises Cianóticas do Recém-nascido",
            "P28.3 - Apnéia Primária do Sono do Recém-nascido",
            "P28.4 - Outras Apnéias do Recém-nascido",
            "P28.5 - Insuficiência Respiratória do Recém-nascido",
            "P28.8 - Outras Afecções Respiratórias Especificadas do Recém-nascido",
            "P28.9 - Afecção Respiratória do Recém-nascido, Não Especificada",
            "P29 - Transtornos Cardiovasculares Originados no Período Perinatal",
            "P29.0 - Insuficiência Cardíaca Neonatal",
            "P29.1 - Disritmia Cardíaca Neonatal",
            "P29.2 - Hipertensão Neonatal",
            "P29.3 - Persistência da Circulação Fetal",
            "P29.4 - Isquemia Miocárdica Transitória do Recém-nascido",
            "P29.8 - Outros Transtornos Cardiovasculares Originados no Período Perinatal",
            "P29.9 - Transtornos Cardiovasculares Não Especificados Originados no Período Perinatal",
            "P35 - Doenças Virais Congênitas",
            "P35.0 - Síndrome da Rubéola Congênita",
            "P35.1 - Infecção Congênita Por Citomegalovirus",
            "P35.2 - Infecção Congênita Por Vírus do Herpes (simples)",
            "P35.3 - Hepatite Viral Congênita",
            "P35.8 - Outras Doenças Virais Congênitas",
            "P35.9 - Doença Viral Congênita Não Especificada",
            "P36 - Septicemia Bacteriana do Recém-nascido",
            "P36.0 - Septicemia do Recém-nascido Devida a Estreptococo do Grupo B",
            "P36.1 - Septicemia do Recém-nascido Devida a Outros Estreptococos e Aos Não Especificados",
            "P36.2 - Septicemia do Recém-nascido Devida ao Stafilococcus Aureus",
            "P36.3 - Septicemia do Recém-nascido Devida a Outros Estafilococos e Aos Não Especificados",
            "P36.4 - Septicemia do Recém-nascido Devida a Escherichia Coli",
            "P36.5 - Septicemia do Recém-nascido Devida a Anaeróbios",
            "P36.8 - Outras Septicemias Bacterianas do Recém-nascido",
            "P36.9 - Septicemia Bacteriana Não Especificada do Recém-nascido",
            "P37 - Outras Doenças Infecciosas e Parasitárias Congênitas",
            "P37.0 - Tuberculose Congênita",
            "P37.1 - Toxoplasmose Congênita",
            "P37.2 - Listeriose Neonatal (disseminada)",
            "P37.3 - Malária Falciparum Congênita",
            "P37.4 - Outras Malárias Congênitas",
            "P37.5 - Candidíase Neonatal",
            "P37.8 - Outras Doenças Infecciosas e Parasitárias Congênitas Especificadas",
            "P37.9 - Doença Infecciosa ou Parasitária Congênita Não Especificada",
            "P38 - Onfalite do Recém-nascido Com ou Sem Hemorragia Leve",
            "P39 - Outras Infecções Específicas do Período Perinatal",
            "P39.0 - Mastite Infecciosa Neonatal",
            "P39.1 - Conjuntivite e Dacriocistite Neonatais",
            "P39.2 - Infecção Intra-amniótica do Feto Não Classificada em Outra Parte",
            "P39.3 - Infecção Neonatal do Trato Urinário",
            "P39.4 - Infecção Neonatal da Pele",
            "P39.8 - Outras Infecções Especificadas Próprias do Período Perinatal",
            "P39.9 - Infecção Própria do Período Perinatal Não Especificada",
            "P50 - Perda Sangüínea Fetal",
            "P50.0 - Perda Sangüínea Fetal Devida a Vasa Prévia",
            "P50.1 - Perda Sangüínea Fetal Por Ruptura do Cordão",
            "P50.2 - Perda Sangüínea Fetal Originada da Placenta",
            "P50.3 - Hemorragia Para a Circulação do Outro Gêmeo",
            "P50.4 - Hemorragia Para a Circulação Materna",
            "P50.5 - Perda Sangüínea Devido a Secção do Cordão Umbilical do Outro Gemelar",
            "P50.8 - Outras Perdas Sangüíneas Fetais",
            "P50.9 - Perda Sangüínea Fetal Não Especificada",
            "P51 - Hemorragia Umbilical do Recém-nascido",
            "P51.0 - Hemorragia Umbilical Maciça do Recém-nascido",
            "P51.8 - Outras Hemorragias Umbilicais do Recém-nascido",
            "P51.9 - Hemorragia Umbilical do Recém-nascido, Não Especificada",
            "P52 - Hemorragia Intracraniana Não-traumática do Feto e do Recém-nascido",
            "P52.0 - Hemorragia Intraventricular (não-traumática) Grau 1, do Feto e do Recém-nascido",
            "P52.1 - Hemorragia Intraventricular (não-traumática) Grau 2, do Feto e do Recém-nascido",
            "P52.2 - Hemorragia Intraventricular (não-traumática) Grau 3, do Feto e do Recém-nascido",
            "P52.3 - Hemorragia Intraventricular (não-traumática) Não Especificada, do Feto ou do Recém-nascido",
            "P52.4 - Hemorragia Intracerebral (não-traumática) do Feto e do Recém-nascido",
            "P52.5 - Hemorragia Subaracnoídea (não-traumática) do Feto e do Recém-nascido",
            "P52.6 - Hemorragia Cerebelar (não-traumática) e da Fossa Posterior do Feto ou Recém-nascido",
            "P52.8 - Outras Hemorragias Intracranianas (não-traumáticas) do Feto e do Recém-nascido",
            "P52.9 - Hemorragia Intracraniana (não-traumática) do Feto e do Recém-nascido, Não Especificada",
            "P53 - Doença Hemorrágica do Feto e do Recém-nascido",
            "P54 - Outras Hemorragias Neonatais",
            "P54.0 - Hematêmese Neonatal",
            "P54.1 - Melena Neonatal",
            "P54.2 - Hemorragia Retal Neonatal",
            "P54.3 - Outras Hemorragias Gastrointestinais do Período Neonatal",
            "P54.4 - Hemorragia Supra-renal Neonatal",
            "P54.5 - Hemorragia Cutânea Neonatal",
            "P54.6 - Hemorragia Vaginal Neonatal",
            "P54.8 - Outras Hemorragias Fetais e Neonatais Especificadas",
            "P54.9 - Hemorragia Fetal e Neonatal Não Especificada",
            "P55 - Doença Hemolítica do Feto e do Recém-nascido",
            "P55.0 - Isoimunização Rh do Feto e do Recém-nascido",
            "P55.1 - Isoimunização ABO do Feto e do Recém-nascido",
            "P55.8 - Outras Doenças Hemolíticas do Feto e do Recém-nascido",
            "P55.9 - Doença Hemolítica Não Especificada do Feto e do Recém-nascido",
            "P56 - Hidropsia Fetal Devida a Doença Hemolítica",
            "P56.0 - Hidropsia Fetal Devida à Isoimunização",
            "P56.9 - Hidropsia Fetal Devida a Outras Doenças Hemolíticas e às Não Especificadas",
            "P57 - Kernicterus",
            "P57.0 - Kernicterus Devido à Isoimunização",
            "P57.8 - Outros Kernicterus Especificados",
            "P57.9 - Kernicterus Não Especificado",
            "P58 - Icterícia Neonatal Devida a Outras Hemólises Excessivas",
            "P58.0 - Icterícia Neonatal Devida a Contusões",
            "P58.1 - Icterícia Neonatal Devida a Sangramento",
            "P58.2 - Icterícia Neonatal Devida a Infecção",
            "P58.3 - Icterícia Neonatal Devida a Policitemia",
            "P58.4 - Icterícia Neonatal Devida a Drogas ou Toxinas Transmitidas Pela Mãe ou Administradas ao Recém-nascido",
            "P58.5 - Icterícia Neonatal Devida à Deglutação de Sangue Materno",
            "P58.8 - Icterícia Neonatal Devida a Outras Hemólises Excessivas Especificadas",
            "P58.9 - Icterícia Neonatal Devida a Hemólise Excessiva Não Especificada",
            "P59 - Icterícia Neonatal Devida a Outras Causas e às Não Especificadas",
            "P59.0 - Icterícia Neonatal Associada ao Parto Prematuro",
            "P59.1 - Síndrome da Bile Espessa",
            "P59.2 - Icterícia Neonatal de Outras Lesões Hepatocelulares e Das Não Especificadas",
            "P59.3 - Icterícia Neonatal Devida a Inibidores do Leite Materno",
            "P59.8 - Icterícia Neonatal Devida a Outras Causas Especificadas",
            "P59.9 - Icterícia Neonatal Não Especificada",
            "P60 - Coagulação Intravascular Disseminada do Feto e do Recém-nascido",
            "P61 - Outros Transtornos Hematológicos Perinatais",
            "P61.0 - Trombocitopenia Transitória Neonatal",
            "P61.1 - Policitemia Neonatal",
            "P61.2 - Anemia da Prematuridade",
            "P61.3 - Anemia Congênita Por Perda Sangüínea Fetal",
            "P61.4 - Outras Anemias Congênitas Não Classificada em Outra Parte",
            "P61.5 - Neutropenia Transitória Neonatal",
            "P61.6 - Outros Transtornos Transitórios de Coagulação do Período Neonatal",
            "P61.8 - Outros Transtornos Hematológicos Perinatais Especificados",
            "P61.9 - Transtorno Hematológico Perinatal Não Especificado",
            "P70 - Transtornos Transitórios do Metabolismo Dos Carboidratos Específicos do Feto e do Recém-nascido",
            "P70.0 - Síndrome do Filho de Mãe Com Diabetes Gestacional",
            "P70.1 - Síndrome do Filho de Mãe Diabética",
            "P70.2 - Diabetes Mellitus Neonatal",
            "P70.3 - Hipoglicemia Neonatal Iatrogênica",
            "P70.4 - Outras Hipoglicemias Neonatais",
            "P70.8 - Outros Transtornos Transitórios do Metabolismo Dos Carboidratos do Feto e do Recém-nascido",
            "P70.9 - Transtorno Transitório Não Especificado do Metabolismo Dos Carboidratos do Feto e do Recém-nascido",
            "P71 - Transtornos Transitórios do Metabolismo do Cálcio e do Magnésio do Período Neonatal",
            "P71.0 - Hipocalcemia do Recém-nascido Pelo Uso de Leite de Vaca",
            "P71.1 - Outras Hipocalcemias Neonatais",
            "P71.2 - Hipomagnesemia Neonatal",
            "P71.3 - Tetania Neonatal Sem Deficiência de Cálcio ou Magnésio",
            "P71.4 - Hipoparatiroidismo Neonatal Transitório",
            "P71.8 - Outros Transtornos Transitórios do Metabolismo do Cálcio e do Magnésio do Período Neonatal",
            "P71.9 - Transtornos Transitórios Não Especificados do Metabolismo do Cálcio e do Magnésio do Período Neonatal",
            "P72 - Outros Transtornos Endócrinos Transitórios do Período Neonatal",
            "P72.0 - Bócio Neonatal, Não Classificado em Outra Parte",
            "P72.1 - Hipotireoidismo Neonatal Transitório",
            "P72.2 - Outros Transtornos Transitórios da Função de Tireóide Não Classificados em Outra Parte",
            "P72.8 - Outros Transtornos Endócrinos Transitórios e Especificados do Período Neonatal",
            "P72.9 - Transtorno Endócrino Neonatal Transitório Não Especificado",
            "P74 - Outros Distúrbios Eletrolíticos e Metabólicos Transitórios do Período Neonatal",
            "P74.0 - Acidose Metabólica Tardia do Recém-nascido",
            "P74.1 - Desidratação do Recém-nascido",
            "P74.2 - Distúrbios do Equilíbrio de Sódio do Recém-nascido",
            "P74.3 - Distúrbios do Equilíbrio de Potássio do Recém-nascido",
            "P74.4 - Outros Distúrbios Eletrolíticos Transitórios do Recém-nascido",
            "P74.5 - Tirosinemia Transitória do Recém-nascido",
            "P74.8 - Outros Distúrbios Metabólicos Transitórios do Recém-nascido",
            "P74.9 - Distúrbio Metabólico Transitório Não Especificado do Recém-nascido",
            "P75 - Íleo Meconial",
            "P76 - Outras Obstruções Intestinais do Recém-nascido",
            "P76.0 - Síndrome da rolha de Mecônio",
            "P76.1 - Íleo Transitório do Recém-nascido",
            "P76.2 - Obstrução Intestinal Devido a Leite Espesso",
            "P76.8 - Outras Obstruções Intestinais Especificadas do Recém-nascido",
            "P76.9 - Obstrução Intestinal Não Especificada do Recém-nascido",
            "P77 - Enterocolite Necrotizante do Feto e do Recém-nascido",
            "P78 - Outros Transtornos do Aparelho Digestivo do Período Perinatal",
            "P78.0 - Perfuração Intestinal no Período Perinatal",
            "P78.1 - Outras Peritonites Neonatais",
            "P78.2 - Hematemese e Melena Devidas a Deglutição de Sangue Materno",
            "P78.3 - Diarréia Neonatal Não-infecciosa",
            "P78.8 - Outros Transtornos Perinatais Especificados do Aparelho Digestivo",
            "P78.9 - Transtorno Perinatal Não Especificado do Aparelho Digestivo",
            "P80 - Hipotermia do Recém-nascido",
            "P80.0 - Síndrome de Lesão Pelo Frio",
            "P80.8 - Outras Hipotermias do Recém-nascido",
            "P80.9 - Hipotermia Não Especificada do Recém-nascido",
            "P81 - Outros Distúrbios da Regulação Térmica do Recém-nascido",
            "P81.0 - Hipertermia Ambiental do Recém-nascido",
            "P81.8 - Outros Distúrbios Especificados da Regulação Térmica do Recém-nascido",
            "P81.9 - Distúrbio Não Especificado da Regulação Térmica do Recém-nascido",
            "P83 - Outras Afecções Comprometendo o Tegumento Específicas do Feto e do Recém-nascido",
            "P83.0 - Esclerema Neonatal",
            "P83.1 - Eritema Tóxico Neonatal",
            "P83.2 - Hidropsia Fetal Não-devida à Doença Hemolítica",
            "P83.3 - Outros Edemas e os Não Especificados Próprios do Feto e do Recém-nascido",
            "P83.4 - Intumescimento Mamário do Recém-nascido",
            "P83.5 - Hidrocele Congênita",
            "P83.6 - Pólipo Umbilical do Recém-nascido",
            "P83.8 - Outras Afecções Especificadas do Tegumento Próprias do Feto e do Recém-nascido",
            "P83.9 - Afecção Não Especificada do Tegumento Própria do Feto e do Recém-nascido",
            "P90 - Convulsões do Recém-nascido",
            "P91 - Outros Distúrbios da Função Cerebral do Recém-nascido",
            "P91.0 - Isquemia Cerebral Neonatal",
            "P91.1 - Cistos Periventriculares Adquiridos do Recém-nascido",
            "P91.2 - Leucomalácia Cerebral Neonatal",
            "P91.3 - Irritabilidade Cerebral Neonatal",
            "P91.4 - Depressão Cerebral Neonatal",
            "P91.5 - Coma Neonatal",
            "P91.6 - Encefalopatia Hipóxico-isquêmica do Recém-nascido",
            "P91.8 - Outros Distúrbios Especificados da Função Cerebral do Recém-nascido",
            "P91.9 - Distúrbio Não Especificado da Função Cerebral do Recém-nascido",
            "P92 - Problemas de Alimentação do Recém-nascido",
            "P92.0 - Vômitos no Recém-nascido",
            "P92.1 - Regurgitação e Ruminação no Recém-nascido",
            "P92.2 - Alimentação Vagarosa do Recém-nascido",
            "P92.3 - Subalimentação do Recém-nascido",
            "P92.4 - Hiperalimentação do Recém-nascido",
            "P92.5 - Dificuldade Neonatal na Amamentação no Peito",
            "P92.8 - Outros Problemas de Alimentação do Recém-nascido",
            "P92.9 - Problema Não Especificado de Alimentação do Recém-nascido",
            "P93 - Reações e Intoxicações Devidas a Drogas Administradas ao Feto e ao Recém-nascido",
            "P94 - Transtornos do Tônus Muscular do Recém-nascido",
            "P94.0 - Miastenia Grave Neonatal Transitória",
            "P94.1 - Hipertonia Congênita",
            "P94.2 - Hipotonia Congênita",
            "P94.8 - Outros Transtornos do Tônus Muscular do Recém-nascido",
            "P94.9 - Transtorno Não Especificado do Tônus Muscular do Recém-nascido",
            "P95 - Morte Fetal de Causa Não Especificada",
            "P96 - Outras Afecções Originadas no Período Perinatal",
            "P96.0 - Insuficiência Renal Congênita",
            "P96.1 - Sintomas de Abstinência Neonatal de Drogas Utilizadas Pela Mãe",
            "P96.2 - Sintomas de Abstinência do Uso de Drogas Terapêuticas no Recém-nascido",
            "P96.3 - Suturas Cranianas Amplas no Recém-nascido",
            "P96.4 - Interrupção de Gravidez, Afetando o Feto e Recém-nascido",
            "P96.5 - Complicações de Procedimentos Intra-uterinos Não Classificados em Outra Parte",
            "P96.8 - Outras Afecções Especificadas Originadas no Período Perinatal",
            "P96.9 - Afecções Originadas no Período Perinatal Não Especificadas",
            "Q00 - Anencefalia e Malformações Similares",
            "Q00.0 - Anencefalia",
            "Q00.1 - Craniorraquisquise",
            "Q00.2 - Iniencefalia",
            "Q01 - Encefalocele",
            "Q01.0 - Encefalocele Frontal",
            "Q01.1 - Encefalocele Nasofrontal",
            "Q01.2 - Encefalocele Occipital",
            "Q01.8 - Encefalocele de Outras Localizações",
            "Q01.9 - Encefalocele Não Especificada",
            "Q02 - Microcefalia",
            "Q03 - Hidrocefalia Congênita",
            "Q03.0 - Malformações do Aqueduto de Sylvius",
            "Q03.1 - Atresia Das Fendas de Luschka e do Forâmen de Magendie",
            "Q03.8 - Outra Hidrocefalia Congênita",
            "Q03.9 - Hidrocefalia Congênita Não Especificada",
            "Q04 - Outras Malformações Congênitas do Cérebro",
            "Q04.0 - Malformações Congênitas do Corpo Caloso",
            "Q04.1 - Arrinencefalia",
            "Q04.2 - Holoprosencefalia",
            "Q04.3 - Outras Deformidades Por Redução do Encéfalo",
            "Q04.4 - Displasia do Septo e Das Vias Ópticas",
            "Q04.5 - Megalencefalia",
            "Q04.6 - Cistos Cerebrais Congênitos",
            "Q04.8 - Outras Malformações Congênitas Especificadas do Encéfalo",
            "Q04.9 - Malformação Congênita Não Especificada do Encéfalo",
            "Q05 - Espinha Bífida",
            "Q05.0 - Espinha Bífida Cervical Com Hidrocefalia",
            "Q05.1 - Espinha Bífida Torácica Com Hidrocefalia",
            "Q05.2 - Espinha Bífida Lombar Com Hidrocefalia",
            "Q05.3 - Espinha Bífida Sacra Com Hidrocefalia",
            "Q05.4 - Espinha Bífida Não Especificada, Com Hidrocefalia",
            "Q05.5 - Espinha Bífida Cervical, Sem Hidrocefalia",
            "Q05.6 - Espinha Bífida Torácica, Sem Hidrocefalia",
            "Q05.7 - Espinha Bífida Lombar, Sem Hidrocefalia",
            "Q05.8 - Espinha Bífida Sacra, Sem Hidrocefalia",
            "Q05.9 - Espinha Bífida Não Especificada",
            "Q06 - Outras Malformações Congênitas da Medula Espinhal",
            "Q06.0 - Amielia",
            "Q06.1 - Hipoplasia e Displasia da Medula Espinal",
            "Q06.2 - Diastematomielia",
            "Q06.3 - Outras Malformações Congênitas da Cauda Eqüina",
            "Q06.4 - Hidromielia",
            "Q06.8 - Outras Malformações Congênitas Especificadas da Medula Espinal",
            "Q06.9 - Malformação Congênita Não Especificada da Medula Espinal",
            "Q07 - Outras Malformações Congênitas do Sistema Nervoso",
            "Q07.0 - Síndrome de Arnold-Chiari",
            "Q07.8 - Outras Malformações Congênitas Especificadas do Sistema Nervoso",
            "Q07.9 - Malformação Congênita Não Especificada do Sistema Nervoso",
            "Q10 - Malformações Congênitas Das Pálpebras, do Aparelho Lacrimal e da Órbita",
            "Q10.0 - Ptose Congênita",
            "Q10.1 - Ectrópio Congênito",
            "Q10.2 - Entrópio Congênito",
            "Q10.3 - Outras Malformações Congênitas Das Pálpebras",
            "Q10.4 - Ausência ou Agenesia do Aparelho Lacrimal",
            "Q10.5 - Estenose ou Estreitamento Congênito do Canal Lacrimal",
            "Q10.6 - Outras Malformações Congênitas do Aparelho Lacrimal",
            "Q10.7 - Malformação Congênita da Órbita",
            "Q11 - Anoftalmia, Microftalmia e Macroftalmia",
            "Q11.0 - Olho Cístico",
            "Q11.1 - Outras Formas de Anoftalmia",
            "Q11.2 - Microftalmia",
            "Q11.3 - Macroftalmia",
            "Q12 - Malformações Congênitas do Cristalino",
            "Q12.0 - Catarata Congênita",
            "Q12.1 - Luxação Congênita do Cristalino",
            "Q12.2 - Coloboma do Cristalino",
            "Q12.3 - Afaquia Congênita",
            "Q12.4 - Esferofaquia",
            "Q12.8 - Outras Malformações Congênitas do Cristalino",
            "Q12.9 - Malformação Congênita Não Especificada do Cristalino",
            "Q13 - Malformações Congênitas da Câmara Anterior do Olho",
            "Q13.0 - Coloboma da Íris",
            "Q13.1 - Ausência de Íris",
            "Q13.2 - Outras Malformações Congênitas da Íris",
            "Q13.3 - Opacidade Congênita da Córnea",
            "Q13.4 - Outras Malformações Congênitas da Córnea",
            "Q13.5 - Esclerótica Azul",
            "Q13.8 - Outras Malformações Congênitas da Câmara Anterior do Olho",
            "Q13.9 - Malformação Congênita Não Especificada da Câmara Anterior do Olho",
            "Q14 - Malformações Congênitas da Câmara Posterior do Olho",
            "Q14.0 - Malformação Congênita do Humor Vítreo",
            "Q14.1 - Malformação Congênita da Retina",
            "Q14.2 - Malformação Congênita do Disco Óptico",
            "Q14.3 - Malformação Congênita da Coróide",
            "Q14.8 - Outras Malformações Congênitas da Câmara Posterior do Olho",
            "Q14.9 - Malformação Congênita Não Especificada da Câmara Posterior do Olho",
            "Q15 - Outras Malformações Congênitas do Olho",
            "Q15.0 - Glaucoma Congênito",
            "Q15.8 - Outras Malformações Congênitas Especificadas do Olho",
            "Q15.9 - Malformação Congênita Não Especificada do Olho",
            "Q16 - Malformações Congênitas do Ouvido Causando Comprometimento da Audição",
            "Q16.0 - Ausência Congênita do Pavilhão Auricular (orelha)",
            "Q16.1 - Ausência, Atresia e Estreitamento Congênitos do Conduto Auditivo (externo)",
            "Q16.2 - Ausência da Trompa de Eustáquio",
            "Q16.3 - Malformação Congênita Dos Ossículos do Ouvido",
            "Q16.4 - Outras Malformações Congênitas do Ouvido Médio",
            "Q16.5 - Malformação Congênita do Ouvido Interno",
            "Q16.9 - Malformação Congênita do Ouvido Não Especificada Causando Comprometimento da Audição",
            "Q17 - Outras Malformações Congênitas da Orelha",
            "Q17.0 - Pavilhão Supranumerário",
            "Q17.1 - Macrotia",
            "Q17.2 - Microtia",
            "Q17.3 - Outras Deformidades da Orelha",
            "Q17.4 - Anomalia de Posição da Orelha",
            "Q17.5 - Orelhas Proeminentes",
            "Q17.8 - Outras Malformações Congênitas Especificadas da Orelha",
            "Q17.9 - Malformação Congênita Não Especificada da Orelha",
            "Q18 - Outras Malformações Congênitas da Face e do Pescoço",
            "Q18.0 - Seio, Fístula e Cisto de Origem Branquial",
            "Q18.1 - Seio, Fístula e Cisto Pré-auricular",
            "Q18.2 - Outras Malformações da Fenda Branquial",
            "Q18.3 - Pescoço Alado",
            "Q18.4 - Macrostomia",
            "Q18.5 - Microstomia",
            "Q18.6 - Macroqueilia",
            "Q18.7 - Microqueilia",
            "Q18.8 - Outras Malformações Congênitas Especificadas da Face e do Pescoço",
            "Q18.9 - Malformação Congênita Não Especificada da Face e do Pescoço",
            "Q20 - Malformações Congênitas Das Câmaras e Das Comunicações Cardíacas",
            "Q20.0 - Tronco Arterial Comum",
            "Q20.1 - Ventrículo Direito Com Dupla Via de Saída",
            "Q20.2 - Ventrículo Esquerdo Com Dupla Via de Saída",
            "Q20.3 - Comunicação Ventrículo-atrial Discordante",
            "Q20.4 - Ventrículo Com Dupla Via de Entrada",
            "Q20.5 - Comunicação Átrio-ventricular Discordante",
            "Q20.6 - Isomerismo Dos Apêndices Atriais",
            "Q20.8 - Outras Malformações Congênitas Das Câmaras e Das Comunicações Cardíacas",
            "Q20.9 - Malformação Congênita Não Especificada Das Câmaras e Das Comunicações Cardíacas",
            "Q21 - Malformações Congênitas Dos Septos Cardíacos",
            "Q21.0 - Comunicação Interventricular",
            "Q21.1 - Comunicação Interatrial",
            "Q21.2 - Comunicação Atrioventricular",
            "Q21.3 - Tetralogia de Fallot",
            "Q21.4 - Comunicação Aortopulmonar",
            "Q21.8 - Outras Malformações Congênitas Dos Septos Cardíacos",
            "Q21.9 - Malformação Congênita Não Especificada de Septo Cardíaco",
            "Q22 - Malformações Congênitas Das Valvas Pulmonar e Tricúspide",
            "Q22.0 - Atresia da Valva Pulmonar",
            "Q22.1 - Estenose Congênita da Valva Pulmonar",
            "Q22.2 - Insuficiência Congênita da Valva Pulmonar",
            "Q22.3 - Outras Malformações Congênitas da Valva Pulmonar",
            "Q22.4 - Estenose Congênita da Valva Tricúspide",
            "Q22.5 - Anomalia de Ebstein",
            "Q22.6 - Síndrome do Coração Direito Hipoplásico",
            "Q22.8 - Outras Malformações Congênitas da Valva Tricúspide",
            "Q22.9 - Malformação Congênita Não Especificada da Valva Tricúspide",
            "Q23 - Malformações Congênitas Das Valvas Aórtica e Mitral",
            "Q23.0 - Estenose Congênita da Valva Aórtica",
            "Q23.1 - Insuficiência Congênita da Valva Aórtica",
            "Q23.2 - Estenose Mitral Congênita",
            "Q23.3 - Insuficiência Mitral Congênita",
            "Q23.4 - Síndrome do Coração Esquerdo Hipoplásico",
            "Q23.8 - Outras Malformações Congênitas Das Valvas Aórtica e Mitral",
            "Q23.9 - Malformação Congênita Não Especificada Das Valvas Aórtica e Mitral",
            "Q24 - Outras Malformações Congênitas do Coração",
            "Q24.0 - Dextrocardia",
            "Q24.1 - Levocardia",
            "Q24.2 - Cor Triatriatum",
            "Q24.3 - Estenose do Infundíbulo Pulmonar",
            "Q24.4 - Estenose Subaórtica Congênita",
            "Q24.5 - Malformações Dos Vasos Coronários",
            "Q24.6 - Bloqueio Congênito do Coração",
            "Q24.8 - Outras Malformações Congênitas Especificadas do Coração",
            "Q24.9 - Malformação Não Especificada do Coração",
            "Q25 - Malformações Congênitas Das Grandes Artérias",
            "Q25.0 - Permeabilidade do Canal Arterial",
            "Q25.1 - Coartação da Aorta",
            "Q25.2 - Atresia da Aorta",
            "Q25.3 - Estenose da Aorta",
            "Q25.4 - Outras Malformações Congênitas da Aorta",
            "Q25.5 - Atresia da Artéria Pulmonar",
            "Q25.6 - Estenose da Artéria Pulmonar",
            "Q25.7 - Outras Malformações Congênitas da Artéria Pulmonar",
            "Q25.8 - Outras Malformações Congênitas Das Grandes Artérias",
            "Q25.9 - Malformação Congênita Não Especificada Das Grandes Artérias",
            "Q26 - Malformações Congênitas Das Grandes Veias",
            "Q26.0 - Estenose Congênita da Veia Cava",
            "Q26.1 - Persistência da Veia Cava Superior Esquerda",
            "Q26.2 - Comunicação Venosa Pulmonar Anormal Total",
            "Q26.3 - Comunicação Venosa Pulmonar Anormal Parcial",
            "Q26.4 - Comunicação Venosa Pulmonar Anormal Não Especificado",
            "Q26.5 - Comunicação Venosa Portal Anormal",
            "Q26.6 - Fístula Entre a Veia Porta e a Artéria Hepática",
            "Q26.8 - Outras Malformações Congênitas Das Grandes Veias",
            "Q26.9 - Malformação Congênita Não Especificada de Grande Veia",
            "Q27 - Outras Malformações Congênitas do Sistema Vascular Periférico",
            "Q27.0 - Ausência Congênita e Hipoplasia da Artéria Umbilical",
            "Q27.1 - Estenose Congênita da Artéria Renal",
            "Q27.2 - Outras Malformações Congênitas da Artéria Renal",
            "Q27.3 - Malformação Artério-venosa Periférica",
            "Q27.4 - Ectasia Venosa (flebectasia) Congênita",
            "Q27.8 - Outras Malformações Congênitas Especificadas do Sistema Vascular Periférico",
            "Q27.9 - Malformação Congênita Não Especificada do Sistema Vascular Periférico",
            "Q28 - Outras Malformações Congênitas do Aparelho Circulatório",
            "Q28.0 - Malformação Arteriovenosa de Vasos Pré-cerebrais",
            "Q28.1 - Outras Malformações Dos Vasos Pré-cerebrais",
            "Q28.2 - Malformação Arteriovenosa Dos Vasos Cerebrais",
            "Q28.3 - Outras Malformações Dos Vasos Cerebrais",
            "Q28.8 - Outras Malformações Congênitas Especificadas do Aparelho Circulatório",
            "Q28.9 - Malformação Congênita Não Especificada do Aparelho Circulatório",
            "Q30 - Malformação Congênita do Nariz",
            "Q30.0 - Atresia Das Coanas",
            "Q30.1 - Agenesia ou Hipoplasia do Nariz",
            "Q30.2 - Fissura, Entalhe ou Fenda Nasal",
            "Q30.3 - Perfuração Congênita do Septo Nasal",
            "Q30.8 - Outras Malformações Congênitas do Nariz",
            "Q30.9 - Malformação Congênita Não Especificada do Nariz",
            "Q31 - Malformações Congênitas da Laringe",
            "Q31.0 - Pterígio da Laringe",
            "Q31.1 - Estenose Subglótica Congênita",
            "Q31.2 - Hipoplasia da Laringe",
            "Q31.3 - Laringocele",
            "Q31.5 - Laringomalácia Congênita",
            "Q31.8 - Outras Malformações Congênitas da Laringe",
            "Q31.9 - Malformação Congênita Não Especificada da Laringe",
            "Q32 - Malformações Congênitas da Traquéia e Dos Brônquios",
            "Q32.0 - Traqueomalácia Congênita",
            "Q32.1 - Outras Malformações Congênitas da Traquéia",
            "Q32.2 - Broncomalácia Congênita",
            "Q32.3 - Estenose Congênita Dos Brônquios",
            "Q32.4 - Outras Malformações Congênitas Dos Brônquios",
            "Q33 - Malformações Congênitas do Pulmão",
            "Q33.0 - Pulmão Cístico Congênito",
            "Q33.1 - Lobo Pulmonar Supranumerário",
            "Q33.2 - Seqüestro Pulmonar",
            "Q33.3 - Agenesia do Pulmão",
            "Q33.4 - Bronquectasia Congênita",
            "Q33.5 - Tecido Ectópico Intrapulmonar",
            "Q33.6 - Hipoplasia e Displasia do Pulmão",
            "Q33.8 - Outras Malformações Congênitas do Pulmão",
            "Q33.9 - Malformação Congênita Não Especificada do Pulmão",
            "Q34 - Outras Malformações Congênitas do Aparelho Respiratório",
            "Q34.0 - Anomalia da Pleura",
            "Q34.1 - Cisto Congênito do Mediastino",
            "Q34.8 - Outras Malformações Congênitas Especificadas do Aparelho Respiratório",
            "Q34.9 - Malformação Congênita Não Especificada do Aparelho Respiratório",
            "Q35 - Fenda Palatina",
            "Q35.1 - Fenda do Palato Duro",
            "Q35.3 - Fenda do Palato Mole",
            "Q35.5 - Fenda do Palato Duro Com Fenda do Palato Mole",
            "Q35.7 - Fenda da Úvula",
            "Q35.9 - Fenda Palatina Não Especificada",
            "Q36 - Fenda Labial",
            "Q36.0 - Fenda Labial Bilateral",
            "Q36.1 - Fenda Labial Mediana",
            "Q36.9 - Fenda Labial Unilateral",
            "Q37 - Fenda Labial Com Fenda Palatina",
            "Q37.0 - Fenda do Palato Duro Com Fenda Labial Bilateral",
            "Q37.1 - Fenda do Palato Duro Com Fenda Labial Unilateral",
            "Q37.2 - Fenda do Palato Mole Com Fenda Labial Bilateral",
            "Q37.3 - Fenda do Palato Mole Com Fenda Labial Unilateral",
            "Q37.4 - Fenda Dos Palatos Duro e Mole Com Fenda Labial Bilateral",
            "Q37.5 - Fenda Dos Palatos Duro e Mole Com Fenda Labial Unilateral",
            "Q37.8 - Fenda do Palato Com Fenda Labial Bilateral, Não Especificada",
            "Q37.9 - Fenda do Palato Com Fenda Labial Unilateral, Não Especificada",
            "Q38 - Outras Malformações Congênitas da Língua, da Boca e da Faringe",
            "Q38.0 - Malformações Congênitas Dos Lábios, Não Classificadas em Outra Parte",
            "Q38.1 - Anquiloglossia",
            "Q38.2 - Macroglossia",
            "Q38.3 - Outras Malformações Congênitas da Língua",
            "Q38.4 - Malformações Congênitas Das Glândulas e Dutos Salivares",
            "Q38.5 - Malformações Congênitas do Palato Não Classificadas em Outra Parte",
            "Q38.6 - Outras Malformações Congênitas da Boca",
            "Q38.7 - Bolsa Faríngea",
            "Q38.8 - Outras Malformações Congênitas da Faringe",
            "Q39 - Malformações Congênitas do Esôfago",
            "Q39.0 - Atresia de Esôfago, Sem Fístula",
            "Q39.1 - Atresia de Esôfago, Com Fístula Traqueoesofágica",
            "Q39.2 - Fístula Traqueoesofágica Congênita, Sem Atresia",
            "Q39.3 - Estenose Congênita e Estreitamento Congênito do Esôfago",
            "Q39.4 - Pterígio do Esôfago",
            "Q39.5 - Dilatação Congênita do Esôfago",
            "Q39.6 - Divertículo do Esôfago",
            "Q39.8 - Outras Malformações Congênitas do Esôfago",
            "Q39.9 - Malformação Congênita Não Especificada do Esôfago",
            "Q40 - Outras Malformações Congênitas do Trato Digestivo Superior",
            "Q40.0 - Estenose Hipertrófica Congênita do Piloro",
            "Q40.1 - Hérnia Congênita de Hiato",
            "Q40.2 - Outras Malformações Congênitas Especificadas do Estômago",
            "Q40.3 - Malformação Congênita Não Especificada do Estômago",
            "Q40.8 - Outras Malformações Congênitas Especificadas do Trato Digestivo Superior",
            "Q40.9 - Malformação Congênita Não Especificada do Trato Digestivo Superior",
            "Q41 - Ausência, Atresia e Estenose Congênita do Intestino Delgado",
            "Q41.0 - Ausência, Atresia e Estenose Congênita do Duodeno",
            "Q41.1 - Ausência, Atresia e Estenose Congênita do Jejuno",
            "Q41.2 - Ausência, Atresia e Estenose Congênita do Íleo",
            "Q41.8 - Ausência, Atresia e Estenose Congênita de Outras Partes Especificadas do Intestino Delgado",
            "Q41.9 - Ausência, Atresia e Estenose Congênita do Intestino Delgado, Sem Especificação de Localização",
            "Q42 - Ausência, Atresia e Estenose Congênita do Cólon",
            "Q42.0 - Ausência, Atresia e Estenose Congênita do Reto, Com Fístula",
            "Q42.1 - Ausência, Atresia e Estenose Congênita do Reto, Sem Fístula",
            "Q42.2 - Ausência, Atresia e Estenose Congênita do Ânus, Com Fístula",
            "Q42.3 - Ausência, Atresia e Estenose Congênita do Ânus, Sem Fístula",
            "Q42.8 - Ausência, Atresia e Estenose Congênita de Outras Partes do Cólon (intestino Grosso)",
            "Q42.9 - Ausência, Atresia e Estenose Congênita de Partes Não Especificadas do Cólon (intestino Grosso)",
            "Q43 - Outras Malformações Congênitas do Intestino",
            "Q43.0 - Divertículo de Meckel",
            "Q43.1 - Doença de Hirschsprung",
            "Q43.2 - Outros Transtornos Funcionais Congênitos do Cólon",
            "Q43.3 - Malformações Congênitas da Fixação do Intestino",
            "Q43.4 - Duplicação do Intestino",
            "Q43.5 - Ânus Ectópico",
            "Q43.6 - Fístula Congênita do Reto e do Ânus",
            "Q43.7 - Persistência de Cloaca",
            "Q43.8 - Outras Malformações Congênitas Especificadas do Intestino",
            "Q43.9 - Malformação Congênita Não Especificada do Intestino",
            "Q44 - Malformações Congênitas da Vesícula Biliar, Das Vias Biliares e do Fígado",
            "Q44.0 - Agenesia, Aplasia e Hipoplasia da Vesícula Biliar",
            "Q44.1 - Outras Malformações Congênitas da Vesícula Biliar",
            "Q44.2 - Atresia Das Vias Biliares",
            "Q44.3 - Estenose e Estreitamento Congênitos Das Vias Biliares",
            "Q44.4 - Cisto do Colédoco",
            "Q44.5 - Outras Malformações Congênitas Das Vias Biliares",
            "Q44.6 - Doença Cística do Fígado",
            "Q44.7 - Outras Malformações Congênitas do Fígado",
            "Q45 - Outras Malformações Congênitas do Aparelho Digestivo",
            "Q45.0 - Agenesia, Aplasia e Hipoplasia do Pâncreas",
            "Q45.1 - Pâncreas Anular",
            "Q45.2 - Cisto Pancreático Congênito",
            "Q45.3 - Outras Malformações Congênitas do Pâncreas e do Duto Pancreático",
            "Q45.8 - Outras Malformações Congênitas Especificadas do Aparelho Digestivo",
            "Q45.9 - Malformação Congênita Não Especificada do Aparelho Digestivo",
            "Q50 - Malformações Congênitas Dos Ovários, Das Trompas de Falópio e Dos Ligamentos Largos",
            "Q50.0 - Ausência Congênita Dos Ovários",
            "Q50.1 - Cisto Ovariano de Desenvolvimento",
            "Q50.2 - Torsão Congênita do Ovário",
            "Q50.3 - Outras Malformações Congênitas do Ovário",
            "Q50.4 - Cisto Embrionário da Trompa de Falópio",
            "Q50.5 - Cisto Embrionário do Ligamento Largo",
            "Q50.6 - Outras Malformações Congênitas Das Trompas de Falópio e Dos Ligamentos Largos",
            "Q51 - Malformações Congênitas do Útero e do Colo do Útero",
            "Q51.0 - Agenesia e Aplasia do Útero",
            "Q51.1 - Útero Duplo Com Duplicação do Colo Uterino e da Vagina",
            "Q51.2 - Outra Duplicação do Útero",
            "Q51.3 - Útero Bicórneo",
            "Q51.4 - Útero Unicórneo",
            "Q51.5 - Agenesia e Aplasia do Colo do Útero",
            "Q51.6 - Cisto Embrionário do Colo do Útero",
            "Q51.7 - Fístula Congênita Útero-digestiva ou Útero-urinária",
            "Q51.8 - Outras Malformações Congênitas do Útero e do Colo do Útero",
            "Q51.9 - Malformação Congênita Não Especificada do Útero e do Colo do Útero SOE",
            "Q52 - Outras Malformações Congênitas Dos Órgãos Genitais Femininos",
            "Q52.0 - Ausência Congênita da Vagina",
            "Q52.1 - Duplicação da Vagina",
            "Q52.2 - Fístula Reto-vaginal Congênita",
            "Q52.3 - Imperfuração do Hímen",
            "Q52.4 - Outras Malformações Congênitas da Vagina",
            "Q52.5 - Fusão Dos Lábios Vulvares",
            "Q52.6 - Malformação Congênita do Clitóris",
            "Q52.7 - Outras Malformações Congênitas da Vulva",
            "Q52.8 - Outras Malformações Congênitas Especificadas Dos Órgãos Genitais Femininos",
            "Q52.9 - Malformação Congênita Não Especificada Dos Órgãos Genitais Femininos",
            "Q53 - Testículo Não-descido",
            "Q53.0 - Testículo Ectópico",
            "Q53.1 - Testículo Não-descido, Unilateral",
            "Q53.2 - Testículo Não-descido, Bilateral",
            "Q53.9 - Testículo Não-descido, Não Especificado",
            "Q54 - Hipospádias",
            "Q54.0 - Hipospádia Balânica",
            "Q54.1 - Hipospádia Peniana",
            "Q54.2 - Hipospádia Penoscrotal",
            "Q54.3 - Hipospádia Perineal",
            "Q54.4 - Corda Venérea Congênita",
            "Q54.8 - Outras Hipospádias",
            "Q54.9 - Hipospádia Não Especificada",
            "Q55 - Outras Malformações Congênitas Dos Órgãos Genitais Masculinos",
            "Q55.0 - Ausência e Aplasia do Testículo",
            "Q55.1 - Hipoplasia Do(s) Testículo(s) e do Escroto",
            "Q55.2 - Outras Malformações Congênitas Do(s) Testículo(s) e do Escroto",
            "Q55.3 - Atresia do Canal Deferente",
            "Q55.4 - Outras Malformações Congênitas do Canal Deferente, do Epidídimo, Das Vesículas Seminais e da Próstata",
            "Q55.5 - Ausência e Aplasia Congênitas do Pênis",
            "Q55.6 - Outras Malformações Congênitas do Pênis",
            "Q55.8 - Outras Malformações Congênitas Especificadas Dos Órgãos Genitais Masculinos",
            "Q55.9 - Malformação Congênita Não Especificada Dos Órgãos Genitais Masculinos",
            "Q56 - Sexo Indeterminado e Pseudo-hermafroditismo",
            "Q56.0 - Hermafroditismo Não Classificado em Outra Parte",
            "Q56.1 - Pseudo-hermafroditismo Masculino, Não Classificado em Outra Parte",
            "Q56.2 - Pseudo-hermafroditismo Feminino, Não Classificado em Outra Parte",
            "Q56.3 - Pseudo-hermafroditismo Não Especificado",
            "Q56.4 - Sexo Indeterminado, Não Especificado",
            "Q60 - Agenesia Renal e Outros Defeitos de Redução do Rim",
            "Q60.0 - Agenesia Unilateral do Rim",
            "Q60.1 - Agenesia Bilateral do Rim",
            "Q60.2 - Agenesia Renal Não Especificada",
            "Q60.3 - Hipoplasia Renal Unilateral",
            "Q60.4 - Hipoplasia Renal Bilateral",
            "Q60.5 - Hipoplasia Renal Não Especificada",
            "Q60.6 - Síndrome de Potter",
            "Q61 - Doenças Císticas do Rim",
            "Q61.0 - Cisto Congênito Único do Rim",
            "Q61.1 - Rim Policístico, Autossômico Recessivo",
            "Q61.2 - Rim Policístico, Autossômico Dominante",
            "Q61.3 - Rim Policístico Não Especificado",
            "Q61.4 - Displasia Renal",
            "Q61.5 - Cisto Medular do Rim",
            "Q61.8 - Outras Doenças Císticas do Rim",
            "Q61.9 - Doença Cística Não Especificada do Rim",
            "Q62 - Anomalias Congênitas Obstrutivas da Pelve Renal e Malformações Congênitas do Ureter",
            "Q62.0 - Hidronefrose Congênita",
            "Q62.1 - Atresia e Estenose do Ureter",
            "Q62.2 - Megaureter Congênito",
            "Q62.3 - Outras Anomalias Obstrutivas da Pelve Renal e do Ureter",
            "Q62.4 - Agenesia do Ureter",
            "Q62.5 - Duplicação do Ureter",
            "Q62.6 - Má-posição do Ureter",
            "Q62.7 - Refluxo Vésico-uretero-renal Congênito",
            "Q62.8 - Outras Malformações Congênitas do Ureter",
            "Q63 - Outras Malformações Congênitas do Rim",
            "Q63.0 - Rim Supranumerário",
            "Q63.1 - Rim Lobulado, Fundido ou em Ferradura",
            "Q63.2 - Rim Ectópico",
            "Q63.3 - Rim Hiperplásico e Gigante",
            "Q63.8 - Outras Malformações Congênitas Especificadas do Rim",
            "Q63.9 - Malformação Congênita Não Especificada do Rim",
            "Q64 - Outras Malformações Congênitas do Aparelho Urinário",
            "Q64.0 - Epispádias",
            "Q64.1 - Extrofia Vesical",
            "Q64.2 - Válvulas Uretrais Posteriores Congênitas",
            "Q64.3 - Outras Formas de Atresia e de Estenose de Uretra e do Colo da Bexiga",
            "Q64.4 - Malformação do Úraco",
            "Q64.5 - Ausência Congênita da Bexiga e da Uretra",
            "Q64.6 - Divertículo Congênito da Bexiga",
            "Q64.7 - Outras Malformações Congênitas da Bexiga e da Uretra",
            "Q64.8 - Outras Malformações Congênitas Especificadas do Aparelho Urinário",
            "Q64.9 - Malformação Congênita Não Especificada do Aparelho Urinário",
            "Q65 - Malformações Congênitas do Quadril",
            "Q65.0 - Luxação Congênita Unilateral do Quadril",
            "Q65.1 - Luxação Congênita Bilateral do Quadril",
            "Q65.2 - Luxação Congênita Não Especificada do Quadril",
            "Q65.3 - Subluxação Congênita Unilateral do Quadril",
            "Q65.4 - Subluxação Congênita Bilateral do Quadril",
            "Q65.5 - Subluxação Congênita Não Especificada do Quadril",
            "Q65.6 - Quadril Instável",
            "Q65.8 - Outras Deformidades Congênitas do Quadril",
            "Q65.9 - Deformidade Congênita Não Especificada do Quadril",
            "Q66 - Deformidades Congênitas do pé",
            "Q66.0 - Pé Torto Eqüinovaro",
            "Q66.1 - Pé Torto Calcaneovaro",
            "Q66.2 - Metatarso Varo",
            "Q66.3 - Outras Deformidades Congênitas Dos Pés em Varo",
            "Q66.4 - Pé Torto Calcaneovalgo",
            "Q66.5 - Pé Chato Congênito",
            "Q66.6 - Outras Deformidades Congênitas Dos Pés em Valgo",
            "Q66.7 - Pé Cavo",
            "Q66.8 - Outras Deformidades Congênitas do pé",
            "Q66.9 - Deformidade Congênita Não Especificada do pé",
            "Q67 - Deformidades Osteomusculares Congênitas da Cabeça, da Face, da Coluna e do Tórax",
            "Q67.0 - Assimetria Facial",
            "Q67.1 - Deformidade Facial Por Compressão",
            "Q67.2 - Dolicocefalia",
            "Q67.3 - Plagiocefalia",
            "Q67.4 - Outras Deformidades Congênitas do Crânio, da Face e da Mandíbula",
            "Q67.5 - Deformidades Congênitas da Coluna Vertebral",
            "Q67.6 - Tórax Escavado",
            "Q67.7 - Tórax Carinado",
            "Q67.8 - Outras Deformidades Congênitas do Tórax",
            "Q68 - Outras Deformidades Osteomusculares Congênitas",
            "Q68.0 - Deformidade Congênita do Músculo Esternocleidomastoideu",
            "Q68.1 - Deformidade Congênita da Mão",
            "Q68.2 - Deformidade Congênita do Joelho",
            "Q68.3 - Encurvamento Congênito do Fêmur",
            "Q68.4 - Encurvamento Congênito da Tíbia e da Perônio (fíbula)",
            "Q68.5 - Encurvamento Congênito de Ossos Longos Não Especificados do Membro Inferior",
            "Q68.8 - Outras Deformidades Osteomusculares Congênitas",
            "Q69 - Polidactilia",
            "Q69.0 - Dedo(s) da Mão Supranumerário(s)",
            "Q69.1 - Polegar(es) Supranumerário(s)",
            "Q69.2 - Artelho(s) Supranumerário(s)",
            "Q69.9 - Polidactilia Não Especificada",
            "Q70 - Sindactilia",
            "Q70.0 - Coalescência Dos Dedos (dedos da Mão Fundidos)",
            "Q70.1 - Dedos Palmados",
            "Q70.2 - Coalescência Dos Artelhos (artelhos Fundidos)",
            "Q70.3 - Artelhos Palmados",
            "Q70.4 - Polissindactilia",
            "Q70.9 - Sindactilia Não Especificada",
            "Q71 - Defeitos, Por Redução, do Membro Superior",
            "Q71.0 - Ausência Congênita Completa Do(s) Membro(s) Superior(es)",
            "Q71.1 - Ausência Congênita do Braço e do Antebraço, Com Mão Presente",
            "Q71.2 - Ausência Congênita do Antebraço e da Mão",
            "Q71.3 - Ausência Congênita da Mão e de Dedo(s)",
            "Q71.4 - Defeito de Redução Longitudinal do Rádio",
            "Q71.5 - Defeito de Redução Longitudinal do Cúbito (ulna)",
            "Q71.6 - Mão em Garra de Lagosta",
            "Q71.8 - Outros Defeitos de Redução do Membro Superior",
            "Q71.9 - Defeito Por Redução do Membro Superior, Não Especificado",
            "Q72 - Defeitos, Por Redução, do Membro Inferior",
            "Q72.0 - Ausência Congênita Completa Do(s) Membro(s) Inferior(es)",
            "Q72.1 - Ausência Congênita da Coxa e da Perna Com pé Presente",
            "Q72.2 - Ausência Congênita da Perna e do pé",
            "Q72.3 - Ausência Congênita do pé e de Artelho(s)",
            "Q72.4 - Defeito Por Redução Longitudinal da Tíbia",
            "Q72.5 - Defeito Por Redução Longitudinal da Tíbia",
            "Q72.6 - Defeito Por Redução Longitudinal do Perônio (fíbula)",
            "Q72.7 - Pé Bífido",
            "Q72.8 - Outros Defeitos Por Redução Do(s) Membro(s) Inferior(es)",
            "Q72.9 - Defeito Não Especificado Por Redução do Membro Inferior",
            "Q73 - Defeitos Por Redução de Membro Não Especificado",
            "Q73.0 - Ausência Congênita de Membro(s) Não Especificado(s)",
            "Q73.1 - Focomelia, Membro(s) Não Especificado(s)",
            "Q73.8 - Outros Defeitos Por Redução de Membro(s) Não Especificado(s)",
            "Q74 - Outras Malformações Congênitas Dos Membros",
            "Q74.0 - Outras Malformações Congênitas Do(s) Membro(s) Superiores, Inclusive da Cintura Escapular",
            "Q74.1 - Malformação Congênita do Joelho",
            "Q74.2 - Outras Malformações Congênitas Do(s) Membro(s) Inferiores, Inclusive da Cintura Pélvica",
            "Q74.3 - Artrogripose Congênita Múltipla",
            "Q74.8 - Outras Malformações Congênitas Especificadas de Membro(s)",
            "Q74.9 - Malformações Congênitas Não Especificadas de Membro(s)",
            "Q75 - Outras Malformações Congênitas Dos Ossos do Crânio e da Face",
            "Q75.0 - Craniossinostose",
            "Q75.1 - Disostose Craniofacial",
            "Q75.2 - Hipertelorismo",
            "Q75.3 - Macrocefalia",
            "Q75.4 - Disostose Mandíbulo-facial",
            "Q75.5 - Disostose Óculo-mandibular",
            "Q75.8 - Outras Malformações Congênitas Especificadas Dos Ossos do Crânio e da Face",
            "Q75.9 - Malformação Congênita Não Especificada Dos Ossos do Crânio e da Face",
            "Q76 - Malformações Congênitas da Coluna Vertebral e Dos Ossos do Tórax",
            "Q76.0 - Espinha Bífida Oculta",
            "Q76.1 - Síndrome de Klippel-Feil",
            "Q76.2 - Espondilolistese Congênita",
            "Q76.3 - Escoliose Congênita Devida à Malformação Óssea Congênita",
            "Q76.4 - Outras Malformações Congênitas da Coluna Vertebral Não-associadas Com Escoliose",
            "Q76.5 - Costela Cervical",
            "Q76.6 - Outras Malformações Congênitas Das Costelas",
            "Q76.7 - Malformação Congênita do Esterno",
            "Q76.8 - Outras Malformações Congênitas Dos Ossos do Tórax",
            "Q76.9 - Malformação Congênita Não Especificada Dos Ossos do Tórax",
            "Q77 - Osteocondrodisplasia Com Anomalias de Crescimento Dos Ossos Longos e da Coluna Vertebral",
            "Q77.0 - Acondrogenesia",
            "Q77.1 - Nanismo Tanatofórico",
            "Q77.2 - Síndrome Das Costelas Curtas",
            "Q77.3 - Condrodisplasia Puntacta",
            "Q77.4 - Acondroplasia",
            "Q77.5 - Displasia Diastrófica",
            "Q77.6 - Displasia Condroectodérmica",
            "Q77.7 - Displasia Espondiloepifisária",
            "Q77.8 - Outras Osteocondrodisplasias Com Anomalias do Crescimento Dos Ossos Longos e da Coluna Vertebral",
            "Q77.9 - Osteocondrodisplasia Não Especificada Com Anomalias do Crescimento Dos Ossos Longos e da Coluna Vertebral",
            "Q78 - Outras Osteocondrodisplasias",
            "Q78.0 - Osteogênese Imperfeita",
            "Q78.1 - Displasia Poliostótica Fibrosa",
            "Q78.2 - Osteopetrose",
            "Q78.3 - Displasia Diafisária Progressiva",
            "Q78.4 - Encondromatose",
            "Q78.5 - Displasia Metafisária",
            "Q78.6 - Exostoses Congênitas Múltiplas",
            "Q78.8 - Outras Osteocondrodisplasias Especificadas",
            "Q78.9 - Osteocondrodisplasia Não Especificada",
            "Q79 - Malformações Congênitas do Sistema Osteomuscular Não Classificadas em Outra Parte",
            "Q79.0 - Hérnia Diafragmática Congênita",
            "Q79.1 - Outras Malformações Congênitas do Diafragma",
            "Q79.2 - Exonfalia",
            "Q79.3 - Gastrosquise",
            "Q79.4 - Síndrome do Abdome em Ameixa Seca(prune Belly Syndrome)",
            "Q79.5 - Outras Malformações Congênitas da Parede Abdominal",
            "Q79.6 - Síndrome de Ehlers-Danlos",
            "Q79.8 - Outras Malformações Congênitas do Sistema Osteomuscular",
            "Q79.9 - Malformação Congênita Não Especificada do Sistema Osteomuscular",
            "Q80 - Ictiose Congênita",
            "Q80.0 - Ictiose Vulgar",
            "Q80.1 - Ictiose Ligada ao Cromossomo X",
            "Q80.2 - Ictiose Lamelar",
            "Q80.3 - Eritrodermia Ictiosiforme Bulhosa Congênita",
            "Q80.4 - Feto Arlequim",
            "Q80.8 - Outras Ictioses Congênitas",
            "Q80.9 - Ictiose Congênita Não Especificada",
            "Q81 - Epidermólise Bolhosa",
            "Q81.0 - Epidermólise Bolhosa Simples",
            "Q81.1 - Epidermólise Bolhosa Letal",
            "Q81.2 - Epidermólise Bolhosa Distrófica",
            "Q81.8 - Outras Epidermólises Bolhosas",
            "Q81.9 - Epidermólise Bolhosa Não Especificada",
            "Q82 - Outras Malformações Congênitas da Pele",
            "Q82.0 - Linfedema Hereditário",
            "Q82.1 - Xeroderma Pigmentoso",
            "Q82.2 - Mastocitose",
            "Q82.3 - Incontinentia Pigmenti",
            "Q82.4 - Displasia Ectodérmica (anidrótica)",
            "Q82.5 - Nevo Não-neoplásico Congênito",
            "Q82.8 - Outras Malformações Congênitas Especificadas da Pele",
            "Q82.9 - Malformação Congênita Não Especificada da Pele",
            "Q83 - Malformações Congênitas da Mama",
            "Q83.0 - Ausência Congênita da Mama Com Ausência do Mamilo",
            "Q83.1 - Mama Supranumerária",
            "Q83.2 - Ausência de Mamilo",
            "Q83.3 - Mamilo Acessório",
            "Q83.8 - Outras Malformações Congênitas da Mama",
            "Q83.9 - Malformação Congênita Não Especificada da Mama",
            "Q84 - Outras Malformações Congênitas do Tegumento",
            "Q84.0 - Alopécia Congênita",
            "Q84.1 - Alterações Morfológicas Congênitas Dos Cabelos Não Classificadas em Outra Parte",
            "Q84.2 - Outras Malformações Congênitas Dos Cabelos",
            "Q84.3 - Anoníquia",
            "Q84.4 - Leuconíquia Congênita",
            "Q84.5 - Hipertrofia e Alargamento Das Unhas",
            "Q84.6 - Outras Malformações Congênitas Das Unhas",
            "Q84.8 - Outras Malformações Congênitas Especificadas do Tegumento",
            "Q84.9 - Malformação Congênita Não Especificada do Tegumento",
            "Q85 - Facomatoses Não Classificadas em Outra Parte",
            "Q85.0 - Neurofibromatose (não-maligna)",
            "Q85.1 - Esclerose Tuberosa",
            "Q85.8 - Outras Facomatoses Não Classificadas em Outra Parte",
            "Q85.9 - Facomatose Não Especificada",
            "Q86 - Síndromes Com Malformações Congênitas Devidas a Causas Exógenas Conhecidas, Não Classificadas em Outra Parte",
            "Q86.0 - Síndrome Fetal Alcoólico (dismórfico)",
            "Q86.1 - Síndrome Fetal Devida à Hidantoína",
            "Q86.2 - Dismorfismo Devido ao Warfarin",
            "Q86.8 - Outras Síndromes Com Malformações Congênitas Devidas a Causas Exógenas Conhecidas",
            "Q87 - Outras Síndromes Com Malformações Congênitas Que Acometem Múltiplos Sistemas",
            "Q87.0 - Síndromes Com Malformações Congênitas Afetando Predominantemente o Aspecto da Face",
            "Q87.1 - Síndromes Com Malformações Congênitas Associadas Predominantemente Com Nanismo",
            "Q87.2 - Síndromes Com Malformações Congênitas Afetando Predominantemente os Membros",
            "Q87.3 - Síndromes Com Malformações Congênitas Com Hipercrescimento Precoce",
            "Q87.4 - Síndrome de Marfan",
            "Q87.5 - Outras Síndromes Com Malformações Congênitas Com Outras Alterações do Esqueleto",
            "Q87.8 - Outras Síndromes Com Malformações Congênitas Especificadas, Não Classificadas em Outra Parte",
            "Q89 - Outras Malformações Congênitas Não Classificadas em Outra Parte",
            "Q89.0 - Malformações Congênitas do Baço",
            "Q89.1 - Malformações Congênitas Das Supra-renais",
            "Q89.2 - Malformações Congênitas de Outras Glândulas Endócrinas",
            "Q89.3 - Situs Inversus",
            "Q89.4 - Reunião de Gêmeos",
            "Q89.7 - Malformações Congênitas Múltiplas, Não Classificadas em Outra Parte",
            "Q89.8 - Outras Malformações Congênitas Especificadas",
            "Q89.9 - Malformações Congênitas Não Especificadas",
            "Q90 - Síndrome de Down",
            "Q90.0 - Trissomia 21, Não-disjunção Meiótica",
            "Q90.1 - Trissomia 21, Mosaicismo (não-disjunção Mitótica)",
            "Q90.2 - Trissomia 21, Translocação",
            "Q90.9 - Síndrome de Down Não Especificada",
            "Q91 - Síndrome de Edwards e Síndrome de Patau",
            "Q91.0 - Trissomia 18, Não-disjunção Meiótica",
            "Q91.1 - Trissomia 18, Mosaicismo Cromossômico (não-disjunção Mitótica)",
            "Q91.2 - Trissomia 18, Translocação",
            "Q91.3 - Síndrome de Edwards Não Especificada",
            "Q91.4 - Trissomia 13, Não-disjunção Meiótica",
            "Q91.5 - Trissomia 13, Mosaicismo Cromossômico (não-disjunção Mitótica)",
            "Q91.6 - Trissomia 13, Translocação",
            "Q91.7 - Síndrome de Patau Não Especificada",
            "Q92 - Outras Trissomias e Trissomias Parciais Dos Autossomos, Não Classificadas em Outra Parte",
            "Q92.0 - Trissomia de um Cromossomo Inteiro, Não-disjunção Meiótica",
            "Q92.1 - Trissomia de um Cromossomo Inteiro, Mosaicismo Cromossômico (não-disjunção Mitótica)",
            "Q92.2 - Trissomia Parcial Major",
            "Q92.3 - Trissomia Parcial Minor",
            "Q92.4 - Duplicações Vistas Somente na Prometáfase",
            "Q92.5 - Duplicação Com Outros Rearranjos Complexos",
            "Q92.6 - Cromossomos Marcadores Suplementares",
            "Q92.7 - Triploidia e Poliploidia",
            "Q92.8 - Outras Trissomias Especificadas e Trissomias Parciais Dos Autossomos",
            "Q92.9 - Trissomia e Trissomia Parcial Não Especificada Dos Autossomos",
            "Q93 - Monossomias e Deleções Dos Autossomos, Não Classificadas em Outra Parte",
            "Q93.0 - Monossomia de Cromossomo Inteiro, Não-disjunção Meiótica",
            "Q93.1 - Monossomia de Cromossomo Inteiro, Mosaicismo Cromossômico (não-disjunção Mitótica)",
            "Q93.2 - Cromossomo Substituído Por Anel ou Dicêntrico",
            "Q93.3 - Deleção do Braço Curto do Cromossomo 4",
            "Q93.4 - Deleção do Braço Curto do Cromossomo 5",
            "Q93.5 - Outras Deleções Parciais de Cromossomo",
            "Q93.6 - Deleções Vistas Somente na Prometáfase",
            "Q93.7 - Deleções Com Outros Rearranjos Complexos",
            "Q93.8 - Outras Deleções Dos Autossomos",
            "Q93.9 - Deleções Não Especificadas Dos Autossomos",
            "Q95 - Rearranjos Equilibrados e Marcadores Estruturais, Não Classificados em Outra Parte",
            "Q95.0 - Translocação ou Inserção Equilibrada em Sujeito Normal",
            "Q95.1 - Inversão Cromossômica em Sujeito Normal",
            "Q95.2 - Rearranjo Autossômico Equilibrado em Sujeito Anormal",
            "Q95.3 - Rearranjo Sexual/autossômico Equilibrado em Sujeito Anormal",
            "Q95.4 - Sujeito Com Marcador de Heterocromatina",
            "Q95.5 - Sujeito Com Sítio Autossômico Frágil",
            "Q95.8 - Outros Rearranjos e Marcadores Equilibrados",
            "Q95.9 - Rearranjos e Marcadores Equilibrados Não Especificados",
            "Q96 - Síndrome de Turner",
            "Q96.0 - Cariótipo 45, X",
            "Q96.1 - Cariótipo 46, X Iso (Xq)",
            "Q96.2 - Cariótipo 46, X Com Cromossomo Sexual Anormal, Salvo Iso (Xq)",
            "Q96.3 - Mosaicismo Cromossômico, 45, X/46, XX ou XY",
            "Q96.4 - Mosaicismo Cromossômico, 45, X/outra(s) Linhagens Celular(es) Com Cromossomo Sexual Anormal",
            "Q96.8 - Outras Variantes da Síndrome de Turner",
            "Q96.9 - Síndrome de Turner Não Especificada",
            "Q97 - Outras Anomalias Dos Cromossomos Sexuais, Fenótipo Feminino, Não Classificadas em Outra Parte",
            "Q97.0 - Cariótipo 47, XXX",
            "Q97.1 - Mulher Com Mais de Três Cromossomos X",
            "Q97.2 - Mosaicismo Cromossômico, Linhagens Com Diversos Números de Cromossomos X",
            "Q97.3 - Mulher Com Cariótipo 46, XY",
            "Q97.8 - Outras Anomalias Especificadas Dos Cromossomos Sexuais, Fenótipo Feminino",
            "Q97.9 - Anomalias Não Especificadas Dos Cromossomos Sexuais, Fenótipo Feminino",
            "Q98 - Outras Anomalias Dos Cromossomos Sexuais, Fenótipo Masculino, Não Classificadas em Outra Parte",
            "Q98.0 - Síndrome de Klinefelter, Cariótipo 47, XXY",
            "Q98.1 - Síndrome de Klinefelter, Homem Com Mais de Dois Cromossomos X",
            "Q98.2 - Síndrome de Klinefelter, Homem Com Cariótipo 46, XX",
            "Q98.3 - Outro Homem Com Cariótipo 46, XX",
            "Q98.4 - Síndrome de Klinefelter Não Especificada",
            "Q98.5 - Cariótipo 47, XYY",
            "Q98.6 - Homem Com Cromossomos Sexuais de Estrutura Anormal",
            "Q98.7 - Homem Com Mosaicismo Dos Cromossomos Sexuais",
            "Q98.8 - Outras Anomalias Especificadas Dos Cromossomos Sexuais, Fenótipo Masculino",
            "Q98.9 - Anomalias Não Especificadas Dos Cromossomos Sexuais, Fenótipo Masculino",
            "Q99 - Outras Anomalias Dos Cromossomos, Não Classificadas em Outra Parte",
            "Q99.0 - Quimera 46, XX/46, XY",
            "Q99.1 - Hermafrodite Verdadeiro 46, XX",
            "Q99.2 - Cromossomo X Frágil",
            "Q99.8 - Outras Anomalias Cromossômicas Especificadas",
            "Q99.9 - Anomalia Cromossômica Não Especificada",
            "R00 - Anormalidades do Batimento Cardíaco",
            "R00.0 - Taquicardia Não Especificada",
            "R00.1 - Bradicardia Não Especificada",
            "R00.2 - Palpitações",
            "R00.8 - Outras Anormalidades e as Não Especificadas do Batimento Cardíaco",
            "R01 - Sopros e Outros Ruídos Cardíacos",
            "R01.0 - Sopros Cardíacos Benignos ou Inocentes",
            "R01.1 - Sopro Cardíaco, Não Especificado",
            "R01.2 - Outros Ruídos Cardíacos",
            "R02 - Gangrena Não Classificada em Outra Parte",
            "R03 - Valor Anormal da Pressão Arterial Sem Diagnóstico",
            "R03.0 - Valor Elevado da Pressão Arterial Sem o Diagnóstico de Hipertensão",
            "R03.1 - Valor Baixo da Pressão Arterial Não Específico",
            "R04 - Hemorragia Das Vias Respiratórias",
            "R04.0 - Epistaxis",
            "R04.1 - Hemorragia da Garganta",
            "R04.2 - Hemoptise",
            "R04.8 - Hemorragia de Outras Localizações Das Vias Respiratórias",
            "R04.9 - Hemorragia Não Especificada Das Vias Respiratórias",
            "R05 - Tosse",
            "R06 - Anormalidades da Respiração",
            "R06.0 - Dispnéia",
            "R06.1 - Estridor",
            "R06.2 - Respiração Ofegante",
            "R06.3 - Respiração Periódica",
            "R06.4 - Hiperventilação",
            "R06.5 - Respiração Pela Boca",
            "R06.6 - Soluço",
            "R06.7 - Espirro",
            "R06.8 - Outras Anormalidades e as Não Especificadas da Respiração",
            "R07 - Dor de Garganta e no Peito",
            "R07.0 - Dor de Garganta",
            "R07.1 - Dor Torácica ao Respirar",
            "R07.2 - Dor Precordial",
            "R07.3 - Outra Dor Torácica",
            "R07.4 - Dor Torácica, Não Especificada",
            "R09 - Outros Sintomas e Sinais Relativos Aos Aparelhos Circulatório e Respiratório",
            "R09.0 - Asfixia",
            "R09.1 - Pleurisia",
            "R09.2 - Parada Respiratória",
            "R09.3 - Escarro Anormal",
            "R09.8 - Outros Sintomas e Sinais Especificados Relativos Aos Aparelhos Circulatório e Respiratório",
            "R10 - Dor Abdominal e Pélvica",
            "R10.0 - Abdome Agudo",
            "R10.1 - Dor Localizada no Abdome Superior",
            "R10.2 - Dor Pélvica e Perineal",
            "R10.3 - Dor Localizada em Outras Partes do Abdome Inferior",
            "R10.4 - Outras Dores Abdominais e as Não Especificadas",
            "R11 - Náusea e Vômitos",
            "R12 - Pirose",
            "R13 - Disfagia",
            "R14 - Flatulência e Afecções Correlatas",
            "R15 - Incontinência Fecal",
            "R16 - Hepatomegalia e Esplenomegalia Não Classificadas em Outra Parte",
            "R16.0 - Hepatomegalia Não Classificada em Outra Parte",
            "R16.1 - Esplenomegalia Não Classificada em Outra Parte",
            "R16.2 - Hepatomegalia Com Esplenomegalia Não Classificada em Outra Parte",
            "R17 - Icterícia Não Especificada",
            "R18 - Ascite",
            "R19 - Outros Sintomas e Sinais Relativos ao Aparelho Digestivo e ao Abdome",
            "R19.0 - Massa, Tumoração ou Tumefação Intra-abdominal e Pélvica",
            "R19.1 - Ruídos Hidroaéreos Anormais",
            "R19.2 - Peristaltismo Visível",
            "R19.3 - Rigidez Abdominal",
            "R19.4 - Alteração do Hábito Intestinal",
            "R19.5 - Outras Anormalidades Fecais",
            "R19.6 - Halitose",
            "R19.8 - Outros Sintomas e Sinais Especificados Relativos ao Aparelho Digestivo e ao Abdome",
            "R20 - Distúrbios da Sensibilidade Cutânea",
            "R20.0 - Anestesia Cutânea",
            "R20.1 - Hipoestesia Cutânea",
            "R20.2 - Parestesias Cutâneas",
            "R20.3 - Hiperestesia",
            "R20.8 - Outros Distúrbios e os Não Especificadas da Sensibilidade Cutânea",
            "R21 - Eritema e Outras Erupções Cutâneas Não Especificadas",
            "R22 - Tumefação, Massa ou Tumoração Localizadas da Pele e do Tecido Subcutâneo",
            "R22.0 - Tumefação, Massa ou Tumoração Localizadas da Cabeça",
            "R22.1 - Tumefação, Massa ou Tumoração Localizadas do Pescoço",
            "R22.2 - Tumefação, Massa ou Tumoração Localizadas do Tronco",
            "R22.3 - Tumefação, Massa ou Tumoração Localizadas de Membro Superior",
            "R22.4 - Tumefação, Massa ou Tumoração Localizadas no Membro Inferior",
            "R22.7 - Tumefação, Massa ou Tumoração Localizadas de Múltiplas Localizações",
            "R22.9 - Tumefação, Massa ou Tumoração Não Especificadas, Localizadas",
            "R23 - Outras Alterações Cutâneas",
            "R23.0 - Cianose",
            "R23.1 - Palidez",
            "R23.2 - Rubor",
            "R23.3 - Equimoses Espontâneas",
            "R23.4 - Alterações na Textura da Pele",
            "R23.8 - Outras Alterações da Pele e as Não Especificadas",
            "R25 - Movimentos Involuntários Anormais",
            "R25.0 - Movimentos Anormais da Cabeça",
            "R25.1 - Tremor Não Especificado",
            "R25.2 - Cãibras e Espasmos",
            "R25.3 - Fasciculação",
            "R25.8 - Outros Movimentos Involuntários Anormais e os Não Especificados",
            "R26 - Anormalidades da Marcha e da Mobilidade",
            "R26.0 - Marcha Atáxica",
            "R26.1 - Marcha Paralítica",
            "R26.2 - Dificuldade Para Andar Não Classificada em Outra Parte",
            "R26.8 - Outras Anormalidades da Marcha e da Mobilidade e as Não Especificadas",
            "R27 - Outros Distúrbios da Coordenação",
            "R27.0 - Ataxia Não Especificada",
            "R27.8 - Outros Distúrbios da Coordenação e os Não Especificados",
            "R29 - Outros Sintomas e Sinais Relativos Aos Sistemas Nervoso e Osteomuscular",
            "R29.0 - Tetania",
            "R29.1 - Meningismo",
            "R29.2 - Reflexos Anormais",
            "R29.3 - Postura Anormal",
            "R29.4 - Quadril Estalante",
            "R29.6 - Tendência a Queda, Não Classificada em Outra Parte",
            "R29.8 - Outros Sintomas e Sinais Relativos Aos Sistemas Nervoso e Osteomuscular e os Não Especificados",
            "R30 - Dor Associada à Micção",
            "R30.0 - Disúria",
            "R30.1 - Tenesmo Vesical",
            "R30.9 - Micção Dolorosa, Não Especificada",
            "R31 - Hematúria Não Especificada",
            "R32 - Incontinência Urinária Não Especificada",
            "R33 - Retenção Urinária",
            "R34 - Anúria e Oligúria",
            "R35 - Poliúria",
            "R36 - Secreção Uretral",
            "R39 - Outros Sintomas e Sinais Relativos ao Aparelho Urinário",
            "R39.0 - Extravasamento de Urina",
            "R39.1 - Outras Dificuldades à Micção",
            "R39.2 - Uremia Extra-renal",
            "R39.8 - Outros Sintomas e Sinais Relativos ao Aparelho Urinário e os Não Especificados",
            "R40 - Sonolência, Estupor e Coma",
            "R40.0 - Sonolência",
            "R40.1 - Estupor",
            "R40.2 - Coma Não Especificado",
            "R41 - Outros Sintomas e Sinais Relativos à Função Cognitiva e à Consciência",
            "R41.0 - Desorientação Não Especificada",
            "R41.1 - Amnésia Anterógrada",
            "R41.2 - Amnésia Retrógrada",
            "R41.3 - Outra Amnésia",
            "R41.8 - Outros Sintomas e Sinais Especificados Relativos às Funções Cognitivas e à Consciência",
            "R42 - Tontura e Instabilidade",
            "R43 - Distúrbios do Olfato e do Paladar",
            "R43.0 - Anosmia",
            "R43.1 - Parosmia",
            "R43.2 - Parageusia",
            "R43.8 - Outros Distúrbios do Olfato e do Paladar e os Não Especificados",
            "R44 - Outros Sintomas e Sinais Relativos às Sensações e às Percepções Gerais",
            "R44.0 - Alucinações Auditivas",
            "R44.1 - Alucinações Visuais",
            "R44.2 - Outras Alucinações",
            "R44.3 - Alucinações Não Especificadas",
            "R44.8 - Outros Sintomas e Sinais Especificados Relativos às Sensações e Percepções Gerais",
            "R45 - Sintomas e Sinais Relativos ao Estado Emocional",
            "R45.0 - Nervosismo",
            "R45.1 - Agitação e Inquietação",
            "R45.2 - Tristeza",
            "R45.3 - Apatia e Desinteresse",
            "R45.4 - Irritabilidade e Mau Humor",
            "R45.5 - Hostilidade",
            "R45.6 - Violência Física",
            "R45.7 - Estado de Choque Emocional e Tensão, Não Especificado",
            "R45.8 - Outros Sintomas e Sinais Relativos ao Estado Emocional",
            "R46 - Sintomas e Sinais Relativos à Aparência e ao Comportamento",
            "R46.0 - Baixo Nível de Higiene Pessoal",
            "R46.1 - Aparência Pessoal Bizarra",
            "R46.2 - Comportamento Estranho e Inexplicável",
            "R46.3 - Hiperatividade",
            "R46.4 - Lentidão e Baixa Reatividade",
            "R46.5 - Personalidade Suspeita e Evasiva",
            "R46.6 - Inquietação e Preocupação Exageradas Com Acontecimentos estressantes",
            "R46.7 - Verborragia e Pormenores Circunstanciais Mascarando o Motivo da Consulta",
            "R46.8 - Outros Sintomas e Sinais Relativos à Aparência e ao Comportamento",
            "R47 - Distúrbios da Fala Não Classificados em Outra Parte",
            "R47.0 - Disfasia e Afasia",
            "R47.1 - Disartria e Anartria",
            "R47.8 - Outros Distúrbios da Fala e os Não Especificados",
            "R48 - Dislexia e Outras Disfunções Simbólicas, Não Classificadas em Outra Parte",
            "R48.0 - Dislexia e Alexia",
            "R48.1 - Agnosia",
            "R48.2 - Apraxia",
            "R48.8 - Outras Disfunções Simbólicas e as Não Especificadas",
            "R49 - Distúrbios da Voz",
            "R49.0 - Disfonia",
            "R49.1 - Afonia",
            "R49.2 - Hipernasalidade e Hiponasalidade",
            "R49.8 - Outros Distúrbios da Voz e os Não Especificados",
            "R50 - Febre de Origem Desconhecida e de Outras Origens",
            "R50.2 - Febre Induzida Por Drogas",
            "R50.8 - Outra Febre Especificada",
            "R50.9 - Febre Não Especificada",
            "R51 - Cefaléia",
            "R52 - Dor Não Classificada em Outra Parte",
            "R52.0 - Dor Aguda",
            "R52.1 - Dor Crônica Intratável",
            "R52.2 - Outra Dor Crônica",
            "R52.9 - Dor Não Especificada",
            "R53 - Mal Estar, Fadiga",
            "R54 - Senilidade",
            "R55 - Síncope e Colapso",
            "R56 - Convulsões, Não Classificadas em Outra Parte",
            "R56.0 - Convulsões Febris",
            "R56.8 - Outras Convulsões e as Não Especificadas",
            "R57 - Choque Não Classificado em Outra Parte",
            "R57.0 - Choque Cardiogênico",
            "R57.1 - Choque Hipovolêmico",
            "R57.8 - Outras Formas de Choque",
            "R57.9 - Choque Não Especificado",
            "R58 - Hemorragia Não Classificada em Outra Parte",
            "R59 - Aumento de Volume Dos Gânglios Linfáticos",
            "R59.0 - Aumento de Volume Localizado de Gânglios Linfáticos",
            "R59.1 - Aumento de Volume Generalizado de Gânglios Linfáticos",
            "R59.9 - Adenomegalia ou Aumento de Volume Dos Gânglios Linfáticos, Não Especificado",
            "R60 - Edema Não Classificado em Outra Parte",
            "R60.0 - Edema Localizado",
            "R60.1 - Edema Generalizado",
            "R60.9 - Edema Não Especificado",
            "R61 - Hiperidrose",
            "R61.0 - Hiperidrose Localizada",
            "R61.1 - Hiperidrose Generalizada",
            "R61.9 - Hiperidrose Não Especificada",
            "R62 - Retardo do Desenvolvimento Fisiológico Normal",
            "R62.0 - Retardo de Maturação",
            "R62.8 - Outras Formas de Retardo do Desenvolvimento Fisiológico Normal",
            "R62.9 - Retardo do Desenvolvimento Fisiológico Normal, Não Especificado",
            "R63 - Sintomas e Sinais Relativos à Ingestão de Alimentos e Líquidos",
            "R63.0 - Anorexia",
            "R63.1 - Polidipsia",
            "R63.2 - Polifagia",
            "R63.3 - Dificuldades de Alimentação e Erros na Administração de Alimentos",
            "R63.4 - Perda de Peso Anormal",
            "R63.5 - Ganho de Peso Anormal",
            "R63.8 - Outros Sintomas e Sinais Relativos a Ingestão de Alimentos e de Líquidos",
            "R64 - Caquexia",
            "R68 - Outros Sintomas e Sinais Gerais",
            "R68.0 - Hipotermia Não Associada à Baixa Temperatura Ambiental",
            "R68.1 - Sintomas Não Específicos Peculiares à Infância",
            "R68.2 - Boca Seca, Não Especificada",
            "R68.3 - Baqueteamento Dos Dedos",
            "R68.8 - Outros Sintomas e Sinais Gerais Especificados",
            "R69 - Causas Desconhecidas e Não Especificadas de Morbidade",
            "R70 - Velocidade de Hemossedimentação Elevada e Outras Anormalidades da Viscosidade Plasmática",
            "R70.0 - Velocidade de Hemossedimentação Elevada",
            "R70.1 - Viscosidade Plasmática Anormal",
            "R71 - Anormalidade Das Hemácias",
            "R72 - Anormalidade Dos Leucócitos Não Classificada em Outra Parte",
            "R73 - Aumento da Glicemia",
            "R73.0 - Teste de Tolerância a Glicose Anormal",
            "R73.9 - Hiperglicemia Não Especificada",
            "R74 - Anormalidades Dos Níveis de Enzimas Séricas",
            "R74.0 - Aumento Dos Níveis de Transaminases e da Desidrogenase Lática (DHL)",
            "R74.8 - Níveis Anormais de Outras Enzimas Séricas",
            "R74.9 - Anormalidade Dos Níveis de Enzimas Séricas, Não Especificada",
            "R75 - Evidência Laboratorial do Vírus da Imunodeficiência Humana (HIV)",
            "R76 - Outros Achados Imunológicos Anormais no Soro",
            "R76.0 - Título Aumentado de Anticorpos",
            "R76.1 - Reação Anormal ao Teste da Tuberculina",
            "R76.2 - Exame Sorológico Falso Positivo Para Sífilis",
            "R76.8 - Outros Achados Imunológicos Especificados Anormais no Soro",
            "R76.9 - Achado Anormal de Exame Imunológico Sérico, Não Especificado",
            "R77 - Outras Anormalidades Das Proteínas Plasmáticas",
            "R77.0 - Anormalidade da Albumina",
            "R77.1 - Anormalidade Das Globulinas",
            "R77.2 - Anormalidade da Alfafetoproteína",
            "R77.8 - Outras Anormalidades Especificadas Das Proteínas Plasmáticas",
            "R77.9 - Anormalidades de Proteína Plasmática, Não Especificadas",
            "R78 - Presença de Drogas e de Outras Substâncias Normalmente Não Encontradas no Sangue",
            "R78.0 - Presença de Álcool no Sangue",
            "R78.1 - Presença de Opiáceos no Sangue",
            "R78.2 - Presença de Cocaína no Sangue",
            "R78.3 - Presença de Alucinógeno no Sangue",
            "R78.4 - Presença de Outras Drogas Com Potencial de Causar Dependência, no Sangue",
            "R78.5 - Presença de Droga Psicotrópica no Sangue",
            "R78.6 - Presença de Agente Esteróide no Sangue",
            "R78.7 - Presença de Nível Anormal de Metais Pesados no Sangue",
            "R78.8 - Presença de Outras Substâncias Especificadas Não Normalmente Encontradas no Sangue",
            "R78.9 - Presença de Substância Não Especificada Normalmente Não Encontrada no Sangue",
            "R79 - Outros Achados Anormais de Exames Químicos do Sangue",
            "R79.0 - Anormalidade do Nível de Elementos Minerais no Sangue",
            "R79.8 - Outros Achados Anormais Especificados de Exames Químicos do Sangue",
            "R79.9 - Achado Anormal de Exame Químico do Sangue, Não Especificado",
            "R80 - Proteinúria Isolada",
            "R81 - Glicosúria",
            "R82 - Outros Achados Anormais na Urina",
            "R82.0 - Quilúria",
            "R82.1 - Mioglobinúria",
            "R82.2 - Biliúria",
            "R82.3 - Hemoglobinúria",
            "R82.4 - Acetonúria",
            "R82.5 - Níveis Urinários Elevados de Drogas, Medicamentos e Substâncias Biológicas",
            "R82.6 - Níveis Urinários Anormais de Substâncias Cuja Origem é Essencialmente Não-medicinal",
            "R82.7 - Achados Anormais ao Exame Microbiológico da Urina",
            "R82.8 - Achados Anormais ao Exame Citológico e Histológico da Urina",
            "R82.9 - Outros Achados Anormais na Urina e os Não Especificados",
            "R83 - Achados Anormais no Líquido Cefalorraquidiano",
            "R83.0 - Achados Anormais no Líquido Cefalorraquidiano - Nível Enzimático Anormal",
            "R83.1 - Achados Anormais no Líquido Cefalorraquidiano - Nível Hormonal Anormal",
            "R83.2 - Achados Anormais no Líquido Cefalorraquidiano - Nível Anormal de Outras Drogas, Medicamentos e Substâncias Biológicas",
            "R83.3 - Achados Anormais no Líquido Cefalorraquidiano - Nível Anormal de Substâncias Cuja Origem é Essencialmente Não Medicinal",
            "R83.4 - Achados Anormais no Líquido Cefalorraquidiano - Achados Imunológicos Anormais",
            "R83.5 - Achados Anormais no Líquido Cefalorraquidiano - Achados Microbiológicos Anormais",
            "R83.6 - Achados Anormais no Líquido Cefalorraquidiano - Achados Citológicos Anormais",
            "R83.7 - Achados Anormais no Líquido Cefalorraquidiano - Achados Histológicos Anormais",
            "R83.8 - Achados Anormais no Líquido Cefalorraquidiano - Outros Achados Anormais",
            "R83.9 - Achados Anormais no Líquido Cefalorraquidiano - Achado Anormal Não Especificado",
            "R84 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax",
            "R84.0 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Nível Enzimático Anormal",
            "R84.1 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Nível Hormonal Anormal",
            "R84.2 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Nível Anormal de Outras Drogas, Medicamentos e Substâncias Biológicas",
            "R84.3 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Nível Anormal de Substâncias Cuja Origem é Essencialmente Não Medicinal",
            "R84.4 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Achados Imunológicos Anormais",
            "R84.5 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Achados Microbiológicos Anormais",
            "R84.6 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Achados Citológicos Anormais",
            "R84.7 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Achados Histológicos Anormais",
            "R84.8 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Outros Achados Anormais",
            "R84.9 - Achados Anormais de Material Proveniente Dos Órgãos Respiratórios e do Tórax - Achado Anormal Não Especificado",
            "R85 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal",
            "R85.0 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Nível Enzimático Anormal",
            "R85.1 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Nível Hormonal Anormal",
            "R85.2 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Nível Anormal de Outras Drogas, Medicamentos e Substâncias Biológicas",
            "R85.3 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Nível Anormal de Substâncias Cuja Origem é Essencialmente Não Medicinal",
            "R85.4 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Achados Imunológicos Anormais",
            "R85.5 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Achados Microbiológicos Anormais",
            "R85.6 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Achados Citológicos Anormais",
            "R85.7 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Achados Histológicos Anormais",
            "R85.8 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Outros Achados Anormais",
            "R85.9 - Achados Anormais de Material Proveniente Dos Órgãos Digestivos e da Cavidade Abdominal - Achado Anormal Não Especificado",
            "R86 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos",
            "R86.0 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Nível Enzimático Anormal",
            "R86.1 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Nível Hormonal Anormal",
            "R86.2 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Nível Anormal de Outras Drogas, Medicamentos e Substâncias Biológicas",
            "R86.3 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Nível Anormal de Substâncias Cuja Origem é Essencialmente Não Medicinal",
            "R86.4 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Achados Imunológicos Anormais",
            "R86.5 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Achados Microbiológicos Anormais",
            "R86.6 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Achados Citológicos Anormais",
            "R86.7 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Achados Histológicos Anormais",
            "R86.8 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Outros Achados Anormais",
            "R86.9 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Masculinos - Achado Anormal Não Especificado",
            "R87 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos",
            "R87.0 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Nível Enzimático Anormal",
            "R87.1 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Nível Hormonal Anormal",
            "R87.2 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Nível Anormal de Outras Drogas, Medicamentos e Substâncias Biológicas",
            "R87.3 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Nível Anormal de Substâncias Cuja Origem é Essencialmente Não Medicinal",
            "R87.4 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Achados Imunológicos Anormais",
            "R87.5 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Achados Microbiológicos Anormais",
            "R87.6 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Achados Citológicos Anormais",
            "R87.7 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Achados Histológicos Anormais",
            "R87.8 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Outros Achados Anormais",
            "R87.9 - Achados Anormais de Material Proveniente Dos Órgãos Genitais Femininos - Achado Anormal Não Especificado",
            "R89 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos",
            "R89.0 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Nível Enzimático Anormal",
            "R89.1 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Nível Hormonal Anormal",
            "R89.2 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Nível Anormal de Outras Drogas, Medicamentos e Substâncias Biológicas",
            "R89.3 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Nível Anormal de Substâncias Cuja Origem é Essencialmente Não Medicinal",
            "R89.4 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Achados Imunológicos Anormais",
            "R89.5 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Achados Microbiológicos Anormais",
            "R89.6 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Achados Citológicos Anormais",
            "R89.7 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Achados Histológicos Anormais",
            "R89.8 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Outros Achados Anormais",
            "R89.9 - Achados Anormais de Material Proveniente de Outros Órgãos, Aparelhos, Sistemas e Tecidos - Achado Anormal Não Especificado",
            "R90 - Resultados Anormais de Exames Para Diagnóstico Por Imagem do Sistema Nervoso Central",
            "R90.0 - Lesão Intracraniana Ocupando Espaço",
            "R90.8 - Outros Resultados Anormais de Exames Para Diagnóstico Por Imagem do Sistema Nervoso Central",
            "R91 - Achados Anormais, de Exames Para Diagnóstico Por Imagem, do Pulmão",
            "R92 - Achados Anormais, de Exames Para Diagnóstico Por Imagem, da Mama",
            "R93 - Achados Anormais de Exames Para Diagnóstico Por Imagem de Outras Estruturas do Corpo",
            "R93.0 - Achados Anormais de Exames Para Diagnóstico Por Imagem do Crânio e da Cabeça Não Classificados em Outra Parte",
            "R93.1 - Achados Anormais de Exames Para Diagnóstico Por Imagem do Coração e da Circulação Coronariana",
            "R93.2 - Achados Anormais de Exames Para Diagnóstico Por Imagem do Fígado e Das Vias Biliares",
            "R93.3 - Achados Anormais de Exames Para Diagnóstico Por Imagem de Outras Partes do Tubo Digestivo",
            "R93.4 - Achados Anormais de Exames Para Diagnóstico Por Imagem Dos Órgãos Urinários",
            "R93.5 - Achados Anormais de Exames Para Diagnóstico Por Imagem de Outras Regiões do Abdome, Incluindo o Retroperitônio",
            "R93.6 - Achados Anormais de Exames Para Diagnóstico Por Imagem Dos Membros",
            "R93.7 - Achados Anormais de Exames Para Diagnóstico Por Imagem de Outras Partes do Sistema Osteomuscular",
            "R93.8 - Achados Anormais de Exames Diagnósticos Por Imagem de Outras Estruturas Somáticas Especificadas",
            "R94 - Resultados Anormais de Estudos de Função",
            "R94.0 - Resultados Anormais de Estudos de Função do Sistema Nervoso Central",
            "R94.1 - Resultados Anormais de Estudos de Função do Sistema Nervoso Periférico e Dos Órgãos Dos Sentidos",
            "R94.2 - Resultados Anormais de Estudos da Função Pulmonar",
            "R94.3 - Resultados Anormais de Estudos da Função Cardiovascular",
            "R94.4 - Resultados Anormais de Estudos de Função Renal",
            "R94.5 - Resultados Anormais de Estudos de Função Hepática",
            "R94.6 - Resultados Anormais de Estudos de Função Tireoidiana",
            "R94.7 - Resultados Anormais de Estudos de Função de Outras Glândulas Endócrinas",
            "R94.8 - Resultados Anormais de Estudos de Função de Outros Órgãos, Aparelhos e Sistemas",
            "R95 - Síndrome da Morte Súbita na Infância",
            "R96 - Outras Mortes Súbitas de Causa Desconhecida",
            "R96.0 - Morte Instantânea",
            "R96.1 - Morte Que Ocorre em Menos de 24 Horas Após o Início Dos Sintomas, Que Não Pode Ser Explicada",
            "R98 - Morte Sem Assistência",
            "R99 - Outras Causas Mal Definidas e as Não Especificadas de Mortalidade",
            "S00 - Traumatismo Superficial da Cabeça",
            "S00.0 - Traumatismo Superficial do Couro Cabeludo",
            "S00.1 - Contusão da Pálpebra e da Região Periocular",
            "S00.2 - Outros Traumatismos Superficiais da Pálpebra e da Região Periocular",
            "S00.3 - Traumatismo Superficial do Nariz",
            "S00.4 - Traumatismo Superficial do Ouvido",
            "S00.5 - Traumatismo Superficial Dos Lábios e da Cavidade Oral",
            "S00.7 - Traumatismos Superficiais Múltiplos da Cabeça",
            "S00.8 - Traumatismo Superficial de Outras Partes da Cabeça",
            "S00.9 - Traumatismo Superficial da Cabeça, Parte Não Especificada",
            "S01 - Ferimento da Cabeça",
            "S01.0 - Ferimento do Couro Cabeludo",
            "S01.1 - Ferimento da Pálpebra e da Região Periocular",
            "S01.2 - Ferimento do Nariz",
            "S01.3 - Ferimento do Ouvido",
            "S01.4 - Ferimento da Bochecha e Região Têmporo-mandibular",
            "S01.5 - Ferimento do Lábio e da Cavidade Oral",
            "S01.7 - Ferimentos Múltiplos da Cabeça",
            "S01.8 - Ferimento na Cabeça, de Outras Localizações",
            "S01.9 - Ferimento na Cabeça, Parte Não Especificada",
            "S02 - Fratura do Crânio e Dos Ossos da Face",
            "S02.0 - Fratura da Abóbada do Crânio",
            "S02.1 - Fratura da Base do Crânio",
            "S02.2 - Fratura Dos Ossos Nasais",
            "S02.3 - Fratura do Assoalho Orbital",
            "S02.4 - Fratura Dos Ossos Malares e Maxilares",
            "S02.5 - Fratura de Dentes",
            "S02.6 - Fratura de Mandíbula",
            "S02.7 - Fraturas Múltiplas Envolvendo os Ossos do Crânio e da Face",
            "S02.8 - Outras Fraturas do Crânio e Dos Ossos da Face",
            "S02.9 - Fratura do Crânio ou Dos Ossos da Face, Parte Não Especificada",
            "S03 - Luxação, Entorse ou Distensão Das Articulações e Dos Ligamentos da Cabeça",
            "S03.0 - Luxação do Maxilar",
            "S03.1 - Luxação da Cartilagem do Septo Nasal",
            "S03.2 - Luxação Dentária",
            "S03.3 - Luxação de Outras Partes da Cabeça e Das Não Especificadas",
            "S03.4 - Entorse e Distensão do Maxilar",
            "S03.5 - Entorse e Distensão da Articulações e Dos Ligamentos de Outras Localizações e de Localizações Não Especificadas da Cabeça",
            "S04 - Traumatismo Dos Nervos Cranianos",
            "S04.0 - Traumatismo do Nervo e Das Vias Ópticas",
            "S04.1 - Traumatismo do Nervo Oculomotor",
            "S04.2 - Traumatismo do Nervo Troclear",
            "S04.3 - Traumatismo do Nervo Trigêmeo",
            "S04.4 - Traumatismo do Nervo Abducente",
            "S04.5 - Traumatismo do Nervo Facial",
            "S04.6 - Traumatismo do Nervo Acústico",
            "S04.7 - Traumatismo do Nervo Acessório",
            "S04.8 - Traumatismo de Outros Nervos Cranianos",
            "S04.9 - Traumatismo de Nervo Craniano Não Especificado",
            "S05 - Traumatismo do Olho e da Órbita Ocular",
            "S05.0 - Traumatismo da Conjuntiva e Abrasão da Córnea Sem Menção de Corpo Estranho",
            "S05.1 - Contusão do Globo Ocular e Dos Tecidos da Órbita",
            "S05.2 - Laceração e Ruptura Ocular Com Prolapso ou Perda de Tecido Intra-ocular",
            "S05.3 - Laceração Ocular Sem Prolapso ou Perda de Tecido Intra-ocular",
            "S05.4 - Ferimento Penetrante da Órbita Com ou Sem Corpo Estranho",
            "S05.5 - Ferimento Penetrante do Globo Ocular Com Corpo Estranho",
            "S05.6 - Ferimento Penetrante do Globo Ocular Sem Corpo Estranho",
            "S05.7 - Avulsão do Olho",
            "S05.8 - Outros Traumatismos do Olho e da Órbita",
            "S05.9 - Traumatismo do Olho e da Órbita, Não Especificado",
            "S06 - Traumatismo Intracraniano",
            "S06.0 - Concussão Cerebral",
            "S06.1 - Edema Cerebral Traumático",
            "S06.2 - Traumatismo Cerebral Difuso",
            "S06.3 - Traumatismo Cerebral Focal",
            "S06.4 - Hemorragia Epidural",
            "S06.5 - Hemorragia Subdural Devida a Traumatismo",
            "S06.6 - Hemorragia Subaracnóide Devida a Traumatismo",
            "S06.7 - Traumatismo Intracraniano Com Coma Prolongado",
            "S06.8 - Outros Traumatismos Intracranianos",
            "S06.9 - Traumatismo Intracraniano, Não Especificado",
            "S07 - Lesões Por Esmagamento da Cabeça",
            "S07.0 - Lesão Por Esmagamento da Face",
            "S07.1 - Lesão Por Esmagamento do Crânio",
            "S07.8 - Lesão Por Esmagamento de Outras Partes da Cabeça",
            "S07.9 - Lesão Por Esmagamento da Cabeça, Parte Não Especificada",
            "S08 - Amputação Traumática de Parte da Cabeça",
            "S08.0 - Avulsão do Couro Cabeludo",
            "S08.1 - Amputação Traumática da Orelha",
            "S08.8 - Amputação Traumática de Outras Partes da Cabeça",
            "S08.9 - Amputação Traumática de Parte Não Especificada da Cabeça",
            "S09 - Outros Traumatismos da Cabeça e os Não Especificados",
            "S09.0 - Traumatismo Dos Vasos Sangüíneos da Cabeça Não Classificados em Outra Parte",
            "S09.1 - Traumatismo Dos Músculos e Dos Tendões da Cabeça",
            "S09.2 - Ruptura Traumática do Tímpano",
            "S09.7 - Traumatismos Múltiplos da Cabeça",
            "S09.8 - Outros Traumatismos Especificados da Cabeça",
            "S09.9 - Traumatismo Não Especificado da Cabeça",
            "S10 - Traumatismo Superficial do Pescoço",
            "S10.0 - Contusão da Garganta",
            "S10.1 - Outros Traumatismos Superficiais da Garganta e os Não Especificados",
            "S10.7 - Traumatismos Superficiais Múltiplos do Pescoço",
            "S10.8 - Traumatismo Superficial de Outras Localizações do Pescoço",
            "S10.9 - Traumatismo Superficial do Pescoço, Parte Não Especificada",
            "S11 - Ferimento do Pescoço",
            "S11.0 - Ferimento Envolvendo a Laringe e a Traquéia",
            "S11.1 - Ferimento da Glândula Tireóide",
            "S11.2 - Ferimento Envolvendo a Faringe e o Esôfago Cervical",
            "S11.7 - Ferimentos Múltiplos do Pescoço",
            "S11.8 - Ferimentos de Outras Partes do Pescoço",
            "S11.9 - Ferimentos do Pescoço, Parte Não Especificada",
            "S12 - Fratura do Pescoço",
            "S12.0 - Fratura da Primeira Vértebra Cervical",
            "S12.1 - Fratura da Segunda Vértebra Cervical",
            "S12.2 - Fratura de Outras Vértebras Cervicais Especificadas",
            "S12.7 - Fraturas Múltiplas da Coluna Cervical",
            "S12.8 - Fratura de Outras Partes do Pescoço",
            "S12.9 - Fratura do Pescoço, Parte Não Especificada",
            "S13 - Luxação, Entorse ou Distensão Das Articulações e Dos Ligamentos do Pescoço",
            "S13.0 - Ruptura Traumática de Disco Intervertebral Cervical",
            "S13.1 - Luxação de Vértebra Cervical",
            "S13.2 - Luxação de Outras Partes do Pescoço e Das Não Especificadas",
            "S13.3 - Luxações Múltiplas do Pescoço",
            "S13.4 - Distensão e Entorse da Coluna Cervical",
            "S13.5 - Distensão e Entorse da Região Tireoidiana",
            "S13.6 - Distensão e Entorse de Articulações e de Ligamentos de Outras Localizações e Das Não Especificadas do Pescoço",
            "S14 - Traumatismo de Nervos e da Medula Espinhal ao Nível Cervical",
            "S14.0 - Concussão e Edema da Medula Cervical",
            "S14.1 - Outros Traumatismos e os Não Especificados da Medula Cervical",
            "S14.2 - Traumatismo da Raiz Nervosa da Coluna Cervical",
            "S14.3 - Traumatismo do Plexo Braquial",
            "S14.4 - Traumatismo Dos Nervos Periféricos do Pescoço",
            "S14.5 - Traumatismo Dos Nervos Simpáticos do Pescoço",
            "S14.6 - Traumatismo de Outros Nervos e Dos Não Especificados do Pescoço",
            "S15 - Traumatismo Dos Vasos Sangüíneos ao Nível do Pescoço",
            "S15.0 - Traumatismo da Artéria Carótida",
            "S15.1 - Traumatismo da Artéria Vertebral",
            "S15.2 - Traumatismo da Veia Jugular Externa",
            "S15.3 - Traumatismo da Veia Jugular Interna",
            "S15.7 - Traumatismo de Múltiplos Vasos Sangüíneos ao Nível do Pescoço",
            "S15.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível do Pescoço",
            "S15.9 - Traumatismo de Vasos Sangüíneos Não Especificados ao Nível do Pescoço",
            "S16 - Traumatismo de Tendões e de Músculos do Pescoço",
            "S17 - Lesões Por Esmagamento do Pescoço",
            "S17.0 - Lesão Por Esmagamento da Laringe e da Traquéia",
            "S17.8 - Lesão Por Esmagamento de Outras Partes do Pescoço",
            "S17.9 - Lesão Por Esmagamento do Pescoço, Parte Não Especificada",
            "S18 - Amputação Traumática ao Nível do Pescoço",
            "S19 - Outros Traumatismos do Pescoço e os Não Especificados",
            "S19.7 - Traumatismos Múltiplos do Pescoço",
            "S19.8 - Outros Traumatismos Especificados do Pescoço",
            "S19.9 - Traumatismo Não Especificado do Pescoço",
            "S20 - Traumatismo Superficial do Tórax",
            "S20.0 - Contusão da Mama",
            "S20.1 - Outros Traumatismos Superficiais da Mama e os Não Especificados",
            "S20.2 - Contusão do Tórax",
            "S20.3 - Outros Traumatismos Superficiais da Parede Anterior do Tórax",
            "S20.4 - Outros Traumatismos Superficiais da Parede Posterior do Tórax",
            "S20.7 - Traumatismos Superficiais Múltiplos do Tórax",
            "S20.8 - Traumatismo Superficial de Outras Partes Especificadas do Tórax e Das Não Especificadas",
            "S21 - Ferimento do Tórax",
            "S21.0 - Ferimento da Mama",
            "S21.1 - Ferimento da Parede Anterior do Tórax",
            "S21.2 - Ferimento da Parede Posterior do Tórax",
            "S21.7 - Ferimentos Múltiplos da Parede Torácica",
            "S21.8 - Ferimento de Outras Partes do Tórax",
            "S21.9 - Ferimento do Tórax Parte Não Especificada",
            "S22 - Fratura de Costela(s), Esterno e Coluna Torácica",
            "S22.0 - Fratura de Vértebra Torácica",
            "S22.1 - Fraturas Múltiplas da Coluna Torácica",
            "S22.2 - Fratura do Esterno",
            "S22.3 - Fratura de Costela",
            "S22.4 - Fraturas Múltiplas de Costelas",
            "S22.5 - Tórax Fundido (oscilante)",
            "S22.8 - Fraturas de Outras Partes Dos Ossos do Tórax",
            "S22.9 - Fratura Dos Ossos do Tórax, Parte Não Especificada",
            "S23 - Luxação, Entorse e Distensão de Articulações e Dos Ligamentos do Tórax",
            "S23.0 - Ruptura Traumática de Disco Intervertebral Torácico",
            "S23.1 - Luxação de Vértebra Torácica",
            "S23.2 - Luxação de Outras Partes do Tórax e Das Não Especificadas",
            "S23.3 - Entorse e Distensão da Coluna Torácica",
            "S23.4 - Entorse e Distensão Das Costelas e do Esterno",
            "S23.5 - Entorse e Distensão de Outras Partes do Tórax e de Partes Não Especificadas",
            "S24 - Traumatismos de Nervos e da Medula Espinhal ao Nível do Tórax",
            "S24.0 - Concussão e Edema da Medula Espinhal Torácica",
            "S24.1 - Outros Traumatismos da Medula Espinhal Torácica e os Não Especificados",
            "S24.2 - Traumatismo da Raiz de Nervo da Coluna Torácica",
            "S24.3 - Traumatismo de Nervos Periféricos do Tórax",
            "S24.4 - Traumatismo de Nervos Simpáticos do Tórax",
            "S24.5 - Traumatismo de Outros Nervos do Tórax",
            "S24.6 - Traumatismo de Nervo do Tórax Não Especificado",
            "S25 - Traumatismo de Vasos Sangüíneos do Tórax",
            "S25.0 - Traumatismo da Aorta Torácica",
            "S25.1 - Traumatismo da Artéria Subclávia ou Inominada (braquiocefálica)",
            "S25.2 - Traumatismo da Veia Cava Superior",
            "S25.3 - Traumatismo da Veia Subclávia ou Inominada (braquiocefálica)",
            "S25.4 - Traumatismo de Vasos Sangüíneos Pulmonares",
            "S25.5 - Traumatismo de Vasos Sangüíneos Intercostais",
            "S25.7 - Traumatismo de Múltiplos Vasos Sangüíneos do Tórax",
            "S25.8 - Traumatismo de Outros Vasos Sangüíneos do Tórax",
            "S25.9 - Traumatismo de Vaso Sangüíneo Não Especificado do Tórax",
            "S26 - Traumatismo do Coração",
            "S26.0 - Traumatismo do Coração Com Hemopericárdio",
            "S26.8 - Outros Traumatismos do Coração",
            "S26.9 - Traumatismo do Coração Não Especificado",
            "S27 - Traumatismo de Outros Órgãos Intratorácicos e Dos Não Especificados",
            "S27.0 - Pneumotórax Traumático",
            "S27.1 - Hemotórax Traumático",
            "S27.2 - Hemopneumotórax Traumático",
            "S27.3 - Outros Traumatismos do Pulmão",
            "S27.4 - Traumatismo Dos Brônquios",
            "S27.5 - Traumatismo da Traquéia Torácica",
            "S27.6 - Traumatismo da Pleura",
            "S27.7 - Traumatismos Múltiplos de Órgãos Intratorácicos",
            "S27.8 - Traumatismo de Outros Órgãos Intratorácicos Especificados",
            "S27.9 - Traumatismo de Órgão Intratorácico Não Especificado",
            "S28 - Lesão Por Esmagamento do Tórax e Amputação Traumática de Parte do Tórax",
            "S28.0 - Esmagamento do Tórax",
            "S28.1 - Amputação Traumática de Parte do Tórax",
            "S29 - Outros Traumatismos do Tórax e os Não Especificados",
            "S29.0 - Traumatismo de Músculo e de Tendão ao Nível Tóracico",
            "S29.7 - Traumatismos Múltiplos do Tórax",
            "S29.8 - Outros Traumatismos Especificados do Tórax",
            "S29.9 - Traumatismo Não Especificado do Tórax",
            "S30 - Traumatismo Superficial do Abdome, do Dorso e da Pelve",
            "S30.0 - Contusão do Dorso e da Pelve",
            "S30.1 - Contusão da Parede Abdominal",
            "S30.2 - Contusão Dos Órgãos Genitais Externos",
            "S30.7 - Traumatismos Superficiais Múltiplos do Abdome, do Dorso e da Pelve",
            "S30.8 - Outros Traumatismos Superficiais do Abdome, do Dorso e da Pelve",
            "S30.9 - Traumatismo Superficial de Parte Não Especificada do Abdome, do Dorso e da Pelve",
            "S31 - Ferimento do Abdome, do Dorso e da Pelve",
            "S31.0 - Ferimento do Dorso e da Pelve",
            "S31.1 - Ferimento da Parede Abdominal",
            "S31.2 - Ferimento do Pênis",
            "S31.3 - Ferimento do Escroto e do Testículo",
            "S31.4 - Ferimento da Vagina e da Vulva",
            "S31.5 - Ferimento de Outros Órgãos Genitais Externos e os Não Especificados",
            "S31.7 - Ferimentos Múltiplos do Abdome, do Dorso e da Pelve",
            "S31.8 - Ferimento de Outras Partes e de Partes Não Especificadas do Abdome",
            "S32 - Fratura da Coluna Lombar e da Pelve",
            "S32.0 - Fratura de Vértebra Lombar",
            "S32.1 - Fratura do Sacro",
            "S32.2 - Fratura do Cóccix",
            "S32.3 - Fratura do Ílio",
            "S32.4 - Fratura do Acetábulo",
            "S32.5 - Fratura do Púbis",
            "S32.7 - Fraturas Múltiplas de Coluna Lombar e da Pelve",
            "S32.8 - Fratura de Outras Partes da Coluna Lombossacra e da Pelve e de Partes Não Especificadas",
            "S33 - Luxação, Entorse ou Distensão Das Articulações e Dos Ligamentos da Coluna Lombar e da Pelve",
            "S33.0 - Ruptura Traumática do Disco Intervertebral Lombar",
            "S33.1 - Luxação da Vértebra Lombar",
            "S33.2 - Luxação Das Articulações Sacroilíaca e Sacrococcígea",
            "S33.3 - Luxação de Outras Partes e Das Não Especificadas da Coluna Lombar e da Pelve",
            "S33.4 - Ruptura Traumática da Sínfise Púbica",
            "S33.5 - Entorse e Distensão da Coluna Lombar",
            "S33.6 - Entorse e Distensão da Articulação Sacroilíaca",
            "S33.7 - Entorse e Distensão de Outras Partes e Das Não Especificadas da Coluna Lombar e da Pelve",
            "S34 - Traumatismo Dos Nervos e da Medula Lombar ao Nível do Abdome, do Dorso e da Pelve",
            "S34.0 - Concussão e Edema da Medula Lombar",
            "S34.1 - Outro Traumatismo da Medula Lombar",
            "S34.2 - Traumatismo de Raiz Nervosa da Medula Lombar e Sacra",
            "S34.3 - Traumatismo de Cauda Eqüina",
            "S34.4 - Traumatismo do Plexo Lombossacro",
            "S34.5 - Traumatismo de Nervos Simpáticos Lombares, Sacros e Pélvicos",
            "S34.6 - Traumatismo Do(s) Nervo(s) Periférico(s) do Abdome, do Dorso e da Pelve",
            "S34.8 - Traumatismo de Outros Nervos e Dos Não Especificados ao Nível do Abdome, do Dorso e da Pelve",
            "S35 - Traumatismo de Vasos Sangüíneos ao Nível do Abdome, do Dorso e da Pelve",
            "S35.0 - Traumatismo da Aorta Abdominal",
            "S35.1 - Traumatismo da Veia Cava Inferior",
            "S35.2 - Traumatismo da Artéria Celíaca ou Mesentérica",
            "S35.3 - Traumatismo da Veia Porta ou Esplênica",
            "S35.4 - Traumatismo de Vaso Sangüíneo Renal",
            "S35.5 - Traumatismo de Vaso Sangüíneo Ilíaco",
            "S35.7 - Traumatismo de Vasos Múltiplos ao Nível do Abdome, do Dorso e da Pelve",
            "S35.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível do Abdome, do Dorso e da Pelve",
            "S35.9 - Traumatismo de Vaso Sangüíneo Não Especificado ao Nível do Abdome, do Dorso e da Pelve",
            "S36 - Traumatismo de Órgãos Intra-abdominais",
            "S36.0 - Traumatismo do Baço",
            "S36.1 - Traumatismo do Fígado ou da Vesícula Biliar",
            "S36.2 - Traumatismo do Pâncreas",
            "S36.3 - Traumatismo do Estômago",
            "S36.4 - Traumatismo do Intestino Delgado",
            "S36.5 - Traumatismo do Cólon",
            "S36.6 - Traumatismo do Reto",
            "S36.7 - Traumatismo de Múltiplos Órgãos Intra-abdominais",
            "S36.8 - Traumatismo de Outros Órgãos Intra-abdominais",
            "S36.9 - Traumatismo de Órgão Intra-abdominal Não Especificado",
            "S37 - Traumatismo do Aparelho Urinário e de Órgãos Pélvicos",
            "S37.0 - Traumatismo do Rim",
            "S37.1 - Traumatismo do Ureter",
            "S37.2 - Traumatismo da Bexiga",
            "S37.3 - Traumatismo da Uretra",
            "S37.4 - Traumatismo do Ovário",
            "S37.5 - Traumatismo da Trompa de Falópio",
            "S37.6 - Traumatismo do Útero",
            "S37.7 - Traumatismo de Múltiplos Órgãos Pélvicos",
            "S37.8 - Traumatismo de Outros Órgãos Pélvicos",
            "S37.9 - Traumatismo de Órgão Pélvico Não Especificado",
            "S38 - Lesão Por Esmagamento e Amputação Traumática de Parte do Abdome, do Dorso e da Pelve",
            "S38.0 - Lesão Por Esmagamento Dos Órgãos Genitais Externos",
            "S38.1 - Lesão Por Esmagamento de Outras Partes e de Partes Não Especificadas do Abdome, do Dorso e da Pelve",
            "S38.2 - Amputação Traumática de Órgãos Genitais Externos",
            "S38.3 - Amputação Traumática de Outras Partes e de Partes Não Especificadas do Abdome, do Dorso e da Pelve",
            "S39 - Outros Traumatismos e os Não Especificados do Abdome, do Dorso e da Pelve",
            "S39.0 - Traumatismo de Músculo e de Tendão do Abdome, do Dorso e da Pelve",
            "S39.6 - Traumatismo de Órgão(s) Intra-abdominal(is) Com Órgão(s) Pélvico(s)",
            "S39.7 - Outros Traumatismos Múltiplos do Abdome, do Dorso e da Pelve",
            "S39.8 - Outros Traumatismos Especificados do Abdome, do Dorso e da Pelve",
            "S39.9 - Traumatismo Não Especificado do Abdome, do Dorso e da Pelve",
            "S40 - Traumatismo Superficial do Ombro e do Braço",
            "S40.0 - Contusão do Ombro e do Braço",
            "S40.7 - Traumatismos Superficiais Múltiplos do Ombro e do Braço",
            "S40.8 - Outros Traumatismos Superficiais do Ombro e do Braço",
            "S40.9 - Traumatismo Superficial Não Especificado do Ombro e do Braço",
            "S41 - Ferimento do Ombro e do Braço",
            "S41.0 - Ferimento do Ombro",
            "S41.1 - Ferimento do Braço",
            "S41.7 - Ferimentos Múltiplos do Ombro e do Braço",
            "S41.8 - Ferimento de Outras Partes e de Partes Não Especificadas da Cintura Escapular",
            "S42 - Fratura do Ombro e do Braço",
            "S42.0 - Fratura da Clavícula",
            "S42.1 - Fratura da Omoplata (escápula)",
            "S42.2 - Fratura da Extremidade Superior do Úmero",
            "S42.3 - Fratura da Diáfise do Úmero",
            "S42.4 - Fratura da Extremidade Inferior do Úmero",
            "S42.7 - Fraturas Múltiplas da Clavícula, da Omoplata (escápula) e do Úmero",
            "S42.8 - Fratura de Outras Partes do Ombro e do Braço",
            "S42.9 - Fratura da Cintura Escapular, Parte Não Especificada",
            "S43 - Luxação, Entorse e Distensão Das Articulações e Dos Ligamentos da Cintura Escapular",
            "S43.0 - Luxação da Articulação do Ombro",
            "S43.1 - Luxação da Articulação Acromioclavicular",
            "S43.2 - Luxação da Articulação Esternoclavicular",
            "S43.3 - Luxação de Outras Partes e Das Não Especificadas da Cintura Escapular",
            "S43.4 - Entorse e Distensão de Articulação do Ombro",
            "S43.5 - Entorse e Distensão da Articulação Acromioclavicular",
            "S43.6 - Entorse e Distensão da Articulação Esternoclavicular",
            "S43.7 - Entorse e Distensão de Outras Partes e de Partes Não Especificadas da Cintura Escapular",
            "S44 - Traumatismo de Nervos ao Nível do Ombro e do Braço",
            "S44.0 - Traumatismo do Nervo Cubital (ulnar) ao Nível do Braço",
            "S44.1 - Traumatismo do Nervo Mediano ao Nível do Braço",
            "S44.2 - Traumatismo do Nervo Radial ao Nível do Braço",
            "S44.3 - Traumatismo do Nervo Axilar",
            "S44.4 - Traumatismo do Nervo Musculocutâneo",
            "S44.5 - Traumatismo do Nervo Sensitivo Cutâneo ao Nível do Ombro e do Braço",
            "S44.7 - Traumatismo de Múltiplos Nervos ao Nível do Ombro e do Braço",
            "S44.8 - Traumatismo de Outros Nervos ao Nível do Ombro e do Braço",
            "S44.9 - Traumatismo de Nervo Não Especificado ao Nível do Ombro e do Braço",
            "S45 - Traumatismo Dos Vasos Sangüíneos ao Nível do Ombro e do Braço",
            "S45.0 - Traumatismo da Artéria Axilar",
            "S45.1 - Traumatismo da Artéria Braquial",
            "S45.2 - Traumatismo da Veia Axilar ou Braquial",
            "S45.3 - Traumatismo de Veia Superficial ao Nível do Ombro e do Braço",
            "S45.7 - Traumatismo de Múltiplos Vasos Sangüíneos ao Nível do Ombro e do Braço",
            "S45.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível do Ombro e do Braço",
            "S45.9 - Traumatismo de Vaso Sangüíneo Não Especificado ao Nível do Ombro e do Braço",
            "S46 - Traumatismo de Tendão e Músculo ao Nível do Ombro e do Braço",
            "S46.0 - Traumatismo do Tendão do Manguito Rotador do Ombro",
            "S46.1 - Traumatismo do Músculo e Tendão da Cabeça Longa do Bíceps",
            "S46.2 - Traumatismo do Músculo e Tendão de Outras Partes do Bíceps",
            "S46.3 - Traumatismo do Músculo e Tendão do Tríceps",
            "S46.7 - Traumatismo de Múltiplos Músculos e Tendões ao Nível do Ombro e do Braço",
            "S46.8 - Traumatismo de Outros Músculos e Tendões ao Nível do Ombro e do Braço",
            "S46.9 - Traumatismo de Músculo e Tendão Não Especificado ao Nível do Ombro e do Braço",
            "S47 - Lesão Por Esmagamento do Ombro e do Braço",
            "S48 - Amputação Traumática do Ombro e do Braço",
            "S48.0 - Amputação Traumática da Articulação do Ombro",
            "S48.1 - Amputação Traumática de Localização Entre o Ombro e o Cotovelo",
            "S48.9 - Amputação Traumática do Ombro e do Braço, de Localização Não Especificada",
            "S49 - Outros Traumatismos e os Não Especificados do Ombro e do Braço",
            "S49.7 - Traumatismos Múltiplos do Ombro e do Braço",
            "S49.8 - Outros Traumatismos Especificados do Ombro e do Braço",
            "S49.9 - Traumatismo Não Especificado do Ombro e do Braço",
            "S50 - Traumatismo Superficial do Cotovelo e do Antebraço",
            "S50.0 - Contusão do Cotovelo",
            "S50.1 - Contusão de Outras Partes e de Partes Não Especificadas do Antebraço",
            "S50.7 - Traumatismos Superficiais Múltiplos do Antebraço",
            "S50.8 - Outros Traumatismos Superficiais do Antebraço",
            "S50.9 - Traumatismo Superficial do Antebraço, Não Especificado",
            "S51 - Ferimento do Antebraço",
            "S51.0 - Ferimento do Cotovelo",
            "S51.7 - Ferimentos Múltiplos do Antebraço",
            "S51.8 - Ferimento de Outras Partes do Antebraço",
            "S51.9 - Ferimento do Antebraço, Parte Não Especificado",
            "S52 - Fratura do Antebraço",
            "S52.0 - Fratura da Extremidade Superior do Cúbito (ulna)",
            "S52.1 - Fratura da Extremidade Superior do Rádio",
            "S52.2 - Fratura da Diáfise do Cúbito (ulna)",
            "S52.3 - Fratura da Diáfise do Rádio",
            "S52.4 - Fratura Das Diáfises do Rádio e do Cúbito (ulna)",
            "S52.5 - Fratura da Extremidade Distal do Rádio",
            "S52.6 - Fratura da Extremidade Distal do Rádio e do Cúbito (ulna)",
            "S52.7 - Fraturas Múltiplas do Antebraço",
            "S52.8 - Fratura de Outras Partes do Antebraço",
            "S52.9 - Fratura do Antebraço, Parte Não Especificada",
            "S53 - Luxação, Entorse e Distensão Das Articulações e Dos Ligamentos do Cotovelo",
            "S53.0 - Luxação da Cabeça do Rádio",
            "S53.1 - Luxação do Cotovelo, Não Especificada",
            "S53.2 - Ruptura Traumática do Ligamento Colateral do Rádio",
            "S53.3 - Ruptura Traumática do Ligamento Colateral do Cúbito (ulna)",
            "S53.4 - Entorse e Distensão do Cotovelo",
            "S54 - Traumatismo de Nervos ao Nível do Antebraço",
            "S54.0 - Traumatismo do Nervo Cubital (ulnar) ao Nível do Antebraço",
            "S54.1 - Traumatismo do Nervo Mediano ao Nível do Antebraço",
            "S54.2 - Traumatismo do Nervo Radial ao Nível do Antebraço",
            "S54.3 - Traumatismo do Nervo Cutâneo Sensitivo ao Nível do Antebraço",
            "S54.7 - Traumatismo de Múltiplos Nervos ao Nível do Antebraço",
            "S54.8 - Traumatismo de Outros Nervos ao Nível do Antebraço",
            "S54.9 - Traumatismo de Nervo Não Especificado ao Nível do Antebraço",
            "S55 - Traumatismo de Vasos Sangüíneos ao Nível do Antebraço",
            "S55.0 - Traumatismo da Artéria Cubital (ulnar) ao Nível do Antebraço",
            "S55.1 - Traumatismo da Artéria Radial ao Nível do Antebraço",
            "S55.2 - Traumatismo de Veia ao Nível do Antebraço",
            "S55.7 - Traumatismo de Múltiplos Vasos Sangüíneos ao Nível do Antebraço",
            "S55.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível do Antebraço",
            "S55.9 - Traumatismo de Vasos Sangüíneos Não Especificados ao Nível do Antebraço",
            "S56 - Traumatismo do Músculo e Tendão ao Nível do Antebraço",
            "S56.0 - Traumatismo do Músculo Flexor e Tendão do Polegar ao Nível do Antebraço",
            "S56.1 - Traumatismo do Músculo Flexor e Tendão de Outro(s) Dedo(s) ao Nível do Antebraço",
            "S56.2 - Traumatismo de Outro Músculo Flexor e Tendão ao Nível do Antebraço",
            "S56.3 - Traumatismo Dos Músculos Extensor ou Abdutor e Tendões do Polegar ao Nível do Antebraço",
            "S56.4 - Traumatismo do Músculo Extensor e Tendão de Outro(s) Dedo(s) ao Nível do Antebraço",
            "S56.5 - Traumatismo de Outros Músculos Extensores e Tendões ao Nível do Antebraço",
            "S56.7 - Traumatismo de Músculos e Tendões Múltiplos ao Nível do Antebraço",
            "S56.8 - Traumatismo de Outros Músculos e Tendões e Dos Não Especificados ao Nível do Antebraço",
            "S57 - Lesão Por Esmagamento do Antebraço",
            "S57.0 - Lesão Por Esmagamento do Cotovelo",
            "S57.8 - Lesão Por Esmagamento de Outras Partes do Antebraço",
            "S57.9 - Lesão Por Esmagamento de Parte Não Especificada do Antebraço",
            "S58 - Amputação Traumática do Cotovelo e do Antebraço",
            "S58.0 - Amputação Traumática ao Nível do Cotovelo",
            "S58.1 - Amputação Traumática do Antebraço Entre o Cotovelo e o Punho",
            "S58.9 - Amputação Traumática do Antebraço, Nível Não Especificado",
            "S59 - Outros Traumatismos do Antebraço e os Não Especificados",
            "S59.7 - Traumatismos Múltiplos do Cotovelo",
            "S59.8 - Outros Traumatismos Especificados do Antebraço",
            "S59.9 - Traumatismo Não Especificado do Antebraço",
            "S60 - Traumatismo Superficial do Punho e da Mão",
            "S60.0 - Contusão de Dedo(s) Sem Lesão da Unha",
            "S60.1 - Contusão de Dedo(s) Com Lesão da Unha",
            "S60.2 - Contusão de Outras Partes do Punho e da Mão",
            "S60.7 - Traumatismos Superficiais Múltiplos do Punho e da Mão",
            "S60.8 - Outros Traumatismos Superficiais do Punho e da Mão",
            "S60.9 - Traumatismo Superficial Não Especificado do Punho e da Mão",
            "S61 - Ferimento do Punho e da Mão",
            "S61.0 - Ferimento de Dedo(s) Sem Lesão da Unha",
            "S61.1 - Ferimento de Dedo(s) Com Lesão da Unha",
            "S61.7 - Ferimentos Múltiplos do Punho e da Mão",
            "S61.8 - Ferimento de Outras Partes do Punho e da Mão",
            "S61.9 - Ferimento do Punho e da Mão, Parte Não Especificada",
            "S62 - Fratura ao Nível do Punho e da Mão",
            "S62.0 - Fratura do Osso Navicular (escafoíde) da Mão",
            "S62.1 - Fratura de Outro(s) Osso(s) do Carpo",
            "S62.2 - Fratura do Primeiro Metacarpiano",
            "S62.3 - Fratura de Outros Ossos do Metacarpo",
            "S62.4 - Fraturas Múltiplas de Ossos Metacarpianos",
            "S62.5 - Fratura do Polegar",
            "S62.6 - Fratura de Outros Dedos",
            "S62.7 - Fraturas Múltiplas de Dedo(s)",
            "S62.8 - Fratura de Outras Partes e de Partes Não Especificadas do Punho e da Mão",
            "S63 - Luxação, Entorse e Distensão Das Articulações e Dos Ligamentos ao Nível do Punho e da Mão",
            "S63.0 - Luxação do Punho",
            "S63.1 - Luxação do Dedo",
            "S63.2 - Luxações Múltiplas Dos Dedos",
            "S63.3 - Ruptura Traumática de Ligamento(s) do Punho e do Carpo",
            "S63.4 - Ruptura Traumática do Ligamento do Dedo Nas Articulações Metacarpofalangianas e Interfalangianas",
            "S63.5 - Entorse e Distensão do Punho",
            "S63.6 - Entorse e Distensão Do(s) Dedo(s)",
            "S63.7 - Entorse e Distensão de Outras Partes e Das Não Especificadas da Mão",
            "S64 - Traumatismo de Nervos ao Nível do Punho e da Mão",
            "S64.0 - Traumatismo do Nervo Cubital (ulnar) ao Nível do Punho e da Mão",
            "S64.1 - Traumatismo do Nervo Mediano ao Nível do Punho e da Mão",
            "S64.2 - Traumatismo do Nervo Radial ao Nível do Punho e da Mão",
            "S64.3 - Traumatismo do Nervo Digital do Polegar",
            "S64.4 - Traumatismo do Nervo Digital de Outro Dedo",
            "S64.7 - Traumatismo de Múltiplos Nervos ao Nível de Punho e da Mão",
            "S64.8 - Traumatismo de Outros Nervos ao Nível do Punho e da Mão",
            "S64.9 - Traumatismo de Nervo Não Especificado ao Nível do Punho e da Mão",
            "S65 - Traumatismo de Vasos Sangüíneos ao Nível do Punho e da Mão",
            "S65.0 - Traumatismo da Artéria Cubital (ulnar) ao Nível do Punho e da Mão",
            "S65.1 - Traumatismo da Artéria Radial ao Nível do Punho e da Mão",
            "S65.2 - Traumatismo do Arco Palmar Superficial",
            "S65.3 - Traumatismo do Arco Palmar Profundo",
            "S65.4 - Traumatismo de Vaso(s) Sangüíneo(s) do Polegar",
            "S65.5 - Traumatismo de Vaso(s) Sangüíneo(s) de Outro Dedo",
            "S65.7 - Traumatismo de Vasos Sangüíneos Múltiplos ao Nível de Punho e da Mão",
            "S65.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível do Punho e de Mão",
            "S65.9 - Traumatismo de Vaso Sangüíneo Não Especificado ao Nível do Punho e da Mão",
            "S66 - Traumatismo de Músculo e Tendão ao Nível do Punho e da Mão",
            "S66.0 - Traumatismo do Músculo Flexor Longo e Tendão do Polegar ao Nível do Punho e da Mão",
            "S66.1 - Traumatismo do Músculo Flexor e Tendão de Outro Dedo ao Nível do Punho e da Mão",
            "S66.2 - Traumatismo do Músculo Extensor e Tendão do Polegar ao Nível do Punho e da Mão",
            "S66.3 - Traumatismo do Músculo Extensor e Tendão de Outro Dedo ao Nível do Punho e da Mão",
            "S66.4 - Traumatismo do Músculo Intrínseco e Tendão do Polegar ao Nível do Punho e da Mão",
            "S66.5 - Traumatismo do Músculo Intrínseco e Tendão de Outro Dedo ao Nível do Punho e da Mão",
            "S66.6 - Traumatismo de Músculos Flexores e Tendões Múltiplos ao Nível do Punho e da Mão",
            "S66.7 - Traumatismo de Músculos Extensores e Tendões Múltiplos ao Nível do Punho e da Mão",
            "S66.8 - Traumatismo de Outros Músculos e Tendões ao Nível do Punho e da Mão",
            "S66.9 - Traumatismo de Músculo e Tendão Não Especificado ao Nível do Punho e da Mão",
            "S67 - Lesão Por Esmagamento do Punho e da Mão",
            "S67.0 - Lesão Por Esmagamento do Polegar e de Outro(s) Dedo(s)",
            "S67.8 - Lesão Por Esmagamento de Outras Partes e Das Não Especificadas do Punho e da Mão",
            "S68 - Amputação Traumática ao Nível do Punho e da Mão",
            "S68.0 - Amputação Traumática do Polegar (completa) (parcial)",
            "S68.1 - Amputação Traumática de um Outro Dedo Apenas (completa) (parcial)",
            "S68.2 - Amputação Traumática de Dois ou Mais Dedos Somente (completa) (parcial)",
            "S68.3 - Amputação Traumática Combinada de (partes De) Dedo(s) Associada a Outras Partes do Punho e da Mão",
            "S68.4 - Amputação Traumática da Mão ao Nível do Punho",
            "S68.8 - Amputação Traumática de Outras Partes do Punho e da Mão",
            "S68.9 - Amputação Traumática do Punho e da Mão, Nível Não Especificado",
            "S69 - Outros Traumatismos e os Não Especificados do Punho e da Mão",
            "S69.7 - Traumatismos Múltiplos do Punho e da Mão",
            "S69.8 - Outros Traumatismos Especificados do Punho e da Mão",
            "S69.9 - Traumatismo Não Especificados do Punho e da Mão",
            "S70 - Traumatismo Superficial do Quadril e da Coxa",
            "S70.0 - Contusão do Quadril",
            "S70.1 - Contusão da Coxa",
            "S70.7 - Traumatismos Superficiais Múltiplos do Quadril e da Coxa",
            "S70.8 - Outros Traumatismos Superficiais do Quadril e da Coxa",
            "S70.9 - Traumatismo Superficial Não Especificado do Quadril e da Coxa",
            "S71 - Ferimento do Quadril e da Coxa",
            "S71.0 - Ferimento do Quadril",
            "S71.1 - Ferimento da Coxa",
            "S71.7 - Ferimentos Múltiplos do Quadril e da Coxa",
            "S71.8 - Ferimentos de Outras Partes e Das Não Especificadas da Cintura Pélvica",
            "S72 - Fratura do Fêmur",
            "S72.0 - Fratura do Colo do Fêmur",
            "S72.1 - Fratura Pertrocantérica",
            "S72.2 - Fratura Subtrocantérica",
            "S72.3 - Fratura da Diáfise do Fêmur",
            "S72.4 - Fratura da Extremidade Distal do Fêmur",
            "S72.7 - Fraturas Múltiplas do Fêmur",
            "S72.8 - Fraturas de Outras Partes do Fêmur",
            "S72.9 - Fratura do Fêmur, Parte Não Especificada",
            "S73 - Luxação, Entorse e Distensão da Articulação e Dos Ligamentos do Quadril",
            "S73.0 - Luxação da Articulação do Quadril",
            "S73.1 - Entorse e Distensão do Quadril",
            "S74 - Traumatismo de Nervos ao Nível do Quadril e da Coxa",
            "S74.0 - Traumatismo do Nervo Ciático ao Nível do Quadril e da Coxa",
            "S74.1 - Traumatismo do Nervo Femural ao Nível do Quadril e da Coxa",
            "S74.2 - Traumatismo do Nervo Sensitivo Cutâneo ao Nível do Quadril e da Coxa",
            "S74.7 - Traumatismo de Nervos Múltiplos ao Nível do Quadril e da Coxa",
            "S74.8 - Traumatismo de Outros Nervos ao Nível do Quadril e da Coxa",
            "S74.9 - Traumatismo de Nervo Não Especificado ao Nível do Quadril e da Coxa",
            "S75 - Traumatismo de Vasos Sangüíneos ao Nível do Quadril e da Coxa",
            "S75.0 - Traumatismo da Artéria Femural",
            "S75.1 - Traumatismo da Veia Femural ao Nível do Quadril e da Coxa",
            "S75.2 - Traumatismo da Veia Grande Safena ao Nível do Quadril e da Coxa",
            "S75.7 - Traumatismo de Múltiplos Vasos Sangüíneos ao Nível do Quadril e da Coxa",
            "S75.8 - Traumatismo de Outros Vaso Sangüíneos ao Nível do Quadril e da Coxa",
            "S75.9 - Traumatismo de Vaso Sangüíneo Não Especificado ao Nível do Quadril e da Coxa",
            "S76 - Traumatismo de Músculo e de Tendão ao Nível do Quadril e da Coxa",
            "S76.0 - Traumatismo do Músculo e do Tendão do Quadril",
            "S76.1 - Traumatismo do Músculo e do Tendão do Quadríceps",
            "S76.2 - Traumatismo do Músculo e do Tendão do Adutor da Coxa",
            "S76.3 - Traumatismo do Músculo e do Tendão Dos Músculos Posteriores ao Nível da Coxa",
            "S76.4 - Traumatismo de Outros Músculos e Tendões e os Não Especificados ao Nível da Coxa",
            "S76.7 - Traumatismo de Múltiplos Músculos e Tendões ao Nível do Quadril e da Coxa",
            "S77 - Lesão Por Esmagamento do Quadril e da Coxa",
            "S77.0 - Lesão Por Esmagamento do Quadril",
            "S77.1 - Lesão Por Esmagamento da Coxa",
            "S77.2 - Lesão Por Esmagamento do Quadril e da Coxa",
            "S78 - Amputação Traumática do Quadril e da Coxa",
            "S78.0 - Amputação Traumática na Articulação do Quadril",
            "S78.1 - Amputação Traumática Localizada Entre o Joelho e o Quadril",
            "S78.9 - Amputação Traumática do Quadril e Coxa Nível Não Especificado",
            "S79 - Outros Traumatismos e os Não Especificados do Quadril e da Coxa",
            "S79.7 - Traumatismos Múltiplos do Quadril e da Coxa",
            "S79.8 - Outros Traumatismos Especificados do Quadril e da Coxa",
            "S79.9 - Traumatismo Não Especificado do Quadril e da Coxa",
            "S80 - Traumatismo Superficial da Perna",
            "S80.0 - Contusão do Joelho",
            "S80.1 - Contusão de Outras Partes e de Partes Não Especificadas da Perna",
            "S80.7 - Traumatismos Superficiais Múltiplos da Perna",
            "S80.8 - Outros Traumatismos Superficiais da Perna",
            "S80.9 - Traumatismo Superficial Não Especificado da Perna",
            "S81 - Ferimento da Perna",
            "S81.0 - Ferimento do Joelho",
            "S81.7 - Ferimentos Múltiplos da Perna",
            "S81.8 - Ferimento de Outras Partes da Perna",
            "S81.9 - Ferimento da Perna, Parte Não Especificada",
            "S82 - Fratura da Perna, Incluindo Tornozelo",
            "S82.0 - Fratura da Rótula (patela)",
            "S82.1 - Fratura da Extremidade Proximal da Tíbia",
            "S82.2 - Fratura da Diáfise da Tíbia",
            "S82.3 - Fratura da Extremidade Distal da Tíbia",
            "S82.4 - Fratura do Perônio (fíbula)",
            "S82.5 - Fratura do Maléolo Medial",
            "S82.6 - Fratura do Maléolo Lateral",
            "S82.7 - Fraturas Múltiplas da Perna",
            "S82.8 - Fratura de Outras Partes da Perna",
            "S82.9 - Fratura da Perna, Parte Não Especificada",
            "S83 - Luxação, Entorse e Distensão Das Articulações e Dos Ligamentos do Joelho",
            "S83.0 - Luxação da Rótula (patela)",
            "S83.1 - Luxação do Joelho",
            "S83.2 - Ruptura do Menisco, Atual",
            "S83.3 - Ruptura Atual da Cartilagem da Articulação do Joelho",
            "S83.4 - Entorse e Distensão Envolvendo Ligamento Colateral (peronial) (tibial) do Joelho",
            "S83.5 - Entorse e Distensão Envolvendo Ligamento Cruzado (anterior) (posterior) do Joelho",
            "S83.6 - Entorse e Distensão de Outras Partes e Das Não Especificadas do Joelho",
            "S83.7 - Traumatismo de Estruturas Múltiplas do Joelho",
            "S84 - Traumatismo de Nervos Periféricos da Perna",
            "S84.0 - Traumatismo do Nervo Tibial ao Nível da Perna",
            "S84.1 - Traumatismo do Nervo Peronial ao Nível da Perna",
            "S84.2 - Traumatismo do Nervo Cutâneo Sensitivo ao Nível da Perna",
            "S84.7 - Traumatismo de Múltiplos Nervos ao Nível da Perna",
            "S84.8 - Traumatismo de Outros Nervos ao Nível da Perna",
            "S84.9 - Traumatismo de Nervo Não Especificado ao Nível da Perna",
            "S85 - Traumatismo de Vasos Sangüíneos da Perna",
            "S85.0 - Traumatismo da Artéria Poplítea",
            "S85.1 - Traumatismo da Artéria Tibial (anterior) (posterior)",
            "S85.2 - Traumatismo da Artéria Peronial",
            "S85.3 - Traumatismo da Veia Grande Safena ao Nível da Perna",
            "S85.4 - Traumatismo da Veia Safena Menor ao Nível da Perna",
            "S85.5 - Traumatismo da Veia Poplítea",
            "S85.7 - Traumatismo de Múltiplos Vasos Sangüíneos ao Nível da Perna",
            "S85.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível da Perna",
            "S85.9 - Traumatismo de Vasos Sangüíneos Não Especificados ao Nível da Perna",
            "S86 - Traumatismos de Músculo e de Tendão ao Nível da Perna",
            "S86.0 - Traumatismo do Tendão de Aquiles",
            "S86.1 - Traumatismo de Outro(s) Músculo(s) e Tendão(ões) do Grupo Muscular Posterior ao Nível da Perna",
            "S86.2 - Traumatismo Do(s) Músculo(s) e Tendão(ões) do Grupo Muscular Anterior ao Nível da Perna",
            "S86.3 - Traumatismo Do(s) Músculo(s) e Tendão(ões) do Grupo Muscular Peronial ao Nível da Perna",
            "S86.7 - Traumatismo de Múltiplos Músculos e Tendões ao Nível da Perna",
            "S86.8 - Traumatismos de Outros Músculos e Tendões ao Nível da Perna",
            "S86.9 - Traumatismo de Músculo e de Tendão Não Especificado ao Nível da Perna",
            "S87 - Traumatismo Por Esmagamento da Perna",
            "S87.0 - Traumatismo Por Esmagamento do Joelho",
            "S87.8 - Traumatismo Por Esmagamento de Outra Parte e de Partes Não Especificadas da Perna",
            "S88 - Amputação Traumática da Perna",
            "S88.0 - Amputação Traumática ao Nível do Joelho",
            "S88.1 - Amputação Traumática Entre o Joelho e o Tornozelo",
            "S88.9 - Amputação Traumática da Perna ao Nível Não Especificado",
            "S89 - Outros Traumatismos e os Não Especificados da Perna",
            "S89.0 - Traumatismos Múltiplos da Perna",
            "S89.8 - Outros Traumatismos Especificados da Perna",
            "S89.9 - Traumatismo Não Especificado da Perna",
            "S90 - Traumatismo Superficial do Tornozelo e do pé",
            "S90.0 - Contusão do Tornozelo",
            "S90.1 - Contusão de Artelho Sem Lesão da Unha",
            "S90.2 - Contusão de Artelho(s) Com Lesão da Unha",
            "S90.3 - Contusão de Outras Partes e Partes Não Especificadas do pé",
            "S90.7 - Traumatismos Superficiais Múltiplos do Tornozelo e do pé",
            "S90.8 - Outros Traumatismos Superficiais do Tornozelo e do pé",
            "S90.9 - Traumatismo Superficial do Tornozelo e do Pé, Não Especificado",
            "S91 - Ferimentos do Tornozelo e do pé",
            "S91.0 - Ferimento do Tornozelo",
            "S91.1 - Ferimento Do(s) Artelho(s) Sem Lesão da Unha",
            "S91.2 - Ferimento Do(s) Artelho(s) Com Lesão da Unha",
            "S91.3 - Ferimento de Outras Partes do pé",
            "S91.7 - Ferimentos Múltiplos do Tornozelo e do pé",
            "S92 - Fratura do pé (exceto do Tornozelo)",
            "S92.0 - Fratura do Calcâneo",
            "S92.1 - Fratura do Astrágalo",
            "S92.2 - Fratura de Outros Ossos do Tarso",
            "S92.3 - Fratura de Ossos do Metatarso",
            "S92.4 - Fratura do Hálux",
            "S92.5 - Fratura de Outro Artelho",
            "S92.7 - Fraturas Múltiplas do pé",
            "S92.9 - Fratura do pé Não Especificada",
            "S93 - Luxação, Entorse e Distensão Das Articulações e Dos Ligamentos ao Nível do Tornozelo e do pé",
            "S93.0 - Luxação da Articulação do Tornozelo",
            "S93.1 - Luxação Do(s) Artelho(s)",
            "S93.2 - Ruptura de Ligamentos ao Nível do Tornozelo e do pé",
            "S93.3 - Luxação de Outras Partes e Das Não Especificadas do pé",
            "S93.4 - Entorse e Distensão do Tornozelo",
            "S93.5 - Entorse e Distensão Do(s) Artelho(s)",
            "S93.6 - Entorse e Distensão de Outras Partes e de Partes Não Especificadas do pé",
            "S94 - Traumatismo Dos Nervos ao Nível do Tornozelo e do pé",
            "S94.0 - Traumatismo do Nervo Plantar Externo (lateral)",
            "S94.1 - Traumatismo do Nervo Plantar Interno (medial)",
            "S94.2 - Traumatismo Nervo Peronial (fibular) Profundo ao Nível do Tornozelo e do pé",
            "S94.3 - Traumatismo do Nervo Sensitivo Cutâneo ao Nível do Tornozelo e do pé",
            "S94.7 - Traumatismo de Múltiplos Nervos ao Nível do Tornozelo e do pé",
            "S94.8 - Traumatismo de Outros Nervos ao Nível do Tornozelo e do pé",
            "S94.9 - Traumatismo de Nervo Não Especificado, ao Nível do Tornozelo e do pé",
            "S95 - Traumatismo de Vasos Sangüíneos ao Nível do Tornozelo e do pé",
            "S95.0 - Traumatismo da Artéria Dorsal do pé",
            "S95.1 - Traumatismo da Artéria Plantar do pé",
            "S95.2 - Traumatismo da Veia Dorsal do pé",
            "S95.7 - Traumatismo de Múltiplos Vasos Sangüíneos ao Nível do Tornozelo e do pé",
            "S95.8 - Traumatismo de Outros Vasos Sangüíneos ao Nível do Tornozelo e do pé",
            "S95.9 - Traumatismo de Vaso Sangüíneo Não Especificado ao Nível do Tornozelo e do pé",
            "S96 - Traumatismos do Músculo e Tendão ao Nível do Tornozelo e do pé",
            "S96.0 - Traumatismo do Músculo e Tendão do Músculo Flexor Longo Do(s) Artelho(s) ao Nível do Tornozelo e do pé",
            "S96.1 - Traumatismo do Músculo e Tendão do Extensor Longo Do(s) Artelho(s) ao Nível do Tornozelo e do pé",
            "S96.2 - Traumatismo de Músculo Intrínseco e Tendão ao Nível do Tornozelo e do pé",
            "S96.7 - Traumatismo de Múltiplos Músculos e Tendões ao Nível do Tornozelo e do pé",
            "S96.8 - Traumatismo de Outros Tendões e Músculos ao Nível do Tornozelo e do pé",
            "S96.9 - Traumatismo de Músculo e Tendão Não Especificados do Tornozelo e do pé",
            "S97 - Lesão Por Esmagamento do Tornozelo e do pé",
            "S97.0 - Lesão Por Esmagamento do Tornozelo",
            "S97.1 - Lesão Por Esmagamento Do(s) Artelho(s)",
            "S97.8 - Lesão Por Esmagamento de Outras Partes do Tornozelo e do pé",
            "S98 - Amputação Traumática do Tornozelo e do pé",
            "S98.0 - Amputação Traumática do pé ao Nível do Tornozelo",
            "S98.1 - Amputação Traumática de Apenas um Artelho",
            "S98.2 - Amputação Traumática de Dois ou Mais Artelhos",
            "S98.3 - Amputação Traumática de Outras Partes do pé",
            "S98.4 - Amputação Traumática do pé ao Nível Não Especificado",
            "S99 - Outros Traumatismos e os Não Especificados do Tornozelo e do pé",
            "S99.7 - Traumatismos Múltiplos do Tornozelo e do pé",
            "S99.8 - Outros Traumatismos Especificados do Tornozelo e do pé",
            "S99.9 - Traumatismos Não Especificados do Tornozelo e do pé",
            "T00 - Traumatismos Superficiais Envolvendo Múltiplas Regiões do Corpo",
            "T00.0 - Traumatismos Superficiais Envolvendo a Cabeça Com o Pescoço",
            "T00.1 - Traumatismos Superficiais Envolvendo o Tórax Com o Abdome, Parte Inferior do Dorso e da Pelve",
            "T00.2 - Traumatismos Superficiais Envolvendo Regiões Múltiplas Do(s) Membro(s) Superior(es)",
            "T00.3 - Traumatismos Superficiais Envolvendo Regiões Múltiplas Do(s) Membro(s) Inferior(es)",
            "T00.6 - Traumatismos Superficiais Envolvendo Regiões Múltiplas Dos Membros Superiores Com Membro(s) Inferiores",
            "T00.8 - Traumatismos Superficiais Envolvendo Outras Combinações de Regiões do Corpo",
            "T00.9 - Traumatismos Superficiais Múltiplos Não Especificados",
            "T01 - Ferimentos Envolvendo Múltiplas Regiões do Corpo",
            "T01.0 - Ferimentos Envolvendo a Cabeça Com o Pescoço",
            "T01.1 - Ferimentos Envolvendo o Tórax Com o Abdome, Parte Inferior do Dorso e da Pelve",
            "T01.2 - Ferimentos Envolvendo Regiões Múltiplas Do(s) Membro(s) Superior(es)",
            "T01.3 - Ferimentos Envolvendo Múltiplas Regiões Do(s) Membro(s) Inferior(es)",
            "T01.6 - Ferimentos Envolvendo Regiões Múltiplas Do(s) Membro(s) Superior(es) Com Membro(s) Inferior(es)",
            "T01.8 - Ferimentos Envolvendo Outras Combinações de Regiões do Corpo",
            "T01.9 - Ferimentos Múltiplos Não Especificados",
            "T02 - Fraturas Envolvendo Múltiplas Regiões do Corpo",
            "T02.0 - Fraturas Envolvendo Cabeça Com Pescoço",
            "T02.1 - Fraturas Envolvendo Tórax Com Parte Inferior do Dorso e da Pelve",
            "T02.2 - Fraturas Envolvendo Regiões Múltiplas de um Membro Superior",
            "T02.3 - Fraturas Envolvendo Regiões Múltiplas de um Membro Inferior",
            "T02.4 - Fraturas Envolvendo Regiões Múltiplas de Ambos os Membros Superiores",
            "T02.5 - Fraturas Envolvendo Regiões Múltiplas de Ambos os Membros Inferiores",
            "T02.6 - Fraturas Envolvendo Regiões Múltiplas Do(s) Membro(s) Superior(es) Com Inferior(es)",
            "T02.7 - Fraturas Envolvendo Tórax Com Parte Inferior do Dorso e Pelve Com Membro(s)",
            "T02.8 - Fraturas Envolvendo Outras Combinações de Regiões do Corpo",
            "T02.9 - Fraturas Múltiplas Não Especificadas",
            "T03 - Luxações, Entorses e Distensões Envolvendo Regiões Múltiplas do Corpo",
            "T03.0 - Luxações, Entorses e Distensões Envolvendo a Cabeça Com o Pescoço",
            "T03.1 - Luxações, Entorses e Distensões Envolvendo Tórax Com Parte Inferior do Dorso e da Pelve",
            "T03.2 - Luxações, Entorses e Distensões Envolvendo Regiões Múltiplas de Membro(s) Superior(es)",
            "T03.3 - Luxações, Entorses e Distensões Envolvendo Regiões Múltiplas Do(s) Membro(s) Inferior(es)",
            "T03.4 - Luxações, Entorses e Distensões Envolvendo Regiões Múltiplas Dos Membros Superiores Com Inferiores",
            "T03.8 - Luxações, Entorses e Distensões Envolvendo Outras Combinações de Regiões do Corpo",
            "T03.9 - Luxações, Entorses e Distensões Múltiplas, Não Especificadas",
            "T04 - Traumatismos Por Esmagamento Envolvendo Múltiplas Regiões do Corpo",
            "T04.0 - Traumatismos Por Esmagamento Envolvendo a Cabeça Com o Pescoço",
            "T04.1 - Traumatismos Por Esmagamento Envolvendo Tórax Com o Abdome, Parte Inferior do Dorso e da Pelve",
            "T04.2 - Traumatismos Por Esmagamento Envolvendo Regiões Múltiplas Do(s) Membro(s) Superior(es)",
            "T04.3 - Traumatismos Por Esmagamento Envolvendo Regiões Múltiplas Do(s) Membro(s) Inferior(es)",
            "T04.4 - Traumatismos Por Esmagamento Envolvendo Regiões Múltiplas Do(s) Membro(s) Superior(es) Com Inferior(es)",
            "T04.7 - Traumatismo Por Esmagamento do Tórax Com Abdome, Parte Inferior do Dorso, Pelve e Membro(s)",
            "T04.8 - Traumatismos Por Esmagamento Envolvendo Outras Combinações de Regiões do Corpo",
            "T04.9 - Traumatismo Múltiplos Por Esmagamento Não Especificados",
            "T05 - Amputações Traumáticas Envolvendo Múltiplas Regiões do Corpo",
            "T05.0 - Amputação Traumática de Ambas as Mãos",
            "T05.1 - Amputação Traumática de Uma Mão e de um Outro Braço (qualquer Nível, Exceto Mão)",
            "T05.2 - Amputação Traumática de Ambos os Braços (qualquer Nível)",
            "T05.3 - Amputação Traumática de Ambos os Pés",
            "T05.4 - Amputação Traumática de um pé e Outra Perna (qualquer Nível, Exceto Pé)",
            "T05.5 - Amputação Traumática de Ambas as Pernas (qualquer Nível)",
            "T05.6 - Amputação Traumática de Membros Superiores e Inferiores, Qualquer Combinação (qualquer Nível)",
            "T05.8 - Amputações Traumáticas Envolvendo Outras Combinações de Regiões do Corpo",
            "T05.9 - Amputações Traumáticas Múltiplas Não Especificadas",
            "T06 - Outros Traumatismos Envolvendo Regiões Múltiplas do Corpo, Não Classificados em Outra Parte",
            "T06.0 - Traumatismos de Cérebro e Nervos Cranianos Com Traumatismos de Nervos e da Medula Espinhal ao Nível do Pescoço",
            "T06.1 - Traumatismos de Nervos e da Medula Espinhal Envolvendo Outras Regiões Múltiplas do Corpo",
            "T06.2 - Traumatismos de Nervos Envolvendo Regiões Múltiplas do Corpo",
            "T06.3 - Traumatismos de Vasos Sangüíneos Envolvendo Regiões Múltiplas do Corpo",
            "T06.4 - Traumatismos de Músculos e de Tendões Envolvendo Regiões Múltiplas do Corpo",
            "T06.5 - Traumatismos de Órgãos Intratorácicos Com Órgãos Intra-abdominais e Pélvicos",
            "T06.8 - Outros Traumatismos Especificados Envolvendo Regiões Múltiplas do Corpo",
            "T07 - Traumatismos Múltiplos Não Especificados",
            "T08 - Fratura da Coluna, Nível Não Especificado",
            "T09 - Outros Traumatismos de Coluna e Tronco, Nível Não Especificado",
            "T09.0 - Traumatismo Superficial do Tronco, Nível Não Especificado",
            "T09.1 - Ferimento do Tronco, Nível Não Especificado",
            "T09.2 - Luxação, Entorse e Distensão de Articulações e Ligamentos Não Especificados do Tronco",
            "T09.3 - Traumatismo de Medula Espinhal, Nível Não Especificado",
            "T09.4 - Traumatismo de Nervos, de Raízes de Nervos Espinhais e de Plexos Não Especificados do Tronco",
            "T09.5 - Traumatismo de Músculo e Tendão Não Especificados do Tronco",
            "T09.6 - Amputação Traumática do Tronco, Nível Não Especificado",
            "T09.8 - Outros Traumatismos Especificados do Tronco, Nível Não Especificado",
            "T09.9 - Traumatismo Não Especificado do Tronco, Nível Não Especificado",
            "T10 - Fratura do Membro Superior, Nível Não Especificado",
            "T11 - Outros Traumatismos de Membro Superior, Nível Não Especificado",
            "T11.0 - Traumatismo Superficial do Membro Superior, Nível Não Especificado",
            "T11.1 - Ferimento do Membro Superior, Nível Não Especificado",
            "T11.2 - Luxação, Entorse e Distensão de Articulação e Ligamento Não Especificados do Membro Superior, Nível Não Especificado",
            "T11.3 - Traumatismo de Nervo Não Especificado do Membro Superior, Nível Não Especificado",
            "T11.4 - Traumatismo de Vaso Sangüíneo Não Especificado, do Membro Superior, Nível Não Especificado",
            "T11.5 - Traumatismo de Músculo e Tendão Não Especificado do Membro Superior, Nível Não Especificado",
            "T11.6 - Amputação Traumática do Membro Superior, Nível Não Especificado",
            "T11.8 - Outros Traumatismos Especificados do Membro Superior Nível Não Especificado",
            "T11.9 - Traumatismo Não Especificado do Membro Superior Nível Não Especificado",
            "T12 - Fratura do Membro Inferior, Nível Não Especificado",
            "T13 - Outros Traumatismos de Membro Inferior, Nível Não Especificado",
            "T13.0 - Traumatismo Superficial de Membro Inferior, Nível Não Especificado",
            "T13.1 - Ferimento de Membro Inferior, Nível Não Especificado",
            "T13.2 - Luxação, Entorse e Distensão de Articulação e Ligamento Não Especificados de Membro Inferior, Nível Não Especificado",
            "T13.3 - Traumatismo de Nervo Não Especificado de Membro Inferior, Nível Não Especificado",
            "T13.4 - Traumatismo de Vaso Sangüíneo Não Especificado de Membro Inferior, Nível Não Especificado",
            "T13.5 - Traumatismo de Músculo e Tendão Não Especificado de Membro Inferior, Nível Não Especificado",
            "T13.6 - Amputação Traumática de Membro Inferior, Nível Não Especificado",
            "T13.8 - Outros Traumatismos Especificados de Membro Inferior, Nível Não Especificado",
            "T13.9 - Traumatismo Não Especificado do Membro Inferior, Nível Não Especificado",
            "T14 - Traumatismo de Região Não Especificada do Corpo",
            "T14.0 - Traumatismo Superficial de Região Não Especificada do Corpo",
            "T14.1 - Ferimento de Região Não Especificada do Corpo",
            "T14.2 - Fratura de Região Não Especificada do Corpo",
            "T14.3 - Luxação, Entorse e Distensão de Região Não Especificada do Corpo",
            "T14.4 - Traumatismo de Nervo(s) de Região Não Especificada do Corpo",
            "T14.5 - Traumatismo de Vaso(s) Sangüíneo(s) de Região Não Especificada do Corpo",
            "T14.6 - Traumatismo de Músculos e Tendões de Região Não Especificada do Corpo",
            "T14.7 - Traumatismo Por Esmagamento e Amputação Traumática de Regiões Não Especificadas do Corpo",
            "T14.8 - Outros Traumatismos de Região Não Especificada do Corpo",
            "T14.9 - Traumatismo Não Especificado",
            "T15 - Corpo Estranho na Parte Externa do Olho",
            "T15.0 - Corpo Estranho na Córnea",
            "T15.1 - Corpo Estranho no Saco Conjuntival",
            "T15.8 - Corpo Estranho em Outros Locais e em Locais Múltiplos da Parte Externa do Olho",
            "T15.9 - Corpo Estranho em Parte Não Especificada da Região Externa do Olho",
            "T16 - Corpo Estranho no Ouvido",
            "T17 - Corpo Estranho no Trato Respiratório",
            "T17.0 - Corpo Estranho no Seio Nasal",
            "T17.1 - Corpo Estranho na Narina",
            "T17.2 - Corpo Estranho na Faringe",
            "T17.3 - Corpo Estranho na Laringe",
            "T17.4 - Corpo Estranho na Traquéia",
            "T17.5 - Corpo Estranho no Brônquio",
            "T17.8 - Corpo Estranho em Outras Partes e Partes Múltiplas do Trato Respiratório",
            "T17.9 - Corpo Estranho no Trato Respiratório, Parte Não Especificada",
            "T18 - Corpo Estranho no Aparelho Digestivo",
            "T18.0 - Corpo Estranho na Boca",
            "T18.1 - Corpo Estranho no Esôfago",
            "T18.2 - Corpo Estranho no Estômago",
            "T18.3 - Corpo Estranho no Intestino Delgado",
            "T18.4 - Corpo Estranho no Cólon",
            "T18.5 - Corpo Estranho no Ânus e Reto",
            "T18.8 - Corpo Estranho em Outras Partes e Partes Múltiplas do Aparelho Digestivo",
            "T18.9 - Corpo Estranho em Parte Não Especificada do Aparelho Digestivo",
            "T19 - Corpo Estranho no Trato Geniturinário",
            "T19.0 - Corpo Estranho na Uretra",
            "T19.1 - Corpo Estranho na Bexiga",
            "T19.2 - Corpo Estranho na Vulva e Vagina",
            "T19.3 - Corpo Estranho no Útero (qualquer Parte)",
            "T19.8 - Corpo Estranho em Outras Partes e Partes Múltiplas do Trato Geniturinário",
            "T19.9 - Corpo Estranho no Trato Geniturinário, Parte Não Especificada",
            "T20 - Queimadura e Corrosão da Cabeça e Pescoço",
            "T20.0 - Queimadura da Cabeça e do Pescoço, Grau Não Especificado",
            "T20.1 - Queimadura de Primeiro Grau da Cabeça e do Pescoço",
            "T20.2 - Queimadura de Segundo Grau da Cabeça e do Pescoço",
            "T20.3 - Queimadura de Terceiro Grau da Cabeça e do Pescoço",
            "T20.4 - Corrosão da Cabeça e do Pescoço, Grau Não Especificado",
            "T20.5 - Corrosão de Primeiro Grau da Cabeça e do Pescoço",
            "T20.6 - Corrosão de Segundo Grau da Cabeça e do Pescoço",
            "T20.7 - Corrosão de Terceiro Grau da Cabeça e do Pescoço",
            "T21 - Queimadura e Corrosão do Tronco",
            "T21.0 - Queimadura do Tronco, Grau Não Especificado",
            "T21.1 - Queimadura de Primeiro Grau do Tronco",
            "T21.2 - Queimadura de Segundo Grau do Tronco",
            "T21.3 - Queimadura de Terceiro Grau do Tronco",
            "T21.4 - Corrosão do Tronco, Grau Não Especificado",
            "T21.5 - Corrosão de Primeiro Grau do Tronco",
            "T21.6 - Corrosão de Segundo Grau do Tronco",
            "T21.7 - Corrosão de Terceiro Grau do Tronco",
            "T22 - Queimadura e Corrosão do Ombro e Membro Superior, Exceto Punho e Mão",
            "T22.0 - Queimadura do Ombro e do Membro Superior, Exceto Punho e Mão, Grau Não Especificado",
            "T22.1 - Queimadura de Primeiro Grau do Ombro e do Membro Superior, Exceto Punho e Mão",
            "T22.2 - Queimadura de Segundo Grau do Ombro e do Membro Superior, Exceto Punho e Mão",
            "T22.3 - Queimadura de Terceiro Grau do Ombro e do Membro Superior, Exceto Punho e Mão",
            "T22.4 - Corrosão do Ombro e do Membro Superior, Exceto Punho e Mão, Grau Não Especificado",
            "T22.5 - Corrosão de Primeiro Grau do Ombro e do Membro Superior, Exceto Punho e Mão",
            "T22.6 - Corrosão de Segundo Grau do Ombro e do Membro Superior, Exceto Punho e Mão",
            "T22.7 - Corrosão de Terceiro Grau do Ombro e do Membro Superior, Exceto Punho e Mão",
            "T23 - Queimadura e Corrosão do Punho e da Mão",
            "T23.0 - Queimadura do Punho e da Mão, Grau Não Especificado",
            "T23.1 - Queimadura de Primeiro Grau do Punho e da Mão",
            "T23.2 - Queimadura de Segundo Grau do Punho e da Mão",
            "T23.3 - Queimadura de Terceiro Grau do Punho e da Mão",
            "T23.4 - Corrosão do Punho e da Mão, Grau Não Especificado",
            "T23.5 - Corrosão de Primeiro Grau do Punho e da Mão",
            "T23.6 - Corrosão de Segundo Grau do Punho e da Mão",
            "T23.7 - Corrosão de Terceiro Grau do Punho e da Mão",
            "T24 - Queimadura e Corrosão do Quadril e Membro Inferior, Exceto Tornozelo e do pé",
            "T24.0 - Queimadura do Quadril e Membro Inferior, Exceto Tornozelo e do Pé, Grau Não Especificado",
            "T24.1 - Queimadura de Primeiro Grau do Quadril e do Membro Inferior, Exceto Tornozelo e do pé",
            "T24.2 - Queimadura de Segundo Grau do Quadril e do Membro Inferior, Exceto Tornozelo e do pé",
            "T24.3 - Queimadura de Terceiro Grau do Quadril e do Membro Inferior, Exceto Tornozelo e do pé",
            "T24.4 - Corrosão do Quadril e do Membro Inferior, Exceto Tornozelo e do Pé, Grau Não Especificado",
            "T24.5 - Corrosão de Primeiro Grau do Quadril e do Membro Inferior, Exceto Tornozelo e do pé",
            "T24.6 - Corrosão de Segundo Grau do Quadril e do Membro Inferior, Exceto Tornozelo e do pé",
            "T24.7 - Corrosão de Terceiro Grau do Quadril e do Membro Inferior, Exceto Tornozelo e do pé",
            "T25 - Queimadura e Corrosão do Tornozelo e do pé",
            "T25.0 - Queimadura do Tornozelo e do Pé, Grau Não Especificado",
            "T25.1 - Queimadura de Primeiro Grau do Tornozelo e do pé",
            "T25.2 - Queimadura de Segundo Grau do Tornozelo e do pé",
            "T25.3 - Queimadura de Terceiro Grau do Tornozelo e do pé",
            "T25.4 - Corrosão do Tornozelo e do Pé, Grau Não Especificado",
            "T25.5 - Corrosão de Primeiro Grau do Tornozelo e do pé",
            "T25.6 - Corrosão de Segundo Grau do Tornozelo e do pé",
            "T25.7 - Corrosão de Terceiro Grau do Tornozelo e do pé",
            "T26 - Queimadura e Corrosão Limitadas ao Olho e Seus Anexos",
            "T26.0 - Queimadura da Pálpebra e da Região Periocular",
            "T26.1 - Queimadura da Córnea e do Saco Conjuntival",
            "T26.2 - Queimadura Com Resultante Ruptura e Destruição do Globo Ocular",
            "T26.3 - Queimadura de Outras Partes do Olho e Anexos",
            "T26.4 - Queimadura do Olho e Anexos, Parte Não Especificada",
            "T26.5 - Corrosão da Pálpebra e da Região Periocular",
            "T26.6 - Corrosão da Córnea e do Saco Conjuntival",
            "T26.7 - Corrosão Com Resultante Ruptura e Destruição do Globo Ocular",
            "T26.8 - Corrosão de Outras Partes do Olho e Anexos",
            "T26.9 - Corrosão do Olho e Anexos, Parte Não Especificada",
            "T27 - Queimadura e Corrosão do Trato Respiratório",
            "T27.0 - Queimadura da Laringe e Traquéia",
            "T27.1 - Queimadura da Laringe, Traquéia Com Pulmão",
            "T27.2 - Queimadura de Outras Partes do Trato Respiratório",
            "T27.3 - Queimadura do Trato Respiratório, Parte Não Especificada",
            "T27.4 - Corrosão da Laringe e Traquéia",
            "T27.5 - Corrosão da Laringe, Traquéia Com Corrosão de Pulmão",
            "T27.6 - Corrosão de Outras Partes do Trato Respiratório",
            "T27.7 - Corrosão do Trato Respiratório, Parte Não Especificada",
            "T28 - Queimadura e Corrosão de Outros Órgãos Internos",
            "T28.0 - Queimadura da Boca e da Faringe",
            "T28.1 - Queimadura do Esôfago",
            "T28.2 - Queimadura de Outras Partes do Trato Alimentar",
            "T28.3 - Queimadura Dos Órgãos Geniturinários Internos",
            "T28.4 - Queimadura de Outros Órgãos Internos e Dos Não Especificados",
            "T28.5 - Corrosão da Boca e Faringe",
            "T28.6 - Corrosão do Esôfago",
            "T28.7 - Corrosão de Outras Partes do Trato Alimentar",
            "T28.8 - Corrosão de Órgãos Geniturinários Internos",
            "T28.9 - Corrosão de Outros Órgãos Internos e Dos Não Especificados",
            "T29 - Queimaduras e Corrosões de Múltiplas Regiões do Corpo",
            "T29.0 - Queimaduras Múltiplas, Grau Não Especificado",
            "T29.1 - Queimaduras Múltiplas, Sem Mencionar Queimadura(s) Ultrapassando o Primeiro Grau",
            "T29.2 - Queimaduras Múltiplas, Sem Mencionar Queimadura(s) Ultrapassando o Segundo Grau",
            "T29.3 - Queimaduras Múltiplas, Mencionando ao Menos Uma Queimadura de Terceiro Grau",
            "T29.4 - Corrosões Múltiplas, Grau Não Especificado",
            "T29.5 - Corrosões Múltiplas, Sem Mencionar Corrosão(ões) Ultrapassando o Primeiro Grau",
            "T29.6 - Corrosões Múltiplas, Sem Mencionar Corrosão(ões) Ultrapassando o Segundo Grau",
            "T29.7 - Corrosões Múltiplas, Mencionado ao Menos Uma Corrosão de Terceiro Grau",
            "T30 - Queimadura e Corrosão, Parte Não Especificada do Corpo",
            "T30.0 - Queimadura, Parte do Corpo Não Especificada, Grau Não Especificado",
            "T30.1 - Queimadura de Primeiro Grau, Parte do Corpo Não Especificada",
            "T30.2 - Queimadura de Segundo Grau, Parte do Corpo Não Especificada",
            "T30.3 - Queimadura de Terceiro Grau, Parte do Corpo Não Especificada",
            "T30.4 - Corrosão, Parte do Corpo Não Especificada, Grau Não Especificado",
            "T30.5 - Corrosão de Primeiro Grau, Parte do Corpo Não Especificada",
            "T30.6 - Corrosão de Segundo Grau, Parte do Corpo Não Especificada",
            "T30.7 - Corrosão de Terceiro Grau, Parte do Corpo Não Especificada",
            "T31 - Queimaduras Classificadas Segundo a Extensão da Superfície Corporal Atingida",
            "T31.0 - Queimaduras Envolvendo Menos de 10% da Superfície Corporal",
            "T31.1 - Queimaduras Envolvendo de 10 - 19% da Superfície Corporal",
            "T31.2 - Queimaduras Envolvendo de 20 - 29% da Superfície Corporal",
            "T31.3 - Queimaduras Envolvendo de 30 - 39% da Superfície Corporal",
            "T31.4 - Queimaduras Envolvendo de 40 - 49% da Superfície Corporal",
            "T31.5 - Queimaduras Envolvendo de 50 - 59% da Superfície Corporal",
            "T31.6 - Queimaduras Envolvendo de 60 - 69% da Superfície Corporal",
            "T31.7 - Queimaduras Envolvendo de 70 - 79% da Superfície Corporal",
            "T31.8 - Queimaduras Envolvendo de 80 - 89% da Superfície Corporal",
            "T31.9 - Queimaduras Envolvendo 90% ou Mais da Superfície Corporal",
            "T32 - Corrosões Classificadas Segundo a Extensão da Superfície Corporal Atingida",
            "T32.0 - Corrosões Envolvendo Menos de 10% da Superfície Corporal",
            "T32.1 - Corrosões Envolvendo 10 - 19% da Superfície Corporal",
            "T32.2 - Corrosões Envolvendo 20 - 29% da Superfície Corporal",
            "T32.3 - Corrosões Envolvendo 30 - 39% da Superfície Corporal",
            "T32.4 - Corrosões Envolvendo 40 - 49% da Superfície Corporal",
            "T32.5 - Corrosões Envolvendo 50 - 59% da Superfície Corporal",
            "T32.6 - Corrosões Envolvendo 60 - 69% da Superfície Corporal",
            "T32.7 - Corrosões Envolvendo 70 - 79% da Superfície Corporal",
            "T32.8 - Corrosões Envolvendo 80 - 89% da Superfície Corporal",
            "T32.9 - Corrosões Envolvendo 90% ou Mais da Superfície Corporal",
            "T33 - Geladura Superficial",
            "T33.0 - Geladura Superficial da Cabeça",
            "T33.1 - Geladura Superficial do Pescoço",
            "T33.2 - Geladura Superficial do Tórax",
            "T33.3 - Geladura Superficial da Parede Abdominal, Parte Inferior do Dorso e da Pelve",
            "T33.4 - Geladura Superficial do Braço",
            "T33.5 - Geladura Superficial do Punho e da Mão",
            "T33.6 - Geladura Superficial do Quadril e da Coxa",
            "T33.7 - Geladura Superficial do Joelho e da Perna",
            "T33.8 - Geladura Superficial do Tornozelo e do pé",
            "T33.9 - Geladura Superficial de Outros Locais e de Locais Não Especificados",
            "T34 - Geladura Com Necrose de Tecidos",
            "T34.0 - Geladura, Com Necrose de Tecidos, da Cabeça",
            "T34.1 - Geladura, Com Necrose de Tecidos, do Pescoço",
            "T34.2 - Geladura, Com Necrose de Tecidos, do Tórax",
            "T34.3 - Geladura, Com Necrose de Tecidos, da Parede Abdominal, Parte Inferior do Dorso e da Pelve",
            "T34.4 - Geladura, Com Necrose de Tecidos, do Braço",
            "T34.5 - Geladura, Com Necrose de Tecidos, do Punho e da Mão",
            "T34.6 - Geladura, Com Necrose de Tecidos, do Quadril e da Coxa",
            "T34.7 - Geladura, Com Necrose de Tecidos, do Joelho e da Perna",
            "T34.8 - Geladura, Com Necrose de Tecidos, do Tornozelo e do pé",
            "T34.9 - Geladura, Com Necrose de Tecidos, de Localização Não Especificada",
            "T35 - Geladura de Múltiplas Partes do Corpo e Das Não Especificadas",
            "T35.0 - Geladura Superficial Envolvendo Múltiplas Partes do Corpo",
            "T35.1 - Geladura, Com Necrose de Tecidos, de Múltiplas Partes do Corpo",
            "T35.2 - Geladura, de Grau Não Especificado, da Cabeça e do Pescoço",
            "T35.3 - Geladura, de Grau Não Especificado, do Tórax, Abdome, Parte Inferior do Dorso e da Pelve",
            "T35.4 - Geladura, de Grau Não Especificado, do Membro Superior",
            "T35.5 - Geladura, de Grau Não Especificado, do Membro Inferior",
            "T35.6 - Geladura, de Grau Não Especificado, de Múltiplas Partes do Corpo",
            "T35.7 - Geladura, de Grau Não Especificado, de Localização Não Especificada",
            "T36 - Intoxicação Por Antibióticos Sistêmicos",
            "T36.0 - Intoxicação Por Penicilinas",
            "T36.1 - Intoxicação Por Cefalosporinas e Outras Betalactaminas",
            "T36.2 - Intoxicação Pelo Grupo do Cloranfenicol",
            "T36.3 - Intoxicação Por Macrolídios",
            "T36.4 - Intoxicação Por Tetraciclinas",
            "T36.5 - Intoxicação Por Aminoglicosídeos",
            "T36.6 - Intoxicação Por Rifamicinas",
            "T36.7 - Intoxicação Por Antibióticos Antifúngicos Administrados Por Via Sistêmica",
            "T36.8 - Intoxicação Por Outros Antibióticos Sistêmicos",
            "T36.9 - Intoxicação Por Antibióticos Sistêmicos Não Especificados",
            "T37 - Intoxicação Por Outras Substâncias Antiinfecciosas ou Antiparasitárias Sistêmicas",
            "T37.0 - Intoxicação Por Sulfonamidas",
            "T37.1 - Intoxicação Por Drogas Antimicobacterianas",
            "T37.2 - Intoxicação Por Antimaláricos e Drogas Que Atuam Sobre Outros Protozoários do Sangue",
            "T37.3 - Intoxicação Por Outros Medicamentos Antiprotozoários",
            "T37.4 - Intoxicação Por Anti-helmínticos",
            "T37.5 - Intoxicação Por Drogas Antivirais",
            "T37.8 - Intoxicação Por Outros Agentes Antiinfecciosos e Antiparasitários Especificados",
            "T37.9 - Intoxicação Por Agentes Antiinfecciosos e Antiparasitários Sistêmicos Não Especificados",
            "T38 - Intoxicação Por Hormônios, Seus Substitutos Sintéticos e Seus Antagonistas Não Classificados em Outra Parte",
            "T38.0 - Intoxicação Por Glicocorticóides e Análogos Sintéticos",
            "T38.1 - Intoxicação Por Hormônios Tireoidianos e Seus Derivados",
            "T38.2 - Intoxicação Por Medicamentos Antitireoidianos",
            "T38.3 - Intoxicação Por Insulina e Drogas Hipoglicemiantes Orais (antidiabéticos)",
            "T38.4 - Intoxicação Por Anticoncepcionais Orais",
            "T38.5 - Intoxicação Por Outros Estrógenos e Progestágenos",
            "T38.6 - Intoxicação Por Antigonadotrofinas, Antiestrógenos, Antiandrógenos Não Classificados em Outra Parte",
            "T38.7 - Intoxicação Por Andrógenos e Anabolizantes Congêneres",
            "T38.8 - Intoxicação Por Outros Hormônios e Substitutos Sintéticos, e os Não Especificados",
            "T38.9 - Intoxicação Por Outros Antagonistas Hormonais, e os Não Especificados",
            "T39 - Intoxicação Por Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos",
            "T39.0 - Intoxicação Por Salicilatos",
            "T39.1 - Intoxicação Por Derivados do 4-aminofenol",
            "T39.2 - Intoxicação Por Derivados Pirazolônicos",
            "T39.3 - Intoxicação Por Outros Antiinflamatórios Não Esteróides",
            "T39.4 - Intoxicação Por Anti-reumáticos Não Classificados em Outra Parte",
            "T39.8 - Intoxicação Por Outros Analgésicos Não Opiáceos e Antipiréticos Não Classificados em Outra Parte",
            "T39.9 - Intoxicação Por Analgésico Não-opiáceo, Antipirético e Anti-reumático, Não Especificados",
            "T40 - Intoxicação Por Narcóticos e Psicodislépticos (alucinógenos)",
            "T40.0 - Intoxicação Por Ópio",
            "T40.1 - Intoxicação Por Heroína",
            "T40.2 - Intoxicação Por Outros Opiáceos",
            "T40.3 - Intoxicação Por Metadona",
            "T40.4 - Intoxicação Por Outros Narcóticos Sintéticos",
            "T40.5 - Intoxicação Por Cocaína",
            "T40.6 - Intoxicação Por Outros Narcóticos e os Não Especificados",
            "T40.7 - Intoxicação Por Cannabis (derivados)",
            "T40.8 - Intoxicação Por Lisergida (LSD)",
            "T40.9 - Intoxicação Por Outros Psicodislépticos e os Não Especificados (alucinógenos)",
            "T41 - Intoxicação Por Anestésicos e Gases Terapêuticos",
            "T41.0 - Intoxicação Por Anestésicos Inalatórios",
            "T41.1 - Intoxicação Por Anestésicos Intravenosos",
            "T41.2 - Intoxicação Por Outros Anestésicos Gerais e os Não Especificados",
            "T41.3 - Intoxicação Por Anestésicos Locais",
            "T41.4 - Intoxicação Por Anestésico Não Especificado",
            "T41.5 - Intoxicação Por Gases Terapêuticos",
            "T42 - Intoxicação Por Antiepilépticos, Sedativos-hipnóticos e Antiparkinsonianos",
            "T42.0 - Intoxicação Por Derivados da Hidantoína",
            "T42.1 - Intoxicação Por Iminostilbenos",
            "T42.2 - Intoxicação Por Succinimidas e Oxazolidinadionas",
            "T42.3 - Intoxicação Por Barbitúricos",
            "T42.4 - Intoxicação Por Benzodiazepinas",
            "T42.5 - Intoxicação Por Antiepiléticos em Associação, Não Classificados em Outra Parte",
            "T42.6 - Intoxicação Por Outras Drogas Antiepilépticas e Sedativos-hipnóticos",
            "T42.7 - Intoxicação Por Drogas Antiepilépticos, Sedativos-hipnóticos, Não Especificados",
            "T42.8 - Intoxicação Por Drogas Antiparkinsonianos e Outros Depressores Centrais do Tônus Muscular",
            "T43 - Intoxicação Por Drogas Psicotrópicas Não Classificadas em Outra Parte",
            "T43.0 - Intoxicação Por Antidepressivos Tricíclicos e Tetracíclicos",
            "T43.1 - Intoxicação Por Antidepressivos Inibidores da Monoaminooxidase (MAO)",
            "T43.2 - Intoxicação Por Outros Antidepressivos e os Não Especificados",
            "T43.3 - Intoxicação Por Antipsicóticos e Neurolépticos Derivados da Fenotiazina",
            "T43.4 - Intoxicação Por Neurolépticos do Tipo Butirofenona e Tioxanteno",
            "T43.5 - Intoxicação Por Outros Antipsicóticos e Neurolépticos e os Não Especificados",
            "T43.6 - Intoxicação Por Psicoestimulantes Que Potencialmente Podem Provocar Dependência",
            "T43.8 - Intoxicação Por Outras Drogas Psicotrópicas Não Classificadas em Outra Parte",
            "T43.9 - Intoxicação Por Droga Psicotrópica Não Especificada",
            "T44 - Intoxicação Por Drogas Que Afetam Principalmente o Sistema Nervoso Autônomo",
            "T44.0 - Intoxicação Por Agentes Anticolinesterase",
            "T44.1 - Intoxicação Por Outros Agentes Parassimpaticomiméticos (colinérgicos)",
            "T44.2 - Intoxicação Por Bloqueadores Ganglionares Não Classificados em Outra Parte",
            "T44.3 - Intoxicação Por Outros Parassimpaticolíticos (anticolinérgicos e Antimuscarínicos) e Espasmolíticos Não Classificados em Outra Parte",
            "T44.4 - Intoxicação Por Agonistas Principalmente Alfa-adrenorreceptores Não Classificados em Outra Parte",
            "T44.5 - Intoxicação Por Agonistas Principalmente Beta-adrenorreceptores Não Classificados em Outra Parte",
            "T44.6 - Intoxicação Por Antagonistas Alfa-adrenorreceptores Não Classificados em Outra Parte",
            "T44.7 - Intoxicação Por Antagonistas Beta-adrenorreceptores Não Classificados em Outra Parte",
            "T44.8 - Intoxicação Por Bloqueadores Neuronais Adrenérgicos e de Ação Central, Não Classificados em Outra Parte",
            "T44.9 - Intoxicação Por Outras Drogas Que Afetam Principalmente o Sistema Nervoso Autônomo e as Não Especificadas",
            "T45 - Intoxicação Por Substâncias de Ação Essencialmente Sistêmica e Substâncias Hematológicas, Não Classificadas em Outra Parte",
            "T45.0 - Intoxicação Por Drogas Antialérgicas e Antieméticas",
            "T45.1 - Intoxicação Por Drogas Antineoplásicas e Imunomupressoras",
            "T45.2 - Intoxicação Por Vitaminas, Não Classificadas em Outra Parte",
            "T45.3 - Intoxicação Por Enzimas, Não Classificadas em Outra Parte",
            "T45.4 - Intoxicação Por Ferro e Seus Compostos",
            "T45.5 - Intoxicação Por Anticoagulantes",
            "T45.6 - Intoxicação Por Drogas Que Afetam a Fibrinólise",
            "T45.7 - Intoxicação Por Antagonistas de Anticoagulantes, Vitamina K e de Outros Fatores de Coagulação",
            "T45.8 - Intoxicação Por Outras Substâncias Primariamente Sistêmicas e Hematológicas",
            "T45.9 - Intoxicação Por Substância Essencialmente Sistêmica e Hematológica Não Especificada",
            "T46 - Intoxicação Por Substâncias Que Atuam Primariamente Sobre o Aparelho Circulatório",
            "T46.0 - Intoxicação Por Glicosídeos Estimulantes do Coração e Substâncias de Ação Semelhante",
            "T46.1 - Intoxicação Por Bloqueadores Dos Canais de Cálcio",
            "T46.2 - Intoxicação Por Outras Drogas Antidisrítmicas Não Classificadas em Outra Parte",
            "T46.3 - Intoxicação Por Vasodilatadores Coronarianos, Não Classificados em Outra Parte",
            "T46.4 - Intoxicação Por Inibidores da Enzima de Conversão da Angiotensina",
            "T46.5 - Intoxicação Por Outras Drogas Anti-hipertensivas Não Classificadas em Outra Parte",
            "T46.6 - Intoxicação Por Drogas Antihiperlipêmicas e Antiarterioscleróticas",
            "T46.7 - Intoxicação Por Vasodilatadores Periféricos",
            "T46.8 - Intoxicação Por Drogas Antivaricosas, Incluindo Substâncias Esclerosantes",
            "T46.9 - Intoxicação Por Outras Substâncias e as Não Especificadas Que Agem Essencialmente Sobre o Aparelho Circulatório",
            "T47 - Intoxicação Por Substâncias Que Atuam Primariamente Sobre o Aparelho Gastrointestinal",
            "T47.0 - Intoxicação Por Antagonistas Dos Receptores H2 da Histamina",
            "T47.1 - Intoxicação Por Outros Antiácidos e Drogas Que Inibem a Secreção Gástrica",
            "T47.2 - Intoxicação Por Laxativos Estimulantes",
            "T47.3 - Intoxicação Por Laxativos Salinos e Osmóticos",
            "T47.4 - Intoxicação Por Outros Laxativos",
            "T47.5 - Intoxicação Por Digestivos",
            "T47.6 - Intoxicação Por Drogas Antidiarréicas",
            "T47.7 - Intoxicação Por Eméticos",
            "T47.8 - Intoxicação Por Outras Substâncias Que Atuam Primariamente Sobre o Aparelho Gastrintestinal",
            "T47.9 - Intoxicação Por Substância Não Especificada Que Atua Primariamente Sobre o Aparelho Gastrointestinal",
            "T48 - Intoxicação Por Substâncias Que Atuam Primariamente Sobre os Músculos Lisos e Esqueléticos e Sobre o Aparelho Respiratório",
            "T48.0 - Intoxicação Por Drogas Ocitócicas",
            "T48.1 - Intoxicação Por Relaxantes Musculares Esqueléticos (bloqueadores Neuromusculares)",
            "T48.2 - Intoxicação Por Outras Substâncias e as Não Especificadas Que Atuam Sobre os Músculos",
            "T48.3 - Intoxicação Por Antitussígenos",
            "T48.4 - Intoxicação Por Expectorantes",
            "T48.5 - Intoxicação Por Drogas Anti-resfriado",
            "T48.6 - Intoxicação Por Antiasmáticos Não Classificados em Outra Parte",
            "T48.7 - Intoxicação Por Outras Substâncias e as Não Especificadas Que Atuam Primariamente Sobre o Aparelho Respiratório",
            "T49 - Intoxicação Por Substâncias de Uso Tópico Que Atuam Primariamente Sobre a Pele e as Mucosas e Por Medicamentos Utilizados em Oftalmologia, Otorrinolaringologia e Odontologia",
            "T49.0 - Intoxicação Por Antifúngicos, Antiinfecciosos e Antiinflamatórios de Uso Tópico, Não Classificados em Outra Parte",
            "T49.1 - Intoxicação Por Antipruriginosos",
            "T49.2 - Intoxicação Por Adstringentes e Detergentes de Uso Local",
            "T49.3 - Intoxicação Por Emolientes, Demulcentes e Protetores",
            "T49.4 - Intoxicação Por Ceratolíticos, Ceratoplásticos e Outras Drogas e Preparados Para o Tratamento Dos Cabelos",
            "T49.5 - Intoxicação Por Drogas e Preparações de Uso Oftalmológico",
            "T49.6 - Intoxicação Por Drogas e Preparações de Uso Otorrinolaringológico",
            "T49.7 - Intoxicação Por Drogas Dentárias de Uso Tópico",
            "T49.8 - Intoxicação Por Outras Drogas de Uso Tópico",
            "T49.9 - Intoxicação Por Preparado de Uso Tópico, Não Especificado",
            "T50 - Intoxicação Por Diuréticos e Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas",
            "T50.0 - Intoxicação Por Mineralocorticóides e Seus Antagonistas",
            "T50.1 - Intoxicação Por Diuréticos de Alça",
            "T50.2 - Intoxicação Por Inibidores da Anidrase Carbônica, Benzotiazidas e Outros Diuréticos",
            "T50.3 - Intoxicação Por Produtos Que Agem Sobre o Equilíbrio Eletrolítico, Calórico e Hídrico",
            "T50.4 - Intoxicação Por Drogas Que Agem Sobre o Metabolismo do Ácido Úrico",
            "T50.5 - Intoxicação Por Inibidores do Apetite",
            "T50.6 - Intoxicação Por Antídotos e Quelantes Não Classificados em Outra Parte",
            "T50.7 - Intoxicação Por Analépticos e Antagonistas Dos Receptores de Opiáceos",
            "T50.8 - Intoxicação Por Agentes de Diagnóstico",
            "T50.9 - Intoxicação Por Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas",
            "T51 - Efeito Tóxico do Álcool",
            "T51.0 - Efeito Tóxico do Etanol",
            "T51.1 - Efeito Tóxico do Metanol",
            "T51.2 - Efeito Tóxico do 2-propanol",
            "T51.3 - Efeito Tóxico do Óleo de Fusel",
            "T51.8 - Efeito Tóxico de Outros Álcoois",
            "T51.9 - Efeito Tóxico de Álcool Não Especificado",
            "T52 - Efeito Tóxico de Solventes Orgânicos",
            "T52.0 - Efeito Tóxico de Derivados do Petróleo",
            "T52.1 - Efeito Tóxico do Benzeno",
            "T52.2 - Efeito Tóxico de Homólogos do Benzeno",
            "T52.3 - Efeito Tóxico de Glicóis",
            "T52.4 - Efeito Tóxico de Cetonas",
            "T52.8 - Efeito Tóxico de Outros Solventes Orgânicos",
            "T52.9 - Efeito Tóxico de Solvente Orgânico Não Especificado",
            "T53 - Efeito Tóxico de Derivados Halogênicos de Hidrocarbonetos Alifáticos e Aromáticos",
            "T53.0 - Efeito Tóxico do Tetracloreto de Carbono",
            "T53.1 - Efeito Tóxico do Clorofórmio",
            "T53.2 - Efeito Tóxico do Tricloroetileno",
            "T53.3 - Efeito Tóxico do Tetracloroetileno",
            "T53.4 - Efeito Tóxico do Diclorometano",
            "T53.5 - Efeito Tóxico do Clorofluorcarbono",
            "T53.6 - Efeito Tóxico de Outros Derivados Halogênicos de Hidrocarbonetos Alifáticos",
            "T53.7 - Efeito Tóxico de Derivados Halogênicos de Hidrocarbonetos Aromáticos",
            "T53.9 - Efeito Tóxico de Derivados Halogênicos de Hidrocarbonetos Alifáticos e Aromáticos Não Especificados",
            "T54 - Efeito Tóxico de Corrosivos",
            "T54.0 - Efeito Tóxico do Fenol e Homólogos do Fenol",
            "T54.1 - Efeito Tóxico de Outros Compostos Corrosivos Orgânicos",
            "T54.2 - Efeito Tóxico de Ácidos Corrosivos e Substâncias Semelhantes",
            "T54.3 - Efeito Tóxico de Bases (álcalis) Cáusticas(os) e Substâncias Semelhantes",
            "T54.9 - Efeito Tóxico de Substância Corrosiva Não Especificada",
            "T55 - Efeito Tóxico de Sabões e Detergentes",
            "T56 - Efeito Tóxico de Metais",
            "T56.0 - Efeito Tóxico do Chumbo e Seus Compostos",
            "T56.1 - Efeito Tóxico do Mercúrio e Seus Compostos",
            "T56.2 - Efeito Tóxico do Cromo e Seus Compostos",
            "T56.3 - Efeito Tóxico do Cádmio e Seus Compostos",
            "T56.4 - Efeito Tóxico do Cobre e Seus Compostos",
            "T56.5 - Efeito Tóxico do Zinco e Seus Compostos",
            "T56.6 - Efeito Tóxico do Estanho e Seus Compostos",
            "T56.7 - Efeito Tóxico do Berilo e Seus Compostos",
            "T56.8 - Efeito Tóxico de Outros Metais",
            "T56.9 - Efeito Tóxico de Metal, Não Especificado",
            "T57 - Efeito Tóxico de Outras Substâncias Inorgânicas",
            "T57.0 - Efeito Tóxico do Arsênico e Seus Compostos",
            "T57.1 - Efeito Tóxico do Fósforo e Seus Compostos",
            "T57.2 - Efeito Tóxico do Manganês e Seus Compostos",
            "T57.3 - Efeito Tóxico do Cianeto de Hidrogênio (ácido Cianídrico)",
            "T57.8 - Efeito Tóxico de Outras Substâncias Inorgânicas Especificadas",
            "T57.9 - Efeito Tóxico de Substância Inorgânica Não Especificada",
            "T58 - Efeito Tóxico do Monóxido de Carbono",
            "T59 - Efeito Tóxico de Outros Gases, Fumaças e Vapores",
            "T59.0 - Efeito Tóxico de Óxidos de Nitrogênio",
            "T59.1 - Efeito Tóxico do Dióxido de Enxofre",
            "T59.2 - Efeito Tóxico de Formaldeído",
            "T59.3 - Efeito Tóxico do Gás Lacrimogêneo",
            "T59.4 - Efeito Tóxico do Cloro Gasoso",
            "T59.5 - Efeito Tóxico do Fluoreto Gasoso e Ácido Fluorídrico",
            "T59.6 - Efeito Tóxico do Ácido Sulfídrico",
            "T59.7 - Efeito Tóxico do Dióxido de Carbono",
            "T59.8 - Efeito Tóxico de Outros Gases, Fumaças e Vapores Especificados",
            "T59.9 - Efeito Tóxico de Gases, Fumaças e Vapores Não Especificados",
            "T60 - Efeito Tóxico de Pesticidas",
            "T60.0 - Efeito Tóxico de Inseticidas Organofosforados e Carbamatos",
            "T60.1 - Efeito Tóxico de Inseticidas Halogenados",
            "T60.2 - Efeito Tóxico de Outros Inseticidas",
            "T60.3 - Efeito Tóxico de Herbicidas e Fungicidas",
            "T60.4 - Efeito Tóxico de Rodenticidas",
            "T60.8 - Efeito Tóxico de Outros Pesticidas",
            "T60.9 - Efeito Tóxico de Pesticida Não Especificado",
            "T61 - Efeito Tóxico de Substâncias Nocivas Consumidas Como Fruto do Mar",
            "T61.0 - Intoxicação Pelo Peixe Ciguatera",
            "T61.1 - Intoxicação Por Peixes Escombrídeos",
            "T61.2 - Outras Intoxicações Por Peixes e Mariscos",
            "T61.8 - Efeito Tóxico de Outros Frutos do Mar",
            "T61.9 - Efeito Tóxico de Frutos do Mar Não Especificados",
            "T62 - Efeito Tóxico de Outras Substâncias Nocivas Ingeridas Como Alimento",
            "T62.0 - Efeito Tóxico de Ingestão de Cogumelos",
            "T62.1 - Efeito Tóxico de Ingestão de Frutos",
            "T62.2 - Efeito Tóxico de Ingestão de Outras (partes De) Plantas",
            "T62.8 - Efeito Tóxico de Outras Substâncias Nocivas Ingeridas Como Alimento",
            "T62.9 - Efeito Tóxico de Substâncias Nocivas Ingeridas Como Alimento, Não Especificadas",
            "T63 - Efeito Tóxico de Contato Com Animais Venenosos",
            "T63.0 - Efeito Tóxico do Veneno de Serpente",
            "T63.1 - Efeito Tóxico do Veneno de Outros Répteis",
            "T63.2 - Efeito Tóxico do Veneno de Escorpião",
            "T63.3 - Efeito Tóxico do Veneno de Aranha",
            "T63.4 - Efeito Tóxico do Veneno de Outros Artrópodes",
            "T63.5 - Efeito Tóxico de Contato Com Peixe",
            "T63.6 - Efeito Tóxico de Contato Com Outros Animais Marinhos",
            "T63.8 - Efeito Tóxico de Contato Com Outros Animais Venenosos",
            "T63.9 - Efeito Tóxico de Contato Com Animal Venenoso Não Especificado",
            "T64 - Efeito Tóxico da Aflatoxina e de Outras Micotoxinas Contaminantes de Alimentos",
            "T65 - Efeito Tóxico de Outras Substâncias e as Não Especificadas",
            "T65.0 - Efeito Tóxico de Cianetos",
            "T65.1 - Efeito Tóxico da Estricnina e Seus Sais",
            "T65.2 - Efeito Tóxico do Tabaco e da Nicotina",
            "T65.3 - Efeito Tóxico de Nitroderivados e Aminoderivados do Benzeno e Seus Homólogos",
            "T65.4 - Efeito Tóxico do Dissulfeto de Carbono",
            "T65.5 - Efeito Tóxico da Nitroglicerina e Outros Ácidos e Ésteres Nítricos",
            "T65.6 - Efeito Tóxico de Tinturas e Corantes, Não Classificadas em Outra Parte",
            "T65.8 - Efeito Tóxico de Outras Substâncias Especificadas",
            "T65.9 - Efeito Tóxico de Substância Não Especificada",
            "T66 - Efeitos Não Especificados de Radiação",
            "T67 - Efeitos do Calor e da Luz",
            "T67.0 - Golpe de Calor e Insolação",
            "T67.1 - Síncope Devida ao Calor",
            "T67.2 - Cãibras Devidas ao Calor",
            "T67.3 - Exaustão Devida ao Calor e à Perda Hídrica",
            "T67.4 - Exaustão Devida ao Calor e à Perda de Sal",
            "T67.5 - Exaustão Devida ao Calor, Sem Especificação",
            "T67.6 - Fadiga Transitória Devida ao Calor",
            "T67.7 - Edema Devido ao Calor",
            "T67.8 - Outros Efeitos do Calor e da Luz",
            "T67.9 - Efeito do Calor e da Luz Não Especificado",
            "T68 - Hipotermia",
            "T69 - Outros Efeitos da Temperatura Reduzida",
            "T69.0 - Mão e pé de Imersão",
            "T69.1 - Congelamento (chilblains)",
            "T69.8 - Outros Efeitos Especificados de Temperatura Reduzida",
            "T69.9 - Efeito Não Especificado de Temperatura Reduzida",
            "T70 - Efeitos da Pressão Atmosférica e da Pressão da Água",
            "T70.0 - Otite Barotraumática",
            "T70.1 - Sinusite Barotraumática",
            "T70.2 - Outros Efeitos Das Grandes Altitude e os Não Especificados",
            "T70.3 - Mal Dos Caixões (doença de Descompressão)",
            "T70.4 - Efeito Dos Fluidos em Alta Pressão",
            "T70.8 - Outros Efeitos da Pressão Atmosférica ou da Pressão da Água",
            "T70.9 - Efeitos Não Especificados da Pressão Atmosférica ou da Pressão da Água",
            "T71 - Asfixia",
            "T73 - Efeitos de Outras Privações",
            "T73.0 - Efeitos da Fome",
            "T73.1 - Efeitos da Sede",
            "T73.2 - Exaustão Devida às Intempéries",
            "T73.3 - Exaustão Devido a um Esforço Intenso",
            "T73.8 - Outros Efeitos de Privação",
            "T73.9 - Efeito Não Especificado de Privação",
            "T74 - Síndromes de Maus Tratos",
            "T74.0 - Abandono",
            "T74.1 - Sevícias Físicas",
            "T74.2 - Abuso Sexual",
            "T74.3 - Abuso Psicológico",
            "T74.8 - Outras Síndromes Especificadas de Maus Tratos",
            "T74.9 - Síndrome Não Especificada de Maus Tratos",
            "T75 - Efeitos de Outras Causas Externas",
            "T75.0 - Efeitos do Raio",
            "T75.1 - Afogamento e Submersão Não Mortal",
            "T75.2 - Efeitos da Vibração",
            "T75.3 - Doença Causada Pelo Movimento",
            "T75.4 - Efeitos da Corrente Elétrica",
            "T75.8 - Outros Efeitos Especificados de Causas Externas",
            "T78 - Efeitos Adversos Não Classificados em Outra Parte",
            "T78.0 - Choque Anafilático Devido à Intolerância Alimentar",
            "T78.1 - Outras Reações de Intolerância Alimentar Não Classificadas em Outra Parte",
            "T78.2 - Choque Anafilático Não Especificado",
            "T78.3 - Edema Angioneurótico",
            "T78.4 - Alergia Não Especificada",
            "T78.8 - Outros Efeitos Adversos Não Classificados em Outra Parte",
            "T78.9 - Efeito Adverso Não Especificado",
            "T79 - Algumas Complicações Precoces Dos Traumatismos Não Classificadas em Outra Parte",
            "T79.0 - Embolia Gasosa (traumática)",
            "T79.1 - Embolia Gordurosa (traumática)",
            "T79.2 - Hemorragia Traumática Secundária e Recidivante",
            "T79.3 - Infecção Pós-traumática de Ferimento Não Classificada em Outra Parte",
            "T79.4 - Choque Traumático",
            "T79.5 - Anúria Traumática",
            "T79.6 - Isquemia Muscular Traumática",
            "T79.7 - Enfisema Subcutâneo de Origem Traumática",
            "T79.8 - Outras Complicações Precoces de Traumatismo",
            "T79.9 - Complicação Precoce Não Especificada de Traumatismo",
            "T80 - Complicações Conseqüentes à Infusão, Transfusão ou Injeção Terapêutica",
            "T80.0 - Embolia Aérea Subseqüentes à Infusão, Transfusão e Injeção Terapêutica",
            "T80.1 - Complicações Vasculares Subseqüentes à Infusão, Transfusão e Injeção Terapêutica",
            "T80.2 - Infecções Subseqüentes à Infusão, Transfusão e Injeção Terapêutica",
            "T80.3 - Reação de Incompatibilidade ABO",
            "T80.4 - Reação de Incompatibilidade Rh",
            "T80.5 - Choque Anafilático Devido a Soro",
            "T80.6 - Outras Reações ao Soro",
            "T80.8 - Outras Complicações Subseqüentes à Infusão, Transfusão e Injeção Terapêutica",
            "T80.9 - Complicação Não Especificada Subseqüente à Infusão, Transfusão e Injeção Terapêutica",
            "T81 - Complicações de Procedimentos Não Classificadas em Outra Parte",
            "T81.0 - Hemorragia e Hematoma Complicando Procedimento Não Classificado em Outra Parte",
            "T81.1 - Choque Durante ou Resultante de Procedimento Não Classificado em Outra Parte",
            "T81.2 - Perfuração e Laceração Acidentais Durante um Procedimento Não Classificado em Outra Parte",
            "T81.3 - Deiscência de Ferida Cirúrgica Não Classificada em Outra Parte",
            "T81.4 - Infecção Subseqüente a Procedimento Não Classificada em Outra Parte",
            "T81.5 - Corpo Estranho Deixado Acidentalmente em Cavidade Corporal ou em Ferida Operatória Subseqüente a Procedimento",
            "T81.6 - Reação Aguda à Substância Estranha Deixada Acidentalmente Durante um Procedimento",
            "T81.7 - Complicações Vasculares Subseqüentes a um Procedimento Não Classificado em Outra Parte",
            "T81.8 - Outras Complicações de Procedimentos Não Classificadas em Outra Parte",
            "T81.9 - Complicação Não Especificada de Procedimento",
            "T82 - Complicações de Dispositivos Protéticos, Implantes e Enxertos Cardíacos e Vasculares",
            "T82.0 - Complicação Mecânica de Prótese Valvular Cardíaca",
            "T82.1 - Complicação Mecânica de Dispositivo Eletrônico Cardíaco",
            "T82.2 - Complicação Mecânica de Enxerto de Ponte Coronária e Implantes de Valvas",
            "T82.3 - Complicações Mecânicas de Outros Enxertos Vasculares",
            "T82.4 - Complicação Mecânica de Cateter Vascular de Diálise",
            "T82.5 - Complicações Mecânicas de Outros Dispositivos e Implantes Cardiovasculares",
            "T82.6 - Infecção e Reação Inflamatórias Devidas à Prótese Valvular Cardíaca",
            "T82.7 - Infecção e Reação Inflamatórias Devidas a Outros Dispositivos, Implantes e Enxertos Cardíacos e Vasculares",
            "T82.8 - Outras Complicações de Dispositivos Protéticos, Implantes e Enxertos Cardíacos e Vasculares",
            "T82.9 - Complicações Não Especificada de Dispositivo Protético, Implante e Enxerto Cardíacos e Vasculares",
            "T83 - Complicações de Dispositivos Protéticos, Implantes e Enxertos Geniturinários Internos",
            "T83.0 - Complicação Mecânica de Cateter (de Demora) Urinário",
            "T83.1 - Complicação Mecânica de Outros Dispositivos e Implantes Urinários",
            "T83.2 - Complicação Mecânica de Enxerto de Órgão Urinário",
            "T83.3 - Complicação Mecânica de Dispositivo Intra-uterino (anticoncepcional)",
            "T83.4 - Complicação Mecânica de Outros Dispositivos Protéticos, Implantes e Enxertos do Trato Genital",
            "T83.5 - Infecção e Reação Inflamatória Devidas à Dispositivo Protético, Implante e Enxerto no Aparelho Urinário",
            "T83.6 - Infecção e Reação Inflamatória Devidas à Dispositivo Protético, Implante e Enxerto no Trato Genital",
            "T83.8 - Outras Complicações de Dispositivos Protéticos, Implantes e Enxertos Geniturinários",
            "T83.9 - Complicação Não Especificada de Prótese, Implante e Enxerto Geniturinários",
            "T84 - Complicações de Dispositivos Protéticos, Implantes e Enxertos Ortopédicos Internos",
            "T84.0 - Complicação Mecânica de Prótese Articular Interna",
            "T84.1 - Complicação Mecânica de Dispositivo de Fixação Interna de Ossos Dos Membros",
            "T84.2 - Complicação Mecânica de Dispositivo de Fixação Interna de Outros Ossos",
            "T84.3 - Complicação Mecânica de Outros Dispositivos, Implantes e Enxertos Ósseos",
            "T84.4 - Complicação Mecânica de Outros Dispositivos, Implantes e Enxertos Ortopédicos Internos",
            "T84.5 - Infecção e Reação Inflamatória Devidas à Prótese Articular Interna",
            "T84.6 - Infecção e Reação Inflamatória Devidas a Dispositivo de Fixação Interna (qualquer Local)",
            "T84.7 - Infecção e Reação Inflamatória Devidas a Outros Dispositivos Protéticos, Implantes e Enxertos Ortopédicos Internos",
            "T84.8 - Outras Complicações de Dispositivos Protéticos, Implantes e Enxertos Ortopédicos Internos",
            "T84.9 - Complicação Não Especificada de Dispositivo Protético, Implante e Enxerto Ortopédicos Internos",
            "T85 - Complicações de Outros Dispositivos Protéticos, Implantes e Enxertos Internos",
            "T85.0 - Complicação Mecânica de Shunt Ventricular (comunicação) Intracraniano",
            "T85.1 - Complicação Mecânica de Estimulador Eletrônico Implantado no Sistema Nervoso",
            "T85.2 - Complicação Mecânica de Lente Intraocular",
            "T85.3 - Complicações Mecânicas de Outros Dispositivos Protéticos, Implantes e Enxertos Oculares",
            "T85.4 - Complicação Mecânica de Prótese e Implante Mamários",
            "T85.5 - Complicação Mecânica de Dispositivos Protéticos, Implante e Enxerto Gastrointestinais",
            "T85.6 - Complicação Mecânica de Outros Dispositivos Protéticos, Implantes e Enxertos Internos Especificados",
            "T85.7 - Infecção e Reação Inflamatória Devida a Outros Dispositivos Protéticos, Implantes e Enxertos Internos",
            "T85.8 - Outras Complicações de Dispositivos Protéticos, Implantes e Enxertos Internos Não Classificados em Outra Parte",
            "T85.9 - Complicação Não Especificada de Outros Dispositivos Protéticos, Implantes e Enxertos Internos",
            "T86 - Falha e Rejeição de Órgãos e Tecidos Transplantados",
            "T86.0 - Rejeição a Transplante de Medula Óssea",
            "T86.1 - Falência ou Rejeição de Transplante de Rim",
            "T86.2 - Falência ou Rejeição de Transplante de Coração",
            "T86.3 - Falência ou Rejeição de Transplante Duplo de Coração e Pulmão",
            "T86.4 - Falência ou Rejeição de Transplante de Fígado",
            "T86.8 - Outra Insuficiência ou Rejeição de Outros Órgãos ou Tecidos Transplantados",
            "T86.9 - Falência e Rejeição a Transplante de Órgão ou Tecido Não Especificado",
            "T87 - Complicações Próprias de Reimplante e Amputação",
            "T87.0 - Complicações de Reimplante (de Parte) de Extremidade Superior",
            "T87.1 - Complicações de Reimplante (de Parte) de Extremidade Inferior",
            "T87.2 - Complicações de Outras Partes Reimplantadas do Corpo",
            "T87.3 - Neuroma de Coto da Amputação",
            "T87.4 - Infecção de Coto da Amputação",
            "T87.5 - Necrose do Coto da Amputação",
            "T87.6 - Outras Complicações e as Não Especificadas do Coto de Amputação",
            "T88 - Outras Complicações de Cuidados Médicos e Cirúrgicos Não Classificadas em Outra Parte",
            "T88.0 - Infecção Subseqüente à Imunização",
            "T88.1 - Outras Complicações Subseqüentes à Imunização Não Classificadas em Outra Parte",
            "T88.2 - Choque Devido à Anestesia",
            "T88.3 - Hipertermia Maligna Devida à Anestesia",
            "T88.4 - Entubação Falha ou Difícil",
            "T88.5 - Outras Complicações de Anestesia",
            "T88.6 - Choque Anafilático Devido a Efeito Adverso de Droga ou Medicamento Corretos e Administrados de Maneira Apropriada",
            "T88.7 - Efeito Adverso Não Especificado de Droga ou Medicamento",
            "T88.8 - Outras Complicações de Cuidados Médicos e Cirúrgicos Especificados Não Classificados em Outra Parte",
            "T88.9 - Complicação Não Especificada de Cuidados Médicos e Cirúrgicos",
            "T90 - Seqüelas de Traumatismo da Cabeça",
            "T90.0 - Seqüelas de Traumatismo Superficial da Cabeça",
            "T90.1 - Seqüelas de Ferimento da Cabeça",
            "T90.2 - Seqüelas de Fratura de Crânio e de Ossos da Face",
            "T90.3 - Seqüelas de Traumatismo de Nervos Cranianos",
            "T90.4 - Seqüelas de Traumatismo do Olho e da Órbita",
            "T90.5 - Seqüelas de Traumatismo Intracraniano",
            "T90.8 - Seqüelas de Outros Traumatismos Especificados da Cabeça",
            "T90.9 - Seqüelas de Traumatismo Não Especificado da Cabeça",
            "T91 - Seqüelas de Traumatismos do Pescoço e do Tronco",
            "T91.0 - Seqüelas de Traumatismo Superficial e Ferimento do Pescoço e do Tronco",
            "T91.1 - Seqüelas de Fratura de Coluna Vertebral",
            "T91.2 - Seqüelas de Outra Fratura do Tórax e da Pelve",
            "T91.3 - Seqüelas de Traumatismo de Medula Espinhal",
            "T91.4 - Seqüelas de Traumatismo de Órgãos Intratorácicos",
            "T91.5 - Seqüelas de Traumatismo de Órgãos Intra-abdominais e Órgãos Pélvicos",
            "T91.8 - Seqüelas de Outros Traumatismos Especificados do Pescoço e do Tronco",
            "T91.9 - Seqüelas de Traumatismo Não Especificado do Pescoço e do Tronco",
            "T92 - Seqüelas de Traumatismos do Membro Superior",
            "T92.0 - Seqüelas de Ferimento do Membro Superior",
            "T92.1 - Seqüelas de Fratura do Braço",
            "T92.2 - Seqüelas de Fratura ao Nível do Punho e da Mão",
            "T92.3 - Seqüelas de Luxação, Entorse e Distensão do Membro Superior",
            "T92.4 - Seqüelas de Traumatismo de Nervo de Membro Superior",
            "T92.5 - Seqüelas de Traumatismo de Músculo e Tendão do Membro Superior",
            "T92.6 - Seqüelas de Esmagamento e Amputação Traumática do Membro Superior",
            "T92.8 - Seqüelas de Outros Traumatismos Especificados do Membro Superior",
            "T92.9 - Seqüelas de Traumatismo Não Especificado do Membro Superior",
            "T93 - Seqüelas de Traumatismos do Membro Inferior",
            "T93.0 - Seqüelas de Ferimento do Membro Inferior",
            "T93.1 - Seqüelas de Fratura do Fêmur",
            "T93.2 - Seqüelas de Outras Fraturas do Membro Inferior",
            "T93.3 - Seqüelas de Luxação, Entorse e Distensão do Membro Inferior",
            "T93.4 - Seqüelas de Traumatismo de Nervo do Membro Inferior",
            "T93.5 - Seqüelas de Traumatismo de Músculo e Tendão do Membro Inferior",
            "T93.6 - Seqüelas de Esmagamento e Amputação Traumática do Membro Inferior",
            "T93.8 - Seqüelas de Outros Traumatismos Especificados do Membro Inferior",
            "T93.9 - Seqüelas de Traumatismo Não Especificado do Membro Inferior",
            "T94 - Seqüelas de Traumatismos Envolvendo Múltiplas Regiões do Corpo e as Não Especificadas",
            "T94.0 - Seqüelas de Traumatismos Envolvendo Regiões Múltiplas do Corpo",
            "T94.1 - Seqüelas de Traumatismos Não Especificados Por Regiões do Corpo",
            "T95 - Seqüelas de Queimaduras, Corrosões e Geladuras",
            "T95.0 - Seqüelas de Queimadura, Corrosão e Geladura da Cabeça e Pescoço",
            "T95.1 - Seqüelas de Queimadura, Corrosão e Geladura do Tronco",
            "T95.2 - Seqüelas de Queimadura, Corrosão e Geladura do Membro Superior",
            "T95.3 - Seqüelas de Queimadura, Corrosão e Geladura do Membro Inferior",
            "T95.4 - Seqüelas de Queimadura e Corrosão Classificável só de Acordo Com a Extensão da Área Corporal Envolvida",
            "T95.8 - Seqüelas de Outra Queimadura, Corrosões e Geladuras Especificadas",
            "T95.9 - Seqüelas de Queimadura, Corrosão e Geladura de Local Não Especificado",
            "T96 - Seqüelas de Intoxicação Por Drogas, Medicamentos e Substâncias Biológicas",
            "T97 - Seqüelas de Efeitos Tóxicos de Substâncias de Origem Predominantemente Não-medicinal",
            "T98 - Seqüelas de Outros Efeitos de Causas Externas e Dos Não Especificados",
            "T98.0 - Seqüelas Dos Efeitos da Penetração de Corpo Estranho Através de Orifício Natural",
            "T98.1 - Seqüelas em Outros Efeitos de Causas Externas e os Não Especificados",
            "T98.2 - Seqüelas de Algumas Complicações Precoces de Traumatismos",
            "T98.3 - Seqüelas de Complicações Dos Cuidados Médicos e Cirúrgicos Não Classificados em Outra Parte",
            "U04 - Síndrome Respiratória Aguda Grave (severe Acute Respiratory Syndrome SARS)",
            "U04.9 - Síndrome Respiratória Aguda Grave (Severe Acute Respiratory Syndrome) (SARS), Não Especificada",
            "U80 - Agente Resistente à Penicilina e Antibióticos Relacionados",
            "U80.0 - Agente Resistente à Penicilina",
            "U80.1 - Agente Resistente à Meticilina",
            "U80.8 - Agente Resistente a Outro Antibiótico Relacionado à Penicilina",
            "U81 - Agente Resistente à Vancomicina e Antibióticos Relacionados",
            "U81.0 - Agente Resistente à Vancomicina",
            "U81.8 - Agente Resistente a Outros Antibióticos Relacionado à Vancomicina",
            "U88 - Agente Resistente a Múltiplos Antibióticos",
            "U89 - Agente Resistente a Outros Antibióticos e a Antibióticos Não Especificados",
            "U89.8 - Agente Resistente a Outro Antibiótico Especificado Único",
            "U89.9 - Agente Resistente a Antibiótico Não Especificado",
            "U99 - CID 10ª Revisão Não Disponível",
            "V01 - Pedestre Traumatizado em Colisão Com um Veículo a Pedal",
            "V01.0 - Pedestre Traumatizado em Colisão Com um Veículo a Pedal - Acidente Não-de-trânsito",
            "V01.1 - Pedestre Traumatizado em Colisão Com um Veículo a Pedal - Acidente de Trânsito",
            "V01.9 - Pedestre Traumatizado em Colisão Com um Veículo a Pedal - Acidente Não Especificado se de Trânsito ou Não de Trânsito",
            "V02 - Pedestre Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas",
            "V02.0 - Pedestre Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Acidente Não-de-trânsito",
            "V02.1 - Pedestre Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Acidente de Trânsito",
            "V02.9 - Pedestre Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Acidente Não Especificado se de Trânsito ou Não de Trânsito",
            "V03 - Pedestre Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete",
            "V03.0 - Pedestre Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Acidente Não-de-trânsito",
            "V03.1 - Pedestre Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Acidente de Trânsito",
            "V03.9 - Pedestre Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Acidente Não Especificado se de Trânsito ou Não de Trânsito",
            "V04 - Pedestre Traumatizado em Colisão Com um Veículo de Transporte Pesado ou Com um Ônibus",
            "V04.0 - Pedestre Traumatizado em Colisão Com um Veículo de Transporte Pesado ou Com um Ônibus - Acidente Não-de-trânsito",
            "V04.1 - Pedestre Traumatizado em Colisão Com um Veículo de Transporte Pesado ou Com um Ônibus - Acidente de Trânsito",
            "V04.9 - Pedestre Traumatizado em Colisão Com um Veículo de Transporte Pesado ou Com um Ônibus - Acidente Não Especificado se de Trânsito ou Não de Trânsito",
            "V05 - Pedestre Traumatizado em Colisão Com Trem (comboio) ou um Veículo Ferroviário",
            "V05.0 - Pedestre Traumatizado em Colisão Com Trem (comboio) ou um Veículo Ferroviário - Acidente Não-de-trânsito",
            "V05.1 - Pedestre Traumatizado em Colisão Com Trem (comboio) ou um Veículo Ferroviário - Acidente de Trânsito",
            "V05.9 - Pedestre Traumatizado em Colisão Com Trem (comboio) ou um Veículo Ferroviário - Acidente Não Especificado se de Trânsito ou Não de Trânsito",
            "V06 - Pedestre Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V06.0 - Pedestre Traumatizado em Colisão Com Outro Veículo Não-motorizado - Acidente Não-de-trânsito",
            "V06.1 - Pedestre Traumatizado em Colisão Com Outro Veículo Não-motorizado - Acidente de Trânsito",
            "V06.9 - Pedestre Traumatizado em Colisão Com Outro Veículo Não-motorizado - Acidente Não Especificado se de Trânsito ou Não de Trânsito",
            "V09 - Pedestre Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V09.0 - Pedestre Traumatizado em um Acidente Não-de-trânsito, Envolvendo Outros Veículos a Motor e os Não Especificados",
            "V09.1 - Pedestre Traumatizado em um Acidente Não-de-trânsito Não Especificado",
            "V09.2 - Pedestre Traumatizado em um Acidente de Trânsito Envolvendo Outros Veículos e os Não Especificados, a Motor",
            "V09.3 - Pedestre Traumatizado em um Acidente de Trânsito Não Especificado",
            "V09.9 - Pedestre Traumatizado em um Acidente de Transporte Não Especificado",
            "V10 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V10.0 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V10.1 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V10.2 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V10.3 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V10.4 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor Traumatizado em um Acidente de Trânsito",
            "V10.5 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V10.9 - Ciclista Traumatizado em Colisão Com um Pedestre ou um Animal - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V11 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal",
            "V11.0 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V11.1 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V11.2 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V11.3 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V11.4 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Condutor Traumatizado em um Acidente de Trânsito",
            "V11.5 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V11.9 - Ciclista Traumatizado em Colisão Com Outro Veículo a Pedal - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V12 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas",
            "V12.0 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V12.1 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V12.2 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V12.3 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V12.4 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Condutor Traumatizado em um Acidente de Trânsito",
            "V12.5 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V12.9 - Ciclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V13 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete",
            "V13.0 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V13.1 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V13.2 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V13.3 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V13.4 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Condutor Traumatizado em um Acidente de Trânsito",
            "V13.5 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V13.9 - Ciclista Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V14 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus",
            "V14.0 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V14.1 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V14.2 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V14.3 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V14.4 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor Traumatizado em um Acidente de Trânsito",
            "V14.5 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V14.9 - Ciclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V15 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário",
            "V15.0 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V15.1 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V15.2 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V15.3 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V15.4 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Condutor Traumatizado em um Acidente de Trânsito",
            "V15.5 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V15.9 - Ciclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V16 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V16.0 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V16.1 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V16.2 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V16.3 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V16.4 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor Traumatizado em um Acidente de Trânsito",
            "V16.5 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V16.9 - Ciclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V17 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V17.0 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V17.1 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V17.2 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V17.3 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V17.4 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor Traumatizado em um Acidente de Trânsito",
            "V17.5 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V17.9 - Ciclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V18 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão",
            "V18.0 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V18.1 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V18.2 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Ciclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V18.3 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V18.4 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Condutor Traumatizado em um Acidente de Trânsito",
            "V18.5 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V18.9 - Ciclista Traumatizado em um Acidente de Transporte Sem Colisão - Ciclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V19 - Ciclista Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V19.0 - Condutor Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V19.1 - Passageiro Traumatizado em Colisão Com Outro Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V19.2 - Ciclista Não Especificado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V19.3 - Ciclista (qualquer) Traumatizado em um Acidente Não Especificado e Não-de-trânsito",
            "V19.4 - Condutor Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V19.5 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V19.6 - Ciclista Não Especificado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V19.8 - Ciclista (qualquer) Traumatizado em Outros Acidentes de Transporte Especificados",
            "V19.9 - Ciclista (qualquer) Traumatizado em um Acidente de Trânsito Não Especificado",
            "V20 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V20.0 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V20.1 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V20.2 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V20.3 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V20.4 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor Traumatizado em um Acidente de Trânsito",
            "V20.5 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V20.9 - Motociclista Traumatizado em Colisão Com um Pedestre ou um Animal - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V21 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal",
            "V21.0 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V21.1 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V21.2 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V21.3 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V21.4 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Condutor Traumatizado em um Acidente de Trânsito",
            "V21.5 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V21.9 - Motociclista Traumatizado em Colisão Com um Veículo a Pedal - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V22 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas",
            "V22.0 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V22.1 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V22.2 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V22.3 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V22.4 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Condutor Traumatizado em um Acidente de Trânsito",
            "V22.5 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V22.9 - Motociclista Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V23 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete",
            "V23.0 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V23.1 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V23.2 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V23.3 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V23.4 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Condutor Traumatizado em um Acidente de Trânsito",
            "V23.5 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V23.9 - Motociclista Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V24 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus",
            "V24.0 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V24.1 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V24.2 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V24.3 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V24.4 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor Traumatizado em um Acidente de Trânsito",
            "V24.5 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V24.9 - Motociclista Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V25 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário",
            "V25.0 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V25.1 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V25.2 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V25.3 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V25.4 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Condutor Traumatizado em um Acidente de Trânsito",
            "V25.5 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V25.9 - Motociclista Traumatizado em Colisão Com um Trem ou um Veículo Ferroviário - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V26 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V26.0 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V26.1 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V26.2 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V26.3 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V26.4 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor Traumatizado em um Acidente de Trânsito",
            "V26.5 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V26.9 - Motociclista Traumatizado em Colisão Com Outro Veículo Não-motorizado - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V27 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V27.0 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V27.1 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V27.2 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V27.3 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V27.4 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor Traumatizado em um Acidente de Trânsito",
            "V27.5 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V27.9 - Motociclista Traumatizado em Colisão Com um Objeto Fixo ou Parado - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V28 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão",
            "V28.0 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V28.1 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V28.2 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Motociclista Não Especificado Traumatizado em Acidente Não-de-trânsito",
            "V28.3 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V28.4 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Condutor Traumatizado em um Acidente de Trânsito",
            "V28.5 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V28.9 - Motociclista Traumatizado em um Acidente de Transporte Sem Colisão - Motociclista Não Especificado Traumatizado em um Acidente de Trânsito",
            "V29 - Motociclista Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V29.0 - Condutor Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V29.1 - Passageiro Traumatizado em Colisão Com Outros Veículos Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V29.2 - Motociclista Não Especificado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V29.3 - Motociclista (qualquer) Traumatizado em um Acidente Não Especificado, Não-de-trânsito",
            "V29.4 - Condutor Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V29.5 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V29.6 - Motociclista Não Especificado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor em um Acidente de Trânsito",
            "V29.8 - Motociclista (qualquer) Traumatizado em Outros Acidentes de Transporte Especificados",
            "V29.9 - Motociclista (qualquer) Traumatizado em um Acidente de Trânsito Não Especificado",
            "V30 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V30.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V30.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V30.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V30.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V30.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V30.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor Traumatizado em um Acidente de Trânsito",
            "V30.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V30.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V30.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V31 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal",
            "V31.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V31.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V31.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V31.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V31.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V31.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Condutor Traumatizado em um Acidente de Trânsito",
            "V31.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V31.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V31.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V32 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas",
            "V32.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V32.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V32.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V32.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V32.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V32.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Condutor Traumatizado em um Acidente de Trânsito",
            "V32.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V32.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V32.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V33 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete",
            "V33.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V33.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V33.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V33.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V33.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V33.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Condutor Traumatizado em um Acidente de Trânsito",
            "V33.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V33.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V33.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Automóvel, pick Up ou Caminhonete - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V34 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus",
            "V34.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V34.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V34.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V34.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V34.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V34.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor Traumatizado em um Acidente de Trânsito",
            "V34.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V34.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V34.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V35 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário",
            "V35.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V35.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V35.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V35.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V35.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V35.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor Traumatizado em um Acidente de Trânsito",
            "V35.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V35.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V35.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V36 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V36.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V36.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V36.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V36.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V36.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V36.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor Traumatizado em um Acidente de Trânsito",
            "V36.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V36.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V36.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V37 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V37.0 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V37.1 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V37.2 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V37.3 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V37.4 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V37.5 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor Traumatizado em um Acidente de Trânsito",
            "V37.6 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V37.7 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V37.9 - Ocupante de um Triciclo Motorizado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V38 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão",
            "V38.0 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Condutor Traumatizado em Acidente Não-de-trânsito",
            "V38.1 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em Acidente Não-de-trânsito",
            "V38.2 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V38.3 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Acidente Não-de-trânsito",
            "V38.4 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V38.5 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Condutor Traumatizado em um Acidente de Trânsito",
            "V38.6 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V38.7 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V38.9 - Ocupante de um Triciclo Motorizado Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em um Acidente de Trânsito",
            "V39 - Ocupante de um Triciclo Motorizado Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V39.0 - Condutor Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V39.1 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V39.2 - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V39.3 - Ocupante (qualquer) de um Triciclo Motorizado Traumatizado em um Acidente Não Especificado e Não-de-trânsito",
            "V39.4 - Condutor Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V39.5 - Passageiro Traumatizado em Colisão Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V39.6 - Ocupante Não Especificado de um Triciclo Motorizado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V39.8 - Ocupante (qualquer) de um Triciclo Motorizado Traumatizado em Outros Acidentes de Trânsito Especificados",
            "V39.9 - Ocupante (qualquer) de um Triciclo Motorizado, Traumatizado em Acidente de Trânsito Não Especificado",
            "V40 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V40.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V40.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V40.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V40.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V40.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V40.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V40.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V40.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V40.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V41 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal",
            "V41.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V41.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V41.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V41.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V41.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V41.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V41.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V41.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V41.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V42 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas",
            "V42.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V42.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V42.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V42.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V42.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V42.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V42.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V42.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V42.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V43 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete",
            "V43.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V43.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V43.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V43.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V43.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V43.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V43.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V43.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V43.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Automóvel (carro), pick Up ou Caminhonete - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V44 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus",
            "V44.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V44.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V44.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V44.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V44.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V44.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V44.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V44.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V44.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V45 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário",
            "V45.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V45.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V45.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V45.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V45.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V45.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V45.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V45.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V45.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V46 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V46.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V46.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V46.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V46.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V46.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V46.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V46.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V46.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V46.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V47 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V47.0 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V47.1 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V47.2 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V47.3 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V47.4 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V47.5 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V47.6 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V47.7 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V47.9 - Ocupante de um Automóvel (carro) Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V48 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão",
            "V48.0 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V48.1 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V48.2 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V48.3 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente Não-de-trânsito",
            "V48.4 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizada ao Subir ou Descer do Veículo",
            "V48.5 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V48.6 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V48.7 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V48.9 - Ocupante de um Automóvel (carro) Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em um Acidente de Trânsito",
            "V49 - Ocupante de um Automóvel (carro) Traumatizado em Outro Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V49.0 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor em um Acidente Não-de-trânsito",
            "V49.1 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V49.2 - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V49.3 - Ocupante (qualquer) de um Automóvel (carro) Traumatizado em um Acidente Não Especificado e Não-de-trânsito",
            "V49.4 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V49.5 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V49.6 - Ocupante Não Especificado de um Automóvel (carro) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V49.8 - Ocupante (qualquer) de um Automóvel (carro) Traumatizado em Outros Acidentes de Transporte Especificados",
            "V49.9 - Ocupante (qualquer) de um Automóvel (carro), Traumatizado em um Acidente de Trânsito Não Especificado",
            "V50 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V50.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V50.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V50.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V50.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V50.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V50.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V50.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V50.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V50.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V51 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal",
            "V51.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V51.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V51.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V51.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V51.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V51.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V51.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V51.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V51.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V52 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas",
            "V52.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V52.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V52.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V52.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V52.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V52.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V52.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V52.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V52.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V53 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete",
            "V53.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V53.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V53.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V53.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V53.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V53.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V53.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V53.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V53.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V54 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus",
            "V54.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V54.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V54.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V54.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V54.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V54.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V54.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V54.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V54.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V55 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário",
            "V55.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V55.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V55.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V55.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V55.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V55.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V55.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V55.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V55.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Trem (comboio) ou Veículo Ferroviário - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V56 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V56.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V56.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V56.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V56.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V56.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V56.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V56.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V56.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V56.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V57 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V57.0 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V57.1 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V57.2 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V57.3 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V57.4 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V57.5 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V57.6 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V57.7 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V57.9 - Ocupante de Uma Caminhonete Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V58 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão",
            "V58.0 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V58.1 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V58.2 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V58.3 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente Não-de-trânsito",
            "V58.4 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V58.5 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V58.6 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V58.7 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V58.9 - Ocupante de Uma Caminhonete Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de Uma Caminhonete Traumatizado em um Acidente de Trânsito",
            "V59 - Ocupante de Uma Caminhonete Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V59.0 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V59.1 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V59.2 - Ocupante Não Especificado de Uma Caminhonete Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V59.3 - Ocupante (qualquer) de Uma Caminhonete Traumatizado em um Acidente Não Especificado e Não-de-trânsito",
            "V59.4 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V59.5 - Passageiro de Uma Caminhonete Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V59.6 - Ocupante Não Especificado de Uma Caminhonete Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V59.8 - Ocupante (qualquer) de Uma Caminhonete Traumatizado em Outros Acidentes de Transporte Especificados",
            "V59.9 - Ocupante (qualquer) de Uma Caminhonete Traumatizado em um Acidente de Trânsito Não Especificado",
            "V60 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V60.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V60.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V60.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V60.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V60.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V60.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V60.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V60.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V60.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V61 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal",
            "V61.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V61.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V61.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V61.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V61.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V61.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V61.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V61.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V61.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V62 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas",
            "V62.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V62.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V62.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V62.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V62.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V62.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V62.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V62.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V62.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V63 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete",
            "V63.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V63.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V63.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V63.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V63.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V63.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V63.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V63.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V63.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V64 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus",
            "V64.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V64.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V64.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V64.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V64.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V64.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V64.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V64.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V64.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V65 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário",
            "V65.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V65.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V65.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V65.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V65.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V65.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V65.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V65.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V65.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V66 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado",
            "V66.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V66.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V66.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V66.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V66.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V66.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V66.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V66.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V66.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Outro Veículo Não-motorizado - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V67 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V67.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V67.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V67.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V67.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V67.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V67.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V67.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V67.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V67.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V68 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão",
            "V68.0 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V68.1 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V68.2 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V68.3 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente Não-de-trânsito",
            "V68.4 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V68.5 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V68.6 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V68.7 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V68.9 - Ocupante de um Veículo de Transporte Pesado Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito",
            "V69 - Ocupante de um Veículo de Transporte Pesado Traumatizado em Outros Acidentes de Transporte Não Especificados",
            "V69.0 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V69.1 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V69.2 - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V69.3 - Ocupante (qualquer) de um Veículo de Transporte Pesado Traumatizado em um Acidente Não Especificado e Não-de-trânsito",
            "V69.4 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V69.5 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V69.6 - Ocupante Não Especificado de um Veículo de Transporte Pesado Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V69.8 - Ocupante (qualquer) de um Veículo de Transporte Pesado Traumatizado em Outros Acidentes de Transporte Especificados",
            "V69.9 - Ocupante (qualquer) de um Veículo de Transporte Pesado Traumatizado em um Acidente de Trânsito Não Especificado",
            "V70 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V70.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V70.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V70.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V70.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V70.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V70.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V70.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V70.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V70.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Pedestre ou um Animal - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V71 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal",
            "V71.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V71.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V71.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V71.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V71.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V71.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V71.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Passageiro Traumatizado em um Acidente de Trânsito",
            "V71.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V71.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo a Pedal - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V72 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas",
            "V72.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V72.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V72.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V72.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V72.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V72.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V72.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Passageiro Traumatizado em um Acidente de Trânsito",
            "V72.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V72.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Outro Veículo a Motor de Duas ou Três Rodas - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V73 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete",
            "V73.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V73.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V73.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V73.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V73.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V73.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V73.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Passageiro Traumatizado em um Acidente de Trânsito",
            "V73.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V73.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Automóvel (carro) ou Uma Caminhonete - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V74 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus",
            "V74.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V74.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V74.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V74.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V74.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V74.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V74.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Passageiro Traumatizado em um Acidente de Trânsito",
            "V74.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V74.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Veículo de Transporte Pesado ou um Ônibus - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V75 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário",
            "V75.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V75.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V75.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V75.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V75.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V75.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V75.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Passageiro Traumatizado em um Acidente de Trânsito",
            "V75.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V75.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V76 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado",
            "V76.0 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V76.1 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V76.2 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V76.3 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V76.4 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V76.5 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V76.6 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V76.7 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V76.9 - Ocupante de um Ônibus Traumatizado em Colisão Com Outro Veículo Não-motorizado - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V77 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V77.0 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V77.1 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V77.2 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V77.3 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V77.4 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V77.5 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V77.6 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Passageiro Traumatizado em um Acidente de Trânsito",
            "V77.7 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V77.9 - Ocupante de um Ônibus Traumatizado em Colisão Com um Objeto Fixo ou Parado - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V78 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão",
            "V78.0 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente Não-de-trânsito",
            "V78.1 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente Não-de-trânsito",
            "V78.2 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente Não-de-trânsito",
            "V78.3 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V78.4 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Traumatizado ao Subir ou Descer do Veículo",
            "V78.5 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Condutor (motorista) Traumatizado em um Acidente de Trânsito",
            "V78.6 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Passageiro Traumatizado em um Acidente de Trânsito",
            "V78.7 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Pessoa Viajando no Exterior do Veículo Traumatizada em um Acidente de Trânsito",
            "V78.9 - Ocupante de um Ônibus Traumatizado em um Acidente de Transporte Sem Colisão - Ocupante Não Especificado de um Ônibus Traumatizado em um Acidente de Trânsito",
            "V79 - Ocupante de um Ônibus Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V79.0 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V79.1 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V79.2 - Ocupante Não Especificado de um Ônibus Traumatizado em Colisão Com Outros Veículos e Com um Veículos Não Especificados, a Motor, em um Acidente Não-de-trânsito",
            "V79.3 - Ocupante (qualquer) de um Ônibus Traumatizado em um Acidente Não-de-trânsito",
            "V79.4 - Condutor (motorista) Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V79.5 - Passageiro Traumatizado em Colisão Com Outros Veículos e Com Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V79.6 - Ocupante Não Especificado de um Ônibus Traumatizado em Colisão Com um Outros Veículos e Com um Veículos Não Especificados, a Motor, em um Acidente de Trânsito",
            "V79.8 - Ocupante ( Qualquer) de um Ônibus Traumatizado em Outros Acidentes de Transporte Especificados",
            "V79.9 - Ocupante (qualquer) de um Ônibus Traumatizado em um Acidente de Trânsito Não Especificado",
            "V80 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em um Acidente de Transporte",
            "V80.0 - Queda ou Ejeção de Uma Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal em um Acidente Sem Colisão",
            "V80.1 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Pedestre ou um Animal",
            "V80.2 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Veículo a Pedal",
            "V80.3 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Veículo a Motor de Duas ou Três Rodas",
            "V80.4 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Automóvel (carro), Uma Caminhonete, um Veículo de Transporte Pesado ou um Ônibus",
            "V80.5 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Outro Veículo a Motor Especificado",
            "V80.6 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Trem (comboio) ou um Veículo Ferroviário",
            "V80.7 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Outro Veículo Não-motorizado",
            "V80.8 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Colisão Com um Objeto Fixo ou Parado",
            "V80.9 - Pessoa Montada em Animal ou Ocupante de um Veículo a Tração Animal Traumatizado em Outros Acidentes de Transporte e em Acidentes de Transporte Não Especificados",
            "V81 - Ocupante de um Trem (comboio) ou um Veículo Ferroviário Traumatizado em um Acidente de Transporte",
            "V81.0 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em Colisão Com um Veículo a Motor em um Acidente Não-de-trânsito",
            "V81.1 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em Colisão Com um Veículo a Motor em um Acidente de Trânsito",
            "V81.2 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em Colisão Com ou Atingido Por Material Rodante",
            "V81.3 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em Colisão Com Outro Objeto",
            "V81.4 - Pessoa Traumatizada ao Subir ou Descer de um Trem (comboio) ou de um Veículo Ferroviário",
            "V81.5 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado Devido a Uma Queda no Interior Dos Mesmos",
            "V81.6 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado Devido a Uma Queda do Trem ou do Veículo Ferroviário",
            "V81.7 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em Razão de um Descarrilhamento Sem Colisão Antecedente",
            "V81.8 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em Outro Acidente Ferroviário Especificado",
            "V81.9 - Ocupante de um Trem (comboio) ou de um Veículo Ferroviário Traumatizado em um Acidente Ferroviário Não Especificado",
            "V82 - Ocupante de um Bonde (carro Elétrico) Traumatizado em um Acidente de Transporte",
            "V82.0 - Ocupante de um Bonde (carro Elétrico) Traumatizado em Uma Colisão Com um Veículo a Motor em um Acidente Não-de-trânsito",
            "V82.1 - Ocupante de um Bonde (carro Elétrico) Traumatizado em Uma Colisão Com um Veículo a Motor em um Acidente de Trânsito",
            "V82.2 - Ocupante de um Bonde (carro Elétrico) Traumatizado em Uma Colisão Com ou Atingido Por Material Rodante",
            "V82.3 - Ocupante de um Bonde (carro Elétrico) Traumatizado em Uma Colisão Com Outro Objeto",
            "V82.4 - Pessoa Traumatizada ao Subir ou Descer de um Bonde (carro Elétrico)",
            "V82.5 - Ocupante de um Bonde (carro Elétrico) Traumatizado Devido a Uma Queda no Interior do Mesmo",
            "V82.6 - Ocupante de um Bonde (carro Elétrico) Traumatizado Devido a Uma Queda do Mesmo",
            "V82.7 - Ocupante de um Bonde (carro Elétrico) Traumatizado Devido a um Descarrilhamento Sem Colisão Antecedente",
            "V82.8 - Ocupante de um Bonde (carro Elétrico) Traumatizado em Outros Acidentes de Transporte Especificados",
            "V82.9 - Ocupante de um Bonde (carro Elétrico) Traumatizado em um Acidente de Trânsito Não Especificado",
            "V83 - Ocupante de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente de Transporte",
            "V83.0 - Condutor (motorista) de um Veículo Especial a Motor Usado Principalmente em Áreas Industrias Traumatizado em um Acidente de Trânsito",
            "V83.1 - Passageiro de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente de Trânsito",
            "V83.2 - Pessoa Viajando no Exterior de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente de Trânsito",
            "V83.3 - Ocupante Não Especificado de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente de Trânsito",
            "V83.4 - Pessoa Traumatizada ao Subir ou Descer de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais",
            "V83.5 - Condutor (motorista) de um Veículo Especial a Motor Usado Principalmente em Áreas Industrias Traumatizado em um Acidente Não-de-trânsito",
            "V83.6 - Passageiro de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente Não-de-trânsito",
            "V83.7 - Pessoa Viajando no Exterior de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente Não-de-trânsito",
            "V83.9 - Ocupante Não Especificado de um Veículo Especial a Motor Usado Principalmente em Áreas Industriais Traumatizado em um Acidente Não-de-trânsito",
            "V84 - Ocupante de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente de Transporte",
            "V84.0 - Condutor (motorista) de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente de Trânsito",
            "V84.1 - Passageiro de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente de Trânsito",
            "V84.2 - Pessoa Viajando no Exterior de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente de Trânsito",
            "V84.3 - Ocupante Não Especificado de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente de Trânsito",
            "V84.4 - Pessoa Traumatizado ao Subir ou Descer de um Veículo Especial a Motor de Uso Essencialmente Agrícola",
            "V84.5 - Condutor (motorista) de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente Não-de-trânsito",
            "V84.6 - Passageiro de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente Não-de-trânsito",
            "V84.7 - Pessoa Viajando no Exterior de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizado em um Acidente Não-de-trânsito",
            "V84.9 - Ocupante Não Especificado de um Veículo Especial a Motor de Uso Essencialmente Agrícola Traumatizada em um Acidente Não-de-trânsito",
            "V85 - Ocupante de um Veículo a Motor Especial de Construções Traumatizado em um Acidente de Transporte",
            "V85.0 - Condutor (motorista) de um Veículo a Motor Especial de Construções Traumatizado em um Acidente de Trânsito",
            "V85.1 - Passageiro de um Veículo a Motor Especial de Construções Traumatizado em um Acidente de Trânsito",
            "V85.2 - Pessoa Viajando no Exterior de um Veículo a Motor Especial de Construções Traumatizado em um Acidente de Trânsito",
            "V85.3 - Ocupante Não Especificado de um Veículo a Motor Especial de Construções Traumatizado em um Acidente de Trânsito",
            "V85.4 - Pessoa Traumatizada ao Subir ou Descer de um Veículo a Motor Especial de Construções",
            "V85.5 - Condutor (motorista) de um Veículo a Motor Especial de Construções Traumatizado em um Acidente Não-de-trânsito",
            "V85.6 - Passageiro de um Veículo a Motor Especial de Construções Traumatizado em um Acidente Não-de-trânsito",
            "V85.7 - Pessoa Viajando no Exterior de um Veículo a Motor Especial de Construções Traumatizado em um Acidente Não-de-trânsito",
            "V85.9 - Ocupante Não Especificado de um Veículo a Motor Especial de Construções Traumatizado em um Acidente Não-de-trânsito",
            "V86 - Ocupante de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública, Traumatizado em um Acidente de Transporte",
            "V86.0 - Condutor (motorista) de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública, Traumatizado em um Acidente de Trânsito",
            "V86.1 - Passageiro de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública, Traumatizado em um Acidente de Trânsito",
            "V86.2 - Pessoa Viajando no Exterior de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública, Traumatizado em um Acidente de Trânsito",
            "V86.3 - Ocupante Não Especificado de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública Traumatizado em um Acidente de Trânsito",
            "V86.4 - Pessoa Traumatizada ao Subir ou Descer de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública",
            "V86.5 - Condutor (motorista) de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública Traumatizado em um Acidente Não-de-trânsito",
            "V86.6 - Passageiro de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública Traumatizado em um Acidente Não-de-trânsito",
            "V86.7 - Pessoa Viajando no Exterior de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública Traumatizado em um Acidente Não-de-trânsito",
            "V86.9 - Ocupante Não Especificado de um Veículo Especial Para Qualquer Terreno ou de Outro Veículo a Motor Projetado Essencialmente Para Uso Não em Via Pública Traumatizado em um Acidente Não-de-trânsito",
            "V87 - Acidente de Trânsito de Tipo Especificado, Mas Sendo Desconhecido o Modo de Transporte da Vítima",
            "V87.0 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e um Veículo a Motor de Duas ou Três Rodas (acidente de Trânsito)",
            "V87.1 - Pessoa Traumatizada em Uma Colisão Entre Outro Veículo a Motor e um Veículo a Motor de Duas ou Três Rodas (acidente de Trânsito)",
            "V87.2 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e Uma Caminhonete (acidente de Trânsito)",
            "V87.3 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e um Ônibus (acidente de Trânsito)",
            "V87.4 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e um Veículo de Transporte Pesado (acidente de Trânsito)",
            "V87.5 - Pessoa Traumatizada em Uma Colisão Entre um Veículo de Transporte Pesado e um Ônibus (acidente de Trânsito)",
            "V87.6 - Pessoa Traumatizada em Uma Colisão Entre um Trem ou um Veículo Ferroviário e um Automóvel (carro) (acidente de Trânsito)",
            "V87.7 - Pessoa Traumatizada em Uma Colisão Entre Outros Veículos a Motor Especificados (acidente de Trânsito)",
            "V87.8 - Pessoa Traumatizada em Outros Acidentes de Transporte Especificados Com Veículo a Motor, Sem Colisão (acidente de Trânsito)",
            "V87.9 - Pessoa Traumatizada em Outros Acidentes de Transporte Especificados (com Colisão) (sem Colisão) Envolvendo um Veículo Não-motorizado (acidente de Trânsito)",
            "V88 - Acidente Não-de-trânsito de Tipo Especificado, Mas Sendo Desconhecido o Modo de Transporte da Vítima",
            "V88.0 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e um Veículo a Motor de Duas ou Três Rodas, Não-de-trânsito",
            "V88.1 - Pessoa Traumatizada em Uma Colisão Entre Outro Veículo a Motor e um Veículo a Motor de Duas ou Três Rodas, Não-de-trânsito",
            "V88.2 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e Uma Caminhonete, Não-de-trânsito",
            "V88.3 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e um Ônibus, Não-de-trânsito",
            "V88.4 - Pessoa Traumatizada em Uma Colisão Entre um Automóvel (carro) e um Veículo de Transporte Pesado, Não-de-trânsito",
            "V88.5 - Pessoa Traumatizada em Uma Colisão Entre um Veículo de Transporte Pesado e um Ônibus, Não-de-trânsito",
            "V88.6 - Pessoa Traumatizada em Uma Colisão Entre um Trem ou um Veículo Ferroviário e um Automóvel (carro), Não-de-trânsito",
            "V88.7 - Pessoa Traumatizada em Uma Colisão Entre Outros Veículos a Motor Especificados, Não-de-trânsito",
            "V88.8 - Pessoa Traumatizada em Outros Acidentes de Transporte Especificados, Com um Veículo a Motor, Sem Colisão, Não-de-trânsito",
            "V88.9 - Pessoa Traumatizada em Outros Acidente de Transporte Especificados (com Colisão) (sem Colisão), Envolvendo um Veículo Não-motorizado, Não-de-trânsito",
            "V89 - Acidente Com um Veículo a Motor ou Não-motorizado, Tipo(s) de Veículo(s) Não Especificado(s)",
            "V89.0 - Pessoa Traumatizada em um Acidente Com um Veículo a Motor Não Especificado, Não-de-trânsito",
            "V89.1 - Pessoa Traumatizada em um Acidente Com Veículo Não-motorizado, Não Especificado, Não-de-trânsito",
            "V89.2 - Pessoa Traumatizada em um Acidente de Trânsito Com um Veículo a Motor Não Especificado",
            "V89.3 - Pessoa Traumatizado em um Acidente de Trânsito Com um Veículo Não-motorizado, Não Especificado",
            "V89.9 - Pessoa Traumatizada em um Acidente Com um Veículo Não Especificado",
            "V90 - Acidente Com Embarcação Causando Afogamento e Submersão",
            "V90.0 - Acidente Com Embarcação Causando Afogamento e Submersão - Navio Mercante",
            "V90.1 - Acidente Com Embarcação Causando Afogamento e Submersão - Navio de Passageiros",
            "V90.2 - Acidente Com Embarcação Causando Afogamento e Submersão - Barco de Pesca",
            "V90.3 - Acidente Com Embarcação Causando Afogamento e Submersão - Outras Embarcações a Motor",
            "V90.4 - Acidente Com Embarcação Causando Afogamento e Submersão - Barco a Vela",
            "V90.5 - Acidente Com Embarcação Causando Afogamento e Submersão - Caiaque ou Canoa",
            "V90.6 - Acidente Com Embarcação Causando Afogamento e Submersão - Bote Inflável (sem Motor)",
            "V90.7 - Acidente Com Embarcação Causando Afogamento e Submersão - Esqui Aquático",
            "V90.8 - Acidente Com Embarcação Causando Afogamento e Submersão - Outras Embarcações Sem Motor",
            "V90.9 - Acidente Com Embarcação Causando Afogamento e Submersão - Embarcação Não Especificada",
            "V91 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo",
            "V91.0 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Navio Mercante",
            "V91.1 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Navio de Passageiros",
            "V91.2 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Barco de Pesca",
            "V91.3 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Outras Embarcações a Motor",
            "V91.4 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Barco a Vela",
            "V91.5 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Caiaque ou Canoa",
            "V91.6 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Bote Inflável (sem Motor)",
            "V91.7 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Esqui Aquático",
            "V91.8 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Outras Embarcações Sem Motor",
            "V91.9 - Acidente Com Embarcação Causando Outro Tipo de Traumatismo - Embarcação Não Especificada",
            "V92 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação",
            "V92.0 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Navio Mercante",
            "V92.1 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Navio de Passageiros",
            "V92.2 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Barco de Pesca",
            "V92.3 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Outras Embarcações a Motor",
            "V92.4 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Barco a Vela",
            "V92.5 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Caiaque ou Canoa",
            "V92.6 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Bote Inflável (sem Motor)",
            "V92.7 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Esqui Aquático",
            "V92.8 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Outras Embarcações Sem Motor",
            "V92.9 - Afogamento e Submersão Relacionados Com Transporte Por Água Sem Acidente Com a Embarcação - Embarcação Não Especificada",
            "V93 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão",
            "V93.0 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Navio Mercante",
            "V93.1 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Navio de Passageiros",
            "V93.2 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Barco de Pesca",
            "V93.3 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Outras Embarcações a Motor",
            "V93.4 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Barco a Vela",
            "V93.5 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Caiaque ou Canoa",
            "V93.6 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Bote Inflável (sem Motor)",
            "V93.7 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Esqui Aquático",
            "V93.8 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Outras Embarcações Sem Motor",
            "V93.9 - Acidente a Bordo de Uma Embarcação, Sem Acidente da Embarcação e Não Causando Afogamento ou Submersão - Embarcação Não Especificada",
            "V94 - Outros Acidentes de Transporte Por Água e os Não Especificados",
            "V94.0 - Outros Acidentes de Transporte Por Água e os Não Especificados - Navio Mercante",
            "V94.1 - Outros Acidentes de Transporte Por Água e os Não Especificados - Navio de Passageiros",
            "V94.2 - Outros Acidentes de Transporte Por Água e os Não Especificados - Barco de Pesca",
            "V94.3 - Outros Acidentes de Transporte Por Água e os Não Especificados - Outras Embarcações a Motor",
            "V94.4 - Outros Acidentes de Transporte Por Água e os Não Especificados - Barco a Vela",
            "V94.5 - Outros Acidentes de Transporte Por Água e os Não Especificados - Caiaque ou Canoa",
            "V94.6 - Outros Acidentes de Transporte Por Água e os Não Especificados - Bote Inflável (sem Motor)",
            "V94.7 - Outros Acidentes de Transporte Por Água e os Não Especificados - Esqui Aquático",
            "V94.8 - Outros Acidentes de Transporte Por Água e os Não Especificados - Outras Embarcações Sem Motor",
            "V94.9 - Outros Acidentes de Transporte Por Água e os Não Especificados - Embarcação Não Especificada",
            "V95 - Acidente de Aeronave a Motor Causando Traumatismo ao Ocupante",
            "V95.0 - Acidente de Helicóptero Causando Traumatismo a Ocupante",
            "V95.1 - Acidente de Planador Motorizado e Ultraleve Causando Traumatismo a Ocupante",
            "V95.2 - Acidente de Outra Aeronave de Asa Fixa de Uso Particular Causando Traumatismo a Ocupante",
            "V95.3 - Acidente de Aeronave de Asa Fixa de Uso Comercial Causando Traumatismo a Ocupante",
            "V95.4 - Acidente de Nave Espacial Causando Traumatismo a Ocupante",
            "V95.8 - Outros Acidente de Aeronaves Causando Traumatismo a Ocupante",
            "V95.9 - Acidente de Aeronave Não Especificada Causando Traumatismo a Ocupante",
            "V96 - Acidente de Uma Aeronave Sem Motor Causando Traumatismo a Ocupante",
            "V96.0 - Acidente de Balão Causando Traumatismo a Ocupante",
            "V96.1 - Acidente de Asa Delta Causando Traumatismo a Ocupante",
            "V96.2 - Acidente de Planador (não-motorizado) Causando Traumatismo a Ocupante",
            "V96.8 - Outros Acidentes de Aeronaves Não-motorizadas Causando Traumatismo a Ocupante",
            "V96.9 - Acidente Não Especificado de Aeronave Não-motorizada Causando Traumatismo a Ocupante",
            "V97 - Outros Acidentes Especificados de Transporte Aéreo",
            "V97.0 - Ocupante de Uma Aeronave Traumatizado em Outros Acidentes de Transporte Aéreo Especificados",
            "V97.1 - Pessoa Traumatizada ao Embarcar em ou Desembarcar de Uma Aeronave",
            "V97.2 - Paraquedista Traumatizado em Acidente de Transporte Aéreo",
            "V97.3 - Pessoa em Terra Traumatizada em Acidente de Transporte Aéreo",
            "V97.8 - Outros Acidentes de Transporte Aéreo Não Classificados em Outra Parte",
            "V98 - Outros Acidentes de Transporte Especificados",
            "V99 - Acidente de Transporte Não Especificado",
            "W00 - Queda no Mesmo Nível Envolvendo Gelo e Neve",
            "W00.0 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Residência",
            "W00.1 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Habitação Coletiva",
            "W00.2 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W00.3 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Área Para a Prática de Esportes e Atletismo",
            "W00.4 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Rua e Estrada",
            "W00.5 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Áreas de Comércio e de Serviços",
            "W00.6 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Áreas Industriais e em Construção",
            "W00.7 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Fazenda",
            "W00.8 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Outros Locais Especificados",
            "W00.9 - Queda no Mesmo Nível Envolvendo Gelo e Neve - Local Não Especificado",
            "W01 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés)",
            "W01.0 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Residência",
            "W01.1 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Habitação Coletiva",
            "W01.2 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W01.3 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Área Para a Prática de Esportes e Atletismo",
            "W01.4 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Rua e Estrada",
            "W01.5 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Áreas de Comércio e de Serviços",
            "W01.6 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Áreas Industriais e em Construção",
            "W01.7 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Fazenda",
            "W01.8 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Outros Locais Especificados",
            "W01.9 - Queda no Mesmo Nível Por Escorregão, Tropeção ou Passos em Falsos (traspés) - Local Não Especificado",
            "W02 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas",
            "W02.0 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Residência",
            "W02.1 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Habitação Coletiva",
            "W02.2 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W02.3 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Área Para a Prática de Esportes e Atletismo",
            "W02.4 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Rua e Estrada",
            "W02.5 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Áreas de Comércio e de Serviços",
            "W02.6 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Áreas Industriais e em Construção",
            "W02.7 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Fazenda",
            "W02.8 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Outros Locais Especificados",
            "W02.9 - Queda Envolvendo Patins de Rodas ou Para Gelo, Esqui ou Pranchas de Rodas - Local Não Especificado",
            "W03 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa",
            "W03.0 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Residência",
            "W03.1 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Habitação Coletiva",
            "W03.2 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W03.3 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Área Para a Prática de Esportes e Atletismo",
            "W03.4 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Rua e Estrada",
            "W03.5 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Áreas de Comércio e de Serviços",
            "W03.6 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Áreas Industriais e em Construção",
            "W03.7 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Fazenda",
            "W03.8 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Outros Locais Especificados",
            "W03.9 - Outras Quedas no Mesmo Nível Por Colisão Com ou Empurrão Por Outra Pessoa - Local Não Especificado",
            "W04 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s)",
            "W04.0 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Residência",
            "W04.1 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Habitação Coletiva",
            "W04.2 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W04.3 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Área Para a Prática de Esportes e Atletismo",
            "W04.4 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Rua e Estrada",
            "W04.5 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Áreas de Comércio e de Serviços",
            "W04.6 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Áreas Industriais e em Construção",
            "W04.7 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Fazenda",
            "W04.8 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Outros Locais Especificados",
            "W04.9 - Queda, Enquanto Estava Sendo Carregado ou Apoiado Por Outra(s) Pessoa(s) - Local Não Especificado",
            "W05 - Queda Envolvendo Uma Cadeira de Rodas",
            "W05.0 - Queda Envolvendo Uma Cadeira de Rodas - Residência",
            "W05.1 - Queda Envolvendo Uma Cadeira de Rodas - Habitação Coletiva",
            "W05.2 - Queda Envolvendo Uma Cadeira de Rodas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W05.3 - Queda Envolvendo Uma Cadeira de Rodas - Área Para a Prática de Esportes e Atletismo",
            "W05.4 - Queda Envolvendo Uma Cadeira de Rodas - Rua e Estrada",
            "W05.5 - Queda Envolvendo Uma Cadeira de Rodas - Áreas de Comércio e de Serviços",
            "W05.6 - Queda Envolvendo Uma Cadeira de Rodas - Áreas Industriais e em Construção",
            "W05.7 - Queda Envolvendo Uma Cadeira de Rodas - Fazenda",
            "W05.8 - Queda Envolvendo Uma Cadeira de Rodas - Outros Locais Especificados",
            "W05.9 - Queda Envolvendo Uma Cadeira de Rodas - Local Não Especificado",
            "W06 - Queda de um Leito",
            "W06.0 - Queda de um Leito - Residência",
            "W06.1 - Queda de um Leito - Habitação Coletiva",
            "W06.2 - Queda de um Leito - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W06.3 - Queda de um Leito - Área Para a Prática de Esportes e Atletismo",
            "W06.4 - Queda de um Leito - Rua e Estrada",
            "W06.5 - Queda de um Leito - Áreas de Comércio e de Serviços",
            "W06.6 - Queda de um Leito - Áreas Industriais e em Construção",
            "W06.7 - Queda de um Leito - Fazenda",
            "W06.8 - Queda de um Leito - Outros Locais Especificados",
            "W06.9 - Queda de um Leito - Local Não Especificado",
            "W07 - Queda de Uma Cadeira",
            "W07.0 - Queda de Uma Cadeira - Residência",
            "W07.1 - Queda de Uma Cadeira - Habitação Coletiva",
            "W07.2 - Queda de Uma Cadeira - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W07.3 - Queda de Uma Cadeira - Área Para a Prática de Esportes e Atletismo",
            "W07.4 - Queda de Uma Cadeira - Rua e Estrada",
            "W07.5 - Queda de Uma Cadeira - Áreas de Comércio e de Serviços",
            "W07.6 - Queda de Uma Cadeira - Áreas Industriais e em Construção",
            "W07.7 - Queda de Uma Cadeira - Fazenda",
            "W07.8 - Queda de Uma Cadeira - Outros Locais Especificados",
            "W07.9 - Queda de Uma Cadeira - Local Não Especificado",
            "W08 - Queda de Outro Tipo de Mobília",
            "W08.0 - Queda de Outro Tipo de Mobília - Residência",
            "W08.1 - Queda de Outro Tipo de Mobília - Habitação Coletiva",
            "W08.2 - Queda de Outro Tipo de Mobília - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W08.3 - Queda de Outro Tipo de Mobília - Área Para a Prática de Esportes e Atletismo",
            "W08.4 - Queda de Outro Tipo de Mobília - Rua e Estrada",
            "W08.5 - Queda de Outro Tipo de Mobília - Áreas de Comércio e de Serviços",
            "W08.6 - Queda de Outro Tipo de Mobília - Áreas Industriais e em Construção",
            "W08.7 - Queda de Outro Tipo de Mobília - Fazenda",
            "W08.8 - Queda de Outro Tipo de Mobília - Outros Locais Especificados",
            "W08.9 - Queda de Outro Tipo de Mobília - Local Não Especificado",
            "W09 - Queda Envolvendo Equipamento de playground",
            "W09.0 - Queda Envolvendo Equipamento de playground - Residência",
            "W09.1 - Queda Envolvendo Equipamento de playground - Habitação Coletiva",
            "W09.2 - Queda Envolvendo Equipamento de playground - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W09.3 - Queda Envolvendo Equipamento de playground - Área Para a Prática de Esportes e Atletismo",
            "W09.4 - Queda Envolvendo Equipamento de playground - Rua e Estrada",
            "W09.5 - Queda Envolvendo Equipamento de playground - Áreas de Comércio e de Serviços",
            "W09.6 - Queda Envolvendo Equipamento de playground - Áreas Industriais e em Construção",
            "W09.7 - Queda Envolvendo Equipamento de playground - Fazenda",
            "W09.8 - Queda Envolvendo Equipamento de playground - Outros Locais Especificados",
            "W09.9 - Queda Envolvendo Equipamento de playground - Local Não Especificado",
            "W10 - Queda em ou de Escadas ou Degraus",
            "W10.0 - Queda em ou de Escadas ou Degraus - Residência",
            "W10.1 - Queda em ou de Escadas ou Degraus - Habitação Coletiva",
            "W10.2 - Queda em ou de Escadas ou Degraus - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W10.3 - Queda em ou de Escadas ou Degraus - Área Para a Prática de Esportes e Atletismo",
            "W10.4 - Queda em ou de Escadas ou Degraus - Rua e Estrada",
            "W10.5 - Queda em ou de Escadas ou Degraus - Áreas de Comércio e de Serviços",
            "W10.6 - Queda em ou de Escadas ou Degraus - Áreas Industriais e em Construção",
            "W10.7 - Queda em ou de Escadas ou Degraus - Fazenda",
            "W10.8 - Queda em ou de Escadas ou Degraus - Outros Locais Especificados",
            "W10.9 - Queda em ou de Escadas ou Degraus - Local Não Especificado",
            "W11 - Queda em ou de Escadas de Mão",
            "W11.0 - Queda em ou de Escadas de Mão - Residência",
            "W11.1 - Queda em ou de Escadas de Mão - Habitação Coletiva",
            "W11.2 - Queda em ou de Escadas de Mão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W11.3 - Queda em ou de Escadas de Mão - Área Para a Prática de Esportes e Atletismo",
            "W11.4 - Queda em ou de Escadas de Mão - Rua e Estrada",
            "W11.5 - Queda em ou de Escadas de Mão - Áreas de Comércio e de Serviços",
            "W11.6 - Queda em ou de Escadas de Mão - Áreas Industriais e em Construção",
            "W11.7 - Queda em ou de Escadas de Mão - Fazenda",
            "W11.8 - Queda em ou de Escadas de Mão - Outros Locais Especificados",
            "W11.9 - Queda em ou de Escadas de Mão - Local Não Especificado",
            "W12 - Queda em ou de um Andaime",
            "W12.0 - Queda em ou de um Andaime - Residência",
            "W12.1 - Queda em ou de um Andaime - Habitação Coletiva",
            "W12.2 - Queda em ou de um Andaime - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W12.3 - Queda em ou de um Andaime - Área Para a Prática de Esportes e Atletismo",
            "W12.4 - Queda em ou de um Andaime - Rua e Estrada",
            "W12.5 - Queda em ou de um Andaime - Áreas de Comércio e de Serviços",
            "W12.6 - Queda em ou de um Andaime - Áreas Industriais e em Construção",
            "W12.7 - Queda em ou de um Andaime - Fazenda",
            "W12.8 - Queda em ou de um Andaime - Outros Locais Especificados",
            "W12.9 - Queda em ou de um Andaime - Local Não Especificado",
            "W13 - Queda de ou Para Fora de Edifícios ou Outras Estruturas",
            "W13.0 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Residência",
            "W13.1 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Habitação Coletiva",
            "W13.2 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W13.3 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Área Para a Prática de Esportes e Atletismo",
            "W13.4 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Rua e Estrada",
            "W13.5 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Áreas de Comércio e de Serviços",
            "W13.6 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Áreas Industriais e em Construção",
            "W13.7 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Fazenda",
            "W13.8 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Outros Locais Especificados",
            "W13.9 - Queda de ou Para Fora de Edifícios ou Outras Estruturas - Local Não Especificado",
            "W14 - Queda de Árvore",
            "W14.0 - Queda de Árvore - Residência",
            "W14.1 - Queda de Árvore - Habitação Coletiva",
            "W14.2 - Queda de Árvore - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W14.3 - Queda de Árvore - Área Para a Prática de Esportes e Atletismo",
            "W14.4 - Queda de Árvore - Rua e Estrada",
            "W14.5 - Queda de Árvore - Áreas de Comércio e de Serviços",
            "W14.6 - Queda de Árvore - Áreas Industriais e em Construção",
            "W14.7 - Queda de Árvore - Fazenda",
            "W14.8 - Queda de Árvore - Outros Locais Especificados",
            "W14.9 - Queda de Árvore - Local Não Especificado",
            "W15 - Queda de Penhasco",
            "W15.0 - Queda de Penhasco - Residência",
            "W15.1 - Queda de Penhasco - Habitação Coletiva",
            "W15.2 - Queda de Penhasco - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W15.3 - Queda de Penhasco - Área Para a Prática de Esportes e Atletismo",
            "W15.4 - Queda de Penhasco - Rua e Estrada",
            "W15.5 - Queda de Penhasco - Áreas de Comércio e de Serviços",
            "W15.6 - Queda de Penhasco - Áreas Industriais e em Construção",
            "W15.7 - Queda de Penhasco - Fazenda",
            "W15.8 - Queda de Penhasco - Outros Locais Especificados",
            "W15.9 - Queda de Penhasco - Local Não Especificado",
            "W16 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão",
            "W16.0 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Residência",
            "W16.1 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Habitação Coletiva",
            "W16.2 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W16.3 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Área Para a Prática de Esportes e Atletismo",
            "W16.4 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Rua e Estrada",
            "W16.5 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Áreas de Comércio e de Serviços",
            "W16.6 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Áreas Industriais e em Construção",
            "W16.7 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Fazenda",
            "W16.8 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Outros Locais Especificados",
            "W16.9 - Mergulho ou Pulo na Água Causando Outro Traumatismo Que Não Afogamento ou Submersão - Local Não Especificado",
            "W17 - Outras Quedas de um Nível a Outro",
            "W17.0 - Outras Quedas de um Nível a Outro - Residência",
            "W17.1 - Outras Quedas de um Nível a Outro - Habitação Coletiva",
            "W17.2 - Outras Quedas de um Nível a Outro - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W17.3 - Outras Quedas de um Nível a Outro - Área Para a Prática de Esportes e Atletismo",
            "W17.4 - Outras Quedas de um Nível a Outro - Rua e Estrada",
            "W17.5 - Outras Quedas de um Nível a Outro - Áreas de Comércio e de Serviços",
            "W17.6 - Outras Quedas de um Nível a Outro - Áreas Industriais e em Construção",
            "W17.7 - Outras Quedas de um Nível a Outro - Fazenda",
            "W17.8 - Outras Quedas de um Nível a Outro - Outros Locais Especificados",
            "W17.9 - Outras Quedas de um Nível a Outro - Local Não Especificado",
            "W18 - Outras Quedas no Mesmo Nível",
            "W18.0 - Outras Quedas no Mesmo Nível - Residência",
            "W18.1 - Outras Quedas no Mesmo Nível - Habitação Coletiva",
            "W18.2 - Outras Quedas no Mesmo Nível - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W18.3 - Outras Quedas no Mesmo Nível - Área Para a Prática de Esportes e Atletismo",
            "W18.4 - Outras Quedas no Mesmo Nível - Rua e Estrada",
            "W18.5 - Outras Quedas no Mesmo Nível - Áreas de Comércio e de Serviços",
            "W18.6 - Outras Quedas no Mesmo Nível - Áreas Industriais e em Construção",
            "W18.7 - Outras Quedas no Mesmo Nível - Fazenda",
            "W18.8 - Outras Quedas no Mesmo Nível - Outros Locais Especificados",
            "W18.9 - Outras Quedas no Mesmo Nível - Local Não Especificado",
            "W19 - Queda Sem Especificação",
            "W19.0 - Queda Sem Especificação - Residência",
            "W19.1 - Queda Sem Especificação - Habitação Coletiva",
            "W19.2 - Queda Sem Especificação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W19.3 - Queda Sem Especificação - Área Para a Prática de Esportes e Atletismo",
            "W19.4 - Queda Sem Especificação - Rua e Estrada",
            "W19.5 - Queda Sem Especificação - Áreas de Comércio e de Serviços",
            "W19.6 - Queda Sem Especificação - Áreas Industriais e em Construção",
            "W19.7 - Queda Sem Especificação - Fazenda",
            "W19.8 - Queda Sem Especificação - Outros Locais Especificados",
            "W19.9 - Queda Sem Especificação - Local Não Especificado",
            "W20 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda",
            "W20.0 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Residência",
            "W20.1 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Habitação Coletiva",
            "W20.2 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W20.3 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Área Para a Prática de Esportes e Atletismo",
            "W20.4 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Rua e Estrada",
            "W20.5 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Áreas de Comércio e de Serviços",
            "W20.6 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Áreas Industriais e em Construção",
            "W20.7 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Fazenda",
            "W20.8 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Outros Locais Especificados",
            "W20.9 - Impacto Causado Por Objeto Lançado, Projetado ou em Queda - Local Não Especificado",
            "W21 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo",
            "W21.0 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Residência",
            "W21.1 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Habitação Coletiva",
            "W21.2 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W21.3 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Área Para a Prática de Esportes e Atletismo",
            "W21.4 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Rua e Estrada",
            "W21.5 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Áreas de Comércio e de Serviços",
            "W21.6 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Áreas Industriais e em Construção",
            "W21.7 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Fazenda",
            "W21.8 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Outros Locais Especificados",
            "W21.9 - Impacto Acidental Ativo ou Passivo Causado Por Equipamento Esportivo - Local Não Especificado",
            "W22 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos",
            "W22.0 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Residência",
            "W22.1 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Habitação Coletiva",
            "W22.2 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W22.3 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Área Para a Prática de Esportes e Atletismo",
            "W22.4 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Rua e Estrada",
            "W22.5 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Áreas de Comércio e de Serviços",
            "W22.6 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Áreas Industriais e em Construção",
            "W22.7 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Fazenda",
            "W22.8 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Outros Locais Especificados",
            "W22.9 - Impacto Acidental Ativo ou Passivo Causado Por Outros Objetos - Local Não Especificado",
            "W23 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos",
            "W23.0 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Residência",
            "W23.1 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Habitação Coletiva",
            "W23.2 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W23.3 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Área Para a Prática de Esportes e Atletismo",
            "W23.4 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Rua e Estrada",
            "W23.5 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Áreas de Comércio e de Serviços",
            "W23.6 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Áreas Industriais e em Construção",
            "W23.7 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Fazenda",
            "W23.8 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Outros Locais Especificados",
            "W23.9 - Apertado, Colhido, Comprimido ou Esmagado Dentro de ou Entre Objetos - Local Não Especificado",
            "W24 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte",
            "W24.0 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Residência",
            "W24.1 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Habitação Coletiva",
            "W24.2 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W24.3 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Área Para a Prática de Esportes e Atletismo",
            "W24.4 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Rua e Estrada",
            "W24.5 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Áreas de Comércio e de Serviços",
            "W24.6 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Áreas Industriais e em Construção",
            "W24.7 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Fazenda",
            "W24.8 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Outros Locais Especificados",
            "W24.9 - Contato Com Elevadores e Instrumentos de Transmissão, Não Classificados em Outra Parte - Local Não Especificado",
            "W25 - Contato Com Vidro Cortante",
            "W25.0 - Contato Com Vidro Cortante - Residência",
            "W25.1 - Contato Com Vidro Cortante - Habitação Coletiva",
            "W25.2 - Contato Com Vidro Cortante - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W25.3 - Contato Com Vidro Cortante - Área Para a Prática de Esportes e Atletismo",
            "W25.4 - Contato Com Vidro Cortante - Rua e Estrada",
            "W25.5 - Contato Com Vidro Cortante - Áreas de Comércio e de Serviços",
            "W25.6 - Contato Com Vidro Cortante - Áreas Industriais e em Construção",
            "W25.7 - Contato Com Vidro Cortante - Fazenda",
            "W25.8 - Contato Com Vidro Cortante - Outros Locais Especificados",
            "W25.9 - Contato Com Vidro Cortante - Local Não Especificado",
            "W26 - Contato Com Faca, Espada e Punhal",
            "W26.0 - Contato Com Faca, Espada e Punhal - Residência",
            "W26.1 - Contato Com Faca, Espada e Punhal - Habitação Coletiva",
            "W26.2 - Contato Com Faca, Espada e Punhal - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W26.3 - Contato Com Faca, Espada e Punhal - Área Para a Prática de Esportes e Atletismo",
            "W26.4 - Contato Com Faca, Espada e Punhal - Rua e Estrada",
            "W26.5 - Contato Com Faca, Espada e Punhal - Áreas de Comércio e de Serviços",
            "W26.6 - Contato Com Faca, Espada e Punhal - Áreas Industriais e em Construção",
            "W26.7 - Contato Com Faca, Espada e Punhal - Fazenda",
            "W26.8 - Contato Com Faca, Espada e Punhal - Outros Locais Especificados",
            "W26.9 - Contato Com Faca, Espada e Punhal - Local Não Especificado",
            "W27 - Contato Com Ferramentas Manuais Sem Motor",
            "W27.0 - Contato Com Ferramentas Manuais Sem Motor - Residência",
            "W27.1 - Contato Com Ferramentas Manuais Sem Motor - Habitação Coletiva",
            "W27.2 - Contato Com Ferramentas Manuais Sem Motor - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W27.3 - Contato Com Ferramentas Manuais Sem Motor - Área Para a Prática de Esportes e Atletismo",
            "W27.4 - Contato Com Ferramentas Manuais Sem Motor - Rua e Estrada",
            "W27.5 - Contato Com Ferramentas Manuais Sem Motor - Áreas de Comércio e de Serviços",
            "W27.6 - Contato Com Ferramentas Manuais Sem Motor - Áreas Industriais e em Construção",
            "W27.7 - Contato Com Ferramentas Manuais Sem Motor - Fazenda",
            "W27.8 - Contato Com Ferramentas Manuais Sem Motor - Outros Locais Especificados",
            "W27.9 - Contato Com Ferramentas Manuais Sem Motor - Local Não Especificado",
            "W28 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama",
            "W28.0 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Residência",
            "W28.1 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Habitação Coletiva",
            "W28.2 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W28.3 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Área Para a Prática de Esportes e Atletismo",
            "W28.4 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Rua e Estrada",
            "W28.5 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Áreas de Comércio e de Serviços",
            "W28.6 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Áreas Industriais e em Construção",
            "W28.7 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Fazenda",
            "W28.8 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Outros Locais Especificados",
            "W28.9 - Contato Com Segadeira Motorizada Para Cortar ou Aparar a Grama - Local Não Especificado",
            "W29 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor",
            "W29.0 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Residência",
            "W29.1 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Habitação Coletiva",
            "W29.2 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W29.3 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Área Para a Prática de Esportes e Atletismo",
            "W29.4 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Rua e Estrada",
            "W29.5 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Áreas de Comércio e de Serviços",
            "W29.6 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Áreas Industriais e em Construção",
            "W29.7 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Fazenda",
            "W29.8 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Outros Locais Especificados",
            "W29.9 - Contato Com Outros Utensílios Manuais e Aparelhos Domésticos Equipados Com Motor - Local Não Especificado",
            "W30 - Contato Com Maquinaria Agrícola",
            "W30.0 - Contato Com Maquinaria Agrícola - Residência",
            "W30.1 - Contato Com Maquinaria Agrícola - Habitação Coletiva",
            "W30.2 - Contato Com Maquinaria Agrícola - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W30.3 - Contato Com Maquinaria Agrícola - Área Para a Prática de Esportes e Atletismo",
            "W30.4 - Contato Com Maquinaria Agrícola - Rua e Estrada",
            "W30.5 - Contato Com Maquinaria Agrícola - Áreas de Comércio e de Serviços",
            "W30.6 - Contato Com Maquinaria Agrícola - Áreas Industriais e em Construção",
            "W30.7 - Contato Com Maquinaria Agrícola - Fazenda",
            "W30.8 - Contato Com Maquinaria Agrícola - Outros Locais Especificados",
            "W30.9 - Contato Com Maquinaria Agrícola - Local Não Especificado",
            "W31 - Contato Com Outras Máquinas e Com as Não Especificadas",
            "W31.0 - Contato Com Outras Máquinas e Com as Não Especificadas - Residência",
            "W31.1 - Contato Com Outras Máquinas e Com as Não Especificadas - Habitação Coletiva",
            "W31.2 - Contato Com Outras Máquinas e Com as Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W31.3 - Contato Com Outras Máquinas e Com as Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "W31.4 - Contato Com Outras Máquinas e Com as Não Especificadas - Rua e Estrada",
            "W31.5 - Contato Com Outras Máquinas e Com as Não Especificadas - Áreas de Comércio e de Serviços",
            "W31.6 - Contato Com Outras Máquinas e Com as Não Especificadas - Áreas Industriais e em Construção",
            "W31.7 - Contato Com Outras Máquinas e Com as Não Especificadas - Fazenda",
            "W31.8 - Contato Com Outras Máquinas e Com as Não Especificadas - Outros Locais Especificados",
            "W31.9 - Contato Com Outras Máquinas e Com as Não Especificadas - Local Não Especificado",
            "W32 - Projétil de Revólver",
            "W32.0 - Projétil de Revólver - Residência",
            "W32.1 - Projétil de Revólver - Habitação Coletiva",
            "W32.2 - Projétil de Revólver - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W32.3 - Projétil de Revólver - Área Para a Prática de Esportes e Atletismo",
            "W32.4 - Projétil de Revólver - Rua e Estrada",
            "W32.5 - Projétil de Revólver - Áreas de Comércio e de Serviços",
            "W32.6 - Projétil de Revólver - Áreas Industriais e em Construção",
            "W32.7 - Projétil de Revólver - Fazenda",
            "W32.8 - Projétil de Revólver - Outros Locais Especificados",
            "W32.9 - Projétil de Revólver - Local Não Especificado",
            "W33 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho",
            "W33.0 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Residência",
            "W33.1 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Habitação Coletiva",
            "W33.2 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W33.3 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Área Para a Prática de Esportes e Atletismo",
            "W33.4 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Rua e Estrada",
            "W33.5 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Áreas de Comércio e de Serviços",
            "W33.6 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Áreas Industriais e em Construção",
            "W33.7 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Fazenda",
            "W33.8 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Outros Locais Especificados",
            "W33.9 - Rifle, Espingarda e Armas de Fogo de Maior Tamanho - Local Não Especificado",
            "W34 - Projéteis de Outras Armas de Fogo e Das Não Especificadas",
            "W34.0 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Residência",
            "W34.1 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Habitação Coletiva",
            "W34.2 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W34.3 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "W34.4 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Rua e Estrada",
            "W34.5 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Áreas de Comércio e de Serviços",
            "W34.6 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Áreas Industriais e em Construção",
            "W34.7 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Fazenda",
            "W34.8 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Outros Locais Especificados",
            "W34.9 - Projéteis de Outras Armas de Fogo e Das Não Especificadas - Local Não Especificado",
            "W35 - Explosão ou Ruptura de Caldeira",
            "W35.0 - Explosão ou Ruptura de Caldeira - Residência",
            "W35.1 - Explosão ou Ruptura de Caldeira - Habitação Coletiva",
            "W35.2 - Explosão ou Ruptura de Caldeira - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W35.3 - Explosão ou Ruptura de Caldeira - Área Para a Prática de Esportes e Atletismo",
            "W35.4 - Explosão ou Ruptura de Caldeira - Rua e Estrada",
            "W35.5 - Explosão ou Ruptura de Caldeira - Áreas de Comércio e de Serviços",
            "W35.6 - Explosão ou Ruptura de Caldeira - Áreas Industriais e em Construção",
            "W35.7 - Explosão ou Ruptura de Caldeira - Fazenda",
            "W35.8 - Explosão ou Ruptura de Caldeira - Outros Locais Especificados",
            "W35.9 - Explosão ou Ruptura de Caldeira - Local Não Especificado",
            "W36 - Explosão ou Ruptura de Cilindro de Gás",
            "W36.0 - Explosão ou Ruptura de Cilindro de Gás - Residência",
            "W36.1 - Explosão ou Ruptura de Cilindro de Gás - Habitação Coletiva",
            "W36.2 - Explosão ou Ruptura de Cilindro de Gás - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W36.3 - Explosão ou Ruptura de Cilindro de Gás - Área Para a Prática de Esportes e Atletismo",
            "W36.4 - Explosão ou Ruptura de Cilindro de Gás - Rua e Estrada",
            "W36.5 - Explosão ou Ruptura de Cilindro de Gás - Áreas de Comércio e de Serviços",
            "W36.6 - Explosão ou Ruptura de Cilindro de Gás - Áreas Industriais e em Construção",
            "W36.7 - Explosão ou Ruptura de Cilindro de Gás - Fazenda",
            "W36.8 - Explosão ou Ruptura de Cilindro de Gás - Outros Locais Especificados",
            "W36.9 - Explosão ou Ruptura de Cilindro de Gás - Local Não Especificado",
            "W37 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados",
            "W37.0 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Residência",
            "W37.1 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Habitação Coletiva",
            "W37.2 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W37.3 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Área Para a Prática de Esportes e Atletismo",
            "W37.4 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Rua e Estrada",
            "W37.5 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Áreas de Comércio e de Serviços",
            "W37.6 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Áreas Industriais e em Construção",
            "W37.7 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Fazenda",
            "W37.8 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Outros Locais Especificados",
            "W37.9 - Explosão ou Ruptura de Pneumático, Tubulação ou Mangueira, Pressurizados - Local Não Especificado",
            "W38 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados",
            "W38.0 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Residência",
            "W38.1 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Habitação Coletiva",
            "W38.2 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W38.3 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Área Para a Prática de Esportes e Atletismo",
            "W38.4 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Rua e Estrada",
            "W38.5 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Áreas de Comércio e de Serviços",
            "W38.6 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Áreas Industriais e em Construção",
            "W38.7 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Fazenda",
            "W38.8 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Outros Locais Especificados",
            "W38.9 - Explosão ou Ruptura de Outros Aparelhos Pressurizados Especificados - Local Não Especificado",
            "W39 - Queima de Fogos de Artifício",
            "W39.0 - Queima de Fogos de Artifício - Residência",
            "W39.1 - Queima de Fogos de Artifício - Habitação Coletiva",
            "W39.2 - Queima de Fogos de Artifício - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W39.3 - Queima de Fogos de Artifício - Área Para a Prática de Esportes e Atletismo",
            "W39.4 - Queima de Fogos de Artifício - Rua e Estrada",
            "W39.5 - Queima de Fogos de Artifício - Áreas de Comércio e de Serviços",
            "W39.6 - Queima de Fogos de Artifício - Áreas Industriais e em Construção",
            "W39.7 - Queima de Fogos de Artifício - Fazenda",
            "W39.8 - Queima de Fogos de Artifício - Outros Locais Especificados",
            "W39.9 - Queima de Fogos de Artifício - Local Não Especificado",
            "W40 - Explosão de Outros Materiais",
            "W40.0 - Explosão de Outros Materiais - Residência",
            "W40.1 - Explosão de Outros Materiais - Habitação Coletiva",
            "W40.2 - Explosão de Outros Materiais - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W40.3 - Explosão de Outros Materiais - Área Para a Prática de Esportes e Atletismo",
            "W40.4 - Explosão de Outros Materiais - Rua e Estrada",
            "W40.5 - Explosão de Outros Materiais - Áreas de Comércio e de Serviços",
            "W40.6 - Explosão de Outros Materiais - Áreas Industriais e em Construção",
            "W40.7 - Explosão de Outros Materiais - Fazenda",
            "W40.8 - Explosão de Outros Materiais - Outros Locais Especificados",
            "W40.9 - Explosão de Outros Materiais - Local Não Especificado",
            "W41 - Exposição a um Jato de Alta Pressão",
            "W41.0 - Exposição a um Jato de Alta Pressão - Residência",
            "W41.1 - Exposição a um Jato de Alta Pressão - Habitação Coletiva",
            "W41.2 - Exposição a um Jato de Alta Pressão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W41.3 - Exposição a um Jato de Alta Pressão - Área Para a Prática de Esportes e Atletismo",
            "W41.4 - Exposição a um Jato de Alta Pressão - Rua e Estrada",
            "W41.5 - Exposição a um Jato de Alta Pressão - Áreas de Comércio e de Serviços",
            "W41.6 - Exposição a um Jato de Alta Pressão - Áreas Industriais e em Construção",
            "W41.7 - Exposição a um Jato de Alta Pressão - Fazenda",
            "W41.8 - Exposição a um Jato de Alta Pressão - Outros Locais Especificados",
            "W41.9 - Exposição a um Jato de Alta Pressão - Local Não Especificado",
            "W42 - Exposição ao Ruído",
            "W42.0 - Exposição ao Ruído - Residência",
            "W42.1 - Exposição ao Ruído - Habitação Coletiva",
            "W42.2 - Exposição ao Ruído - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W42.3 - Exposição ao Ruído - Área Para a Prática de Esportes e Atletismo",
            "W42.4 - Exposição ao Ruído - Rua e Estrada",
            "W42.5 - Exposição ao Ruído - Áreas de Comércio e de Serviços",
            "W42.6 - Exposição ao Ruído - Áreas Industriais e em Construção",
            "W42.7 - Exposição ao Ruído - Fazenda",
            "W42.8 - Exposição ao Ruído - Outros Locais Especificados",
            "W42.9 - Exposição ao Ruído - Local Não Especificado",
            "W43 - Exposição à Vibração",
            "W43.0 - Exposição à Vibração - Residência",
            "W43.1 - Exposição à Vibração - Habitação Coletiva",
            "W43.2 - Exposição à Vibração - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W43.3 - Exposição à Vibração - Área Para a Prática de Esportes e Atletismo",
            "W43.4 - Exposição à Vibração - Rua e Estrada",
            "W43.5 - Exposição à Vibração - Áreas de Comércio e de Serviços",
            "W43.6 - Exposição à Vibração - Áreas Industriais e em Construção",
            "W43.7 - Exposição à Vibração - Fazenda",
            "W43.8 - Exposição à Vibração - Outros Locais Especificados",
            "W43.9 - Exposição à Vibração - Local Não Especificado",
            "W44 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural",
            "W44.0 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Residência",
            "W44.1 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Habitação Coletiva",
            "W44.2 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W44.3 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Área Para a Prática de Esportes e Atletismo",
            "W44.4 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Rua e Estrada",
            "W44.5 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Áreas de Comércio e de Serviços",
            "W44.6 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Áreas Industriais e em Construção",
            "W44.7 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Fazenda",
            "W44.8 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Outros Locais Especificados",
            "W44.9 - Penetração de Corpo Estranho no ou Através de Olho ou Orifício Natural - Local Não Especificado",
            "W45 - Penetração de Corpo ou Objeto Estranho Através da Pele",
            "W45.0 - Penetração de Corpo ou Objeto Estranho Através da Pele - Residência",
            "W45.1 - Penetração de Corpo ou Objeto Estranho Através da Pele - Habitação Coletiva",
            "W45.2 - Penetração de Corpo ou Objeto Estranho Através da Pele - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W45.3 - Penetração de Corpo ou Objeto Estranho Através da Pele - Área Para a Prática de Esportes e Atletismo",
            "W45.4 - Penetração de Corpo ou Objeto Estranho Através da Pele - Rua e Estrada",
            "W45.5 - Penetração de Corpo ou Objeto Estranho Através da Pele - Áreas de Comércio e de Serviços",
            "W45.6 - Penetração de Corpo ou Objeto Estranho Através da Pele - Áreas Industriais e em Construção",
            "W45.7 - Penetração de Corpo ou Objeto Estranho Através da Pele - Fazenda",
            "W45.8 - Penetração de Corpo ou Objeto Estranho Através da Pele - Outros Locais Especificados",
            "W45.9 - Penetração de Corpo ou Objeto Estranho Através da Pele - Local Não Especificado",
            "W46 - Contato Com Agulha Hipodérmica",
            "W46.0 - Contato Com Agulha Hipodérmica - Residência",
            "W46.1 - Contato Com Agulha Hipodérmica - Habitação Coletiva",
            "W46.2 - Contato Com Agulha Hipodérmica - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W46.3 - Contato Com Agulha Hipodérmica - Área Para a Prática de Esportes e Atletismo",
            "W46.4 - Contato Com Agulha Hipodérmica - Rua e Estrada",
            "W46.5 - Contato Com Agulha Hipodérmica - Áreas de Comércio e de Serviços",
            "W46.6 - Contato Com Agulha Hipodérmica - Áreas Industriais e em Construção",
            "W46.7 - Contato Com Agulha Hipodérmica - Fazenda",
            "W46.8 - Contato Com Agulha Hipodérmica - Outros Locais Especificados",
            "W46.9 - Contato Com Agulha Hipodérmica - Local Não Especificado",
            "W49 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas",
            "W49.0 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Residência",
            "W49.1 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Habitação Coletiva",
            "W49.2 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W49.3 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "W49.4 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Rua e Estrada",
            "W49.5 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Áreas de Comércio e de Serviços",
            "W49.6 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Áreas Industriais e em Construção",
            "W49.7 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Fazenda",
            "W49.8 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Outros Locais Especificados",
            "W49.9 - Exposição a Outras Forças Mecânicas Inanimadas e às Não Especificadas - Local Não Especificado",
            "W50 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa",
            "W50.0 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Residência",
            "W50.1 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Habitação Coletiva",
            "W50.2 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W50.3 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Área Para a Prática de Esportes e Atletismo",
            "W50.4 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Rua e Estrada",
            "W50.5 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Áreas de Comércio e de Serviços",
            "W50.6 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Áreas Industriais e em Construção",
            "W50.7 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Fazenda",
            "W50.8 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Outros Locais Especificados",
            "W50.9 - Golpe, Pancada, Pontapé, Mordedura ou Escoriação Infligidos Por Outra Pessoa - Local Não Especificado",
            "W51 - Colisão Entre Duas Pessoas",
            "W51.0 - Colisão Entre Duas Pessoas - Residência",
            "W51.1 - Colisão Entre Duas Pessoas - Habitação Coletiva",
            "W51.2 - Colisão Entre Duas Pessoas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W51.3 - Colisão Entre Duas Pessoas - Área Para a Prática de Esportes e Atletismo",
            "W51.4 - Colisão Entre Duas Pessoas - Rua e Estrada",
            "W51.5 - Colisão Entre Duas Pessoas - Áreas de Comércio e de Serviços",
            "W51.6 - Colisão Entre Duas Pessoas - Áreas Industriais e em Construção",
            "W51.7 - Colisão Entre Duas Pessoas - Fazenda",
            "W51.8 - Colisão Entre Duas Pessoas - Outros Locais Especificados",
            "W51.9 - Colisão Entre Duas Pessoas - Local Não Especificado",
            "W52 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas",
            "W52.0 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Residência",
            "W52.1 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Habitação Coletiva",
            "W52.2 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W52.3 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Área Para a Prática de Esportes e Atletismo",
            "W52.4 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Rua e Estrada",
            "W52.5 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Áreas de Comércio e de Serviços",
            "W52.6 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Áreas Industriais e em Construção",
            "W52.7 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Fazenda",
            "W52.8 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Outros Locais Especificados",
            "W52.9 - Esmagado, Empurrado ou Pisoteado Por Multidão ou Debandada em Massa de Pessoas - Local Não Especificado",
            "W53 - Mordedura de Rato",
            "W53.0 - Mordedura de Rato - Residência",
            "W53.1 - Mordedura de Rato - Habitação Coletiva",
            "W53.2 - Mordedura de Rato - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W53.3 - Mordedura de Rato - Área Para a Prática de Esportes e Atletismo",
            "W53.4 - Mordedura de Rato - Rua e Estrada",
            "W53.5 - Mordedura de Rato - Áreas de Comércio e de Serviços",
            "W53.6 - Mordedura de Rato - Áreas Industriais e em Construção",
            "W53.7 - Mordedura de Rato - Fazenda",
            "W53.8 - Mordedura de Rato - Outros Locais Especificados",
            "W53.9 - Mordedura de Rato - Local Não Especificado",
            "W54 - Mordedura ou Golpe Provocado Por Cão",
            "W54.0 - Mordedura ou Golpe Provocado Por Cão - Residência",
            "W54.1 - Mordedura ou Golpe Provocado Por Cão - Habitação Coletiva",
            "W54.2 - Mordedura ou Golpe Provocado Por Cão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W54.3 - Mordedura ou Golpe Provocado Por Cão - Área Para a Prática de Esportes e Atletismo",
            "W54.4 - Mordedura ou Golpe Provocado Por Cão - Rua e Estrada",
            "W54.5 - Mordedura ou Golpe Provocado Por Cão - Áreas de Comércio e de Serviços",
            "W54.6 - Mordedura ou Golpe Provocado Por Cão - Áreas Industriais e em Construção",
            "W54.7 - Mordedura ou Golpe Provocado Por Cão - Fazenda",
            "W54.8 - Mordedura ou Golpe Provocado Por Cão - Outros Locais Especificados",
            "W54.9 - Mordedura ou Golpe Provocado Por Cão - Local Não Especificado",
            "W55 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos",
            "W55.0 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Residência",
            "W55.1 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Habitação Coletiva",
            "W55.2 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W55.3 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Área Para a Prática de Esportes e Atletismo",
            "W55.4 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Rua e Estrada",
            "W55.5 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Áreas de Comércio e de Serviços",
            "W55.6 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Áreas Industriais e em Construção",
            "W55.7 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Fazenda",
            "W55.8 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Outros Locais Especificados",
            "W55.9 - Mordedura ou Golpe Provocado Por Outros Animais Mamíferos - Local Não Especificado",
            "W56 - Contato Com Animais Marinhos",
            "W56.0 - Contato Com Animais Marinhos - Residência",
            "W56.1 - Contato Com Animais Marinhos - Habitação Coletiva",
            "W56.2 - Contato Com Animais Marinhos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W56.3 - Contato Com Animais Marinhos - Área Para a Prática de Esportes e Atletismo",
            "W56.4 - Contato Com Animais Marinhos - Rua e Estrada",
            "W56.5 - Contato Com Animais Marinhos - Áreas de Comércio e de Serviços",
            "W56.6 - Contato Com Animais Marinhos - Áreas Industriais e em Construção",
            "W56.7 - Contato Com Animais Marinhos - Fazenda",
            "W56.8 - Contato Com Animais Marinhos - Outros Locais Especificados",
            "W56.9 - Contato Com Animais Marinhos - Local Não Especificado",
            "W57 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos",
            "W57.0 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Residência",
            "W57.1 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Habitação Coletiva",
            "W57.2 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W57.3 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Área Para a Prática de Esportes e Atletismo",
            "W57.4 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Rua e Estrada",
            "W57.5 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Áreas de Comércio e de Serviços",
            "W57.6 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Áreas Industriais e em Construção",
            "W57.7 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Fazenda",
            "W57.8 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Outros Locais Especificados",
            "W57.9 - Mordeduras e Picadas de Inseto e de Outros Artrópodes, Não-venenosos - Local Não Especificado",
            "W58 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor",
            "W58.0 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Residência",
            "W58.1 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Habitação Coletiva",
            "W58.2 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W58.3 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Área Para a Prática de Esportes e Atletismo",
            "W58.4 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Rua e Estrada",
            "W58.5 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Áreas de Comércio e de Serviços",
            "W58.6 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Áreas Industriais e em Construção",
            "W58.7 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Fazenda",
            "W58.8 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Outros Locais Especificados",
            "W58.9 - Mordedura ou Golpe Provocado Por Crocodilo ou Aligátor - Local Não Especificado",
            "W59 - Mordedura ou Esmagamento Provocado Por Outros Répteis",
            "W59.0 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Residência",
            "W59.1 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Habitação Coletiva",
            "W59.2 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W59.3 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Área Para a Prática de Esportes e Atletismo",
            "W59.4 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Rua e Estrada",
            "W59.5 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Áreas de Comércio e de Serviços",
            "W59.6 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Áreas Industriais e em Construção",
            "W59.7 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Fazenda",
            "W59.8 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Outros Locais Especificados",
            "W59.9 - Mordedura ou Esmagamento Provocado Por Outros Répteis - Local Não Especificado",
            "W60 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas",
            "W60.0 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Residência",
            "W60.1 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Habitação Coletiva",
            "W60.2 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W60.3 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Área Para a Prática de Esportes e Atletismo",
            "W60.4 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Rua e Estrada",
            "W60.5 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Áreas de Comércio e de Serviços",
            "W60.6 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Áreas Industriais e em Construção",
            "W60.7 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Fazenda",
            "W60.8 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Outros Locais Especificados",
            "W60.9 - Contato Com Espinhos de Plantas ou Com Folhas Aguçadas - Local Não Especificado",
            "W64 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas",
            "W64.0 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Residência",
            "W64.1 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Habitação Coletiva",
            "W64.2 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W64.3 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "W64.4 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Rua e Estrada",
            "W64.5 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Áreas de Comércio e de Serviços",
            "W64.6 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Áreas Industriais e em Construção",
            "W64.7 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Fazenda",
            "W64.8 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Outros Locais Especificados",
            "W64.9 - Exposição a Outras Forças Mecânicas Animadas e às Não Especificadas - Local Não Especificado",
            "W65 - Afogamento e Submersão Durante Banho em Banheira",
            "W65.0 - Afogamento e Submersão Durante Banho em Banheira - Residência",
            "W65.1 - Afogamento e Submersão Durante Banho em Banheira - Habitação Coletiva",
            "W65.2 - Afogamento e Submersão Durante Banho em Banheira - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W65.3 - Afogamento e Submersão Durante Banho em Banheira - Área Para a Prática de Esportes e Atletismo",
            "W65.4 - Afogamento e Submersão Durante Banho em Banheira - Rua e Estrada",
            "W65.5 - Afogamento e Submersão Durante Banho em Banheira - Áreas de Comércio e de Serviços",
            "W65.6 - Afogamento e Submersão Durante Banho em Banheira - Áreas Industriais e em Construção",
            "W65.7 - Afogamento e Submersão Durante Banho em Banheira - Fazenda",
            "W65.8 - Afogamento e Submersão Durante Banho em Banheira - Outros Locais Especificados",
            "W65.9 - Afogamento e Submersão Durante Banho em Banheira - Local Não Especificado",
            "W66 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira",
            "W66.0 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Residência",
            "W66.1 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Habitação Coletiva",
            "W66.2 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W66.3 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Área Para a Prática de Esportes e Atletismo",
            "W66.4 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Rua e Estrada",
            "W66.5 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Áreas de Comércio e de Serviços",
            "W66.6 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Áreas Industriais e em Construção",
            "W66.7 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Fazenda",
            "W66.8 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Outros Locais Especificados",
            "W66.9 - Afogamento e Submersão Consecutiva a Queda Dentro de Uma Banheira - Local Não Especificado",
            "W67 - Afogamento e Submersão em Piscina",
            "W67.0 - Afogamento e Submersão em Piscina - Residência",
            "W67.1 - Afogamento e Submersão em Piscina - Habitação Coletiva",
            "W67.2 - Afogamento e Submersão em Piscina - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W67.3 - Afogamento e Submersão em Piscina - Área Para a Prática de Esportes e Atletismo",
            "W67.4 - Afogamento e Submersão em Piscina - Rua e Estrada",
            "W67.5 - Afogamento e Submersão em Piscina - Áreas de Comércio e de Serviços",
            "W67.6 - Afogamento e Submersão em Piscina - Áreas Industriais e em Construção",
            "W67.7 - Afogamento e Submersão em Piscina - Fazenda",
            "W67.8 - Afogamento e Submersão em Piscina - Outros Locais Especificados",
            "W67.9 - Afogamento e Submersão em Piscina - Local Não Especificado",
            "W68 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina",
            "W68.0 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Residência",
            "W68.1 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Habitação Coletiva",
            "W68.2 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W68.3 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Área Para a Prática de Esportes e Atletismo",
            "W68.4 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Rua e Estrada",
            "W68.5 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Áreas de Comércio e de Serviços",
            "W68.6 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Áreas Industriais e em Construção",
            "W68.7 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Fazenda",
            "W68.8 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Outros Locais Especificados",
            "W68.9 - Afogamento e Submersão Conseqüente a Queda Dentro de Uma Piscina - Local Não Especificado",
            "W69 - Afogamento e Submersão em Águas Naturais",
            "W69.0 - Afogamento e Submersão em Águas Naturais - Residência",
            "W69.1 - Afogamento e Submersão em Águas Naturais - Habitação Coletiva",
            "W69.2 - Afogamento e Submersão em Águas Naturais - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W69.3 - Afogamento e Submersão em Águas Naturais - Área Para a Prática de Esportes e Atletismo",
            "W69.4 - Afogamento e Submersão em Águas Naturais - Rua e Estrada",
            "W69.5 - Afogamento e Submersão em Águas Naturais - Áreas de Comércio e de Serviços",
            "W69.6 - Afogamento e Submersão em Águas Naturais - Áreas Industriais e em Construção",
            "W69.7 - Afogamento e Submersão em Águas Naturais - Fazenda",
            "W69.8 - Afogamento e Submersão em Águas Naturais - Outros Locais Especificados",
            "W69.9 - Afogamento e Submersão em Águas Naturais - Local Não Especificado",
            "W70 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais",
            "W70.0 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Residência",
            "W70.1 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Habitação Coletiva",
            "W70.2 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W70.3 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Área Para a Prática de Esportes e Atletismo",
            "W70.4 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Rua e Estrada",
            "W70.5 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Áreas de Comércio e de Serviços",
            "W70.6 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Áreas Industriais e em Construção",
            "W70.7 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Fazenda",
            "W70.8 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Outros Locais Especificados",
            "W70.9 - Afogamento e Submersão Conseqüentes a Queda Dentro de Águas Naturais - Local Não Especificado",
            "W73 - Outros Afogamentos e Submersão Especificados",
            "W73.0 - Outros Afogamentos e Submersão Especificados - Residência",
            "W73.1 - Outros Afogamentos e Submersão Especificados - Habitação Coletiva",
            "W73.2 - Outros Afogamentos e Submersão Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W73.3 - Outros Afogamentos e Submersão Especificados - Área Para a Prática de Esportes e Atletismo",
            "W73.4 - Outros Afogamentos e Submersão Especificados - Rua e Estrada",
            "W73.5 - Outros Afogamentos e Submersão Especificados - Áreas de Comércio e de Serviços",
            "W73.6 - Outros Afogamentos e Submersão Especificados - Áreas Industriais e em Construção",
            "W73.7 - Outros Afogamentos e Submersão Especificados - Fazenda",
            "W73.8 - Outros Afogamentos e Submersão Especificados - Outros Locais Especificados",
            "W73.9 - Outros Afogamentos e Submersão Especificados - Local Não Especificado",
            "W74 - Afogamento e Submersão Não Especificados",
            "W74.0 - Afogamento e Submersão Não Especificados - Residência",
            "W74.1 - Afogamento e Submersão Não Especificados - Habitação Coletiva",
            "W74.2 - Afogamento e Submersão Não Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W74.3 - Afogamento e Submersão Não Especificados - Área Para a Prática de Esportes e Atletismo",
            "W74.4 - Afogamento e Submersão Não Especificados - Rua e Estrada",
            "W74.5 - Afogamento e Submersão Não Especificados - Áreas de Comércio e de Serviços",
            "W74.6 - Afogamento e Submersão Não Especificados - Áreas Industriais e em Construção",
            "W74.7 - Afogamento e Submersão Não Especificados - Fazenda",
            "W74.8 - Afogamento e Submersão Não Especificados - Outros Locais Especificados",
            "W74.9 - Afogamento e Submersão Não Especificados - Local Não Especificado",
            "W75 - Sufocação e Estrangulamento Acidental na Cama",
            "W75.0 - Sufocação e Estrangulamento Acidental na Cama - Residência",
            "W75.1 - Sufocação e Estrangulamento Acidental na Cama - Habitação Coletiva",
            "W75.2 - Sufocação e Estrangulamento Acidental na Cama - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W75.3 - Sufocação e Estrangulamento Acidental na Cama - Área Para a Prática de Esportes e Atletismo",
            "W75.4 - Sufocação e Estrangulamento Acidental na Cama - Rua e Estrada",
            "W75.5 - Sufocação e Estrangulamento Acidental na Cama - Áreas de Comércio e de Serviços",
            "W75.6 - Sufocação e Estrangulamento Acidental na Cama - Áreas Industriais e em Construção",
            "W75.7 - Sufocação e Estrangulamento Acidental na Cama - Fazenda",
            "W75.8 - Sufocação e Estrangulamento Acidental na Cama - Outros Locais Especificados",
            "W75.9 - Sufocação e Estrangulamento Acidental na Cama - Local Não Especificado",
            "W76 - Outro Enforcamento e Estrangulamento Acidental",
            "W76.0 - Outro Enforcamento e Estrangulamento Acidental - Residência",
            "W76.1 - Outro Enforcamento e Estrangulamento Acidental - Habitação Coletiva",
            "W76.2 - Outro Enforcamento e Estrangulamento Acidental - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W76.3 - Outro Enforcamento e Estrangulamento Acidental - Área Para a Prática de Esportes e Atletismo",
            "W76.4 - Outro Enforcamento e Estrangulamento Acidental - Rua e Estrada",
            "W76.5 - Outro Enforcamento e Estrangulamento Acidental - Áreas de Comércio e de Serviços",
            "W76.6 - Outro Enforcamento e Estrangulamento Acidental - Áreas Industriais e em Construção",
            "W76.7 - Outro Enforcamento e Estrangulamento Acidental - Fazenda",
            "W76.8 - Outro Enforcamento e Estrangulamento Acidental - Outros Locais Especificados",
            "W76.9 - Outro Enforcamento e Estrangulamento Acidental - Local Não Especificado",
            "W77 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias",
            "W77.0 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Residência",
            "W77.1 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Habitação Coletiva",
            "W77.2 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W77.3 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Área Para a Prática de Esportes e Atletismo",
            "W77.4 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Rua e Estrada",
            "W77.5 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Áreas de Comércio e de Serviços",
            "W77.6 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Áreas Industriais e em Construção",
            "W77.7 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Fazenda",
            "W77.8 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Outros Locais Especificados",
            "W77.9 - Risco a Respiração Devido a Desmoronamento, Queda de Terra e de Outras Substâncias - Local Não Especificado",
            "W78 - Inalação do Conteúdo Gástrico",
            "W78.0 - Inalação do Conteúdo Gástrico - Residência",
            "W78.1 - Inalação do Conteúdo Gástrico - Habitação Coletiva",
            "W78.2 - Inalação do Conteúdo Gástrico - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W78.3 - Inalação do Conteúdo Gástrico - Área Para a Prática de Esportes e Atletismo",
            "W78.4 - Inalação do Conteúdo Gástrico - Rua e Estrada",
            "W78.5 - Inalação do Conteúdo Gástrico - Áreas de Comércio e de Serviços",
            "W78.6 - Inalação do Conteúdo Gástrico - Áreas Industriais e em Construção",
            "W78.7 - Inalação do Conteúdo Gástrico - Fazenda",
            "W78.8 - Inalação do Conteúdo Gástrico - Outros Locais Especificados",
            "W78.9 - Inalação do Conteúdo Gástrico - Local Não Especificado",
            "W79 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório",
            "W79.0 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Residência",
            "W79.1 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Habitação Coletiva",
            "W79.2 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W79.3 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Área Para a Prática de Esportes e Atletismo",
            "W79.4 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Rua e Estrada",
            "W79.5 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Áreas de Comércio e de Serviços",
            "W79.6 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Áreas Industriais e em Construção",
            "W79.7 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Fazenda",
            "W79.8 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Outros Locais Especificados",
            "W79.9 - Inalação e Ingestão de Alimentos Causando Obstrução do Trato Respiratório - Local Não Especificado",
            "W80 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório",
            "W80.0 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Residência",
            "W80.1 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Habitação Coletiva",
            "W80.2 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W80.3 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Área Para a Prática de Esportes e Atletismo",
            "W80.4 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Rua e Estrada",
            "W80.5 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Áreas de Comércio e de Serviços",
            "W80.6 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Áreas Industriais e em Construção",
            "W80.7 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Fazenda",
            "W80.8 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Outros Locais Especificados",
            "W80.9 - Inalação e Ingestão de Outros Objetos Causando Obstrução do Trato Respiratório - Local Não Especificado",
            "W81 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio",
            "W81.0 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Residência",
            "W81.1 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Habitação Coletiva",
            "W81.2 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W81.3 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Área Para a Prática de Esportes e Atletismo",
            "W81.4 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Rua e Estrada",
            "W81.5 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Áreas de Comércio e de Serviços",
            "W81.6 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Áreas Industriais e em Construção",
            "W81.7 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Fazenda",
            "W81.8 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Outros Locais Especificados",
            "W81.9 - Confinado ou Aprisionado em um Ambiente Pobre em Oxigênio - Local Não Especificado",
            "W83 - Outros Riscos Especificados à Respiração",
            "W83.0 - Outros Riscos Especificados à Respiração - Residência",
            "W83.1 - Outros Riscos Especificados à Respiração - Habitação Coletiva",
            "W83.2 - Outros Riscos Especificados à Respiração - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W83.3 - Outros Riscos Especificados à Respiração - Área Para a Prática de Esportes e Atletismo",
            "W83.4 - Outros Riscos Especificados à Respiração - Rua e Estrada",
            "W83.5 - Outros Riscos Especificados à Respiração - Áreas de Comércio e de Serviços",
            "W83.6 - Outros Riscos Especificados à Respiração - Áreas Industriais e em Construção",
            "W83.7 - Outros Riscos Especificados à Respiração - Fazenda",
            "W83.8 - Outros Riscos Especificados à Respiração - Outros Locais Especificados",
            "W83.9 - Outros Riscos Especificados à Respiração - Local Não Especificado",
            "W84 - Riscos Não Especificados à Respiração",
            "W84.0 - Riscos Não Especificados à Respiração - Residência",
            "W84.1 - Riscos Não Especificados à Respiração - Habitação Coletiva",
            "W84.2 - Riscos Não Especificados à Respiração - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W84.3 - Riscos Não Especificados à Respiração - Área Para a Prática de Esportes e Atletismo",
            "W84.4 - Riscos Não Especificados à Respiração - Rua e Estrada",
            "W84.5 - Riscos Não Especificados à Respiração - Áreas de Comércio e de Serviços",
            "W84.6 - Riscos Não Especificados à Respiração - Áreas Industriais e em Construção",
            "W84.7 - Riscos Não Especificados à Respiração - Fazenda",
            "W84.8 - Riscos Não Especificados à Respiração - Outros Locais Especificados",
            "W84.9 - Riscos Não Especificados à Respiração - Local Não Especificado",
            "W85 - Exposição a Linhas de Transmissão de Corrente Elétrica",
            "W85.0 - Exposição a Linhas de Transmissão de Corrente Elétrica - Residência",
            "W85.1 - Exposição a Linhas de Transmissão de Corrente Elétrica - Habitação Coletiva",
            "W85.2 - Exposição a Linhas de Transmissão de Corrente Elétrica - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W85.3 - Exposição a Linhas de Transmissão de Corrente Elétrica - Área Para a Prática de Esportes e Atletismo",
            "W85.4 - Exposição a Linhas de Transmissão de Corrente Elétrica - Rua e Estrada",
            "W85.5 - Exposição a Linhas de Transmissão de Corrente Elétrica - Áreas de Comércio e de Serviços",
            "W85.6 - Exposição a Linhas de Transmissão de Corrente Elétrica - Áreas Industriais e em Construção",
            "W85.7 - Exposição a Linhas de Transmissão de Corrente Elétrica - Fazenda",
            "W85.8 - Exposição a Linhas de Transmissão de Corrente Elétrica - Outros Locais Especificados",
            "W85.9 - Exposição a Linhas de Transmissão de Corrente Elétrica - Local Não Especificado",
            "W86 - Exposição a Outra Corrente Elétrica Especificada",
            "W86.0 - Exposição a Outra Corrente Elétrica Especificada - Residência",
            "W86.1 - Exposição a Outra Corrente Elétrica Especificada - Habitação Coletiva",
            "W86.2 - Exposição a Outra Corrente Elétrica Especificada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W86.3 - Exposição a Outra Corrente Elétrica Especificada - Área Para a Prática de Esportes e Atletismo",
            "W86.4 - Exposição a Outra Corrente Elétrica Especificada - Rua e Estrada",
            "W86.5 - Exposição a Outra Corrente Elétrica Especificada - Áreas de Comércio e de Serviços",
            "W86.6 - Exposição a Outra Corrente Elétrica Especificada - Áreas Industriais e em Construção",
            "W86.7 - Exposição a Outra Corrente Elétrica Especificada - Fazenda",
            "W86.8 - Exposição a Outra Corrente Elétrica Especificada - Outros Locais Especificados",
            "W86.9 - Exposição a Outra Corrente Elétrica Especificada - Local Não Especificado",
            "W87 - Exposição a Corrente Elétrica Não Especificada",
            "W87.0 - Exposição a Corrente Elétrica Não Especificada - Residência",
            "W87.1 - Exposição a Corrente Elétrica Não Especificada - Habitação Coletiva",
            "W87.2 - Exposição a Corrente Elétrica Não Especificada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W87.3 - Exposição a Corrente Elétrica Não Especificada - Área Para a Prática de Esportes e Atletismo",
            "W87.4 - Exposição a Corrente Elétrica Não Especificada - Rua e Estrada",
            "W87.5 - Exposição a Corrente Elétrica Não Especificada - Áreas de Comércio e de Serviços",
            "W87.6 - Exposição a Corrente Elétrica Não Especificada - Áreas Industriais e em Construção",
            "W87.7 - Exposição a Corrente Elétrica Não Especificada - Fazenda",
            "W87.8 - Exposição a Corrente Elétrica Não Especificada - Outros Locais Especificados",
            "W87.9 - Exposição a Corrente Elétrica Não Especificada - Local Não Especificado",
            "W88 - Exposição a Radiação Ionizante",
            "W88.0 - Exposição a Radiação Ionizante - Residência",
            "W88.1 - Exposição a Radiação Ionizante - Habitação Coletiva",
            "W88.2 - Exposição a Radiação Ionizante - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W88.3 - Exposição a Radiação Ionizante - Área Para a Prática de Esportes e Atletismo",
            "W88.4 - Exposição a Radiação Ionizante - Rua e Estrada",
            "W88.5 - Exposição a Radiação Ionizante - Áreas de Comércio e de Serviços",
            "W88.6 - Exposição a Radiação Ionizante - Áreas Industriais e em Construção",
            "W88.7 - Exposição a Radiação Ionizante - Fazenda",
            "W88.8 - Exposição a Radiação Ionizante - Outros Locais Especificados",
            "W88.9 - Exposição a Radiação Ionizante - Local Não Especificado",
            "W89 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta",
            "W89.0 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Residência",
            "W89.1 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Habitação Coletiva",
            "W89.2 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W89.3 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Área Para a Prática de Esportes e Atletismo",
            "W89.4 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Rua e Estrada",
            "W89.5 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Áreas de Comércio e de Serviços",
            "W89.6 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Áreas Industriais e em Construção",
            "W89.7 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Fazenda",
            "W89.8 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Outros Locais Especificados",
            "W89.9 - Exposição a Fontes Luminosas Artificiais Visíveis ou à Luz Ultravioleta - Local Não Especificado",
            "W90 - Exposição a Outros Tipos de Radiação Não-ionizante",
            "W90.0 - Exposição a Outros Tipos de Radiação Não-ionizante - Residência",
            "W90.1 - Exposição a Outros Tipos de Radiação Não-ionizante - Habitação Coletiva",
            "W90.2 - Exposição a Outros Tipos de Radiação Não-ionizante - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W90.3 - Exposição a Outros Tipos de Radiação Não-ionizante - Área Para a Prática de Esportes e Atletismo",
            "W90.4 - Exposição a Outros Tipos de Radiação Não-ionizante - Rua e Estrada",
            "W90.5 - Exposição a Outros Tipos de Radiação Não-ionizante - Áreas de Comércio e de Serviços",
            "W90.6 - Exposição a Outros Tipos de Radiação Não-ionizante - Áreas Industriais e em Construção",
            "W90.7 - Exposição a Outros Tipos de Radiação Não-ionizante - Fazenda",
            "W90.8 - Exposição a Outros Tipos de Radiação Não-ionizante - Outros Locais Especificados",
            "W90.9 - Exposição a Outros Tipos de Radiação Não-ionizante - Local Não Especificado",
            "W91 - Exposição a Tipo Não Especificado de Radiação",
            "W91.0 - Exposição a Tipo Não Especificado de Radiação - Residência",
            "W91.1 - Exposição a Tipo Não Especificado de Radiação - Habitação Coletiva",
            "W91.2 - Exposição a Tipo Não Especificado de Radiação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W91.3 - Exposição a Tipo Não Especificado de Radiação - Área Para a Prática de Esportes e Atletismo",
            "W91.4 - Exposição a Tipo Não Especificado de Radiação - Rua e Estrada",
            "W91.5 - Exposição a Tipo Não Especificado de Radiação - Áreas de Comércio e de Serviços",
            "W91.6 - Exposição a Tipo Não Especificado de Radiação - Áreas Industriais e em Construção",
            "W91.7 - Exposição a Tipo Não Especificado de Radiação - Fazenda",
            "W91.8 - Exposição a Tipo Não Especificado de Radiação - Outros Locais Especificados",
            "W91.9 - Exposição a Tipo Não Especificado de Radiação - Local Não Especificado",
            "W92 - Exposição a um Calor Excessivo de Origem Artificial",
            "W92.0 - Exposição a um Calor Excessivo de Origem Artificial - Residência",
            "W92.1 - Exposição a um Calor Excessivo de Origem Artificial - Habitação Coletiva",
            "W92.2 - Exposição a um Calor Excessivo de Origem Artificial - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W92.3 - Exposição a um Calor Excessivo de Origem Artificial - Área Para a Prática de Esportes e Atletismo",
            "W92.4 - Exposição a um Calor Excessivo de Origem Artificial - Rua e Estrada",
            "W92.5 - Exposição a um Calor Excessivo de Origem Artificial - Áreas de Comércio e de Serviços",
            "W92.6 - Exposição a um Calor Excessivo de Origem Artificial - Áreas Industriais e em Construção",
            "W92.7 - Exposição a um Calor Excessivo de Origem Artificial - Fazenda",
            "W92.8 - Exposição a um Calor Excessivo de Origem Artificial - Outros Locais Especificados",
            "W92.9 - Exposição a um Calor Excessivo de Origem Artificial - Local Não Especificado",
            "W93 - Exposição a um Frio Excessivo de Origem Artificial",
            "W93.0 - Exposição a um Frio Excessivo de Origem Artificial - Residência",
            "W93.1 - Exposição a um Frio Excessivo de Origem Artificial - Habitação Coletiva",
            "W93.2 - Exposição a um Frio Excessivo de Origem Artificial - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W93.3 - Exposição a um Frio Excessivo de Origem Artificial - Área Para a Prática de Esportes e Atletismo",
            "W93.4 - Exposição a um Frio Excessivo de Origem Artificial - Rua e Estrada",
            "W93.5 - Exposição a um Frio Excessivo de Origem Artificial - Áreas de Comércio e de Serviços",
            "W93.6 - Exposição a um Frio Excessivo de Origem Artificial - Áreas Industriais e em Construção",
            "W93.7 - Exposição a um Frio Excessivo de Origem Artificial - Fazenda",
            "W93.8 - Exposição a um Frio Excessivo de Origem Artificial - Outros Locais Especificados",
            "W93.9 - Exposição a um Frio Excessivo de Origem Artificial - Local Não Especificado",
            "W94 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica",
            "W94.0 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Residência",
            "W94.1 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Habitação Coletiva",
            "W94.2 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W94.3 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Área Para a Prática de Esportes e Atletismo",
            "W94.4 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Rua e Estrada",
            "W94.5 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Áreas de Comércio e de Serviços",
            "W94.6 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Áreas Industriais e em Construção",
            "W94.7 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Fazenda",
            "W94.8 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Outros Locais Especificados",
            "W94.9 - Exposição a Alta, Baixa e a Variações da Pressão Atmosférica - Local Não Especificado",
            "W99 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados",
            "W99.0 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Residência",
            "W99.1 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Habitação Coletiva",
            "W99.2 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "W99.3 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Área Para a Prática de Esportes e Atletismo",
            "W99.4 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Rua e Estrada",
            "W99.5 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Áreas de Comércio e de Serviços",
            "W99.6 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Áreas Industriais e em Construção",
            "W99.7 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Fazenda",
            "W99.8 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Outros Locais Especificados",
            "W99.9 - Exposição a Outros Fatores Ambientais Artificiais e Aos Não Especificados - Local Não Especificado",
            "X00 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção",
            "X00.0 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Residência",
            "X00.1 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Habitação Coletiva",
            "X00.2 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X00.3 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Área Para a Prática de Esportes e Atletismo",
            "X00.4 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Rua e Estrada",
            "X00.5 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Áreas de Comércio e de Serviços",
            "X00.6 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Áreas Industriais e em Construção",
            "X00.7 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Fazenda",
            "X00.8 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Outros Locais Especificados",
            "X00.9 - Exposição a Fogo Não-controlado em um Edifício ou Outro Tipo de Construção - Local Não Especificado",
            "X01 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção",
            "X01.0 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Residência",
            "X01.1 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Habitação Coletiva",
            "X01.2 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X01.3 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Área Para a Prática de Esportes e Atletismo",
            "X01.4 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Rua e Estrada",
            "X01.5 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Áreas de Comércio e de Serviços",
            "X01.6 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Áreas Industriais e em Construção",
            "X01.7 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Fazenda",
            "X01.8 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Outros Locais Especificados",
            "X01.9 - Exposição a Fogo Não-controlado Fora de um Edifício ou de Outro Tipo de Construção - Local Não Especificado",
            "X02 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção",
            "X02.0 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Residência",
            "X02.1 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Habitação Coletiva",
            "X02.2 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X02.3 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Área Para a Prática de Esportes e Atletismo",
            "X02.4 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Rua e Estrada",
            "X02.5 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Áreas de Comércio e de Serviços",
            "X02.6 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Áreas Industriais e em Construção",
            "X02.7 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Fazenda",
            "X02.8 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Outros Locais Especificados",
            "X02.9 - Exposição a Fogo Controlado em um Edifício ou Outro Tipo de Construção - Local Não Especificado",
            "X03 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção",
            "X03.0 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Residência",
            "X03.1 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Habitação Coletiva",
            "X03.2 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X03.3 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Área Para a Prática de Esportes e Atletismo",
            "X03.4 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Rua e Estrada",
            "X03.5 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Áreas de Comércio e de Serviços",
            "X03.6 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Áreas Industriais e em Construção",
            "X03.7 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Fazenda",
            "X03.8 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Outros Locais Especificados",
            "X03.9 - Exposição a Fogo Controlado Fora de um Edifício ou de Outro Tipo de Construção - Local Não Especificado",
            "X04 - Exposição a Combustão de Substância Muito Inflamável",
            "X04.0 - Exposição a Combustão de Substância Muito Inflamável - Residência",
            "X04.1 - Exposição a Combustão de Substância Muito Inflamável - Habitação Coletiva",
            "X04.2 - Exposição a Combustão de Substância Muito Inflamável - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X04.3 - Exposição a Combustão de Substância Muito Inflamável - Área Para a Prática de Esportes e Atletismo",
            "X04.4 - Exposição a Combustão de Substância Muito Inflamável - Rua e Estrada",
            "X04.5 - Exposição a Combustão de Substância Muito Inflamável - Áreas de Comércio e de Serviços",
            "X04.6 - Exposição a Combustão de Substância Muito Inflamável - Áreas Industriais e em Construção",
            "X04.7 - Exposição a Combustão de Substância Muito Inflamável - Fazenda",
            "X04.8 - Exposição a Combustão de Substância Muito Inflamável - Outros Locais Especificados",
            "X04.9 - Exposição a Combustão de Substância Muito Inflamável - Local Não Especificado",
            "X05 - Exposição a Combustão de Roupa de Dormir",
            "X05.0 - Exposição a Combustão de Roupa de Dormir - Residência",
            "X05.1 - Exposição a Combustão de Roupa de Dormir - Habitação Coletiva",
            "X05.2 - Exposição a Combustão de Roupa de Dormir - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X05.3 - Exposição a Combustão de Roupa de Dormir - Área Para a Prática de Esportes e Atletismo",
            "X05.4 - Exposição a Combustão de Roupa de Dormir - Rua e Estrada",
            "X05.5 - Exposição a Combustão de Roupa de Dormir - Áreas de Comércio e de Serviços",
            "X05.6 - Exposição a Combustão de Roupa de Dormir - Áreas Industriais e em Construção",
            "X05.7 - Exposição a Combustão de Roupa de Dormir - Fazenda",
            "X05.8 - Exposição a Combustão de Roupa de Dormir - Outros Locais Especificados",
            "X05.9 - Exposição a Combustão de Roupa de Dormir - Local Não Especificado",
            "X06 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios",
            "X06.0 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Residência",
            "X06.1 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Habitação Coletiva",
            "X06.2 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X06.3 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Área Para a Prática de Esportes e Atletismo",
            "X06.4 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Rua e Estrada",
            "X06.5 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Áreas de Comércio e de Serviços",
            "X06.6 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Áreas Industriais e em Construção",
            "X06.7 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Fazenda",
            "X06.8 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Outros Locais Especificados",
            "X06.9 - Exposição a Combustão de Outro Tipo de Roupa ou de Acessórios - Local Não Especificado",
            "X08 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas",
            "X08.0 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Residência",
            "X08.1 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Habitação Coletiva",
            "X08.2 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X08.3 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Área Para a Prática de Esportes e Atletismo",
            "X08.4 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Rua e Estrada",
            "X08.5 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Áreas de Comércio e de Serviços",
            "X08.6 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Áreas Industriais e em Construção",
            "X08.7 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Fazenda",
            "X08.8 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Outros Locais Especificados",
            "X08.9 - Exposição a Outro Tipo Especificado de Fumaça, Fogo ou Chamas - Local Não Especificado",
            "X09 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas",
            "X09.0 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Residência",
            "X09.1 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Habitação Coletiva",
            "X09.2 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X09.3 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Área Para a Prática de Esportes e Atletismo",
            "X09.4 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Rua e Estrada",
            "X09.5 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Áreas de Comércio e de Serviços",
            "X09.6 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Áreas Industriais e em Construção",
            "X09.7 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Fazenda",
            "X09.8 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Outros Locais Especificados",
            "X09.9 - Exposição a Tipo Não Especificado de Fumaça, Fogo ou Chamas - Local Não Especificado",
            "X10 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes",
            "X10.0 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Residência",
            "X10.1 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Habitação Coletiva",
            "X10.2 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X10.3 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Área Para a Prática de Esportes e Atletismo",
            "X10.4 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Rua e Estrada",
            "X10.5 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Áreas de Comércio e de Serviços",
            "X10.6 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Áreas Industriais e em Construção",
            "X10.7 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Fazenda",
            "X10.8 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Outros Locais Especificados",
            "X10.9 - Contato Com Bebidas, Alimentos, Gordura e Óleo de Cozinha Quentes - Local Não Especificado",
            "X11 - Contato Com Água Corrente Quente de Torneira",
            "X11.0 - Contato Com Água Corrente Quente de Torneira - Residência",
            "X11.1 - Contato Com Água Corrente Quente de Torneira - Habitação Coletiva",
            "X11.2 - Contato Com Água Corrente Quente de Torneira - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X11.3 - Contato Com Água Corrente Quente de Torneira - Área Para a Prática de Esportes e Atletismo",
            "X11.4 - Contato Com Água Corrente Quente de Torneira - Rua e Estrada",
            "X11.5 - Contato Com Água Corrente Quente de Torneira - Áreas de Comércio e de Serviços",
            "X11.6 - Contato Com Água Corrente Quente de Torneira - Áreas Industriais e em Construção",
            "X11.7 - Contato Com Água Corrente Quente de Torneira - Fazenda",
            "X11.8 - Contato Com Água Corrente Quente de Torneira - Outros Locais Especificados",
            "X11.9 - Contato Com Água Corrente Quente de Torneira - Local Não Especificado",
            "X12 - Contato Com Outros Líquidos Quentes",
            "X12.0 - Contato Com Outros Líquidos Quentes - Residência",
            "X12.1 - Contato Com Outros Líquidos Quentes - Habitação Coletiva",
            "X12.2 - Contato Com Outros Líquidos Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X12.3 - Contato Com Outros Líquidos Quentes - Área Para a Prática de Esportes e Atletismo",
            "X12.4 - Contato Com Outros Líquidos Quentes - Rua e Estrada",
            "X12.5 - Contato Com Outros Líquidos Quentes - Áreas de Comércio e de Serviços",
            "X12.6 - Contato Com Outros Líquidos Quentes - Áreas Industriais e em Construção",
            "X12.7 - Contato Com Outros Líquidos Quentes - Fazenda",
            "X12.8 - Contato Com Outros Líquidos Quentes - Outros Locais Especificados",
            "X12.9 - Contato Com Outros Líquidos Quentes - Local Não Especificado",
            "X13 - Contato Com Vapor D''água e Com Vapores Quentes",
            "X13.0 - Contato Com Vapor D'água e Com Vapores Quentes - Residência",
            "X13.1 - Contato Com Vapor D'água e Com Vapores Quentes - Habitação Coletiva",
            "X13.2 - Contato Com Vapor D'água e Com Vapores Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X13.3 - Contato Com Vapor D'água e Com Vapores Quentes - Área Para a Prática de Esportes e Atletismo",
            "X13.4 - Contato Com Vapor D'água e Com Vapores Quentes - Rua e Estrada",
            "X13.5 - Contato Com Vapor D'água e Com Vapores Quentes - Áreas de Comércio e de Serviços",
            "X13.6 - Contato Com Vapor D'água e Com Vapores Quentes - Áreas Industriais e em Construção",
            "X13.7 - Contato Com Vapor D'água e Com Vapores Quentes - Fazenda",
            "X13.8 - Contato Com Vapor D'água e Com Vapores Quentes - Outros Locais Especificados",
            "X13.9 - Contato Com Vapor D'água e Com Vapores Quentes - Local Não Especificado",
            "X14 - Contato Com ar e Gases Quentes",
            "X14.0 - Contato Com ar e Gases Quentes - Residência",
            "X14.1 - Contato Com ar e Gases Quentes - Habitação Coletiva",
            "X14.2 - Contato Com ar e Gases Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X14.3 - Contato Com ar e Gases Quentes - Área Para a Prática de Esportes e Atletismo",
            "X14.4 - Contato Com ar e Gases Quentes - Rua e Estrada",
            "X14.5 - Contato Com ar e Gases Quentes - Áreas de Comércio e de Serviços",
            "X14.6 - Contato Com ar e Gases Quentes - Áreas Industriais e em Construção",
            "X14.7 - Contato Com ar e Gases Quentes - Fazenda",
            "X14.8 - Contato Com ar e Gases Quentes - Outros Locais Especificados",
            "X14.9 - Contato Com ar e Gases Quentes - Local Não Especificado",
            "X15 - Contato Com Aparelhos Domésticos Quentes",
            "X15.0 - Contato Com Aparelhos Domésticos Quentes - Residência",
            "X15.1 - Contato Com Aparelhos Domésticos Quentes - Habitação Coletiva",
            "X15.2 - Contato Com Aparelhos Domésticos Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X15.3 - Contato Com Aparelhos Domésticos Quentes - Área Para a Prática de Esportes e Atletismo",
            "X15.4 - Contato Com Aparelhos Domésticos Quentes - Rua e Estrada",
            "X15.5 - Contato Com Aparelhos Domésticos Quentes - Áreas de Comércio e de Serviços",
            "X15.6 - Contato Com Aparelhos Domésticos Quentes - Áreas Industriais e em Construção",
            "X15.7 - Contato Com Aparelhos Domésticos Quentes - Fazenda",
            "X15.8 - Contato Com Aparelhos Domésticos Quentes - Outros Locais Especificados",
            "X15.9 - Contato Com Aparelhos Domésticos Quentes - Local Não Especificado",
            "X16 - Contato Com Aquecedores, Radiadores e Tubulação",
            "X16.0 - Contato Com Aquecedores, Radiadores e Tubulação - Residência",
            "X16.1 - Contato Com Aquecedores, Radiadores e Tubulação - Habitação Coletiva",
            "X16.2 - Contato Com Aquecedores, Radiadores e Tubulação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X16.3 - Contato Com Aquecedores, Radiadores e Tubulação - Área Para a Prática de Esportes e Atletismo",
            "X16.4 - Contato Com Aquecedores, Radiadores e Tubulação - Rua e Estrada",
            "X16.5 - Contato Com Aquecedores, Radiadores e Tubulação - Áreas de Comércio e de Serviços",
            "X16.6 - Contato Com Aquecedores, Radiadores e Tubulação - Áreas Industriais e em Construção",
            "X16.7 - Contato Com Aquecedores, Radiadores e Tubulação - Fazenda",
            "X16.8 - Contato Com Aquecedores, Radiadores e Tubulação - Outros Locais Especificados",
            "X16.9 - Contato Com Aquecedores, Radiadores e Tubulação - Local Não Especificado",
            "X17 - Contato Com Motores, Máquinas e Ferramentas Quentes",
            "X17.0 - Contato Com Motores, Máquinas e Ferramentas Quentes - Residência",
            "X17.1 - Contato Com Motores, Máquinas e Ferramentas Quentes - Habitação Coletiva",
            "X17.2 - Contato Com Motores, Máquinas e Ferramentas Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X17.3 - Contato Com Motores, Máquinas e Ferramentas Quentes - Área Para a Prática de Esportes e Atletismo",
            "X17.4 - Contato Com Motores, Máquinas e Ferramentas Quentes - Rua e Estrada",
            "X17.5 - Contato Com Motores, Máquinas e Ferramentas Quentes - Áreas de Comércio e de Serviços",
            "X17.6 - Contato Com Motores, Máquinas e Ferramentas Quentes - Áreas Industriais e em Construção",
            "X17.7 - Contato Com Motores, Máquinas e Ferramentas Quentes - Fazenda",
            "X17.8 - Contato Com Motores, Máquinas e Ferramentas Quentes - Outros Locais Especificados",
            "X17.9 - Contato Com Motores, Máquinas e Ferramentas Quentes - Local Não Especificado",
            "X18 - Contato Com Outros Metais Quentes",
            "X18.0 - Contato Com Outros Metais Quentes - Residência",
            "X18.1 - Contato Com Outros Metais Quentes - Habitação Coletiva",
            "X18.2 - Contato Com Outros Metais Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X18.3 - Contato Com Outros Metais Quentes - Área Para a Prática de Esportes e Atletismo",
            "X18.4 - Contato Com Outros Metais Quentes - Rua e Estrada",
            "X18.5 - Contato Com Outros Metais Quentes - Áreas de Comércio e de Serviços",
            "X18.6 - Contato Com Outros Metais Quentes - Áreas Industriais e em Construção",
            "X18.7 - Contato Com Outros Metais Quentes - Fazenda",
            "X18.8 - Contato Com Outros Metais Quentes - Outros Locais Especificados",
            "X18.9 - Contato Com Outros Metais Quentes - Local Não Especificado",
            "X19 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados",
            "X19.0 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Residência",
            "X19.1 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Habitação Coletiva",
            "X19.2 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X19.3 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Área Para a Prática de Esportes e Atletismo",
            "X19.4 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Rua e Estrada",
            "X19.5 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Áreas de Comércio e de Serviços",
            "X19.6 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Áreas Industriais e em Construção",
            "X19.7 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Fazenda",
            "X19.8 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Outros Locais Especificados",
            "X19.9 - Contato Com Outras Fontes de Calor ou Com Substâncias Quentes Não Especificados - Local Não Especificado",
            "X20 - Contato Com Serpentes e Lagartos Venenosos",
            "X20.0 - Contato Com Serpentes e Lagartos Venenosos - Residência",
            "X20.1 - Contato Com Serpentes e Lagartos Venenosos - Habitação Coletiva",
            "X20.2 - Contato Com Serpentes e Lagartos Venenosos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X20.3 - Contato Com Serpentes e Lagartos Venenosos - Área Para a Prática de Esportes e Atletismo",
            "X20.4 - Contato Com Serpentes e Lagartos Venenosos - Rua e Estrada",
            "X20.5 - Contato Com Serpentes e Lagartos Venenosos - Áreas de Comércio e de Serviços",
            "X20.6 - Contato Com Serpentes e Lagartos Venenosos - Áreas Industriais e em Construção",
            "X20.7 - Contato Com Serpentes e Lagartos Venenosos - Fazenda",
            "X20.8 - Contato Com Serpentes e Lagartos Venenosos - Outros Locais Especificados",
            "X20.9 - Contato Com Serpentes e Lagartos Venenosos - Local Não Especificado",
            "X21 - Contato Com Aranhas Venenosas",
            "X21.0 - Contato Com Aranhas Venenosas - Residência",
            "X21.1 - Contato Com Aranhas Venenosas - Habitação Coletiva",
            "X21.2 - Contato Com Aranhas Venenosas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X21.3 - Contato Com Aranhas Venenosas - Área Para a Prática de Esportes e Atletismo",
            "X21.4 - Contato Com Aranhas Venenosas - Rua e Estrada",
            "X21.5 - Contato Com Aranhas Venenosas - Áreas de Comércio e de Serviços",
            "X21.6 - Contato Com Aranhas Venenosas - Áreas Industriais e em Construção",
            "X21.7 - Contato Com Aranhas Venenosas - Fazenda",
            "X21.8 - Contato Com Aranhas Venenosas - Outros Locais Especificados",
            "X21.9 - Contato Com Aranhas Venenosas - Local Não Especificado",
            "X22 - Contato Com Escorpiões",
            "X22.0 - Contato Com Escorpiões - Residência",
            "X22.1 - Contato Com Escorpiões - Habitação Coletiva",
            "X22.2 - Contato Com Escorpiões - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X22.3 - Contato Com Escorpiões - Área Para a Prática de Esportes e Atletismo",
            "X22.4 - Contato Com Escorpiões - Rua e Estrada",
            "X22.5 - Contato Com Escorpiões - Áreas de Comércio e de Serviços",
            "X22.6 - Contato Com Escorpiões - Áreas Industriais e em Construção",
            "X22.7 - Contato Com Escorpiões - Fazenda",
            "X22.8 - Contato Com Escorpiões - Outros Locais Especificados",
            "X22.9 - Contato Com Escorpiões - Local Não Especificado",
            "X23 - Contato Com Abelhas, Vespas e Vespões",
            "X23.0 - Contato Com Abelhas, Vespas e Vespões - Residência",
            "X23.1 - Contato Com Abelhas, Vespas e Vespões - Habitação Coletiva",
            "X23.2 - Contato Com Abelhas, Vespas e Vespões - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X23.3 - Contato Com Abelhas, Vespas e Vespões - Área Para a Prática de Esportes e Atletismo",
            "X23.4 - Contato Com Abelhas, Vespas e Vespões - Rua e Estrada",
            "X23.5 - Contato Com Abelhas, Vespas e Vespões - Áreas de Comércio e de Serviços",
            "X23.6 - Contato Com Abelhas, Vespas e Vespões - Áreas Industriais e em Construção",
            "X23.7 - Contato Com Abelhas, Vespas e Vespões - Fazenda",
            "X23.8 - Contato Com Abelhas, Vespas e Vespões - Outros Locais Especificados",
            "X23.9 - Contato Com Abelhas, Vespas e Vespões - Local Não Especificado",
            "X24 - Contato Com Centopéias e Miriápodes Venenosas (tropicais)",
            "X24.0 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Residência",
            "X24.1 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Habitação Coletiva",
            "X24.2 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X24.3 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Área Para a Prática de Esportes e Atletismo",
            "X24.4 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Rua e Estrada",
            "X24.5 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Áreas de Comércio e de Serviços",
            "X24.6 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Áreas Industriais e em Construção",
            "X24.7 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Fazenda",
            "X24.8 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Outros Locais Especificados",
            "X24.9 - Contato Com Centopéias e Miriápodes Venenosas (tropicais) - Local Não Especificado",
            "X25 - Contato Com Outros Artrópodes Venenosos",
            "X25.0 - Contato Com Outros Artrópodes Venenosos - Residência",
            "X25.1 - Contato Com Outros Artrópodes Venenosos - Habitação Coletiva",
            "X25.2 - Contato Com Outros Artrópodes Venenosos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X25.3 - Contato Com Outros Artrópodes Venenosos - Área Para a Prática de Esportes e Atletismo",
            "X25.4 - Contato Com Outros Artrópodes Venenosos - Rua e Estrada",
            "X25.5 - Contato Com Outros Artrópodes Venenosos - Áreas de Comércio e de Serviços",
            "X25.6 - Contato Com Outros Artrópodes Venenosos - Áreas Industriais e em Construção",
            "X25.7 - Contato Com Outros Artrópodes Venenosos - Fazenda",
            "X25.8 - Contato Com Outros Artrópodes Venenosos - Outros Locais Especificados",
            "X25.9 - Contato Com Outros Artrópodes Venenosos - Local Não Especificado",
            "X26 - Contato Com Animais e Plantas Marinhos Venenosos",
            "X26.0 - Contato Com Animais e Plantas Marinhos Venenosos - Residência",
            "X26.1 - Contato Com Animais e Plantas Marinhos Venenosos - Habitação Coletiva",
            "X26.2 - Contato Com Animais e Plantas Marinhos Venenosos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X26.3 - Contato Com Animais e Plantas Marinhos Venenosos - Área Para a Prática de Esportes e Atletismo",
            "X26.4 - Contato Com Animais e Plantas Marinhos Venenosos - Rua e Estrada",
            "X26.5 - Contato Com Animais e Plantas Marinhos Venenosos - Áreas de Comércio e de Serviços",
            "X26.6 - Contato Com Animais e Plantas Marinhos Venenosos - Áreas Industriais e em Construção",
            "X26.7 - Contato Com Animais e Plantas Marinhos Venenosos - Fazenda",
            "X26.8 - Contato Com Animais e Plantas Marinhos Venenosos - Outros Locais Especificados",
            "X26.9 - Contato Com Animais e Plantas Marinhos Venenosos - Local Não Especificado",
            "X27 - Contato Com Outros Animais Venenosos Especificados",
            "X27.0 - Contato Com Outros Animais Venenosos Especificados - Residência",
            "X27.1 - Contato Com Outros Animais Venenosos Especificados - Habitação Coletiva",
            "X27.2 - Contato Com Outros Animais Venenosos Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X27.3 - Contato Com Outros Animais Venenosos Especificados - Área Para a Prática de Esportes e Atletismo",
            "X27.4 - Contato Com Outros Animais Venenosos Especificados - Rua e Estrada",
            "X27.5 - Contato Com Outros Animais Venenosos Especificados - Áreas de Comércio e de Serviços",
            "X27.6 - Contato Com Outros Animais Venenosos Especificados - Áreas Industriais e em Construção",
            "X27.7 - Contato Com Outros Animais Venenosos Especificados - Fazenda",
            "X27.8 - Contato Com Outros Animais Venenosos Especificados - Outros Locais Especificados",
            "X27.9 - Contato Com Outros Animais Venenosos Especificados - Local Não Especificado",
            "X28 - Contato Com Outras Plantas Venenosas Especificadas",
            "X28.0 - Contato Com Outras Plantas Venenosas Especificadas - Residência",
            "X28.1 - Contato Com Outras Plantas Venenosas Especificadas - Habitação Coletiva",
            "X28.2 - Contato Com Outras Plantas Venenosas Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X28.3 - Contato Com Outras Plantas Venenosas Especificadas - Área Para a Prática de Esportes e Atletismo",
            "X28.4 - Contato Com Outras Plantas Venenosas Especificadas - Rua e Estrada",
            "X28.5 - Contato Com Outras Plantas Venenosas Especificadas - Áreas de Comércio e de Serviços",
            "X28.6 - Contato Com Outras Plantas Venenosas Especificadas - Áreas Industriais e em Construção",
            "X28.7 - Contato Com Outras Plantas Venenosas Especificadas - Fazenda",
            "X28.8 - Contato Com Outras Plantas Venenosas Especificadas - Outros Locais Especificados",
            "X28.9 - Contato Com Outras Plantas Venenosas Especificadas - Local Não Especificado",
            "X29 - Contato Com Animais ou Plantas Venenosos, Sem Especificação",
            "X29.0 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Residência",
            "X29.1 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Habitação Coletiva",
            "X29.2 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X29.3 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Área Para a Prática de Esportes e Atletismo",
            "X29.4 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Rua e Estrada",
            "X29.5 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Áreas de Comércio e de Serviços",
            "X29.6 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Áreas Industriais e em Construção",
            "X29.7 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Fazenda",
            "X29.8 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Outros Locais Especificados",
            "X29.9 - Contato Com Animais ou Plantas Venenosos, Sem Especificação - Local Não Especificado",
            "X30 - Exposição a Calor Natural Excessivo",
            "X30.0 - Exposição a Calor Natural Excessivo - Residência",
            "X30.1 - Exposição a Calor Natural Excessivo - Habitação Coletiva",
            "X30.2 - Exposição a Calor Natural Excessivo - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X30.3 - Exposição a Calor Natural Excessivo - Área Para a Prática de Esportes e Atletismo",
            "X30.4 - Exposição a Calor Natural Excessivo - Rua e Estrada",
            "X30.5 - Exposição a Calor Natural Excessivo - Áreas de Comércio e de Serviços",
            "X30.6 - Exposição a Calor Natural Excessivo - Áreas Industriais e em Construção",
            "X30.7 - Exposição a Calor Natural Excessivo - Fazenda",
            "X30.8 - Exposição a Calor Natural Excessivo - Outros Locais Especificados",
            "X30.9 - Exposição a Calor Natural Excessivo - Local Não Especificado",
            "X31 - Exposição a Frio Natural Excessivo",
            "X31.0 - Exposição a Frio Natural Excessivo - Residência",
            "X31.1 - Exposição a Frio Natural Excessivo - Habitação Coletiva",
            "X31.2 - Exposição a Frio Natural Excessivo - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X31.3 - Exposição a Frio Natural Excessivo - Área Para a Prática de Esportes e Atletismo",
            "X31.4 - Exposição a Frio Natural Excessivo - Rua e Estrada",
            "X31.5 - Exposição a Frio Natural Excessivo - Áreas de Comércio e de Serviços",
            "X31.6 - Exposição a Frio Natural Excessivo - Áreas Industriais e em Construção",
            "X31.7 - Exposição a Frio Natural Excessivo - Fazenda",
            "X31.8 - Exposição a Frio Natural Excessivo - Outros Locais Especificados",
            "X31.9 - Exposição a Frio Natural Excessivo - Local Não Especificado",
            "X32 - Exposição à Luz Solar",
            "X32.0 - Exposição à Luz Solar - Residência",
            "X32.1 - Exposição à Luz Solar - Habitação Coletiva",
            "X32.2 - Exposição à Luz Solar - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X32.3 - Exposição à Luz Solar - Área Para a Prática de Esportes e Atletismo",
            "X32.4 - Exposição à Luz Solar - Rua e Estrada",
            "X32.5 - Exposição à Luz Solar - Áreas de Comércio e de Serviços",
            "X32.6 - Exposição à Luz Solar - Áreas Industriais e em Construção",
            "X32.7 - Exposição à Luz Solar - Fazenda",
            "X32.8 - Exposição à Luz Solar - Outros Locais Especificados",
            "X32.9 - Exposição à Luz Solar - Local Não Especificado",
            "X33 - Vítima de Raio",
            "X33.0 - Vítima de Raio - Residência",
            "X33.1 - Vítima de Raio - Habitação Coletiva",
            "X33.2 - Vítima de Raio - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X33.3 - Vítima de Raio - Área Para a Prática de Esportes e Atletismo",
            "X33.4 - Vítima de Raio - Rua e Estrada",
            "X33.5 - Vítima de Raio - Áreas de Comércio e de Serviços",
            "X33.6 - Vítima de Raio - Áreas Industriais e em Construção",
            "X33.7 - Vítima de Raio - Fazenda",
            "X33.8 - Vítima de Raio - Outros Locais Especificados",
            "X34 - Vítima de Terremoto",
            "X33.9 - Vítima de Raio - Local Não Especificado",
            "X34.1 - Vítima de Terremoto - Habitação Coletiva",
            "X34.0 - Vítima de Terremoto - Residência",
            "X34.3 - Vítima de Terremoto - Área Para a Prática de Esportes e Atletismo",
            "X34.2 - Vítima de Terremoto - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X34.5 - Vítima de Terremoto - Áreas de Comércio e de Serviços",
            "X34.4 - Vítima de Terremoto - Rua e Estrada",
            "X34.7 - Vítima de Terremoto - Fazenda",
            "X34.6 - Vítima de Terremoto - Áreas Industriais e em Construção",
            "X34.9 - Vítima de Terremoto - Local Não Especificado",
            "X34.8 - Vítima de Terremoto - Outros Locais Especificados",
            "X35.0 - Vítima de Erupção Vulcânica - Residência",
            "X35 - Vítima de Erupção Vulcânica",
            "X35.2 - Vítima de Erupção Vulcânica - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X35.1 - Vítima de Erupção Vulcânica - Habitação Coletiva",
            "X35.4 - Vítima de Erupção Vulcânica - Rua e Estrada",
            "X35.3 - Vítima de Erupção Vulcânica - Área Para a Prática de Esportes e Atletismo",
            "X35.6 - Vítima de Erupção Vulcânica - Áreas Industriais e em Construção",
            "X35.5 - Vítima de Erupção Vulcânica - Áreas de Comércio e de Serviços",
            "X35.8 - Vítima de Erupção Vulcânica - Outros Locais Especificados",
            "X35.7 - Vítima de Erupção Vulcânica - Fazenda",
            "X36 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre",
            "X35.9 - Vítima de Erupção Vulcânica - Local Não Especificado",
            "X36.1 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Habitação Coletiva",
            "X36.0 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Residência",
            "X36.3 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Área Para a Prática de Esportes e Atletismo",
            "X36.2 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X36.5 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Áreas de Comércio e de Serviços",
            "X36.4 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Rua e Estrada",
            "X36.7 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Fazenda",
            "X36.6 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Áreas Industriais e em Construção",
            "X36.9 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Local Não Especificado",
            "X36.8 - Vítima de Avalanche, Desabamento de Terra e Outros Movimentos da Superfície Terrestre - Outros Locais Especificados",
            "X37.0 - Vítima de Tempestade Cataclísmica - Residência",
            "X37 - Vítima de Tempestade Cataclísmica",
            "X37.2 - Vítima de Tempestade Cataclísmica - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X37.1 - Vítima de Tempestade Cataclísmica - Habitação Coletiva",
            "X37.4 - Vítima de Tempestade Cataclísmica - Rua e Estrada",
            "X37.3 - Vítima de Tempestade Cataclísmica - Área Para a Prática de Esportes e Atletismo",
            "X37.6 - Vítima de Tempestade Cataclísmica - Áreas Industriais e em Construção",
            "X37.5 - Vítima de Tempestade Cataclísmica - Áreas de Comércio e de Serviços",
            "X37.8 - Vítima de Tempestade Cataclísmica - Outros Locais Especificados",
            "X37.7 - Vítima de Tempestade Cataclísmica - Fazenda",
            "X38 - Vítima de Inundação",
            "X37.9 - Vítima de Tempestade Cataclísmica - Local Não Especificado",
            "X38.1 - Vítima de Inundação - Habitação Coletiva",
            "X38.0 - Vítima de Inundação - Residência",
            "X38.3 - Vítima de Inundação - Área Para a Prática de Esportes e Atletismo",
            "X38.2 - Vítima de Inundação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X38.5 - Vítima de Inundação - Áreas de Comércio e de Serviços",
            "X38.4 - Vítima de Inundação - Rua e Estrada",
            "X38.7 - Vítima de Inundação - Fazenda",
            "X38.6 - Vítima de Inundação - Áreas Industriais e em Construção",
            "X38.9 - Vítima de Inundação - Local Não Especificado",
            "X38.8 - Vítima de Inundação - Outros Locais Especificados",
            "X39.0 - Exposição a Outras Forças da Natureza e às Não Especificadas - Residência",
            "X39 - Exposição a Outras Forças da Natureza e às Não Especificadas",
            "X39.2 - Exposição a Outras Forças da Natureza e às Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X39.1 - Exposição a Outras Forças da Natureza e às Não Especificadas - Habitação Coletiva",
            "X39.4 - Exposição a Outras Forças da Natureza e às Não Especificadas - Rua e Estrada",
            "X39.3 - Exposição a Outras Forças da Natureza e às Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "X39.6 - Exposição a Outras Forças da Natureza e às Não Especificadas - Áreas Industriais e em Construção",
            "X39.5 - Exposição a Outras Forças da Natureza e às Não Especificadas - Áreas de Comércio e de Serviços",
            "X39.8 - Exposição a Outras Forças da Natureza e às Não Especificadas - Outros Locais Especificados",
            "X39.7 - Exposição a Outras Forças da Natureza e às Não Especificadas - Fazenda",
            "X40 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos",
            "X39.9 - Exposição a Outras Forças da Natureza e às Não Especificadas - Local Não Especificado",
            "X40.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Habitação Coletiva",
            "X40.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Residência",
            "X40.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Área Para a Prática de Esportes e Atletismo",
            "X40.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X40.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Áreas de Comércio e de Serviços",
            "X40.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Rua e Estrada",
            "X40.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Fazenda",
            "X40.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Áreas Industriais e em Construção",
            "X40.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Local Não Especificado",
            "X40.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Outros Locais Especificados",
            "X41.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Residência",
            "X41 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte",
            "X41.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X41.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Habitação Coletiva",
            "X41.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Rua e Estrada",
            "X41.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Área Para a Prática de Esportes e Atletismo",
            "X41.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Áreas Industriais e em Construção",
            "X41.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Áreas de Comércio e de Serviços",
            "X41.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Outros Locais Especificados",
            "X41.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Fazenda",
            "X42 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte",
            "X41.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificadas em Outra Parte - Local Não Especificado",
            "X42.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Habitação Coletiva",
            "X42.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Residência",
            "X42.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Área Para a Prática de Esportes e Atletismo",
            "X42.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X42.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Áreas de Comércio e de Serviços",
            "X42.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Rua e Estrada",
            "X42.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Fazenda",
            "X42.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Áreas Industriais e em Construção",
            "X42.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Local Não Especificado",
            "X42.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Outros Locais Especificados",
            "X43.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Residência",
            "X43 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo",
            "X43.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X43.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Habitação Coletiva",
            "X43.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Rua e Estrada",
            "X43.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Área Para a Prática de Esportes e Atletismo",
            "X43.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Áreas Industriais e em Construção",
            "X43.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Áreas de Comércio e de Serviços",
            "X43.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Outros Locais Especificados",
            "X43.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Fazenda",
            "X44 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas",
            "X43.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Local Não Especificado",
            "X44.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Habitação Coletiva",
            "X44.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Residência",
            "X44.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "X44.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X44.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Áreas de Comércio e de Serviços",
            "X44.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Rua e Estrada",
            "X44.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Fazenda",
            "X44.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Áreas Industriais e em Construção",
            "X44.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Local Não Especificado",
            "X44.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas Não Especificadas - Outros Locais Especificados",
            "X45.0 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Residência",
            "X45 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool",
            "X45.2 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X45.1 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Habitação Coletiva",
            "X45.4 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Rua e Estrada",
            "X45.3 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Área Para a Prática de Esportes e Atletismo",
            "X45.6 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Áreas Industriais e em Construção",
            "X45.5 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Áreas de Comércio e de Serviços",
            "X45.8 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Outros Locais Especificados",
            "X45.7 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Fazenda",
            "X46 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores",
            "X45.9 - Envenenamento (intoxicação) Acidental Por e Exposição ao Álcool - Local Não Especificado",
            "X46.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Habitação Coletiva",
            "X46.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Residência",
            "X46.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Área Para a Prática de Esportes e Atletismo",
            "X46.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X46.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Áreas de Comércio e de Serviços",
            "X46.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Rua e Estrada",
            "X46.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Fazenda",
            "X46.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Áreas Industriais e em Construção",
            "X46.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Local Não Especificado",
            "X46.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores - Outros Locais Especificados",
            "X47.0 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Residência",
            "X47 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores",
            "X47.2 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X47.1 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Habitação Coletiva",
            "X47.4 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Rua e Estrada",
            "X47.3 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Área Para a Prática de Esportes e Atletismo",
            "X47.6 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Áreas Industriais e em Construção",
            "X47.5 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Áreas de Comércio e de Serviços",
            "X47.8 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Outros Locais Especificados",
            "X47.7 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Fazenda",
            "X48 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas",
            "X47.9 - Intoxicação Acidental Por e Exposição a Outros Gases e Vapores - Local Não Especificado",
            "X48.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Habitação Coletiva",
            "X48.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Residência",
            "X48.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Área Para a Prática de Esportes e Atletismo",
            "X48.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X48.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Áreas de Comércio e de Serviços",
            "X48.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Rua e Estrada",
            "X48.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Fazenda",
            "X48.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Áreas Industriais e em Construção",
            "X48.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Local Não Especificado",
            "X48.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Pesticidas - Outros Locais Especificados",
            "X49.0 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Residência",
            "X49 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas",
            "X49.2 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X49.1 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Habitação Coletiva",
            "X49.4 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Rua e Estrada",
            "X49.3 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "X49.6 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Áreas Industriais e em Construção",
            "X49.5 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Áreas de Comércio e de Serviços",
            "X49.8 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Outros Locais Especificados",
            "X49.7 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Fazenda",
            "X50 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos",
            "X49.9 - Envenenamento (intoxicação) Acidental Por e Exposição a Outras Substâncias Químicas Nocivas e às Não Especificadas - Local Não Especificado",
            "X50.1 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Habitação Coletiva",
            "X50.0 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Residência",
            "X50.3 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Área Para a Prática de Esportes e Atletismo",
            "X50.2 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X50.5 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Áreas de Comércio e de Serviços",
            "X50.4 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Rua e Estrada",
            "X50.7 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Fazenda",
            "X50.6 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Áreas Industriais e em Construção",
            "X50.9 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Local Não Especificado",
            "X50.8 - Excesso de Exercícios e Movimentos Vigorosos ou Repetitivos - Outros Locais Especificados",
            "X51.0 - Viagem e Movimento - Residência",
            "X51 - Viagem e Movimento",
            "X51.2 - Viagem e Movimento - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X51.1 - Viagem e Movimento - Habitação Coletiva",
            "X51.4 - Viagem e Movimento - Rua e Estrada",
            "X51.3 - Viagem e Movimento - Área Para a Prática de Esportes e Atletismo",
            "X51.6 - Viagem e Movimento - Áreas Industriais e em Construção",
            "X51.5 - Viagem e Movimento - Áreas de Comércio e de Serviços",
            "X51.8 - Viagem e Movimento - Outros Locais Especificados",
            "X51.7 - Viagem e Movimento - Fazenda",
            "X52 - Estadia Prolongada em Ambiente Agravitacional",
            "X51.9 - Viagem e Movimento - Local Não Especificado",
            "X52.1 - Estadia Prolongada em Ambiente Agravitacional - Habitação Coletiva",
            "X52.0 - Estadia Prolongada em Ambiente Agravitacional - Residência",
            "X52.3 - Estadia Prolongada em Ambiente Agravitacional - Área Para a Prática de Esportes e Atletismo",
            "X52.2 - Estadia Prolongada em Ambiente Agravitacional - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X52.5 - Estadia Prolongada em Ambiente Agravitacional - Áreas de Comércio e de Serviços",
            "X52.4 - Estadia Prolongada em Ambiente Agravitacional - Rua e Estrada",
            "X52.7 - Estadia Prolongada em Ambiente Agravitacional - Fazenda",
            "X52.6 - Estadia Prolongada em Ambiente Agravitacional - Áreas Industriais e em Construção",
            "X52.9 - Estadia Prolongada em Ambiente Agravitacional - Local Não Especificado",
            "X52.8 - Estadia Prolongada em Ambiente Agravitacional - Outros Locais Especificados",
            "X53.0 - Falta de Alimento - Residência",
            "X53 - Falta de Alimento",
            "X53.2 - Falta de Alimento - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X53.1 - Falta de Alimento - Habitação Coletiva",
            "X53.4 - Falta de Alimento - Rua e Estrada",
            "X53.3 - Falta de Alimento - Área Para a Prática de Esportes e Atletismo",
            "X53.6 - Falta de Alimento - Áreas Industriais e em Construção",
            "X53.5 - Falta de Alimento - Áreas de Comércio e de Serviços",
            "X53.8 - Falta de Alimento - Outros Locais Especificados",
            "X53.7 - Falta de Alimento - Fazenda",
            "X54 - Falta de Água",
            "X53.9 - Falta de Alimento - Local Não Especificado",
            "X54.1 - Falta de Água - Habitação Coletiva",
            "X54.0 - Falta de Água - Residência",
            "X54.3 - Falta de Água - Área Para a Prática de Esportes e Atletismo",
            "X54.2 - Falta de Água - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X54.5 - Falta de Água - Áreas de Comércio e de Serviços",
            "X54.4 - Falta de Água - Rua e Estrada",
            "X54.7 - Falta de Água - Fazenda",
            "X54.6 - Falta de Água - Áreas Industriais e em Construção",
            "X54.9 - Falta de Água - Local Não Especificado",
            "X54.8 - Falta de Água - Outros Locais Especificados",
            "X57.0 - Privação Não Especificada - Residência",
            "X57 - Privação Não Especificada",
            "X57.2 - Privação Não Especificada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X57.1 - Privação Não Especificada - Habitação Coletiva",
            "X57.4 - Privação Não Especificada - Rua e Estrada",
            "X57.3 - Privação Não Especificada - Área Para a Prática de Esportes e Atletismo",
            "X57.6 - Privação Não Especificada - Áreas Industriais e em Construção",
            "X57.5 - Privação Não Especificada - Áreas de Comércio e de Serviços",
            "X57.8 - Privação Não Especificada - Outros Locais Especificados",
            "X57.7 - Privação Não Especificada - Fazenda",
            "X58 - Exposição a Outros Fatores Especificados",
            "X57.9 - Privação Não Especificada - Local Não Especificado",
            "X58.1 - Exposição a Outros Fatores Especificados - Habitação Coletiva",
            "X58.0 - Exposição a Outros Fatores Especificados - Residência",
            "X58.3 - Exposição a Outros Fatores Especificados - Área Para a Prática de Esportes e Atletismo",
            "X58.2 - Exposição a Outros Fatores Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X58.5 - Exposição a Outros Fatores Especificados - Áreas de Comércio e de Serviços",
            "X58.4 - Exposição a Outros Fatores Especificados - Rua e Estrada",
            "X58.7 - Exposição a Outros Fatores Especificados - Fazenda",
            "X58.6 - Exposição a Outros Fatores Especificados - Áreas Industriais e em Construção",
            "X58.9 - Exposição a Outros Fatores Especificados - Local Não Especificado",
            "X58.8 - Exposição a Outros Fatores Especificados - Outros Locais Especificados",
            "X59.0 - Exposição a Fator Não Especificado Causando Fratura",
            "X59 - Exposição a Fatores Não Especificados",
            "X60 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos",
            "X59.9 - Exposição a Fator Não Especificado Causando Outro Traumatismo e Traumatismo Não Especificado",
            "X60.1 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Habitação Coletiva",
            "X60.0 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Residência",
            "X60.3 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Área Para a Prática de Esportes e Atletismo",
            "X60.2 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X60.5 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Áreas de Comércio e de Serviços",
            "X60.4 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Rua e Estrada",
            "X60.7 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Fazenda",
            "X60.6 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Áreas Industriais e em Construção",
            "X60.9 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Local Não Especificado",
            "X60.8 - Auto-intoxicação Por e Exposição, Intencional, a Analgésicos, Antipiréticos e Anti-reumáticos, Não-opiáceos - Outros Locais Especificados",
            "X61.0 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Residência",
            "X61 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte",
            "X61.2 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X61.1 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Habitação Coletiva",
            "X61.4 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Rua e Estrada",
            "X61.3 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Área Para a Prática de Esportes e Atletismo",
            "X61.6 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Áreas Industriais e em Construção",
            "X61.5 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Áreas de Comércio e de Serviços",
            "X61.8 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Outros Locais Especificados",
            "X61.7 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Fazenda",
            "X62 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte",
            "X61.9 - Auto-intoxicação Por e Exposição, Intencional, a Drogas Anticonvulsivantes (antiepilépticos) Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte - Local Não Especificado",
            "X62.1 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Habitação Coletiva",
            "X62.0 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Residência",
            "X62.3 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Área Para a Prática de Esportes e Atletismo",
            "X62.2 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X62.5 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Áreas de Comércio e de Serviços",
            "X62.4 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Rua e Estrada",
            "X62.7 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Fazenda",
            "X62.6 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Áreas Industriais e em Construção",
            "X62.9 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Local Não Especificado",
            "X62.8 - Auto-intoxicação Por e Exposição, Intencional, a Narcóticos e Psicodislépticos (alucinógenos) Não Classificados em Outra Parte - Outros Locais Especificados",
            "X63.0 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Residência",
            "X63 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo",
            "X63.2 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X63.1 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Habitação Coletiva",
            "X63.4 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Rua e Estrada",
            "X63.3 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Área Para a Prática de Esportes e Atletismo",
            "X63.6 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Áreas Industriais e em Construção",
            "X63.5 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Áreas de Comércio e de Serviços",
            "X63.8 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Outros Locais Especificados",
            "X63.7 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Fazenda",
            "X64 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas",
            "X63.9 - Auto-intoxicação Por e Exposição, Intencional, a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo - Local Não Especificado",
            "X64.1 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Habitação Coletiva",
            "X64.0 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Residência",
            "X64.3 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "X64.2 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X64.5 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Áreas de Comércio e de Serviços",
            "X64.4 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Rua e Estrada",
            "X64.7 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Fazenda",
            "X64.6 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Áreas Industriais e em Construção",
            "X64.9 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Local Não Especificado",
            "X64.8 - Auto-intoxicação Por e Exposição, Intencional, a Outras Drogas, Medicamentos e Substâncias Biológicas e às Não Especificadas - Outros Locais Especificados",
            "X65.0 - Auto-intoxicação Voluntária Por Álcool - Residência",
            "X65 - Auto-intoxicação Voluntária Por Álcool",
            "X65.2 - Auto-intoxicação Voluntária Por Álcool - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X65.1 - Auto-intoxicação Voluntária Por Álcool - Habitação Coletiva",
            "X65.4 - Auto-intoxicação Voluntária Por Álcool - Rua e Estrada",
            "X65.3 - Auto-intoxicação Voluntária Por Álcool - Área Para a Prática de Esportes e Atletismo",
            "X65.6 - Auto-intoxicação Voluntária Por Álcool - Áreas Industriais e em Construção",
            "X65.5 - Auto-intoxicação Voluntária Por Álcool - Áreas de Comércio e de Serviços",
            "X65.8 - Auto-intoxicação Voluntária Por Álcool - Outros Locais Especificados",
            "X65.7 - Auto-intoxicação Voluntária Por Álcool - Fazenda",
            "X66 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores",
            "X65.9 - Auto-intoxicação Voluntária Por Álcool - Local Não Especificado",
            "X66.1 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Habitação Coletiva",
            "X66.0 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Residência",
            "X66.3 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Área Para a Prática de Esportes e Atletismo",
            "X66.2 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X66.5 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Áreas de Comércio e de Serviços",
            "X66.4 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Rua e Estrada",
            "X66.7 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Fazenda",
            "X66.6 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Áreas Industriais e em Construção",
            "X66.9 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Local Não Especificado",
            "X66.8 - Auto-intoxicação Intencional Por Solventes Orgânicos, Hidrocarbonetos Halogenados e Seus Vapores - Outros Locais Especificados",
            "X67.0 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Residência",
            "X67 - Auto-intoxicação Intencional Por Outros Gases e Vapores",
            "X67.2 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X67.1 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Habitação Coletiva",
            "X67.4 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Rua e Estrada",
            "X67.3 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Área Para a Prática de Esportes e Atletismo",
            "X67.6 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Áreas Industriais e em Construção",
            "X67.5 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Áreas de Comércio e de Serviços",
            "X67.8 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Outros Locais Especificados",
            "X67.7 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Fazenda",
            "X68 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas",
            "X67.9 - Auto-intoxicação Intencional Por Outros Gases e Vapores - Local Não Especificado",
            "X68.1 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Habitação Coletiva",
            "X68.0 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Residência",
            "X68.3 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Área Para a Prática de Esportes e Atletismo",
            "X68.2 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X68.5 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Áreas de Comércio e de Serviços",
            "X68.4 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Rua e Estrada",
            "X68.7 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Fazenda",
            "X68.6 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Áreas Industriais e em Construção",
            "X68.9 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Local Não Especificado",
            "X68.8 - Auto-intoxicação Por e Exposição, Intencional, a Pesticidas - Outros Locais Especificados",
            "X69.0 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Residência",
            "X69 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas",
            "X69.2 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X69.1 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Habitação Coletiva",
            "X69.4 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Rua e Estrada",
            "X69.3 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Área Para a Prática de Esportes e Atletismo",
            "X69.6 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Áreas Industriais e em Construção",
            "X69.5 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Áreas de Comércio e de Serviços",
            "X69.8 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Outros Locais Especificados",
            "X69.7 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Fazenda",
            "X70 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação",
            "X69.9 - Auto-intoxicação Por e Exposição, Intencional, a Outros Produtos Químicos e Substâncias Nocivas Não Especificadas - Local Não Especificado",
            "X70.1 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Habitação Coletiva",
            "X70.0 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Residência",
            "X70.3 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Área Para a Prática de Esportes e Atletismo",
            "X70.2 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X70.5 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Áreas de Comércio e de Serviços",
            "X70.4 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Rua e Estrada",
            "X70.7 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Fazenda",
            "X70.6 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Áreas Industriais e em Construção",
            "X70.9 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Local Não Especificado",
            "X70.8 - Lesão Autoprovocada Intencionalmente Por Enforcamento, Estrangulamento e Sufocação - Outros Locais Especificados",
            "X71.0 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Residência",
            "X71 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão",
            "X71.2 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X71.1 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Habitação Coletiva",
            "X71.4 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Rua e Estrada",
            "X71.3 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Área Para a Prática de Esportes e Atletismo",
            "X71.6 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Áreas Industriais e em Construção",
            "X71.5 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Áreas de Comércio e de Serviços",
            "X71.8 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Outros Locais Especificados",
            "X71.7 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Fazenda",
            "X72 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão",
            "X71.9 - Lesão Autoprovocada Intencionalmente Por Afogamento e Submersão - Local Não Especificado",
            "X72.1 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Habitação Coletiva",
            "X72.0 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Residência",
            "X72.3 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Área Para a Prática de Esportes e Atletismo",
            "X72.2 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X72.5 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Áreas de Comércio e de Serviços",
            "X72.4 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Rua e Estrada",
            "X72.7 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Fazenda",
            "X72.6 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Áreas Industriais e em Construção",
            "X72.9 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Local Não Especificado",
            "X72.8 - Lesão Autoprovocada Intencionalmente Por Disparo de Arma de Fogo de Mão - Outros Locais Especificados",
            "X73.0 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Residência",
            "X73 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre",
            "X73.2 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X73.1 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Habitação Coletiva",
            "X73.4 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Rua e Estrada",
            "X73.3 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Área Para a Prática de Esportes e Atletismo",
            "X73.6 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Áreas Industriais e em Construção",
            "X73.5 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Áreas de Comércio e de Serviços",
            "X73.8 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Outros Locais Especificados",
            "X73.7 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Fazenda",
            "X74 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada",
            "X73.9 - Lesão Autoprovocada Intencionalmente Por Disparo de Espingarda, Carabina, ou Arma de Fogo de Maior Calibre - Local Não Especificado",
            "X74.1 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Habitação Coletiva",
            "X74.0 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Residência",
            "X74.3 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Área Para a Prática de Esportes e Atletismo",
            "X74.2 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X74.5 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Áreas de Comércio e de Serviços",
            "X74.4 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Rua e Estrada",
            "X74.7 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Fazenda",
            "X74.6 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Áreas Industriais e em Construção",
            "X74.9 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Local Não Especificado",
            "X74.8 - Lesão Autoprovocada Intencionalmente Por Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada - Outros Locais Especificados",
            "X75.0 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Residência",
            "X75 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos",
            "X75.2 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X75.1 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Habitação Coletiva",
            "X75.4 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Rua e Estrada",
            "X75.3 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Área Para a Prática de Esportes e Atletismo",
            "X75.6 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Áreas Industriais e em Construção",
            "X75.5 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Áreas de Comércio e de Serviços",
            "X75.8 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Outros Locais Especificados",
            "X75.7 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Fazenda",
            "X76 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas",
            "X75.9 - Lesão Autoprovocada Intencionalmente Por Dispositivos Explosivos - Local Não Especificado",
            "X76.1 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Habitação Coletiva",
            "X76.0 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Residência",
            "X76.3 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Área Para a Prática de Esportes e Atletismo",
            "X76.2 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X76.5 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Áreas de Comércio e de Serviços",
            "X76.4 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Rua e Estrada",
            "X76.7 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Fazenda",
            "X76.6 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Áreas Industriais e em Construção",
            "X76.9 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Local Não Especificado",
            "X76.8 - Lesão Autoprovocada Intencionalmente Pela Fumaça, Pelo Fogo e Por Chamas - Outros Locais Especificados",
            "X77.0 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Residência",
            "X77 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes",
            "X77.2 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X77.1 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Habitação Coletiva",
            "X77.4 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Rua e Estrada",
            "X77.3 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Área Para a Prática de Esportes e Atletismo",
            "X77.6 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Áreas Industriais e em Construção",
            "X77.5 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Áreas de Comércio e de Serviços",
            "X77.8 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Outros Locais Especificados",
            "X77.7 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Fazenda",
            "X78 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante",
            "X77.9 - Lesão Autoprovocada Intencionalmente Por Vapor de Água, Gases ou Objetos Quentes - Local Não Especificado",
            "X78.1 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Habitação Coletiva",
            "X78.0 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Residência",
            "X78.3 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Área Para a Prática de Esportes e Atletismo",
            "X78.2 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X78.5 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Áreas de Comércio e de Serviços",
            "X78.4 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Rua e Estrada",
            "X78.7 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Fazenda",
            "X78.6 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Áreas Industriais e em Construção",
            "X78.9 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Local Não Especificado",
            "X78.8 - Lesão Autoprovocada Intencionalmente Por Objeto Cortante ou Penetrante - Outros Locais Especificados",
            "X79.0 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Residência",
            "X79 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente",
            "X79.2 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X79.1 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Habitação Coletiva",
            "X79.4 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Rua e Estrada",
            "X79.3 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Área Para a Prática de Esportes e Atletismo",
            "X79.6 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Áreas Industriais e em Construção",
            "X79.5 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Áreas de Comércio e de Serviços",
            "X79.8 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Outros Locais Especificados",
            "X79.7 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Fazenda",
            "X80 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado",
            "X79.9 - Lesão Autoprovocada Intencionalmente Por Objeto Contundente - Local Não Especificado",
            "X80.1 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Habitação Coletiva",
            "X80.0 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Residência",
            "X80.3 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Área Para a Prática de Esportes e Atletismo",
            "X80.2 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X80.5 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Áreas de Comércio e de Serviços",
            "X80.4 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Rua e Estrada",
            "X80.7 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Fazenda",
            "X80.6 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Áreas Industriais e em Construção",
            "X80.9 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Local Não Especificado",
            "X80.8 - Lesão Autoprovocada Intencionalmente Por Precipitação de um Lugar Elevado - Outros Locais Especificados",
            "X81.0 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Residência",
            "X81 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento",
            "X81.2 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X81.1 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Habitação Coletiva",
            "X81.4 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Rua e Estrada",
            "X81.3 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Área Para a Prática de Esportes e Atletismo",
            "X81.6 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Áreas Industriais e em Construção",
            "X81.5 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Áreas de Comércio e de Serviços",
            "X81.8 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Outros Locais Especificados",
            "X81.7 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Fazenda",
            "X82 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor",
            "X81.9 - Lesão Autoprovocada Intencionalmente Por Precipitação ou Permanência Diante de um Objeto em Movimento - Local Não Especificado",
            "X82.1 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Habitação Coletiva",
            "X82.0 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Residência",
            "X82.3 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Área Para a Prática de Esportes e Atletismo",
            "X82.2 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X82.5 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Áreas de Comércio e de Serviços",
            "X82.4 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Rua e Estrada",
            "X82.7 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Fazenda",
            "X82.6 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Áreas Industriais e em Construção",
            "X82.9 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Local Não Especificado",
            "X82.8 - Lesão Autoprovocada Intencionalmente Por Impacto de um Veículo a Motor - Outros Locais Especificados",
            "X83.0 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Residência",
            "X83 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados",
            "X83.2 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X83.1 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Habitação Coletiva",
            "X83.4 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Rua e Estrada",
            "X83.3 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Área Para a Prática de Esportes e Atletismo",
            "X83.6 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Áreas Industriais e em Construção",
            "X83.5 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Áreas de Comércio e de Serviços",
            "X83.8 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Outros Locais Especificados",
            "X83.7 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Fazenda",
            "X84 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados",
            "X83.9 - Lesão Autoprovocada Intencionalmente Por Outros Meios Especificados - Local Não Especificado",
            "X84.1 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Habitação Coletiva",
            "X84.0 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Residência",
            "X84.3 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Área Para a Prática de Esportes e Atletismo",
            "X84.2 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X84.5 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Áreas de Comércio e de Serviços",
            "X84.4 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Rua e Estrada",
            "X84.7 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Fazenda",
            "X84.6 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Áreas Industriais e em Construção",
            "X84.9 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Local Não Especificado",
            "X84.8 - Lesão Autoprovocada Intencionalmente Por Meios Não Especificados - Outros Locais Especificados",
            "X85.0 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Residência",
            "X85 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas",
            "X85.2 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X85.1 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Habitação Coletiva",
            "X85.4 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Rua e Estrada",
            "X85.3 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Área Para a Prática de Esportes e Atletismo",
            "X85.6 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Áreas Industriais e em Construção",
            "X85.5 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Áreas de Comércio e de Serviços",
            "X85.8 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Outros Locais Especificados",
            "X85.7 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Fazenda",
            "X86 - Agressão Por Meio de Substâncias Corrosivas",
            "X85.9 - Agressão Por Meio de Drogas, Medicamentos e Substâncias Biológicas - Local Não Especificado",
            "X86.1 - Agressão Por Meio de Substâncias Corrosivas - Habitação Coletiva",
            "X86.0 - Agressão Por Meio de Substâncias Corrosivas - Residência",
            "X86.3 - Agressão Por Meio de Substâncias Corrosivas - Área Para a Prática de Esportes e Atletismo",
            "X86.2 - Agressão Por Meio de Substâncias Corrosivas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X86.5 - Agressão Por Meio de Substâncias Corrosivas - Áreas de Comércio e de Serviços",
            "X86.4 - Agressão Por Meio de Substâncias Corrosivas - Rua e Estrada",
            "X86.7 - Agressão Por Meio de Substâncias Corrosivas - Fazenda",
            "X86.6 - Agressão Por Meio de Substâncias Corrosivas - Áreas Industriais e em Construção",
            "X86.9 - Agressão Por Meio de Substâncias Corrosivas - Local Não Especificado",
            "X86.8 - Agressão Por Meio de Substâncias Corrosivas - Outros Locais Especificados",
            "X87.0 - Agressão Por Pesticidas - Residência",
            "X87 - Agressão Por Pesticidas",
            "X87.2 - Agressão Por Pesticidas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X87.1 - Agressão Por Pesticidas - Habitação Coletiva",
            "X87.4 - Agressão Por Pesticidas - Rua e Estrada",
            "X87.3 - Agressão Por Pesticidas - Área Para a Prática de Esportes e Atletismo",
            "X87.6 - Agressão Por Pesticidas - Áreas Industriais e em Construção",
            "X87.5 - Agressão Por Pesticidas - Áreas de Comércio e de Serviços",
            "X87.8 - Agressão Por Pesticidas - Outros Locais Especificados",
            "X87.7 - Agressão Por Pesticidas - Fazenda",
            "X88 - Agressão Por Meio de Gases e Vapores",
            "X87.9 - Agressão Por Pesticidas - Local Não Especificado",
            "X88.1 - Agressão Por Meio de Gases e Vapores - Habitação Coletiva",
            "X88.0 - Agressão Por Meio de Gases e Vapores - Residência",
            "X88.3 - Agressão Por Meio de Gases e Vapores - Área Para a Prática de Esportes e Atletismo",
            "X88.2 - Agressão Por Meio de Gases e Vapores - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X88.5 - Agressão Por Meio de Gases e Vapores - Áreas de Comércio e de Serviços",
            "X88.4 - Agressão Por Meio de Gases e Vapores - Rua e Estrada",
            "X88.7 - Agressão Por Meio de Gases e Vapores - Fazenda",
            "X88.6 - Agressão Por Meio de Gases e Vapores - Áreas Industriais e em Construção",
            "X88.9 - Agressão Por Meio de Gases e Vapores - Local Não Especificado",
            "X88.8 - Agressão Por Meio de Gases e Vapores - Outros Locais Especificados",
            "X89.0 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Residência",
            "X89 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados",
            "X89.2 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X89.1 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Habitação Coletiva",
            "X89.4 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Rua e Estrada",
            "X89.3 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Área Para a Prática de Esportes e Atletismo",
            "X89.6 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Áreas Industriais e em Construção",
            "X89.5 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Áreas de Comércio e de Serviços",
            "X89.8 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Outros Locais Especificados",
            "X89.7 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Fazenda",
            "X90 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados",
            "X89.9 - Agressão Por Meio de Outros Produtos Químicos e Substâncias Nocivas Especificados - Local Não Especificado",
            "X90.1 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Habitação Coletiva",
            "X90.0 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Residência",
            "X90.3 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Área Para a Prática de Esportes e Atletismo",
            "X90.2 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X90.5 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Áreas de Comércio e de Serviços",
            "X90.4 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Rua e Estrada",
            "X90.7 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Fazenda",
            "X90.6 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Áreas Industriais e em Construção",
            "X90.9 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Local Não Especificado",
            "X90.8 - Agressão Por Meio de Produtos Químicos e Substâncias Nocivas Não Especificados - Outros Locais Especificados",
            "X91.0 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Residência",
            "X91 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação",
            "X91.2 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X91.1 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Habitação Coletiva",
            "X91.4 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Rua e Estrada",
            "X91.3 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Área Para a Prática de Esportes e Atletismo",
            "X91.6 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Áreas Industriais e em Construção",
            "X91.5 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Áreas de Comércio e de Serviços",
            "X91.8 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Outros Locais Especificados",
            "X91.7 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Fazenda",
            "X92 - Agressão Por Meio de Afogamento e Submersão",
            "X91.9 - Agressão Por Meio de Enforcamento, Estrangulamento e Sufocação - Local Não Especificado",
            "X92.1 - Agressão Por Meio de Afogamento e Submersão - Habitação Coletiva",
            "X92.0 - Agressão Por Meio de Afogamento e Submersão - Residência",
            "X92.3 - Agressão Por Meio de Afogamento e Submersão - Área Para a Prática de Esportes e Atletismo",
            "X92.2 - Agressão Por Meio de Afogamento e Submersão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X92.5 - Agressão Por Meio de Afogamento e Submersão - Áreas de Comércio e de Serviços",
            "X92.4 - Agressão Por Meio de Afogamento e Submersão - Rua e Estrada",
            "X92.7 - Agressão Por Meio de Afogamento e Submersão - Fazenda",
            "X92.6 - Agressão Por Meio de Afogamento e Submersão - Áreas Industriais e em Construção",
            "X92.9 - Agressão Por Meio de Afogamento e Submersão - Local Não Especificado",
            "X92.8 - Agressão Por Meio de Afogamento e Submersão - Outros Locais Especificados",
            "X93.0 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Residência",
            "X93 - Agressão Por Meio de Disparo de Arma de Fogo de Mão",
            "X93.2 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X93.1 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Habitação Coletiva",
            "X93.4 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Rua e Estrada",
            "X93.3 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Área Para a Prática de Esportes e Atletismo",
            "X93.6 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Áreas Industriais e em Construção",
            "X93.5 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Áreas de Comércio e de Serviços",
            "X93.8 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Outros Locais Especificados",
            "X93.7 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Fazenda",
            "X94 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre",
            "X93.9 - Agressão Por Meio de Disparo de Arma de Fogo de Mão - Local Não Especificado",
            "X94.1 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Habitação Coletiva",
            "X94.0 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Residência",
            "X94.3 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Área Para a Prática de Esportes e Atletismo",
            "X94.2 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X94.5 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Áreas de Comércio e de Serviços",
            "X94.4 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Rua e Estrada",
            "X94.7 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Fazenda",
            "X94.6 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Áreas Industriais e em Construção",
            "X94.9 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Local Não Especificado",
            "X94.8 - Agressão Por Meio de Disparo de Espingarda, Carabina ou Arma de Fogo de Maior Calibre - Outros Locais Especificados",
            "X95.0 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Residência",
            "X95 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada",
            "X95.2 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X95.1 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Habitação Coletiva",
            "X95.4 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Rua e Estrada",
            "X95.3 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Área Para a Prática de Esportes e Atletismo",
            "X95.6 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Áreas Industriais e em Construção",
            "X95.5 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Áreas de Comércio e de Serviços",
            "X95.8 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Outros Locais Especificados",
            "X95.7 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Fazenda",
            "X96 - Agressão Por Meio de Material Explosivo",
            "X95.9 - Agressão Por Meio de Disparo de Outra Arma de Fogo ou de Arma Não Especificada - Local Não Especificado",
            "X96.1 - Agressão Por Meio de Material Explosivo - Habitação Coletiva",
            "X96.0 - Agressão Por Meio de Material Explosivo - Residência",
            "X96.3 - Agressão Por Meio de Material Explosivo - Área Para a Prática de Esportes e Atletismo",
            "X96.2 - Agressão Por Meio de Material Explosivo - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X96.5 - Agressão Por Meio de Material Explosivo - Áreas de Comércio e de Serviços",
            "X96.4 - Agressão Por Meio de Material Explosivo - Rua e Estrada",
            "X96.7 - Agressão Por Meio de Material Explosivo - Fazenda",
            "X96.6 - Agressão Por Meio de Material Explosivo - Áreas Industriais e em Construção",
            "X96.9 - Agressão Por Meio de Material Explosivo - Local Não Especificado",
            "X96.8 - Agressão Por Meio de Material Explosivo - Outros Locais Especificados",
            "X97.0 - Agressão Por Meio de Fumaça, Fogo e Chamas - Residência",
            "X97 - Agressão Por Meio de Fumaça, Fogo e Chamas",
            "X97.2 - Agressão Por Meio de Fumaça, Fogo e Chamas - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X97.1 - Agressão Por Meio de Fumaça, Fogo e Chamas - Habitação Coletiva",
            "X97.4 - Agressão Por Meio de Fumaça, Fogo e Chamas - Rua e Estrada",
            "X97.3 - Agressão Por Meio de Fumaça, Fogo e Chamas - Área Para a Prática de Esportes e Atletismo",
            "X97.6 - Agressão Por Meio de Fumaça, Fogo e Chamas - Áreas Industriais e em Construção",
            "X97.5 - Agressão Por Meio de Fumaça, Fogo e Chamas - Áreas de Comércio e de Serviços",
            "X97.8 - Agressão Por Meio de Fumaça, Fogo e Chamas - Outros Locais Especificados",
            "X97.7 - Agressão Por Meio de Fumaça, Fogo e Chamas - Fazenda",
            "X98 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes",
            "X97.9 - Agressão Por Meio de Fumaça, Fogo e Chamas - Local Não Especificado",
            "X98.1 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Habitação Coletiva",
            "X98.0 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Residência",
            "X98.3 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Área Para a Prática de Esportes e Atletismo",
            "X98.2 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X98.5 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Áreas de Comércio e de Serviços",
            "X98.4 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Rua e Estrada",
            "X98.7 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Fazenda",
            "X98.6 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Áreas Industriais e em Construção",
            "X98.9 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Local Não Especificado",
            "X98.8 - Agressão Por Meio de Vapor de Água, Gases ou Objetos Quentes - Outros Locais Especificados",
            "X99.0 - Agressão Por Meio de Objeto Cortante ou Penetrante - Residência",
            "X99 - Agressão Por Meio de Objeto Cortante ou Penetrante",
            "X99.2 - Agressão Por Meio de Objeto Cortante ou Penetrante - Escolas, Outras Instituições e Áreas de Administração Pública",
            "X99.1 - Agressão Por Meio de Objeto Cortante ou Penetrante - Habitação Coletiva",
            "X99.4 - Agressão Por Meio de Objeto Cortante ou Penetrante - Rua e Estrada",
            "X99.3 - Agressão Por Meio de Objeto Cortante ou Penetrante - Área Para a Prática de Esportes e Atletismo",
            "X99.6 - Agressão Por Meio de Objeto Cortante ou Penetrante - Áreas Industriais e em Construção",
            "X99.5 - Agressão Por Meio de Objeto Cortante ou Penetrante - Áreas de Comércio e de Serviços",
            "X99.8 - Agressão Por Meio de Objeto Cortante ou Penetrante - Outros Locais Especificados",
            "X99.7 - Agressão Por Meio de Objeto Cortante ou Penetrante - Fazenda",
            "Y00 - Agressão Por Meio de um Objeto Contundente",
            "X99.9 - Agressão Por Meio de Objeto Cortante ou Penetrante - Local Não Especificado",
            "Y00.1 - Agressão Por Meio de um Objeto Contundente - Habitação Coletiva",
            "Y00.0 - Agressão Por Meio de um Objeto Contundente - Residência",
            "Y00.3 - Agressão Por Meio de um Objeto Contundente - Área Para a Prática de Esportes e Atletismo",
            "Y00.2 - Agressão Por Meio de um Objeto Contundente - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y00.5 - Agressão Por Meio de um Objeto Contundente - Áreas de Comércio e de Serviços",
            "Y00.4 - Agressão Por Meio de um Objeto Contundente - Rua e Estrada",
            "Y00.7 - Agressão Por Meio de um Objeto Contundente - Fazenda",
            "Y00.6 - Agressão Por Meio de um Objeto Contundente - Áreas Industriais e em Construção",
            "Y00.9 - Agressão Por Meio de um Objeto Contundente - Local Não Especificado",
            "Y00.8 - Agressão Por Meio de um Objeto Contundente - Outros Locais Especificados",
            "Y01.0 - Agressão Por Meio de Projeção de um Lugar Elevado - Residência",
            "Y01 - Agressão Por Meio de Projeção de um Lugar Elevado",
            "Y01.2 - Agressão Por Meio de Projeção de um Lugar Elevado - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y01.1 - Agressão Por Meio de Projeção de um Lugar Elevado - Habitação Coletiva",
            "Y01.4 - Agressão Por Meio de Projeção de um Lugar Elevado - Rua e Estrada",
            "Y01.3 - Agressão Por Meio de Projeção de um Lugar Elevado - Área Para a Prática de Esportes e Atletismo",
            "Y01.6 - Agressão Por Meio de Projeção de um Lugar Elevado - Áreas Industriais e em Construção",
            "Y01.5 - Agressão Por Meio de Projeção de um Lugar Elevado - Áreas de Comércio e de Serviços",
            "Y01.8 - Agressão Por Meio de Projeção de um Lugar Elevado - Outros Locais Especificados",
            "Y01.7 - Agressão Por Meio de Projeção de um Lugar Elevado - Fazenda",
            "Y02 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento",
            "Y01.9 - Agressão Por Meio de Projeção de um Lugar Elevado - Local Não Especificado",
            "Y02.1 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Habitação Coletiva",
            "Y02.0 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Residência",
            "Y02.3 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Área Para a Prática de Esportes e Atletismo",
            "Y02.2 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y02.5 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Áreas de Comércio e de Serviços",
            "Y02.4 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Rua e Estrada",
            "Y02.7 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Fazenda",
            "Y02.6 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Áreas Industriais e em Construção",
            "Y02.9 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Local Não Especificado",
            "Y02.8 - Agressão Por Meio de Projeção ou Colocação da Vítima Diante de um Objeto em Movimento - Outros Locais Especificados",
            "Y03.0 - Agressão Por Meio de Impacto de um Veículo a Motor - Residência",
            "Y03 - Agressão Por Meio de Impacto de um Veículo a Motor",
            "Y03.2 - Agressão Por Meio de Impacto de um Veículo a Motor - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y03.1 - Agressão Por Meio de Impacto de um Veículo a Motor - Habitação Coletiva",
            "Y03.4 - Agressão Por Meio de Impacto de um Veículo a Motor - Rua e Estrada",
            "Y03.3 - Agressão Por Meio de Impacto de um Veículo a Motor - Área Para a Prática de Esportes e Atletismo",
            "Y03.6 - Agressão Por Meio de Impacto de um Veículo a Motor - Áreas Industriais e em Construção",
            "Y03.5 - Agressão Por Meio de Impacto de um Veículo a Motor - Áreas de Comércio e de Serviços",
            "Y03.8 - Agressão Por Meio de Impacto de um Veículo a Motor - Outros Locais Especificados",
            "Y03.7 - Agressão Por Meio de Impacto de um Veículo a Motor - Fazenda",
            "Y04 - Agressão Por Meio de Força Corporal",
            "Y03.9 - Agressão Por Meio de Impacto de um Veículo a Motor - Local Não Especificado",
            "Y04.1 - Agressão Por Meio de Força Corporal - Habitação Coletiva",
            "Y04.0 - Agressão Por Meio de Força Corporal - Residência",
            "Y04.3 - Agressão Por Meio de Força Corporal - Área Para a Prática de Esportes e Atletismo",
            "Y04.2 - Agressão Por Meio de Força Corporal - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y04.5 - Agressão Por Meio de Força Corporal - Áreas de Comércio e de Serviços",
            "Y04.4 - Agressão Por Meio de Força Corporal - Rua e Estrada",
            "Y04.7 - Agressão Por Meio de Força Corporal - Fazenda",
            "Y04.6 - Agressão Por Meio de Força Corporal - Áreas Industriais e em Construção",
            "Y04.9 - Agressão Por Meio de Força Corporal - Local Não Especificado",
            "Y04.8 - Agressão Por Meio de Força Corporal - Outros Locais Especificados",
            "Y05.0 - Agressão Sexual Por Meio de Força Física - Residência",
            "Y05 - Agressão Sexual Por Meio de Força Física",
            "Y05.2 - Agressão Sexual Por Meio de Força Física - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y05.1 - Agressão Sexual Por Meio de Força Física - Habitação Coletiva",
            "Y05.4 - Agressão Sexual Por Meio de Força Física - Rua e Estrada",
            "Y05.3 - Agressão Sexual Por Meio de Força Física - Área Para a Prática de Esportes e Atletismo",
            "Y05.6 - Agressão Sexual Por Meio de Força Física - Áreas Industriais e em Construção",
            "Y05.5 - Agressão Sexual Por Meio de Força Física - Áreas de Comércio e de Serviços",
            "Y05.8 - Agressão Sexual Por Meio de Força Física - Outros Locais Especificados",
            "Y05.7 - Agressão Sexual Por Meio de Força Física - Fazenda",
            "Y06 - Negligência e Abandono",
            "Y05.9 - Agressão Sexual Por Meio de Força Física - Local Não Especificado",
            "Y06.1 - Negligência e Abandono Pelos Pais",
            "Y06.0 - Negligência e Abandono Pelo Esposo ou Companheiro",
            "Y06.8 - Negligência e Abandono Por Outra Pessoa Especificada",
            "Y06.2 - Negligência e Abandono Por Conhecido ou Amigo",
            "Y07 - Outras Síndromes de Maus Tratos",
            "Y06.9 - Negligência e Abandono Por Pessoa Não Especificada",
            "Y07.1 - Outras Síndromes de Maus Tratos Pelos Pais",
            "Y07.0 - Outras Síndromes de Maus Tratos Pelo Esposo ou Companheiro",
            "Y07.3 - Outras Síndromes de Maus Tratos Por Autoridades Oficiais",
            "Y07.2 - Outras Síndromes de Maus Tratos Por Conhecido ou Amigo",
            "Y07.9 - Outras Síndromes de Maus Tratos Por Pessoa Não Especificada",
            "Y07.8 - Outras Síndromes de Maus Tratos Por Outra Pessoa Especificada",
            "Y08.0 - Agressão Por Outros Meios Especificados - Residência",
            "Y08 - Agressão Por Outros Meios Especificados",
            "Y08.2 - Agressão Por Outros Meios Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y08.1 - Agressão Por Outros Meios Especificados - Habitação Coletiva",
            "Y08.4 - Agressão Por Outros Meios Especificados - Rua e Estrada",
            "Y08.3 - Agressão Por Outros Meios Especificados - Área Para a Prática de Esportes e Atletismo",
            "Y08.6 - Agressão Por Outros Meios Especificados - Áreas Industriais e em Construção",
            "Y08.5 - Agressão Por Outros Meios Especificados - Áreas de Comércio e de Serviços",
            "Y08.8 - Agressão Por Outros Meios Especificados - Outros Locais Especificados",
            "Y08.7 - Agressão Por Outros Meios Especificados - Fazenda",
            "Y09 - Agressão Por Meios Não Especificados",
            "Y08.9 - Agressão Por Outros Meios Especificados - Local Não Especificado",
            "Y09.1 - Agressão Por Meios Não Especificados - Habitação Coletiva",
            "Y09.0 - Agressão Por Meios Não Especificados - Residência",
            "Y09.3 - Agressão Por Meios Não Especificados - Área Para a Prática de Esportes e Atletismo",
            "Y09.2 - Agressão Por Meios Não Especificados - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y09.5 - Agressão Por Meios Não Especificados - Áreas de Comércio e de Serviços",
            "Y09.4 - Agressão Por Meios Não Especificados - Rua e Estrada",
            "Y09.7 - Agressão Por Meios Não Especificados - Fazenda",
            "Y09.6 - Agressão Por Meios Não Especificados - Áreas Industriais e em Construção",
            "Y09.9 - Agressão Por Meios Não Especificados - Local Não Especificado",
            "Y09.8 - Agressão Por Meios Não Especificados - Outros Locais Especificados",
            "Y10.0 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Residência",
            "Y10 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada",
            "Y10.2 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y10.1 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Habitação Coletiva",
            "Y10.4 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Rua e Estrada",
            "Y10.3 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y10.6 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y10.5 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y10.8 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Outros Locais Especificados",
            "Y10.7 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Fazenda",
            "Y11 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada",
            "Y10.9 - Envenenamento (intoxicação) Por e Exposição a Analgésicos, Antipiréticos e Anti-reumáticos Não-opiáceos, Intenção Não Determinada - Local Não Especificado",
            "Y11.1 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Habitação Coletiva",
            "Y11.0 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Residência",
            "Y11.3 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y11.2 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y11.5 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y11.4 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Rua e Estrada",
            "Y11.7 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Fazenda",
            "Y11.6 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y11.9 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Local Não Especificado",
            "Y11.8 - Envenenamento (intoxicação) Por e Exposição a Anticonvulsivantes (antiepilépticos), Sedativos, Hipnóticos, Antiparkinsonianos e Psicotrópicos Não Classificados em Outra Parte, Intenção Não Determinada - Outros Locais Especificados",
            "Y12.0 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Residência",
            "Y12 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada",
            "Y12.2 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y12.1 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Habitação Coletiva",
            "Y12.4 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Rua e Estrada",
            "Y12.3 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y12.6 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y12.5 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y12.8 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Outros Locais Especificados",
            "Y12.7 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Fazenda",
            "Y13 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada",
            "Y12.9 - Envenenamento (intoxicação) Por e Exposição a Narcóticos e a Psicodislépticos (alucinógenos) Não Classificados em Outra Parte, Intenção Não Determinada - Local Não Especificado",
            "Y13.1 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Habitação Coletiva",
            "Y13.0 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Residência",
            "Y13.3 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y13.2 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y13.5 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y13.4 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Rua e Estrada",
            "Y13.7 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Fazenda",
            "Y13.6 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y13.9 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Local Não Especificado",
            "Y13.8 - Envenenamento (intoxicação) Por e Exposição a Outras Substâncias Farmacológicas de Ação Sobre o Sistema Nervoso Autônomo, Intenção Não Determinada - Outros Locais Especificados",
            "Y14.0 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Residência",
            "Y14 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada",
            "Y14.2 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y14.1 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Habitação Coletiva",
            "Y14.4 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Rua e Estrada",
            "Y14.3 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y14.6 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y14.5 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y14.8 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Outros Locais Especificados",
            "Y14.7 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Fazenda",
            "Y15 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada",
            "Y14.9 - Envenenamento (intoxicação) Por e Exposição a Outras Drogas, Medicamentos e Substâncias Biológicas e as Não Especificadas, Intenção Não Determinada - Local Não Especificado",
            "Y15.1 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Habitação Coletiva",
            "Y15.0 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Residência",
            "Y15.3 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y15.2 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y15.5 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y15.4 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Rua e Estrada",
            "Y15.7 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Fazenda",
            "Y15.6 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y15.9 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Local Não Especificado",
            "Y15.8 - Envenenamento (intoxicação) Por e Exposição ao Álcool, Intenção Não Determinada - Outros Locais Especificados",
            "Y16.0 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Residência",
            "Y16 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada",
            "Y16.2 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y16.1 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Habitação Coletiva",
            "Y16.4 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Rua e Estrada",
            "Y16.3 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y16.6 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y16.5 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y16.8 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Outros Locais Especificados",
            "Y16.7 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Fazenda",
            "Y17 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada",
            "Y16.9 - Envenenamento (intoxicação) Por e Exposição a Solventes Orgânicos e Hidrocarbonetos Halogenados e Seus Vapores, Intenção Não Determinada - Local Não Especificado",
            "Y17.1 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Habitação Coletiva",
            "Y17.0 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Residência",
            "Y17.3 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y17.2 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y17.5 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y17.4 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Rua e Estrada",
            "Y17.7 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Fazenda",
            "Y17.6 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y17.9 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Local Não Especificado",
            "Y17.8 - Envenenamento (intoxicação) Por e Exposição a Outros Gases e Vapores, Intenção Não Determinada - Outros Locais Especificados",
            "Y18.0 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Residência",
            "Y18 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada",
            "Y18.2 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y18.1 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Habitação Coletiva",
            "Y18.4 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Rua e Estrada",
            "Y18.3 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y18.6 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y18.5 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y18.8 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Outros Locais Especificados",
            "Y18.7 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Fazenda",
            "Y19 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada",
            "Y18.9 - Envenenamento (intoxicação) Por e Exposição a Pesticidas, Intenção Não Determinada - Local Não Especificado",
            "Y19.1 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Habitação Coletiva",
            "Y19.0 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Residência",
            "Y19.3 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y19.2 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y19.5 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y19.4 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Rua e Estrada",
            "Y19.7 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Fazenda",
            "Y19.6 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y19.9 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Local Não Especificado",
            "Y19.8 - Envenenamento (intoxicação) Por e Exposição a Outros Produtos Químicos e Substâncias Nocivas e Aos Não Especificados, Intenção Não Determinada - Outros Locais Especificados",
            "Y20.0 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Residência",
            "Y20 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada",
            "Y20.2 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y20.1 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Habitação Coletiva",
            "Y20.4 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Rua e Estrada",
            "Y20.3 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y20.6 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y20.5 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y20.8 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Outros Locais Especificados",
            "Y20.7 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Fazenda",
            "Y21 - Afogamento e Submersão, Intenção Não Determinada",
            "Y20.9 - Enforcamento, Estrangulamento e Sufocação, Intenção Não Determinada - Local Não Especificado",
            "Y21.1 - Afogamento e Submersão, Intenção Não Determinada - Habitação Coletiva",
            "Y21.0 - Afogamento e Submersão, Intenção Não Determinada - Residência",
            "Y21.3 - Afogamento e Submersão, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y21.2 - Afogamento e Submersão, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y21.5 - Afogamento e Submersão, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y21.4 - Afogamento e Submersão, Intenção Não Determinada - Rua e Estrada",
            "Y21.7 - Afogamento e Submersão, Intenção Não Determinada - Fazenda",
            "Y21.6 - Afogamento e Submersão, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y21.9 - Afogamento e Submersão, Intenção Não Determinada - Local Não Especificado",
            "Y21.8 - Afogamento e Submersão, Intenção Não Determinada - Outros Locais Especificados",
            "Y22.0 - Disparo de Pistola, Intenção Não Determinada - Residência",
            "Y22 - Disparo de Pistola, Intenção Não Determinada",
            "Y22.2 - Disparo de Pistola, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y22.1 - Disparo de Pistola, Intenção Não Determinada - Habitação Coletiva",
            "Y22.4 - Disparo de Pistola, Intenção Não Determinada - Rua e Estrada",
            "Y22.3 - Disparo de Pistola, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y22.6 - Disparo de Pistola, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y22.5 - Disparo de Pistola, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y22.8 - Disparo de Pistola, Intenção Não Determinada - Outros Locais Especificados",
            "Y22.7 - Disparo de Pistola, Intenção Não Determinada - Fazenda",
            "Y23 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada",
            "Y22.9 - Disparo de Pistola, Intenção Não Determinada - Local Não Especificado",
            "Y23.1 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Habitação Coletiva",
            "Y23.0 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Residência",
            "Y23.3 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y23.2 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y23.5 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y23.4 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Rua e Estrada",
            "Y23.7 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Fazenda",
            "Y23.6 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y23.9 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Local Não Especificado",
            "Y23.8 - Disparo de Fuzil, Carabina e Arma de Fogo de Maior Calibre, Intenção Não Determinada - Outros Locais Especificados",
            "Y24.0 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Residência",
            "Y24 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada",
            "Y24.2 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y24.1 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Habitação Coletiva",
            "Y24.4 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Rua e Estrada",
            "Y24.3 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y24.6 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y24.5 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y24.8 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Outros Locais Especificados",
            "Y24.7 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Fazenda",
            "Y25 - Contato Com Material Explosivo, Intenção Não Determinada",
            "Y24.9 - Disparo de Outra Arma de Fogo e de Arma de Fogo Não Especificada, Intenção Não Determinada - Local Não Especificado",
            "Y25.1 - Contato Com Material Explosivo, Intenção Não Determinada - Habitação Coletiva",
            "Y25.0 - Contato Com Material Explosivo, Intenção Não Determinada - Residência",
            "Y25.3 - Contato Com Material Explosivo, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y25.2 - Contato Com Material Explosivo, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y25.5 - Contato Com Material Explosivo, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y25.4 - Contato Com Material Explosivo, Intenção Não Determinada - Rua e Estrada",
            "Y25.7 - Contato Com Material Explosivo, Intenção Não Determinada - Fazenda",
            "Y25.6 - Contato Com Material Explosivo, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y25.9 - Contato Com Material Explosivo, Intenção Não Determinada - Local Não Especificado",
            "Y25.8 - Contato Com Material Explosivo, Intenção Não Determinada - Outros Locais Especificados",
            "Y26.0 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Residência",
            "Y26 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada",
            "Y26.2 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y26.1 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Habitação Coletiva",
            "Y26.4 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Rua e Estrada",
            "Y26.3 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y26.6 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y26.5 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y26.8 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Outros Locais Especificados",
            "Y26.7 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Fazenda",
            "Y27 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada",
            "Y26.9 - Exposição a Fumaça, Fogo e Chamas, Intenção Não Determinada - Local Não Especificado",
            "Y27.1 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Habitação Coletiva",
            "Y27.0 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Residência",
            "Y27.3 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y27.2 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y27.5 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y27.4 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Rua e Estrada",
            "Y27.7 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Fazenda",
            "Y27.6 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y27.9 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Local Não Especificado",
            "Y27.8 - Exposição a Vapor de Água, Gases ou Objetos Quentes, Intenção Não Determinada - Outros Locais Especificados",
            "Y28.0 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Residência",
            "Y28 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada",
            "Y28.2 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y28.1 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Habitação Coletiva",
            "Y28.4 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Rua e Estrada",
            "Y28.3 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y28.6 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y28.5 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y28.8 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Outros Locais Especificados",
            "Y28.7 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Fazenda",
            "Y29 - Contato Com Objeto Contundente, Intenção Não Determinada",
            "Y28.9 - Contato Com Objeto Cortante ou Penetrante, Intenção Não Determinada - Local Não Especificado",
            "Y29.1 - Contato Com Objeto Contundente, Intenção Não Determinada - Habitação Coletiva",
            "Y29.0 - Contato Com Objeto Contundente, Intenção Não Determinada - Residência",
            "Y29.3 - Contato Com Objeto Contundente, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y29.2 - Contato Com Objeto Contundente, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y29.5 - Contato Com Objeto Contundente, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y29.4 - Contato Com Objeto Contundente, Intenção Não Determinada - Rua e Estrada",
            "Y29.7 - Contato Com Objeto Contundente, Intenção Não Determinada - Fazenda",
            "Y29.6 - Contato Com Objeto Contundente, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y29.9 - Contato Com Objeto Contundente, Intenção Não Determinada - Local Não Especificado",
            "Y29.8 - Contato Com Objeto Contundente, Intenção Não Determinada - Outros Locais Especificados",
            "Y30.0 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Residência",
            "Y30 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada",
            "Y30.2 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y30.1 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Habitação Coletiva",
            "Y30.4 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Rua e Estrada",
            "Y30.3 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y30.6 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y30.5 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y30.8 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Outros Locais Especificados",
            "Y30.7 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Fazenda",
            "Y31 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada",
            "Y30.9 - Queda, Salto ou Empurrado de um Lugar Elevado, Intenção Não Determinada - Local Não Especificado",
            "Y31.1 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Habitação Coletiva",
            "Y31.0 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Residência",
            "Y31.3 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y31.2 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y31.5 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y31.4 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Rua e Estrada",
            "Y31.7 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Fazenda",
            "Y31.6 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y31.9 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Local Não Especificado",
            "Y31.8 - Queda, Permanência ou Corrida Diante de um Objeto em Movimento, Intenção Não Determinada - Outros Locais Especificados",
            "Y32.0 - Impacto de um Veículo a Motor, Intenção Não Determinada - Residência",
            "Y32 - Impacto de um Veículo a Motor, Intenção Não Determinada",
            "Y32.2 - Impacto de um Veículo a Motor, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y32.1 - Impacto de um Veículo a Motor, Intenção Não Determinada - Habitação Coletiva",
            "Y32.4 - Impacto de um Veículo a Motor, Intenção Não Determinada - Rua e Estrada",
            "Y32.3 - Impacto de um Veículo a Motor, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y32.6 - Impacto de um Veículo a Motor, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y32.5 - Impacto de um Veículo a Motor, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y32.8 - Impacto de um Veículo a Motor, Intenção Não Determinada - Outros Locais Especificados",
            "Y32.7 - Impacto de um Veículo a Motor, Intenção Não Determinada - Fazenda",
            "Y33 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada",
            "Y32.9 - Impacto de um Veículo a Motor, Intenção Não Determinada - Local Não Especificado",
            "Y33.1 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Habitação Coletiva",
            "Y33.0 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Residência",
            "Y33.3 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y33.2 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y33.5 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y33.4 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Rua e Estrada",
            "Y33.7 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Fazenda",
            "Y33.6 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y33.9 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Local Não Especificado",
            "Y33.8 - Outros Fatos ou Eventos Especificados, Intenção Não Determinada - Outros Locais Especificados",
            "Y34.0 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Residência",
            "Y34 - Fatos ou Eventos Não Especificados e Intenção Não Determinada",
            "Y34.2 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Escolas, Outras Instituições e Áreas de Administração Pública",
            "Y34.1 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Habitação Coletiva",
            "Y34.4 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Rua e Estrada",
            "Y34.3 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Área Para a Prática de Esportes e Atletismo",
            "Y34.6 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Áreas Industriais e em Construção",
            "Y34.5 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Áreas de Comércio e de Serviços",
            "Y34.8 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Outros Locais Especificados",
            "Y34.7 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Fazenda",
            "Y35 - Intervenção Legal",
            "Y34.9 - Fatos ou Eventos Não Especificados e Intenção Não Determinada - Local Não Especificado",
            "Y35.1 - Intervenção Legal Envolvendo o Uso de Explosivos",
            "Y35.0 - Intervenção Legal Envolvendo o Uso de Armas de Fogo",
            "Y35.3 - Intervenção Legal Envolvendo o Uso de Objetos Contundentes",
            "Y35.2 - Intervenção Legal Envolvendo o Uso de Gás",
            "Y35.5 - Execução Legal",
            "Y35.4 - Intervenção Legal Envolvendo o Uso de Objetos Cortantes e Penetrantes",
            "Y35.7 - Intervenção Legal, Meio Não Especificado",
            "Y35.6 - Intervenção Legal Envolvendo o Uso de Outros Meios Especificados",
            "Y36.0 - Operações de Guerra Envolvendo Explosão de Armamento Naval",
            "Y36 - Operações de Guerra",
            "Y36.2 - Operações de Guerra Envolvendo Outras Explosões ou Fragmentos",
            "Y36.1 - Operações de Guerra Envolvendo Destruição de Aeronave",
            "Y36.4 - Operações de Guerra Envolvendo Disparo de Armas de Fogo e de Outras Formas Convencionais de Combate",
            "Y36.3 - Operações de Guerra Envolvendo Incêndio, Fogo e Substâncias Quentes",
            "Y36.6 - Operações de Guerra Envolvendo Armas Biológicas",
            "Y36.5 - Operações de Guerra Envolvendo Armamento Nuclear",
            "Y36.8 - Operações de Guerra Ocorridas Após a Cessação Das Hostilidades",
            "Y36.7 - Operações de Guerra Envolvendo Armas Químicas e Outros Meios de Combate Não-convencionais",
            "Y40 - Efeitos Adversos de Antibióticos Sistêmicos",
            "Y36.9 - Operações de Guerra Não Especificadas",
            "Y40.1 - Efeitos Adversos de Cefalosporinas e Outras Betalactaminas",
            "Y40.0 - Efeitos Adversos de Penicilinas",
            "Y40.3 - Efeitos Adversos de Macrolídeos",
            "Y40.2 - Efeitos Adversos do Grupo do Cloranfenicol",
            "Y40.5 - Efeitos Adversos de Aminoglicosídeos",
            "Y40.4 - Efeitos Adversos de Tetraciclinas",
            "Y40.7 - Efeitos Adversos de Antibióticos Antifúngicos Administrados Por Via Sistêmica",
            "Y40.6 - Efeitos Adversos de Rifamicinas",
            "Y40.9 - Efeitos Adversos de Antibiótico Sistêmico, Não Especificado",
            "Y40.8 - Efeitos Adversos de Outros Antibióticos Administrados Por Via Sistêmica",
            "Y41.0 - Efeitos Adversos de Sulfonamidas",
            "Y41 - Efeitos Adversos de Outros Antiinfecciosos e Antiparasitários Sistêmicos",
            "Y41.2 - Efeitos Adversos de Antimaláricos e Drogas Que Atuam Sobre Outros Protozoários Parasitas do Sangue",
            "Y41.1 - Efeitos Adversos de Drogas Antimicobacterianas",
            "Y41.4 - Efeitos Adversos de Anti-helmínticos",
            "Y41.3 - Efeitos Adversos de Outras Drogas Antiprotozoárias",
            "Y41.8 - Efeitos Adversos de Outros Antiinfecciosos e Antiparasitários Especificados",
            "Y41.5 - Efeitos Adversos de Drogas Antivirais",
            "Y42 - Efeitos Adversos de Hormônios e Seus Substitutos Sintéticos e Antagonistas, Não Classificados em Outra Parte",
            "Y41.9 - Efeitos Adversos de Antiinfecciosos e Antiparasitários Sistêmicos Não Especificados",
            "Y42.1 - Efeitos Adversos de Hormônios e Substitutos da Tireóide",
            "Y42.0 - Efeitos Adversos de Glicocorticóides e Análogos Sintéticos",
            "Y42.3 - Efeitos Adversos de Insulina e Hipoglicêmicos Orais (antidiabéticos)",
            "Y42.2 - Efeitos Adversos de Drogas Antitireoidianas",
            "Y42.5 - Efeitos Adversos de Outros Estrógenos e Progestágenos",
            "Y42.4 - Efeitos Adversos de Anticoncepcionais (contraceptivos) Orais",
            "Y42.7 - Efeitos Adversos de Andrógenos e Anabolizantes Congêneres",
            "Y42.6 - Efeitos Adversos de Antigonadotrofinas, Antiestrógenos e Antiandrógenos, Não Classificados em Outra Parte",
            "Y42.9 - Efeitos Adversos de Outros Antagonistas Hormonais e os Não Especificados",
            "Y42.8 - Efeitos Adversos de Outros Hormônios e os Não Especificados e Seus Substitutos Sintéticos",
            "Y43.0 - Efeitos Adversos de Drogas Antialérgicas e Antieméticas",
            "Y43 - Efeitos Adversos de Substâncias de Ação Primariamente Sistêmica",
            "Y43.2 - Efeitos Adversos de Produtos Naturais Antineoplásicos",
            "Y43.1 - Efeitos Adversos de Antimetabólicos Antineoplásicos",
            "Y43.4 - Efeitos Adversos de Agentes Imunossupressores",
            "Y43.3 - Efeitos Adversos de Outras Drogas Antineoplásicas",
            "Y43.6 - Efeitos Adversos de Enzimas, Não Classificadas em Outra Parte",
            "Y43.5 - Efeitos Adversos de Acidificantes e Alcalinizantes",
            "Y43.9 - Efeitos Adversos de Substância Farmacológica de Ação Sistêmica, Não Especificada",
            "Y43.8 - Efeitos Adversos de Outras Substâncias Farmacológicas de Ação Primariamente Sistêmica, Não Classificadas em Outra Parte",
            "Y44.0 - Efeitos Adversos de Preparações a Base de Ferro e Outros Preparações Prescritas na Anemia Hipocrômica",
            "Y44 - Efeitos Adversos de Substâncias Farmacológicas Que Atuam Primariamente Sobre os Constituintes do Sangue",
            "Y44.2 - Efeitos Adversos de Anticoagulantes",
            "Y44.1 - Efeitos Adversos da Vitamina B12, Ácido Fólico e Outros Preparações Prescritas Nas Anemias Megaloblásticas",
            "Y44.4 - Efeitos Adversos de Drogas Antitrombóticas (inibidores da Agregação de Plaquetas)",
            "Y44.3 - Efeitos Adversos de Antagonistas de Anticoagulantes, Vitamina K e Outros Coagulantes",
            "Y44.6 - Efeitos Adversos do Sangue Natural e Produtos do Sangue",
            "Y44.5 - Efeitos Adversos de Trombolíticos",
            "Y44.9 - Efeitos Adversos de Outras Substâncias Farmacológicas Que Atuam Sobre os Constituintes do Sangue e as Não Especificadas",
            "Y44.7 - Efeitos Adversos de Substitutos do Plasma",
            "Y45.0 - Efeitos Adversos de Opiáceos e Analgésicos Correlatos",
            "Y45 - Efeitos Adversos de Substâncias Analgésicas, Antipiréticas e Antiinflamatórias",
            "Y45.2 - Efeitos Adversos de Derivados do Ácido Propiônico",
            "Y45.1 - Efeitos Adversos de Salicilatos",
            "Y45.4 - Efeitos Adversos de Anti-reumáticos",
            "Y45.3 - Efeitos Adversos de Outras Drogas Antiinflamatórias Não-esteróides",
            "Y45.8 - Efeitos Adversos de Outros Analgésicos e Antipiréticos",
            "Y45.5 - Efeitos Adversos de Derivados do 4-aminofenol",
            "Y46 - Efeitos Adversos de Drogas Anticonvulsivantes (antiepilépticas) e Antiparkinsonianas",
            "Y45.9 - Efeitos Adversos de Substâncias Analgésicas e Antipiréticas Não Especificadas",
            "Y46.1 - Efeitos Adversos de Oxazolidínas",
            "Y46.0 - Efeitos Adversos de Succimidas",
            "Y46.3 - Efeitos Adversos de Desoxibarbitúricos",
            "Y46.2 - Efeitos Adversos de Derivados da Hidantoína",
            "Y46.5 - Efeitos Adversos do Ácido Valpróico",
            "Y46.4 - Efeitos Adversos de Iminostilbenos",
            "Y46.7 - Efeitos Adversos de Drogas Antiparkinsonianas",
            "Y46.6 - Efeitos Adversos de Outros Anticonvulsivantes (antiepilépticos) e os Não Especificados",
            "Y47 - Efeitos Adversos de Sedativos, Hipnóticos e Tranquilizantes (ansiolíticos)",
            "Y46.8 - Efeitos Adversos de Substâncias Miorrelaxantes (anti-espásticas)",
            "Y47.1 - Efeitos Adversos de Benzodiazepínicos",
            "Y47.0 - Efeitos Adversos de Barbitúricos, Não Classificados em Outra Parte",
            "Y47.3 - Efeitos Adversos de Paraldeído",
            "Y47.2 - Efeitos Adversos de Derivados do Cloral",
            "Y47.5 - Efeitos Adversos de Sedativos e Hipnóticos em Associações Não Classificados em Outra Parte",
            "Y47.4 - Efeitos Adversos de Compostos de Bromo",
            "Y47.9 - Efeitos Adversos de Sedativos, Hipnóticos e Tranquilizantes (ansiolíticos), Não Especificados",
            "Y47.8 - Efeitos Adversos de Outros Sedativos, Hipnóticos e Tranquilizantes (ansiolíticos)",
            "Y48.0 - Efeitos Adversos de Anestésicos Por Inalação",
            "Y48 - Efeitos Adversos de Anestésicos e Gases Terapêuticos",
            "Y48.2 - Efeitos Adversos de Outros Anestésicos Gerais e os Não Especificados",
            "Y48.1 - Efeitos Adversos de Anestésicos Por Via Parenteral",
            "Y48.4 - Efeitos Adversos de Anestésico, Não Especificado",
            "Y48.3 - Efeitos Adversos de Anestésicos Locais",
            "Y49 - Efeitos Adversos de Substâncias Psicotrópicas, Não Classificadas em Outra Parte",
            "Y48.5 - Efeitos Adversos de Gases Terapêuticos",
            "Y49.1 - Efeitos Adversos de Antidepressivos Inibidores da Mono-amino-oxidase",
            "Y49.0 - Efeitos Adversos de Antidepressivos Triciclos e Tetracíclicos",
            "Y49.3 - Efeitos Adversos de Antipsicóticos e Neurolépticos Fenotiazínicos",
            "Y49.2 - Efeitos Adversos de Outros Antidepressivos e os Não Especificados",
            "Y49.5 - Efeitos Adversos de Outros Antipsicóticos e Neurolépticos",
            "Y49.4 - Efeitos Adversos de Neurolépticos a Base da Butirofenona e do Tioxanteno",
            "Y49.7 - Efeitos Adversos de Psicoestimulantes Que Podem Provocar Dependência",
            "Y49.6 - Efeitos Adversos de Psicodislépticos (alucinógenos)",
            "Y49.9 - Efeitos Adversos de Substância Psicotrópica, Não Especificada",
            "Y49.8 - Efeitos Adversos de Outras Substâncias Psicotrópicas, Não Classificados em Outra Parte",
            "Y50.0 - Efeitos Adversos de Analépticos",
            "Y50 - Efeitos Adversos de Estimulantes do Sistema Nervoso Central, Não Classificados em Outra Parte",
            "Y50.2 - Efeitos Adversos de Metilxantinas, Não Classificadas em Outra Parte",
            "Y50.1 - Efeitos Adversos de Antagonistas de Receptores Dos Opiáceos",
            "Y50.9 - Efeitos Adversos de Estimulante do Sistema Nervoso Central, Não Especificado",
            "Y50.8 - Efeitos Adversos de Outros Estimulantes do Sistema Nervoso Central",
            "Y51.0 - Efeitos Adversos de Agentes Anticolinesterásicos",
            "Y51 - Efeitos Adversos de Drogas Que Atuam Primariamente Sobre o Sistema Nervoso Autônomo",
            "Y51.2 - Efeitos Adversos de Ganglioplégicos, Não Classificados em Outra Parte",
            "Y51.1 - Efeitos Adversos de Outros Parasimpaticomiméticos (colinérgicos)",
            "Y51.4 - Efeitos Adversos de Agonistas Predominantemente Alfa-adrenorreceptores, Não Classificados em Outra Parte",
            "Y51.3 - Efeitos Adversos de Outros Parassimpaticolíticos (anticolinérgicos e Antimuscarínicos) e Espasmolíticos, Não Classificados em Outra Parte",
            "Y51.6 - Efeitos Adversos de Antagonistas Alfa-adrenorreceptores, Não Classificados em Outra Parte",
            "Y51.5 - Efeitos Adversos de Agonistas Predominantemente Beta-adrenorreceptores, Não Classificados em Outra Parte",
            "Y51.8 - Efeitos Adversos de Bloqueadores Nervosos de Ação Central e Adrenérgicos, Não Classificados em Outra Parte",
            "Y51.7 - Efeitos Adversos de Antagonistas Beta-adrenorreceptores, Não Classificados em Outra Parte",
            "Y52 - Efeitos Adversos de Substâncias Que Atuam Primariamente Sobre o Aparelho Cardiovascular",
            "Y51.9 - Efeitos Adversos de Outras Drogas Que Atuam Primariamente Sobre o Sistema Nervoso Autônomo e as Não Especificadas",
            "Y52.1 - Efeitos Adversos de Antagonistas do Cálcio (inibidores do Cálcio)",
            "Y52.0 - Efeitos Adversos de Glicosídeos Estimulantes do Coração e Substâncias de Ação Semelhante",
            "Y52.3 - Efeitos Adversos de Vasodilatadores Coronarianos, Não Classificados em Outra Parte",
            "Y52.2 - Efeitos Adversos de Outras Drogas Antidisrrítmicas, Não Classificadas em Outra Parte",
            "Y52.5 - Efeitos Adversos de Outras Drogas Anti-hipertensivas, Não Classificadas em Outra Parte",
            "Y52.4 - Efeitos Adversos de Inibidores da Enzima de Conversão da Angiotensina",
            "Y52.6 - Efeitos Adversos de Drogas Anti-hiperlipidêmicas e Antiarterioscleróticas",
            "Y52.7 - Efeitos Adversos de Vasodilatadores Periféricos",
            "Y52.8 - Efeitos Adversos de Drogas Antivaricosas, Incluindo os Agentes Esclerosantes",
            "Y52.9 - Efeitos Adversos de Outras Drogas Que Atuam Primariamente Sobre o Aparelho Cardiovascular e as Não Especificadas",
            "Y53 - Efeitos Adversos de Substâncias Que Atuam Primariamente Sobre o Aparelho Gastrointestinal",
            "Y53.0 - Efeitos Adversos de Antagonistas Dos Receptores H2 da Histamina",
            "Y53.1 - Efeitos Adversos de Outros Medicamentos Antiácidos e Anti-secreção Gástrica",
            "Y53.2 - Efeitos Adversos de Laxativos Estimulantes",
            "Y53.3 - Efeitos Adversos de Laxativos Salinos e Osmóticos",
            "Y53.4 - Efeitos Adversos de Outros Laxativos",
            "Y53.5 - Efeitos Adversos de Medicamentos Que Facilitam a Digestão",
            "Y53.6 - Efeitos Adversos de Antidiarréicos",
            "Y53.7 - Efeitos Adversos de Eméticos",
            "Y53.8 - Efeitos Adversos de Outras Substâncias Que Atuam Primariamente Sobre o Aparelho Gastrointestinal",
            "Y53.9 - Efeitos Adversos de Substâncias Que Atuam Primariamente Sobre o Aparelho Gastrointestinal, Não Especificadas",
            "Y54 - Efeitos Adversos de Substâncias Que Atuam Primariamente Sobre o Metabolismo da Água, Dos Sais Minerais e do Ácido Úrico",
            "Y54.0 - Efeitos Adversos de Mineralocorticóides",
            "Y54.1 - Efeitos Adversos de Antagonistas Dos Mineralocorticóides (antagonistas da Aldosterona)",
            "Y54.2 - Efeitos Adversos de Inibidores da Anidrase Carbônica",
            "Y54.3 - Efeitos Adversos de Derivados da Benzotiadiazína",
            "Y54.4 - Efeitos Adversos de Diuréticos de Alça",
            "Y54.5 - Efeitos Adversos de Outros Diuréticos",
            "Y54.6 - Efeitos Adversos de Agentes Que Atuam Sobre o Equilíbrio Eletrolítico, Calórico e Hídrico",
            "Y54.7 - Efeitos Adversos de Medicamentos Que Atuam Sobre o Metabolismo de Cálcio",
            "Y54.8 - Efeitos Adversos de Drogas Que Atuam Sobre o Metabolismo de Ácido Úrico",
            "Y54.9 - Efeitos Adversos de Sais Minerais Não Classificados em Outra Parte",
            "Y55 - Efeitos Adversos de Substâncias Que Atuam Primariamente Sobre os Músculos Lisos e Esqueléticos e Sobre o Aparelho Respiratório",
            "Y55.0 - Efeitos Adversos de Ocitócicos",
            "Y55.1 - Efeitos Adversos de Relaxantes da Musculatura Esquelética (bloqueadores Neuromusculares) (curarizantes)",
            "Y55.2 - Efeitos Adversos de Outras Substâncias Que Atuam Primariamente Sobre os Músculos e as Não Especificadas",
            "Y55.3 - Efeitos Adversos de Antitússicos",
            "Y55.4 - Efeitos Adversos de Expectorantes",
            "Y55.5 - Efeitos Adversos de Drogas Anti-resfriado Comum",
            "Y55.6 - Efeitos Adversos de Antiasmáticos, Não Classificados em Outra Parte",
            "Y55.9 - Efeitos Adversos de Outras Drogas Que Atuam Sobre o Aparelho Respiratório e as Não Especificadas",
            "Y56 - Efeitos Adversos de Substâncias de Uso Tópico Que Atuam Primariamente Sobre a Pele e as Membranas Mucosas e Drogas de Uso Oftalmológico, Otorrinolaringológico e Dentário",
            "Y56.0 - Efeitos Adversos de Drogas Antifúngicas, Antiinfecciosas e Antiinflamatórias de Uso Local, Não Classificadas em Outra Parte",
            "Y56.1 - Efeitos Adversos de Antipruriginosos",
            "Y56.2 - Efeitos Adversos de Adstringentes e Detergentes Locais",
            "Y56.3 - Efeitos Adversos de Emolientes, Suavizantes e Protetores da Pele",
            "Y56.4 - Efeitos Adversos de Ceratolíticos, Ceratoplásticos e Outros Medicamentos e Preparações Capilares",
            "Y56.5 - Efeitos Adversos de Medicamentos e Preparações de Uso Oftalmológico",
            "Y56.6 - Efeitos Adversos de Medicamentos e Preparações Usados em Otorrinolaringologia",
            "Y56.7 - Efeitos Adversos de Drogas de Uso Dentário Aplicadas Topicamente",
            "Y56.8 - Efeitos Adversos de Outras Substâncias de Uso Tópico",
            "Y56.9 - Efeitos Adversos de Substância Não Especificada de Uso Tópico",
            "Y57 - Efeitos Adversos de Outras Drogas e Medicamentos e as Não Especificadas",
            "Y57.0 - Efeitos Adversos de Depressores do Apetite (anoréxicos)",
            "Y57.1 - Efeitos Adversos de Lipotrópicos",
            "Y57.2 - Efeitos Adversos de Antídotos e Agentes Quelantes, Não Classificados em Outra Parte",
            "Y57.3 - Efeitos Adversos de Substâncias Para Restringir a Ingestão de Álcool",
            "Y57.4 - Efeitos Adversos de Excipientes Farmacêuticos",
            "Y57.5 - Efeitos Adversos de Substâncias Utilizadas Como Contraste em Radiologia",
            "Y57.6 - Efeitos Adversos de Outras Substâncias Para Fins Diagnósticos",
            "Y57.7 - Efeitos Adversos de Vitaminas, Não Classificadas em Outra Parte",
            "Y57.8 - Efeitos Adversos de Outras Drogas e Medicamentos",
            "Y57.9 - Efeitos Adversos de Droga e Medicamento Não Especificado",
            "Y58 - Efeitos Adversos de Vacinas Bacterianas",
            "Y58.0 - Efeitos Adversos da Vacina BCG",
            "Y58.1 - Efeitos Adversos de Vacinas Antitifoídica e Antiparafoídica",
            "Y58.2 - Efeitos Adversos da Vacina Anticolérica",
            "Y58.3 - Efeitos Adversos da Vacina Antipeste",
            "Y58.4 - Efeitos Adversos da Vacina Antitetânica",
            "Y58.5 - Efeitos Adversos da Vacina Antidiftérica",
            "Y58.6 - Efeitos Adversos da Vacina Antipertussis, Incluindo as Combinações em Que um Dos Componentes é a Antipertussis",
            "Y58.8 - Efeitos Adversos de Vacinas Bacterianas Mistas, Exceto as Combinações em Que um Dos Componentes é a Antipertussis",
            "Y58.9 - Efeitos Adversos de Outras Vacinas Bacterianas e as Não Especificadas",
            "Y59 - Efeitos Adversos de Outras Vacinas e Substâncias Biológicas e as Não Especificadas",
            "Y59.0 - Efeitos Adversos de Vacinas Antivirais",
            "Y59.1 - Efeitos Adversos de Vacinas Anti-rickettsias",
            "Y59.2 - Efeitos Adversos de Vacinas Antiprotozoários",
            "Y59.3 - Efeitos Adversos da Imunoglobulina",
            "Y59.8 - Efeitos Adversos de Outras Vacinas e Substâncias Biológicas Especificadas",
            "Y59.9 - Efeitos Adversos de Vacina ou Substância Biológica, Não Especificada",
            "Y60 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante a Prestação de Cuidados Médicos ou Cirúrgicos",
            "Y60.0 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Intervenção Cirúrgica",
            "Y60.1 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Infusão ou Transfusão",
            "Y60.2 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Hemodiálise ou Outras Perfusões",
            "Y60.3 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Injeção ou Vacinação (imunização)",
            "Y60.4 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Exame Endoscópico",
            "Y60.5 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Cateterismo Cardíaco",
            "Y60.6 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante Aspiração, Punção ou Outro Tipo de Cateterização",
            "Y60.7 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante a Administração de Enema",
            "Y60.8 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante a Prestação de Outros Cuidados Cirúrgicos e Médicos",
            "Y60.9 - Corte, Punção, Perfuração ou Hemorragia Acidentais Durante a Prestação de Cuidado Cirúrgico e Médico, Não Especificados",
            "Y61 - Objeto Estranho Deixado Acidentalmente no Corpo Durante a Prestação de Cuidados Cirúrgicos e Médicos",
            "Y61.0 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Intervenção Cirúrgica",
            "Y61.1 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Infusão ou Transfusão",
            "Y61.2 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Hemodiálise ou Outras Perfusões",
            "Y61.3 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Injeção ou Vacinação (imunização)",
            "Y61.4 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Exame Endoscópico",
            "Y61.5 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Cateterismo Cardíaco",
            "Y61.6 - Objeto Estranho Deixado Acidentalmente no Corpo Durante Aspiração, Punção ou Outro Tipo de Cateterização",
            "Y61.7 - Objeto Estranho Deixado Acidentalmente no Corpo Durante a Extração de um Cateter ou de Compressas",
            "Y61.8 - Objeto Estranho Deixado Acidentalmente no Corpo Durante a Prestação de Outro Cuidado Cirúrgico e Médico",
            "Y61.9 - Objeto Estranho Deixado Acidentalmente no Corpo Durante a Prestação de Cuidado Cirúrgico e Médico Não Especificados",
            "Y62 - Assepsia Insuficiente Durante a Prestação de Cuidados Cirúrgicos e Médicos",
            "Y62.0 - Assepsia Insuficiente Durante Intervenção Cirúrgica",
            "Y62.1 - Assepsia Insuficiente Durante Infusão ou Transfusão",
            "Y62.2 - Assepsia Insuficiente Durante Hemodiálise ou Outras Perfusões",
            "Y62.3 - Assepsia Insuficiente Durante Injeção ou Vacinação (imunização)",
            "Y62.4 - Assepsia Insuficiente Durante Exame Endoscópico",
            "Y62.5 - Assepsia Insuficiente Durante Cateterismo Cardíaco",
            "Y62.6 - Assepsia Insuficiente Durante Aspiração, Punção ou Outro Tipo de Cateterização",
            "Y62.8 - Assepsia Insuficiente Durante a Prestação de Outro Cuidado Cirúrgico e Médico",
            "Y62.9 - Assepsia Insuficiente Durante a Prestação de Cuidado Cirúrgico e Médico Não Especificados",
            "Y63 - Erros de Dosagem Durante a Prestação de Cuidados Médicos e Cirúrgicos",
            "Y63.0 - Administração de Quantidade Excessiva de Sangue ou de um Outro Líquido Durante Transfusão ou Infusão",
            "Y63.1 - Diluição Incorreta de Líquido Usado Durante a Infusão",
            "Y63.2 - Superdosagem de Radiação Administrada Durante a Terapia",
            "Y63.3 - Exposição Inadvertida de Paciente a Radiação Durante a Prestação de Cuidado Médico",
            "Y63.4 - Erro de Dosagem na Terapia Por Eletrochoque ou Choque Insulínico",
            "Y63.5 - Temperatura Inadequada Durante Aplicação Local ou Curativo",
            "Y63.6 - Não Administração de Droga, Medicamento ou Substância Biológica Necessária",
            "Y63.8 - Erros de Dosagem Durante a Prestação de Outro Cuidado Médico e Cirúrgico",
            "Y63.9 - Erros de Dosagem Durante a Prestação de Cuidados Médico e Cirúrgico, Não Especificados",
            "Y64 - Medicamentos ou Substâncias Biológicas Contaminados",
            "Y64.0 - Transfusão ou Infusão de Medicamento ou Substância Biológica Contaminados",
            "Y64.1 - Injeção ou Vacinação Com Medicamento ou Substância Biológica Contaminados",
            "Y64.8 - Administração Por Outros Meios de Medicamento ou Substância Biológicas Contaminados",
            "Y64.9 - Medicamento e Substância Biológica Contaminados, Administrada Por Meios Não Especificados",
            "Y65 - Outros Acidentes Durante a Prestação de Cuidados Médicos e Cirúrgicos",
            "Y65.0 - Uso de Sangue Incompatível em Transfusão",
            "Y65.1 - Uso de Líquido Errado em Infusão",
            "Y65.2 - Falha de Sutura ou de Ligadura Durante Intervenção Cirúrgica",
            "Y65.3 - Cânula Endotraqueal Mal Posicionada Durante Procedimento Anestésico",
            "Y65.4 - Falha na Introdução ou na Remoção de Outras Cânulas ou Instrumentos",
            "Y65.5 - Realização de Uma Operação Inapropriada",
            "Y65.8 - Outros Acidentes Especificados Durante a Prestação de Cuidado Médico e Cirúrgico",
            "Y66 - Não Administração de Cuidado Médico e Cirúrgico",
            "Y69 - Acidente Não Especificado Durante a Prestação de Cuidado Médico e Cirúrgico",
            "Y70 - Dispositivos (aparelhos) de Anestesiologia, Associados a Incidentes Adversos",
            "Y70.0 - Dispositivos (aparelhos) de Anestesiologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y70.1 - Dispositivos (aparelhos) de Anestesiologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y70.2 - Dispositivos (aparelhos) de Anestesiologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y70.3 - Dispositivos (aparelhos) de Anestesiologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y70.8 - Dispositivos (aparelhos) de Anestesiologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y71 - Dispositivos (aparelhos) Cardiovasculares, Associados a Incidentes Adversos",
            "Y71.0 - Dispositivos (aparelhos) Cardiovasculares, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y71.1 - Dispositivos (aparelhos) Cardiovasculares, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y71.2 - Dispositivos (aparelhos) Cardiovasculares, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y71.3 - Dispositivos (aparelhos) Cardiovasculares, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y71.8 - Dispositivos (aparelhos) Cardiovasculares, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y72 - Dispositivos (aparelhos) Utilizados em Otorrinolaringologia, Associados a Incidentes Adversos",
            "Y72.0 - Dispositivos (aparelhos) Utilizados em Otorrinolaringologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y72.1 - Dispositivos (aparelhos) Utilizados em Otorrinolaringologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y72.2 - Dispositivos (aparelhos) Utilizados em Otorrinolaringologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y72.3 - Dispositivos (aparelhos) Utilizados em Otorrinolaringologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y72.8 - Dispositivos (aparelhos) Utilizados em Otorrinolaringologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y73 - Dispositivos (aparelhos) Usados em Gastroenterologia e em Urologia, Associados a Incidentes Adversos",
            "Y73.0 - Dispositivos (aparelhos) Usados em Gastroenterologia e em Urologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y73.1 - Dispositivos (aparelhos) Usados em Gastroenterologia e em Urologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y73.2 - Dispositivos (aparelhos) Usados em Gastroenterologia e em Urologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y73.3 - Dispositivos (aparelhos) Usados em Gastroenterologia e em Urologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y73.8 - Dispositivos (aparelhos) Usados em Gastroenterologia e em Urologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y74 - Dispositivos (aparelhos) Gerais de Uso Hospitalar ou Pessoal, Associados a Incidentes Adversos",
            "Y74.0 - Dispositivos (aparelhos) Gerais de Uso Hospitalar ou Pessoal, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y74.1 - Dispositivos (aparelhos) Gerais de Uso Hospitalar ou Pessoal, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y74.2 - Dispositivos (aparelhos) Gerais de Uso Hospitalar ou Pessoal, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y74.3 - Dispositivos (aparelhos) Gerais de Uso Hospitalar ou Pessoal, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y74.8 - Dispositivos (aparelhos) Gerais de Uso Hospitalar ou Pessoal, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y75 - Dispositivos (aparelhos) Utilizados em Neurologia, Associados a Incidentes Adversos",
            "Y75.0 - Dispositivos (aparelhos) Utilizados em Neurologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y75.1 - Dispositivos (aparelhos) Utilizados em Neurologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y75.2 - Dispositivos (aparelhos) Utilizados em Neurologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y75.3 - Dispositivos (aparelhos) Utilizados em Neurologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y75.8 - Dispositivos (aparelhos) Utilizados em Neurologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y76 - Dispositivos (aparelhos) Utilizados em Obstetrícia e em Ginecologia, Associados a Incidentes Adversos",
            "Y76.0 - Dispositivos (aparelhos) Utilizados em Obstetrícia e em Ginecologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y76.1 - Dispositivos (aparelhos) Utilizados em Obstetrícia e em Ginecologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y76.2 - Dispositivos (aparelhos) Utilizados em Obstetrícia e em Ginecologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y76.3 - Dispositivos (aparelhos) Utilizados em Obstetrícia e em Ginecologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y76.8 - Dispositivos (aparelhos) Utilizados em Obstetrícia e em Ginecologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y77 - Dispositivos (aparelhos) Utilizados em Oftalmologia, Associados a Incidentes Adversos",
            "Y77.0 - Dispositivos (aparelhos) Utilizados em Oftalmologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y77.1 - Dispositivos (aparelhos) Utilizados em Oftalmologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y77.2 - Dispositivos (aparelhos) Utilizados em Oftalmologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y77.3 - Dispositivos (aparelhos) Utilizados em Oftalmologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y77.8 - Dispositivos (aparelhos) Utilizados em Oftalmologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y78 - Dispositivos (aparelhos) Utilizados em Radiologia, Associados a Incidentes Adversos",
            "Y78.0 - Dispositivos (aparelhos) Utilizados em Radiologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y78.1 - Dispositivos (aparelhos) Utilizados em Radiologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y78.2 - Dispositivos (aparelhos) Utilizados em Radiologia, Associados a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y78.3 - Dispositivos (aparelhos) Utilizados em Radiologia, Associados a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y78.8 - Dispositivos (aparelhos) Utilizados em Radiologia, Associados a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y79 - Dispositivos (aparelhos) Ortopédicos, Associado a Incidentes Adversos",
            "Y79.0 - Dispositivos (aparelhos) Ortopédicos, Associado a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y79.1 - Dispositivos (aparelhos) Ortopédicos, Associado a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y79.2 - Dispositivos (aparelhos) Ortopédicos, Associado a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y79.3 - Dispositivos (aparelhos) Ortopédicos, Associado a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y79.8 - Dispositivos (aparelhos) Ortopédicos, Associado a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y80 - Dispositivos (aparelhos) Utilizados em Medicina Física (fisiatria), Associado a Incidentes Adversos",
            "Y80.0 - Dispositivos (aparelhos) Utilizados em Medicina Física (fisiatria), Associado a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y80.1 - Dispositivos (aparelhos) Utilizados em Medicina Física (fisiatria), Associado a Incidentes Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y80.2 - Dispositivos (aparelhos) Utilizados em Medicina Física (fisiatria), Associado a Incidentes Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y80.3 - Dispositivos (aparelhos) Utilizados em Medicina Física (fisiatria), Associado a Incidentes Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y80.8 - Dispositivos (aparelhos) Utilizados em Medicina Física (fisiatria), Associado a Incidentes Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y81 - Dispositivos (aparelhos) Utilizados em Cirurgia Geral ou Cirurgia Plástica, Associados a Incidente Adversos",
            "Y81.0 - Dispositivos (aparelhos) Utilizados em Cirurgia Geral ou Cirurgia Plástica, Associados a Incidente Adversos - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y81.1 - Dispositivos (aparelhos) Utilizados em Cirurgia Geral ou Cirurgia Plástica, Associados a Incidente Adversos - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y81.2 - Dispositivos (aparelhos) Utilizados em Cirurgia Geral ou Cirurgia Plástica, Associados a Incidente Adversos - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y81.3 - Dispositivos (aparelhos) Utilizados em Cirurgia Geral ou Cirurgia Plástica, Associados a Incidente Adversos - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y81.8 - Dispositivos (aparelhos) Utilizados em Cirurgia Geral ou Cirurgia Plástica, Associados a Incidente Adversos - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y82 - Outros Dispositivos (aparelhos) Associados a Incidentes Adversos e os Não Especificados",
            "Y82.0 - Outros Dispositivos (aparelhos) Associados a Incidentes Adversos e os Não Especificados - Dispositivos (aparelhos) Para Fins Diagnósticos ou de Monitorização",
            "Y82.1 - Outros Dispositivos (aparelhos) Associados a Incidentes Adversos e os Não Especificados - Dispositivos (aparelhos) Para Fins Terapêuticos (não-cirúrgicos) e Aparelhagem de Reabilitação",
            "Y82.2 - Outros Dispositivos (aparelhos) Associados a Incidentes Adversos e os Não Especificados - Próteses e Outros Implantes, Incluindo Materiais e Acessórios",
            "Y82.3 - Outros Dispositivos (aparelhos) Associados a Incidentes Adversos e os Não Especificados - Instrumentos, Materiais e Aparelhos Cirúrgicos (incluindo Suturas)",
            "Y82.8 - Outros Dispositivos (aparelhos) Associados a Incidentes Adversos e os Não Especificados - Dispositivos (aparelhos) Diversos, Inclusive Combinações, Não Classificada em Outra Parte",
            "Y83 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Intervenção Cirúrgica e Por Outros Atos Cirúrgicos, Sem Menção de Acidente Durante a Intervenção",
            "Y83.0 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Intervenção Cirúrgica Com Transplante de Todo o Órgão, Sem Menção de Acidente Durante a Intervenção",
            "Y83.1 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Intervenção Cirúrgica Com Implante de Uma Prótese Interna, Sem Menção de Acidente Durante a Intervenção",
            "Y83.2 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Intervenção Cirúrgica Com Anastomose, Derivação(bypass) ou Enxerto, Sem Menção de Acidente Durante a Intervenção",
            "Y83.3 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Intervenção Cirúrgica Com Formação de Estoma Externo, Sem Menção de Acidente Durante a Intervenção",
            "Y83.4 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Outras Cirurgias Reparadoras, Sem Menção de Acidente Durante a Intervenção",
            "Y83.5 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Amputação de Membro(s), Sem Menção de Acidente Durante a Intervenção",
            "Y83.6 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Remoção de um Outro Órgão (parcial) (total), Sem Menção de Acidente Durante a Intervenção",
            "Y83.8 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Outras Intervenções ou Procedimentos Cirúrgicos, Sem Menção de Acidente Durante a Intervenção",
            "Y83.9 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Intervenção Cirúrgica, Não Especificada, Sem Menção de Acidente Durante a Intervenção",
            "Y84 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Outros Procedimentos Médicos, Sem Menção de Acidente Durante o Procedimento",
            "Y84.0 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Cateterismo Cardíaco, Sem Menção de Acidente Durante o Procedimento",
            "Y84.1 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Hemodiálise, Sem Menção de Acidente Durante o Procedimento",
            "Y84.2 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Procedimento Radiológico e Radioterapia, Sem Menção de Acidente Durante o Procedimento",
            "Y84.3 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Terapia Por Choque, Sem Menção de Acidente Durante o Procedimento",
            "Y84.4 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Aspiração de Líquido, Sem Menção de Acidente Durante o Procedimento",
            "Y84.5 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Colocação de Sonda Gástrica ou Duodenal, Sem Menção de Acidente Durante o Procedimento",
            "Y84.6 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Cateterismo Urinário, Sem Menção de Acidente Durante o Procedimento",
            "Y84.7 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Amostra de Sangue, Sem Menção de Acidente Durante o Procedimento",
            "Y84.8 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Outros Procedimentos Médicos, Sem Menção de Acidente Durante o Procedimento",
            "Y84.9 - Reação Anormal em Paciente ou Complicação Tardia, Causadas Por Procedimento Médico, Não Especificado, Sem Menção de Acidente Durante o Procedimento",
            "Y85 - Seqüelas de Acidentes de Transporte",
            "Y85.0 - Seqüelas de um Acidente de Veículo a Motor",
            "Y85.9 - Seqüelas de Outros Acidentes de Transporte e Dos Não Especificados",
            "Y86 - Seqüelas de Outros Acidentes",
            "Y87 - Seqüelas de Uma Lesão Autoprovocada Intencionalmente, de Agressão ou de um Fato Cuja Intenção é Indeterminada",
            "Y87.0 - Seqüelas de Lesões Autoprovocadas Intencionalmente",
            "Y87.1 - Seqüelas de Uma Agressão",
            "Y87.2 - Seqüelas de um Fato Cuja Intenção é Indeterminada",
            "Y88 - Seqüelas de Cuidado Médico ou Cirúrgico Considerados Como Uma Causa Externa",
            "Y88.0 - Seqüelas de Efeitos Adversos Causados Por Drogas, Medicamentos ou Substâncias Biológicas Usados Com Finalidade Terapêutica",
            "Y88.1 - Seqüelas de Acidentes Durante a Prestação de Cuidado Médico e Cirúrgico",
            "Y88.2 - Seqüelas de Incidentes Adversos Associados Com o Uso de Dispositivos (aparelhos) Médicos Durante Atos Diagnósticos ou Terapêuticos",
            "Y88.3 - Seqüelas de Reação Anormal em Paciente ou Complicação Tardia Causada Por Procedimento Médico e Cirúrgico Sem Menção de Acidente Durante o Procedimento",
            "Y89 - Seqüelas de Outras Causas Externas",
            "Y89.0 - Seqüelas de Intervenção Legal",
            "Y89.1 - Seqüelas de Operações de Guerra",
            "Y89.9 - Seqüelas de Causa Externa Não Especificada",
            "Y90 - Evidência de Alcoolismo Determinada Por Taxas de Alcoolemia",
            "Y90.0 - Alcoolemia Inferior a 20 Mg/100 ml",
            "Y90.1 - Alcoolemia de 20-39 Mg/100ml",
            "Y90.2 - Alcoolemia de 40-59 Mg/100ml",
            "Y90.3 - Alcoolemia de 60-79 Mg/100ml",
            "Y90.4 - Alcoolemia de 80-99 Mg/100ml",
            "Y90.5 - Alcoolemia de 100-119 Mg/100ml",
            "Y90.6 - Alcoolemia de 120-199 Mg/100ml",
            "Y90.7 - Alcoolemia de 200-239 Mg/100ml",
            "Y90.8 - Alcoolemia Igual ou Superior a 240 Mg/100ml",
            "Y90.9 - Presença de Álcool no Sangue, Taxa Não Especificada",
            "Y91 - Evidência de Alcoolismo Determinada Pelo Nível da Intoxicação",
            "Y91.0 - Intoxicação Alcoólica Leve",
            "Y91.1 - Intoxicação Alcoólica Moderada",
            "Y91.2 - Intoxicação Alcoólica Grave",
            "Y91.3 - Intoxicação Alcoólica Muito Grave",
            "Y91.9 - Envolvimento Com Álcool Não Especificado de Outra Forma",
            "Y95 - Circunstância Relativa as Condições Nosocomiais (hospitalares)",
            "Y96 - Circunstância Relativa às Condições de Trabalho",
            "Y97 - Circunstâncias Relativas a Condições de Poluição Ambiental",
            "Y98 - Circunstâncias Relativas a Condições do Modo de Vida",
            "Z00 - Exame Geral e Investigação de Pessoas Sem Queixas ou Diagnóstico Relatado",
            "Z00.0 - Exame Médico Geral",
            "Z00.1 - Exame de Rotina de Saúde da Criança",
            "Z00.2 - Exame no Período de Crescimento Rápido na Infância",
            "Z00.3 - Exame do Adolescente Durante o Crescimento na Puberdade",
            "Z00.4 - Exame Psiquiátrico Geral Não Classificado em Outra Parte",
            "Z00.5 - Exame de Doador Potencial de Órgão e Tecido",
            "Z00.6 - Exame Para Comparação ou de Controle de Normalidade Num Programa de Investigação Clínica",
            "Z00.8 - Outros Exames Gerais",
            "Z01 - Outros Exames e Investigações Especiais de Pessoas Sem Queixa ou Diagnóstico Relatado",
            "Z01.0 - Exame Dos Olhos e da Visão",
            "Z01.1 - Exame Dos Ouvidos e da Audição",
            "Z01.2 - Exame Dentário",
            "Z01.3 - Exame da Pressão Arterial",
            "Z01.4 - Exame Ginecológico (geral) (de Rotina)",
            "Z01.5 - Testes Cutâneos de Diagnóstico e Sensibilização",
            "Z01.6 - Exame Radiológico Não Classificado em Outra Parte",
            "Z01.7 - Exame de Laboratório",
            "Z01.8 - Outros Exames Especiais Especificados",
            "Z01.9 - Exame Especial Não Especificado",
            "Z02 - Exame Médico e Consulta Com Finalidades Administrativas",
            "Z02.0 - Exame Para Admissão a Instituição Educacional",
            "Z02.1 - Exame Pré-emprego (pré-admissional)",
            "Z02.2 - Exame Para Admissão em Instituição Residencial",
            "Z02.3 - Exame Para a Incorporação Nas Forças Armadas",
            "Z02.4 - Exame Para Licença Para a Condução de Veículos (carteira de Motorista)",
            "Z02.5 - Exame Para Participação em Esporte",
            "Z02.6 - Exame Para Fins de Seguro",
            "Z02.7 - Obtenção de Atestado Médico",
            "Z02.8 - Outros Exames Para Propósitos Administrativos",
            "Z02.9 - Exame Não Especificado Com Finalidades Administrativas",
            "Z03 - Observação e Avaliação Médica Por Doenças e Afecções Suspeitas",
            "Z03.0 - Observação Por Suspeita de Tuberculose",
            "Z03.1 - Observação Por Suspeita de Neoplasia Maligna",
            "Z03.2 - Observação Por Suspeita de Transtornos Mentais e do Comportamento",
            "Z03.3 - Observação Por Suspeita de Transtorno do Sistema Nervoso",
            "Z03.4 - Observação Por Suspeita de Infarto do Miocárdio",
            "Z03.5 - Observação Por Suspeita de Outras Doenças Cardiovasculares",
            "Z03.6 - Observação Por Suspeita de Efeito Tóxico de Substância Ingerida",
            "Z03.8 - Observação Por Suspeita de Outras Doenças e Afecções",
            "Z03.9 - Observação Por Suspeita de Doença ou Afecção Não Especificada",
            "Z04 - Exame e Observação Por Outras Razões",
            "Z04.0 - Teste Para Álcool e Para Drogas no Sangue",
            "Z04.1 - Exame e Observação Após Acidente de Transporte",
            "Z04.2 - Exame e Observação Após Acidente de Trabalho",
            "Z04.3 - Exame e Observação Após Outro Acidente",
            "Z04.4 - Exame e Observação Após Alegação de Estupro e Sedução",
            "Z04.5 - Exame e Observação Após Outros Ferimentos Infligidos",
            "Z04.6 - Exame Psiquiátrico Geral, Requerido Por Autoridade",
            "Z04.8 - Exame e Observação Por Outras Razões Especificadas",
            "Z04.9 - Exame e Observação Por Razão Não Especificada",
            "Z08 - Exame de Seguimento Após Tratamento Por Neoplasia Maligna",
            "Z08.0 - Exame de Seguimento Após Tratamento Cirúrgico de Neoplasia Maligna",
            "Z08.1 - Exame de Seguimento Após Radioterapia Por Neoplasia Maligna",
            "Z08.2 - Exame de Seguimento Após Quimioterapia Por Neoplasia Maligna",
            "Z08.7 - Exame de Seguimento Após Tratamento Misto Por Neoplasia Maligna",
            "Z08.8 - Exame de Seguimento Após Outro Tratamento Por Neoplasia Maligna",
            "Z08.9 - Exame de Seguimento Após Tratamento Não Especificado Por Neoplasia Maligna",
            "Z09 - Exame de Seguimento Após Tratamento de Outras Afecções Que Não Neoplasias Malignas",
            "Z09.0 - Exame de Seguimento Após Cirurgia Por Outras Afecções",
            "Z09.1 - Exame de Seguimento Após Radioterapia Por Outras Afecções",
            "Z09.2 - Exame de Seguimento Após Quimioterapia Por Outras Afecções",
            "Z09.3 - Exame de Seguimento Após Psicoterapia",
            "Z09.4 - Exame de Seguimento Após Tratamento de Fratura",
            "Z09.7 - Exame de Seguimento Após Tratamento Combinado Por Outras Afecções",
            "Z09.8 - Exame de Seguimento Após Outro Tratamento Por Outras Afecções",
            "Z09.9 - Exame de Seguimento Após Tratamento Não Especificado Por Outras Afecções",
            "Z10 - Exame Geral de Rotina (check Up) de Uma Subpopulação Definida",
            "Z10.0 - Exame de Saúde Ocupacional",
            "Z10.1 - Exame Geral de Rotina de Residentes de Instituições",
            "Z10.2 - Exame Geral de Rotina de Pessoas Das Forças Armadas",
            "Z10.3 - Exame Geral de Rotina de Equipe Esportiva",
            "Z10.8 - Exame Geral de Rotina de Outra Subpopulação Definida",
            "Z11 - Exame Especial de Rastreamento (screening) de Doenças Infecciosas e Parasitárias",
            "Z11.0 - Exame Especial de Rastreamento de Doenças Infecciosas Intestinais",
            "Z11.1 - Exame Especial de Rastreamento de Tuberculose Pulmonar",
            "Z11.2 - Exame Especial de Rastreamento de Outras Doenças Bacterianas",
            "Z11.3 - Exame Especial de Rastreamento de Infecções de Transmissão Predominantemente Sexual",
            "Z11.4 - Exame Especial de Rastreamento de Vírus da Imunodeficiência Humana (HIV)",
            "Z11.5 - Exame Especial de Rastreamento de Outras Doenças Virais",
            "Z11.6 - Exame Especial de Rastreamento de Outras Doenças Por Protozoários e Por Helmintos",
            "Z11.8 - Exame Especial de Rastreamento de Outras Doenças Infecciosas e Parasitárias",
            "Z11.9 - Exame Especial de Rastreamento Para Doença Infecciosa e Parasitária Não Especificada",
            "Z12 - Exame Especial de Rastreamento (screening) de Neoplasias",
            "Z12.0 - Exame Especial de Rastreamento de Neoplasia do Estômago",
            "Z12.1 - Exame Especial de Rastreamento de Neoplasia do Trato Intestinal",
            "Z12.2 - Exame Especial de Rastreamento de Neoplasia de Órgãos Respiratórios",
            "Z12.3 - Exame Especial de Rastreamento de Neoplasia de Mama",
            "Z12.4 - Exame Especial de Rastreamento de Neoplasia do Colo do Útero",
            "Z12.5 - Exame Especial de Rastreamento de Neoplasia da Próstata",
            "Z12.6 - Exame Especial de Rastreamento de Neoplasia da Bexiga",
            "Z12.8 - Exame Especial de Rastreamento de Neoplasias de Outras Localizações",
            "Z12.9 - Exame Especial de Rastreamento de Neoplasia Não Especificada",
            "Z13 - Exame Especial de Rastreamento (screening) de Outros Transtornos e Doenças",
            "Z13.0 - Exame Especial de Rastreamento de Doenças do Sangue e Dos Órgãos Hematopoéticos de Alguns Transtornos Imunitários",
            "Z13.1 - Exame Especial de Rastreamento de Diabetes Mellitus",
            "Z13.2 - Exame Especial de Rastreamento de Transtornos Nutricionais",
            "Z13.3 - Exame Especial de Rastreamento de Transtornos Mentais e do Comportamento",
            "Z13.4 - Exame Especial de Rastreamento de Alguns Transtornos do Desenvolvimento na Infância",
            "Z13.5 - Exame Especial de Rastreamento de Doenças Dos Ouvidos e Dos Olhos",
            "Z13.6 - Exame Especial de Rastreamento de Doenças Cardiovasculares",
            "Z13.7 - Exame Especial de Rastreamento de Malformações Congênitas, Deformidades e Anomalias Cromossômicas",
            "Z13.8 - Exame Especial de Rastreamento de Outras Doenças e Transtornos Especificados",
            "Z13.9 - Exame Especial de Rastreamento Não Especificado",
            "Z20 - Contato Com e Exposição a Doenças Transmissíveis",
            "Z20.0 - Contato Com e Exposição a Doenças Infecciosas Intestinais",
            "Z20.1 - Contato Com e Exposição à Tuberculose",
            "Z20.2 - Contato Com e Exposição a Infecções de Transmissão Predominantemente Sexual",
            "Z20.3 - Contato Com e Exposição à Raiva",
            "Z20.4 - Contato Com e Exposição à Rubéola",
            "Z20.5 - Contato Com e Exposição à Hepatite Viral",
            "Z20.6 - Contato Com e Exposição ao Vírus da Imunodeficiência Humana (HIV)",
            "Z20.7 - Contato Com e Exposição à Pediculose, Acaríase e Outras Infestações",
            "Z20.8 - Contato Com e Exposição a Outras Doenças Transmissíveis",
            "Z20.9 - Contato Com e Exposição a Doença Transmissível Não Especificada",
            "Z21 - Estado de Infecção Assintomática Pelo Vírus da Imunodeficiência Humana (HIV)",
            "Z22 - Portador de Doença Infecciosa",
            "Z22.0 - Portador de Febre Tifóide",
            "Z22.1 - Portador de Outras Doenças Infecciosas Intestinais",
            "Z22.2 - Portador de Difteria",
            "Z22.3 - Portador de Outras Doenças Bacterianas Especificadas",
            "Z22.4 - Portador de Infecções Com Modo de Transmissão Predominantemente Sexual",
            "Z22.5 - Portador de Hepatite Viral",
            "Z22.6 - Portador de Infecção Pelo Vírus T-linfotrópico Tipo 1 (HTLV-1)",
            "Z22.8 - Portador de Outras Doenças Infecciosas",
            "Z22.9 - Portador de Doença Infecciosa Não Especificada",
            "Z23 - Necessidade de Imunização Contra Uma Única Doença Bacteriana",
            "Z23.0 - Necessidade de Imunização Somente Contra Cólera",
            "Z23.1 - Necessidade de Imunização Somente Contra Febre Tifóide e Paratifóide (TAB)",
            "Z23.2 - Necessidade de Imunização Contra Tuberculose (BCG)",
            "Z23.3 - Necessidade de Imunização Contra a Peste",
            "Z23.4 - Necessidade de Imunização Contra a Tularemia",
            "Z23.5 - Necessidade de Imunização Somente Contra o Tétano",
            "Z23.6 - Necessidade de Imunização Somente Contra a Difteria",
            "Z23.7 - Necessidade de Imunização Somente Contra a Coqueluche",
            "Z23.8 - Vacinação Contra Outras Doenças Bacterianas Únicas",
            "Z24 - Necessidade de Imunização Contra Algumas Doenças Virais Únicas",
            "Z24.0 - Necessidade de Imunização Contra a Poliomielite",
            "Z24.1 - Necessidade de Imunização Contra a Encefalite Viral Transmitida Por Artrópodes",
            "Z24.2 - Necessidade de Imunização Contra a Raiva",
            "Z24.3 - Necessidade de Imunização Contra a Febre Amarela",
            "Z24.4 - Necessidade de Imunização Somente Contra o Sarampo",
            "Z24.5 - Necessidade de Imunização Somente Contra a Rubéola",
            "Z24.6 - Necessidade de Imunização Somente Contra a Hepatite Viral",
            "Z25 - Necessidade de Imunização Contra Outras Doenças Virais Únicas",
            "Z25.0 - Necessidade de Imunização Somente Contra a Caxumba (parotidite Epidêmica)",
            "Z25.1 - Necessidade de Imunização Somente Contra a Influenza (gripe)",
            "Z25.8 - Necessidade de Imunização Contra Outras Doenças Virais Únicas Especificadas",
            "Z26 - Necessidade de Imunização Contra Outras Doenças Infecciosas Únicas",
            "Z26.0 - Necessidade de Imunização Contra a Leishmaniose",
            "Z26.8 - Necessidade de Imunização Contra Outras Doenças Infecciosas Especificadas Únicas",
            "Z26.9 - Necessidade de Imunização Contra Doença Infecciosa Não Especificada",
            "Z27 - Necessidade de Imunização Associada Contra Combinações de Doenças Infecciosas",
            "Z27.0 - Necessidade de Imunização Contra a Cólera e as Febres Tifóide e Paratifóides (Cólera + TAB)",
            "Z27.1 - Necessidade de Imunização Associada Contra a Difteria-pertussis-tétano, Combinada (DPT)",
            "Z27.2 - Necessidade de Imunização Contra a Difteria-pertussis-tétano Com Febres Tifóide e Paratifóides (DPT + TAB)",
            "Z27.3 - Necessidade de Imunização Contra a Difteria-pertussis-tétano Com Poliomielite (DPT + Pólio)",
            "Z27.4 - Necessidade de Imunização Contra o Sarampo-caxumba-rubéola",
            "Z27.8 - Necessidade de Imunização Contra Outras Combinações de Doenças Infecciosas",
            "Z27.9 - Necessidade de Imunização Contra Combinações Não Especificadas de Doenças Infecciosas",
            "Z28 - Imunização Não Realizada",
            "Z28.0 - Imunização Não Realizada Por Contra-indicação",
            "Z28.1 - Imunização Não Realizada Por Decisão do Paciente Devida à Crença ou a Grupo de Pressão",
            "Z28.2 - Imunização Não Realizada Por Decisão do Paciente Devida a Razões Outras e às Não Especificadas",
            "Z28.8 - Imunização Não Realizada Por Outras Razões",
            "Z28.9 - Imunização Não Realizada Por Razões Não Especificadas",
            "Z29 - Necessidade de Outras Medidas Profiláticas",
            "Z29.0 - Isolamento",
            "Z29.1 - Imunoterapia Profilática",
            "Z29.2 - Outras Quimioterapias Profiláticas",
            "Z29.8 - Outras Medidas Profiláticas Especificadas",
            "Z29.9 - Medida Profilática Não Especificada",
            "Z30 - Anticoncepção",
            "Z30.0 - Aconselhamento Geral Sobre Contracepção",
            "Z30.1 - Inserção de Dispositivo Anticoncepcional (intra-uterino)",
            "Z30.2 - Esterilização",
            "Z30.3 - Extração Menstrual",
            "Z30.4 - Supervisão do Uso de Medicamentos Anticoncepcionais",
            "Z30.5 - Supervisão de Dispositivo Anticoncepcional (intra-uterino)",
            "Z30.8 - Outro Procedimento Anticoncepcional",
            "Z30.9 - Procedimento Anticoncepcional Não Especificado",
            "Z31 - Medidas de Procriação",
            "Z31.0 - Tuboplastia ou Vasoplastia Após Esterilização Prévia",
            "Z31.1 - Inseminação Artificial",
            "Z31.2 - Fecundação in Vitro",
            "Z31.3 - Outros Métodos Assistidos de Fertilização",
            "Z31.4 - Investigação e Testes Com Relação à Procriação",
            "Z31.5 - Aconselhamento Genético",
            "Z31.6 - Aconselhamento Geral Sobre a Procriação",
            "Z31.8 - Outra Medida Especificada de Procriação",
            "Z31.9 - Medida Procriativa Não Especificada",
            "Z32 - Exame ou Teste de Gravidez",
            "Z32.0 - Gravidez (ainda) Não Confirmada",
            "Z32.1 - Gravidez Confirmada",
            "Z33 - Gravidez Como Achado Casual",
            "Z34 - Supervisão de Gravidez Normal",
            "Z34.0 - Supervisão de Primeira Gravidez Normal",
            "Z34.8 - Supervisão de Outra Gravidez Normal",
            "Z34.9 - Supervisão de Gravidez Normal, Não Especificada",
            "Z35 - Supervisão de Gravidez de Alto Risco",
            "Z35.0 - Supervisão de Gravidez Com História de Esterilidade",
            "Z35.1 - Supervisão de Gravidez Com História de Aborto",
            "Z35.2 - Supervisão de Gravidez Com Outros Antecedentes de Procriação Problemática",
            "Z35.3 - Supervisão de Gravidez Com História de Assistência Pré-natal Insuficiente",
            "Z35.4 - Supervisão de Gravidez Com Grande Multiparidade",
            "Z35.5 - Supervisão de Primigesta Idosa",
            "Z35.6 - Supervisão de Primigesta Muito Jovem",
            "Z35.7 - Supervisão de Gravidez de Alto Risco Devido a Problemas Sociais",
            "Z35.8 - Supervisão de Outras Gravidezes de Alto Risco",
            "Z35.9 - Supervisão Não Especificada de Gravidez de Alto Risco",
            "Z36 - Rastreamento (screening) Pré-natal",
            "Z36.0 - Rastreamento de Anomalias Cromossômicas",
            "Z36.1 - Rastreamento Pré-natal de Taxa Elevada de Alfa-fetoproteínas",
            "Z36.2 - Outros Rastreamentos Pré-natais Por Amniocentese",
            "Z36.3 - Rastreamento Pré-natal de Malformações Por Ultrassom e Outros Meios Físicos",
            "Z36.4 - Rastreamento Pré-natal do Retardo de Crescimento do Feto Por Ultrassom e Outros Métodos Físicos",
            "Z36.5 - Rastreamento Pré-natal de Isoimunização",
            "Z36.8 - Outros Rastreamentos Pré-natais",
            "Z36.9 - Rastreamento Pré-natal Não Especificado",
            "Z37 - Resultado do Parto",
            "Z37.0 - Nascimento Único, Nativivo (nado-vivo)",
            "Z37.1 - Nascimento Único, Natimorto (feto-morto)",
            "Z37.2 - Nascimento Gemelar, Gêmeos (ambos) Nativivos (nado-vivos)",
            "Z37.3 - Nascimento Gemelar, um Dos Gêmeos, Nativivo (nado-vivo), o Outro, Natimorto (feto-morto)",
            "Z37.4 - Nascimento Gemelar, Gêmeos Natimortos (feto-mortos)",
            "Z37.5 - Outros Nascimentos Múltiplos, Todos Nativivos (nado-vivos)",
            "Z37.6 - Outros Nascimentos Múltiplos, Alguns Nativivos (nado-vivos)",
            "Z37.7 - Outros Nascimentos Múltiplos, Todos Natimortos (feto-mortos)",
            "Z37.9 - Nascimento Não Especificado",
            "Z38 - Nascidos Vivos (nado-vivos) Segundo o Local de Nascimento",
            "Z38.0 - Criança Única, Nascida em Hospital",
            "Z38.1 - Criança Única, Nascida Fora do Hospital",
            "Z38.2 - Criança Única, Não Especificado Quanto ao Local de Nascimento",
            "Z38.3 - Gêmeos (duplos), Nascidos em Hospital",
            "Z38.4 - Gêmeos (duplos), Nascidos Fora de Hospital",
            "Z38.5 - Gêmeos, Não Especificados Quanto ao Local de Nascimento",
            "Z38.6 - Outros Nascimentos Múltiplos, em Hospital",
            "Z38.7 - Outros Nascimentos Múltiplos, Fora de Hospital",
            "Z38.8 - Outros Nascimentos Múltiplos, Não Especificados Quanto ao Local de Nascimento",
            "Z39 - Assistência e Exame Pós-natal",
            "Z39.0 - Assistência e Exame Imediatamente Após o Parto",
            "Z39.1 - Assistência e Exame da Mãe Nutriz",
            "Z39.2 - Seguimento Pós-parto de Rotina",
            "Z40 - Cirurgia Profilática",
            "Z40.0 - Cirurgia Profilática Para Fatores de Risco Relacionados Com Neoplasias Malignas",
            "Z40.8 - Outra Cirurgia Profilática",
            "Z40.9 - Cirurgia Profilática Não Especificada",
            "Z41 - Procedimentos Para Outros Propósitos Exceto Cuidados de Saúde",
            "Z41.0 - Transplante de Cabelo",
            "Z41.1 - Outras Intervenções de Cirurgia Plástica Por Razões Estéticas",
            "Z41.2 - Circuncisão Ritual e de Rotina",
            "Z41.3 - Perfuração do Lobo da Orelha",
            "Z41.8 - Outros Procedimentos Para Outros Propósitos Exceto Cuidados de Saúde",
            "Z41.9 - Procedimentos Não Especificado Para Outros Propósitos Exceto Cuidados de Saúde",
            "Z42 - Seguimento Envolvendo Cirurgia Plástica",
            "Z42.0 - Seguimento Envolvendo Cirurgia Plástica da Cabeça e do Pescoço",
            "Z42.1 - Seguimento Envolvendo Cirurgia Plástica de Mama",
            "Z42.2 - Seguimento Envolvendo Cirurgia Plástica de Outras Partes do Tronco",
            "Z42.3 - Seguimento Envolvendo Cirurgia Plástica Das Extremidades Superiores",
            "Z42.4 - Seguimento Envolvendo Cirurgia Plástica Das Extremidades Inferiores",
            "Z42.8 - Seguimento Envolvendo Cirurgia Plástica de Outras Partes do Corpo",
            "Z42.9 - Seguimento Envolvendo Cirurgia Plástica Não Especificada",
            "Z43 - Atenção a Orifícios Artificiais",
            "Z43.0 - Cuidados à Traqueostomia",
            "Z43.1 - Cuidados à Gastrostomia",
            "Z43.2 - Cuidados à Ileostomia",
            "Z43.3 - Cuidados à Colostomia",
            "Z43.4 - Cuidados a Outros Orifícios Artificiais do Tubo Digestivo",
            "Z43.5 - Cuidados à Cistostomia",
            "Z43.6 - Cuidados a Outros Orifícios Artificiais Das Vias Urinárias",
            "Z43.7 - Cuidados à Vagina Artificial",
            "Z43.8 - Cuidados a Outros Orifícios Artificiais",
            "Z43.9 - Cuidados a Orifício Artificial Não Especificado",
            "Z44 - Colocação e Ajustamento de Aparelhos de Prótese Externa",
            "Z44.0 - Colocação e Ajustamento de Braço Artificial (parcial) (total)",
            "Z44.1 - Colocação e Ajustamento de Perna Artificial (parcial) (total)",
            "Z44.2 - Colocação e Ajustamento de Olho Artificial",
            "Z44.3 - Colocação e Ajustamento de Prótese Externa de Mama",
            "Z44.8 - Colocação e Ajustamento de Outros Aparelhos de Prótese Externa",
            "Z44.9 - Colocação e Ajustamento de Aparelho de Prótese Externa Não Especificado",
            "Z45 - Ajustamento e Manuseio de Dispositivo Implantado",
            "Z45.0 - Ajustamento e Manuseio de Marca-passo Cardíaco",
            "Z45.1 - Ajustamento e Manuseio de Bomba de Infusão",
            "Z45.2 - Ajustamento e Manuseio de Dispositivo de Acesso Vascular",
            "Z45.3 - Ajustamento e Manuseio de Dispositivo Implantado de Audição",
            "Z45.8 - Ajustamento e Manuseio de Outros Dispositivos Implantados",
            "Z45.9 - Ajustamento e Manuseio de Dispositivo Implantado Não Especificado",
            "Z46 - Colocação e Ajustamento de Outros Aparelhos",
            "Z46.0 - Colocação e Ajustamento de Óculos e Lentes de Contato",
            "Z46.1 - Colocação e Ajustamento de Aparelho Auditivo",
            "Z46.2 - Colocação e Ajustamento de Outros Aparelhos Relacionados Com o Sistema Nervoso e Órgãos Dos Sentidos",
            "Z46.3 - Colocação e Ajustamento de Dispositivo de Prótese Dentária",
            "Z46.4 - Colocação e Ajustamento de Aparelho Ortodôntico",
            "Z46.5 - Colocação e Ajustamento de Ileostomia e de Outros Dispositivos Intestinais",
            "Z46.6 - Colocação e Ajustamento de Prótese Urinária",
            "Z46.7 - Colocação e Ajustamento de Aparelho Ortopédico",
            "Z46.8 - Colocação e Ajustamento de Outros Aparelhos Especificados",
            "Z46.9 - Colocação e Ajustamento de Aparelho Não Especificado",
            "Z47 - Outros Cuidados de Seguimento Ortopédico",
            "Z47.0 - Seguimento Envolvendo Remoção de Placa de Fratura e Outros Dispositivos de Fixação Interna",
            "Z47.8 - Outros Seguimentos Ortopédicos Especificados",
            "Z47.9 - Seguimento Ortopédico Não Especificado",
            "Z48 - Outro Seguimento Cirúrgico",
            "Z48.0 - Cuidados a Curativos e Suturas Cirúrgicas",
            "Z48.8 - Outro Seguimento Cirúrgico Especificado",
            "Z48.9 - Seguimento Cirúrgico Não Especificado",
            "Z49 - Cuidados Envolvendo Diálise",
            "Z49.0 - Cuidado Preparatório Para Diálise",
            "Z49.1 - Diálise Extracorpórea",
            "Z49.2 - Outras Diálises",
            "Z50 - Cuidados Envolvendo o Uso de Procedimentos de Reabilitação",
            "Z50.0 - Reabilitação Cardíaca",
            "Z50.1 - Outra Fisioterapia",
            "Z50.2 - Reabilitação de Alcoólatra",
            "Z50.3 - Reabilitação de Toxicodependentes",
            "Z50.4 - Psicoterapia, Não Classificada em Outra Parte",
            "Z50.5 - Reabilitação da Linguagem",
            "Z50.6 - Treinamento Ortóptico",
            "Z50.7 - Terapia Ocupacional e Reabilitação Vocacional Não Classificada em Outra Parte",
            "Z50.8 - Cuidados Envolvendo Uso de Outros Procedimentos de Reabilitação",
            "Z50.9 - Cuidados Envolvendo Uso de Procedimento de Reabilitação Não Especificado",
            "Z51 - Outros Cuidados Médicos",
            "Z51.0 - Sessão de Radioterapia",
            "Z51.1 - Sessão de Quimioterapia Por Neoplasia",
            "Z51.2 - Outra Quimioterapia",
            "Z51.3 - Transfusão de Sangue, Sem Diagnóstico Registrado",
            "Z51.4 - Cuidado Preparatório Para Tratamento Subsequente Não Classificado em Outra Parte",
            "Z51.5 - Cuidado Paliativo",
            "Z51.6 - Dessensibilização a Alérgenos",
            "Z51.8 - Outro Cuidado Médico Especificado",
            "Z51.9 - Cuidado Médico Não Especificado",
            "Z52 - Doadores de Órgãos e Tecidos",
            "Z52.0 - Doador de Sangue",
            "Z52.1 - Doador de Pele",
            "Z52.2 - Doador de Osso",
            "Z52.3 - Doador de Medula Óssea",
            "Z52.4 - Doador de Rim",
            "Z52.5 - Doador de Córnea",
            "Z52.6 - Doador de Fígado",
            "Z52.7 - Doador de Coração",
            "Z52.8 - Doador de Outros Órgãos ou Tecidos",
            "Z52.9 - Doador de Órgão ou Tecido Não Especificado",
            "Z53 - Pessoas em Contato Com Serviços de Saúde Para Procedimentos Específicos Não Realizados",
            "Z53.0 - Procedimento Não Realizado Devido à Contra-indicação",
            "Z53.1 - Procedimento Não Realizado Devido à Decisão do Paciente Por Razões de Crença ou Grupo de Pressão",
            "Z53.2 - Procedimento Não Realizado Devido à Decisão do Paciente Por Outras Razões e as Não Especificadas",
            "Z53.8 - Procedimento Não Realizado Por Outras Razões",
            "Z53.9 - Procedimento Não Realizado Por Razão Não Especificada",
            "Z54 - Convalescença",
            "Z54.0 - Convalescença Após Cirurgia",
            "Z54.1 - Convalescença Após Radioterapia",
            "Z54.2 - Convalescença Após Quimioterapia",
            "Z54.3 - Convalescença Após Psicoterapia",
            "Z54.4 - Convalescença Após Tratamento de Fratura",
            "Z54.7 - Convalescença Após Tratamento Combinado",
            "Z54.8 - Convalescença Após Outro Tratamento",
            "Z54.9 - Convalescença Após Tratamento Não Especificado",
            "Z55 - Problemas Relacionados Com a Educação e Com a Alfabetização",
            "Z55.0 - Analfabetismo e Baixa Escolaridade",
            "Z55.1 - Escolarização Não Disponível e Impossível",
            "Z55.2 - Reprovação em Exames",
            "Z55.3 - Maus Resultados Escolares",
            "Z55.4 - Má Adaptação Escolar e Dificuldades Com Professores e Colegas",
            "Z55.8 - Outros Problemas Relacionados Com a Educação e Com a Alfabetização",
            "Z55.9 - Problemas Não Especificados Relacionados Com a Educação e Com a Alfabetização",
            "Z56 - Problemas Relacionados Com o Emprego e Com o Desemprego",
            "Z56.0 - Desemprego Não Especificado",
            "Z56.1 - Mudança de Emprego",
            "Z56.2 - Ameaça de Perda de Emprego",
            "Z56.3 - Ritmo de Trabalho Penoso",
            "Z56.4 - Desacordo Com Patrão e Colegas de Trabalho",
            "Z56.5 - Má Adaptação ao Trabalho",
            "Z56.6 - Outras Dificuldades Físicas e Mentais Relacionadas ao Trabalho",
            "Z56.7 - Outros Problemas e os Não Especificados Relacionados Com o Emprego",
            "Z57 - Exposição Ocupacional a Fatores de Risco",
            "Z57.0 - Exposição Ocupacional ao Ruído",
            "Z57.1 - Exposição Ocupacional à Radiação",
            "Z57.2 - Exposição Ocupacional à Poeira (pó)",
            "Z57.3 - Exposição Ocupacional a Outros Contaminantes do ar",
            "Z57.4 - Exposição Ocupacional a Agentes Tóxicos na Agricultura",
            "Z57.5 - Exposição Ocupacional a Agentes Tóxicos em Outras Indústrias",
            "Z57.6 - Exposição Ocupacional a Temperaturas Extremas",
            "Z57.7 - Exposição Ocupacional à Vibração",
            "Z57.8 - Exposição Ocupacional a Outros Fatores de Risco",
            "Z57.9 - Exposição Ocupacional a Fator de Risco Não Especificado",
            "Z58 - Problemas Relacionados Com o Ambiente Físico",
            "Z58.0 - Exposição ao Ruído",
            "Z58.1 - Exposição à Poluição Atmosférica",
            "Z58.2 - Exposição à Poluição da Água",
            "Z58.3 - Exposição à Poluição do Solo",
            "Z58.4 - Exposição à Radiação",
            "Z58.5 - Exposição a Outras Poluições",
            "Z58.6 - Água Potável Insuficiente",
            "Z58.7 - Exposição à Fumaça de Tabaco",
            "Z58.8 - Outros Problemas Relacionados Com o Ambiente Físico",
            "Z58.9 - Problema Não Especificado Relacionado Com o Ambiente Físico",
            "Z59 - Problemas Relacionados Com a Habitação e Com as Condições Econômicas",
            "Z59.0 - Falta de Domicílio Fixo",
            "Z59.1 - Habitação Inadequada",
            "Z59.2 - Desacordo Com Vizinhos, Locatários e Proprietários",
            "Z59.3 - Problemas Relacionados Com a Vida em Instituição Residencial",
            "Z59.4 - Falta de Alimentação Adequada",
            "Z59.5 - Pobreza Extrema",
            "Z59.6 - Baixo Rendimento",
            "Z59.7 - Seguro Social e Medidas de Bem-estar Social Insuficientes",
            "Z59.8 - Outros Problemas Relacionados Com a Habitação e Com as Circunstâncias Econômicas",
            "Z59.9 - Circunstâncias Não Especificadas Econômicas ou de Habitação",
            "Z60 - Problemas Relacionados Com o Meio Social",
            "Z60.0 - Problemas de Adaptação às Transições do Ciclo de Vida",
            "Z60.1 - Situação Parental Atípica",
            "Z60.2 - Viver só",
            "Z60.3 - Dificuldade de Aculturação",
            "Z60.4 - Exclusão e Rejeição Sociais",
            "Z60.5 - Objeto de Discriminação e Perseguição Percebidas",
            "Z60.8 - Outros Problemas Relacionados Com o Meio Social",
            "Z60.9 - Problema Não Especificado Relacionado Com o Meio Social",
            "Z61 - Problemas Relacionados Com Eventos Negativos de Vida na Infância",
            "Z61.0 - Perda de Relação Afetiva na Infância",
            "Z61.1 - Remoção do Lar na Infância",
            "Z61.2 - Padrão Alterado de Relações Familiares na Infância",
            "Z61.3 - Eventos Que Originam a Perda de Auto-estima na Infância",
            "Z61.4 - Problemas Relacionados Com Abuso Sexual Alegado de Uma Criança Por Uma Pessoa de Dentro de Seu Grupo",
            "Z61.5 - Problemas Relacionados Com Abuso Sexual Alegado de Uma Criança Por Pessoa de Fora de Seu Grupo",
            "Z61.6 - Problemas Relacionados Com Abuso Físico Alegado da Criança",
            "Z61.7 - Experiência Pessoal Amedrontadora na Infância",
            "Z61.8 - Outros Eventos da Vida Pessoal Negativos na Infância",
            "Z61.9 - Evento Pessoal Negativo Não Especificado na Infância",
            "Z62 - Outros Problemas Relacionados Com a Educação da Criança",
            "Z62.0 - Supervisão e Controle Inadequado Por Parte Dos Pais",
            "Z62.1 - Superproteção Por Parte Dos Pais",
            "Z62.2 - Educação Numa Instituição",
            "Z62.3 - Hostilidade Com Relação a Uma Criança, Transformada em Bode Expiatório",
            "Z62.4 - Negligência Emocional da Criança",
            "Z62.5 - Outros Problemas Relacionados Com Negligência na Educação Dos Filhos",
            "Z62.6 - Pressões Não Apropriadas Por Parte Dos Pais e Outras Falhas de Educação",
            "Z62.8 - Outros Problemas Especificados Relacionados Com a Educação Dos Filhos",
            "Z62.9 - Problema Não Especificado Relacionado Com a Educação Dos Filhos",
            "Z63 - Outros Problemas Relacionados Com o Grupo Primário de Apoio, Inclusive Com a Situação Familiar",
            "Z63.0 - Problemas Nas Relações Com Cônjuge ou Parceiro",
            "Z63.1 - Problemas Nas Relações Com os Pais ou Com os Sogros",
            "Z63.2 - Suporte Familiar Inadequado",
            "Z63.3 - Ausência de um Dos Membros da Família",
            "Z63.4 - Desaparecimento ou Falecimento de um Membro da Família",
            "Z63.5 - Rompimento da Família Por Separação ou Divórcio",
            "Z63.6 - Parente Dependente de Cuidados, Residente no Domicílio",
            "Z63.7 - Outros Acontecimentos Difíceis Com Incidência na Família ou no Lar",
            "Z63.8 - Outros Problemas Especificados Relacionados Com o Grupo Primário de Suporte",
            "Z63.9 - Problema Não Especificado Relacionado Com o Grupo Primário de Suporte",
            "Z64 - Problemas Relacionados Com Algumas Outras Circunstâncias Psicossociais",
            "Z64.0 - Problemas Relacionados Com Uma Gravidez Não Desejada",
            "Z64.1 - Problemas Relacionados Com Multiparidade",
            "Z64.2 - Procura e Aceitação de Intervenções Físicas, Nutricionais, ou Químicas Conhecidas Como Perigosas ou Nocivas",
            "Z64.3 - Procura e Aceitação de Intervenções Comportamentais ou Psicológicas Conhecidas Como Perigosas ou Nocivas",
            "Z64.4 - Desacordo Com Conselheiros",
            "Z65 - Problemas Relacionados Com Outras Circunstâncias Psicossociais",
            "Z65.0 - Condenação, Sem Prisão, Por Tribunal Civil ou Criminal",
            "Z65.1 - Prisão ou Encarceramento",
            "Z65.2 - Problemas Ligados à Libertação de Prisão",
            "Z65.3 - Problemas Ligados à Outras Circunstâncias Legais",
            "Z65.4 - Vítima de Crime ou de Atos Terroristas",
            "Z65.5 - Exposição a Catástrofe, Guerra e Outras Hostilidades",
            "Z65.8 - Outros Problemas Especificados Relacionados Com Circunstâncias Psicossociais",
            "Z65.9 - Problemas Relacionados Com Circunstâncias Psicossociais Não Especificadas",
            "Z70 - Aconselhamento Relativo às Atitudes, Comportamento e Orientação em Matéria de Sexualidade",
            "Z70.0 - Acompanhamento Relativo às Atitudes em Matéria de Sexualidade",
            "Z70.1 - Aconselhamento Relativo ao Comportamento e à Orientação Sexual do Sujeito",
            "Z70.2 - Aconselhamento Relativo ao Comportamento e à Orientação Sexual de um Terceiro",
            "Z70.3 - Aconselhamento Relativo a Preocupações Associadas Relacionadas Com as Atitudes, o Comportamento e Orientação em Matéria de Sexualidade",
            "Z70.8 - Outros Aconselhamentos em Matéria de Sexualidade",
            "Z70.9 - Aconselhamento Não Especificado em Matéria de Sexualidade",
            "Z71 - Pessoas em Contato Com os Serviços de Saúde Para Outros Aconselhamentos e Conselho Médico, Não Classificados em Outra Parte",
            "Z71.0 - Pessoa Que Consulta no Interesse de um Terceiro",
            "Z71.1 - Pessoa Com Medo de Uma Queixa Para a Qual Não Foi Feito Diagnóstico",
            "Z71.2 - Pessoa Que Consulta Para Explicação de Achados de Exame",
            "Z71.3 - Aconselhamento e Supervisão Dietéticos",
            "Z71.4 - Aconselhamento e Supervisão Para Abuso de Álcool",
            "Z71.5 - Aconselhamento e Supervisão Para Abuso de Drogas",
            "Z71.6 - Aconselhamento Para o Abuso de Fumo",
            "Z71.7 - Aconselhamento a Propósito do Vírus da Imunodeficiência Humana (HIV)",
            "Z71.8 - Outros Aconselhamentos Especificados",
            "Z71.9 - Aconselhamento Não Especificado",
            "Z72 - Problemas Relacionados Com o Estilo de Vida",
            "Z72.0 - Uso do Tabaco",
            "Z72.1 - Uso de Álcool",
            "Z72.2 - Uso de Droga",
            "Z72.3 - Falta de Exercício Físico",
            "Z72.4 - Regime e Hábitos Alimentares Inadequados",
            "Z72.5 - Comportamento Sexual de Alto Risco",
            "Z72.6 - Mania de Jogo e Apostas",
            "Z72.8 - Outros Problemas Relacionados Com o Estilo de Vida",
            "Z72.9 - Problema Não Especificado Relacionado Com o Estilo de Vida",
            "Z73 - Problemas Relacionados Com a Organização de Seu Modo de Vida",
            "Z73.0 - Esgotamento",
            "Z73.1 - Acentuação de Traços de Personalidade",
            "Z73.2 - Falta de Repouso e de Lazer",
            "Z73.3 - Stress Não Classificado em Outra Parte",
            "Z73.4 - Habilidades Sociais Inadequadas Não Classificadas em Outra Parte",
            "Z73.5 - Conflito Sobre o Papel Social, Não Classificado em Outra Parte",
            "Z73.6 - Limites Impostos às Atividades Por Invalidez",
            "Z73.8 - Outros Problemas Relacionados Com a Organização do Seu Modo de Vida",
            "Z73.9 - Problema Relacionado Com a Organização de Seu Modo de Vida Não Especificado",
            "Z74 - Problemas Relacionados Com a Dependência de Uma Pessoa Que Oferece Cuidados de Saúde",
            "Z74.0 - Mobilidade Reduzida",
            "Z74.1 - Necessidade de Assistência Com Cuidados Pessoais",
            "Z74.2 - Necessidade de Assistência a Domicílio, Sendo Que Nenhuma Pessoa do Lar é Capaz de Assegurar os Cuidados",
            "Z74.3 - Necessidade de Supervisão Contínua",
            "Z74.8 - Outros Problemas Relacionados Com a Dependência de Pessoa Que Oferece Cuidados de Saúde",
            "Z74.9 - Problema Não Especificado Relacionado Com a Dependência de Pessoa Que Oferece Cuidados de Saúde",
            "Z75 - Problemas Relacionados Com as Facilidades Médicas e Outros Cuidados de Saúde",
            "Z75.0 - Falta de Disponibilidade de Serviços Médicos no Domicílio",
            "Z75.1 - Pessoa Esperando Ser Admitida em Estabelecimento Médico Adequado em Outra Parte",
            "Z75.2 - Outro Período de Espera Para Investigação e Tratamento",
            "Z75.3 - Facilidades de Saúde Não Disponíveis ou Não Acessíveis",
            "Z75.4 - Outras Organizações de Cuidados Não Disponíveis ou Não Acessíveis",
            "Z75.5 - Cuidados Dispensados Para Ajudar às Famílias Durante as Férias",
            "Z75.8 - Outras Dificuldades Especificadas Relacionadas Com Facilidades Médicas e Com Outros Cuidados de Saúde",
            "Z75.9 - Problema Não Especificado Relacionado Com Facilidades Médicas e Com Outros Cuidados de Saúde",
            "Z76 - Pessoas em Contato Com os Serviços de Saúde em Outras Circunstâncias",
            "Z76.0 - Emissão de Prescrição de Repetição",
            "Z76.1 - Supervisão e Cuidado de Saúde de Crianças Assistidas",
            "Z76.2 - Supervisão de Cuidado de Saúde de Outras Crianças ou Recém-nascidos Sadios",
            "Z76.3 - Pessoa em Boa Saúde Acompanhando Pessoa Doente",
            "Z76.4 - Outros Pensionistas em Estabelecimentos de Cuidados de Saúde",
            "Z76.5 - Pessoa Fingindo Ser Doente (simulação Consciente)",
            "Z76.8 - Contatos Com Serviços de Saúde Por Outras Circunstâncias Especificadas",
            "Z76.9 - Pessoa em Contato Com Serviços de Saúde em Circunstâncias Não Especificadas",
            "Z80 - História Familiar de Neoplasia Maligna",
            "Z80.0 - História Familiar de Neoplasia Maligna de Órgãos Digestivos",
            "Z80.1 - História Familiar de Neoplasia Maligna de Traquéia, Brônquio e Pulmão",
            "Z80.2 - História Familiar de Neoplasia Maligna de Outros Órgãos Respiratórios e Intratorácicos",
            "Z80.3 - História Familiar de Neoplasia de Mama",
            "Z80.4 - História Familiar de Neoplasia Maligna de Órgãos Genitais",
            "Z80.5 - História Familiar de Neoplasia Maligna do Trato Urinário",
            "Z80.6 - História Familiar de Leucemia",
            "Z80.7 - História Familiar de Outras Neoplasias Malignas Dos Tecidos Linfático, Hematopoético e Correlacionados",
            "Z80.8 - História Familiar de Neoplasia Maligna de Outros Órgãos ou Aparelhos",
            "Z80.9 - História Familiar de Neoplasia Maligna Não Especificada",
            "Z81 - História Familiar de Transtornos Mentais e Comportamentais",
            "Z81.0 - História Familiar de Retardo Mental",
            "Z81.1 - História Familiar de Abuso de Álcool",
            "Z81.2 - História Familiar de Abuso de Fumo",
            "Z81.3 - História Familiar de Abuso de Outra Substância Psicoativa",
            "Z81.4 - História Familiar de Abuso de Outra Substância",
            "Z81.8 - História Familiar de Outros Transtornos Mentais e Comportamentais",
            "Z82 - História Familiar de Algumas Incapacidades e Doenças Crônicas Que Conduzem a Incapacitação",
            "Z82.0 - História Familiar de Epilepsia e de Outras Doenças do Sistema Nervoso",
            "Z82.1 - História Familiar de Cegueira e Perda de Visão",
            "Z82.2 - História Familiar de Surdez e Perda de Audição",
            "Z82.3 - História Familiar de Acidente Vascular Cerebral",
            "Z82.4 - História Familiar de Doença Isquêmica do Coração e Outras Doenças do Aparelho Circulatório",
            "Z82.5 - História Familiar de Asma e Outras Doenças Respiratórias Inferiores Crônicas",
            "Z82.6 - História Familiar de Artrite e Outras Doenças do Sistema Osteomuscular e Tecido Conjuntivo",
            "Z82.7 - História Familiar de Malformações e Deformações Congênitas e Anomalias Cromossômicas",
            "Z82.8 - História Familiar de Outras Incapacidades e Doenças Crônicas Que Conduzem a Incapacitação, Não Classificadas em Outra Parte",
            "Z83 - História Familiar de Outros Transtornos Específicos",
            "Z83.0 - História Familiar de Doença Pelo Vírus da Imunodeficiência Humana (HIV)",
            "Z83.1 - História Familiar de Outras Doenças Infecciosas e Parasitárias",
            "Z83.2 - História Familiar de Doenças do Sangue e Dos Órgãos Hematopoéticos e Alguns Transtornos Imunitários",
            "Z83.3 - História Familiar de Diabetes Mellitus",
            "Z83.4 - História Familiar de Outras Doenças Endócrinas, Nutricionais e Metabólicas",
            "Z83.5 - História Familiar de Outros Transtornos Dos Olhos e Dos Ouvidos",
            "Z83.6 - História Familiar de Doenças do Aparelho Respiratório",
            "Z83.7 - História Familiar Das Doenças do Aparelho Digestivo",
            "Z84 - História Familiar de Outras Afecções",
            "Z84.0 - História Familiar de Doenças da Pele e do Tecido Subcutâneo",
            "Z84.1 - História Familiar de Transtornos do Rim e Ureter",
            "Z84.2 - História Familiar de Outras Doenças do Aparelho Geniturinário",
            "Z84.3 - História Familiar de Consangüinidade",
            "Z84.8 - História Familiar de Outras Afecções Especificadas",
            "Z85 - História Pessoal de Neoplasia Maligna",
            "Z85.0 - História Pessoal de Neoplasia Maligna de Órgãos Digestivos",
            "Z85.1 - História Pessoal de Neoplasia Maligna de Traquéia, Brônquio e Pulmão",
            "Z85.2 - História Pessoal de Neoplasia Maligna de Outros Órgãos Respiratórios e Intratorácicos",
            "Z85.3 - História Pessoal de Neoplasia Maligna de Mama",
            "Z85.4 - História Pessoal de Neoplasia Maligna de Órgãos Genitais",
            "Z85.5 - História Pessoal de Neoplasia Maligna de Trato Urinário",
            "Z85.6 - História Pessoal de Leucemia",
            "Z85.7 - História Pessoal de Outras Neoplasias Malignas do Tecido Linfático, Hematopoético e Tecidos Correlatos",
            "Z85.8 - História Pessoal de Neoplasias Malignas de Outros Órgãos ou Aparelhos",
            "Z85.9 - História Pessoal de Neoplasia Maligna Não Especificada",
            "Z86 - História Pessoal de Algumas Outras Doenças",
            "Z86.0 - História Pessoal de Outras Neoplasias",
            "Z86.1 - História Pessoal de Doenças Infecciosas e Parasitárias",
            "Z86.2 - História Pessoal de Doenças do Sangue e Dos Órgãos Hematopoéticos e Alguns Transtornos Imunitários",
            "Z86.3 - História Pessoal de Doenças Endócrinas, Nutricionais e Metabólicas",
            "Z86.4 - História Pessoal de Abuso de Substâncias Psicoativas",
            "Z86.5 - História Pessoal de Outros Transtornos Mentais e Comportamentais",
            "Z86.6 - História Pessoal de Doenças do Sistema Nervoso e Dos Órgãos Dos Sentidos",
            "Z86.7 - História Pessoal de Doenças do Aparelho Circulatório",
            "Z87 - História Pessoal de Outras Doenças e Afecções",
            "Z87.0 - História Pessoal de Doenças do Aparelho Respiratório",
            "Z87.1 - História Pessoal de Doenças do Aparelho Digestivo",
            "Z87.2 - História Pessoal de Doenças da Pele e Tecido Subcutâneo",
            "Z87.3 - História Pessoal de Doenças do Sistema Osteomuscular e Tecido Conjuntivo",
            "Z87.4 - História Pessoal de Doenças do Aparelho Geniturinário",
            "Z87.5 - História Pessoal de Complicações da Gravidez, Parto e Puerpério",
            "Z87.6 - História Pessoal de Algumas Afecções Originadas no Período Perinatal",
            "Z87.7 - História Pessoal de Malformações e Deformidades Congênitas e Anomalias Cromossômicas",
            "Z87.8 - História Pessoal de Outras Afecções Especificadas",
            "Z88 - História Pessoal de Alergia a Drogas, Medicamentos e a Substâncias Biológicas",
            "Z88.0 - História Pessoal de Alergia à Penicilina",
            "Z88.1 - História Pessoal de Alergia a Outro Agente Antibiótico",
            "Z88.2 - História Pessoal de Alergia às Sulfonamidas",
            "Z88.3 - História Pessoal de Alergia a Outros Agentes Antiinfecciosos",
            "Z88.4 - História Pessoal de Alergia a Agente Anestésico",
            "Z88.5 - História Pessoal de Alergia a Agente Narcótico",
            "Z88.6 - História Pessoal de Alergia a Agente Analgésico",
            "Z88.7 - História Pessoal de Alergia a Soro e à Vacina",
            "Z88.8 - História Pessoal de Alergia a Outros Drogas, Medicamentos e Substâncias Biológicas",
            "Z88.9 - História Pessoal de Alergia a Drogas, Medicamentos e Substâncias Biológicas Não Especificadas",
            "Z89 - Ausência Adquirida de Membros",
            "Z89.0 - Ausência Adquirida de Dedo(s) da Mão (inclusive Polegar) Unilateral",
            "Z89.1 - Ausência Adquirida de Mão e Punho",
            "Z89.2 - Ausência Adquirida de Braço Acima do Punho",
            "Z89.3 - Ausência Adquirida de Ambos Membros Superiores (qualquer Nível)",
            "Z89.4 - Ausência Adquirida de pé e Tornozelo",
            "Z89.5 - Ausência Adquirida da Perna ao Nível ou Abaixo do Joelho",
            "Z89.6 - Ausência Adquirida da Perna Acima do Joelho",
            "Z89.7 - Ausência Adquirida de Ambos Membros Inferiores (qualquer Nível, Exceto Somente Artelhos)",
            "Z89.8 - Ausência Adquirida Dos Membros Superiores e Inferiores (qualquer Nível)",
            "Z89.9 - Ausência Adquirida de Membro Não Especificado",
            "Z90 - Ausência Adquirida de Órgãos Não Classificados em Outra Parte",
            "Z90.0 - Ausência Adquirida de Parte da Cabeça e do Pescoço",
            "Z90.1 - Ausência Adquirida Da(s) Mama(s)",
            "Z90.2 - Ausência Adquirida (de Parte) do Pulmão",
            "Z90.3 - Ausência Adquirida de Parte do Estômago",
            "Z90.4 - Ausência Adquirida de Outras Partes do Trato Digestivo",
            "Z90.5 - Ausência Adquirida do Rim",
            "Z90.6 - Ausência Adquirida de Outras Partes do Trato Urinário",
            "Z90.7 - Ausência Adquirida de Órgão(s) Genital(is)",
            "Z90.8 - Ausência Adquirida de Outros Órgãos",
            "Z91 - História Pessoal de Fatores de Risco, Não Classificados em Outra Parte",
            "Z91.0 - História Pessoal de Alergia Exceto a Drogas e a Substâncias Biológicas",
            "Z91.1 - História Pessoal de Não Aderência a Tratamento ou Regime Médico",
            "Z91.2 - História Pessoal de má Higiene Pessoal",
            "Z91.3 - História Pessoal de Ciclo Vigília-sono Anormal",
            "Z91.4 - História Pessoal de Trauma Psicológico Não Classificado em Outra Parte",
            "Z91.5 - História Pessoal de Auto Agressão",
            "Z91.6 - História Pessoal de Outros Traumas Físicos",
            "Z91.8 - História Pessoal de Outros Fatores de Risco Especificados Não Classificados em Outra Parte",
            "Z92 - História Pessoal de Tratamento Médico",
            "Z92.0 - História Pessoal de Anticoncepção",
            "Z92.1 - História Pessoal de Uso de Longo Prazo (atual) de Anticoagulantes",
            "Z92.2 - História Pessoal de Uso de Longo Prazo (atual) de Outros Medicamentos",
            "Z92.3 - História Pessoal de Irradiação",
            "Z92.4 - História Pessoal de Cirurgia de Grande Porte Não Classificada em Outra Parte",
            "Z92.5 - História Pessoal de Medidas de Reabilitação",
            "Z92.6 - História Pessoal de Quimioterapia Para Doença Neoplásica",
            "Z92.8 - História Pessoal de Outros Tratamentos Médicos",
            "Z92.9 - História Pessoal de Tratamento Médico Não Especificado",
            "Z93 - Orifícios Artificiais",
            "Z93.0 - Traqueostomia",
            "Z93.1 - Gastrostomia",
            "Z93.2 - Ileostomia",
            "Z93.3 - Colostomia",
            "Z93.4 - Outros Orifícios Artificiais do Trato Gastrointestinal",
            "Z93.5 - Cistostomia",
            "Z93.6 - Outros Orifícios Artificiais do Aparelho Urinário",
            "Z93.8 - Outros Orifícios Artificiais",
            "Z93.9 - Orifício Artificial Não Especificado",
            "Z94 - Órgãos e Tecidos Transplantados",
            "Z94.0 - Rim Transplantado",
            "Z94.1 - Coração Transplantado",
            "Z94.2 - Pulmão Transplantado",
            "Z94.3 - Coração e Pulmões Transplantados",
            "Z94.4 - Fígado Transplantado",
            "Z94.5 - Pele Transplantada",
            "Z94.6 - Osso Transplantado",
            "Z94.7 - Córnea Transplantada",
            "Z94.8 - Outros Órgãos e Tecidos Transplantados",
            "Z94.9 - Órgão e Tecido Não Especificado Transplantado",
            "Z95 - Presença de Implantes e Enxertos Cardíacos e Vasculares",
            "Z95.0 - Presença de Marca-passo Cardíaco",
            "Z95.1 - Presença de Enxerto de Ponte (bypass) Aortocoronária",
            "Z95.2 - Presença de Prótese de Válvula Cardíaca",
            "Z95.3 - Presença de Valva Cardíaca Xenógena",
            "Z95.4 - Presença de Outras Substituições Valvares Cardíacas",
            "Z95.5 - Presença de Implante e Enxerto de Angioplastia Coronária",
            "Z95.8 - Presença de Outros Implantes e Enxertos Cardíacos e Vasculares",
            "Z95.9 - Presença de Implante e Enxerto Cardíaco e Vascular Não Especificado",
            "Z96 - Presença de Outros Implantes Funcionais",
            "Z96.0 - Presença de Implantes Urogenitais",
            "Z96.1 - Presença de Lente Intra-ocular",
            "Z96.2 - Presença de Implante Otológicos e Audiológicos",
            "Z96.3 - Presença de Laringe Artificial",
            "Z96.4 - Presença de Implantes Endócrinos",
            "Z96.5 - Presença de Raiz Dentária e Implantes Mandibulares",
            "Z96.6 - Presença de Implantes Articulares Ortopédicos",
            "Z96.7 - Presença de Outros Implantes de Osso e Tendão",
            "Z96.8 - Presença de Outros Implantes Funcionais Especificados",
            "Z96.9 - Presença de Implante Funcional Não Especificado",
            "Z97 - Presença de Outros Dispositivos Protéticos",
            "Z97.0 - Presença de Olho Artificial",
            "Z97.1 - Presença de Membro Artificial (completo) (parcial)",
            "Z97.2 - Presença de Dispositivo Protético Dentário (completo) (parcial)",
            "Z97.3 - Presença de Óculos e de Lentes de Contato",
            "Z97.4 - Presença de Aparelho Externo de Surdez",
            "Z97.5 - Presença de Dispositivo Anticoncepcional Intra-uterino (DIU)",
            "Z97.8 - Presença de Outros Dispositivos Especificados",
            "Z98 - Outros Estados Pós-cirúrgicos",
            "Z98.0 - Derivação Intestinal ou Anastomose Intestinal",
            "Z98.1 - Artrodese",
            "Z98.2 - Presença de Dispositivo de Drenagem do Líquor",
            "Z98.8 - Outros Estados Pós-cirúrgicos Especificados",
            "Z99 - Dependência de Máquinas e Dispositivos Capacitantes, Não Classificados em Outra Parte",
            "Z99.0 - Dependência de Aspirador",
            "Z99.1 - Dependência de Respirador",
            "Z99.2 - Dependência de Diálise Renal",
            "Z99.3 - Dependência de Cadeira de Rodas",
            "Z99.8 - Dependência de Outras Máquinas e Aparelhos Capacitantes",
            "Z99.9 - Dependência de Máquina e Aparelho Capacitante Não Especificado"
        ]);


    const removerAcentos = (texto) => {
        return unorm.nfd(texto).replace(/[\u0300-\u036f]/g, '');
    };
    const handleResultClick = (result) => {
        setCentralFolderName(result);
    };
    const resultadosFiltrados = nomePatologias.filter((result) =>
        removerAcentos(result.toLowerCase()).includes(removerAcentos(filtro.toLowerCase()))
    );
    return (
        <>
            <div className="col-md-12 collapse" >             
                {resultadosFiltrados.slice(0, resultadosPorPagina).map((result, index) => (
                    <Link
                        key={index}
                        className="d-flex flex-wrap col-md-12"
                        onClick={() => handleResultClick(result)}
                    >
                        <p>
                            <span className="bullet-point">&#8226;</span>
                            <small>{result}</small>
                        </p>
                    </Link>
                ))}
            </div>
        </>)
}




export default NomePatologias;