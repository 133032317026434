import React from 'react';
import { Image, Typography } from 'antd';

const { Title } = Typography;

export const DocumentosSoftwareAnaliseFacialAnalisesDoSorriso = () => (
  <section style={{ marginTop: '20px' }}>
    <Title level={3}>Análises do Sorriso</Title>
    <div style={{ backgroundColor: '#fff3cd', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
      <strong>⚠️ IMPORTANTE:</strong> Leia antes de começar: Modelo de I.A. não disponível para o cliente
      <ul>
        <li>Testando modelo de I.A.</li>
      </ul>
    </div>
    <ul>
      <li><strong>Detecção, Segmentação e Classificação:</strong> Identificação de pontos de referência no sorriso.</li>
      <li><strong>Análise de Características do Sorriso:</strong> Avaliação de simetria e proporções do sorriso.</li>
      <li><strong>Sugestões de Reabilitação:</strong> Recomendações para reabilitações, uso de aparelhos ortodônticos e cirurgias estéticas.</li>
    </ul>
    <div className='row text-center'>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/s1.jpg')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Pontos</small>
      </div>
      <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
        <Image src={require('../assets/s2.jpg')} alt="Exemplo 2" width={50} height={50} /><br />
        <small>Linhas</small>
      </div>
    </div>
  </section>
);

export default DocumentosSoftwareAnaliseFacialAnalisesDoSorriso;
