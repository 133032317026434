import axios from "axios";

let baseURL = "https://www.thotiacorp.com.br/api/";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:8484/";
  //Teste de ambiente do projeto - ver aonde ele está rodando
  //alert('Ambiente http://localhost:8383/') 
}

export const api = axios.create({
  baseURL: baseURL,
});

export const createSession = async (email, password) => {
  return api.post("/post_user_login", { email, password })
}

export const getUsers = async () => {
  return api.get("/user");
}

export const register = async (email, name, password, cpf, address, phone, dataNascimento, user_type, convite_code) => {
  return api.post("/post_user", { email, name, password, cpf, address, phone, dataNascimento, user_type, convite_code });
}

export const createSchedule = async (weekday, startTime, endTime) => {
  return api.post("/shedule", {weekday, startTime, endTime });
}
export const buscarPacientesApi = async (nomePaciente) => {
  return api.post("/buscarPacientesPorNome", {nomePaciente });
}


export default api;
export { baseURL };