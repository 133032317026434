import { InvitationList } from "./partials/InvitationList";
import { NavUserMain, FooterUser, Nav, NavBreadcrumb } from "../../../../components";
import { NotificationSystem } from "./partials/NotificationSystem";
export const Notificacoes = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <NavUserMain />
            <section className="w-100">
                <div className=' w-100' >
                    <NavBreadcrumb icon={'user fa'} />
                    <NotificationSystem />
                    <InvitationList />
                    <FooterUser />
                </div>
            </section>
        </div>
    </>)
}