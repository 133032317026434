import { Modal, Spin, notification } from 'antd';
import React from 'react';

export const LoadingModal = ({ visible, message = 'Carregando...', children, onClose }) => {

  const handleClose = () => {
    notification.info({
      message: 'Aviso',
      description: 'O modal foi fechado, mas a operação continua em andamento.',
      placement: 'topRight',
      duration: 0, // Notificação permanece aberta até ser fechada manualmente

    });
    onClose(); // Chama a função onClose passada como prop
  };

  return (
    <Modal
      open={visible}
      footer={null}
      closable={true} // O usuário pode fechar manualmente
      centered
      onCancel={handleClose} // Ação ao fechar manualmente, chama handleClose
    >
      <div style={{ textAlign: 'center' }}>
        <Spin size="large" />
        <p>{message}</p>
      </div>
      {children}
    </Modal>
  );
};

export default LoadingModal;
