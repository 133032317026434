import React, { useState } from 'react';
const PoliticaDePrivacidade = () => {
  const [showModules, setShowModules] = useState(false);
  const toggleModules = () => {
    setShowModules(!showModules);
  };
  return (
    <div className='text-justify'>
      <abbr title="Clique para ver mais informações"> <h5 className={`${showModules ? 'font-weight-bold text-justify text-success mb-5' : 'font-weight-bold text-justify text-primary mt-2'}`} onClick={toggleModules}>Política de Privacidade &nbsp;<i className='fa fa-info-circle'></i></h5></abbr>
      {showModules && <>
        <p>
          Bem-vindo ao nosso serviço de politica de privacidade. Nossa prioridade é garantir a proteção de suas informações pessoais e manter sua privacidade. Esta política de privacidade tem como objetivo informar como coletamos, usamos e compartilhamos suas informações quando você usa nossos serviços. Ao utilizar nossos serviços, você concorda com as práticas descritas nesta política.
        </p>
        <h5 className='font-weight-bold mt-5'  >Coleta de Informações</h5>
        <p>
          Ao se cadastrar e usar nossos serviços, podemos coletar as seguintes informações:
        </p>
        <ol className='ml-3'>
          <li><strong>Informações Pessoais:</strong> Isso pode incluir seu nome, endereço de e-mail, informações de contato, data de nascimento e outras informações pessoais que você fornecer voluntariamente.</li>
          <li><strong>Informações de Saúde:</strong> Se você fornecer informações de saúde, saiba que essas informações serão mantidas de acordo com as regulamentações brasileiras, incluindo a Rede Nacional de Dados em Saúde (RNDS). Essas informações serão usadas apenas para fins de saúde e de acordo com a legislação aplicável.</li>
          <li><strong>Informações de Uso:</strong> Coletamos informações sobre como você interage com nossos serviços, incluindo registros de atividade, endereço IP, tipo de navegador, páginas visitadas e outros dados de uso.</li>
        </ol>
        <h5 className='font-weight-bold  mt-5'  >Uso das Informações</h5>
        <p>As informações que coletamos podem ser usadas para os seguintes fins:</p>
        <ol className='ml-3'>
          <li><strong>Fins Comerciais:</strong> Podemos usar suas informações para fornecer, manter e melhorar nossos serviços, bem como para fins comerciais legítimos, como análises de mercado, publicidade e marketing.</li>
          <li><strong>Estudos e Pesquisas:</strong> Podemos usar suas informações para realizar estudos e pesquisas, sempre protegendo sua privacidade e anonimizando os dados, quando necessário.</li>
          <li><strong>Compartilhamento de Informações:</strong> Podemos compartilhar suas informações com terceiros para cumprir obrigações legais, proteger nossos direitos, garantir a segurança do serviço ou com seu consentimento expresso.</li>
        </ol>
        <h5 className='font-weight-bold  mt-5'  >Segurança de Dados</h5>
        <p>Valorizamos a segurança de suas informações e implementamos medidas técnicas e organizacionais para proteger seus dados contra acesso não autorizado, uso indevido ou divulgação.</p>
        <h5 className='font-weight-bold  mt-5'  >Seus Direitos</h5>
        <p>Você tem o direito de acessar, retificar, excluir ou limitar o processamento de suas informações pessoais. Para exercer esses direitos ou fazer perguntas sobre nossas práticas de privacidade, entre em contato conosco através dos dados de contato fornecidos abaixo.</p>
        <h5 className='font-weight-bold  mt-5'  >Alterações na Política de Privacidade</h5>
        <p>Podemos atualizar esta política periodicamente para refletir mudanças em nossas práticas de privacidade. Recomendamos que você revise esta política regularmente para se manter informado sobre como suas informações estão sendo tratadas.</p>
        <h5 className='font-weight-bold mt-5'  >Contato</h5>
        <p>Se você tiver alguma dúvida ou preocupação sobre esta política de privacidade, entre em contato conosco. Esta política de privacidade é eficaz a partir da data da última atualização mencionada acima.</p>
      </>}
    </div>
  );
};

export default PoliticaDePrivacidade;
