import { baseURL } from "../../../../api.js";
import { axiosHeadersToken } from "../../../../contexts";
import buscarAtestadosService from "./buscarAtestadosService";

export const editarAtestadosService = async (values, setModalVisible, professional_id, setAtestados) => {
  try {
    const response = await axiosHeadersToken().post(`${baseURL}put_atestados/${professional_id}`, values, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response.data.message);
    setModalVisible(false);
    buscarAtestadosService({ setAtestados, professional_id });
    return response;
  } catch (error) {
    console.error('Error editing atestado:', error);
    setModalVisible(false);
    throw error; 
  }
};

export default editarAtestadosService;
