import React, { useState, useEffect, useContext, useCallback } from 'react';
import { baseURL } from '../../../../../../services/api';
import ArtigosCard from './ArtigosCard';
import { Pagination } from 'antd';
import { post_cart } from '../../../../Carrinho/partials/services/post_cart';
import { AuthContext } from '../../../../../../contexts/auth';
export const ResultadoBuscaArtigos = () => {
  const [artigos, setArtigos] = useState([]);
  const [totalArtigos, setTotalArtigos] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [tituloFilter] = useState('');
  const [resumoFilter] = useState('');
  const [statusFilter] = useState('');
  const resultadosPorPagina = 10;
  const [error, setError] = useState(null);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();
  const handleAddToCart = (product) => {
    post_cart(product, cart, setCart, user_id, baseURL);
  };

 
  const fetchArtigos = useCallback(() => {
    const queryParams = new URLSearchParams({
      pagina: paginaAtual,
      resultadosPorPagina: resultadosPorPagina,
      titulo: tituloFilter,
      resumo: resumoFilter,
      status: statusFilter,
    }).toString();
  
    fetch(`${baseURL}get_pesquisas?${queryParams}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na busca de artigos');
        }
        return response.json();
      })
      .then(data => {
        setArtigos(data.artigos);
        setTotalArtigos(data.totalArtigos);
        setError(null); // Limpa qualquer erro anterior
      })
      .catch(error => {
        console.error('Erro ao obter os dados dos artigos:', error);
        setError('Ocorreu um erro ao buscar os artigos. Por favor, tente novamente mais tarde.');
      });
  }, [paginaAtual, resultadosPorPagina, tituloFilter, resumoFilter, statusFilter, setArtigos, setTotalArtigos, setError]);
  useEffect(() => {
    fetchArtigos();
  }, [fetchArtigos]);
  const handlePagination = (pagina) => {
    setPaginaAtual(pagina);
  };
  const filtrarArtigos = () => {
    let artigosFiltrados = artigos.filter((artigo) => {
      return (
        (!tituloFilter || artigo.titulo.toLowerCase().includes(tituloFilter.toLowerCase())) &&
        (!resumoFilter || artigo.resumo.toLowerCase().includes(resumoFilter.toLowerCase())) &&
        (!statusFilter || artigo.status.toLowerCase().includes(statusFilter.toLowerCase()))
      );
    });
    return artigosFiltrados;
  };
  return (<>
    <div className='  py-3' style={{ borderRadius: "0px" }}>
      <div className="mt-3 col-md-12  " id="divConteudo">
        {error ? (
          <p>{error}</p>
        ) : (<>
          <small className='font-weight-bold text-left bg-light'>Total Artigos {totalArtigos}</small>
          <div className="col-md-12" style={{ padding: '0px' }}>
            <div className="d-flex flex-wrap justify-content-center">
              {artigos.length > 0 ? (
                filtrarArtigos().map((artigo) => (
                  <ArtigosCard
                    key={artigo.id}
                    product_id={artigo.id}
                    product_code_id={'Artigo' + artigo.id}

                    products_name={artigo.titulo}
                    description={artigo.resumo}
                    value={0}  // Você precisa ajustar o valor conforme necessário
                    products_type={artigo.status}
                    onAddToCart={handleAddToCart}
                    company_name=""  // Ajuste conforme necessário
                  />
                ))
              ) : (
                <p>Nenhum artigo encontrado.</p>
              )}
            </div> </div>
        </>
        )}
        {totalArtigos <= resultadosPorPagina ? null : (<>
          <div className="col-md-12 text-center mt-5 mb-3 text-dark font-weight-bold">
            Total de páginas: {Math.ceil(totalArtigos / resultadosPorPagina)}
          </div>
          <Pagination
            current={paginaAtual}
            total={totalArtigos}
            pageSize={resultadosPorPagina}
            onChange={handlePagination}
            className="pagination justify-content-center mb-5"
          />
        </>)}
      </div>
    </div>
  </>)
}