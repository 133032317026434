//TODO: Mudar esse método depois para redirecionar corretamente para as telas de Exames.
import React from "react";
import { useLocation } from "react-router-dom";
import EVAComponent from "../../Consultas/EVAComponent";
import { CardDirecionamento, FooterUser, NavUserMain, NavBreadcrumb } from "../../../../../components";
import Agenda from "../../Agenda/Agenda";
//import ServicosProcedimentosList from '../../../../Public/Servicos/components/Procedimentos/ServicosProcedimentosList';



function RedirecionarParaBuscarPaciente() {
  window.location.href = "/usuario/consultas/buscar-paciente";
}

export const MarcarExamesCards = () => {
  const location = useLocation();

  return (
    <>
      <div className="d-flex">
        <NavUserMain />
        <div className="w-100">
          <NavBreadcrumb
            text={` ${
              location.pathname === "/usuario/consultas/marcar"
                ? "Marcar Consultas"
                : "Marcar Exames"
            }`}
            icon={"calendar fa"}
          />
          <div className="py-3 ">
            <div className="text-center">
              <div className="container-fluid">
                <div className="row d-inline-flex justify-content-center w-100">
                  <CardDirecionamento
                    text={"PARTICULAR"}
              
                    onClick={() => RedirecionarParaBuscarPaciente()}
                    icon={"mt-3 fa fa-money fa-3x text-white"}
                  />
                  <CardDirecionamento
                    text={"CONVÊNIO"}
              
                    onClick={() => RedirecionarParaBuscarPaciente()}
                    icon={"mt-3 fa fa-address-card fa-3x text-white"}
                  />
                  <CardDirecionamento
                    text={"PÚBLICO"}
              
                    onClick={() => RedirecionarParaBuscarPaciente()}
                    icon={"mt-3 fa fa-institution fa-3x text-white"}
                  />
                  <EVAComponent />
                  <div
                    id="ServicosProcedimentosList"
                    className="collapse col-md-12"
                  >
                    <div className="col-md-12 mt-5 py-3 mx-auto">
                      <Agenda />
                    </div>
                    <div className="col-md-12 mt-5 py-3 mx-auto">
                      {/*   <ServicosProcedimentosList />*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterUser />
        </div>
      </div>
    </>
  );
};
export default MarcarExamesCards;