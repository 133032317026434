import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export const NotificationSystem = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Verificando o conteúdo de localStorage
    const professionalDataRaw = localStorage.getItem('professionalData');
    console.log('Conteúdo de localStorage para "professionalData":', professionalDataRaw);

    if (!professionalDataRaw) {
      // Mostra o card se professionalData não existir no localStorage
      document.getElementById('notification-card').style.display = 'block';
    } else {
      try {
        // Tentando converter para JSON se existir
        const professionalData = JSON.parse(professionalDataRaw);
        console.log('Objeto professionalData após JSON.parse:', professionalData);

        // Exibe o card se user_type for 'usuario'
        if (professionalData.user_type === 'usuario') {
          document.getElementById('notification-card').style.display = 'block';
        }
      } catch (error) {
        console.error('Erro ao fazer parse de professionalData:', error);
      }
    }
  }, []);


  const handleRedirect = () => {
    navigate('/usuario/cadastro/profissional');
  };

  return (
    <Card id="notification-card" style={{ display: 'none', marginTop: 16 }}>
      <p>Caso seja um profissional. Por favor, complete seu cadastro profissional. Com cadastro profissional você terá acesso a:</p>
      <li>Venda de serviços</li>
      <li>Venda de produtos</li>
      <li>Uso das IA</li>
      <Button type="primary" onClick={handleRedirect}>
        Cadastro Profissional
      </Button>
    </Card>
  );
};

export default NotificationSystem;
