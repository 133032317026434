import React from 'react';
import { ApresentacaoServicos } from '../layout/vender/ApresentacaoServicos';
import SolucaoGerencimamentoAvancado from '../layout/vender/layout/GerenciamentoAvancado';
import SolucaoIAAvancadaServicos from '../layout/vender/layout/SolucaoIAAvancadaServicos';
import PlanosPrecosServicos from '../layout/vender/layout/PlanosPrecosServicos';
import ImagensSistemaServicos from '../layout/vender/layout/ImagensSistemaServicos';
import DashboardService from '../layout/vender/layout/DashboardService';
import AgendaCompartilhada from '../layout/vender/layout/AgendaCompartilhada';
import ServicosList from '../layout/comprar/ServicosList';

export const items = [
  {
    id: 'servicos', title: 'Comprar Serviços', component: <ServicosList />,
    subItems: [],
  },
  {
    id: 'apresentacaoServicos', title: 'Vender Serviços', component: <ApresentacaoServicos />,
    subItems: [
      {
        id: 'SolucaoGerencimamentoAvancado', title: 'Gerenciamento', component: (
          <>
            <SolucaoGerencimamentoAvancado />
          </>
        ),
      },
      {
        id: 'AgendaService', title: 'Agenda', component: (
          <>
            <AgendaCompartilhada />
          </>
        ),
        subItems: [],
      },
      {
        id: 'DashboardService', title: 'Dashboard', component: (
          <>
            <DashboardService />
          </>
        ),
        subItems: [],
      },
      {
        id: 'SolucaoIAAvancadaServicos', title: 'I.A.', component: (
          <>
            <SolucaoIAAvancadaServicos />
          </>
        ),
        subItems: [],
      },
      {
        id: 'ImagensSistemaServicos', title: 'Imagens', component: (
          <>
            <ImagensSistemaServicos />
          </>
        ),
        subItems: [],
      },
      {
        id: 'PlanosPrecosServicos', title: 'Planos e Preços', component: (
          <>
            <PlanosPrecosServicos />
          </>
        ),
        subItems: [],
      },
    ],
  },
];

export default items;
