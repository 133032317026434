import React from 'react';
import '../styles/TRL.css';
import { TitleL2 } from '../../../../../components';

const trlLevels = [
  { level: 1, description: 'Princípios observados e relatados.' },
  { level: 2, description: 'Conceito de tecnologia e/ou aplicação formulados.' },
  { level: 3, description: 'Prova de conceito analítica e experimental de função e/ou característica crítica.' },
  { level: 4, description: 'Validação de componente e/ou protótipo em ambiente de laboratório.' },
  { level: 5, description: 'Validação de componente e/ou protótipo em ambiente relevante.' },
  { level: 6, description: 'Demonstração de modelo ou protótipo de sistema/subsistema em ambiente relevante.' },
  { level: 7, description: 'Demonstração de protótipo de sistema em ambiente operacional.' },
  { level: 8, description: 'Sistema real completo e qualificado por meio de testes e demonstrações.' },
  { level: 9, description: 'Sistema real comprovado por operações de missão bem-sucedidas.' },
];

const TRL = () => {
  return (
    <div className="trl-container mt-5">
      <TitleL2 style={{color:'white'}}>Níveis de Maturidade Tecnológica (TRL)</TitleL2>
      <div className="trl-levels">
        {trlLevels.map((level) => (
          <div key={level.level} className="trl-level">
            <div className={`trl-number trl-number-${level.level}`}>{level.level}</div>
            <div className="trl-description">{level.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TRL;
