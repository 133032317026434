
import { ContainerMenuItens } from './ContainerMenuItens'
import { DownItem } from './DownItem'
import { TableContent } from './TableContent'

export const MenuItens = {
  Container: ContainerMenuItens,
  SprintItem: DownItem,
  TableContent: TableContent
}
