import { CheckCircleOutlined } from "@ant-design/icons";
import { Input, Tag } from "antd";
import { ButtonAPI, TitleL2 } from "../../../../../../../../../components";
import ProcurarEquipamento from "./ProcurarEquipamento";
export const IframeIAImagem = (props) => {
  return (
    <>
    <Tag className='mt-3 mb-3 d-flex ' style={{
      width: '100%',
      padding: '60px',
      whiteSpace: 'pre-line'
    }} icon={<CheckCircleOutlined style={{ fontSize: '48px', paddingInlineEnd: '50px' }} />} color="green">
      <div className='col-md-12'>
        <TitleL2 style={{ color: "green", textAlign: "left" }}>Como implementar</TitleL2>
        <li className='text-success'> Libere o CORS no servidor da THOT para THOT responder seu sistema. Informe a url principal do seu sistema</li>
        <Input placeholder='https://exemplo.com.br' />
        <li className='text-dark font-weight-bold mt-3'> Termos da ferramenta de I.A. de classificação de imagens no code da THOT I.A. CORP.</li>
        <li className='text-dark '> Ao adicionar essa página no seu sistema você assumiu compartilhamento total de dados com a THOT I.A. CORP.</li>
        <li className='text-dark '> Você assume total responsabilidade pelo uso dos sistemas e está pronto para desfrustar dos beneficíos desse</li>
        <li className='text-dark'> Lembre-se a THOT I.A. CORP. não se responsabiliza pelo uso da ferramenta ou venda dessa ferramenta para 3º ou ainda serviços de 3º</li>
        <li className='text-dark'> A THOT I.A. CORP. é uma gerenciadora digital administrativa qualquer ato irregular detectado deve ser manifestado de imediato ao jurídico do país competente para notificar o 3º servidor e eventualmente a THOT I.A. CORP para esta fornecer toda e qualquer documentação solicitada judicialmente</li>
        <li className='text-warning'> Você também pode discordar a qualquer momento dos nossos termos e inativar seu serviços</li>
        <li className='text-dark py-3 text-center'><strong>  <Input className='text-left' type='checkbox' /> Eu aceito os termos</strong>{ }</li>
        <li className='text-dark py-3'><strong>Data aceita: </strong>{ }</li><ButtonAPI>Gerar script</ButtonAPI>
      </div>
    </Tag>
    {props.centralFolderName &&
      <div className="col-md-12 mx-auto mt-3 d-flex flex-column align-items-stretch border bg bg-light rounded">
        <ProcurarEquipamento />
      </div>
    }</>
  );
};

export default IframeIAImagem;
