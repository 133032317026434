import React from 'react';
import { Busca } from '../../layout/comprar/Busca';
import SobrePublicacoesInfo from '../../layout/vender/SobrePublicacoesInfo';
import ArticleSubmissionRequirements from '../../layout/vender/ArticleSubmissionRequirements';

export const items = [
  {
    id: 'publicArtigos',
    title: 'Artigos',
    component: <Busca />,
    subItems: [],
  },

  {
    id: 'SobrePublicacoesInfo',
    title: 'Politica de Publicações',
    component:   <SobrePublicacoesInfo />,
    subItems: [],
  },
  {
    id: 'ArticleSubmissionRequirements',
    title: 'Como publicar',
    component: <ArticleSubmissionRequirements />,
    subItems: [],
  }
];

export default items;
