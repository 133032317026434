import { Input, Form, Divider } from 'antd';
import { TitleL2 } from '../../../../../components';
const { TextArea } = Input;
export const Motivos = ({ talentos, setTalentos }) => {
    const handleChange = (e) => {
        setTalentos(e.target.value);
    };
    return (
        <div className="motivos">           
            <Form layout="vertical"  >
                <TitleL2>Dados do Colaborador</TitleL2>
                <Divider/>
                <Form.Item label="Conte-nos sobre seus talentos">
                    <TextArea
                        rows={6}
                        value={talentos}
                        onChange={handleChange}
                        placeholder="Escreva sobre seus talentos e experiências..."
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
export default Motivos;