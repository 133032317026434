import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from '../../../../../../services/api';
import { Nav, Footer, ButtonAPI, Carousel3D, NavUserMain, NavBreadcrumb, MarkdownRenderer } from '../../../../../../components/index'
import PdfViewer from '../../../../../../components/pdf/PdfViewer';
import SearchMain from '../../../../Search/partials/layout/SearchMain'
import { AuthContext } from '../../../../../../contexts/auth';
import RenderImageGrid from '../../../../../../components/images/RenderImageGrid';
import { post_cart } from '../../../../Carrinho/partials/services/post_cart';
export const ServicesDetailsPage = () => {
  const { services_id } = useParams();
  const [service, setservice] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [quantidade, setQuantidade] = useState(1);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [resultadosPorPagina, setResultsPorPagina] = useState(10);
  const [bulaInfo, setBulaInfo] = useState('');
  const location = useLocation();
  const { search } = location;
  const fetchResults = useCallback(async () => {
    if (!searchTerm) return;
    setSearching(true);
    try {
      const response = await axios.get(`${baseURL}get_products`, {
        params: { nome: searchTerm, pagina: page }
      });
      const newResults = response.data.produtos;
      setResults((prevResults) => [...prevResults, ...newResults]);
      const totalResults = response.data.totalProdutos;
      const remainingResults = totalResults - page * resultadosPorPagina;
      setHasMore(remainingResults > 0);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setSearching(false);
  }, [searchTerm, page, resultadosPorPagina,]);
  const loadMore = () => {
    setPage(page + 1);
    setResultsPorPagina(resultadosPorPagina + 10);
  };
  useEffect(() => {
    fetchResults();
  }, [fetchResults]);
  const handleAddToCart = () => {
    if (service) {
      alert(JSON.stringify(service));
      const serviceToAdd = {
        id: service.id,
        product_id: service.id,
        product_code_id: service.id + '_services',
        products_name: service.services_name,
        description: service.services_description,
        image: service.services_image,
        value: service.value,
        services_type: service.type,
        company_unit_id: service.company_unit_id,
        quantity: quantidade,
        table: 'service'
      };
      post_cart(serviceToAdd, cart, setCart, user_id, baseURL);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };
  const handleComprarAgora = () => {
    if (service) {
      const serviceToAdd = {
        id: service.id,
        product_id: service.id,
        product_code_id: service.id + '_services',
        products_name: service.services_name,
        description: service.services_description,
        image: service.services_image,
        value: service.value,
        services_type: service.type,
        company_unit_id: service.company_unit_id,
        quantity: quantidade,
        table: 'service'
      };
      post_cart(serviceToAdd, cart, setCart, user_id, baseURL);
      const urlCarrinho = '/carrinho';
      window.location.href = urlCarrinho;
    }
  };

  useEffect(() => {
    async function fetchservice() {
      try {
        const response = await axios.get(`${baseURL}get_services_id/${services_id}`);
        const products_bula_id = response.data.products_bula_id;
        setservice(response.data);
        console.log("response bula" + response)
        console.log("producto bula id" + products_bula_id)
        if (products_bula_id) {
          // Buscar as informações da bula usando o products_bula_id
          const bulaResponse = await axios.get(`${baseURL}get_services_bula_id/${products_bula_id}`);
          const bulaData = bulaResponse.data;
          setBulaInfo(bulaData);
        }

        // Tratamento de imagens
        let serviceImages = [];
        if (typeof response.data.services_image === 'string') {
          try {
            serviceImages = JSON.parse(response.data.services_image);
          } catch (error) {
            console.error('Error parsing JSON string:', error);
            serviceImages = [{ url: response.data.services_image, id: 1 }];
          }
        } else if (Array.isArray(response.data.services_image)) {
          serviceImages = response.data.services_image;
        }

        // Certificar que a lista de imagens não cause loop infinito
        if (serviceImages.length > 0) {
          while (serviceImages.length < 9) {
            serviceImages.push(...serviceImages);
          }
          serviceImages = serviceImages.slice(0, 9);
          setImages(serviceImages);
        }
                
        if (response.data.services_name) {
          localStorage.setItem('searchTerm', JSON.stringify(response.data.services_name));
          setSearchTerm(response.data.services_name);
        }
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    }
    fetchservice();
  }, [navigate, services_id, search]);

  useEffect(() => {
    async function fetchBulaInfo() {
      try {
        const response = await axios.get(`${baseURL}get_services_bula_id/${services_id}`);
        const data = response.data;
        setBulaInfo(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBulaInfo();
  }, [services_id]);
  // const pdfUrl = `${baseURL}bula/${bulaInfo.bulaPacienteFileName}`;
  const pdfUrl = bulaInfo.bulaPacienteFileName;
  if (!service) {
    return <div>Loading...</div>;
  }
  const currentURL = window.location.href;
  const whatsappMessage = `Veja este produto: ${service.services_name}. Valor R$ ${service.value} - Link:${currentURL} `;
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (<>
    <Nav />
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      {currentURL.includes('usuario') && <NavUserMain />}
      <section className="w-100 ">
        {currentURL.includes('usuario') && <NavBreadcrumb icon={'user fa'} text={'Cadastro'} />}
        <div className="col-md-12" style={{ minHeight: "100dvh", padding: '0px' }} >
          <div className="col-md-12 mt-1">
            <div className="d-flex flex-wrap justify-content-center align-items-center  ">
              <RenderImageGrid images={images} />
              <div className='col-md-6   justify-content-center align-items-center '>
                <h3 className='text-center  font-weight-bold py-3'>{service.services_name}</h3>
                <div className="text-justify">
                  <MarkdownRenderer content={service.services_description} />
                </div>
                <p className='text-justify'><b className='font-weight-bold'>Tipo:</b> {service.services_type}</p>
                <p className='text-justify'><b className='font-weight-bold'>Empresa:</b> {service.company_name}</p>
                <p className='text-left text-dark font-weight-bold'>R${service.value}</p>
                <small>Entregas:   &nbsp; </small>
                <i className="fa fa-envelope text-success" aria-hidden="true"></i>  &nbsp; <i className="fa fa-truck text-success" aria-hidden="true"></i>  &nbsp;  <i className="fa fa-motorcycle text-success" aria-hidden="true"></i>
              </div>
              <div className='text-center ml-3  py-2 mb-5 mt-5 bg-light rounded ' style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)", }}>
                <div className='mr-2 ml-2'>
                  <label className='font-weight-bold mt-1 text-dark text-center' >Quantidade</label><br />
                  <input
                    className='text-center form-control mb-3' style={{ borderRadius: '20px' }}
                    type="number"
                    value={quantidade}
                    onChange={(event) => setQuantidade(event.target.value)}
                    inputMode="numeric"
                    min="0"
                  />
                  <ButtonAPI style={{ minWidth: '100%' }} onClick={() => handleComprarAgora(quantidade)}>👆 Comprar Agora</ButtonAPI>
                  <ButtonAPI style={{ minWidth: '100%' }} onClick={() => handleAddToCart(quantidade)}><i className="fa text-white flip-horizontal">🛒</i> Adicionar ao Carrinho</ButtonAPI>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12  bg-primary  justify-content-center align-items-center mt-5 text-center py-4" style={{ overflowX: 'hidden', borderRadius: '0.25rem' }}>
            <div className='d-flex mx-auto  justify-content-center  align-items-center ' >
              <div className='d-flex mx-auto justify-content-center align-items-center'>
                {images.length > 0 && (
                  <Carousel3D images={images.map((images, index) => ({
                    src: images.url,
                    alt: `service Image ${index + 1}`,
                    text: `Imagem ${index + 1}`,
                  }))} />
                )}
              </div>
            </div>
          </div>
          <SearchMain
            info={'As pessoas também visualizaram'}
            setResultadosPorPagina={setResultsPorPagina}
            searching={searching}
            results={results}
            error={error}
            loadMore={loadMore}
            hasMore={hasMore}
            setHasMore={setHasMore} />
          {bulaInfo &&<div className="mt-5 bg-light py-3">
            <h2 className='font-weight-bold text-center'>{service.services_type === "Medicações" ? 'Bula' : 'Manual'}</h2>
            <div className="  d-flex flex-wrap justify-content-center align-items-center">
            <PdfViewer 
                pdfUrl={`${bulaInfo.bulaPacienteFileName}`}
                fileName="bulaPaciente.pdf" // Nome do arquivo para o download
              />
              
            </div>
          </div>}
        </div>
        <div className="col-md-12  text-center justify-content-center align-items-center mt-4 mb-4 py-4">
          <a href={`whatsapp://send?text=${encodeURIComponent(whatsappMessage)}`}>
            <i className="ion-social-whatsapp  text-success fa-3x" />
            <p className='font-weight-bold text-dark'> Compartilhar Produto</p>
          </a>
          <p>Preço: R${service.value}</p>
        </div>
        <serviceAlert
          show={showAlert}
          onHide={handleCloseAlert}
          services_name={service.services_name}
          services_type={service.services_type}
          value={service.value}
          quantity={service.quantidade}
        />
        <Footer />
      </section>
    </div>
  </>);
};
export default ServicesDetailsPage;

