import React, { useState } from 'react';
import { ButtonAPI, MaisInfoMenosInfo } from '../../../../../../components';
import ConexaoDBInfo from '../info/ConexaoDBInfo';

export function ConexaoDB() {
    const [host, setHost] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [database, setDatabase] = useState('');

    return (<>
        <div className='text-center'>
            <input
                type="text"
                placeholder="Host"
                value={host}
                onChange={(e) => setHost(e.target.value)}
            />
            <br />
            <br />
            <input
                type="text"
                placeholder="User"
                value={user}
                onChange={(e) => setUser(e.target.value)}
            />
            <br />
            <br />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            <input
                type="text"
                placeholder="Database"
                value={database}
                onChange={(e) => setDatabase(e.target.value)}
            />
            <br />
            <br />
        </div>
        <div className="col-md-12 mt-2   d-flex">
            <ButtonAPI className="btn btn-primary mx-auto col-md-6" href="#">
                Enviar Informações
            </ButtonAPI>
        </div>
        <div className='py-4'>
    <MaisInfoMenosInfo text={<ConexaoDBInfo />}></MaisInfoMenosInfo>
        </div>
    </>
    );
}

export default ConexaoDB;
