import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { AuthContext } from '../../../../../../contexts/auth';
import {
    InputPadrao,
    ButtonAPI,
    InputPadraoTitle
} from '../../../../../../components';
import { baseURL } from '../../../../../../services/api';
import CadastrarLogo from './layout/CadastrarLogo';
import CadastrarProfissionalUnit from './layout/CadastrarProfissionalUnit';
import { axiosHeadersToken } from '../../../../../../services/contexts';
export const CadastrarClinicaInstituicao = () => {
    const [cnpj, setCnpj] = useState("");
    const [maskedCnpj, setMaskedCnpj] = useState("");
    const [cnpjFilial, setCnpjFilial] = useState("");
    const [maskedCnpjMatriz, setMaskedCnpjMatriz] = useState("");
    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('Matriz');
    const { getUserId } = useContext(AuthContext);
    const id = getUserId();
    const [boxValidoEmpresaCadastrada, setBoxValidoEmpresaCadastrada] = useState("");
    const limparDados = () => {
        setData(null);
        setIsLoading(false);
        setMaskedCnpjMatriz('');
    };
    const procuraInfoCNPJ = (event) => {
        setIsLoading(true);
        const cnpj = selectedOption === 'Matriz' ? document.getElementById('cnpj').value : document.getElementById('cnpjFilial').value;
        const cnpjWithoutMask = cnpj.replace(/\D/g, '');
        axiosHeadersToken().get(`${baseURL}cnpj/` + cnpjWithoutMask + `/${id}`)
            .then(response => response.data)
            .then(data => {
                if (data.status === 'OK') {
                    setData(data);
                    setError(null);
                    setIsLoading(false);
                } else {
                    alert('Erro ao consultar CNPJ!');
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setError(error.message);
                setData(null);
                alert('Erro ao consultar CNPJ!');
                setIsLoading(false);
            });
    };
    const buscarEmpresas = async () => {
        try {
            const response = await axios.get(`${baseURL}get_company`);
            const empresas = response.data;
            setCompanies(empresas);
        } catch (error) {
            console.error('Erro ao buscar as empresas:', error);
        }
    };
    useEffect(() => {
        buscarEmpresas();
    }, []);
    const cadastraClinicaInsituicao = () => {
        const empresa = {
            nome: data.nome || "atualizar",
            fantasia: data.fantasia || "atualizar",
            atividade_principal: [
                {
                    text: data.atividade_principal.text || "atualizar",
                    code: data.atividade_principal.code || "atualizar",
                },
            ],
            atividades_secundarias: data.atividades_secundarias.map((atividade) => ({
                text: atividade.text || "atualizar",
                code: atividade.code || "atualizar",
            })),
            abertura: data.abertura
                ? moment(data.abertura, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            situacao: data.situacao || "atualizar",
            data_situacao: data.data_situacao
                ? moment(data.data_situacao, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            capital_social: data.capital_social || "atualizar",
            natureza_juridica: data.natureza_juridica || "atualizar",
            ultima_atualizacao: data.ultima_atualizacao
                ? moment(data.ultima_atualizacao, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            tipo: data.tipo || "atualizar",
            logradouro: data.logradouro || "atualizar",
            numero: data.numero || "atualizar",
            complemento: data.complemento || "atualizar",
            bairro: data.bairro || "atualizar",
            municipio: data.municipio || "atualizar",
            uf: data.uf || "atualizar",
            cep: data.cep || "atualizar",
            telefone: data.telefone || "atualizar",
            email: data.email || "atualizar",
            efr: data.efr || "atualizar",
            situacao_especial: data.situacao_especial || "atualizar",
            data_situacao_especial: data.data_situacao_especial
                ? moment(data.data_situacao_especial, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            owner_id: id,
            type: localStorage.getItem('checkbox'),
            company_name: data.fantasia,
            created_by: id,
        };
        axiosHeadersToken()
            .post(`${baseURL}post_company/${id}`, empresa)
            .then((response) => {
                const company_id = response.data.company_id;
                localStorage.setItem('company_id', company_id);
                alert('Dados inseridos com sucesso!');
                setIsLoading(false);
                setBoxValidoEmpresaCadastrada(true);
            })
            .catch((error) => {
                alert('Erro ao inserir dados!');
                setIsLoading(false);
            });
    };
    const [isLoading, setIsLoading] = useState(false);
    //Mascaras de CNPJ
    function maskCnpj(value) {
        const cnpjWithoutMask = value.replace(/\D/g, "");
        setCnpj(cnpjWithoutMask);
        return value
            .replace(/\D/g, "") // Remove tudo o que não é dígito
            .replace(/^(\d{2})(\d)/, "$1.$2") // Coloca ponto entre o segundo e o terceiro dígitos
            .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") // Coloca ponto entre o quinto e o sexto dígitos
            .replace(/\.(\d{3})(\d)/, ".$1/$2") // Coloca barra entre o oitavo e o nono dígitos
            .replace(/(\d{4})(\d)/, "$1-$2") // Coloca hífen depois do bloco de quatro dígitos
            .substr(0, 18); // Limita o tamanho máximo do campo
    }
    return (<>
        <div className="form-group ">
            <div className={`text-center d-flex justify-content-center mt-3 col-lg-12 boxFooter ${boxValidoEmpresaCadastrada ? 'bg-success' : 'bg-primary'
                } TridimencionalSupLat`}>
                <h5 className="text-white mt-2 font-weight-bold "> <b>Clínica / Instituição</b></h5>
            </div>
            <div className="py-3 CardTHOT bg-light ">
                <div className="form-group col-md-10 mb-5 mx-auto ">
                    <select id='selectAreaInteresse' className='custom-select  text-center bg-light mx-auto font-weight-bold rounded'
                        type='select'
                        value={selectedOption}
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            limparDados(); // Limpar dados quando a opção selecionada for alterada
                        }}
                    >
                        <option className='text-center mx-auto' value='Matriz'>Matriz</option>
                        <option className='text-center mx-auto' value='Filial'>Filial</option>
                    </select>
                    <div className='text-dark text-center col-md-12'>
                        <small >Qualificação empres Arial, Helvetica
                        </small>
                    </div>
                </div>
                {(selectedOption === "Matriz" || selectedOption === "Filial") && (
                    <div className="form-group py-3 col-md-10 mx-auto">
                        <InputPadrao
                            title={'INFORME O Nº DE IDENTIFICAÇÃO ÚNICA DA MATRIZ'}
                            type="text"
                            id="cnpj"
                            name="cnpj"
                            value={maskedCnpj}
                            onChange={(event) => setMaskedCnpj(maskCnpj(event.target.value))}
                            placeholder="Brasil: Digite o CNPJ da matriz"
                        />
                        {selectedOption === "Filial" && (<>
                            <InputPadraoTitle title={'SELECIONE A MATRIZ'} />
                            <select className="form-control text-center" onChange={buscarEmpresas}>
                                <option value="">Selecione uma empresa</option>
                                {companies.map((empresa) => (
                                    <option key={empresa.id} value={empresa.id}>
                                        {empresa.company_name}
                                    </option>
                                ))}
                            </select>
                        </>)}
                        <ButtonAPI
                            isLoading={isLoading}
                            onClick={selectedOption === "Matriz" ? procuraInfoCNPJ : buscarEmpresas}
                        >
                            Buscar informações
                            {maskedCnpjMatriz}      </ButtonAPI>
                    </div>
                )}
                {selectedOption === "Filial" && (
                    <div className="form-group py-3 col-md-10 mx-auto">
                        <InputPadrao
                            title={'INFORME O Nº DE IDENTIFICAÇÃO ÚNICA DA FILIAL'}
                            type="text"
                            id="cnpjFilial"
                            name="cnpjFilial"
                            value={cnpjFilial}
                            onChange={(event) => setCnpjFilial(event.target.value)}
                            placeholder="Brasil: Digite o CNPJ"
                        />
                        <ButtonAPI isLoading={isLoading} onClick={procuraInfoCNPJ}>
                            Buscar informações
                        </ButtonAPI>
                    </div>)}
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-12 text-left mt-2">
                            <p className="text-center text-dark">Informações automatas resgatadas por API empresa a ser cadastrada</p>
                            {data && (
                                <>
                                    <div className='col-md-10'>
                                        <p>Razão Social: {data.nome}</p>
                                        <p>Nome Fantasia: {data.fantasia}</p>
                                        <p>Atividade Principal: {data.atividade_principal[0].text}</p>
                                        <p>CNAE Principal: {data.atividade_principal[0].code}</p>
                                        <p>Atividades Secundárias:</p>
                                        <ul>
                                            {data.atividades_secundarias.map((atividade, index) => (
                                                <li key={index}>{atividade.text} ({atividade.code})</li>
                                            ))}
                                        </ul>
                                        <p>Data de Abertura: {data.abertura}</p>
                                        <p>Status: {data.situacao}</p>
                                        <p>Data de Situação: {data.data_situacao}</p>
                                        <p>Capital Social: {data.capital_social}</p>
                                        <p>Natureza Jurídica: {data.natureza_juridica}</p>
                                        <p>Última Atualização: {data.ultima_atualizacao}</p>
                                        <p>Tipo: {data.tipo}</p>
                                        <p>Logradouro: {data.logradouro}</p>
                                        <p>Número: {data.numero}</p>
                                        <p>Complemento: {data.complemento}</p>
                                        <p>Bairro: {data.bairro}</p>
                                        <p>Cidade: {data.municipio} - {data.uf}</p>
                                        <p>CEP: {data.cep}</p>
                                        <p>Telefone: {data.telefone}</p>
                                        <p>E-mail: {data.email}</p>
                                        <p>Ente Federativo Responsável: {data.efr}</p>
                                        <p>Situação Especial: {data.situacao_especial}</p>
                                        <p>Data da Situação Especial: {data.data_situacao_especial}</p>
                                    </div>
                                    <ButtonAPI isLoading={isLoading} onClick={cadastraClinicaInsituicao}>
                                        Confirmar e Envia Dados
                                    </ButtonAPI>
                                </>)}
                            {error && <p className='text-center font-weight-bold text-danger'><i className='fa fa-exclamation-triangle text-warning mr-2'></i>{error}:{cnpj}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`boxHeader text-center  d-flex  justify-content-center align-items-center  
                     ${boxValidoEmpresaCadastrada ? 'bg-success' : 'bg-primary'
                } TridimencionalInfLagerLat py-2`}>
                <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
                    <b>{boxValidoEmpresaCadastrada ? <i className="fa fa-check" aria-hidden="true"></i> : '...'
                    }  </b> </h5>
            </div>
        </div>
        {selectedOption === "Matriz" && (
            <div className="form-group mt-5">
                <div className="text-center d-flex justify-content-center col-lg-12 boxFooter bg-primary TridimencionalSupLat">
                    <h5 className="text-white mt-2"> <b>LOGO  EMPRESA</b></h5>
                </div>
                <div className="py-3 CardTHOT border border-dark">
                    <CadastrarLogo />
                </div>
                <div className="boxHeader text-center  d-flex  justify-content-center align-items-center  bg-primary TridimencionalInfLagerLat">
                    <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
                        <b>...</b> </h5>
                </div>
            </div>)}
        <div className="form-group mt-5">
            <div className="text-center d-flex justify-content-center col-lg-12 boxFooter bg-primary TridimencionalSupLat">
                <h5 className="text-white mt-2"> <b>IMAGEM DO ALVARÁ DE FUNCIONAMENTO</b></h5>
            </div>
            <div className="py-3 CardTHOT border border-dark">

            </div>
            <div className={`boxHeader text-center  d-flex  justify-content-center align-items-center  
                     ${boxValidoEmpresaCadastrada ? 'bg-success' : 'bg-primary'
                } TridimencionalInfLagerLat py-2`}>
                <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
                    <b>{boxValidoEmpresaCadastrada ? <i className="fa fa-check" aria-hidden="true"></i> : '...'
                    }  </b> </h5>
            </div>
            <CadastrarProfissionalUnit title={'Adicionar Profissionais'} />
            <div className="form-group mt-5">
                <div className="text-center d-flex justify-content-center col-lg-12 boxFooter bg-primary TridimencionalSupLat">
                    <h5 className="text-white mt-2"> <b>Informações Fiscais</b></h5>
                </div>
                <div className="py-3 CardTHOT border border-dark">
                    <div className='mx-auto col-md-10'>
                        {/*     <InputPadrao
                                title={'CONTADOR'}
                                type="text"
                                value={''}
                                onChange={''}
                                placeholder="Digite o nº do CPF"
                            />
                            <p className="text-justify text-dark mt-2">Informe o CPF do contador para facilitar todas transações contábeis / tributarias,
                                caso não haja informe o e-CNPJ. <br />  <br />Também considere fortemente e sempre o auxílio de um contador para que juntos nós possamos trilhar caminhos
                                futuros que os auxiliára caso precise de aportes financeiros para melhorar extrutura e/ou comprar novos equipamentos,
                                materiais ou semelhantes.<br /> <br />  Rede intrinséca de informações comprobatórias para que você tenha maior comodidade possível.
                            </p>
                    */}
                        <div className="form-group mt-5">
                            <InputPadrao
                                title={'e-CNPJ'}
                                type="text"
                                value={''}
                                onChange={''}
                                placeholder="Digite o nº do e-CNPJ"
                            />
                            <p className="mt-2 text-justify text-dark">É necessário adquirir o e-CNPJ para emissão de NF-e, normalmente este documento fica em posse do contador</p>
                        </div>
                    </div>
                </div>
                <div className="boxHeader text-center  d-flex  justify-content-center align-items-center  bg-primary TridimencionalInfLagerLat">
                    <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
                        <b>...</b> </h5>
                </div>
            </div>
        </div >
    </>)
}