export const mainData = [
    {
        id: "navHome",
        title: " - Usuário",
        icon: "fa-user",
        href: "../../usuario",

        subData: []
    },
    {
        id: "navServicosInternos",
        title: " - Profissional",
        icon: "fa-suitcase",
        href: "../../profissionais",
        subData: []
    },
    {
        id: "navSocial",
        title: " - Social",
        icon: "fa-comments",
        href: "../../usuario/social",
        topDist: "mt-5",
        subData: []
    },
    {
        id: "navServicos",
        title: " - Serviços",
        icon: "fa-concierge-bell",
        topDist: "mt-3",
        subData: [
            {
                id: "navServicosComprar",
                title: "Comprar",
                icon: "fa-calendar text-theme",
                href: "../../usuario/servicos#servicos",

            },
            {
                id: "navServicosVender",
                title: "Vender",
                icon: "fa-location-arrow",
                href: "../../usuario/servicos#apresentacaoServicos",

            },
        ]
    },
    {
        id: "navProdutos",
        title: " - Produtos",
        icon: "fa-cubes",
        subData: [
            {
                id: "navProdutosComprar",
                title: "Comprar",
                icon: "fa-calendar text-theme",
                href: "../../usuario/produtos#produtos",
               
            },
            {
                id: "navProdutosVender",
                title: "Vender",
                icon: "fa-location-arrow",
                href: "../../usuario/produtos#apresentacao",
              
            },
        ]
    },
    {
        id: "navConsultas",
        title: " - Consultas",
        icon: "fa-hand-paper-o",

        subData: [

            {
                id: "navMarcarConsultas",
                title: "Marcar Consultas",
                icon: "fa-calendar text-theme",
                href: "../../../usuario/consultas/marcar",
                count: 0
            },
            {
                id: "navConsultasMarcadas",
                title: "Consultas Marcadas",
                icon: "fa-location-arrow",
                href: "../../../usuario/consultas/marcadas",
                count: 0
            },
            {
                id: "navConsultasFinalizadas",
                title: "Consultas Finalizadas",
                icon: "fa-database",
                href: "../../../usuario/consultas/finalizadas",
                count: 0
            }
        ]
    },
    {
        id: "navExames",
        title: " - Exames",
        icon: "fa-flask",

        subData: [
            {
                id: "navMarcarExames",
                title: "Marcar Exames",
                icon: "fa-calendar text-theme",
                href: "../../../usuario/exames/marcar",
                count: 0
            },
            {
                id: "navExamesMarcados",
                title: "Exames Marcados",
                icon: "fa-location-arrow",
                href: "../../../usuario/exames/marcados",
                count: 0
            },
            {
                id: "navExamesFinalizados",
                title: "Exames Finalizados",
                icon: "fa-database",
                href: "../../../usuario/exames/finalizados",
                count: 0
            }
        ]
    },
    {
        id: "navMedicacoes",
        title: " - Medicamentos",
        icon: "fa-pills",

        subData: [
            {
                id: "navReceitasUsoContinuo",
                title: " - Uso Contínuo",
                icon: "fa-history text-theme",
                href: "../../../usuario/medicacao/marcar",
                count: 0
            },
            {
                id: "receitasOcasioesEspecificas",
                title: " - Ocasiões Específicas",
                icon: "fa-wave-square",
                href: "../../../usuario/medicacao/marcados",
                count: 0
            },
            {
                id: "receitasEstoque",
                title: " - Estoque",
                icon: "fa-database",
                href: "../../../usuario/medicacao/finalizados",
                count: 0
            }
        ]
    },
    {
        id: "navEquipamentos",
        title: " - Equipamentos",
        icon: "fa-microchip",
        href: "../../usuario/equipamentos",

        subData: []
    },
    {
        id: "navProntuarioUnico",
        title: " - Prontuário Único",
        icon: "fa-book",
        href: "../../usuario/prontuario",
        topDist: "mt-5",
        subData: []
    },

    {
        id: "meusAtestados",
        title: " - Atestados",
        icon: "fa-bed",

        subData: [
            {
                id: "navAtestadosAtivos",
                title: "Ativos",
                icon: "fa-bed text-theme",
                href: "../../usuario/atestados",
                count: 0
            },
            {
                id: "navAtestadosArmazenados",
                title: "Armazenados",
                icon: "fa-database",
                href: "../../usuario/atestados",
                count: 0
            }
        ]
    },
    {
        id: "navCarrinho",
        title: " - Carrinho",
        icon: "fa-cart-plus",

        subData: [{
            id: "navCarrinhoItens",
            title: "Items no carrinho",
            icon: "fa-bed text-theme",
            href: "../../usuario/carrinho",
            count: 0
        },
        {
            id: "navCarrinhoPedidos",
            title: "Pedidos",
            icon: "fa-database",
            href: "../../usuario/carrinho#NavPublicCarrinhoOrder",
            count: 0
        }]
    },

    {
        id: "navNotasFiscais",
        title: " - Notas Fiscais",
        icon: "fa-barcode",

        subData: [
            {
                id: "navNfeVigentes",
                title: "Vigentes",
                href: "../../usuario/notasFiscais",
                icon: "fa-barcode text-theme",
                count: 0
            },
            {
                id: "navNfeArmazenados",
                title: "Armazenados",
                icon: "fa-database",
                href: "../../usuario/notasFiscais",
                count: 0
            }
        ]
    },

    {
        id: "navMeuCadastro",
        title: " - Meu Cadastro",
        icon: "fa-id-card",
        topDist: "mt-5",
        subData: [
            {
                id: "NavDadosPessoais",
                title: "Paciente",
                icon: "fa-user text-theme",
                href: "../../usuario/cadastro"
            },
            {
                id: "NavDadosProfissionais",
                title: "Profissional",
                icon: "fa-suitcase text-theme",
                href: "../../usuario/cadastro"
            }
        ]
    },


    {
        id: "navPreferencias",
        title: " - Preferências",
        icon: "fa-cogs",
        href: "../../usuario/preferencias",

        subData: []
    },





    {
        id: "navEmergencia",
        title: " - Emergências",
        icon: "fa-phone",
        topDist: "mt-5",
        subData: [
            {
                id: "navSOSAmbulancia",
                title: " - Ambulância Pública (SAMU)",
                icon: "fa-phone",
                href: "tel:+55192"

            },
            {
                id: "navSOSBombeiros",
                title: " - Corpo de Bombeiros",
                icon: "fa-phone",
                href: "tel:+55193"

            },
            {
                id: "navSOSPM",
                title: " - Polícia Militar",
                icon: "fa-phone",
                href: "tel:+55190"

            }
            ,
            {
                id: "navSOSCVV",
                title: " - CVV | Centro de Valorização da Vida",
                icon: "fa-phone",
                href: "tel:+55188"

            }
            ,
            {
                id: "navSOSDH",
                title: " - Disque Direitos Humanos",
                icon: "fa-phone",
                href: "tel:+55100"

            }
            ,
            {
                id: "navSOSDD",
                title: " - Disque Denúncia",
                icon: "fa-phone",
                href: "tel:+55181"

            }
            ,
            {
                id: "navSOSMulher",
                title: " - Central de Atendimento para a Mulher - Ministério da Justiça",
                icon: "fa-phone",
                href: "tel:+55180"

            }
            ,
            {
                id: "navSOSPersonalizado",
                title: " - Configurações personalizadas de SOS",
                icon: "fa-phone",
                href: "../../../usuario/sosPersonalizado"

            }
        ]
    },

];