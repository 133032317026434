import React from 'react';
import { TitleL2, TitleL3 } from '../../../../../components';

export const InnovationScope = () => {
  return (<>
    <TitleL2 className='mt-5'>Escopo das Atividades de Inovação</TitleL2>
    <p className='text-justify'>      &nbsp;A THOT I.A. CORP., em conformidade com as normas ISO 56001 e ISO 56002, adota um escopo de atividades de inovação focado no desenvolvimento contínuo de produtos e serviços, melhoria de processos internos através da automação e digitalização, capacitação de talentos por meio de treinamentos em novas tecnologias, e parcerias estratégicas com instituições e startups. Além disso, a empresa investe em pesquisa de ponta em IA e machine learning, fomentando uma cultura organizacional aberta e colaborativa, onde a inovação é incentivada em todos os níveis.</p>
    <div className='row' style={{ padding: '20px', marginTop: '15px', textAlign: 'justify' }}>
      <div className='col-md-4'>
        <TitleL3>Inovação em Produtos e Serviços</TitleL3>
        <p>
    Na THOT I.A. CORP., estamos comprometidos com o desenvolvimento e a otimização contínua de nossos produtos e serviços. Nossa equipe se dedica a encontrar soluções inovadoras que aumentem o valor entregue aos clientes, criando novas funcionalidades que atendam às necessidades em constante evolução.
        </p>
      </div>
      <div className='col-md-4'>
        <TitleL3>Melhoria de Processos Internos</TitleL3>
        <p>
          A eficiência operacional é um dos pilares da nossa inovação. Investimos na automação e na digitalização de processos internos para garantir que nossa equipe esteja sempre focada em atividades de alto valor. Isso inclui o uso de ferramentas de inteligência artificial para otimização de fluxos de trabalho.
        </p>   </div>
      <div className='col-md-4'>
        <TitleL3>Capacitação e Desenvolvimento de Talentos</TitleL3>
        <p>
          Acreditamos que o capital humano é o motor da inovação. Oferecemos programas de treinamento contínuo, incentivando o aprendizado de novas tecnologias e metodologias, como a aplicação de IA e análise de dados para gerar insights estratégicos e melhorar nossas soluções.
        </p>   </div>
      <div className='col-md-4'>
        <TitleL3>Parcerias Estratégicas</TitleL3>
        <p>
          A colaboração é fundamental para impulsionar a inovação. Estabelecemos parcerias com startups, universidades e centros de pesquisa para desenvolver tecnologias emergentes e explorar novas oportunidades de mercado.
        </p>   </div>
      <div className='col-md-4'>
        <TitleL3>Inovação Tecnológica e Pesquisa</TitleL3>
        <p>
          Realizamos pesquisas contínuas em inteligência artificial e machine learning para manter nossa liderança tecnológica. Nossos times de P&D estão focados em explorar novas aplicações e desenvolver ferramentas que revolucionem nossos serviços.
        </p>   </div>
      <div className='col-md-4'>
        <TitleL3>Cultura de Inovação</TitleL3>
        <p>
          Na THOT I.A. CORP., promovemos uma cultura de inovação aberta, onde todos os colaboradores têm a oportunidade de compartilhar suas ideias e participar ativamente no processo de inovação. Mantemos uma mentalidade de aprendizado contínuo e incentivamos a criatividade.
        </p>
      </div>
    </div>
  </>);
};

export default InnovationScope;
