import React, { useState } from 'react';
import { baseURL } from '../../../../../../../../../services/api';
import { Button, Image } from 'antd';
import { axiosHeadersToken } from '../../../../../../../../../services/contexts';
import { InputPadrao } from '../../../../../../../../../components';
export const BuscarIA = ({ setCentralFolderName }) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [subfolderData, setSubfolderData] = useState([]);
  const [nameIAText, setNameIAText] = useState('');
  const handleChange = async (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setNameIAText(value);
    setCentralFolderName(value)
    try {
      const response = await axiosHeadersToken().get(`${baseURL}/get_ia/1/${value}/${nameIAText}`);
      const data = response.json();
      setSearchResults(data);
      setSubfolderData([]); // Clear subfolderData when changing search results
    } catch (error) {
      console.error('Erro ao realizar a busca:', error);
    }
  };
  const handleFolderClick = async (folderName) => {
    try {
      const subfolderDataResponse = await fetchSubfoldersAndImages(folderName);
      setSubfolderData(subfolderDataResponse); // Make sure to set the response data
    } catch (error) {
      console.error('Erro ao obter as subpastas e imagens:', error);
    }
  };
  const fetchSubfoldersAndImages = async (folderName) => {
    try {
      const response = await fetch(`${baseURL}search/folder/${folderName}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao obter as imagens da subpasta:', error);
      return [];
    }
  };
  return (<>

    <InputPadrao
      title={'Buscar I.A'}
      className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
      type="text"
      placeholder="Nome da I.A. ?"
      value={searchValue}
      onChange={handleChange}
    />
    <ul>
      {searchResults.map((result, index) => (
        <li key={index}>
          <Button
            onClick={() => handleFolderClick(result)}
            style={{ cursor: 'pointer' }}
          >
            {result}
          </Button>
        </li>
      ))}
    </ul>
    <div>
      <div>
        {subfolderData && subfolderData.length > 0 ? (
          subfolderData.map((subfolder, subfolderIndex) => (
            <div className='bg bg-light' key={subfolderIndex}>
              {subfolderIndex === 0 || subfolder.subFolder !== subfolderData[subfolderIndex - 1].subFolder ? (
                <div>
                  <p>{subfolder.subFolder}</p>
                  <div style={{ display: 'flex' }}>
                    {subfolder.filename && subfolder.content ? (
                      subfolderData
                        .filter(sub => sub.subFolder === subfolder.subFolder)
                        .map((image, imageIndex) => (
                          <Image
                            key={imageIndex}
                            className="image-preview"
                            src={`data:image/png;base64,${image.content}`}
                            alt={`Image ${imageIndex}`}
                            style={{ maxWidth: '100px', height: 'auto', marginRight: '10px' }}
                          />
                        ))
                    ) : (
                      <p>No images available for this subfolder.</p>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          ))
        ) : (
          null
        )}
      </div>
    </div>
  </>);
};
export default BuscarIA;