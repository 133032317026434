import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, MaisInfoMenosInfo, ButtonRemoverCampo, ButtonEditarCampo } from '../../../../../../components';
import ProntuarioEstaEmTratamentoInfo from '../info/ProntuarioEstaEmTratamentoInfo';

export const ProntuarioEstaEmTratamento = () => {
    const [estaEmTratamento, setEstaEmTratamento] = useState(false);
    const [tratamento, setTratamento] = useState('');
    const [tratamentosLista, setTratamentosLista] = useState([]);
    const [indiceEditando, setIndiceEditando] = useState(-1);
    const handleCheckboxChange = (e) => {
        setEstaEmTratamento(e.target.checked);
    };

    const handleTratamentoChange = (e) => {
        setTratamento(e.target.value);
    };

    const handleAdicionarTratamento = () => {
        if (tratamento.trim() !== '') {
            if (indiceEditando === -1) {
                setTratamentosLista([...tratamentosLista, tratamento]);
            } else {
                const novosTratamentos = [...tratamentosLista];
                novosTratamentos[indiceEditando] = tratamento;
                setTratamentosLista(novosTratamentos);
                setIndiceEditando(-1);
            }
            setTratamento('');
        }
    };

    const handleEditarTratamento = (index) => {
        setTratamento(tratamentosLista[index]);
        setIndiceEditando(index);
    };

    const handleRemoverTratamento = (index) => {
        const novaLista = [...tratamentosLista];
        novaLista.splice(index, 1);
        setTratamentosLista(novaLista);
    };


    return (
        <>
            <h5 style={{ fontWeight: "bold" }} className="mt-3">
                - Atualmente está em tratamento médico ?
            </h5>
            <label>
                <input
                    type="checkbox"
                    defaultChecked={estaEmTratamento}
                    onChange={handleCheckboxChange}
                /> Sim
            </label>
            <br />
            <label>
                <input
                    type="checkbox"
                    defaultChecked={!estaEmTratamento}
                    onChange={() => setEstaEmTratamento(!estaEmTratamento)}
                /> Não
            </label>
            {estaEmTratamento && (
                <div>
                    *Se Sim, quais ?{' '}
                    <input
                        type="text"
                        value={tratamento}
                        onChange={handleTratamentoChange}
                        placeholder="Especifique o tratamento"
                    />
                    <ButtonAdicionarCampo type={'button'} onClick={handleAdicionarTratamento} />
                </div>
            )}
            {tratamentosLista.length > 0 && (
                <div>
                    <h6><strong>Tratamentos Adicionados:</strong></h6>
                    <ul>
                        {tratamentosLista.map((item, index) => (
                            <li key={index}>
                                {item}
                                <ButtonEditarCampo onClick={() => handleEditarTratamento(index)} />
                                <ButtonRemoverCampo onClick={() => handleRemoverTratamento(index)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="col-md-12 mt-2 mb-5 d-flex">
                <Link className="btn btn-primary mx-auto col-md-6" href="#">
                Enviar Informações
                </Link>
            </div>

           <MaisInfoMenosInfo text={<ProntuarioEstaEmTratamentoInfo />}></MaisInfoMenosInfo>
        </>
    );
};

export default ProntuarioEstaEmTratamento;
