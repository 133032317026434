import React, { useState, useEffect, useContext } from 'react';

import { AuthContext } from '../../../../../contexts/auth';
import { List } from 'antd';
import PaymentDetails from './PaymentDetails';
import { fetchOrders } from '../services/get_cart_payments_res'; // Importa a função da API para buscar pedidos
import MockOrderButton from '../testes/mockDataOrder';


const CartOrder = () => {
  const [orders, setOrders] = useState([]);
    const { getUserId } = useContext(AuthContext);
    const user_id = getUserId();
  useEffect(() => {
    const getOrders = async () => {
      const fetchedOrders = await fetchOrders(user_id);
      setOrders(fetchedOrders);
    };

    getOrders();
  }, [user_id]);

  return (
    <>
      {process.env.NODE_ENV === 'development' && <MockOrderButton setOrders={setOrders} />}
      <List
        itemLayout="vertical"
        dataSource={orders}
        split={false}
        renderItem={order => (
          <List.Item>
            <PaymentDetails data={order} />
          </List.Item>
        )}
      />
    </>
  );
};

export default CartOrder;
