import { baseURL } from "../../../../../api.js";
import { axiosHeadersToken } from "../../../../../contexts";
export const updateImageDate = async (id, imageId) => {
  try {
    const shouldUpdate = window.confirm(`Tem certeza que deseja usar essa imagem no perfil ?`);
    if (!shouldUpdate) {
      return;
    }
    const response = await axiosHeadersToken().put(
      `${baseURL}atualizarDataImagem/${id}/${imageId}`,
      { status: 'active' },
    );
    if (response.status === 200) {
      alert(`Imagem com ID ${id} marcada como atual no perfil backend.`);
    } else {
      console.error('Failed to update image status:', response.status);
      alert(`Erro ao atualizar o status da imagem: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating image status:', error);
    alert('Erro ao atualizar o status da imagem:', error);
  }
};