import { Nav } from '../../../../components';
import ViewAtestadosArmazenados from './components/AtestadosArmazenados/ViewAtestadosAmarzenados';
import { ViewAtestadosAtivos } from './components/AtestadosAtivos/ViewAtestadosAtivos';
export const Atestados = () => {
    return (<>
        <Nav />
        <ViewAtestadosArmazenados />
        <ViewAtestadosAtivos />
    </>)
}
export default Atestados;