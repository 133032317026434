import React from 'react';
import { Tag, Button } from 'antd'; 
import { WarningOutlined, CheckOutlined } from '@ant-design/icons';
import { TitleL2 } from '../../../../../../../../components';
export const MasterProfissionalDeleted = ({ item, handleAtivarCadastroProfissional }) => {
  return (<>
    <div className=''>
      <Tag className='mt-3 mb-3 d-flex col-md-12' style={{
        width: '100%',
        padding: '60px',
        whiteSpace: 'pre-line'
      }} icon={<WarningOutlined style={{ fontSize: '48px', paddingInlineEnd: '50px' }} />} color="warning">
        <TitleL2 className='text-danger'>Seu cadastro profissional foi deletado</TitleL2>
        <li className='text-dark mt-3'><strong>Nome profissional: </strong>{item.professional_name}</li>
        <li className='text-dark'><strong>Deletado em: </strong>{new Date(item.created_at).toLocaleString()}</li>
        <li className='text-dark'><strong>Estado: </strong>{item.state}</li>
        <li className='text-success mt-5' style={{ whiteSpace: 'pre-line' }}><strong>Política de segurança    </strong>
          <ol className='text-dark text-justify'>
            <li> Relembrando os termos anteriormente aceitos</li>
            <li> Segundo as regras da empresa só é possível ter um master cadastro profissional com as seguintes observações:</li>
            <ul className='ml-3'>2.1 Você pode ter várias ocupações</ul>
            <ul className='ml-3'>2.2 Você pode ter vários vínculos</ul>
            <ul className='ml-3'>2.3 Você pode ter várias empresas e sub</ul>
            <ul className='ml-3'>2.4 No entanto todas essas funções profissionais estão vinculadas ao master profissional</ul>
            <li> Todos os seus dados e serviços dependências diretas estão pausadas temporariamente</li>
            <li> Todos os seus dados estão e continuarão armazenados indefinidamente</li>
            <li> Se você precisa de cópias de documentos só ativar o cadastro</li>
            <li> Para voltar a ativa profissional ganhar tempo e dinheiro é só ativar novamente o cadastro e voltamos tudo a ativo</li>
          </ol>
        </li>

        <Button
          type="primary"
          onClick={() => handleAtivarCadastroProfissional(item.id)}
          icon={<CheckOutlined />}
          className="mt-3 btn-success"
        >
          Ativar Cadastro Profissional
        </Button>
      </Tag>
    </div>
    </> );
};
export default MasterProfissionalDeleted;