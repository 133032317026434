import React from 'react'

export function ContainerMenuItens({titulo,children}) {
  return (
    <aside className='container-menuitem'>
      <h3 className='titulo-menuitem'>{titulo}</h3>
      <ol className='ordenado-container-menuitem'>
        {children}
      </ol>
      
    </aside>
  )
}
