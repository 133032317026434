import { baseURL } from "../../../../api.js";
export const fetchColunas = async (tabelaSelecionada, setColunas) => {
    try {
        if (tabelaSelecionada) {
            const response = await fetch(
                `${baseURL}api/tabelas/${tabelaSelecionada.value}/colunas`
            );
            if (!response.ok) {
                throw new Error("Erro ao buscar colunas");
            }
            const data = await response.json();
            setColunas(
                data.colunas.map((coluna) => ({ value: coluna, label: coluna }))
            );
        } else {
            setColunas([]);
        }
    } catch (error) {
        console.error("Erro ao buscar colunas:", error);
    }
};
export default fetchColunas;