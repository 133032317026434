import React, { useEffect, useState } from "react";
import qs from "query-string";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const redirectToGoogle = () => {
  const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/auth';
  const params = {
    response_type: 'code',
    scope: 'openid profile email', // Escopo de permissões do Google
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
  };
  const queryStrings = qs.stringify(params);
  const authorizationUrl = `${GOOGLE_AUTH_URL}?${queryStrings}`;
 
  window.location.href = authorizationUrl;
};

const LoginAuthGoogle = () => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [callbackHandled, setCallbackHandled] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const convite_code_local = params.get('convite_code');
  if (convite_code_local) {
    localStorage.setItem('convite_code', convite_code_local);
  }
  useEffect(() => {
    const handleGoogleCallback = async () => {
      const { code } = qs.parseUrl(window.location.href).query;
      const convite_code = localStorage.getItem('convite_code');

      if (code && !callbackHandled ) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}post_user_tenant_google`, { 
            code, 
            convite_code
          });
          const { user, token, userId, user_type } = response.data;
          localStorage.setItem("user", JSON.stringify({ ...user, id: userId, user_type }));
          localStorage.setItem("token", token);
          axios.defaults.headers.Authorization = `Bearer ${token}`;    
          setAuthenticated(true);       
          localStorage.setItem("Google", "Login");
          setCallbackHandled(true);
        } catch (err) {
 
          setCallbackHandled(true);
        }
      }
    };
    handleGoogleCallback();
  }, [callbackHandled, navigate, authenticated]);

  useEffect(() => {
    setTimeout(() => {
      const loginGoogle = localStorage.getItem('Google');
    if (loginGoogle === 'Login') {
      window.location.href = "/usuario";
    }
    }, 2000); 
   
  }, [navigate]);

  return (
    <Link className="btn btn-primary botao-google" onClick={redirectToGoogle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 488 512"
        className="icone"
      >
        <path
          fill="white"
          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
      </svg>
    </Link>
  );
};
export default LoginAuthGoogle;