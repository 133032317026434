import { FooterUser, Nav, NavBreadcrumb, NavUserMain } from "../../../../components";
import SocialFeed from "./SocialFeed";
export const Social = () => {
    return (<>
     <Nav/>
        <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
            <NavUserMain />
            <section className="w-100">
            <NavBreadcrumb icon={'user fa'} text={'Social'} />
                <SocialFeed />
                <FooterUser />
            </section>
        </div>
    </>)
}