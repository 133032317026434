import React from 'react';
import removeMarkdown from 'remove-markdown';

const RemoveMarkdown = ({ description }) => {
  // Remove o Markdown da descrição
  const plainTextDescription = description ? removeMarkdown(description) : '';

  return (
    <div>
      {plainTextDescription && (
        <p className='text-justify text-primary' 
           style={{ 
             marginBottom: '0px', 
             overflow: 'hidden', 
             textOverflow: 'ellipsis', 
             display: '-webkit-box', 
             minHeight: "24px", 
             WebkitLineClamp: 2, 
             WebkitBoxOrient: 'vertical' 
           }}>
          {plainTextDescription}
        </p>
      )}
    </div>
  );
};

export default RemoveMarkdown;
