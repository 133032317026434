import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import { baseURL } from '../../../../../../../../services/api';
import { axiosHeadersToken } from '../../../../../../../../services/contexts';

const SearchAreas = ({ id, professional_id }) => {
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Grande Área', dataIndex: 'grande_area', key: 'grande_area' },
    { title: 'Área', dataIndex: 'area', key: 'area' },
    { title: 'Sub-Área', dataIndex: 'sub_area', key: 'sub_area' },
    { title: 'Especialidade', dataIndex: 'especialidade', key: 'especialidade' },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Popconfirm
          title="Tem certeza que deseja excluir esta área?"
          onConfirm={() => handleDelete(record.id)}
          okText="Sim"
          cancelText="Cancelar"
        >
         
          <Button  style={{ border: '0px',  }}  type="danger"> <i className='fa fa-trash text-danger' > &nbsp;Excluir</i></Button>
        </Popconfirm>
      ),
    },
  ];
  useEffect(() => {
    const handleSearch = async () => {
      setLoading(true);
      try {
        const response = await axiosHeadersToken().get(`${baseURL}buscarAreas/${id}/${professional_id}`);
        if (response.status === 200) {
          setAreas(response.data);
        } else {
          console.error('Erro ao buscar áreas:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao buscar áreas:', error);
      } finally {
        setLoading(false);
      }
    };
    handleSearch();
  }, [id, professional_id]);
  const handleDelete = async (areaId) => {
    try {
      const response = await axiosHeadersToken().put(`${baseURL}put_professional_area_state/${areaId}/${id}/${professional_id}`);
      if (response.status === 200) {
        message.success('Área excluída com sucesso!');
        // Atualiza a lista de áreas após a exclusão
        const updatedAreas = areas.filter(area => area.id !== areaId);
        setAreas(updatedAreas);
      } else {
        message.error('Erro ao excluir área:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao excluir área:', error);
      message.error('Erro ao excluir área.');
    }
  };
  return (
    <div>
      {areas.length > 0 && <Table columns={columns} dataSource={areas} rowKey="id" loading={loading} />}
    </div>
  );
};
export default SearchAreas;
