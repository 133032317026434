import { Form, Input, Button, Select, Flex } from "antd";
import { useState, useEffect, useContext } from "react";
import { ImageDragDrop, NavBreadcrumb } from "../../../../../../../components";
import { AuthContext } from "../../../../../../../contexts/auth";
import axios from "axios";
import { baseURL } from "../../../../../../../services/api";
import { useForm } from "antd/lib/form/Form";
import DragDropPdf from "../../../../../../../components/dragdrop/DragDropPdf";
import { axiosHeadersToken } from "../../../../../../../services/contexts";

export const Index = () => {
  const [form] = useForm();
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedServiceDetails, setSelectedServiceDetails] = useState(null);
  const { getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();
  const [fileList, setFileList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedBulaId, setSelectedBulaId] = useState(null);

  useEffect(() => {
    async function fetchServices() {
      try {
        const response = await axios.get(
          `${baseURL}get_services_profissional_id/${professional_id}`
        );
        if (Array.isArray(response.data)) {
          const serviceNames = response.data.map((service) => ({
            label: service.services_name,
            value: service.id,
          }));
          setServiceOptions(serviceNames);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    }

    fetchServices();
  }, [professional_id]);

  const handleServiceSelect = async (services_id) => {
    try {
      const response = await axios.get(
        `${baseURL}get_services_id/${services_id}`
      );
      setSelectedServiceDetails(response.data);
      setSelectedServiceId(services_id); // Captura o id do serviço
      form.setFieldsValue(response.data);

      const images = processImages(response.data.services_image);
      setFileList(
        images.map((image) => ({
          uid: image.id,
          name: image.url.split("/").pop(),
          status: "done",
          url: image.url,
        }))
      );

      const { products_bula_id } = response.data;
      if (products_bula_id) {
        const bulaResponse = await axios.get(
          `${baseURL}get_services_bula_id/${products_bula_id}`
        );

        setPdfFile(bulaResponse.data || null);
        setSelectedBulaId(bulaResponse.data.id || null);
      }
    } catch (error) {
      console.error("Erro ao buscar detalhes do serviço:", error);
    }
  };

  const processImages = (imagesString) => {
    try {
      return JSON.parse(imagesString);
    } catch (error) {
      console.error("Erro ao processar as imagens:", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
    });

    if (pdfFile) {
        formData.append("informacao_pdf", pdfFile);
    }

    try {
        let productsBulaId = "";
        if (pdfFile) {
            const pdfFormData = new FormData();
            pdfFormData.append("bulaPacienteFileName", pdfFile);
            pdfFormData.append("id", selectedBulaId);
            const responsePdf = await axiosHeadersToken().post(
                `${baseURL}post_products_bula/${professional_id}`,
                pdfFormData
            );

            console.log("Resposta do Endpoint /post_products_bula:", responsePdf.data);

            productsBulaId = responsePdf.data.products_bula_id; // Capture o ID retornado
            console.log("ID da Bula Capturado:", productsBulaId);
        }

        fileList.forEach((file) => {
          formData.append("images", file.originFileObj); // Certifique-se de que "originFileObj" está correto
        });

        console.log("formData", [...formData]);         


        if (productsBulaId) {
            formData.append("products_bula_id", productsBulaId); // Adiciona o ID da bula ao FormData
        }

        if (selectedServiceId) {
            formData.append("id", selectedServiceId); // Adiciona o id capturado
        }

        const response = await axiosHeadersToken().post(
            `${baseURL}post_services/${professional_id}`,
            formData
        );

        console.log("Serviço atualizado com sucesso:", response.data);
    } catch (error) {
        console.error("Erro ao atualizar serviço:", error);
    }
};


  return (
    <>
      <NavBreadcrumb icon={"suitcase fa"} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Item do Serviço" name="services_name">
          <Select
            showSearch
            options={serviceOptions}
            placeholder="Selecione um serviço"
            onChange={handleServiceSelect}
          />
        </Form.Item>

        {selectedServiceDetails && (
          <>
            <Form.Item
              label="Nome do Serviço"
              name="services_name"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o nome do serviço.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Upload de Imagens" name="informacao_images">
              <ImageDragDrop fileList={fileList} setFileList={setFileList} />
            </Form.Item>
            <Form.Item
              label="Arquivo de PDF Informação Bula"
              name="informacao_pdf"
            >
              <DragDropPdf
                onPdfUpload={setPdfFile}
                initialPdf={pdfFile ? pdfFile : null}
              />
            </Form.Item>
            <Form.Item
              label="Valor do Serviço"
              name="value"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o valor do serviço.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Estoque do Serviço" name="services_stock">
              <Input />
            </Form.Item>
            <Form.Item
              label="Descrição do Serviço"
              name="services_description"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira a descrição do serviço.",
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Flex vertical gap="small" style={{ width: "10%" }}>
            <Button type="primary" htmlType="submit" size="large">
              Enviar
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};

export default Index;
