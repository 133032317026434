import React from "react";
import '../styles/IdentificacaoComprador.css'
const IdentificacaoComprador = ({ nome, rg, orgaoEmissor, endereco, cidade, uf, telefone }) => {
    return (
        <div className="principalCompradorId">
        <h2>IDENTIFICAÇÃO DO COMPRADOR</h2>
        <div className="containerCompradorDiv">
            <p>NOME: {nome}</p>
            <p>RG: {rg}</p>
            <p>ORGÃO EMISSOR: {orgaoEmissor}</p>
            <p>ENDEREÇO COMPLETO: {endereco}</p>
            <br></br>
            <p>CIDADE: {cidade}</p>
            <p>UF: {uf}</p>
            <p>TELEFONE: {telefone}</p>
        </div>
        </div>
    );
    }
    export default IdentificacaoComprador;