import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export const ServicosAlert = ({ show, onHide, products_name, value, quantity, products_type }) => {
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        onHide(); 
      }
    };
    if (show) {
      window.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [show, onHide]);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Produto Adicionado com Sucesso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Produto <strong>{products_name}</strong> adicionado com sucesso.
        <br /><br />
        <small> <strong>Valor unitário:</strong> R$ {value}</small>   <br />
        <small> <strong>Valor total:</strong> R$ {value * quantity}</small>   <br />
        <small> <strong>Quantidade total: </strong>{quantity}</small>   <br />
        <small> <strong>Tipo:</strong> {products_type}</small>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ServicosAlert;