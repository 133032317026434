import React from 'react';
import {
  Apresentacao,
  Condutas,
  Significados,
  Historia,
  Reconhecimentos,
  Ferramentas,
  Linguagem,
  Team,
  NossosNumeros,
  NivelMaturidadeTecnologica,
  TeamList,
  TeamListColaboradores,
  InnovationScope
} from '../layout';
export const items = [
  {
    id: 'apresentacao', title: 'Apresentação', component: <Apresentacao />,
    subItems: [],
  },
  {
    id: 'historia', title: 'Historia', component: <Historia />,
    subItems: [
    ],
  },
  {
    id: 'significados', title: 'Significados', component: <Significados />,
    subItems: [],
  },
  {
    id: 'Condutas', title: 'Condutas', component: <Condutas />,
    subItems: [],
  }, {
    id: 'Inovacao', title: 'Inovação', component: <InnovationScope />,
    subItems: [],
  },
  {
    id: 'Team', title: 'Equipe', component: <Team />,
    subItems: [
      {
        id: 'TeamFundadoresProjetos', title: 'Fundadores de Projetos', component: (
          <>
            <TeamList />
          </>
        ),
      },
      {
        id: 'TeamBolsistasContratados', title: 'Colaboradores', component: (
          <>
            <TeamListColaboradores />
          </>
        ),
      },
    ],
  },
  {
    id: 'ferramentas', title: 'Ferramentas', component: <Ferramentas />,
    subItems: [],
  },
  {
    id: 'linguagem', title: 'Linguagem', component: <Linguagem />,
    subItems: [],
  },
  {
    id: 'maturidade', title: 'Maturidade Tec.', component: <NivelMaturidadeTecnologica />,
    subItems: [],
  },
  {
    id: 'nossosNumeros', title: 'Nossos Números', component: <NossosNumeros />,
    subItems: [],
  },
  {
    id: 'premios', title: 'Prêmios', component: <Reconhecimentos />,
    subItems: [],
  },
];
export default items;