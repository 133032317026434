import React from "react";
import "../Wiki/style.css";
import Logo from "../../Profissionais/Wiki/img/logo_thot.jpg";
import { Header } from "../Wiki/components/Header/Header";
import { Cards } from "../Wiki/components/Cards/Cards";
import { FooterWiki } from "../Wiki/components/Footer/Footer";

export const Wiki = () => {
  const pessoas = [
    {
      img: "https://avatars.githubusercontent.com/u/109116643?v=4",
      nome: "Cleyson Oliveira",
      funcao: "Desenvolvedor",
      link: "https://github.com/CleysonOlvr",
    },
    {
      img: "https://avatars.githubusercontent.com/u/123272360?v=4",
      nome: "Anabelly Passos",
      funcao: "Desenvolvedora",
      link: "https://github.com/anabellypassos",
    },
    {
      img: "https://avatars.githubusercontent.com/u/128336634?v=4",
      nome: "Felipe Martines",
      funcao: "Desenvolvedor",
      link: "https://github.com/Kurjata",
    },
    {
      img: "https://avatars.githubusercontent.com/u/74729853?v=4",
      nome: "Leonardo Santos",
      funcao: "Gerente De Projetos",
      link: "https://github.com/LeonardoRSantos",
    },
  ];
  return (
    <div className="principal">
      <Header pagina="inicio" />

      <main className="conteudo">
        <section>
          <div>
            <h1 className="conteudo-frase">THOT I.A. CORP.</h1>
            <div className="conteudo-img">
              <img src={Logo} alt="Logo Thot" />
            </div>
          </div>
          <div className="container">
            <h3 className="conteudo-titulo">Sobre o projeto</h3>
            <div className="conteudo-paragrafo">
              <p>
                O projeto Wiki tem como objetivo desenvolver uma plataforma
                colaborativa para análise de códigos e estruturas,
                proporcionando um ambiente interativo e educacional para
                desenvolvedores, estudantes e entusiastas da programação.
                Através dessa plataforma, os usuários poderão explorar e
                compreender o funcionamento de trechos de código, além de
                analisar a estrutura e organização dos projetos.
              </p>
              <br />
              <p>
                O projeto Wiki visa promover a colaboração, compartilhamento de
                conhecimento e aprimoramento contínuo das habilidades de
                programação Uma das principais características do projeto Wiki é
                a capacidade de ler códigos e fornecer informações detalhadas
                sobre sua funcionalidade. Através de algoritmos avançados, a
                plataforma será capaz de identificar linguagens de programação,
                analisar trechos de código e fornecer uma explicação clara e
                precisa sobre o que cada linha de código faz. Essa
                funcionalidade permitirá que os usuários compreendam melhor a
                lógica por trás dos códigos e aprendam com exemplos práticos.
              </p>
            </div>
          </div>
        </section>
        <section className="card-section">
          <div className="card-titulo">
            <h3>Equipe</h3>
          </div>
          <Cards.Root estilo={"container-card-wiki"}>
            <div className="d-flex">              
              {pessoas.map((pessoa, index) => (
                <div className="md-typeset__scrollwrap">
                  <div className="md-typeset__table">
                    <table className="md-typset__table">
                      <tbody>
                        <tr>
                          <td align="center">
                            <Cards.Card
                              key={index}
                              img={pessoa.img}
                              nome={pessoa.nome}
                              funcao={pessoa.funcao}
                              link={pessoa.link}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </Cards.Root>
        </section>
      </main>
      <FooterWiki />
    </div>
  );
};
export default Wiki;
