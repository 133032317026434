import React from 'react';

const ProntuarioCirurgiasInfo = () => {
  return (
    <div>
      <h5>Usando o Componente <strong>Cirurgias</strong></h5>
      <p>
        O componente <strong>Cirurgias</strong> permite que você registre informações sobre cirurgias realizadas. Você pode adicionar, editar e remover cirurgias conforme necessário. Abaixo, você encontrará um guia passo a passo sobre como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li>Passo 1: Indicar se já fez alguma cirurgia</li>
        <p>
          Quando você abre o componente, você verá a pergunta "Já fez alguma cirurgia?" e duas opções: "Sim" e "Não". Marque a opção correspondente para indicar se já realizou alguma cirurgia.
        </p>
        <li>Passo 2: Adicionar cirurgias</li>
        <p>
          Se você marcar "Sim," uma seção adicional aparecerá onde você pode adicionar informações sobre as cirurgias realizadas. Digite o nome da cirurgia no campo apropriado e clique no botão "Adicionar Informações de Saúde" para inserir as informações na lista.
        </p>
        <li>Passo 3: Editar cirurgias</li>
        <p>
          Se você deseja editar uma cirurgia existente na lista, clique no botão "Editar" ao lado dela. Isso permitirá que você faça as alterações necessárias no nome da cirurgia. Após as edições, clique no botão "Adicionar Informações de Saúde" para salvar as alterações.
        </p>
        <li>Passo 4: Remover cirurgias</li>
        <p>
          Para remover uma cirurgia da lista, clique no botão "Remover" ao lado dela. A cirurgia será excluída da lista.
        </p>
        <li>Passo 5: Conclusão</li>
        <p>
          Depois de adicionar, editar ou remover cirurgias, você pode continuar usando o componente conforme necessário. Quando terminar, você pode clicar no botão "ADICIONAR INFORMAÇÕES DE SAÚDE" no final do componente para concluir o processo.
        </p>
      </ol>
      <hr />
      <p>
        Agora você está pronto para usar o componente <strong>Cirurgias</strong> para gerenciar informações sobre cirurgias realizadas. Certifique-se de marcar corretamente se já realizou cirurgias e de adicionar/editar/remover as informações conforme necessário.
      </p>
      <br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  );
};

export default ProntuarioCirurgiasInfo;
