import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../../../../../../services/api';
import { NavProfissionalMain, ReferenciaLocal, FooterProfissional, Nav, CardDirecionamento } from '../../../../../../../components';
import { Image, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Pagination } from 'antd';
export const ProdutosEstoque = () => {
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const [editedProducts, setEditedProducts] = useState({});
    const handleEdit = (products_id, productData) => {
        setEditedProducts({ ...editedProducts, [products_id]: productData });
    };
    const cancelEditing = (products_id) => {
        const updatedEditedProducts = { ...editedProducts };
        delete updatedEditedProducts[products_id];
        setEditedProducts(updatedEditedProducts);
    };
    const saveChanges = async (products_id) => {
        try {
            const response = await axios.put(`${baseURL}update_produto/${products_id}`, editedProducts[products_id]);
            if (response.status === 200) {
                const updatedProducts = products.map(product => {
                    if (product.product_id === products_id) {
                        return {
                            ...product,
                            ...editedProducts[products_id]
                        };
                    }
                    return product;
                });
                setProducts(updatedProducts);
                cancelEditing(products_id);
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

  
    const fetchProducts = useCallback(async () => {
        try {
          const storedData = JSON.parse(localStorage.getItem('drive'));
          const professional_id = storedData?.id;
          const company_unit_id = storedData?.company_unit_id;
    
          // Monta os parâmetros dinamicamente
          const params = {
            pagina: currentPage,
            resultadosPorPagina: 10,
          };
    
          if (professional_id) {
            params.professional_id = professional_id;
          }
    
          if (company_unit_id) {
            params.company_unit_id = company_unit_id;
          }
    
          const response = await axios.get(`${baseURL}get_products`, { params });
    
          setProducts(response.data.produtos);
          setTotalPages(Math.ceil(response.data.totalProdutos / 10));
          setTotalProducts(response.data.totalProdutos);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }, [currentPage]); // Dependência de currentPage
    
      useEffect(() => {
        fetchProducts();
      }, [fetchProducts]); // Adiciona fetchProducts como dependência do useEffect
    
    const handleDelete = async (products_id) => {
        try {
            const response = await axios.delete(`${baseURL}delete_produto/${products_id}`);
            if (response.status === 200) {
                const updatedProducts = products.filter(product => product.product_id !== products_id);
                setProducts(updatedProducts);
                setTotalProducts(totalProducts - 1);
                cancelEditing(products_id);
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <section>
                <div className="w-100">
                    <ReferenciaLocal text={'Produtos Cadastrados'} />
                    {products.length > 0 && (
                        <div>
                            <div className="total-products">
                                <small className='font-weight-bold'>Total Produtos Cadastrados: {totalProducts}</small>
                            </div>
                            {products.map((product) => (
                                <div key={product.product_id} className="col-md-12 mt-2 py-2 bg-light">
                                    <div className="d-flex flex-wrap justify-content-center">
                                        {!editedProducts[product.product_id] && (
                                            <div className="product-info col-md-12 d-flex flex-wrap justify-content-center mx-auto">
                                                <div className='col-md-3 mt-1 d-flex align-items-center'>
                                                    <Image width={80} height={80} src={product.products_image} style={{ borderRadius: '0.25rem' }} />
                                                </div>
                                                < div className='col-md-8 mt-1'>
                                                    <h3>{product.products_name}</h3>
                                                    <h3>{product.product_id}</h3>
                                                    <p>Tipo: {product.products_type}</p>
                                                    <p>Value: {product.value}</p>
                                                    <small>Description: {product.product_description}</small></div>
                                            </div>
                                        )}
                                        <div className="product-actions justify-content-center mx-auto ">
                                            {editedProducts[product.product_id] ? (
                                                <div className="product-edit-form mt-1">
                                                    <Input
                                                        type="text"
                                                        value={editedProducts[product.product_id].products_name}
                                                        onChange={(e) => setEditedProducts({
                                                            ...editedProducts,
                                                            [product.product_id]: { ...editedProducts[product.product_id], products_name: e.target.value }
                                                        })}
                                                    />
                                                    <Input
                                                        type="text"
                                                        value={editedProducts[product.product_id].products_type}
                                                        onChange={(e) => setEditedProducts({
                                                            ...editedProducts,
                                                            [product.product_id]: { ...editedProducts[product.product_id], products_type: e.target.value }
                                                        })}
                                                    />
                                                    <Input
                                                        type="text"
                                                        value={editedProducts[product.product_id].value}
                                                        onChange={(e) => setEditedProducts({
                                                            ...editedProducts,
                                                            [product.product_id]: { ...editedProducts[product.product_id], value: e.target.value }
                                                        })}
                                                    />
                                                    <TextArea
                                                        type="text"
                                                        value={editedProducts[product.product_id].product_description}
                                                        onChange={(e) => setEditedProducts({
                                                            ...editedProducts,
                                                            [product.product_id]: { ...editedProducts[product.product_id], product_description: e.target.value }
                                                        })}
                                                    />
                                                    <p className='text-center'>
                                                        <button onClick={() => saveChanges(product.product_id)}>Salvar</button>
                                                        <button onClick={() => cancelEditing(product.product_id)}>Cancelar</button>
                                                    </p>  </div>
                                            ) : (
                                                <div className="product-actions-buttons ">
                                                    <button onClick={() => handleEdit(product.product_id, product)}>Editar</button>
                                                    <button onClick={() => handleDelete(product.product_id)}>Excluir</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="total-products">
                                <small className='font-weight-bold'>Total Produtos Cadastrados: {totalProducts}</small>
                            </div>
                            <div className="text-center mt-3">
                                <small className='font-weight-bold'>Total Páginas: {totalPages}</small>
                            </div>
                            <div className="text-center mt-3">
                                <Pagination
                                    current={currentPage}
                                    total={totalProducts}
                                    pageSize={10}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}
                    {products.length === 0 && (
                        <>
                            <h5 className='text-center font-weight-bold'><strong>Cadastre um ou mais produto </strong></h5>
                            <div className="text-center">
                                <div className="container-fluid">
                                    <div className="row d-inline-flex justify-content-center w-100">
                                        <CardDirecionamento text={'Cadastrar Produto'} icon={'mt-3 fa fa-line-chart fa-3x text-white'} href={`/profissionais/produtos/cadastrar`} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <FooterProfissional />
                </div>
            </section>
        </div>
    </>);
};
export default ProdutosEstoque;