import NavHeader from "../NavHeader/NavHeader";
import NavProfissionais from "./NavProfissional";
export const NavProfissionalMain = () => {
    return (<>
    <br/>
        <NavHeader
            HTML={
                <NavProfissionais />
            }
        />
    </>)
}