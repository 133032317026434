import React from "react";
import { Header } from '../../components/Header/Header'
import UrlInput from "./urlInput";
import Analyze from "../Analyze/Analyze"
import './Configuracoes.css'
import { FooterWiki } from '../Footer/Footer'

export function Configuracoes() {

  return (
    <>
      <Header pagina="configuracoes"/>
      <main className="configuracao-main-content">
        <h1 className="configuracao-main-content-title">Configurações</h1>
        <Analyze />
        <span id="getGithub">
          <h6 className="configuracao-main-input-title">Diretório GitHub</h6>
          <div className="configuracao-wrapper">          
            <input 
            type="text"
            placeholder="Insira um repositório do Github"
            className="configuracao-main-content-input"
            />
            <button className="btn btn-primary configuracao-btn-github">
              Enviar
            </button>
          </div>
        </span>
        
        <span id="getUrl">
          <h6 className="configuracao-main-input-title">Diretório Central</h6>
          <div className="configuracao-wrapper">
            <input 
            type="text"
            value={UrlInput.url}
            onChange={UrlInput.handleUrlChange}
            className="configuracao-main-content-input"
            placeholder="Insira uma URL"
            />
            <button className="btn btn-primary configuracao-btn-diretorio">
              Enviar
              </button>
            </div>
        </span>
      </main>
      <FooterWiki/>
    </>
  )
};