import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '../../../../../../../../components';

const ProntuarioDentistaFeridaFaceLabios = () => {
  const [feridaFaceLabios, setFeridaFaceLabios] = useState(false);
  const [historicoFeridaFaceLabios, setHistoricoFeridaFaceLabios] = useState([]);
  const [novaResposta, setNovaResposta] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);

  const handleCheckboxChange = () => {
    setFeridaFaceLabios(!feridaFaceLabios);
  };

  const handleAdicionarResposta = () => {
    if (novaResposta.trim() !== '') {
      if (indiceEditando === -1) {
        setHistoricoFeridaFaceLabios([...historicoFeridaFaceLabios, novaResposta]);
      } else {
        const novaLista = [...historicoFeridaFaceLabios];
        novaLista[indiceEditando] = novaResposta;
        setHistoricoFeridaFaceLabios(novaLista);
        setIndiceEditando(-1);
      }
      setNovaResposta('');
    }
  };

  const handleRemoverResposta = (index) => {
    const novaLista = [...historicoFeridaFaceLabios];
    novaLista.splice(index, 1);
    setHistoricoFeridaFaceLabios(novaLista);
  };

  const handleEditarResposta = (index) => {
    setNovaResposta(historicoFeridaFaceLabios[index]);
    setIndiceEditando(index);
  };

  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Já teve alguma ferida ou bolha na face ou nos lábios?</h5>
      <label>
        <input type="checkbox" defaultChecked={feridaFaceLabios} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!feridaFaceLabios} onChange={() => setFeridaFaceLabios(false)} /> Não
      </label>
      {feridaFaceLabios && (
        <>
          <h5 style={{ fontWeight: "bold" }}>Histórico de Respostas:</h5>
          <div>
            <input
              type="text"
              value={novaResposta}
              onChange={(e) => setNovaResposta(e.target.value)}
              placeholder="Nova resposta"
            />
            <ButtonAdicionarCampo onClick={handleAdicionarResposta} />
          </div>
        </>
      )}

      <ul>
        {historicoFeridaFaceLabios.map((resposta, index) => (
          <li key={index}>
            {resposta}
            <ButtonEditarCampo onClick={() => handleEditarResposta(index)} />
            <ButtonRemoverCampo onClick={() => handleRemoverResposta(index)} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProntuarioDentistaFeridaFaceLabios;
