import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Upload, message, Input, Button, List, Progress, Pagination, Modal } from 'antd';
import { DeleteOutlined, InboxOutlined, LikeOutlined, UndoOutlined } from '@ant-design/icons';
import { baseURL } from '../../../../../../../../../services/api';

const { confirm } = Modal;
export function InteracaoIAImagem({ centralFolderName }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [classesPerPage, setClassesPerPage] = useState(1); // Número de classes por página
  const [showJson, setShowJson] = useState(false);
  const handleUpload = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('images', file);
    formData.append('centralFolderName', centralFolderName.toString());
    try {
      const res = await axios.post(`${baseURL}testeIAImagem`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      message.success(`${file.name} uploaded successfully.`);
      return { url: URL.createObjectURL(file), response: res.data, error: null };
    } catch (error) {
      message.error(`${file.name} upload failed.`);
      return { url: '', response: null, error: error.response.data.error };
    } finally {
      setLoading(false);
    }
  };
  const handleChange = async (info) => {
    const fileList = [...info.fileList];
    const updatedFiles = await Promise.all(fileList.map(async (file) => {
      if (file.originFileObj) {
        file.url = URL.createObjectURL(file.originFileObj);
      }
      return file;
    }));
    setFiles(updatedFiles);
    const lastUploaded = updatedFiles[updatedFiles.length - 1];
    if (lastUploaded && lastUploaded.status === 'done') {
      const updatedFilesWithResponse = await Promise.all(updatedFiles.map(async (file) => {
        if (file.status === 'done') {
          return await handleUpload(file.originFileObj);
        }
        return file;
      }));
      setFiles(updatedFilesWithResponse);
    }
  };
  const handleRetry = async (index) => {
    setLoading(true);
    const updatedFile = await handleUpload(files[index].originFileObj);
    const updatedFiles = [...files];
    updatedFiles[index] = updatedFile;
    setFiles(updatedFiles);
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
    }
  };
  const indexOfLastClass = currentPage * classesPerPage;
  const indexOfFirstClass = indexOfLastClass - classesPerPage;
  const currentClasses = files.length > 0 && files[files.length - 1].response && files[files.length - 1].response.predictions && files[files.length - 1].response.predictions[0] && files[files.length - 1].response.predictions[0].predictions ? files[files.length - 1].response.predictions[0].predictions.slice(indexOfFirstClass, indexOfLastClass) : [];
  const handleShowJson = () => {
    setShowJson(!showJson);
    if (!showJson) {
      setClassesPerPage(5)
    }
    else {
      setClassesPerPage(1)
    }
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleLike = async (index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = { ...updatedFiles[index], liked: true };
    setFiles(updatedFiles);
  };
  const handleDelete = (index) => {
    confirm({
      title: 'Tem certeza que deseja excluir esta imagem?',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        const updatedFiles = files.filter((file, i) => i !== index);
        setFiles(updatedFiles);
      },
    });
  };
  useEffect(() => {
  }, [centralFolderName]);
  return (
    <>
      <List
        itemLayout="vertical"
        dataSource={files}
        renderItem={(file, index) => (
          <List.Item key={index}>
            <div className='py-2'>
              <img src={file.url} alt={`Uploaded ${index + 1}`} className="uploaded-image" style={{ maxHeight: '200px', minHeight: '200px' }} />
              {file.response && (
                <>
                  {Array.isArray(file.response.predictions) && file.response.predictions.length > 0 && (
                    <div className="upload-bar">
                      {currentClasses.map((prediction, index) => (
                        <div key={index} className='w-100' >
                          <Progress className='col-md-10' percent={parseFloat((prediction.confidence * 100).toFixed(2))}
                            strokeColor={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Gera uma cor aleatória para cada classe
                            status="active" />
                          <p> <small>{index + 1}.<abbr>{prediction.class}</abbr></small></p>
                        </div>
                      ))}
                    </div>
                  )}
                  {!file.error && (
                    <p className='py-2'>
                      <Button onClick={() => handleLike(index)} icon={<LikeOutlined />} className="ml-2"></Button>
                      <Button onClick={() => handleRetry(index)} loading={loading} icon={<UndoOutlined />} className="ml-2"></Button>
                      <Button onClick={handleShowJson} className={showJson ? 'btn-danger ml-2' : 'ml-2'}>{showJson ? 'Ocultar Detalhes' : 'Mostrar Detalhes'} </Button>
                      <Button onClick={() => handleDelete(index)} icon={<DeleteOutlined />} className="ml-2"></Button>
                    </p>
                  )}
                  {!file.error && (<div className="response" >
                    {showJson && (<>
                      <Pagination className="pagination text-center d-flex  align-items-center justify-content-center "
                        current={currentPage}
                        total={file.response.predictions.length}
                        pageSize={classesPerPage}
                        onChange={paginate}
                        showSizeChanger
                        onShowSizeChange={(current, size) => setClassesPerPage(size)}
                        pageSizeOptions={showJson.length > 5 ? ['5', '10', '15', '50', '100', `${currentClasses.length}`] : [`${currentClasses.length}`]}
                      />
                      <pre style={{ textOverflow: 'ellipsis' }}>{JSON.stringify(file.response, null, 2)}</pre>
                      <p className='text-center'> <Button onClick={handleShowJson} className={showJson ? 'btn-danger ml-2' : 'ml-2'}>{showJson ? 'Ocultar Detalhes' : 'Mostrar Detalhes'} </Button>
                      </p>
                    </>
                    )}
                  </div>)}
                </>
              )}
              {file.error && (
                <div className="error">
                  <p className='text-warning'><strong>Error: {file.error.message}</strong></p>
                  <Button onClick={() => handleRetry(index)} loading={loading}>Tentar novamente</Button>
                </div>
              )}
            </div>
          </List.Item>
        )}
      />
      <div className="col-md-12">
        {loading && <p>Loading...</p>}
      </div>
      <div className="col-md-12 py-2 bg-light rounded mb-5">
        <div className="col-md-12">
          <Upload.Dragger
            name="images"
            multiple={true}
            action={`${baseURL}testeIAImagem`}
            onChange={handleChange}
            fileList={files}
            accept="image/*"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Clique ou arraste as imagens para esta área para fazer o upload</p>
            <p className="ant-upload-hint">Suporte para upload de múltiplas imagens.</p>
          </Upload.Dragger>
        </div>
        <div className="generate-container">
          <div className="input-container mt-3">
            <Input
              className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
              placeholder="Quero saber sobre ?"
              onKeyDown={handleEnterKeyPress}
            />
            <button className={` generate-button`} onClick={handleEnterKeyPress}>
              <i className={` fa fa-rocket mt-2`}></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default InteracaoIAImagem;