import React from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { TitleL1, TitleL2 } from '../../../../../components';
const { TextArea } = Input;
const Fornecedores = () => {
    const onFinish = (values) => {
        console.log('Valores do formulário:', values);
        // Aqui você pode enviar os valores do formulário para o backend ou fazer outra ação
    };
    return (<>
        <section className='mr-3 ml-3'style={{ minHeight: '95dvh' }}>
            <p className='text-center'>
                <img className="d-block image-fluid mx-auto col-md-12"
                    style={{ maxHeight: '450px', maxWidth: '450px'}}
                    src={require('../assets/Fornecedores.png')} alt='Imagem de lâmpada moderna para representar a ideia e o desenvolvimento' />
            </p>
            <TitleL1>Fornecedores Dropshipping</TitleL1>
            <h5 className='text-justify'>
                Conectar seu e-commerce ao nosso serviço de dropshipping pode trazer uma série de benefícios
                significativos que impulsionarão o sucesso do seu negócio.
                Em primeiro lugar, nossa parceria permitirá que você expanda instantaneamente
                seu carteira de clientes sem a necessidade de investimento adicional
            </h5>
            <h5 className='mt-5 font-weight-bold'><strong>Programa de parceria recomendado para:</strong></h5>
            <ul className=' mb-4'>
                <h5>
                <li>✅ E-commerces</li>
                </h5>
            </ul>
            <Form
                name="Fornecedores-regional-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                className='border bg-light py-2 rounded text-center'
            >
                <TitleL2>Dados do Fornecedor</TitleL2>
                <Divider />
                <div className='mr-2 ml-2'>
                    <Form.Item
                        label="Site"
                        name="site"
                        rules={[{ required: true, message: 'Por favor, insira seu site!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Mensagem" name="message">
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Enviar
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </section>
    </>);
};

export default Fornecedores;
