export const addHeader = (props) => {
    props.doc.setFontSize(8);
    props.doc.text('Paciente: ' + props.user, 10, 40, { align: 'left' });
    props.doc.text('Idade: ' + props.idade.toString(), 10, 45, { align: 'left' });
    props.doc.text('Empresa: ' + props.empresa, 10, 50, { align: 'left' });
    props.doc.text('Responsável: ' + props.profissional, 10, 55, { align: 'left' });
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `Dia da Análise: ${day}/${month}/${year}`;
    props.doc.text(formattedDate, 10, 60, { align: 'left' });
}
export default addHeader;