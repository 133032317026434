export const proximoPassoTutorial = (tutorialStep, setTutorialStep, stepActions, setCurrentAction) => {
    if (tutorialStep < stepActions.length - 1) {
        // Avança para o próximo passo
        setTutorialStep(tutorialStep + 1);
        stepActions[tutorialStep + 1](); // Executa a função do próximo passo
    } else {
        // Finaliza o tutorial e reseta
        setCurrentAction(null); // Finaliza o tutorial
        setTutorialStep(0); // Reseta o passo do tutorial
    }
};
