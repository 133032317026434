import { IndexPublicCadastrar } from "./partials/layout/IndexPublicCadastrar";
import { Nav, Footer } from "../../../components";
import { useLocation } from "react-router-dom";
export const Cadastrar = () => {
  const location = useLocation();
  const isCadastrarPage = location.pathname.startsWith("/cadastrar");
  return (<>
    {isCadastrarPage && <Nav />}
    <IndexPublicCadastrar />
    {isCadastrarPage && <Footer />}
  </>);
};
