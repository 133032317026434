import React, { useState } from 'react';
import { MaisInfoMenosInfo } from '../../../../../../../components';
import MonitoringTrainIATextInfo from '../info/MonitoringTrainIATextInfo';
export function MonitoringTrainIAtext({ progressData, setProgressData })  {
 
  const [simulationProgress, setSimulationProgress] = useState(0);
  const simulationInterval = 500; // Interval in milliseconds for simulating progress

  const startSimulation = () => {
    let currentProgress = 0;
    const maxStepValue = 10000; // Maximum value for the step
  
    const simulationTimer = setInterval(() => {
      currentProgress += 1; // Increment the progress by 1 in each simulation step
      const currentStep = Math.floor((currentProgress / 100) * maxStepValue); // Calculate current step
  
      if (currentProgress <= 100) {
        // Calculate random values for epoch, loss, and sentencesProcessed
        const randomEpoch = 1; // Random value between 0 and 9
        const randomLoss = Math.random() * 0.5; // Random value between 0 and 0.5
        const randomSentencesProcessed = 10000; // Random value between 0 and 99  
        // Update simulation progress
        setSimulationProgress(currentProgress);  
        // Update progressData with simulation progress and random values
        setProgressData({
          ...progressData,
          status: 'Training...',
          progress: currentProgress,
          epoch: randomEpoch,
          step: currentStep, // Update step with the calculated current step
          loss: randomLoss,
          sentencesProcessed: randomSentencesProcessed,
        });
      } else {
        clearInterval(simulationTimer); // Stop the simulation when progress reaches 100%
        setSimulationProgress(false);
      }
    }, simulationInterval);
  };
  return (
    <div className="col-md-12 mt-2 rounded"
            style={{
              backgroundColor: '#f1f1f1', /* Cor do background */
              padding: '10px', /* Espaçamento interno em todos os lados */
              marginLeft: '10px', // Add margin of 5 pixels to the left
              marginRight: "10px", // Add margin of 5 pixels to the right
            }}>
      <h5 className='font-weight-bold mt-3 mb-3'>Criar I.A. - Stage 3 - Monitorar Treinamento</h5>
      <div className="training-status">
        {progressData.status && (
          <>
            <small className='font-weight-bold te'>Barra de progresso</small>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progressData.progress}%`, height: '25px' }}
                aria-valuenow={progressData.progress.toFixed(2)}
                aria-valuemin="0"
                aria-valuemax="100"
              ><h5 className='mt-1'>{progressData.progress}%</h5></div>
            </div>
          </>
        )}
        {progressData.status && (
          <div className="status-messages">
            <div>
              <p>Status: {progressData.status}</p>
              <p>Epoch: {progressData.epoch}</p>
              <p>Step: {progressData.step}</p>
              <p>Progress: {progressData.progress.toFixed(2)}%</p>
              <p>Loss: {progressData.loss.toFixed(4)}</p>
              <p>Sentences Processed: {progressData.sentencesProcessed}</p>
            </div>
          </div>
        )}
      </div>
    <MaisInfoMenosInfo text={<MonitoringTrainIATextInfo simulationProgress={simulationProgress} startSimulation={startSimulation} />}></MaisInfoMenosInfo>  
    </div>
  );
}

export default MonitoringTrainIAtext;
