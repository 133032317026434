import { useLocation } from 'react-router-dom';
import { CardDirecionamento } from '../../../../../components';
import {
  MdRoomService,
  MdCategory,
  MdLibraryBooks
} from "react-icons/md";
export const BtnPulbicCarrinhoVazio = () => {
  const location = useLocation();
  const isCarrinhoPage = location.pathname.startsWith("/carrinho");
  const { search } = location;
  return (<>
    <section >
      <div className="row d-inline-flex justify-content-center w-100">
        <CardDirecionamento href={isCarrinhoPage ? `../servicos${search}` : `../usuario/servicos${search}`} title={'História'} text={'Serviços'} iconReact={<MdRoomService style={{ scale: '3', marginTop: '22px' }} />} />
        <CardDirecionamento href={isCarrinhoPage ? `../produtos${search}` : `../usuario/produtos${search}`} title={'História'} text={'Produtos'} iconReact={<MdCategory style={{ scale: '3', marginTop: '22px' }} />} />
        <CardDirecionamento href={isCarrinhoPage ? `../artigos${search}` : `../usuario/artigos${search}`} title={'História'} text={'Artigos'} iconReact={<MdLibraryBooks style={{ scale: '3', marginTop: '22px' }} />} />
      </div>
    </section>
  </>);
};
export default BtnPulbicCarrinhoVazio;