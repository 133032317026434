import React from "react";
import '../styles/IdentificacaoComprador.css'

const IdentificacaoFornecedor = ({ nome, assinatura, crf, crfuf, farmacia, endereco, cidade, uf, telefone, cnpj }) => {
    return (
        <div className="principalCompradorId">
        <h2>IDENTIFICAÇÃO DO FORNECEDOR</h2>
            <div className="containerCompradorDiv">
                <p>NOME DO FARMACEUTICO: {nome}</p>
                <p className="crfP">
                    <span className="crfP-70">CRF: {crf}</span>
                    <span className="crfP-30">UF: {crfuf}</span>
                </p>
                <p>NOME DA FARMACIA {farmacia}</p>
                <p>ENDEREÇO: {endereco}</p>
                <p className="cidadeUfF">
                    <span className="cidadeUfF-70">CIDADE: {cidade}</span>
                    <span className="cidadeUfF-30">UF: {uf}</span>
                </p>
                <p>CNPJ: {cnpj}</p>
                <p>TELEFONE: {telefone}</p>
                <p>ASSINATURA DO FARMACEUTICO {assinatura}</p>
            </div>
        </div>
    );
    }
    export default IdentificacaoFornecedor;