import React, { useState } from 'react';

export const ProntuarioEnfermeiro = () => {
  const [queixaPrincipal, setQueixaPrincipal] = useState('');
  const [objetivoTratamento, setObjetivoTratamento] = useState('');

  return (
    <div>
      <h5 style={{ fontWeight: "bold" }} className="mt-5 text-center">Enfermeiro</h5>

      <h5 style={{ fontWeight: "bold" }} className="mt-5">Queixa principal:</h5>
      <textarea
        required
        id="AnamnesesEnfermeiroQueixaPrincipal"
        type="text"
        rows="2"
        data-ls-module="charCounter"
        value={queixaPrincipal}
        onChange={(e) => setQueixaPrincipal(e.target.value)}
        name=""
        maxlength="100"
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 className="text-justify">Insira o motivo principal de ir ao Enfermeiro.</h5>

      <h5 style={{ fontWeight: "bold" }} className="mt-5">Objetivo do tratamento</h5>
      <textarea
        required
        id="AnamnesesEnfermeiroObjetivoDoTratamento"
        type="text"
        rows="2"
        data-ls-module="charCounter"
        value={objetivoTratamento}
        onChange={(e) => setObjetivoTratamento(e.target.value)}
        name=""
        maxlength="100"
        className="form-control text-center form-control-lg border-dark"
      />
      <h5 className="text-justify">Insira o objetivo do tratamento - O que espera com o tratamento do Enfermeiro.</h5>
    </div>
  );
};



export default ProntuarioEnfermeiro;