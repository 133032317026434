import React from 'react';
import { chartTypes } from '../../../../../../Private/Profissionais/DashBoardFinanceiro/components/graficosDinamicos/ChartSelection';
import { ButtonCadastrar, IconInfo, MaisInfoMenosInfo, TitleL1 } from '../../../../../../../components';
import DashboardServiceAdvanced from './DashboardServiceAdvanced';
export function DashboardService() {
  return (<>
    <section  className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
      <p className='text-center'>
        <img
          src={require('../assets/DashboardDinamico.png')}
          alt="Liberdade Financeira"
          style={{ maxHeight: '280px', maxWidth: '350px' }}
          className=' col-md-12'
        />
      </p>
      <TitleL1> Dashboard</TitleL1>
      <h5 className='text-justify'>
        Este é o seu portal para explorar e analisar dados de forma eficiente,
        permitindo que você tome decisões informadas em tempo real. Com nossa
        plataforma, você pode exploarar seus dados dessa plataforma e ou se conectar a outro banco de dados, para fazer análises
        detalhadas e transmitir suas descobertas de maneira clara e impactante
      </h5>
      <h5 className='font-weight-bold mt-4 py-3'><strong>Principais Recursos: </strong></h5>
      <ul className='text-justify'>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Vizualize o Modelo de Dashboard de Dados Padrão:&nbsp;
          <IconInfo text={" Crie um conjunto de gráficos padrão com 1 clique para comunicar insights da plataforma com clareza"} />
        </h5>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Crie Modelos de Visualização de Dados Avançada:&nbsp;
          <IconInfo text={" Crie visualizações interativas e personalizadas para comunicar insights diferenciados com clareza"} />
        </h5>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Tome de Decisões Baseada em Dados:&nbsp;
          <IconInfo text={"Utilize análises avançadas para orientar estratégias de negócios"} />
        </h5>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Use Inteligência de Negócios Preditiva:&nbsp;
          <IconInfo text={"Preveja tendências e eventos futuros"} />
        </h5>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Tenha Relatórios Personalizados:&nbsp;
          <IconInfo text={"Crie relatórios sob medida para atender às necessidades específicas de sua equipe"} />
        </h5>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Exponha Resultados Impactantes:&nbsp;
          <IconInfo text={"Obtenha facilmente insights que impulsionam o sucesso de sua empresa"} />
        </h5>
        <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Segurança e Privacidade de Dados:&nbsp;
          <IconInfo text={"Garanta a proteção dos dados de sua empresa com medidas avançadas de segurança"} />
        </h5>
      </ul>
      <br />  <br />
      <h5 className='font-weight-bold mt-5'><strong>Tipos de gráficos:</strong></h5>
      <div className="col-md-12 ">
        <div className="d-flex flex-wrap justify-content-center mb-5">
          {chartTypes.map((chart) => (
            <div
              key={chart.type}
              className='col-md-2 mt-5 justify-content-center text-center'        >
              <img src={chart.imageSrc} alt={chart.label} className=" mt-2" style={{ maxHeight: '60px', minHeight: '60px' }} />
              <div className='text-center font-weight-bold mt-1'><strong>{chart.label}</strong></div>
            </div>
          ))}
        </div>
      </div>
      <div className='py-4 text-center'>
        <MaisInfoMenosInfo text={<><DashboardServiceAdvanced /></>} />
      </div>
      <ButtonCadastrar/>
    </section >
  </>);
}
export default DashboardService;
