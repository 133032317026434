import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, MaisInfoMenosInfo, ButtonRemoverCampo, ButtonEditarCampo } from '../../../../../../components';
import ProntuarioAlergiasInfo from '../info/ProntuarioAlergiasInfo';

export const ProntuarioAlergias = () => {
  const [temAlergia, setTemAlergia] = useState(false);
  const [alergias, setAlergias] = useState([]);
  const [novaAlergia, setNovaAlergia] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);

  const handleCheckboxChange = (e) => {
    setTemAlergia(e.target.checked);
  };

  const handleNovaAlergiaChange = (e) => {
    setNovaAlergia(e.target.value);
  };

  const handleAdicionarAlergia = () => {
    if (novaAlergia.trim() !== '') {
      if (indiceEditando === -1) {
        setAlergias([...alergias, novaAlergia]);
      } else {
        const novaLista = [...alergias];
        novaLista[indiceEditando] = novaAlergia;
        setAlergias(novaLista);
        setIndiceEditando(-1); 
      }
      setNovaAlergia('');
    }
  };

  const handleRemoverAlergia = (index) => {
    const novaLista = [...alergias];
    novaLista.splice(index, 1);
    setAlergias(novaLista);
  };

  const handleEditarAlergia = (index) => {
    setNovaAlergia(alergias[index]);
    setIndiceEditando(index);
  };
  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        Tem algum tipo de alergia?   </h5>
      <label>
        <input type="checkbox" onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" onChange={() => setTemAlergia(false)} /> Não
      </label>
      <br />
      <label>
        <input type="checkbox" onChange={() => setTemAlergia(true)} /> Não Sei
      </label>
      {temAlergia && (
        <div>
          *Se Sim, quais?  <br />
          <input
            type="text"
            value={novaAlergia}
            onChange={handleNovaAlergiaChange}
            placeholder="Especifique a alergia"
          />
          <ButtonAdicionarCampo onClick={handleAdicionarAlergia}/>
        </div>
      )}
      {temAlergia && alergias.length > 0 && (
        <div className='py-3'>
          <h6>Alergias Adicionadas:</h6>
          <ul>
            {alergias.map((alergia, index) => (
              <li key={index}>{alergia}
                <ButtonEditarCampo onClick={() => handleEditarAlergia(index)} />
                <ButtonRemoverCampo onClick={() => handleRemoverAlergia(index)} />              
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>
       <MaisInfoMenosInfo text={<ProntuarioAlergiasInfo />}></MaisInfoMenosInfo> 
   
    </>
  );
};

export default ProntuarioAlergias;
