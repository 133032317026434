import React, { useState, useRef } from 'react';
import { Card, Upload, Button, message, Image } from 'antd';
import { MdCamera, MdCloudUpload, MdDelete, MdCheckCircle } from "react-icons/md";
import Webcam from 'react-webcam';
import Draggable from 'react-draggable'; // Importando a biblioteca de arrasto
import axios from 'axios';
import FaceVideoRecorder from './FaceVideoRecorder'; // Import the video recorder component
import { axiosHeadersToken } from '../../../../../../../../../../../services/contexts';
import { baseURL } from '../../../../../../../../../../../services/api';
import { MaisInfoMenosInfo } from '../../../../../../../../../../../components';

export const FacePhotoCollector = ({ setImage, handleImageUpload, thumbnail, setThumbnail, piscaMaisInfo, highlightStates, photos, setPhotos }) => {
  const [capturing, setCapturing] = useState(false);
  const [currentType, setCurrentType] = useState(null);
  const [landmarks, setLandmarks] = useState([]); // Agora é um array de objetos com coordenadas
  const [modifiedLandmarks, setModifiedLandmarks] = useState([]); // State to store modified landmarks
  const [originalImage, setOriginalImage] = useState(null);
  const [modifiedImage, setModifiedImage] = useState(null); // Para armazenar a imagem modificada
  const [videoBlob, setVideoBlob] = useState(null); // State to hold video blob data
  const webcamRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [pontoDestacado, setPontoDestacado] = useState(null);
  const [uploadCount, setUploadCount] = useState({});
  const [analiseFrontalAR, setAnaliseFrontalAR] = useState({})
  const [others, setAnaliseOthers] = useState({})
  // Handle photo capture
  const handleCapture = (type) => {
    setCurrentType(type);
    setCapturing(true);
  };

  const capturePhoto = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setThumbnail((prev) => ({ ...prev, [currentType]: imageSrc }));
        setPhotos((prev) => ({ ...prev, [currentType]: imageSrc }));
        setCapturing(false);
        setCurrentType(null);
        // Se a captura atual for da foto frontal, atualiza setImage
        if (currentType === 'FaceFrontal') {
          setImage(imageSrc);  // Atualiza o estado com a imagem frontal capturada
          handleImageUpload()
        }

      }
    } else {
      message.error('A câmera não está disponível.');
    }
  };

  // Handle video blob update from FaceVideoRecorder
  const handleVideoBlob = (blob) => {
    setVideoBlob(blob);
  };

  // Submit individual photo to backend
  const handleSubmitSingle = async (type) => {
    const formData = new FormData();
    const selectedPhoto = photos[type];
    if (selectedPhoto) {
      formData.append('image', selectedPhoto);
      const imageUrl = URL.createObjectURL(selectedPhoto);
      setOriginalImage(imageUrl);
    }
    formData.append('type', type);
    try {
      const response = await axios.post(`${baseURL}analiseFacial`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (type === 'FaceEsquerda') {
        const { landmarks, image, lines, original_image } = response.data;
        // Handle FaceEsquerda type data
        setLandmarks(landmarks);
        setModifiedImage(image);
        setModifiedLandmarks(
          landmarks.map(landmark => ({ ...landmark, isDragging: false })) // Initialize dragging state for FaceEsquerda
        );
      } else if (type === 'FaceFrontalAR') {
        // Handle FaceFrontalAR type data
        setAnaliseFrontalAR(response.data);
        setModifiedImage(response.data.response.modified_image_data)
        
        // Additional processing for FaceFrontalAR can be added here if needed
      } else {
        setAnaliseOthers(response.data)
        console.warn('Unexpected analysis type:', type);
      }
      // Incrementar a contagem de envios
      setUploadCount((prevCount) => ({
        ...prevCount,
        [type]: (prevCount[type] || 0) + 1,
      }));
      message.success(`${type} enviada com sucesso!`);
    } catch (error) {
      console.error(`Erro ao enviar a foto ${type}:`, error);
      message.error(`Erro ao enviar a foto ${type}.`);
    }
  };

  // Handle photo deletion
  const handleDelete = (type) => {
    setThumbnail((prev) => {
      const newState = { ...prev };
      delete newState[type];
      return newState;
    });
    setPhotos((prev) => {
      const newState = { ...prev };
      delete newState[type];
      return newState;
    });
  };

  // Render individual photo card
  const renderCard = (title, type) => (
    <Card title={title} id={`card_id_${type}`} style={{
      border: highlightStates[type] ? '2px solid red' : 'none', // Adiciona uma borda vermelha se destacado
      borderRadius: '5px', // Arredonda os cantos
      boxShadow: highlightStates[type] ? '0 0 10px rgba(255, 0, 0, 0.5)' : 'none', // Sombra para destaque
      marginBottom: 16
    }} >
      {thumbnail[type] && (
        <div style={{ marginBottom: 16 }}>
          <Image
            src={thumbnail[type]}
            alt={`${type}`}
            style={{ width: 80, height: 80, objectFit: 'cover' }}
          />
          <Button
            icon={<MdDelete />}
            onClick={() => handleDelete(type)}
            style={{ marginTop: 8 }}
            type="danger"
          >
            Excluir
          </Button>
        </div>
      )}
      <Upload
        customRequest={({ file, onSuccess, onError }) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            setThumbnail((prev) => ({ ...prev, [type]: e.target.result }));
            setPhotos((prev) => ({ ...prev, [type]: file }));
            onSuccess();
            // Atualizando o estado com a imagem carregada
            if (type === 'FaceFrontal') {
              setImage(file);  // Chamando setImage com a imagem carregada
              handleImageUpload(file); // Pass the image data to the upload handler
            }

            onSuccess(); // Mark the upload as successful
          };

          reader.onerror = (error) => {
            onError(error);
            message.error('Erro ao carregar a imagem.');
          };
          reader.readAsDataURL(file);
        }}
        showUploadList={false}
        accept="image/*"
      >
        <Button icon={<MdCloudUpload />}>Upload</Button>
      </Upload>
      <Button icon={<MdCamera />} onClick={() => handleCapture(type)}>
        Capturar com Câmera
      </Button>

      {/* Individual submit button */}
      <Button
        type="primary"
        onClick={() => handleSubmitSingle(type)}
        style={{ marginTop: 8 }}
      >
        Enviar Arquivo
      </Button>
      {uploadCount[type] > 0 && (
        <div style={{ marginTop: 8, color: 'green' }}>
          <MdCheckCircle style={{ marginRight: 4 }} />
          {uploadCount[type]} envio(s) já realizado(s) para {title}.
        </div>
      )}
      <MaisInfoMenosInfo destacar={piscaMaisInfo} />
    </Card>
  );
  const handlePointMouseDown = (e, index) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    const imageRect = e.target.getBoundingClientRect();
    const offsetX = clientX - imageRect.left;
    const offsetY = clientY - imageRect.top;
    const initialX = modifiedLandmarks[index].x * imageWidth;
    const initialY = modifiedLandmarks[index].y * imageHeight;
    const deltaX = offsetX - initialX;
    const deltaY = offsetY - initialY;
    const updatePointCoordinates = (x, y) => {
      const newModifiedLandmarks = [...modifiedLandmarks];
      if (!newModifiedLandmarks[index]) {
        newModifiedLandmarks[index] = { x: 0, y: 0 };
      }
      newModifiedLandmarks[index].x = (x - deltaX) / imageWidth;
      newModifiedLandmarks[index].y = (y - deltaY) / imageHeight;
      setModifiedLandmarks(newModifiedLandmarks);
    };
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const offsetX = clientX - imageRect.left;
      const offsetY = clientY - imageRect.top;
      updatePointCoordinates(offsetX, offsetY);
    };
    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };
  const handleImageLoad = (e) => {
    setImageWidth(e.target.width);
    setImageHeight(e.target.height);

  };

  return (
    <div>
      {/* Renderização das predições e imagens */}
      {landmarks > 0 && (
        <div className='col-md-12'>
          <h4>Landmarks Detected:</h4>
          <li>{JSON.stringify(landmarks, null, 2)}</li>
        </div>
      )}
      {originalImage && (
        <div className='d-flex justify-content-center align-items-center col-md-12'>
          <div style={{ position: 'relative' }}>
            {originalImage && (
              <div className='d-flex justify-content-center align-items-center col-md-12'>
                <div style={{ position: 'relative' }}>
                  <img
                    className="d-block w-100 mx-auto"
                    alt="Uploaded"
                    width="auto"
                    height="auto"
                    src={typeof originalImage === 'string' && originalImage.startsWith('data:image/jpeg;base64,')
                      ? originalImage
                      : originalImage
                    }
                    onLoad={handleImageLoad}
                  />
                </div>
              </div>
            )}
            {modifiedLandmarks.map((landmark, index) => (
              (pontoDestacado === null || pontoDestacado === index) && (
                <div
                  key={index}
                  style={{
                    position: 'absolute',
                    left: landmark && landmark.x !== undefined ? landmark.x * imageWidth : 0,
                    top: landmark && landmark.y !== undefined ? landmark.y * imageHeight : 0,
                    width: '5px',
                    height: '5px',
                    backgroundColor: 'red',
                    cursor: 'pointer',
                  }}
                  onMouseDown={(e) => handlePointMouseDown(e, index)}
                ></div>
              )
            ))}
          </div>
        </div>
      )}

      {modifiedImage && (
        <div className='d-flex justify-content-center align-items-center col-md-12'>
          <div style={{ position: 'relative' }}>
            <Image
              className="d-block w-100 mx-auto"
              alt="Uploaded"
              style={{ width: 150, height: 150, objectFit: 'cover' }}
              src={`data:image/jpeg;base64,${modifiedImage}`
              }
              onLoad={handleImageLoad}
            />

          </div>
        </div>
      )}

      {capturing && (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            height="auto"
          />
          <Button onClick={capturePhoto} style={{ marginTop: 16 }}>
            Capturar Foto
          </Button>
        </div>
      )}
      <div className='row bg-light' style={{ padding: '10px', margin: '10px' }}>
        <p className='col-md-12 text-left'>Capturas da Face</p>
        {/* <div className='col-md-3'><FaceVideoRecorder piscaMaisInfo={piscaMaisInfo} onVideoBlob={handleVideoBlob} /></div> */}
        <div className='col-md-3'>{renderCard('Frontal', 'FaceFrontal')}</div>
        <div className='col-md-3'>{renderCard('Realidade Aumentada - Frontal', 'FaceFrontalAR')} </div>
        <div className='col-md-3'>{renderCard('Frontal Sorrindo', 'FaceSorrindo')}</div>
        <div className='col-md-3'>{renderCard('Frontal Bravo', 'FaceBravo')}</div>
        <div className='col-md-3'>{renderCard('Frontal Atenção', 'FaceAtencao')}</div>
        <div className='col-md-3'>{renderCard('Lateral Esquerda', 'FaceEsquerda')}</div>
        <div className='col-md-3'>{renderCard('Lateral Direita', 'FaceDireita')}</div>
        <p className='col-md-12 text-left'>Capturas da Boca</p>
        <div className='col-md-3'>{renderCard('Superior', 'BocaSuperior')}</div>
        <div className='col-md-3'>{renderCard('Inferior', 'BocaInferior')}</div>
        <div className='col-md-3'>{renderCard('Frontal Fechada', 'BocaFrontalFechada')}</div>
        <div className='col-md-3'>{renderCard('Lateral Esquerda', 'BocaFechadaEsquerda')}</div>
        <div className='col-md-3'>{renderCard('Lateral Direita', 'BocaFechadaDireita')}</div>
        <p className='col-md-12 text-left'>Raio-X</p>
        <div className='col-md-3'>{renderCard('Panorâmica', 'Panoramica')}</div>
        <div className='col-md-3'>{renderCard('Cefalométrica', 'Cefalometrica')}</div>
      </div>
    </div>
  );
};
export default FacePhotoCollector;