import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { baseURL } from "../../../../../../services/api";
import ProntuarioSelectProfissionalCBOInfo from "../info/ProntuarioSelectProfissionalCBOInfo";
import { InputPadraoTitle, MaisInfoMenosInfo } from '../../../../../../components';

const ProntuarioSelectProfissionalCBO = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchOptions = async () => {
    try {
      const response = await axios.get(`${baseURL}buscarCBO/search`, {
        params: {
          search: searchTerm,
        },
      });
      const fetchedOptions = response.data.map((item) => ({
        value: item.id,
        label: item.titulo,
      }));
      setOptions(fetchedOptions);
    } catch (error) {
      console.error('Error fetching data from API:', error.message);
    }
  };

  useEffect(() => {
    fetchOptions();
  });

  const handleChange = (selectedOption) => {
    onChange(selectedOption);
  };

  return (<>
<InputPadraoTitle htmlFor={'Escolher o Profissional'} title={'Escolher o Profissional'}/>
    <Select
      name="select-com-busca"
      className="text-center"
      value={value}
      onChange={handleChange}
      options={options}
      placeholder='Escolher o Profissional'
      onInputChange={(newValue) => setSearchTerm(newValue)}
    />
    <div className="py-4">
 <MaisInfoMenosInfo text={<ProntuarioSelectProfissionalCBOInfo />}></MaisInfoMenosInfo>
    </div>
  </>);
};

export default ProntuarioSelectProfissionalCBO;
