import { Index } from "./Index";
import { FooterUser, Nav, NavUserMain } from "../../../../components";
export const Preferencias = () => {
    return (<>
     <Nav/>
        <div className="d-flex mt-2" style={{overflowX:'hidden'}}>
            <NavUserMain />
            <section className="w-100">
                <Index />
                <FooterUser />
            </section>
        </div>
    </>)
}