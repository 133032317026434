//TesteAutoLogin

import React from "react";
import { ButtonAPI } from "../../../../../components";

const TesteAutoLogin = ({ setEmail, setPassword, efetuarLogin }) => {

  const handleTestButtonClick = (e) => {
    e.preventDefault();
    setEmail("teste@thotiacorp.com");
    setPassword("12345");
    efetuarLogin(e);
  };
  if (window.location.hostname === "localhost") {
    return (
      <div className="py-3">
        <ButtonAPI type={'button'} className="btn btn-warning" onClick={handleTestButtonClick}>
          <b>Preencher Auto Teste - Homolog</b>
        </ButtonAPI>
      </div>
    );
  }
  return null;
};


export default TesteAutoLogin;
