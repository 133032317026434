// imageUpload.js
export const simulateImageUpload = async (testImage,
    setLoading,
    setImage,
    setLandmarks,
    setModifiedLandmarks, 
    setFaceShape, 
    setLeftImage, 
    setRightImage, 
    setDark_pixel_coords, 
    setRugas, 
    setSugestao, 
    setPredictionsOthers, 
    setImageWidth, 
    setImageHeight) => {
    setLoading(true);
    // Simulação de um tempo de espera, como se estivesse aguardando a resposta da API
    await new Promise(resolve => setTimeout(resolve, 5000));
    if (!testImage) {
        console.error('Nenhum arquivo de imagem válido fornecido.');
        setLoading(false);
        return;
    }
    // Neste caso, você não precisa criar uma URL, pois está usando a imagem importada diretamente.
    setImage(testImage); // Salva a imagem no estado
    // Abaixo, simula a obtenção da resposta
    const img = new Image();
    img.src = testImage; // Use a imagem importada aqui
    img.onload = () => {
        setImageWidth(img.width);
        setImageHeight(img.height);
    };
    try {
        const response = await fetch('/assets/json/response.json'); // Certifique-se de que o caminho está correto
        const simulatedResponse = await response.json();
        setLoading(false);
        setLandmarks(simulatedResponse.landmarks);
        setModifiedLandmarks([...simulatedResponse.landmarks]);
        setFaceShape(simulatedResponse.face_shape);
        const leftImageUrl = `${simulatedResponse.combined_image_left}`;
        const rightImageUrl = `${simulatedResponse.combined_image_right}`;
        setLeftImage(leftImageUrl);
        setRightImage(rightImageUrl);
        setDark_pixel_coords(simulatedResponse.dark_pixel_coords);
        setRugas(Number(simulatedResponse.occupied_percentage_rugas_testa));
        setSugestao(simulatedResponse.sugestao);
        setPredictionsOthers(simulatedResponse.predictionsOthers);      
    } catch (error) {
        setLoading(false);
        console.error('Error loading simulated response:', error);
    }
};
