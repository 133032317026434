import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ReferenciaLocal, NavBreadcrumb, MaisInfoMenosInfo, TermoIAText } from '../../../../../../../components';
import '../style/Style.css';
import { baseURL } from '../../../../../../../services/api';
import SearchAIText from './SearchAIText';
import ShareAI from '../../global/ShareAI';
import MonitoringTrainIAtext from './MonitoringTrainIAText';
import CreateTrainIATextStage2 from './CreateTrainIATextStage2';
import CreateTrainIATextStage1 from './CreateTrainIATextStage1';
import TextIAInfo from '../info/TextIAInfo';
import { Link } from 'react-router-dom';
import CommentSectionIA from '../../global/CommentSectionIA';

export const TextIAMain = () => {
  const [testPrompt, setTestPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [trainingLog, setTrainingLog] = useState(false);
  const [trainingCompleted, setTrainingCompleted] = useState([]);
  const [nameIAText, setNameIAText] = useState('');
  const [trainMessage, setTrainMessage] = useState('');
  const [epoch, setEpoch] = useState('');
  const [fragmentSize, setFragmentSize] = useState('');
  const [wordCount, setWordCount] = useState('');
  const [comments, setComments] = useState([]);
  const [likedConversations, setLikedConversations] = useState([]);

  const handleGenerateClick = () => {
    setIsLoading(true);
    const existingDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
    let ia_name = '';
    let ia_id = '';
    if (existingDriveIAData) {
      ia_name = existingDriveIAData.token;
      ia_id = existingDriveIAData.id;
    }
    axios
      .post(`${baseURL}test-ia`, { prompt: testPrompt, ia_name, ia_id })
      .then((response) => {
        setIsLoading(false);
        setTestPrompt('');
      })
      .catch((error) => {
        alert(error);
        setIsLoading(false);
        setTestPrompt('');
      });
  };

  const fetchConversationFromDatabase = useCallback(() => {
    const existingDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
    let ia_id = '';
    if (existingDriveIAData) {
      ia_id = existingDriveIAData.id;
    }
    axios.get(`${baseURL}buscarTodosOsRegistrosIAQA/ia_id=${ia_id}`)
      .then((response) => {
        const newMessages = response.data.registros.map((registro) => ([
          { type: 'user', message: registro.pergunta, id: registro.id },
          { type: 'ai', message: registro.resposta, id: registro.id },
        ])).flat();
        setConversation((prevConversation) => [...prevConversation, ...newMessages]);
      })
      .catch((error) => {
        alert('Erro ao buscar conversas do banco:', error);
      });
  }, []);

  useEffect(() => {
    const fetchConversationInterval = setInterval(() => {
      fetchConversationFromDatabase();
    }, 5000);
    return () => {
      clearInterval(fetchConversationInterval);
    };
  }, [fetchConversationFromDatabase]);

  const handleTrain = async () => {
    setIsLoading(true);
    monitoringTraing();
    setTrainingLog(true);
    try {
      const storedDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
      if (storedDriveIAData) {
        const requestData = {
          driveIA: storedDriveIAData.token,
          fragment_size: 1000,
          word_count: 5000,
          epoch: 5,
        };
        const response = await axios.post(`${baseURL}treinarIA`, requestData);
        alert(response.data);
        setIsLoading(false);
        setTrainingCompleted(true);
      } else {
        alert('Dados não encontrados no localStorage.');
        setIsLoading(false);
      }
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleGenerateClick();
    }
  };

  const [progressData, setProgressData] = useState({
    status: 'Waiting...',
    epoch: 0,
    step: 0,
    progress: 0,
    loss: 0,
    sentencesProcessed: 0,
  });

  const monitoringTraing = () => {
    const eventSource = new EventSource(`${baseURL}monitor_training`);
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setProgressData({
        status: 'Training in Progress',
        epoch: data.epoch,
        step: data.step,
        progress: data.progress,
        loss: data.loss,
        sentencesProcessed: data.sentencesProcessed,
      });
      if (data.progress >= 100) {
        setProgressData((prevData) => ({
          ...prevData,
          status: 'Training Completed',
          progress: 100,
        }));
        eventSource.close();
      }
    };
    eventSource.onerror = (error) => {
      alert('Error:', error);
    };
    return () => {
      eventSource.close();
    };
  };

  const handleAddComment = (newComment) => {
    setComments((prevComments) => [...prevComments, newComment]);
  };

  const handleLikeConversation = (messageId) => {
    const existingDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
    const user_id = existingDriveIAData.id;
  
    if (!likedConversations.includes(messageId)) {
      axios
        .post(`${baseURL}postarIALikes`, { user_id, qa_id: messageId })
        .then((response) => {
          alert(`Like registrado com sucesso\nuser_id: ${user_id}\nmessageId: ${messageId}`);
          setLikedConversations((prevLikedConversations) => [
            ...prevLikedConversations,
            messageId
          ]);
        })
        .catch((error) => {
          alert(`Erro ao postar o like\nuser_id: ${user_id}\nmessageId: ${messageId}`);
        });
    } else {
      alert('Você já curtiu esta conversa.');
    }
  };
  

  return (
    <div className="App">
      <NavBreadcrumb icon={'book fa'} text={'I.A - Texto'} />
      <ReferenciaLocal text={'Interagir'} />
      <header className="App-header text-center mt-3">
        <div>
          {conversation >= 0 && <div className='py-5 mb-5 mt-5' />}
          {conversation.map((item, index) => {
            if (item.type === 'user' && conversation[index + 1] && conversation[index + 1].type === 'ai') {
              return (
                <div key={index} className="message-container bg-light text-justify mr-2 ml-2 rounded mt-3">
                  <div className='col-md-12'>
                    <small className='font-weight-bold'>Pergunta: {item.message}</small><br />
                    <small className=''> <strong className='font-weight-bold'>Resposta:</strong> {conversation[index + 1].message}</small>
                  </div>
                  <div className='col-md-12 py-2 '>
                    <hr />
                    <Link
                      className={`bg-primary rounded py-1 ${likedConversations.includes(item.id) ? 'liked' : 'success'}`}
                      onClick={() => handleLikeConversation(item.id)}
                    >
                      <i className="fa fa-thumbs-up text-white mr-2 ml-2 "> <small className='font-weight-bold ml-2 text-white'>Gostei </small></i>
                    </Link>
                  </div>
                </div>
              );
            } else if (item.type === 'ai') {
              return null;
            } else {
              return (
                <div key={index} className="message-container bg-light text-justify mr-2 ml-2 rounded">
                  <small className='font-weight-bold'>Pergunta: {item.message}</small>
                </div>
              );
            }
          })}
        </div>
        <div className="generate-container">
          <div className="input-container mt-3">
            <input
              className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
              type="text"
              placeholder="Quero saber sobre ?"
              value={testPrompt}
              onChange={(e) => setTestPrompt(e.target.value)}
              onKeyDown={handleEnterKeyPress}
              disabled={isLoading}
            />
            <button className={` generate-button`} onClick={handleGenerateClick}>
              <i className={` ${isLoading ? 'text-success' : ' text-primary'} fa fa-rocket mt-2`} style={{}}></i>
            </button>
          </div>
          <small>Atenção procure uma I.A. para utilizar o sistema ou crie uma <MaisInfoMenosInfo text={<TextIAInfo />}></MaisInfoMenosInfo> </small>
        </div>
        <ReferenciaLocal text={'Procurar e Compartilhar'} />
        <div className='d-flex col-md-12 flex-wrap justify-content-center '>
          <SearchAIText
            nameIAText={nameIAText}
            setNameIAText={setNameIAText} />
          <ShareAI nameIAText={nameIAText} setNameIAText={setNameIAText} />
          <CommentSectionIA
            comments={comments}
            setComments={setComments}
            onComment={handleAddComment}
            isLoading={isLoading}
            commentPosted={true}
          />
        </div>
        <ReferenciaLocal text={'Criar e Treinar I.A'} />
        <div className='d-flex col-md-12 flex-wrap justify-content-center '>
          <div className='col-md-12'>
            <small>Termo de responsabilidade <MaisInfoMenosInfo text={<TermoIAText />}></MaisInfoMenosInfo> </small></div>
          <CreateTrainIATextStage1
            setTrainMessage={setTrainMessage}
            trainMessage={trainMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            nameIAText={nameIAText}
            setNameIAText={setNameIAText}
            wordCount={wordCount}
            setWordCount={setWordCount}
            epoch={epoch}
            setEpoch={setEpoch}
            fragmentSize={fragmentSize}
            setFragmentSize={setFragmentSize}
          />
          <CreateTrainIATextStage2
            handleTrain={handleTrain}
            trainingCompleted={trainingCompleted}
            isLoading={isLoading}
            wordCount={wordCount}
            setWordCount={setWordCount}
            epoch={epoch}
            setEpoch={setEpoch}
            fragmentSize={fragmentSize}
            setFragmentSize={setFragmentSize}
          />
          {trainingLog && <MonitoringTrainIAtext
            monitoringTraing={monitoringTraing}
            progressData={progressData}
            setProgressData={setProgressData}
          />}
        </div>
      </header>
    </div>
  );
}

export default TextIAMain;
