
  export const IsValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

 // Função para tratar a mudança no campo de e-mail
export const handleEmailChange = (event, setEmail, setError) => {
  const newEmail = event.target.value;

  // Valida o e-mail e atualiza o estado de erro
  if (!IsValidEmail(newEmail)) {
    setError('Atenção, insira um endereço de e-mail válido');
  } else {
    setError(null);
  }

  // Atualiza o estado do e-mail
  setEmail(newEmail);
};