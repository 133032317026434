import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '../../../../../../../../components';

const ProntuarioDentistaRangeDentes = () => {
  const [rangeDentes, setRangeDentes] = useState(false);
  const [historicoRangeDentes, setHistoricoRangeDentes] = useState([]);
  const [novaResposta, setNovaResposta] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);

  const handleCheckboxChange = () => {
    setRangeDentes(!rangeDentes);
  };

  const handleAdicionarResposta = () => {
    if (novaResposta.trim() !== '') {
      if (indiceEditando === -1) {
        setHistoricoRangeDentes([...historicoRangeDentes, novaResposta]);
      } else {
        const novaLista = [...historicoRangeDentes];
        novaLista[indiceEditando] = novaResposta;
        setHistoricoRangeDentes(novaLista);
        setIndiceEditando(-1);
      }
      setNovaResposta('');
    }
  };

  const handleRemoverResposta = (index) => {
    const novaLista = [...historicoRangeDentes];
    novaLista.splice(index, 1);
    setHistoricoRangeDentes(novaLista);
  };

  const handleEditarResposta = (index) => {
    setNovaResposta(historicoRangeDentes[index]);
    setIndiceEditando(index);
  };

  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Range os dentes de dia ou de noite?</h5>
      <label>
        <input type="checkbox" defaultChecked={rangeDentes} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!rangeDentes} onChange={() => setRangeDentes(false)} /> Não
      </label>
      {rangeDentes && (
        <>
          <h5 style={{ fontWeight: "bold" }}>Histórico de Respostas:</h5>
          <div>
            <input
              type="text"
              value={novaResposta}
              onChange={(e) => setNovaResposta(e.target.value)}
              placeholder="Nova resposta"
            />
            <ButtonAdicionarCampo onClick={handleAdicionarResposta} />
          </div>
        </>
      )}

      <ul>
        {historicoRangeDentes.map((resposta, index) => (
          <li key={index}>
            {resposta}
            <ButtonEditarCampo onClick={() => handleEditarResposta(index)} />
            <ButtonRemoverCampo onClick={() => handleRemoverResposta(index)} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProntuarioDentistaRangeDentes;
