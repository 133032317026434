import { useEffect } from 'react';
const ConsoleMessage = () => {
  useEffect(() => {
    console.log('%cO sistema da THOT I.A. CORP. utiliza múltiplas linguagens de desenvolvimento computacional. O sistema é desenvolvido e aprimorado constantemente - Quer colaborar voluntariamente conosco? Preencha uma mensagem no WhatsApp com os seguintes informativos', 'background: #2C3582; color: #FFFFFF; font-size: 18px');
    console.log('%cMeu nome é: XXXX', 'color: #333;');
    console.log('%cMeu GitHub: XXXX', 'color: #333;');
    console.log('%cTenho XXX anos de experiência com desenvolvimento', 'color: #333;');
    console.log('%c📞 Entre em contato', 'background: #2C3582; color: #FFFFFF; font-size: 14px; padding: 8px 16px; border: none; border-radius: 4px; cursor: pointer;', '%https://api.whatsapp.com/send?phone=+5566996675486&text=Meu%20nome%20é:%20XXXX%20%20Meu%20GitHub:%20XXXX%20%20Tenho%20XXX%20anos%20de%20experiência%20com%20desenvolvimento');
  }, []); // Lista de dependências vazia
};
export default ConsoleMessage;