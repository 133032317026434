import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Image, Upload } from "antd";
import "./css/style.css";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const ImageDragDrop = ({ fileList, setFileList }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
//   const [fileList, setFileList] = useState([]);

  // Função para abrir a visualização da imagem no modal do Ant Design
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  // Manipulação dos arquivos após serem carregados
  const handleFileChange = ({ fileList: newFileList }) => {
    if (newFileList.length <= 4) {
      setFileList(newFileList);
    } else {
      // Se o limite for excedido, não atualiza a lista
      // Você pode mostrar uma mensagem de erro aqui, se desejar
      alert("Você pode importar no máximo 4 imagens.");
    }
  };

  // Lógica de drag-and-drop
  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("imageId", id);
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const imageId = e.dataTransfer.getData("imageId");
    const draggedImage = fileList.find((file) => file.uid === imageId);
    if (draggedImage) {
      const updatedImages = fileList.filter(
        (file) => file.uid !== draggedImage.uid
      );
      updatedImages.splice(targetIndex, 0, draggedImage);
      setFileList(updatedImages);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Botão de upload customizado
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div>
      <div className="image-container">
        <div className="main-image-container">
          {fileList.length > 0 && (
            <img
              src={
                fileList[0].url ||
                URL.createObjectURL(fileList[0].originFileObj)
              }
              alt={`Imagem Principal`}
              className="main-image"
              draggable
              onDragStart={(e) => handleDragStart(e, fileList[0].uid)}
              onDrop={(e) => handleDrop(e, 0)}
              onDragOver={handleDragOver}
            />
          )}
          <small>Arraste ou solte imagens</small>
          <div className="flex-wrap col-md-12 d-flex mt-3 captured-image-container justify-content-center align-items-center">
            {fileList.slice(1).map((file, index) => (
              <div
                key={`image_${index}`}
                style={{
                  padding: "5px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                className="captured-image-container"
              >
                <div className="captured-image-wrapper d-flex col-md-12 justify-content-center mx-auto">
                  <img
                    src={file.url || URL.createObjectURL(file.originFileObj)}
                    alt={`Imagem ${index + 2}`}
                    className="captured-image"
                    style={{
                      minHeight: "60px",
                      maxHeight: "60px",
                      minWidth: "60px",
                      maxWidth: "60px",
                    }}
                    draggable
                    onDragStart={(e) => handleDragStart(e, file.uid)}
                    onDrop={(e) => handleDrop(e, index + 1)}
                    onDragOver={handleDragOver}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Upload do Ant Design */}
      <Form.Item
        label="Importe imagens para o seu serviço"
        name="image_service"
      >
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleFileChange}
          beforeUpload={() => false} // Desativa o upload automático para manter controle
        >
          {fileList.length < 4 ? uploadButton : null}
        </Upload>

        {previewImage && (
          <Image
            wrapperStyle={{
              display: "none",
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </Form.Item>
    </div>
  );
};

export default ImageDragDrop;
