import { Main } from "./components/layout/Main";
import { FooterProfissional, Nav, NavProfissionalMain } from "../../../../components";
export const ProdutosProfissionais = () => {
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <section>
            <div className="w-100" >
                <Main />
                <FooterProfissional />
            </div>
            </section>
        </div>
    </>)
};