import React from 'react';
import ServicesCard from './ServicesCard'; // Importe seu componente individual de card aqui
export const ServicesCardList = ({ servicesData, onAddToCart }) => {
  return (
    <div className="col-md-12" style={{ padding: '0px' }}>
      <div className="d-flex flex-wrap justify-content-center">
        {servicesData.map((service) => (
          <ServicesCard
            key={service.service_id}
            product_id={service.service_id}
            products_name={service.services_name}
            image={service.services_image}
            product_code_id={service.service_id + 'services'}
            description={service.services_description}
            value={service.value}
            services_type={service.type}
            services_code_id={service.services_code_id}
            company_unit_id={service.company_unit_id}
            onAddToCart={onAddToCart}
            company_name={service.company_name}
          />
        ))}
      </div>
    </div>
  );
};
export default ServicesCardList;