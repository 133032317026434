// import MarcarConsultas from '../../Consultas/components/MarcarConsultas'
import MarcarExamesCards from './MarcarExamesCards'

export const MarcarExames = () =>{

    return (
    <>
  <MarcarExamesCards/>
    </>
    )
    }