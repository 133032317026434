import React, { useState, useEffect, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Layout } from 'antd';
import { saveAs } from 'file-saver';
import './styles/PdfViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Content, Footer } = Layout;

const PdfViewer = ({ pdfUrl, fileName}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPreviousPage = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber]);

  const goToNextPage = useCallback(() => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, numPages]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'document.pdf';
    link.click();
  };

  const handleDownloadPDF = () => {
    saveAs(pdfUrl, fileName);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        goToPreviousPage();
      } else if (event.key === 'ArrowRight') {
        goToNextPage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [goToPreviousPage, goToNextPage]);


  return (
    <Layout className="pdf-viewer-layout">
      <Content className="pdf-viewer-content">
        <div className="pdf-viewer-controls">
          <Button onClick={goToPreviousPage} disabled={pageNumber === 1}>
            Anterior
          </Button>
          <Button onClick={goToNextPage} disabled={pageNumber === numPages}>
            Próxima
          </Button>
          <Button onClick={handleDownloadPDF}>
            Download PDF
          </Button>
          <span>Página {pageNumber} de {numPages}</span>
        </div>
        <div
          className={`pdf-page-container ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleExpand}
        >
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </div>
      </Content>
      <Footer className="pdf-viewer-footer" />
    </Layout>
  );
};

export default PdfViewer;
