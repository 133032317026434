import React from "react";
import Navbar from "./SprintContent/SprintContentNavbar";
import { Header } from "../../components/Header/Header";
import { MenuItens } from "../../components/MenuItens/MenuItens";
import SprintNavLoadData from "./NavSprint/SprintNavLoadData.jsx";
import { SprintContent } from "../../components/Sprints/SprintContent/SprintContent";
import { FooterWiki } from "../Footer/Footer";

import "./Sprints.css";

export function Sprints() {
  return (
    <>
      <Header pagina="sprints" />      
        <main className="main-sprints">
          <MenuItens.Container titulo="Sprints">
            <SprintNavLoadData />
          </MenuItens.Container>
          <Navbar />

          <SprintContent />
        </main>      
      <FooterWiki />
    </>
  );
}
