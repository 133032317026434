import React from 'react';
import TeamListVoluntarioInfo from './TeamListVoluntarioInfo'
export const TeamListBolsistaInfo = () => {
    return (<>
        <div className="text-justify">
            <TeamListVoluntarioInfo />
            <h5 className="font-weight-bold mt-5">Regime de Colaboração Bolsistas e/ou Contratados</h5>
            <h5 className='mt-5'>Os 'Bolsistas e/ou Contratados' são indivíduos dedicados que desempenham um papel
                importante no desenvolvimento e na contribuição para projetos. Eles são aqueles que demonstraram interesse
                e comprometimento ao participar de iniciativas e programas de bolsas, contribuindo com seu tempo e conhecimento
                Esses bolsistas desempenham um papel significativo ao colaborar com projetos em diferentes.
                capacidades, como desenvolvimento de software, pesquisa, design, entre outras áreas. Sua participação
                é valiosa para o progresso e o sucesso das iniciativas.
                Os bolsistas muitas vezes buscam adquirir experiência prática, ampliar seu aprendizado e
                contribuir para a comunidade. Seu envolvimento demonstra uma forte vontade de crescer profissionalmente
                e fazer a diferença em projetos que compartilham seus interesses e objetivos.
                Portanto, os bolsistas desempenham um papel essencial no apoio a projetos e iniciativas,
                trazendo entusiasmo e novas perspectivas para alcançar objetivos comuns
            </h5>
        </div>
    </>);
};
export default TeamListBolsistaInfo;