// SprintNavLoadData.jsx
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { sprintMainData } from "./SprintNavData";
import "./SprintNavLoadData.css"; // Importe o arquivo CSS de estilos

const SprintNavLoadData = () => {
  const [isOpen, setIsOpen] = useState([]);
  const collapseRef = useRef([]);

  const toggleCollapse = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <>
      <div>
        {sprintMainData.map((data, index) => (
          <h5 className={data.topDist} key={data.title}>
            <Link onClick={() => toggleCollapse(index)}>
              <i className={`fa ${data.icon} text-black`} />
              <span className={`ml-1 ml-22 ${data.titleClass} title-container`}>
                {data.title}{" "}
                <i
                  className={`fas ${
                    isOpen[index]
                      ? "fa-chevron-down wiki-align-right rotate-icon"
                      : "fa-chevron-right wiki-align-right"
                  }`}
                ></i>
              </span>
            </Link>
            <ul
              className={`collapse ${isOpen[index] ? "show" : ""}`}
              ref={(el) => (collapseRef.current[index] = el)}
            >
              {data.subData.map((subData) => (
                <li className="text-black text-left mt-2 ml-2" key={subData.id}>
                  <Link to={subData.href}>
                    <i className={`fa ${subData.icon} circle-icon`} />
                    <span className={`ml-1 ml-22 ${subData.titleClass}`}>
                      {subData.title}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </h5>
        ))}
      </div>
    </>
  );
};

export default SprintNavLoadData;
