import React from "react";
import "./App.css";
import AppRoutes from "./routes/routes";
import { ConfigProvider } from "antd";
import { customTheme } from "../src/theme";
export function App() {
  return (
    <div className="App">
      <ConfigProvider theme={customTheme}>
        <AppRoutes />
      </ConfigProvider>
    </div>
  );
}
export default App;
