import { baseURL } from "../../../../../../services/api";

export const post_prontuario = async (data) => {
  try {
    const response = await fetch(`${baseURL}post_prontuario`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Erro ao enviar as informações');
    }

    const result = await response.json();
    console.log('Informações enviadas com sucesso:', result);
    // Handle success (e.g., show a confirmation message)
  } catch (error) {
    console.error('Erro:', error);
    // Handle error (e.g., show an error message)
  }
};
