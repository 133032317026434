import React, { useState } from "react";
import { ThemeContext } from "./ThemeProvider";
import { ButtonAPI, FooterProfissional, Nav, NavProfissionalMain, ReferenciaLocal, NavBreadcrumb } from "../../../../components";
import { Home } from "../../../Public/Home/Home";
import { ImageHome } from "./ImageHome";
import { ThemeContextSecondary } from "./ThemeProviderSecondary";
import ThemeContextTerciary from "./ThemeProviderTerciary";
import ThemeContextTextPrimary from "./ThemeProviderTextPrimary";
import ThemeContextTextSecondary from "./ThemeProviderTextSecondary";
import {
  Produtos,
  Servicos,
  Parcerias,
  Artigos,
  SobreNos,
  Carrinho,
  Cadastrar,
} from "../../../Public";
import { baseURL } from "../../../../services/api";

export const Ecommerce = () => {
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [terciaryColor, setTerciaryColor] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [textSecondaryColor, setTextSecondaryColor] = useState("");
  const [selectedOption, setSelectedOption] = useState("Inicio");
  const handleColorSubmit = () => {
    // Helper function to check if the response is a JSON
    const isJsonResponse = (response) => {
      const contentType = response.headers.get("content-type");
      return contentType && contentType.indexOf("application/json") !== -1;
    };

    // Helper function to handle errors in the fetch request
    const handleFetchError = (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response;
    };
    const colors = [primaryColor, secondaryColor, terciaryColor, textPrimaryColor, textSecondaryColor];

    // Sending the primaryColor to the server using a POST request
    fetch(`${baseURL}post_ecommerce`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ecommerceData: {
          options: "primaryColor",
          text: colors,
          ecommerce_id: 1,
        }
      }),
    })
      .then(handleFetchError)
      .then((response) => {
        if (isJsonResponse(response)) {
          return response.json();
        } else {
          throw new Error('Invalid response or not a JSON');
        }
      })
      .then((data) => alert(data.message))
      .catch((error) => alert('Error submitting primaryColor: ' + error.message));

    // Sending the secondaryColor to the server using a POST request
    // ... (similar logic for other colors)
  };

  // Rest of your component code here...




  const handleColorChange = (color) => {
    setPrimaryColor(color);

    const elements = document.querySelectorAll(
      '.bg-primary, [class*="-primary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("bg-primary");
      element.classList.add("-primary");
      element.style.backgroundColor = color;

    });
  };

  const handleColorChangeSecundary = (colorSecondary) => {
    setSecondaryColor(colorSecondary);

    const elements = document.querySelectorAll(
      '.CardProdutos, [class*="-secondary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("CardProdutos");
      element.classList.add("-secondary");
      element.style.backgroundColor = colorSecondary;
    });
  };

  const handleColorChangeTerciary = (colorTerciary) => {
    setTerciaryColor(colorTerciary);

    const elements = document.querySelectorAll('.App, [class*="-terciary"]');
    elements.forEach((element) => {
      element.classList.remove("App");
      element.classList.add("-terciary");
      element.style.backgroundColor = colorTerciary;
    });
  };

  const handleColorChangeTextPrimary = (colorTextPrimary) => {
    setTextPrimaryColor(colorTextPrimary);

    const elements = document.querySelectorAll(
      '.text-white, [class*="-textprimary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("text-white");
      element.classList.add("-textprimary");
      element.style.color = colorTextPrimary;
    });
  };

  const handleColorChangeTextSecondary = (colorTextSecondary) => {
    setTextSecondaryColor(colorTextSecondary);

    const elements = document.querySelectorAll(
      '.text-dark, [class*="-textsecondary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("text-dark");
      element.classList.add("-textsecondary");
      element.style.color = colorTextSecondary;
    });
  };
  return (<>
   <Nav/>
    <div className="d-flex mt-3">
      <NavProfissionalMain />
      <section>
      <div className="  ">
        <NavBreadcrumb text={"E-commerce"} icon={"money fa"} />
        <ReferenciaLocal text={"Personalização Geral"} />
        <div className="py-3 ">
          <div className="text-center">
            <div className="container-fluid">
              <div className="row d-inline-flex justify-content-center w-100">
                <div className="col-md-12">
                  <small className="text-dark">Cores de fundo</small>
                </div>
                <ThemeContext onChange={handleColorChange} />
                <ThemeContextSecondary
                  onChange={handleColorChangeSecundary}
                />
                <ThemeContextTerciary onChange={handleColorChangeTerciary} />
                <div className="col-md-12">
                  <small className="text-dark">Cores de texto</small>
                </div>
                <ThemeContextTextPrimary
                  onChange={handleColorChangeTextPrimary}
                />
                <ThemeContextTextSecondary
                  onChange={handleColorChangeTextSecondary}
                />
                <div className="col-md-12">
                  <small className="text-dark">
                    Todas as informações são trocadas parcialmente ao vivo
                    para facilitar ver a aparência antes de aplicar para o
                    cliente
                    <br />{" "}
                  </small>
                </div>
                <ButtonAPI onClick={handleColorSubmit} > Salvar</ButtonAPI>
                <ReferenciaLocal text={"Personalização Local"} />

                <select
                  id="selectAreaInteresse"
                  className="mb-3 custom-select  text-center border border-dark mx-auto font-weight-bold rounded"
                  type="select"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option className="text-center mx-auto" value="Inicio">
                    Início
                  </option>
                  <option className="text-center mx-auto" value="Servicos">
                    Serviços
                  </option>
                  <option className="text-center mx-auto" value="Produtos">
                    Produtos
                  </option>
                  <option className="text-center mx-auto" value="Artigos">
                    Artigos
                  </option>
                  <option className="text-center mx-auto" value="SobreNos">
                    Sobre Nós
                  </option>
                  <option className="text-center mx-auto" value="Parcerias">
                    Parcerias
                  </option>
                  <option className="text-center mx-auto" value="Carrinho">
                    Carrinho
                  </option>
                  <option className="text-center mx-auto" value="Cadastrar">
                    Cadastrar
                  </option>
                </select>

                <div className="col-md-12"
                  style={{
                    display: selectedOption === "Inicio" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-6 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div className="col-md-12" style={{ height: "auto", zoom: "0.3" }}>
                      <Home />
                    </div>
                  </div>
                  <ImageHome />
                </div>
                <div
                  style={{
                    display: selectedOption === "Servicos" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-12 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <Servicos />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: selectedOption === "Produtos" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-12 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <Produtos />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      selectedOption === "Parcerias" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-12 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <Parcerias />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: selectedOption === "Artigos" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-6 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <Artigos />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: selectedOption === "SobreNos" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-12 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <SobreNos />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: selectedOption === "Carrinho" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-12 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <Carrinho />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      selectedOption === "Cadastrar" ? "block" : "none",
                  }}
                >
                  <div
                    className="col-md-12 aligm-items-center justify-content-center"
                    style={{ display: "inline-block", overflow: "hidden" }}
                  >
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <Cadastrar />
                    </div>
                  </div>
                </div>
                <ReferenciaLocal text={"Compartilhar Aplicativo"} />
                <small className="text-dark">
                  Https://thotiacorp.com.br/?profissional_id=1
                  <br />
                  <i className="fa fa-share-alt fa-2x  mt-3"></i>
                </small>
              </div>
            </div>
          </div>
        </div>
        <FooterProfissional />
      </div>
      </section>
    </div>
  </>
  );
};
export default Ecommerce;
