import React from 'react';
export const TituloDoProjetoInputInfo = () => {
    return (
        <div>
            <h5 className="  mt-5">Informação para Título do Projeto</h5>
            <h5>
                Este é o campo onde você deve inserir o título do seu projeto. Certifique-se de fornecer um título claro e conciso que represente o seu projeto da melhor forma possível.

                Este campo é obrigatório, o que significa que você deve preenchê-lo para prosseguir com o formulário
            </h5>
        </div>
    );
};
export const NivelDeMaturidadeInputInfo = () => {
    return (
        <div>
            <h5 className="  mt-5">Informação para Nível de Maturidade Tecnológica</h5>
            <h5>
                Este campo permite que você selecione o nível de maturidade tecnológica do seu projeto. Escolha uma das opções que melhor descreva o estágio atual do seu projeto, seja ele uma ideia, protótipo ou MVh5(Produto Mínimo Viável).
                Certifique-se de selecionar a opção apropriada que corresponda ao estado atual do seu projeto.
            </h5>
        </div>
    );
};
export const BreveDescricaoInputInfo = () => {
    return (
        <div>
            <h5 className="  mt-5">Informação para Breve Descrição</h5>
            <h5>
                Este campo é usado para fornecer uma breve descrição do seu projeto. Você pode incluir informações importantes sobre o que o projeto faz, seus objetivos e qualquer outro detalhe relevante.
                A descrição deve ser concisa, mas informativa, e pode conter no máximo 10.000 caracteres.
            </h5>
        </div>
    );
};

export const PretensaoProjetoInputInfo = () => {
    return (
        <div>
            <h5 className="  mt-5">Informação para Pretensão com Projeto</h5>
            <h5>
                Neste campo, você deve selecionar a sua pretensão com relação aos direitos autorais do projeto. Escolha a opção que melhor corresponda à forma como você deseja lidar com os direitos autorais, seja concedendo-os gratuitamente ou vendendo-os parcial ou totalmente.
            </h5>
            <h5>
                Certifique-se de selecionar a opção que melhor se alinhe com os seus objetivos para o projeto.
            </h5>
        </div>
    );
};

export const NomeProprietarioInputInfo = () => {
    return (
        <div>
            <h5 className="  mt-5">Informação para Nome do Proprietário</h5>
            <h5>
                Este campo exige que você insira o seu nome como proprietário do projeto. Certifique-se de fornecer o seu nome corretamente para que possamos entrar em contato e entender quem é o responsável pelo projeto.
            </h5>
            <h5>
                Este campo é obrigatório, o que significa que você deve preenchê-lo para prosseguir com o formulário.
            </h5>
        </div>
    );
};
export const ContatoInputInfo = () => {
    return (
        <div>
            <h5 className=" mt-5 ">Informação para Contato</h5>
            <h5>
                Este campo é usado para fornecer informações de contato para que possamos entrar em contato com você em relação ao projeto. Você pode inserir um endereço de e-mail ou um número de telefone do WhatsApp, dependendo da sua preferência.
            </h5>
            <h5>
                Certifique-se de fornecer informações de contato corretas e atualizadas para garantir que nossa equipe possa entrar em contato com você com facilidade.
            </h5>
            <h5>
                Você pode inserir um e-mail no formato "seuemail@example.com" ou um número de telefone do WhatsAph5no formato "+551234567890".
            </h5>
            <h5>
                Este campo é obrigatório, o que significa que você deve preenchê-lo para prosseguir com o formulário.
            </h5>
        </div>
    );
};
const CadastroParceriasInputInfo = {
    TituloDoProjetoInputInfo,
    NivelDeMaturidadeInputInfo,
    BreveDescricaoInputInfo,
    PretensaoProjetoInputInfo,
    NomeProprietarioInputInfo,
    ContatoInputInfo,
};

export default CadastroParceriasInputInfo;