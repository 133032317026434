import React from 'react'
import { NavBreadcrumb, FooterProfissional, InputOnOff, NavProfissionalMain, Nav } from '../../../../components';
import ConfigHorarioAgenda from '../../Usuarios/Agenda/ConfigHorarioAgenda';
import Agenda from '../../Usuarios/Agenda/Agenda';
export const AgendaProfissional = () => {
  return (<>
    <Nav />
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      <NavProfissionalMain />
      <section className='w-100'>
        <NavBreadcrumb text={'Agenda'} icon={'calendar fa'} />
        <div className='mr-1'>
          <Agenda />
          <div className='col-md-12 mt-5 py-3 mx-auto'>
            <InputOnOff />
            <ConfigHorarioAgenda />
          </div>
        </div>
        <FooterProfissional />
      </section>
    </div>
  </>)
}
export default AgendaProfissional;