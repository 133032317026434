export const mainData = [
    {
        id: "navHome",
        title: " - Usuário",
        icon: "fa fa-user",
        href: "../../usuario",
        topDist: [],
        subData: []
    },
    {
        id: "navHomeProfissionais",
        title: " - Profissional",
        icon: "fa fa-suitcase",
        href: "../../profissionais",

        subData: []
    },
    {
        id: "navVendas",
        title: " - Vendas",
        icon: "fa fa-money",
        href: "../../profissionais/vendas",
        topDist: "mt-5",
        subData: []
    },
    {
        id: "navAgenda",
        title: " - Agenda",
        icon: "fa fa-calendar",
        href: "../../profissionais/agenda",
        topDist: [],
        subData: []
    },
    {
        id: "navControleFinanceiro",
        title: " - Gráficos",
        icon: "fa fa-line-chart",
        href: "../../profissionais/graficos",
        subData: []
    },
    {
        id: "navEcommerce",
        title: " - E-commerce",
        icon: "fa fa-money",
        href: "../../profissionais/ecommerce",

        subData: []
    }, {
        id: "navServicos",
        title: " - Serviços",
        icon: "fa fa-concierge-bell",
        topDist: "mt-5",
        subData: [
            {
                id: "navOrcamentos",
                title: "Orçamentos",
                icon: "fa-calendar text-theme",
                href: "../../profissionais/servicos/orcamento",
                count: 0
            }, {
                id: "navOrcamentoAprovado",
                title: "Aprovados",
                icon: "fa-location-arrow",
                href: "../../profissionais/servicos/aprovados",
                count: 0
            }, {
                id: "navProcedimentoFinalizado",
                title: "Finalizados",
                icon: "fa-database",
                href: "../../profissionais/servicos/finalizados",
                count: 0
            }, {
                id: "navConfiguracoesservicos",
                title: "Cadastrar / Editar",
                icon: "fa-edit",
                href: "../../profissionais/servicos/cadastrar"
            }
        ]
    },
    {
        id: "navProdutos",
        title: " - Produtos",
        icon: "fa fa-cubes",
        href: "../../profissionais/produtos",
        subData: [
            {
                id: "navProdutosCadastrar",
                title: "Cadastrar",
                icon: "fa-calendar text-theme",
                href: "../../profissionais/produtos/cadastrar",
                count: 0
            }, {
                id: "navProdutosEstoque",
                title: "Estoque",
                icon: "fa-location-arrow",
                href: "../../profissionais/produtos/estoque",
                count: 0
            }, {
                id: "navProdutosVendidos",
                title: "Vendidos",
                icon: "fa-database",
                href: "../../profissionais/produtos/vendidos",
                count: 0
            }
        ]
    },
    {
        id: "navEquipamentos",
        title: " - Equipamentos",
        icon: "fa fa-book",
        href: "../../profissionais/equipamentos",

        subData: []
    },
    {
        id: "navEmitirReceitas",
        title: " - Receitas",
        icon: "fa fa-capsules",
        topDist: "mt-5",
        subData: [
            {
                id: "navReceitasUsoContinuo",
                title: " - Uso Contínuo",
                icon: "fa-history text-theme",
                href: "../../../usuario/medicacao/marcar",
                count: 0
            }, {
                id: "receitasOcasioesEspecificas",
                title: " - Ocasiões Específicas",
                icon: "fa-wave-square",
                href: "../../../usuario/medicacao/marcados",
                count: 0
            }, {
                id: "receitasEstoque",
                title: " - Estoque",
                icon: "fa-database",
                href: "../../../usuario/medicacao/finalizados",
                count: 0
            }
        ]
    },
    {
        id: "meusAtestados",
        title: " - Atestados",
        icon: "fa-bed",
        subData: [
            {
                id: "navAtestadosNovos",
                title: "Novo",
                icon: "fa-bed text-theme",
                href: "../../profissionais/atestados/novo",
                count: 0
            },
            {
                id: "navAtestadosAtivos",
                title: "Ativos",
                icon: "fa-bed text-theme",
                href: "../../profissionais/atestados/ativo",
                count: 0
            },
            {
                id: "navAtestadosArmazenados",
                title: "Armazenados",
                icon: "fa-database",
                href: "../../profissionais/atestados/armazenado",
                count: 0
            }
        ]
    },
    {
        id: "navProntuarioUnico",
        title: " - Encaminhamentos",
        icon: "fa fa-ambulance",
        href: "../../usuario",
        subData: []
    },
    {
        id: "navProntuarioUnicoSolicitarExames",
        title: " - Exames",
        icon: "fa fa-flask",
        href: "../../usuario",
        subData: []
    },
    {
        id: "navCarrinho",
        title: " - Carrinho",
        icon: "fa fa-cart-plus",
        href: "../../usuario",
        topDist: "mt-5",
        subData: []
    },
    {
        id: "navNotasFiscais",
        title: " - Notas Fiscais",
        icon: "fa-barcode",
        href: "../../usuario",
        subData: [
            {
                id: "navNfeVigentes",
                title: "Vigentes",
                href: "../../usuario",
                icon: "fa-barcode text-theme",
                count: 0
            }, {
                id: "navNfeArmazenados",
                title: "Armazenados",
                icon: "fa-database",
                href: "../../usuario",
                count: 0
            }
        ]
    },
    {
        id: "navIa",
        title: " - I.As",
        icon: "fa fa-comments",
        href: "../../profissionais/IA",
        topDist: "mt-5",
        subData: [{
            id: "navIaDiagnostico",
            title: " - I.A. Imagem",
            icon: "fa fa-camera",
            href: "../../profissionais/IA/imagem",
            subData: []
        },
        {
            id: "navIaProspeccao",
            title: " - I.A. Prospecção",
            icon: "fa fa-rocket",
            href: "../../profissionais/prospeccaoIA",
            subData: []
        },
        {
            id: "navIaText",
            title: " - I.A. Texto",
            icon: "fa fa-book",
            href: "../../profissionais/IA/text",
            subData: []
        },]
    },
    {
        id: "navMinhasPesquisas",
        title: " - Minhas Pesquisas",
        icon: "fa fa-lightbulb-o",
        href: "../../profissionais/prospccaoIA",
        topDist: "mt-5",
        subData: []
    },
    {
        id: "navMeuCadastro",
        title: " - Meu Cadastro",
        icon: "fa-id-card",
        topDist: "mt-5",
        subData: [
            {
                id: "NavDadosPessoais",
                title: "Dados Pessoais",
                icon: "fa-user text-theme",
                href: "../../usuario"
            }, {
                id: "NavDadosProfissionais",
                title: "Dados Profissionais",
                icon: "fa-suitcase text-theme",
                href: "../../usuario"
            }, {
                id: "NavSaldoRenda",
                title: "Saldo Renda",
                icon: "fa-money text-theme",
                href: "../../usuario"
            }, {
                id: "NavExcluirCadastro",
                title: "Excluir Cadatro",
                icon: "fa-window-close text-theme",
                href: "../../usuario"
            }
        ]
    }, {
        id: "navPreferencias",
        title: " - Preferências",
        icon: "fa fa-cogs",
        href: "../../profissionais/preferencias",
        subData: []
    }, {
        id: "navEmergencia",
        title: " - Emergências",
        icon: "fa-phone",
        topDist: "mt-5",
        subData: [
            {
                id: "navSOSAmbulancia",
                title: " - Ambulância Pública (SAMU)",
                icon: "fa-phone",
                href: "tel:+55192"
            }, {
                id: "navSOSBombeiros",
                title: " - Corpo de Bombeiros",
                icon: "fa-phone",
                href: "tel:+55193"
            }, {
                id: "navSOSPM",
                title: " - Polícia Militar",
                icon: "fa-phone",
                href: "tel:+55190"
            }, {
                id: "navSOSCVV",
                title: " - CVV | Centro de Valorização da Vida",
                icon: "fa-phone",
                href: "tel:+55188"
            }, {
                id: "navSOSDH",
                title: " - Disque Direitos Humanos",
                icon: "fa-phone",
                href: "tel:+55100"
            }, {
                id: "navSOSDD",
                title: " - Disque Denúncia",
                icon: "fa-phone",
                href: "tel:+55181"
            }, {
                id: "navSOSMulher",
                title: " - Central de Atendimento para a Mulher - Ministério da Justiça",
                icon: "fa-phone",
                href: "tel:+55180"
            }, {
                id: "navSOSPersonalizado",
                title: " - Configurações personalizadas de SOS",
                icon: "fa-phone",
                href: "../../../usuario"
            }
        ]
    },
    // {
    //     id: "navWiki",
    //     title: " - Wiki",
    //     icon: "fab fa-wikipedia-w",
    //     href: "../../wiki",
    //     topDist: "mt-5",
    //     subData: []
    // },
];