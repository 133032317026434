import React from 'react';
export const ImpostoBR = ({ cart, procedimentosValor, equipamentosValor, totalValor }) => {
    return (<>
        <section >
            <div className="container mt-2 col-md-12">
                <div className="row ">
                    <div className="input-group-append h-100 w-100 col-md-6">
                        <h5 className='mt-2 text-left'>
                            <a id="BtnMostarContribuicaoMedia" className=" text-dark "
                                href="#MostarContribuicaoMedia" data-toggle="collapse" data-target="#MostarContribuicaoMedia">
                                <i className="fa fa-sign-in-alt ml-2 mr-2"></i>  Contribuição média
                            </a>
                        </h5>
                    </div>
                </div>
            </div>
            <div id='MostarContribuicaoMedia' className="collapse form-group col-md-12  mx-auto rounded align-items-center justify-content-center bg-white">
                {cart.filter(item => item.product_code_id === "Equipamentos").length === 0 ? (
                    <p></p>
                ) : (<>
                    <small className='mt-5 text-dark font-weight-bold py-3 text-center'>EQUIPAMENTOS PRÓPRIOS <br />Contribuiçao estimada sobre lucro presumido</small>
                    <table id='contribuicaoEquipamentos' className="col-md-12 w-100 table text-center mx-auto justify-content-center table-dark align-items-center">
                        <thead className="col-md-12 " >
                            <tr>
                                <th id="ContribuicaoBrasilProduto" className="text-white font-weight-bold" >Contribuiçao</th>
                                <th id="ContribuicaoBrasilProdutoDescricao" className="text-white font-weight-bold">%</th>
                                <th id="ContribuicaoBrasilProdutoValor" className="text-white font-weight-bold">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-white font-weight-bold text-center">ICMS</td>
                                <td className="text-white ">17</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.17)}</td>
                            </tr>
                            <tr>
                                <td className="text-white font-weight-bold text-center">COFINS</td>
                                <td className="text-white ">3</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.03)}</td>
                            </tr>
                            <tr>
                                <td className="text-white font-weight-bold text-center">CSLL</td>
                                <td className="text-white ">2,88</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.0288)}</td>
                            </tr>
                            <tr>
                                <td className="text-white font-weight-bold text-center"> PIS ou PASEP</td>
                                <td className="text-white ">0,65</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.0065)}</td>
                            </tr>
                            <tr>
                                <td className="text-white font-weight-bold text-center">IRPJ</td>
                                <td className="text-white "> 4,8</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.048)}</td>
                            </tr>
                            <tr>
                                <td className="text-white font-weight-bold text-center"> ISS</td>
                                <td className="text-white ">  5</td>
                                <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.05)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='text-center justify-content-center'>
                        <small className='col-md-12 py-3  text-dark font-weight-bold text-center'> Total de contribuição união <br />{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(equipamentosValor * 0.33, 33)} </small> <br /> <br />
                        <h5 className='bg-dark col-md-12 ' style={{ opacity: '0.8', borderRadius: '20px' }} >.</h5></div>
                </>
                )}
                {cart.filter(item => item.product_code_id !== "Equipamentos" && item.product_code_id !== "Medicamentos").length === 0 ? (
                    <p></p>
                ) : (
                    <>
                        <small className='text-dark font-weight-bold py-3 text-center'>PROCEDIMENTOS <br />Contribuiçao estimada sobre lucro presumido</small>
                        <table id='contribuicaoProcedimentos' className="col-md-12 w-100 py-2 table-dark text-center mx-auto justify-content-center table-dark align-items-center">
                            <thead className="col-md-12 " >
                                <tr>
                                    <th id="ContribuicaoBrasilProduto" className="text-white font-weight-bold" >Contribuiçao</th>
                                    <th id="ContribuicaoBrasilProdutoDescricao" className="text-white font-weight-bold">%</th>
                                    <th id="ContribuicaoBrasilProdutoValor" className="text-white font-weight-bold">Valor</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td className="text-white font-weight-bold text-center">COFINS</td>
                                    <td className="text-white ">3</td>
                                    <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(procedimentosValor * 0.03)}</td>
                                </tr>
                                <tr>
                                    <td className="text-white font-weight-bold text-center">CSLL</td>
                                    <td className="text-white ">2,88</td>
                                    <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(procedimentosValor * 0.0288)}</td>
                                </tr>
                                <tr>
                                    <td className="text-white font-weight-bold text-center"> PIS ou PASEP</td>
                                    <td className="text-white ">0,65</td>
                                    <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(procedimentosValor * 0.0065)}</td>
                                </tr>
                                <tr>
                                    <td className="text-white font-weight-bold text-center">IRPJ</td>
                                    <td className="text-white "> 4,8</td>
                                    <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(procedimentosValor * 0.048)}</td>
                                </tr>
                                <tr>
                                    <td className="text-white font-weight-bold text-center"> ISS</td>
                                    <td className="text-white ">  5</td>
                                    <td className="text-white font-weight-bold">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(procedimentosValor * 0.05)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='text-center justify-content-center'>
                            <small className='  text-dark font-weight-bold text-center'> Total de contribuição união <br />{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(procedimentosValor * 0.16, 33)} </small> <br /> <br />
                        </div>
                    </>)}
            </div>
        </section>
    </>);
};
export default ImpostoBR;