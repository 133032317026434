import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Badge } from 'antd';

export const Notificacoes = () => {
    const [notificacoes, setNotificacoes] = useState(0); // Inicializado com 0
    const location = useLocation();
    const isProfissionaisPage = location.pathname.startsWith("/profissionais");

    useEffect(() => {
        // Verifica se `professionalData` existe no localStorage
        const professionalData = localStorage.getItem('professionalData');
        
        if (!professionalData) {
            // Se não existe, exibe uma notificação
            setNotificacoes(1); // Pode definir 1 para mostrar a notificação
        }
    }, []);

    const post = () => {
        setNotificacoes(0); // Resetando notificações ao clicar
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <div className="d-flex align-items-center justify-content-center py-4">
            <div className="text-center">
                <i className="fa fa-power-off text-danger" onClick={logout}></i>
                <a 
                    href={isProfissionaisPage ? '../../../../profissionais/notificacoes' : '../../../../usuario/notificacoes'} 
                    className="" 
                    onClick={post}
                >
                    &emsp;&emsp;
                    <Badge count={notificacoes > 0 ? notificacoes : 0} overflowCount={99} offset={[5, -5]}>
                        <i className="fa fa-bell text-warning" aria-hidden="true"></i>
                    </Badge>
                </a>
            </div>
        </div>
    );
};
