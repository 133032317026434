
export const Map = (props) => {
    
    return (
        <>
        
                <div className={`  ${props.className}`} style={props.propStyle} >
                    <div className=" rounded border" style={{ background: "#6c757d" }}>
                        <div className="bg-info py-3 rounded" >
                            <div className={`sphereMaior mt-3 d-flex mx-auto  ${props.classNameBola}`}>
                                <img className=" d-block image-fluid mx-auto d-flex w-100 h-100" alt={props.textA}  src={props.image} style={{borderRadius:'50%'}} />
                            </div>
                            <h2 className="card-front__heading text-dark py-2"> <b>{props.textA}<br></br>{props.textB}</b></h2>
                        </div>
                        <a href={props.href} onClick={props.onClick}>
                            <div className="card-front__bt">
                                <h4 className="text-dark">
                                    <b><i className="mt-2 fa fa-hand-o-right"></i> IR
                                    </b></h4>
                            </div>
                        </a>
                    </div>
                </div>
           
        </>
    )
}