import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../../../../contexts/auth';
import { fetchImages, updateImageStatus, updateImageDate } from '../../../../../../../services';
export function CarouselFotoFace({ routeGetSendSingleImage, tradeKey, imageUpdated, onImageUpdated }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deletedImages, setDeletedImages] = useState([]);
  const { getUserId } = useContext(AuthContext);
  const id = getUserId();
  const RemoveFotoFace = (index) => {
    setDeletedImages([...deletedImages, { id: images[index].id, content: images[index].content }]);
    const remainingImages = images.filter((_, i) => i !== index);
    setImages(remainingImages);
  };
  const handleUndoDelete = () => {
    if (deletedImages.length > 0) {
      const lastDeletedImage = deletedImages.pop();
      setDeletedImages(deletedImages);
      setImages([...images, { id: lastDeletedImage.id, content: { data: new Uint8Array(lastDeletedImage.content.data) } }]);
    }
  };
  useEffect(() => {
    fetchImages(setLoading, setImages, onImageUpdated, setError, routeGetSendSingleImage);
  }, [routeGetSendSingleImage, imageUpdated, onImageUpdated]);
  if (loading) {
    return <p>Carregando carousel...</p>;
  }
  if (error) {
    return null;
  }
  return (<>
    <div className='d-flex flex-wrap  col-md-12 justify-content-center align-items-center mt-2'>
      {images.map((image, index) => {
        const blob = new Blob([new Uint8Array(image.content.data)]);
        const url = URL.createObjectURL(blob);
        const imageKey = `${tradeKey}_${index}`;
        return (
          <div style={{ position: "relative", display: "inline-block" }} key={imageKey}>
            <img
              className='ml-1 mt-1'
              src={url}
              alt={`Imagem ${index}`}
              onClick={() => updateImageDate(id, image.id, onImageUpdated)}
              style={{
                maxHeight: '80px',
                minHeight: '80px',
                maxWidth: '80px',
                minWidth: '80px',
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)",
              }}
            />
            <i
              className="fa fa-trash holder text-danger position-absolute"
              onClick={() => {
                RemoveFotoFace(index, image.id);
                updateImageStatus(id, image.id);
              }}
              style={{
                zIndex: '1',
                top: '85%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            ></i>
          </div>
        );
      })}
      {deletedImages.length > 0 && (
        <button className='btn btn-warning ml-1' onClick={handleUndoDelete}>
          <i className='fa fa-refresh'></i>
        </button>
      )}
    </div>
  </>);
}
export default CarouselFotoFace;