import React from "react";
import Select from "react-select";


const TabelaSelect = ({ value, onChange, options }) => {
  return (
    <Select name="tabela" value={value} onChange={onChange} options={options} />
  );
};

export default TabelaSelect;
