import { baseURL } from "../../../../../services/api";
import { axiosHeadersToken } from "../../../../../services/contexts";
export const fetchOrders = async (user_id) => {
  try {
    const response = await axiosHeadersToken().get(`${baseURL}get_cart_payments_res/${user_id}`);
    const data = response.data; // Acesso direto à propriedade data
    return data;
  } catch (error) {
    console.error('Erro ao buscar pedidos:', error);
    return [];
  }
};
