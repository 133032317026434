import React from 'react';

export const ProgressBar = ({ progress }) => {
  const progressBarStyle = {
    width: `${progress}%`,
    backgroundColor: '#00ff00', // Cor da barra de progresso verde
    height: '10px', // Altura da barra de progresso
  };

  return <div style={progressBarStyle}></div>;
};

export default ProgressBar;
