import React from 'react';
import { Form, Input, Button, Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Trash2Icon } from 'lucide-react';
const { Option } = Select;
export const RedesSociais = ({ fields = [], setFields }) => {
    const handleAdd = () => {
        setFields([...fields, { name: '', url: '', otherName: '' }]);
    };
    const handleRemove = (index) => {
        if (fields.length > 1) {
            const newFields = fields.filter((_, idx) => idx !== index);
            setFields(newFields);
        }
    };
    const handleChange = (index, field, value) => {
        const newFields = fields.map((item, idx) => {
            if (idx === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setFields(newFields);
    };
    return (<>
        {fields.map((field, index) => (
            <div key={index} className='justify-content-center mt-3 mb-2 py-1'>
                <Divider/>
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <span className='font-weight-bold' >{index + 1}</span> {/* Número da rede social */}
                    </div>
                    <div className='col-md-3 text-left'>
                        <span>Rede Social</span><br />
                        <Form.Item
                            name={['social_networks', index, 'name']}
                        >
                            <Select
                                value={field.name}
                                onChange={(value) => handleChange(index, 'name', value)}
                                style={{ width: "100%" }}
                            >
                                <Option value="site">Site</Option>
                                <Option value="linkedin">LinkedIn</Option>
                                <Option value="instagram">Instagram</Option>
                                <Option value="facebook">Facebook</Option>
                                <Option value="twitter">Twitter</Option>
                                <Option value="github">GitHub</Option>
                                <Option value="orcid">Orcid</Option>
                                <Option value="other">Outra</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    {field.name === 'other' && (
                        <div className='col-md-3 text-left'>
                            <span>Nome</span><br />
                            <Form.Item
                                name={['social_networks', index, 'otherName']}
                                rules={[{ required: true, message: 'Insira o nome da rede social' }]}
                            >
                                <Input
                                    value={field.otherName}
                                    onChange={(e) => handleChange(index, 'otherName', e.target.value)}
                                    placeholder="Nome da Rede Social"
                                />
                            </Form.Item>
                        </div>)}
                    <div className='col-md-3 text-left'>
                        <span>Url</span><br />
                        <Form.Item
                            name={['social_networks', index, 'url']}
                        >
                            <Input
                                value={field.url}
                                onChange={(e) => handleChange(index, 'url', e.target.value)}
                                placeholder="https://"
                            />
                        </Form.Item></div>
                    {fields.length > 1 && (
                        <div className='d-flex align-items-center justify-content-center'>
                            <Trash2Icon color='red' onClick={() => handleRemove(index)} />
                        </div>
                    )}
                </div>  </div>
        ))}
        <Form.Item>
            <Button type="dashed" onClick={handleAdd} icon={<PlusOutlined />}>
                Adicionar Rede Social
            </Button>
        </Form.Item>

    </>);
};
export default RedesSociais;