import { ButtonCadastrar, IconInfo, TitleL1 } from '../../../../../../../../components'
export const ApresentacaoProdutosPublic = () => {
  return (<>
    <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
      <p className='text-center'>
        <img
          src={require('../assets/Ecommerce.png')}
          alt="Vantagens para Profissionais, Empresas e Instituições"
          style={{ maxHeight: '280px', maxWidth: '580px', minHeight: "280px" }}
          className='col-md-12'
        />
      </p>
      <TitleL1>E-commerce Dropshipping</TitleL1>
      <h5 className='text-justify'>
        Maximize o seu desempenho com nossa plataforma de vendas de produtos online, venda os seus produtos e o da plataforma com apenas alguns cliques.
        Desfrute das seguintes vantagens ao usar nossa plataforma para fazer vendas de produtos, seja você um profissional, uma empresa ou uma instituição:
      </h5>
      <ul className='text-justify'>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success mt-5'></i>&nbsp;Gerenciamento Simplificado:&nbsp; 
        <IconInfo text={"Controle e gerencie seus produtos e serviços de forma eficiente e intuitiva"} />
                      </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Ampla Visibilidade:&nbsp; 
        <IconInfo text={"Exponha seus produtos para um público maior, aumentando as oportunidades de negócios"} />  
        </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Interação Fácil:&nbsp; 
        <IconInfo text={"Comunique-se de forma simples e direta com seus clientes e parceiros"} /> 
       </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Gestão Centralizada:&nbsp; 
        <IconInfo text={"Mantenha um controle organizado de todos os aspectos do seu negócio ou instituição"} /> 
        </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Feedback Valioso:&nbsp; 
        <IconInfo text={"Receba feedback e avaliações que podem ajudar a melhorar seus produtos e serviços"} /> 
        </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Segurança de Dados:&nbsp; 
        <IconInfo text={"Garanta a segurança das informações e dados dos seus clientes e parceiros"} />
        </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Experiência do Cliente:&nbsp; 
        <IconInfo text={"Proporcione uma experiência de compra ou serviço personalizada e adaptada às necessidades do cliente"} />
        </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Mobilidade Total:&nbsp; 
        <IconInfo text={"Acesse a plataforma de qualquer lugar, a qualquer hora, utilizando dispositivos móveis"} />
        </h5>
        <h5 className='mt-2 text-justify'><i className='fa fa-check-square text-success '></i>&nbsp;Colaboração Eficiente:&nbsp; 
        <IconInfo text={" Colabore de maneira transparente e eficaz com outros profissionais, empresas ou instituições"} />
       </h5>
      </ul>
      <h5 className='font-weight-bold text-justify mt-5'>
      <strong>Seu Centro de Produtos em Destaque</strong>  
      </h5>
      <h5 className='mt-5 text-justify'>
        Explore a facilidade de gerenciar e exibir seus produtos, seja você 
        um varejista, uma empresa ou um empreendedor. Nossa plataforma de comércio eletrônico
         - E-commerce e Dropshipping oferece recursos avançados para garantir uma experiência perfeita de exibição de produtos
     
      </h5>
      <br /> <br /> <br /> <br /> <br />
      <ButtonCadastrar href={"/profissionais/produtos"} />
    </section>
  </>);
};

export default ApresentacaoProdutosPublic;
