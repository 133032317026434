

export function drawBlueSquareWithArrowDown(doc, x, y, size) {
    doc.setFillColor(0, 0, 255);
    doc.rect(x, y, size, size, 'F');
    const centerX = x + size / 2;
    const centerY = y + size / 2;
    const arrowSize = size / 3;
    const legHeight = arrowSize / 4;
    const legWidth = arrowSize / 4;
    doc.setFillColor(255, 255, 255);
    doc.triangle(
        centerX - arrowSize / 2,
        centerY - arrowSize / 4,
        centerX + arrowSize / 2,
        centerY - arrowSize / 4,
        centerX,
        centerY + arrowSize / 2,
        'F'
    );
    const legX = centerX - legWidth / 2;
    const legY = centerY - arrowSize / 4 - legHeight;
    doc.rect(legX, legY, legWidth, legHeight, 'F');
}
