import React from 'react'

export function ContentList({title, children}) {
  return (
    <li className="container-titulo-list">
      <h2 className='titulo-list'>{title}</h2>

      {children}
    </li>
  )
}
