import React, { useState } from "react";
import { ReferenciaLocal, InputPadrao } from "../../../../../../../components";
const EutanasiaCalculator = () => {
  const [pesoAnimal, setPesoAnimal] = useState("");
  const [doseKetamina, setDoseKetamina] = useState(0);
  const [doseXylazina, setDoseXylazina] = useState(0);
  const handleChangePeso = (event) => {
    setPesoAnimal(event.target.value);
    calcularDosagens(event.target.value);
  };
  const calcularDosagens = (peso) => {
    const parsedPeso = parseFloat(peso);
    const dosagemKetamina = 0.3;
    const dosagemXylazina = 0.15;
    const doseKetaminaCalculada = (parsedPeso * dosagemKetamina) / 100;
    const doseXylazinaCalculada = (parsedPeso * dosagemXylazina) / 100;
    setDoseKetamina(doseKetaminaCalculada.toFixed(2));
    setDoseXylazina(doseXylazinaCalculada.toFixed(2));
  };
  return (
    <div>
      <ReferenciaLocal text='Calculo Eutanásia'/>
      <InputPadrao
      title='Peso do Animal em Gramas'
        type="number"
        value={pesoAnimal}
        onChange={handleChangePeso}
        placeholder="Digite o peso do animal em gramas"
      />
      <p className="font-weight-bold">Dosagem de Ketamina: {doseKetamina} ml</p>
      <p className="font-weight-bold">Dosagem de Xylazina: {doseXylazina} ml</p>
    </div>
  );
};

export default EutanasiaCalculator;
