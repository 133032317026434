import React from 'react'

export function HeadTable({children}) {
  return (
    <thead className='thead-table'>
      <tr className='tr-table'>
       {children}
      </tr>
    </thead>
  )
}
