import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'antd';
import { baseURL } from '../../../../../../../../../services/api';
import { ButtonHelp, InputPadrao, LoadingModal, MaisInfoMenosInfo, TitleL1, TitleL3 } from '../../../../../../../../../components';
import AnaliseFacialInfo from './partials/info/AnaliseFacialInfo';
import { PDFGenerator } from './partials/layout/PDFDownload';
import { Image } from 'antd';
import FacePhotoCollector from './partials/layout/FacePhotoCollector';
import { voltarPassoTutorial, proximoPassoTutorial, textoPassosTutorial, simulateImageUpload } from './partials/tutorial';
import imgFrontal from './partials/assets/test.jpg';
import imgFrontalAR from './partials/assets/frontalAR.jpg';
import TablePontosFrontais from './partials/layout/TablePontosFrontais';
export const AnaliseFacial = () => {
  const [image, setImage] = useState(null);
  const [photos, setPhotos] = useState({});
  const [thumbnail, setThumbnail] = useState({});
  const [landmarks, setLandmarks] = useState([]);
  const [dark_pixel_coords, setDark_pixel_coords] = useState([]);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [pontoDestacado, setPontoDestacado] = useState(null);
  const [modifiedLandmarks, setModifiedLandmarks] = useState([]);
  const [rugas, setRugas] = useState('')
  const [rightImage, setRightImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);
  const [faceShape, setFaceShape] = useState(null);
  const [sugestao, setSugestao] = useState([])
  const [predictionsOthers, setPredictionsOthers] = useState([])
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    nomeUsuario: '',
    idade: '',
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'idade') {
      const idade = parseInt(value, 10);
      if (!isNaN(idade)) {
        setUserData({
          ...userData,
          [name]: idade.toString(),
        });
      } else {
        console.error('Idade inválida. Certifique-se de inserir um número válido.');
      }
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };
  const handleImageUpload = async (imageFile) => {
    const formData = new FormData();
    formData.append('image', imageFile);
    formData.append('type', 'FaceFrontal');
    setImage(imageFile);
    setLoading(true);
    try {
      const response = await axios.post(`${baseURL}analiseFacial`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setLandmarks(response.data.analysisResult);
      setModifiedLandmarks([...response.data.analysisResult]);
      // Removed setting unused states
      // setInitialCoordinates([...response.data.analysisResult]);
      // setInsertResult(response.data.insertResult);
      setFaceShape(response.data.face_shape);
      const leftImageUrl = `${baseURL}imagem?diretorio=../pages/Private/Profissional/IA/Database/&image=${response.data.combined_image_left}`;
      const rightImageUrl = `${baseURL}imagem?diretorio=../pages/Private/Profissional/IA/Database/&image=${response.data.combined_image_right}`;
      setDark_pixel_coords(response.data.dark_pixel_coords);
      //alert(response.data.dark_pixel_coords)
      setRugas(Number(response.data.occupied_percentage_rugas_testa));
      setSugestao(response.data.sugestao)
      setLeftImage(leftImageUrl);
      setRightImage(rightImageUrl);
      setPredictionsOthers(response.data.predictionsOthers)
    } catch (error) {
      setLoading(false);
      console.error('Error detecting facial landmarks:', error);
    }
  };
  const handleImageLoad = (e) => {
    setImageWidth(e.target.width);
    setImageHeight(e.target.height);
    createNewLandmark();
  };
  const handlePointMouseDown = (e, index) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    const imageRect = e.target.getBoundingClientRect();
    const offsetX = clientX - imageRect.left;
    const offsetY = clientY - imageRect.top;
    const initialX = modifiedLandmarks[index].x * imageWidth;
    const initialY = modifiedLandmarks[index].y * imageHeight;
    const deltaX = offsetX - initialX;
    const deltaY = offsetY - initialY;
    const updatePointCoordinates = (x, y) => {
      const newModifiedLandmarks = [...modifiedLandmarks];
      if (!newModifiedLandmarks[index]) {
        newModifiedLandmarks[index] = { x: 0, y: 0 };
      }
      newModifiedLandmarks[index].x = (x - deltaX) / imageWidth;
      newModifiedLandmarks[index].y = (y - deltaY) / imageHeight;
      setModifiedLandmarks(newModifiedLandmarks);
    };
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      const offsetX = clientX - imageRect.left;
      const offsetY = clientY - imageRect.top;
      updatePointCoordinates(offsetX, offsetY);
    };
    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };
  const createNewLandmark = () => {
    if (modifiedLandmarks.length < 469) {
      const x_11 = modifiedLandmarks[10]?.x * imageWidth;
      const y_11 = modifiedLandmarks[10]?.y * imageHeight;
      const y_10 = modifiedLandmarks[9]?.y * imageHeight;
      if (x_11 !== undefined && y_11 !== undefined && y_10 !== undefined) {
        const x_new = x_11; // O mesmo valor de X do ponto 11
        const y_new = y_11 + (y_11 - y_10); // Y do ponto 11 + a diferença entre Y do ponto 11 e do ponto 10
        const newLandmark = { x: x_new / imageWidth, y: y_new / imageHeight, z: -0.01 };
        const newModifiedLandmarks = [...modifiedLandmarks];
        newModifiedLandmarks[469] = newLandmark;
        setModifiedLandmarks(newModifiedLandmarks);
      }
    }
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && pontoDestacado !== null) {
        setPontoDestacado(null);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [pontoDestacado]);
  const [mostrarInformacoes, setMostrarInformacoes] = useState(false);
  const [mostrarInformacoesValidador, setMostrarInformacoesValidador] = useState(false);
  const handleMostrarInformacoes = () => {
    setMostrarInformacoes(!mostrarInformacoes);
    setPontoDestacado(null)
  };

  const handleMostrarInformacoesValidador = () => {
    setMostrarInformacoesValidador(!mostrarInformacoesValidador);
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [tutorialStep, setTutorialStep] = useState(0); // Adicionando estado para o passo do tutorial
  const [piscaMaisInfo, setPiscaMaisInfo] = useState(false);
  // Funções específicas para cada passo
  const stepActions = [
    function handleStep1() {
      // console.log("Executando lógica do Passo 1");
      setPiscaMaisInfo(true)
      setTimeout(() => {
        setPiscaMaisInfo(false);
      }, 8000);
    },
    async function handleStep2() {
      //console.log("Executando lógica do Passo 2");
      const type = "FaceFrontal";  // Definindo o tipo do cartão que será atualizado
      destacarCard(type)
      setThumbnail((prev) => ({ ...prev, [type]: imgFrontal }));  // Define o thumbnail com a URL da imagem carregada
      setPhotos((prev) => ({ ...prev, [type]: imgFrontal }));  // Armazena o arquivo simulado no estado
      // Certifique-se de que imgFrontal está sendo importado corretamente
      const element = document.getElementById("card_id_FaceFrontal");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Faz o scroll ser suave
          block: "end",     // Alinha o topo do elemento com o topo da área de visualização
        });
      }
      try {
        await simulateImageUpload(
          imgFrontal,
          setLoading,
          setImage,
          setLandmarks,
          setModifiedLandmarks,
          setFaceShape,
          setLeftImage,
          setRightImage,
          setDark_pixel_coords,
          setRugas,
          setSugestao,
          setPredictionsOthers,
          setImageWidth,
          setImageHeight,
          createNewLandmark
        );
        await new Promise(resolve => setTimeout(resolve, 1000))
        setUserData({
          nomeUsuario: 'João Silva Teste',
          idade: '40',
        });
      } catch (error) {
        console.error("Erro ao simular upload da imagem:", error);
      }
      destacarCard()
    },
    async function handleStep3() {
      console.log("Executando lógica do Passo 3");
      const element = document.getElementById("downloadPDFAnaliseFacial");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Faz o scroll ser suave
          block: "start",     // Alinha o topo do elemento com o topo da área de visualização
        });
      }
      await new Promise(resolve => setTimeout(resolve, 8000))
      handleMostrarInformacoesValidador()

    },
    function handleStep4() {
      console.log("Executando lógica do Passo 4");
      const type = "FaceFrontalAR";  // Definindo o tipo do cartão que será atualizado
      destacarCard(type);  // Destaque o cartão "FaceFrontalAR"
      const element = document.getElementById("card_id_FaceFrontalAR");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Faz o scroll ser suave
          block: "end",     // Alinha o topo do elemento com o topo da área de visualização
        });
      }
      // Atualizando o thumbnail e a imagem no estado
      setThumbnail((prev) => ({ ...prev, [type]: imgFrontalAR }));  // Define o thumbnail com a URL da imagem carregada
      setPhotos((prev) => ({ ...prev, [type]: imgFrontalAR }));  // Armazena o arquivo simulado no estado
    },
    function handleStep5() {
      console.log("Executando lógica do Passo 5");
      const element = document.getElementById("card_id_FaceFrontalAR");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Faz o scroll ser suave
          block: "end",     // Alinha o topo do elemento com o topo da área de visualização
        });
      }
    }
  ];
  const cancelarTutorial = () => {
    setModalVisible(false);
    setCurrentAction(null);
    setTutorialStep(0);
    setLoading(false); // Exemplo: inicializando como 'false'
    setImage(null); // Exemplo: imagem inicial nula
    setPhotos({});
    setThumbnail({});
    setLandmarks([]); // Exemplo: array vazio
    setModifiedLandmarks([]); // Exemplo: array vazio para landmarks modificados
    setFaceShape(null); // Exemplo: null para forma facial
    setLeftImage(null); // Exemplo: imagem lateral esquerda inicial
    setRightImage(null); // Exemplo: imagem lateral direita inicial
    setDark_pixel_coords([]); // Exemplo: array vazio para coordenadas de pixels escuros
    setRugas([]); // Exemplo: array vazio para rugas
    setSugestao([]); // Exemplo: sugestão inicial como null
    setPredictionsOthers([]); // Exemplo: array vazio para previsões adicionais
    setImageWidth(0); // Exemplo: largura da imagem inicial como 0
    setImageHeight(0); // Exemplo: altura da imagem inicial como 0

    setUserData({
      nomeUsuario: '',
      idade: '',
    });
  };

  const handleTutorial = () => {
    setModalVisible(false);
    setCurrentAction('tutorial');
    stepActions[0](); // Começa o tutorial no Passo 1
  };
  const showAlert = () => {
    setModalVisible(true);
  };
  const handleWhatsApp = () => {
    window.open("https://wa.me/5566996675486", "_blank"); // Substitua pelo número do WhatsApp da equipe técnica
  };
  const [highlightStates, setHighlightStates] = useState({});
  const destacarCard = (type) => {
    setHighlightStates((prevStates) => ({
      ...prevStates,
      [type]: true, // Destaca o cartão
    }));
    // Remove o destaque após 5 segundos (ou o tempo que preferir)
    setTimeout(() => {
      setHighlightStates((prevStates) => ({
        ...prevStates,
        [type]: false, // Remove o destaque
      }));
    }, 10000); // 5000 milissegundos = 5 segundos
  };
  return (<>
    <div className='text-center'>
      <TitleL1>Analise Facial</TitleL1>
    </div>
    <MaisInfoMenosInfo text={<AnaliseFacialInfo />}></MaisInfoMenosInfo>
    <div className='d-flex justify-content-center align-items-center col-md-12'>
      <div style={{ position: 'relative' }}>
        {image && (
          <img
            className="d-block w-100 mx-auto "
            src={typeof image === 'string' ? image : URL.createObjectURL(image)}
            alt="Uploaded"
            width="auto"
            height="auto"
            onLoad={handleImageLoad}
          />
        )}
        {modifiedLandmarks.map((landmark, index) => (
          (pontoDestacado === null || pontoDestacado === index) && (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: landmark && landmark.x !== undefined ? landmark.x * imageWidth : 0,
                top: landmark && landmark.y !== undefined ? landmark.y * imageHeight : 0,
                width: '5px',
                height: '5px',
                backgroundColor: 'red',
                cursor: 'pointer',
              }}
              onMouseDown={(e) => handlePointMouseDown(e, index)}
            />
          )
        ))}
      </div>
    </div>
    {image && <><button
      className={ mostrarInformacoes ? "btn btn-success  w-100 mt-3" : 'btn btn-warning  w-100  mt-3'}
      onClick={handleMostrarInformacoes}    >
      {mostrarInformacoes ? 'Ocultar Informações de Pontos Frontais' : 'Mostrar Alterar Informações de Pontos Frontais ⚙️'}
    </button> &nbsp;
      {mostrarInformacoes && (
        <TablePontosFrontais landmarks={landmarks}
          setLandmarks={setLandmarks}
          pontoDestacado={pontoDestacado}
          setPontoDestacado={setPontoDestacado} />
      )}
    </>}
    <ButtonHelp onClick={showAlert} />
    <Modal
      title="Ajuda"
      visible={modalVisible}
      onCancel={cancelarTutorial}
      footer={null} // Remove o rodapé padrão
    >
      <p>Escolha uma opção:</p>
      <Button type="primary" onClick={handleTutorial} style={{ marginRight: '10px' }}>
        Tutorial Guiado
      </Button>
      <Button type="default" onClick={handleWhatsApp}>
        Converse com a Equipe Técnica
      </Button>
    </Modal>
    {/* Modal do tutorial (só aparece se currentAction for 'tutorial') */}
    {currentAction === 'tutorial' && (
      <Modal
        title="Tutorial Guiado - Análise Facial"
        visible={currentAction === 'tutorial'}
        onCancel={cancelarTutorial}
        footer={[
          <Button key="previous" type="default" onClick={() => voltarPassoTutorial(tutorialStep, setTutorialStep, stepActions)} // Passando como função
            disabled={tutorialStep === 0}>
            Passo Anterior
          </Button>,
          tutorialStep < textoPassosTutorial.length - 1 ? (
            <Button key="next" type="primary" onClick={() => proximoPassoTutorial(tutorialStep, setTutorialStep, stepActions, setCurrentAction)} // Passando como função
            > Próximo Passo
            </Button>
          ) : (
            <Button key="finish" type="default" onClick={() => {
              setTutorialStep(0);         // Reseta para o primeiro passo
              setCurrentAction(null);      // Define como finalizado
            }}>
              Finalizar Tutorial
            </Button>
          )
        ]}>
        {textoPassosTutorial[tutorialStep].split('\n').map((line, index) => (
          <p className='text-justify' key={index}>
            {line}
          </p>
        ))}
      </Modal>)}
    {faceShape &&
      <div className='text-center mt-2 bg-success py-3  bg-secondary trl-container rounded'>
        <TitleL3 className="text-white">Insights</TitleL3>
        <div className='d-flex '>
          {leftImage && (
            <div className='col-md-4'>
              <Image src={leftImage} alt="Left Half" width={80} height={80} preview={{
                src: leftImage, // URL da imagem para pré-visualização
              }} />
              <span>Esquerda (Espelhada)</span>
            </div>
          )}
          {rightImage && (
            <div className='col-md-4'>
              <Image src={rightImage} alt="Right Half" width={80} height={80} preview={{
                src: rightImage, // URL da imagem para pré-visualização
              }} />
              <span>Direita (Espelhada)</span>
            </div>
          )}
          {faceShape && (
            <div className='col-md-4'>
              <span className='text-center'>Formato da face: {faceShape}</span>
            </div>
          )}
        </div>
      </div>}
    {/* Aqui você pode renderizar o input separado */}
    {/* <div className='text-center mt-3 col-md-12'>
        <input className='btn btn-primary form-control' type="file" accept="image/*" onChange={handleImageUpload} />
      </div> */}
    <FacePhotoCollector
      setImage={setImage}
      handleImageUpload={handleImageUpload}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
      piscaMaisInfo={piscaMaisInfo}
      highlightStates={highlightStates}
      photos={photos}
      setPhotos={setPhotos}
    />
    
    {image && (<div className='text-center mt-2'>
      <button
        className={mostrarInformacoesValidador ? "btn btn-success  w-100 mt-3" : 'btn btn-success  w-100 mt-3'}
        onClick={handleMostrarInformacoesValidador}
      >
        {mostrarInformacoesValidador ? 'Ocultar Informações de Validação PDF' : 'Inserir Informações do Paciente - Validação PDF ✅'}
      </button>&nbsp;
      {mostrarInformacoesValidador && (<>
        <div className='col-md-12 text-center'>
          <div className="form-group col-md-5 ">
            <Form.Item
              name="nomeUsuario"
              rules={[
                { required: true, message: 'Por favor, insira um nome!' },
                { type: 'text', message: 'O nome informado não é válido!' }
              ]}>
              <InputPadrao
                type='text'
                title={'Nome Usuário'}
                value={userData.nomeUsuario}
                onChange={handleChange}
                className="configuracao-main-content-input  mb-3 text-center"
                placeholder='Digite o nome do usuário:'
              />
            </Form.Item>
          </div>
          <div className="form-group col-md-5 ">
            <Form.Item
              name="idade"
              rules={[
                { required: true, message: 'Por favor, insira uma idade!' },
                { type: 'number', message: 'A idade informada não é válida!' }
              ]}>
              <InputPadrao
                type='number'
                title={'Idade'}
                value={userData.idade}
                onChange={handleChange}
                min={0}
                className="configuracao-main-content-input  mb-5 text-center"
                placeholder='Digite a idade do usuário'
              />
            </Form.Item>
          </div>
        </div>
      </>)}
    </div>)}
    <PDFGenerator
      modifiedLandmarks={modifiedLandmarks}
      pontoDestacado={pontoDestacado}
      image={image}
      rightImage={rightImage}
      leftImage={leftImage}
      landmarks={landmarks}
      user={userData.nomeUsuario}
      idade={userData.idade.toString()}
      dark_pixel_coords={dark_pixel_coords}
      occupied_percentage_rugas_testa={rugas}
      sugestao={sugestao}
      faceShape={faceShape}
      predictionsOthers={predictionsOthers}
    />
    <LoadingModal
      visible={loading}
      message="Carregando dados..."
      onClose={() => setLoading(false)} // Fechar manualmente o modal
    />
  </>);
};
export default AnaliseFacial;