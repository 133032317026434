import React, { useEffect, useState } from 'react';
import './styles/ButtonScrollToTop.css'
export const ButtonScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  return (<>
    <p className='text-right col-md-12'>
      <button
        className={`scroll-to-top-button bg-primary no-drag  font-weight-bold ${isVisible ? 'visible' : ''}`}
        onClick={scrollToTop}
      >
        <span className='font-weight-bold'><strong>&uarr;</strong></span>
      </button><br/>
    <small className=' font-weight-bold text-dark'>( TOPO )</small>
    </p>

  </>);
};
export default ButtonScrollToTop;