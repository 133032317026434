import React from 'react';
import { InputPadrao } from '..';
export const InputDataNascimento = ({  dataNascimento, setDataNascimento }) => {
  return (
    <div className="form-group mx-auto justify-content-center">
      <InputPadrao
        title={'Data nascimento'}
        type="date"
        value={dataNascimento}
        onChange={(e) => setDataNascimento(e.target.value)}
        name="dataNascimento"
        placeholder="Data nascimento"
        max="2018-12-31"
        id="dataNascimento" />
    </div>
  );
};

export default InputDataNascimento;
