import { useLocation } from 'react-router-dom';
import { MasterRenderArtigos } from "./MasterRenderArtigos"
import { Nav, Footer } from '../../../../../../components/index'
export const RenderArtigos = () => {
    const location = useLocation();
    return (<>
        <Nav location={location} />
        <MasterRenderArtigos />
        <Footer />
    </>)
}