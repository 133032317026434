import React, { useState } from 'react';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo } from '../../../../../../../../components';

const ProntuarioDentistaDoresEstalos = () => {
  const [doresEstalos, setDoresEstalos] = useState(false);
  const [historicoDoresEstalos, setHistoricoDoresEstalos] = useState([]);
  const [novaResposta, setNovaResposta] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);

  const handleCheckboxChange = () => {
    setDoresEstalos(!doresEstalos);
  };

  const handleAdicionarResposta = () => {
    if (novaResposta.trim() !== '') {
      if (indiceEditando === -1) {
        setHistoricoDoresEstalos([...historicoDoresEstalos, novaResposta]);
      } else {
        const novaLista = [...historicoDoresEstalos];
        novaLista[indiceEditando] = novaResposta;
        setHistoricoDoresEstalos(novaLista);
        setIndiceEditando(-1);
      }
      setNovaResposta('');
    }
  };

  const handleRemoverResposta = (index) => {
    const novaLista = [...historicoDoresEstalos];
    novaLista.splice(index, 1);
    setHistoricoDoresEstalos(novaLista);
  };

  const handleEditarResposta = (index) => {
    setNovaResposta(historicoDoresEstalos[index]);
    setIndiceEditando(index);
  };

  return (
    <>
      <h5 style={{ fontWeight: "bold" }}>Sente dores ou estalos no maxilar ou no ouvido?</h5>
      <label>
        <input type="checkbox" defaultChecked={doresEstalos} onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" defaultChecked={!doresEstalos} onChange={() => setDoresEstalos(false)} /> Não
      </label>
      {doresEstalos && (
        <>
          <h5 style={{ fontWeight: "bold" }}>Histórico de Respostas:</h5>
          <div>
            <input
              type="text"
              value={novaResposta}
              onChange={(e) => setNovaResposta(e.target.value)}
              placeholder="Nova resposta"
            />
            <ButtonAdicionarCampo onClick={handleAdicionarResposta} />
          </div>
        </>
      )}

      <ul>
        {historicoDoresEstalos.map((resposta, index) => (
          <li key={index}>
            {resposta}
            <ButtonEditarCampo onClick={() => handleEditarResposta(index)} />
            <ButtonRemoverCampo onClick={() => handleRemoverResposta(index)} />
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProntuarioDentistaDoresEstalos;
