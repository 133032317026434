import React from 'react';
import { MaisInfoMenosInfo, TitleL1, TitleL2 } from '../../../../../../../components';
import PlanosPrecosServicosInfo from '../info/PlanosPrecosServicosInfo';
import ButtonCadastrar from '../../../../../../../components/button/ButtonCadastrar'
import RepasseAPIPagamento from './RepasseAPIPagamento';
export const PlanosPrecosServicos = () => {
    return (<>
        <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
            <p className='text-center'>
                <img
                    src={require('../assets/PlanosPrecos.png')}
                    alt="Liberdade Financeira"
                    style={{ maxHeight: '280px', maxWidth: '480px' }}
                    className='col-md-12'
                />
            </p>
            <TitleL1>Planos e Preços</TitleL1>
            <h5 className=' text-justify'>
                O máximo de automação sempre para te eficiêncimente no centro de todos os seus negócios, para isso criamos
                planos autoescaláveis dinâmicamente na modalidade <strong> [ só pague pelo que usar ]</strong>.  O justo é justo e é open source "gratis" até o momento da
                movimentação real. Isso quer dizer que você pode criar sua Rede Social Profissional "seu sistema" gratuitamente e  quando
                o sistema indentificar vendas ou transações deduzimos automaticamente o valor do plano da transação se essa for realizada
                com nosso sistema de pagamentos se externa acumulamos para deduzir em outra transação conosco. Em casos de só haver transação externas
                geração de pagamento mês das transações registradas na plataforma
            </h5>
            <div className="row d-flex flex-wrap justify-content-center trl-container text-white mt-4 py-2" style={{ padding: '0px' }}>
                <div className="col-md-4 mt-3 " style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <div className='border rounded'>
                        <TitleL2 style={{ color: 'white' }}>Profissional Único</TitleL2>
                        <div className='text-justify mr-2 ml-2'>
                            <ul className='font-weight-bold'>Benefícios</ul>
                            <ul>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;Usuários Ilimitados</li>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;E-commerce como sub-domínio</li>
                                <li className='font-weight-bold'><strong><i className=' fa fa-check-square text-success'></i>&nbsp;Comissão de 30% em vendas de exames digitais da plataforma</strong></li>
                                <br/>
                            </ul>

                            <ul>
                                <ul className='font-weight-bold mt-3'>Valor</ul>
                                <li className='font-weight-bold'>💳 Taxa de mov. bruta: 1.5%</li>
                                <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li><br/>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mt-3  " style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <div className='border rounded'>
                        <TitleL2 style={{ color: 'white' }} >Até 5 profissionais</TitleL2>
                        <div className='text-justify mr-2 ml-2'>
                            <ul className='font-weight-bold'>Benefícios</ul>
                            <ul>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;Usuários Ilimitados</li>
                                <li><i className=' fa fa-check-square text-success'></i> E-commerce como sub-domínio</li>
                                <li className='font-weight-bold'><strong><i className=' fa fa-check-square text-success'></i> Comissão de 50% em vendas de exames digitais da plataforma</strong></li>
                                <br/>
                            </ul>
                            <ul>
                                <ul className='font-weight-bold mt-3'>Valor</ul>
                                <li className='font-weight-bold '>💳 Taxa de mov. bruta: 2.5%</li>
                                <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li>
                                <br/>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mt-3 " style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <div className='border rounded'>
                        <TitleL2 style={{ color: 'white' }}>5+ Profissionais</TitleL2>
                        <div className='text-justify mr-2 ml-2'>
                            <ul className='font-weight-bold'>Benefícios</ul>
                            <ul>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;Usuários Ilimitados</li>
                                <li><i className=' fa fa-check-square text-success'></i>&nbsp;E-commerce com sub ou domínio</li>
                                <li className='font-weight-bold'><strong><i className=' fa fa-check-square text-success'></i>&nbsp;Comissão de 70% em vendas de exames digitais da plataforma</strong></li>
                                <li className='font-weight-bold'><i className=' fa fa-check-square text-success'></i>&nbsp; Adição de alunos</li>
                            </ul>
                            <ul>
                                <ul className='font-weight-bold mt-3'>Valor</ul>
                                <li className='font-weight-bold '>💳 Taxa de mov. bruta: 5%</li>
                                <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li>
                                <li className='font-weight-bold '>💳 Hospedagem, domínio e similares</li></ul>
                        </div>
                    </div>
                </div>
            </div>
            <PlanosPrecosServicosInfo />
            <div className='py-4 text-center'>
                <MaisInfoMenosInfo text={<>  <RepasseAPIPagamento /> </>} />
            </div>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <ButtonCadastrar />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </section>
    </>);
};
export default PlanosPrecosServicos;