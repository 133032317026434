import React, { useState } from 'react';

import { InputPadrao } from '../../../../components';

export const ThemeContextTerciary = ({ onChange }) => {
  const [colorTerciary, setColorTerciary] = useState('');

  const handleColorChangeTerciary= (event) => {
    const newColorTerciary = event.target.value;
    setColorTerciary(newColorTerciary);
    onChange(newColorTerciary);
  };

  return (
    <InputPadrao title={'Cor Fundo'}
      type="color"
      value={colorTerciary}
      onChange={handleColorChangeTerciary}
    />
  );
};

export default ThemeContextTerciary;
