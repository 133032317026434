import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, ButtonEditarCampo, ButtonRemoverCampo, MaisInfoMenosInfo } from '../../../../../../components';
import ProntuarioFaltaDeArInfo from '../info/ProntuarioFaltaDeArInfo';

export const ProntuarioFaltaDeAr = () => {
  const [senteFaltaDeAr, setSenteFaltaDeAr] = useState(false);
  const [situacaoFaltaDeAr, setSituacaoFaltaDeAr] = useState('');
  const [situacoesFaltaDeAr, setSituacoesFaltaDeAr] = useState([]);
  const [indiceEditando, setIndiceEditando] = useState(-1);

  const handleCheckboxChange = (e) => {
    setSenteFaltaDeAr(e.target.checked);
  };

  const handleSituacaoFaltaDeArChange = (e) => {
    setSituacaoFaltaDeAr(e.target.value);
  };

  const adicionarSituacao = () => {
    if (situacaoFaltaDeAr.trim() !== '') {
      if (indiceEditando === -1) {
        setSituacoesFaltaDeAr([...situacoesFaltaDeAr, situacaoFaltaDeAr]);
      } else {
        const novasSituacoes = [...situacoesFaltaDeAr];
        novasSituacoes[indiceEditando] = situacaoFaltaDeAr;
        setSituacoesFaltaDeAr(novasSituacoes);
        setIndiceEditando(-1);
      }
      setSituacaoFaltaDeAr('');
    }
  };

  const removerSituacao = (index) => {
    const novasSituacoes = [...situacoesFaltaDeAr];
    novasSituacoes.splice(index, 1);
    setSituacoesFaltaDeAr(novasSituacoes);
  };

  const handleEditarSituacao = (index) => {
    const situacaoEditando = situacoesFaltaDeAr[index];
    setSituacaoFaltaDeAr(situacaoEditando);
    setIndiceEditando(index);
  };


  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        Sente falta de ar com frequência?      </h5>
        <label>
          <input type="checkbox" onChange={handleCheckboxChange} /> Sim
        </label>
        <br/>
        <label>
          <input type="checkbox" onChange={() => setSenteFaltaDeAr(false)} /> Não
        </label>

      {senteFaltaDeAr && (
        <div>
          <h6>Em que situação você sente falta de ar?</h6>
          <input
            type="text"
            value={situacaoFaltaDeAr}
            onChange={handleSituacaoFaltaDeArChange}
            placeholder="Informe a situação"
          />
          <ButtonAdicionarCampo type={'button'} onClick={adicionarSituacao} />
        </div>
      )}
      {situacoesFaltaDeAr.length > 0 && (
        <div>
          <h6>Situações de Falta de Ar Adicionadas:</h6>
          <ul>
            {situacoesFaltaDeAr.map((situacao, index) => (
              <li key={index}>
                {situacao}{' '}
                <ButtonEditarCampo onClick={() => handleEditarSituacao(index)} />
                <ButtonRemoverCampo onClick={() => removerSituacao(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>
 <MaisInfoMenosInfo text={<ProntuarioFaltaDeArInfo />}></MaisInfoMenosInfo>

    </>
  );
};

export default ProntuarioFaltaDeAr;
