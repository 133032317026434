import { Typography } from 'antd';
const { Title } = Typography;
export const TitleL2 = (props) => {
  return (<>
    <Title level={2} style={{ textAlign: 'center', fontWeight: '900', fontSize: '24px', ...props.style }} className={`mt-2 font-weight-bold ${props.className}`}  id={props.id}  >
      <b><strong style={{ fontFamily: ' Arial, Helvetica', fontWeight: '800', ...props.style }} >    {props.children}</strong></b>
    </Title>
  </>);
};
export default TitleL2;

