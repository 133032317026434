import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import InputCabecalho from '../../../../../../../../../components/Input/InputCabecalho';
import { axiosHeadersToken } from '../../../../../../../../../services/contexts';
import { baseURL } from '../../../../../../../../../services/api';
import { AuthContext } from '../../../../../../../../../contexts/auth';

const ProductItem = ({
  productIndex,
  subIndex,
  xmlData,
  setXmlData,
  product,
  productImages,
  handleSetProductImages,
  handleSaveProduct,
  handleCancelEdit,
  inEditMode,
  handleEditProduct,
}) => {
  const [editedProduct, setEditedProduct] = useState({
    xProd: '',
    NCM: '',
    vProd: '',
    valorVenda: '',
    qCom: '',
    vUnTrib: '',
    NCMDescription: '',
  });

  const { getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();

  useEffect(() => {
    setEditedProduct({
      xProd: product.xProd || '',
      NCM: product.NCM || '',
      NCMDescription: product.NCMDescription || '', // Use product's NCMDescription directly
      qCom: product.qCom || '',
      vProd: product.vProd || '',
      vUnTrib: product.vUnTrib || '',
      valorVenda: product.valorVenda || '',
    });
  }, [product]);
  

  const fetchNCMDescription = useCallback(async (ncm) => {
    try {
      const response = await axiosHeadersToken().get(`${baseURL}get_products_ncm_info/${professional_id}/${ncm}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar descrição do NCM:', error);
      return null;
    }
  }, [professional_id]); // Adding professional_id as a dependency
  
  const fetchNCMData = useCallback(async () => {
    if (editedProduct.NCM) {
      const ncmData = await fetchNCMDescription(professional_id, editedProduct.NCM);
      if (ncmData && ncmData.length > 0) {
        const descriptions = ncmData.map(item => item.descricao).join(', ');
        setEditedProduct(prevProduct => ({
          ...prevProduct,
          NCMDescription: descriptions,
        }));
      }
    }
  }, [editedProduct.NCM, professional_id, setEditedProduct, fetchNCMDescription]);

  useEffect(() => {
    fetchNCMData();
  }, [fetchNCMData]);

  useEffect(() => {
    const updatedXmlData = [...xmlData];
    updatedXmlData[productIndex].det[subIndex] = {
      ...updatedXmlData[productIndex].det[subIndex],
      xProd: editedProduct.xProd,
      NCM: editedProduct.NCM,
      qCom: editedProduct.qCom,
      vProd: editedProduct.vProd,
      vUnTrib: editedProduct.vUnTrib,
      valorVenda: editedProduct.valorVenda,
      NCMDescription: editedProduct.NCMDescription,
    };
    setXmlData(updatedXmlData);
  }, [editedProduct, productIndex, subIndex, setXmlData, xmlData]);

  const handleSave = async () => {
    await fetchNCMData();
    handleSaveProduct({
      ...editedProduct,
      images: productImages,
    });
  };


  const handleCancel = () => {
    handleCancelEdit();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };
  const {  getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      // Verifique se todos os arquivos são do tipo imagem
      const filteredFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
  
      if (filteredFiles.length !== acceptedFiles.length) {
        console.warn('Alguns arquivos foram rejeitados por não serem imagens.');
      }
  
      // Apenas imagens são processadas
      handleSetProductImages(filteredFiles);
    },
  });
  

  return (
    <>
      <li className='bg-light rounded mt-3'>
        <div className='ml-1 mr-1 text-dark text-left mt-3'>
          <br />
          <p><strong>Nome:</strong> {editedProduct.xProd}</p>
          <p><strong>NCM:</strong> {editedProduct.NCM}</p>
          <p><strong>NCM Description:</strong> {editedProduct.NCMDescription}</p>
          <p><strong>Valor unidade:</strong> {parseFloat(editedProduct.vProd).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
          <p className='text-success'><strong>Valor venda: </strong>{parseFloat(editedProduct.valorVenda).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
          <p><strong>Quantidade:</strong> {parseFloat(editedProduct.qCom).toLocaleString('pt-BR', { minimumFractionDigits: 0 })}</p>
          <p><strong>Valor total: </strong> {parseFloat(editedProduct.vUnTrib).toLocaleString('pt-BR', { minimumFractionDigits: 0 })}</p>
          <p className='text-success'><strong>Lucro total: </strong> {/* Cálculo de Lucro */}</p>
          {productImages && productImages.length > 0 && (
            <div>
              <h5>Imagens Inseridas</h5>
              <div className="d-flex">
                {productImages.map((image, index) => (
                  <img
                    key={index}
                    src={image instanceof File ? URL.createObjectURL(image) : ''}
                    alt={`Imagem ${index + 1}`}
                    className="mr-2 mb-2"
                    style={{
                      minHeight: "60px",
                      maxHeight: '60px',
                      minWidth: '60px',
                      maxWidth: '60px',
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {inEditMode ? (
            <>
              <div>
                <h4 className='font-weight-bold text-center'>Editar Produto</h4>
                <div>
                  <InputCabecalho
                    type="text"
                    name="xProd"
                    value={editedProduct.xProd}
                    onChange={handleInputChange} >
                    Nome
                  </InputCabecalho>
                  <InputCabecalho
                    type="text"
                    name="vProd"
                    value={editedProduct.vProd}
                    onChange={handleInputChange}
                    disabled={true}>
                    Valor de custo
                  </InputCabecalho>
                  <InputCabecalho
                    type="number"
                    name="valorVenda"
                    value={editedProduct.valorVenda}
                    onChange={handleInputChange} >
                    Valor venda
                  </InputCabecalho>
                  <InputCabecalho
                    type="text"
                    name="qCom"
                    value={editedProduct.qCom}
                    onChange={handleInputChange}
                    disabled={true}
                  >
                    Quantidade
                  </InputCabecalho>
                </div>
                {editedProduct.images && editedProduct.images.length > 0 && (
                  <div>
                    <h5 className='mt-3'>Imagens Inseridas</h5>
                    <div className="d-flex">
                      {editedProduct.images.map((image, index) => (
                        <img
                          key={index}
                          src={image instanceof File ? URL.createObjectURL(image) : ''}
                          alt={`Imagem ${index + 1}`}
                          className="mr-2 mb-2"
                          style={{
                            minHeight: "60px",
                            maxHeight: '60px',
                            minWidth: '60px',
                            maxWidth: '60px',
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div {...getRootProps({ className: 'dropzone mt-3' })}>
                  <input {...getInputProps()} />
                  <p>Arraste e solte as imagens aqui ou clique para selecionar.</p>
                </div>
                <div className='mt-3 text-center'>
                  <button onClick={handleSave}>Salvar</button>
                  <button onClick={handleCancel}>Cancelar</button>
                </div>
              </div>
            </>
          ) : (
            <div className='d-flex row align-items-center justify-content-center py-3'>
              <div>
                <button onClick={handleEditProduct}>Editar</button>
              </div>
            </div>
          )}
        </div>
      </li>
    </>
  );
};

export default ProductItem;
