import { TitleL1, ButtonCadastrar, IconInfo } from '../../../../../../../components';
export const AgendaCompartilhada = () => {
  return (<>
    <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
      <p className='text-center'>
        <img
          src={require('../assets/AgendaCompartilhada.png')}
          alt="Liberdade Financeira"
          style={{ maxHeight: '280px', maxWidth: '450px' }}
          className='col-md-12'
        />
      </p>
      <TitleL1> Agenda </TitleL1>
      <h5 className='text-justify mt-2'>Desfrute da Agenda Compartilhada Dinâmica. Organização Simplificada e Colaboração Eficiente.
        Explore a facilidade de gerenciar e sincronizar sua agenda, seja você um profissional ou um usuário. Nossa plataforma
         de controle de agenda oferece recursos avançados para garantir uma experiência perfeita de agendamento e acompanhamento
      </h5>
      <br />
      <h5 className='mt-4 mb-4 text-justify font-weight-bold'>
        <strong> Disponível e compartilhavel com acesso fácil e intuitivo</strong>
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Agendamento Simplificado:&nbsp;
        <IconInfo text={"Marque serviços com facilidade, escolhendo datas e horários convenientes"} /></h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Lembretes Automatizados: &nbsp;
        <IconInfo text={"Receba notificações para seus serviços, garantindo que você nunca se esqueça de um compromisso"} /></h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Gerenciamento Centralizado:&nbsp;
        <IconInfo text={"Profissionais podem visualizar e gerenciar todas os serviços de forma organizada"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Compartilhamento de info.:&nbsp;
        <IconInfo text={"Comunique-se facilmente com seu profissional, compartilhando informações relevantes"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Histórico de serviços:&nbsp;
        <IconInfo text={"Visualize seu histórico de serviços anteriores para um acompanhamento eficaz"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Acesso Seguro:&nbsp;
        <IconInfo text={"Proteja seus dados e informações com medidas avançadas de segurança"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Feedback e Avaliações:&nbsp;
        <IconInfo text={"Compartilhe suas opiniões sobre os serviços, promovendo a melhoria contínua"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Conveniência Móvel:&nbsp;
        <IconInfo text={"Acesse sua agenda de qualquer lugar, a qualquer momento, através de dispositivos móveis"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Colaboração Transparente: &nbsp;
        <IconInfo text={"Profissionais e usuários podem trabalhar juntos para alcançar melhores resultados"} />
      </h5>
      <h5 className='mt-2 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;Atendimento Personalizado:&nbsp;
        <IconInfo text={"Receba um atendimento personalizado e adaptado às suas necessidades"} />
      </h5>

      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <ButtonCadastrar />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
    </section>
  </>);
};
export default AgendaCompartilhada;