import React, { useState } from "react";
import "./Styles/Agenda.css";
function DualTimeInput({ index }) {
  const [selectedTime, setSelectedTime] = useState("08:00");
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const timeOptions = [
    "",
    "08:00 às 09:00",
    "09:00 às 10:00",
    "10:00 às 11:00",
    "11:00 às 12:00",
    "12:00 às 13:00",
    "13:00 às 14:00",    
  ];
  const inputIdName = `Name${index}`;
  const inputIdProcedimentos = `procedimentos${index}`;
  const selectIdTimeRange = `timeRange${index}`;
  return (
    <div className="input-container">
      <input
        type="text"
        name={inputIdName}
        placeholder="Nome do Paciente"
        required
        className="input-paciente"
      />
      <input
        type="text"
        name={inputIdProcedimentos}
        placeholder="Procedimento"
        required
        className="input-procedimentos"
      />
      <select
        id={selectIdTimeRange}
        name={selectIdTimeRange}
        value={selectedTime}
        onChange={handleTimeChange}
        className="select-timedual"
      >
        {timeOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
export default DualTimeInput;