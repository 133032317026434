import React, { useState, useEffect } from 'react';
import InputCabecalho from '../../../../../../../../../components/Input/InputCabecalho';

const AlterarValorVenda = ({ xmlData, setXmlData }) => {
  const [porcentagem, setPorcentagem] = useState('');
  const [porcentagemInserida, setPorcentagemInserida] = useState('');
  const [totalItensNormais, setTotalItensNormais] = useState(0);

  useEffect(() => {
    const total = xmlData.reduce((acc, item) => {
      return acc + item.det.reduce((detAcc, detItem) => {
        return detAcc + detItem.prod.reduce((prodAcc, prodItem) => {
          const valorAtual = parseFloat(prodItem.vProd);
          return isNaN(valorAtual) ? prodAcc : prodAcc + valorAtual;
        }, 0);
      }, 0);
    }, 0);
    setTotalItensNormais(total);
  }, [xmlData]);

  const handleAlterarValorVenda = () => {
    const porcentagemFloat = parseFloat(porcentagem.replace(',', '.'));
    if (!isNaN(porcentagemFloat)) {
      const novoXmlData = xmlData.map((item) => {
        return {
          ...item,
          det: item.det.map((detItem) => {
            return {
              ...detItem,
              prod: detItem.prod.map((prodItem) => {
                const valorAtual = parseFloat(prodItem.vProd);
                if (!isNaN(valorAtual)) {
                  // Calcula o novo valor de venda baseado na porcentagem inserida
                  const novoValorVenda = (valorAtual * (1 + porcentagemFloat / 100)).toFixed(2);
                  return {
                    ...prodItem,
                    valorVenda: novoValorVenda,
                  };
                }
                return prodItem;
              }),
            };
          }),
        };
      });
      setXmlData(novoXmlData);
      setPorcentagemInserida(porcentagem);
      setPorcentagem('');
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAlterarValorVenda();
    }
  };

  const lucroEsperado = (totalItensNormais * (1 + porcentagemInserida / 100)).toFixed(2);

  return (
    <>
      <div className="mt-3">
        <InputCabecalho
          type="text"
          value={porcentagem}
          onChange={(e) => setPorcentagem(e.target.value)}
          onKeyDown={handleEnterKeyPress}
        >
          Porcentagem de Acréscimo
        </InputCabecalho>
      </div>
      {xmlData.map((item, index) => (
        <div className="mt-5" key={index}>
          {item.total.map((totalItem, totalIndex) => (
            <div key={totalIndex}>
              {totalItem.ICMSTot.map((ICMSTotItem, ICMSTotIndex) => (
                <div key={ICMSTotIndex}>
                  <li>
                    <strong>Valor Total da NFe:</strong>{' '}
                    <b className='text-warning'>{ICMSTotItem.vNF}</b>
                  </li>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
      <li className='mt-3'>
        <strong>% Adicionada: </strong>
        <b className='text-success'>{porcentagemInserida}</b>
      </li>
      <li className='mt-3'>
        <strong>Lucro esperado: </strong>
        <b className='text-success'>{lucroEsperado}</b>
      </li>
    </>
  );
};

export default AlterarValorVenda;
