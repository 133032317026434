import React from 'react';

const ProntuarioPressaoArterialInfo = () => {
  return (
    <div>
      <h5 >Usando o Componente<strong> Pressão Arterial</strong></h5>
      <p>
        O componente <strong> Pressão Arterial</strong> permite que você registre sua pressão arterial. Você pode adicionar, editar e remover leituras da sua pressão arterial conforme necessário. Abaixo, você encontrará um guia de como usar este componente.
      </p>
      <hr />
      <ol className='ml-3'>
        <li><strong>  Registrar leituras de pressão arterial</strong>
          <p>
            Ao abrir o componente, você encontrará opções para registrar suas leituras de pressão arterial. Você pode inserir a pressão arterial no formato "120/80 mmHg" no campo de entrada e clicar no botão "Adicionar Leitura de Pressão Arterial" para registrar a leitura.
          </p>
        </li>
        <li><strong>  Visualizar leituras registradas</strong>   </li>
        <p>
          Todas as leituras de pressão arterial que você registrar serão exibidas abaixo da seção de registro. Você pode ver todas as leituras anteriores a qualquer momento.
        </p>
        <li><strong>  Editar ou remover leituras</strong>   </li>
        <p>
          Se você deseja editar uma leitura anterior, clique no botão "Editar" ao lado dela. Isso preencherá o campo de entrada com a leitura anterior para edição. Faça as alterações necessárias e clique em "Adicionar Leitura de Pressão Arterial" para salvar as alterações. Se você deseja remover uma leitura, clique no botão "Remover".
        </p>
        <li><strong>  Conclusão</strong>   </li>
        <p>
          Após registrar suas leituras de pressão arterial, você pode continuar usando o componente conforme necessário. Todas as leituras serão mantidas para referência futura.
        </p>

      </ol>
      <hr />
      <p>
        Certifique-se de registrar com precisão suas leituras de pressão arterial para manter um registro útil de suas informações médicas.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};

export default ProntuarioPressaoArterialInfo;
