import React, { useEffect, useState } from "react";
import { FooterUser, NavBreadcrumb } from "../../../../components";
import { ApresentacaoServicos } from "../../../Public";
import ServicosList from "../../../Public/Servicos/partials/layout/comprar/ServicosList";
import { PlanosPrecosServicos, GerenciamentoAvancado, SolucaoIAAvancadaServicos } from "../../../Public/Servicos/partials/layout/vender";

export const Index = () => {
    const [currentSection, setCurrentSection] = useState(
        window.location.hash || "#servicos" // Define #servicos como padrão se não houver hash
    );

    // Monitorar mudanças na URL (hash)
    useEffect(() => {
        const handleHashChange = () => {
            setCurrentSection(window.location.hash);
        };
        // Adiciona um listener para mudanças no hash
        window.addEventListener("hashchange", handleHashChange);
        // Remove o listener ao desmontar o componente
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);

    return (
        <>
            <NavBreadcrumb icon={'user fa'} text={'Serviços'} />
            {currentSection === "#servicos" && <ServicosList />}
            {currentSection === "#apresentacaoServicos" && <ApresentacaoServicos />}
            {currentSection === "#PlanosPrecosServicos" && <PlanosPrecosServicos />}
            {currentSection === "#SolucaoGerencimamentoAvancado" && <GerenciamentoAvancado />}
            {currentSection === "#SolucaoIAAvancadaServicos" && <SolucaoIAAvancadaServicos />}
            <FooterUser />
        </>
    );
};
