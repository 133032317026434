import { Link } from "react-router-dom"


export function Card({img, funcao, link, nome}){
    return( 
        <Link className="card" to={link}>
            <img src={img} alt={nome} className="img-card"/>
            <span className="texto-card"> <strong>{nome}</strong> <strong>{funcao}</strong></span>
        </Link>  
    )
}