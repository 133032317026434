import React, { useContext, useState } from "react";
import { Form, Input, Button, Select, Flex, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { baseURL } from "../../../../../../../services/api";
import { ImageDragDrop, NavBreadcrumb } from "../../../../../../../components";
import { axiosHeadersToken } from "../../../../../../../services/contexts";
import { AuthContext } from "../../../../../../../contexts/auth";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import DragDropPdf from "../../../../../../../components/dragdrop/DragDropPdf";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const mdParser = new MarkdownIt();
export const Index = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null); // Novo estado para o arquivo PDF
  const [markdownContent, setMarkdownContent] = useState("");
  const { getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();
  const onFinish = async (values) => {
    try {
      let productsBulaId = ""; // Variável para armazenar o ID da bula
      if (pdfFile) {
        const pdfFormData = new FormData();
        pdfFormData.append("bulaPacienteFileName", pdfFile);

        const responsePdf = await axiosHeadersToken().post(
          `${baseURL}post_products_bula/${professional_id}`,
          pdfFormData
        );

        console.log(
          "Resposta do Endpoint /post_products_bula:",
          responsePdf.data
        );

        productsBulaId = responsePdf.data.products_bula_id; // Capture o ID retornado
        console.log("ID da Bula Capturado:", productsBulaId);
      }

      // Passo 2: Enviar os outros dados junto com o ID da Bula
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "creation_date") {
          formData.append(key, values[key].toISOString());
        } else {
          formData.append(key, values[key]);
        }
      });

       // Adicionar as imagens ao FormData
       fileList.forEach((file) => {
        formData.append("images", file.originFileObj); // Certifique-se de que "originFileObj" está correto
      });
      
      console.log("formData", [...formData]); 

      if (productsBulaId) {
        formData.append("products_bula_id", productsBulaId); // Adiciona o ID da bula ao FormData
      }

      // Enviar o restante dos dados
      const response = await axiosHeadersToken().post(
        `${baseURL}post_services/${professional_id}`,
        formData
      );
      console.log("Dados enviados com sucesso:", response.data);
      message.success("Serviço cadastrado com sucesso!", 2, () => {
        // Redirecionar o usuário após o toast de sucesso
        navigate("/profissionais/servicos");
      });
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    }
  };

  const handleEditorChange = ({ text }) => {
    setMarkdownContent(text);
    form.setFieldsValue({ services_description: text });
  };

  return (
    <>
      <NavBreadcrumb icon={"suitcase fa"} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
      <ImageDragDrop fileList={fileList} setFileList={setFileList} />
        <Form.Item label="Arquivo de PDF Informação Bula" name="informacao_pdf">
          <DragDropPdf onPdfUpload={setPdfFile} />
        </Form.Item>
        <Form.Item
          label="Nome do Serviço"
          name="services_name"
          rules={[
            { required: true, message: "Por favor, insira o nome do serviço." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Valor do Serviço"
          name="value"
          rules={[
            {
              required: true,
              message: "Por favor, insira o valor do serviço.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Estoque do Serviço" name="services_stock">
          <Input />
        </Form.Item>
        {/* <Form.Item
          label="Código do Serviço"
          name="services_code"
          rules={[
            {
              required: true,
              message: "Por favor, insira o código do serviço.",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label="Descrição do Serviço"
          name="services_description"
          rules={[
            {
              required: true,
              message: "Por favor, insira a descrição do serviço.",
            },
          ]}
        >
          <Input.TextArea rows={4} />
          {/* <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div style={{ flex: 1 }}>
              <MarkdownEditor
                value={markdownContent}
                style={{ height: "400px" }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleEditorChange}
              />
            </div>
          </div> */}
        </Form.Item>
        {/* <Form.Item
          label="Estado do Serviço"
          name="services_state"
          rules={[
            {
              required: true,
              message: "Por favor, insira o estado do serviço.",
            },
          ]}
        >
          <Select defaultValue="active">
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </Form.Item> */}
        {/* <Form.Item
          label="Competência Inicial"
          name="services_competencia_inicial"
          rules={[
            {
              required: true,
              message: "Por favor, insira a competência inicial do serviço.",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
        {/* <Form.Item label="ID da Informação do Serviço" name="services_info_id">
          <Input />
        </Form.Item> */}

        {/* <Form.Item label="Data de Criação" name="creation_date">
          <DatePicker showTime />
        </Form.Item> */}
        <Form.Item>
          <Flex vertical gap="small" style={{ width: "10%", }}>
            <Button type="primary" htmlType="submit" size="large">
              Enviar
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};

export default Index;
