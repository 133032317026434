
import { Link } from "react-router-dom";
import Logo from "../../img/logo_thot.jpg";
import './header.css' 
import '../../GlobalWiki.css'
import { useState } from "react"; 

export function Header({ pagina }) {
  const [inputExpanded, setInputExpanded] = useState(false); 

  const handleInputClick = () => {
    setInputExpanded(true);
  };

  const handleInputBlur = () => {
    setInputExpanded(false);
  };

  return (
    <header className="cabecalho bg-primary">
      <div className="content">
        <div className="group">
          <div className="cabecalho-left">
            <img src={Logo} alt="Logo Thot" className="cabecalho-img" />
            <span>THOT I.A. CORP</span>
          </div>
          <nav className="cabecalho-menu">
          <Link to='/wiki' className={pagina === 'inicio' ? "cabecalho-menu-item active-tab" : "cabecalho-menu-item"}>Início</Link>
         <Link to='/wiki/sprint' className={pagina === 'sprints' ? "cabecalho-menu-item active-tab" : "cabecalho-menu-item"}>Sprints</  Link>
     <Link to='/wiki/projetos' className={pagina === 'projetos' ? "cabecalho-menu-item active-tab" : "cabecalho-menu-item"}>Projetos</Link>
        <Link to='/wiki/configuracoes' className={pagina === 'configuracoes' ? "cabecalho-menu-item active-tab" : "cabecalho-menu-item"}>Configurações</Link>
        <Link to='/wiki/info' className={pagina === 'info' ? "cabecalho-menu-item active-tab" : "cabecalho-menu-item"}>Info</Link>
        <Link className={pagina === 'servico' ? "cabecalho-menu-item active-tab" : "cabecalho-menu-item"}>Serviços</Link>
         </nav>

          </div>
        <div className="cabecalho-right">
          <div className={`cabecalho-input ${inputExpanded ? "expanded" : ""}`}>
            <div className="search-container">
              <i className="fa fa-search" aria-hidden="true"></i>
              <input
                type="text"
                placeholder="Search"
                onClick={handleInputClick}
                onBlur={handleInputBlur}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}