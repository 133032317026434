import ControleProfessionalUnitListData from './components/layout/VinculosProfissional';
import MasterControlsProfissional from './components/layout/MasterControlsProfissional';
export const ControleProfissional = () => {
 
  

return (<>
    <MasterControlsProfissional />
    <ControleProfessionalUnitListData />
  </>);
}