import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MaisInfoMenosInfo } from '../../../../../../components';
import ProntuarioDiabetesCicatrizacaoInfo from '../info/ProntuarioDiabetesCicatrizacaoInfo';
import { post_prontuario } from '../services/post_prontuario';

export const ProntuarioDiabetesCicatrizacao = () => {
  const [temDiabetes, setTemDiabetes] = useState(false);
  const [sangramento, setSangramento] = useState('');
  const [cicatrizacao, setCicatrizacao] = useState('');

  const handleCheckboxChange = (e) => {
    setTemDiabetes(e.target.checked);
  };
  const handleSangramentoChange = (e) => {
    setSangramento(e.target.value);
  };
  const handleCicatrizacaoChange = (e) => {
    setCicatrizacao(e.target.value);
  };
const handleSubmit =()=>{
  const data = {
    temDiabetes,
    sangramento,
    cicatrizacao
  }
  post_prontuario(data)
}
  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        Tem diabetes?</h5>
      <label>
        <input
          type="radio"
          name="diabetes"
          onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="diabetes"
          onChange={() => setTemDiabetes(false)} /> Não
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="diabetes"
          onChange={() => setTemDiabetes(true)} /> Não Sei
      </label>
      <h5 style={{ fontWeight: "bold" }}>Quando se corta há um sangramento  </h5>
      <label>
        <input type="radio" name="sangramento" value="Normal" onChange={handleSangramentoChange} /> Normal
      </label>
      <br />
      <label>
        <input type="radio" name="sangramento" value="Excessivo" onChange={handleSangramentoChange} /> Excessivo
      </label>
      <h5 style={{ fontWeight: "bold" }}>Sua cicatrização é:   </h5>
      <label>
        <input type="radio" name="cicatrizacao" value="Normal" onChange={handleCicatrizacaoChange} /> Normal
      </label>
      <br />
      <label>
        <input type="radio" name="cicatrizacao" value="Complicada" onChange={handleCicatrizacaoChange} /> Complicada
      </label>
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" onClick={handleSubmit}>
        Enviar Informações
        </Link>
      </div>
   <MaisInfoMenosInfo text={<ProntuarioDiabetesCicatrizacaoInfo />}></MaisInfoMenosInfo>
    </>
  );
};

export default ProntuarioDiabetesCicatrizacao;
