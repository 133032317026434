import React, { useState, useEffect, useContext, useCallback } from "react";
import "../../styles/ChartConfiguration.css";
import "../../styles/ChartDropDown.css";
import PeriodoSelect from "../selectsInputsGraficos/Periodo";
import ShowTrendSelect from "../selectsInputsGraficos/ShowTrendSelect";
import OperationSelect from "../selectsInputsGraficos/OperationSelect";
import ShowVersionsSelect from "../selectsInputsGraficos/ShowVersionsSelect";
import TabelaSelect from "../selectsInputsGraficos/TabelaSelect";
import ColunaSelect from "../selectsInputsGraficos/ColunaSelect";
import { fetchTabelas, postDashboardData, fetchColunas } from "../../../../../../../services";
import { AuthContext } from "../../../../../../../contexts/auth";
import { ButtonAPI } from "../../../../../../../components";
const ChartConfiguration = ({ config, onConfigure, onCancel, onDelete, selectedChart, professionalId, }) => {
  const [chartConfig, setChartConfig] = useState(config);
  const [isActive, setIsActive] = useState(false);
  const [tabelas, setTabelas] = useState([]);
  const [selectedTabela, setSelectedTabela] = useState(null);
  const [tableData] = useState({});
  const [selectedColuna, setSelectedColuna] = useState(null);
  const [colunas, setColunas] = useState([]);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setChartConfig((prevConfig) => ({ ...prevConfig, [name]: newValue }));
  };
  const handleSelectChange = (selectedOption, fieldName) => {
    const updatedConfig = { ...chartConfig };
    if (selectedOption && selectedOption.value !== undefined) {
      updatedConfig[fieldName] = selectedOption.value;
    } else {
      updatedConfig[fieldName] = selectedOption.target.value;
    }
    setChartConfig(updatedConfig);
  };
  const handleConfigure = async () => {
    try {
      const localStorageKey = `professional_${professionalId}_chartConfigs`;
      const savedConfigs = JSON.parse(localStorage.getItem(localStorageKey)) || {};
      savedConfigs[selectedChart.label] = chartConfig;
      localStorage.setItem(localStorageKey, JSON.stringify(savedConfigs));
      await postDashboardData(chartConfig);
      await fetchData();
      onConfigure(chartConfig);
      console.log("Dados do dashboard foram enviados com sucesso!");
      alert("Dados do dashboard foram enviados com sucesso!");
      window.location.reload();
    } catch (error) {
      console.error("Error durante el envío de datos del dashboard:", error);
      console.error("Error details:", error.response ? error.response.data : error.message);

      alert("Não foi possível salvar os dados. Por favor, tente novamente mais tarde.");
    }
  };

  const handleDelete = () => {
    onDelete();
  };
  const handleToggleClick = () => {
    setIsActive(!isActive);
  };
  const { getUserId } = useContext(AuthContext);
  const id = getUserId();
  const fetchData = useCallback(async () => {
    try {
      const result = await fetchTabelas(id, setTabelas);
      console.log('Data recebida:', result); // Use result em vez de tabelas
    } catch (error) {
      console.error("Erro ao buscar tabelas:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const localStorageKey = `professional_${professionalId}_chartConfigs`;
    const savedConfigs =
      JSON.parse(localStorage.getItem(localStorageKey)) || {};
    const chartConfig = savedConfigs[selectedChart.label] || {
      title_dashboard: "",
      tabela: null,
      coluna: null,
      quantidade_dados: "",
      periodo_grafico: "",
      intervalo_tempo: "",
      operacao_coleta: "",
      exibir_tendencia: "",
      exibir_versao: "",
      tipo_grafico: selectedChart.label,
    };
    setChartConfig(chartConfig);
  }, [professionalId, selectedChart]);

  const fields = [
    { name: "title_dashboard", label: "Title", type: "text", required: true },
    { name: "tabela", label: "Tabela", type: "select", required: false },
    { name: "coluna", label: "Coluna Analise", type: "select", required: false, },
    { name: "quantidade_dados", label: "Quantidade de Dados a Ser Analisados", type: "text", required: false, },
    { name: "periodo_grafico", label: "Período", type: "select", required: false, },
    { name: "intervalo_tempo", label: "Intervalo de Tempo", type: "text", required: false, },
    { name: "operacao_coleta", label: "Operação de Coleta", type: "select", required: false, },
    { name: "exibir_tendencia", label: "Exibir Tendência Não Resolvidos", type: "select", required: false, },
    { name: "exibir_versao", label: "Exibir Versões", type: "select", required: false, },
  ];
  return (
    <>
      <div className="chart-configuration-container">
        <div
          className={`chart-dropdown-navigation ${isActive ? "active" : ""}`}
        >
          <div className="userBx"></div>
          <div
            className="chart-dropdown-menuToggle "
            onClick={handleToggleClick}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
              <path
                d="M12 14a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-4.5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm9 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
                fill="currentColor"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
          <ul className="chart-dropdown-menu">
            <li>
              <span className="chart-configuration-dropdown-item">
                Configurar
              </span>
            </li>
            <li>
              <span
                className="chart-excluir-dropdown-item"
                onClick={handleDelete}
              >
                Excluir
              </span>
            </li>
          </ul>
        </div>
        <h4>{selectedChart.label} Chart</h4>
        <div>
          <form className="chart-configuration-form">
            {fields.map((field) => (              <>
                <div
                  key={field.name}
                  className="chart-configuration-field auxiliold"
                >
                  <label className="config-label">
                    {field.label}:
                    {field.required && (
                      <span className="chart-configuration-required-field">
                        *
                      </span>
                    )}
                  </label>
                  {field.type === "checkbox" ? (
                    <input
                      type={field.type}
                      name={field.name}
                      className="config-input"
                      value={tableData[field.name] || ""}
                      defaultChecked={chartConfig[field.name] || false}
                      onChange={handleInputChange}
                    />
                  ) : field.type === "checkbox" ? (
                    <input
                      type="hidden"
                      name="tipo_grafico"
                      value={selectedChart.label}
                    />
                  ) : field.type === "select" ? (
                    // Renderizar os componentes de seleção apropriados
                    field.name === "tabela" ? (
                      <TabelaSelect
                        value={selectedTabela}
                        onChange={async (selectedOption) => {
                          setSelectedTabela(selectedOption);
                          await fetchColunas(selectedOption, setColunas); // Asegúrate de pasar setColunas aquí
                          handleSelectChange(selectedOption, field.name);
                        }}
                        options={tabelas.map((tabela) => ({
                          value: tabela,
                          label: tabela,
                        }))}
                      />

                    ) : field.name === "coluna" ? (
                      <ColunaSelect
                        value={selectedColuna}
                        onChange={(selectedOption) => {
                          setSelectedColuna(selectedOption);
                          handleSelectChange(selectedOption, field.name); // Atualize o estado chartConfig
                        }}
                        options={colunas}
                      />
                    ) : field.name === "periodo_grafico" ? (
                      <PeriodoSelect
                        value={chartConfig[field.name] || ""}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, field.name)
                        }
                      />
                    ) : field.name === "operacao_coleta" ? (
                      <OperationSelect
                        value={chartConfig[field.name] || ""}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, field.name)
                        }
                      />
                    ) : field.name === "exibir_tendencia" ? (
                      <ShowTrendSelect
                        value={chartConfig[field.name] || ""}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, field.name)
                        }
                      />
                    ) : field.name === "exibir_versao" ? (
                      <ShowVersionsSelect
                        value={chartConfig[field.name] || ""}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, field.name)
                        }
                      />
                    ) : null
                  ) : (
                    <div>
                      <input
                        type={field.type}
                        name={field.name}
                        className="config-input"
                        value={chartConfig[field.name] || ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  )}
                </div>
              </>
            ))}
            <ButtonAPI
              type="button"
              className="chart-configuration-button"
              onClick={handleConfigure}
            >
              Save
            </ButtonAPI>
          </form>
        </div>
      </div>
    </>);
};
export default ChartConfiguration;