import { IconInfo } from '../../../../../../../../../../../components';
import DocumentosSoftwareAnaliseFacial from './DocumentosSoftwareAnaliseFacial';
export const AnaliseFacialInfo = () => {
  return (<>
    <div>
      <p className='text-justify'>
        Análise Facial com dois cliques. Tenha laudos com indicações de forma automática com precisão e velocidades impressionantes. Para demais informações técnicas consulte documentos de software
      </p>
      <div className='text-center mt-3'>
        <IconInfo style={{ cursor: "pointer" }} titleOpen="Mostrar documentos de software" titleDown='Esconder documentos de software' text={<><DocumentosSoftwareAnaliseFacial /> </>} />
      </div>
    </div>
  </>);
};
export default AnaliseFacialInfo;