import React, { useState } from 'react';
const UrlInput = () => {
  const [url, setUrl] = useState('');
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };
  const handleShowUrl = () => {
    alert('URL inserida:', url);
  };
  return (
    <div>
      <input
        type="text"
        value={url}
        onChange={handleUrlChange}
        placeholder="Digite a URL"
      />
      <button onClick={handleShowUrl}>Exibir URL no Console</button>
    </div>
  );
};
export default UrlInput;