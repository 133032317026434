import React from 'react';
import GraficosFinanceiros from './GraficosFinanceiros';
import { NavBreadcrumb } from '../../../../../../components';
export const Index = () => {
    return (<>
        <div className=' w-100' >
            <NavBreadcrumb icon={'suitcase fa'} />
            <GraficosFinanceiros />
        </div>
    </>)
}


