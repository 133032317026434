import React from 'react';
import ProntuarioDentistaArcadaDentaria from './ProntuarioDentistaArcadaDentaria';
import ProntuarioDenstitaReacaoAnestesia from './ProntuarioDenstitaReacaoAnestesia';
import ProntuarioDentistaGengivaSangra from './ProntuarioDentistaGengivaSangra';
import PronutarioDentistaGostoRuinBoca from './PronutarioDentistaGostoRuinBoca';
import ProntuarioDentistaEscovarDentes from './ProntuarioDentistaEscovarDentes';
import ProntuarioDentistaFioDental from './ProntuarioDentistaFioDental';
import ProntuarioDentistaDoresEstalos from './ProntuarioDentistaDoresEstalos';
import ProntuarioDentistaRangeDentes from './ProntuarioDentistaRangeDentes';
import ProntuarioDentistaFeridaFaceLabios from './ProntuarioDentistaFeridaFaceLabios';
import ProntuarioDentistaFuma from './ProntuarioDentistaFuma';

export const ProntuarioDenstita = () => {

  return (<>

 
    <ProntuarioDenstitaReacaoAnestesia />
    <ProntuarioDentistaGengivaSangra />
    <PronutarioDentistaGostoRuinBoca />
    <ProntuarioDentistaEscovarDentes />
    <ProntuarioDentistaFioDental />
    <ProntuarioDentistaDoresEstalos />
    <ProntuarioDentistaRangeDentes />
    <ProntuarioDentistaFeridaFaceLabios />
    <ProntuarioDentistaFuma />
    <ProntuarioDentistaArcadaDentaria />
  </>);
};

export default ProntuarioDenstita;