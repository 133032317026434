import React from 'react';

const ApresentacaoCarrinhoVazioInfo = () => {
  return (
    <div className="text-justify col-md-12">
      <h5>Por que Comprar Conosco?</h5>
      <p>
        Quando você escolhe fazer compras conosco, você está optando por uma experiência de compra excepcional, repleta de vantagens que valorizamos. Aqui está o que torna a sua escolha conosco uma decisão inteligente:
      </p>
      <hr />
      <ol className="ml-3">
        <li><strong>Segurança Garantida:</strong> Sua segurança é nossa prioridade. Utilizamos os mais avançados protocolos de segurança para proteger suas informações pessoais e financeiras.</li>
        <li><strong>Produtos de Qualidade:</strong> Oferecemos apenas produtos de alta qualidade que passam por rigorosos controles de qualidade para garantir que você obtenha o melhor.</li>
        <li><strong>Atendimento ao Cliente Excepcional:</strong> Nossa equipe de suporte ao cliente está sempre pronta para ajudar. Estamos aqui para responder às suas perguntas e resolver quaisquer problemas que possam surgir.</li>
        <li><strong>Entrega Rápida e Confiável:</strong> Entregamos seus produtos de forma rápida e confiável, para que você não precise esperar muito para desfrutar de suas compras.</li>
        <li><strong>Promoções Especiais:</strong> Oferecemos promoções e descontos exclusivos para nossos clientes fiéis. Fique atento para economizar ainda mais em suas compras.</li>
        <li><strong>Experiência de Compra Conveniente:</strong> Nosso site foi projetado para proporcionar uma experiência de compra fácil e conveniente, para que você possa encontrar e comprar o que deseja com facilidade.</li>
      </ol>
      <hr />
      <p>
        Na sua próxima compra conosco, experimente a diferença. Temos o compromisso de tornar sua experiência de compra a melhor possível, e estamos ansiosos para atendê-lo.
      </p>
    </div>
  );
};

export default ApresentacaoCarrinhoVazioInfo;
