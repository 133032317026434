import { TitleL2 } from "../../../../../components";
import { CheckCircleOutlined } from "@ant-design/icons";
const RepresentacaoComercialInfo = () => {
    return (<>
        <TitleL2>Vinculação de Convite</TitleL2>
        <h5 className='text-justify'>
            Com seu cadastro ativo você poderá gerar um link com a oportunidade de convidar outras pessoas a se juntarem à nossa Rede Social Profissional.
            Com isso, você estará vinculando o seu ganho de Representação Comercial a esses convites bem-sucedidos de forma automática
            <br /><br />
            Além disso, para oferecer ainda mais transparência e controle, você poderá acompanhar o seu ganho de Representação Comercial diretamente em nosso dashboard.
            Lá, você terá acesso a informações detalhadas sobre os convites enviados, quantos foram aceitos e o valor correspondente a cada um. Assim, você terá total
            confiança e clareza sobre seus ganhos na plataforma
            <br /><br />
            Sobre o convite você deve ver algo como abaixo quando gerar, além disso sugerimos que você crie um convite para
            cada local que for propagar o convite assim você terá o controle do nosso analytics e poderá rastrear as conversões de cada rede. 
            Use a mensagem para diferenciar os convites e as redes
         

        </h5>
        <div className='mt-3 mb-3 col-md-12 text-center py-2' style={{backgroundColor: 'rgba(183, 235, 143, 0.2)'}}>
                          
                          <CheckCircleOutlined className='text-success' style={{ fontSize: '48px', }} />
                          <TitleL2 style={{ color: "green" }}>Link gerado</TitleL2>
                          <h5 style={{ color: 'blue', wordWrap: 'break-word' }}>https://thotiacorp.com.br?convite_code={'convite'}</h5>
               
                      </div>
    </>);
};
export default RepresentacaoComercialInfo; 