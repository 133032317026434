import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonAdicionarCampo,
  ButtonEditarCampo,
  ButtonRemoverCampo,
  InputPadraoTitle,
  MaisInfoMenosInfo
} from '../../../../../../../components';
import ProntuarioExpectativaTratamentoInfo from '../../info/ProntuarioExpectativaTratamentoInfo';

export const ProntuarioExpectativaTratamento = ({ info }) => {
  const [expectativa, setExpectativa] = useState('');
  const [expectativasTratamento, setExpectativasTratamento] = useState([]);
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleExpectativaChange = (e) => {
    setExpectativa(e.target.value);
  };
  const adicionarExpectativa = () => {
    if (expectativa.trim() !== '') {
      if (indiceEditando === -1) {
        setExpectativasTratamento([...expectativasTratamento, expectativa]);
      } else {
        const novasExpectativas = [...expectativasTratamento];
        novasExpectativas[indiceEditando] = expectativa;
        setExpectativasTratamento(novasExpectativas);
        setIndiceEditando(-1);
      }
      setExpectativa('');
    }
  };

  const removerExpectativa = (index) => {
    const novasExpectativas = [...expectativasTratamento];
    novasExpectativas.splice(index, 1);
    setExpectativasTratamento(novasExpectativas);
  };

  const handleEditarExpectativa = (index) => {
    setExpectativa(expectativasTratamento[index]);
    setIndiceEditando(index);
  };

  return (
    <>
      <div>
        <InputPadraoTitle htmlFor={'Expectativas de Tratamento'} title={'Expectativas de Tratamento'} />
        <textarea
          className="form-control text-center col-md-12 border border-dark"
          type="text"
          value={expectativa}
          onChange={handleExpectativaChange}
          placeholder="Informe uma expectativa de tratamento"
        />
        &nbsp;Quais suas expectativas principais frente ao profssional<strong> {info}</strong>
        <div className="col-md-12 mt-2 mb-5 d-flex">
          <ButtonAdicionarCampo type={'button'} onClick={adicionarExpectativa} />
        </div>
      </div>
      {expectativasTratamento.length > 0 && (
        <div>
          <h6>Expectativas de Tratamento Adicionadas:</h6>
          <ul>
            {expectativasTratamento.map((item, index) => (
              <li key={index}>
                {item}{' '}
                <ButtonRemoverCampo onClick={() => removerExpectativa(index)} />
                <ButtonEditarCampo onClick={() => handleEditarExpectativa(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}

      <MaisInfoMenosInfo text={<ProntuarioExpectativaTratamentoInfo />}></MaisInfoMenosInfo>
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
          Enviar Informações
        </Link>

      </div>
    </>
  );
};
export default ProntuarioExpectativaTratamento;
