import React, { useState, useEffect, useContext, useCallback } from 'react';
import { baseURL } from '../../../../services/api';
import { AuthContext } from '../../../../contexts/auth';
import { NavBreadcrumb, ReferenciaLocal, CardDirecionamento } from '../../../../components';
import { axiosHeadersToken } from '../../../../services/contexts';
import InvitationCard from '../../Usuarios/Notificacoes/partials/InvitationCard';
export const Index = () => {
  const [apiResponse, setApiResponse] = useState([]);

  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();
  const onAcceptInvitation = async (invitationId) => {
    try {
      const response = await axiosHeadersToken().put(`${baseURL}put_convite/${user_id}`, {
        id: invitationId, // Envie o ID do convite
        state: "active", // Estado a ser atualizado       
      });
      if (response.status === 200) {
        fetchApiResponse();
      } else {
        console.error('Erro ao aceitar convite:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao aceitar convite:', error);
    }
  };
  const onDeleteInvitation = async (invitationId) => {
    try {
      const response = await axiosHeadersToken().put(`${baseURL}put_convite/${user_id}`, {
        id: invitationId, // Envie o ID do convite
        state: "deleted", // Estado a ser atualizado       
      });
      if (response.status === 200) {
        fetchApiResponse();
      } else {
        console.error('Erro ao deletar convite:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao deletar convite:', error);
    }
  };
  const onBlockedInvitation = async (invitationId) => {
    try {
      const response = await axiosHeadersToken().put(`${baseURL}put_convite/${user_id}`, {
        id: invitationId, // Envie o ID do convite
        state: "blocked", // Estado a ser atualizado       
      });
      if (response.status === 200) {
        fetchApiResponse();
      } else {
        console.error('Erro ao bloquear convite:', response.statusText);
      }
    } catch (error) {
      console.error('Erro ao bloquear convite:', error);
    }
  };
  const fetchApiResponse = useCallback(async () => {
    try {
      const response = await axiosHeadersToken().get(`${baseURL}get_convite/${user_id}`);
      const data = response.data; // Use response.data em vez de response.json()
      setApiResponse(data);
      // alert(JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [user_id, setApiResponse]); // Dependências necessárias

  useEffect(() => {
    fetchApiResponse();
  }, [fetchApiResponse]); // Dependência: fetchApiResponse

  // Remova o "Loading..." e renderize apenas o restante do conteúdo quando apiResponse não for nulo
  if (apiResponse === null) {
    return null; // Isso fará com que a página fique em branco
  }
  if (!Array.isArray(apiResponse)) {
    console.error('O valor retornado pela API não é um array:', apiResponse);
    return null;
  }
  // Organize o array de convites com base no estado (state)
  const invitationsArray = apiResponse.sort((a, b) => {
    if (a.state === "pending" && b.state !== "pending") return -1;
    if (a.state !== "pending" && b.state === "pending") return 1;
    // Priorize os convites ativos (state: "active")
    if (a.state === "active" && b.state !== "active") return -1;
    if (a.state !== "active" && b.state === "active") return 1;
    // Em seguida, organize os convites bloqueados (state: "blocked")
    if (a.state === "blocked" && b.state !== "blocked") return -1;
    if (a.state !== "blocked" && b.state === "blocked") return 1;
    // Por fim, os convites deletados (state: "deleted")
    if (a.state === "deleted" && b.state !== "deleted") return -1;
    if (a.state !== "deleted" && b.state === "deleted") return 1;
    // Se os estados forem iguais, mantenha a ordem atual
    return 0;
  });
  return (
    <>
      <div className="w-100">
        <NavBreadcrumb icon="bell fa" text="Notificações Profissionais" />
        <div className="py-3">
          <div className="text-center">
            <div className="container-fluid">
              <div className="row d-inline-flex justify-content-center w-100">
                {/* Complexo A - sintonia NAV */}
                {!apiResponse && <CardDirecionamento
                  text="Sem"
             
                  icon="mt-3 fa fa-bell fa-3x text-white"
                  href="../profissionais"
                />}
                <ReferenciaLocal text="Convites" />
                {invitationsArray.map((invitation, index) => {
                  let title = null;
                  if (index === 0 || invitation.state !== invitationsArray[index - 1].state) {
                    title = invitation.state === "active" ? "Ativos" : invitation.state === "blocked" ? "Bloqueados": invitation.state === "pending" ? "Pendentes" : "Deletados";
                  }
                  return (
                    <React.Fragment key={index}>
                      {title && (
                        <div className="col-md-12 mt-3">
                          <h3>{title}</h3>
                        </div>
                      )}
                      <div className="col-md-12">
                        <InvitationCard
                          invitation={invitation.imageUrl}
                          name={invitation.name}
                          invitation_date={invitation.invitation_date}
                          location={invitation.location}
                          status={invitation.status}
                          id={invitation.id}
                          owner_user_id={invitation.owner_user_id}
                          owner_professional_id={invitation.owner_professional_id}
                          owner_professional_unit_id={invitation.owner_professional_unit_id}
                          owner_company_id={invitation.owner_company_id}
                          owner_company_unit_id={invitation.owner_company_unit_id}
                          state={invitation.state}
                          user_id={invitation.user_id}
                          professional_id={invitation.professional_id}
                          company_unit_id={invitation.company_unit_id}
                          token={invitation.token}
                          expiry_date={invitation.expiry_date}
                          message={invitation.message}
                          deleteInvitation={onDeleteInvitation}
                          acceptInvitation={() => onAcceptInvitation(invitation.id)}
                          blockedInvitation={onBlockedInvitation}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
