import React, { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Input, Row, Col, DatePicker, Button, Select } from "antd";
import InputMask from "react-input-mask";
import "../../../Usuarios/Consultas/components/Utils/BuscarPaciente/style.css";
import "../components/layout/novo/AtestadoPage.css";
import { useLocation } from "react-router-dom";
import { FooterProfissional, NavProfissionalMain, NavBreadcrumb } from "../../../../../components";



dayjs.extend(customParseFormat);

const dateFormat = "DD/MM/YYYY";
const { TextArea } = Input;
const timeFormat = "HH:mm";
const { Option } = Select;

export const EmitirAtestadoPage = () => {
  const location = useLocation();
  const patient = location.state?.patient || {};
  const [dadosPaciente, setDadosPaciente] = useState({
    name: patient.name || "",
    cpf: "",
    cid10: "",
    dataConsulta: dayjs(),
    motivo: "",
    periodoInicio: dayjs("12:00", timeFormat),
    periodoFim: dayjs("13:00", timeFormat),
    periodoAfastamento: "",
  });

  const [validacaoCampos, setValidacaoCampos] = useState(true);


  const handleNomeChange = (e) => {
    const newValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    setDadosPaciente((prev) => ({ ...prev, name: newValue }));
  };

  const handleRgChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, "");
    if (/^\d{0,10}$/.test(newValue)) {
      setDadosPaciente((prev) => ({ ...prev, cpf: newValue }));
    }
  };

  const handleCid10Change = (e) => {
    const newValue = e.target.value;
    setDadosPaciente((prev) => ({ ...prev, cid10: newValue }));
  };

  const handleDataConsultaChange = (date) => {
    setDadosPaciente((prev) => ({ ...prev, dataConsulta: date }));
  };

  const handleMotivoChange = (e) => {
    const newValue = e.target.value;
    setDadosPaciente((prev) => ({ ...prev, motivo: newValue }));
  };



  const handlePeriodoAfastamentoChange = (e) => {
    const newValue = e.target.value;
    setDadosPaciente((prev) => ({ ...prev, periodoAfastamento: newValue }));
  };


  const handleEmitirClick = () => {
    const {
      name,
      cpf,
      cid10,
      motivo,
      periodoAfastamento,
    } = dadosPaciente;

    if (
      name.trim() === "" ||
      cpf.trim() === "" ||
      cid10.trim() === "" ||
      motivo.trim() === "" ||
      periodoAfastamento.trim() === ""
    ) {
      // Exibir mensagem de erro indicando quais campos precisam ser preenchidos
      setValidacaoCampos(false);
    } else {
      // Lógica para enviar os dados (substitua isso pela sua lógica real de envio)
      console.log("Enviar dados:", dadosPaciente);
      // Resetar a validação
      setValidacaoCampos(true);
    }
  };

  return (
    <div className="d-flex">
      <NavProfissionalMain />
      <div className="w-100">
        <div className="w-100">
          <NavBreadcrumb text={" Atestado"} icon={"user fa"} />
        </div>
        <div className="Atestado-Paciente">
          <div className="Atestado-titulo">Dados Pacientes</div>
        </div>
        <div className="Atestado-input-select-container-container-com-borda">


          <div className="Atestado-Nome">
            <label style={{ marginBottom: "20px", fontWeight: "bold" }}>Nome:</label>
            <Input
              className="campo-input"
              placeholder="Digite o nome"
              value={dadosPaciente.name}
              onChange={handleNomeChange}
              disabled={true}
              style={{ marginBottom: "30px", height: "53px", color: "	#808080" }}
            />
          </div>

          <div className="Atestado-campo-cpf">
            <label style={{ display: "block", fontWeight: "bold" }}>CPF:</label>
            <InputMask
              placeholder="999.999.999-90"
              mask="999.999.999-90"
              maskChar=" "
              value={patient.cpf}
              onChange={handleRgChange}
              disabled={true}
              style={{ marginBottom: "20px", width: "30%", height: "53px", color: "	#808080" }}
              className="RG"
            >
              {(inputProps) => <Input {...inputProps} disabled={true} />}
            </InputMask>
          </div>


          <div className="Atestado-campo-CID">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ fontSize: "16px", marginBottom: "20px", fontWeight: "bold" }}>CID-10:</label>
              <Select
                placeholder="Selecione o CID-10"
                value={dadosPaciente.cid10}
                onChange={handleCid10Change}
                style={{ width: "30%", marginBottom: "20px", height: "53px" }}
                className="CID"
              >
                <Option value="">CID-10</Option>
              </Select>
            </div>
          </div>

          <div className="Atestado-campo-Data-de-Consulta">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ fontSize: "16px", marginBottom: "20px", fontWeight: "bold" }}>
                Data da Consulta:
              </label>
              <DatePicker
                defaultValue={dadosPaciente.dataConsulta}
                format={dateFormat}
                onChange={handleDataConsultaChange}
                style={{ width: "30%", marginBottom: "20px", height: "53px" }}
                className="Data"
              />
            </div>
          </div>

          <div className="Atestado-Motivo">
            <label style={{ fontSize: "16px", marginTop: "20px", fontWeight: "bold" }}>Motivo:</label>

            <TextArea
              style={{ width: "100%", height: "120px" }}
              showCount
              maxLength={300}
              onChange={handleMotivoChange}
              placeholder=""
              styles={{ marginBottom: "20px" }}
            />
          </div>

          <div className="Atestado-Periodo">
            <label style={{ fontSize: "16px", marginTop: "30px", fontWeight: "bold" }}>Período:</label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                type="time"



                style={{ marginBottom: "30px", width: "13%", height: "53px" }}
                className="time"
              />
              <span style={{ margin: "0 8px", fontSize: "16px", marginBottom: "30px", fontWeight: "bold" }}>
                às
              </span>
              <Input
                type="time"


                style={{ marginBottom: "30px", width: "13%", height: "53px" }}
                className="time"
              />
            </div>
          </div>

          <div className="Atestado-Periodo-de-Afastamento">
            <label style={{ fontSize: "16px", marginTop: "20px", fontWeight: "bold" }}>
              Período de Afastamento:
            </label>

            <Input
              placeholder=""
              value={dadosPaciente.periodoAfastamento}
              onChange={handlePeriodoAfastamentoChange}
              style={{ marginBottom: "20px", height: "53px" }}
            />
          </div>

          <Row justify="end" style={{ marginTop: "10px" }}>
            {!validacaoCampos && (
              <span
                style={{
                  color: "red",
                  margin: "0 auto",
                  alignItems: "center",
                  fontSize: "20px"
                }}
              >
                Todos os campos devem ser preenchidos corretamente.
              </span>
            )}
          </Row>

          <Row justify="end" style={{ marginTop: "20px" }}>
            <Col>
              <Button type="primary" size="large" onClick={handleEmitirClick}>
                Emitir
              </Button>
            </Col>
          </Row>
        </div>

        <FooterProfissional />
      </div>
    </div>
  );
}
export default EmitirAtestadoPage;