
import React from 'react';
import { TitleL1, ButtonCadastrar, IconInfo } from '../../../../../../../components';
import AnaliseFacialInfo from '../../../../../../Private/Profissionais/IA/components/Imagem/partials/layout/AnaliseFacial/partials/info/AnaliseFacialInfo';
export const SolucaoIAAvancadaServicos = () => {
    return (<>
        <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
            <p className='text-center'>
                <img
                    src={require('../assets/MetaCriacaoIA.png')}
                    alt="Meta criações de I.A com dois cliques"
                    style={{ maxHeight: '280px', maxWidth: '300px' }}
                    className='col-md-12'
                />
            </p>
            <TitleL1>I.A.</TitleL1>
            <h5 className=' text-justify'>
                <strong> Utilize ou crie Inteligências Artificais sem código - técnologia no-code</strong>.
                Explore as capacidades incríveis de criação e treinamento de
                I.A.s com apenas dois cliques. I.A. no-code dispensa conhecimento prévio em programação, conceito arrasta e solta
            </h5>
            <h5 className='font-weight-bold mt-5'><strong>Tipos de criação de inteligência artificial</strong></h5>

            <ul className=' mt-3 text-justify'>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Classificação de Imagens:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Crie e treine I.A. para classificação de de imagens com dois cliques. Verifique imagens de forma automática com precisão e velocidades impressionantes"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Análise de Imagens:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={<><AnaliseFacialInfo /> </>} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Texto:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Crie e treine I.A. de geração de texto com dois cliques. Gere automaticamente textos envolventes e relevantes para diversos fins"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Mista:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={" Combine I.A. de texto e de imagem para verificar e gerar conteúdo altamente relevante"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Business Intelligence:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={`Descubra o Poder da I.A. de Business Intelligence (B.I.) para receita recorrênte
Explore as capacidades incríveis de inteligência artificial para impulsionar sua estratégia de vendas
com nossa tecnologia avançada. Simplificamos a criação e treinamento de modelos de I.A. para análise de dados e tomada de decisões com apenas alguns cliques. Sem necessidade de conhecimento avançado em programação, com uma interface intuitiva`} />
                </h5>
            </ul>
            <h5 className='font-weight-bold mt-5'><strong>Funcionalidades</strong></h5>

            <ul className=' mt-3 text-justify'>

                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Compartilhamento de I.A.s com Grupo de Trabalho:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Colabore em projetos de I.A.s com equipes em tempo real"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Treinamento e Aprimoramento de I.A.s &nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Aperfeiçoe a IA através de treinamento contínuo com feedback humano"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Conteúdo Personalizado&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Adapte o conteúdo de acordo com as preferências individuais"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Automação Eficiente:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Economize tempo e recursos na produção ou verificação de conteúdo"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Flexibilidade Criativa:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Experimente diferentes estilos e abordagens com facilidade"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Expansão de Possibilidades&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Descubra novas formas de expressão e comunicação"} />
                </h5>
                <h5 className='mt-2'><i className=' fa fa-check-square text-success'></i>&nbsp;Resultados Impactantes:&nbsp;
                    <IconInfo style={{ cursor: "pointer" }} text={"Surpreenda seu público com conteúdo de alta qualidade"} />
                </h5>
            </ul>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <ButtonCadastrar />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </section>
    </>);
}
export default SolucaoIAAvancadaServicos;