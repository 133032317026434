import React, { useState, useRef, useEffect } from 'react';
import { Button, Input, Pagination, Upload } from 'antd';
import { CameraOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import "../../style/IAImagem.css";
import { TitleL3 } from '../../../../../../../../../components';


const ClassesIAImagem = ({ name, startCamera, stopCamera, captureImage, videoRef }) => {
  const [cameraOn, setCameraOn] = useState(false);
  const [capturedImages, setCapturedImages] = useState([]);
  const [totalImages, setTotalImages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [editingName, setEditingName] = useState(false); // Estado para controlar se o nome está sendo editado
  const [editedName, setEditedName] = useState(name); // Estado para armazenar o valor editado do nome
  const canvasRef = useRef(null);
  const handleCaptureImage = (moduleName, configuracoes) => {
    if (videoRef.current && videoRef.current.videoWidth && videoRef.current.videoHeight) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png", { type: "image/png" });
        setCapturedImages((prevImages) => [
          ...prevImages,
          { file: file, previewURL: URL.createObjectURL(file) },
        ]);
        captureImage(moduleName, configuracoes);
        setTotalImages(prevTotalImages => prevTotalImages + 1);
      }, 'image/png');
    }
  };
  const handleDeleteImage = (index) => {
    setCapturedImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      return newImages;
    });

    setTotalImages(prevTotalImages => prevTotalImages - 1);
  };
  const handleToggleCamera = () => {
    setCameraOn(!cameraOn);
  };
  useEffect(() => {
    if (cameraOn) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [cameraOn, startCamera, stopCamera]);
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newImages = Array.from(files).map(file => ({
      file: file,
      previewURL: URL.createObjectURL(file)
    }));
    setCapturedImages(prevImages => [
      ...prevImages,
      ...newImages
    ]);
    setTotalImages(prevTotalImages => prevTotalImages + newImages.length);
  };
  const handleChange = (info) => {
    const files = info.fileList.map(file => file.originFileObj);
    const newImages = files.filter(file => {
      const existingImage = capturedImages.find(image => image.file.name === file.name);
      return !existingImage;
    }).map(file => ({
      file: file,
      previewURL: URL.createObjectURL(file)
    }));
    setCapturedImages(prevImages => [
      ...prevImages,
      ...newImages
    ]);
    setTotalImages(prevTotalImages => prevTotalImages + newImages.length);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return (
    <div className="module flex-wrap col-md-12 mt-5 bg-white rounded">

      {!editingName ? (
        <Input
          className='py-2 mt-2 mb-2'
          type="text"
          value={editedName}
          onChange={(e) => setEditedName(e.target.value)}
          onBlur={() => {
            // Ao perder o foco, salva o novo nome e desabilita a edição
            setEditingName(false);
            // Aqui você pode adicionar uma função para salvar o novo nome, se necessário
          }}
          autoFocus // Foca automaticamente no input quando ele é renderizado
          suffix={<i className="fa fa-edit" />}
        />
      ) : (
        <TitleL3
          style={{ cursor: 'pointer' }}
          onClick={() => setEditingName(true)} // Habilita a edição quando clicado no nome
        >
          {editedName}
        </TitleL3>
      )}
      <div className='d-flex flex-wrap col-md-12'>
        <div className="drag-drop col-md-6" onDragOver={handleDragOver} onDrop={handleDrop}>
          <Upload.Dragger
            multiple
            showUploadList={false}
            onChange={handleChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <small className="ant-upload-text">Clique ou arraste as imagens para esta área para fazer o upload</small>
          </Upload.Dragger>
        </div>
        {cameraOn ? (
          <>
            <div className="camera-container col-md-6">
              <video ref={videoRef} autoPlay style={{ height: '100%', width: '100%' }}></video>
              <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            </div>
            <div className="camera-buttons">
              <div className="col-md-12 d-flex mx-auto justify-content-center">
                <Button type="primary" icon={<CameraOutlined />} onClick={() => handleCaptureImage(name)}>
                  Capturar Imagem
                </Button>
                <Button danger onClick={handleToggleCamera}>
                  Parar Câmera
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className='col-md-6'>
            <Button type="primary" className="h-100 d-flex" onClick={handleToggleCamera}>
              <p className="ant-upload-drag-icon">
                <CameraOutlined style={{ fontSize: '50px', marginTop: '10px' }} /><br /><br />Ligar Câmera
              </p>
            </Button>
          </div>
        )}
      </div>
      <div className="flex-wrap col-md-12 d-flex mt-3 captured-image-container justify-content-center">
        {capturedImages.slice(startIndex, endIndex).map((image, index) => (
          <div key={index} className="captured-image-container">
            <div className="captured-image-wrapper d-flex col-md-12 justify-content-center mx-auto">
              <img
                src={image.previewURL}
                alt="Imagem Capturada"
                className="captured-image"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
              <Button
                type="text"
                danger
                onClick={() => handleDeleteImage(index + startIndex)}
                icon={<DeleteOutlined />}
                style={{
                  position: 'absolute',
                  bottom: '-5px',
                  left: '8px',
                }}
              />
            </div>
          </div>
        ))}
        <div className="d-flex flex-nowrap pagination-container">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={capturedImages.length}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={(current, size) => setPageSize(size)}
            pageSizeOptions={['5', '10', '15', '50', '100', `${totalImages}`]}
          />
        </div>

      </div>
      <small className='font-weight-bold'>Total de imagens classe: {totalImages}</small>

    </div >
  );
};

export default ClassesIAImagem;
