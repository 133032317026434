import React from 'react';
const TeamCard = ({ name, title, imageSource, lattesLink, linkedinLink, githubLink }) => {
    return (
<div className='col-md-12'>
        <div className='row mt-2 py-2 rounded align-items-center justify-content-center'>
            <div className='col-md-2 text-center'>
                <img src={imageSource}
                    className=" rounded-circle"
                    alt={name}
                    style={{ maxHeight: '80px', minHeight: '80px', maxWidth: '80px', minWidth: '80px' }}

                />
            </div>
            <div className=" col-md-6 text-justify">
                <h2 style={{
                    maxHeight: '58px',
                    minHeight: '45px',
                    padding: '0px',
                    fontWeight: 700,
                    overflow: 'hidden',
                    marginBottom: '0px',
                    textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                }}>{name}</h2>
                <p className="card-text" style={{
                    overflow: 'hidden',
                    width: '100%',
                    textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'
                }}>{title}</p>

            </div>
            <div className='col-md-4 text-center'>
                <a className="btn text-white mt-2" href={lattesLink} style={{ background: 'rgb(9, 118, 180)' }} target="_blank" rel="noopener noreferrer">
                    <i className="ai ai-lattes ai-fw ai-1x"></i></a>
                <a className="btn text-white mt-2" href={linkedinLink} style={{ background: '#55acee' }} target="_blank" rel="noopener noreferrer">
                    <i className="icon text-white ion-social-linkedin"></i></a>
                <a className="btn text-white mt-2" href={githubLink} style={{ background: '#040404' }} target="_blank" rel="noopener noreferrer">
                    <i className="ion-social-github"></i></a>
            </div>
        </div>
        </div>

    );
};

export default TeamCard;
