import React from 'react';
import { TitleL3 } from '../../../../../../../../../../../components';
import { Divider, Image } from 'antd';
import DocumentosSoftwareAnaliseFacialAugmentedReality from './DocumentosSoftwareAnaliseFacialAugmentedReality';
import DocumentosSoftwareAnaliseFacialAnalisesFrontais from './DocumentosSoftwareAnaliseFacialAnalisesFrontais';
import DocumentosSoftwareAnaliseFacialAnalisesLaterais from './DocumentosSoftwareAnaliseFacialAnalisesLaterais';
import DocumentosSoftwareAnaliseFacialAnalisesDoSorriso from './DocumentosSoftwareAnaliseFacialAnalisesDoSorriso';
import DocumentosSoftwareAnaliseFacialRefTeorica from './DocumentosSoftwareAnaliseFacialRefTeorica';

export const DocumentosSoftwareAnaliseFacial = () => {
  return (
    <section>
      <div className='col-md-12 text-justify'>
        {/* Adicionando o ID para ancoragem */}

        <section>
          <TitleL3 id="DocumentosSoftwareAnaliseFacialdescricao">Descrição</TitleL3>
          <p>
            O projeto de <strong>Análise Facial</strong> da <strong>THOT I.A. CORP.</strong> é uma solução avançada que utiliza inteligência artificial para detectar e analisar características faciais.
            Este sistema permite a extração de pontos de referência faciais, avaliação e medidas de características estéticas,
            além de sugestões personalizadas para harmonização facial.
          </p>
          <p>
            À nossa solução inovadora de Análise Facial com I.A. também usa Realidade Aumentada (AR),
            foi desenvolvida para fornecer uma avaliação detalhada e sugestões de harmonização baseadas em características e medidas reais.
          </p>
        </section>
        <TitleL3 id='DocumentosSoftwareAnaliseFacialRegrasGeraisdasImagens' className='font-weight-bold mt-3'> Regras Gerais das Imagens</TitleL3>
        <ul className='ml-3'>
          <li >
            <strong>✅ Identificação Fácil:</strong> Use uma foto com as mesmas regras de um 3/4 de identidade,
            o rosto centralizado em um fundo homogêneo que permita que as pessoas o identifiquem facilmente.
            Evite maquiagem, óculos, chapéus ou outros artefatos que possam obscurecer seu rosto
          </li>
          <li >
            <strong>✅ Enquadramento Adequado:</strong> Certifique-se de que sua foto esteja bem enquadrada, com seu rosto centralizado
          </li>
          <li >
            <strong>✅ Fundo Homogêneo:</strong> Escolha um local que contenha um fundo homogêneo
          </li>
          <li >
            <strong>✅ Iluminação Natural:</strong> Escolha uma foto tirada em boa iluminação natural, pois isso realça sua aparência
          </li>
          <li >
            <strong>✅ Resolução Adequada:</strong> Use uma foto de alta resolução para garantir que sua imagem seja nítida e clara
          </li>
        </ul>

        <TitleL3 id="material-visual">Material visual</TitleL3>




        <div className='col-md-2'>Story</div>
        <div className='row text-center'>

          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p1.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>1</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p2.png')} alt="Exemplo 5" width={50} height={70} />
            <br /><small>2</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p3.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>3</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p4.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>4</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p5.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>5</small>
          </div>
        </div>
        <div className='col-md-2'>Post</div>
        <div className='row text-center'>

          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf1.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>1</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf2.png')} alt="Exemplo 5" width={50} height={50} />
            <br /><small>2</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf3.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>3</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf4.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>4</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf5.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>5</small>
          </div>  </div>

        <section>
          <TitleL3 id="tabela-de-conteudos">Tabela de Conteúdos</TitleL3>
          <ul className='text-primary text-justify'>
            <li><a href="#analise-facial">Análise Facial com I.A.</a></li>
            <ul className='ml-1 text-primary text-justify'>
              <li><a href="#DocumentosSoftwareAnaliseFacialdescricao">Descrição</a></li>
              <li><a href="#DocumentosSoftwareAnaliseFacialRegrasGeraisdasImagens">Regras Gerais das Imagens</a></li>
              <li><a href="#material-visual">Material Visual</a></li>
              <li><a href="#tabela-de-conteudos">Tabela de Conteúdos</a></li>
              <li><a href="#DocumentosSoftwareAnaliseFacialRecursos">Recursos</a>
                <ul className='ml-2'>
                  <li><a href="#realidade-aumentada-ar">Realidade Aumentada (AR)</a></li>
                  <li><a href="#analises-frontais">Análises Frontais</a></li>
                  <li><a href="#analises-laterais">Análises Laterais</a></li>
                  <li><a href="#analises-do-sorriso">Análises do Sorriso</a></li>
                  <li><a href="#ref-teorica">Ref. Teórica</a></li>
                </ul>
              </li>
              <li><a href="#como-usar">Como Usar</a></li>
              <li><a href="#exemplos-de-resultados">Exemplos de Resultados</a>
                <ul>
                  <li><a href="#exemplo-analise-facial-laudo">Exemplo: Análise Facial Laudo</a></li>
                </ul>
              </li>
              <li><a href="#contribuicoes">Contribuições</a></li>
              <li><a href="#licenca">Licença</a></li>
              <li><a href="#contato">Contato</a></li>
            </ul>
          </ul>
        </section>

        <section>
          <TitleL3 id="DocumentosSoftwareAnaliseFacialRecursos">Recursos</TitleL3>
          <DocumentosSoftwareAnaliseFacialAugmentedReality />
          <Divider />
          <DocumentosSoftwareAnaliseFacialAnalisesFrontais />
          <Divider />
          <DocumentosSoftwareAnaliseFacialAnalisesLaterais />
          <Divider />
          <DocumentosSoftwareAnaliseFacialAnalisesDoSorriso />
          <Divider />
          <DocumentosSoftwareAnaliseFacialRefTeorica />
          <Divider />

        </section>

        <section>
          <TitleL3 id="como-usar">Como usar</TitleL3>
          <ol>
            <li>
              <strong>Submeta uma imagem</strong>: Envie uma imagem clara do seu rosto nos formatos JPG ou PNG.
            </li>
            <li>
              <strong>Aguarde a análise</strong>: Visualize os resultados.
            </li>
            <li>
              <strong>Interaja com a Análise</strong>: <a href="http://localhost:8484/analisar">Visualizar Análise</a>
            </li>
          </ol>
        </section>



        <footer>
          <TitleL3 id="contribuicoes">Contribuições</TitleL3>
          <p>Contribuições são bem-vindas! Sinta-se à vontade para abrir participar do nosso grupo de profissionais e dar sugestões </p>

          <TitleL3 id="licenca">Licença</TitleL3>
          <div style={{ backgroundColor: '#fff3cd', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
            <strong>⚠️ IMPORTANTE:</strong> Leia antes de começar
            <p>Este projeto é privado para usar como API no seu sistema chame a equipe de suporte</p>
          </div>
          <TitleL3 id="contato">Contato</TitleL3>
          <ul>
      <li>
        <a className='text-primary' href="https://discord.gg/Ugpz8fD45v" target="_blank" rel="noopener noreferrer">
          Discordy
        </a>
      </li>
      <li>
        <a className='text-primary' href="https://chat.whatsapp.com/L5A95MXz8St2oabZj5AesC" target="_blank" rel="noopener noreferrer">
          WhatsApp
        </a>
      </li>
    </ul>
        </footer>
      </div >
    </section >
  );
};

export default DocumentosSoftwareAnaliseFacial;
