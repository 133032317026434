import React, { useState, useRef } from 'react';
import "./styles/Carousel3D.css";
import { Image } from 'antd';

export function Carousel3D({ images }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const carouselRef = useRef();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const cellCount = Math.min(images.length, 9); // Limitar o número de imagens a 9

  if (!images) {
    return null;
  }

  function rotateCarousel() {
    const angle = 360 / cellCount * selectedIndex * -1;
    carouselRef.current.style.transform = `translateZ(-288px) rotateY(${angle}deg)`;
  }

  function handleMouseDown(e) {
    setIsMouseDown(true);
    setStartX(e.clientX);
  }

  function handleMouseUp() {
    setIsMouseDown(false);
  }

  function handleMouseMove(e) {
    if (!isMouseDown) {
      return;
    }
    const endX = e.clientX;
    const difference = startX - endX;
    let newIndex = selectedIndex;
    if (difference > 0) {
      newIndex = (selectedIndex + 1) % cellCount; // Garantir que o índice esteja dentro dos limites
    } else {
      newIndex = (selectedIndex - 1 + cellCount) % cellCount; // Garantir que o índice esteja dentro dos limites
    }
    setSelectedIndex(newIndex);
    setStartX(endX);
    rotateCarousel();
  }

  function handleNext() {
    setSelectedIndex((selectedIndex + 1) % cellCount);
    rotateCarousel();
  }

  function handlePrev() {
    setSelectedIndex((selectedIndex - 1 + cellCount) % cellCount);
    rotateCarousel();
  }

  return (
    <>
      <div className="scene"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        draggable={false}
      >
        <div className="carousel3D col-md-12" draggable={false} ref={carouselRef}>
          {images.slice(0, 9).map((image, index) => (
            <div className="carousel3D__cell active rounded" key={index} draggable={false}>
              <Image draggable={false} src={image.src} style={{ width: '250px', height: '230px' }} alt={image.alt} className={index === selectedIndex ? 'w-100 rounded' : 'w-100 rounded'} />
     
            </div>
          ))}
        </div>
        <div className='input-group d-flex mx-auto w-100' >
         
        </div>
        <div className='container'style={{ paddingTop: "120%" }}>
            <div className='row input-group-append'>
              <button onClick={handlePrev} className='btn btn-secondary mr-2 ml-2'>👈</button>
              <button onClick={handleNext} className='btn btn-secondary mr-2 ml-2'>👉</button>
            </div>
          </div>
      </div>
    </>
  );
}

export default Carousel3D;
