import React from 'react';
import CompatibilidadeAtendimentoServicos from './CompatibilidadeAtendimentoServicos';
import { ButtonCadastrar, MaisInfoMenosInfo, TitleL1, TitleL2 } from '../../../../../../../components';
import GerenciamentoAvancadoInfo from '../info/GerenciamentoAvancadoInfo';
export const GerenciamentoAvancado = () => {
    return (<>
        <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
            <p className='text-center'>  <img
                src={require('../assets/SolucaoGerenciamentoAvancado.png')}
                alt="Liberdade Financeira"
                style={{ maxHeight: '280px', maxWidth: '450px' }}
                className='col-md-12'
            />
            </p>
            <TitleL1>Gerenciamento</TitleL1>
            <h5 className='text-justify '>Sistema Único de Gerenciamento Avançado. Economize
                e otimize tempo e recursos mesmo entando no centro de todos os seus negócios. Rede Social Profissional autoescalonável
                e autoexplicativa onde você poderá contar com os seguintes recursos imbutidos:
            </h5>
            <div className="d-flex flex-wrap justify-content-center ">
                <div className="col-md-3 mt-5 mb-2 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>ERP </TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'><strong>ERP:</strong> Esse sistema de gerenciamento é dinâmico, pode ser adaptado
                        às suas necessidades específicas de forma automática.
                        Nossa solução é altamente flexível e pode ser ajustada para atender os seus requisitos exclusivos
                    </li>
                </div>
                <div className="col-md-3 mt-5 mb-2 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',

                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>CRM</TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'><strong>CRM Avançado:</strong> Nosso sistema inclui um CRM avançado, que ajuda a gerenciar relacionamentos com clientes de maneira eficaz. Ele oferece recursos de automação, análise de dados, recorrência e muito mais</li>
                </div>
                <div className="col-md-3 mt-5 mb-2 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',

                    alignItems: 'center',
                    flexDirection: 'column',

                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>E-commerce </TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'>
                        <strong>E-commerce Dropshipping: </strong>
                        Venda seus produtos e produtos da plataforma no modelo dropshipping.
                        Programe vendas recorrentes para os clientes, prospecte clientes, prospere com auxilio desse recurso
                    </li>
                </div>
                <div className="col-md-3 mt-5 mb-2 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',

                    alignItems: 'center',
                    flexDirection: 'column',

                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>Agenda </TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'><strong>Agenda Dinâmica Interativa:</strong> Configure sua agenda com poucos cliques e deixe que os usuários marquem seus horários.
                        Isso melhora a eficiência operacional o controle mútou da agenda usuário / profissional
                    </li>
                </div>
                <div className="col-md-3 mt-5 mb-2 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>Dashboard</TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'>
                        <strong>Dashboard Dinânimco e Integrável: </strong>
                        Nosso sistema inclui um dashboard que se integra automaticamente a outros bancos de dados,
                        proporcionando uma visão unificada das informações essenciais para o seu negócio
                    </li>
                </div>
                <div className="col-md-3 mt-5 mb-2 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>I.A. no code</TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'>
                        <strong>Meta Criação de I.A. com Dois Cliques: </strong>
                        Meta Criações e Treinamento de Inteligência Artificial (I.A.): Facilitamos a
                        criação de soluções de I.A. personalizadas com apenas dois cliques.
                        Isso melhora a eficiência operacional e fornece insights valiosos
                    </li>
                </div>
                <div className="col-md-3 mt-5 mb-5 border" style={{
                    padding: '5px',
                    marginLeft: '35px',
                    marginRight: "35px",
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <TitleL2>White Label</TitleL2>
                    <li className='text-justify ml-3 mr-3 mt-3 mb-4'>
                        <strong>White Label: </strong>
                        Oferecemos todas as opção anteriores  passíveis
                        de personalização com a marca da sua empresa (White Label). Isso permite que você promova sua própria
                        identidade corporativa junto com nossos recursos
                    </li>
                </div>
            </div>
            <div className='py-4 text-center'>
                <MaisInfoMenosInfo text={<><GerenciamentoAvancadoInfo /></>} />
            </div>
            <br /> <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <CompatibilidadeAtendimentoServicos />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <ButtonCadastrar />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </section>
    </>);
}
export default GerenciamentoAvancado;