import React, { useState, useEffect } from "react";

export function MaisInfoMenosInfo(props) {
  const [openMaisInfoMenosInfo, setOpenMaisInfoMenosInfo] = useState(false);
  const [highlight, setHighlight] = useState(false);

  const MostrarMaisInfo = () => {
    setOpenMaisInfoMenosInfo(!openMaisInfoMenosInfo);
  };

  // Usando useEffect para monitorar a prop 'destacar'
  useEffect(() => {
    if (props.destacar) {
      setHighlight(true);
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 8000); // Duração do destaque

      return () => clearTimeout(timer); // Limpa o timer quando o componente é desmontado ou quando a prop muda
    } else {
      setHighlight(false); // Reseta o estado de highlight se a prop não for verdadeira
    }
  }, [props.destacar]); // Roda quando 'destacar' muda

  return (
    <div className='text-center font-weight-bold ml-2 mr-2 py-3 mt-2'>
      <a
        className={`text-${highlight || openMaisInfoMenosInfo ? 'danger' : 'primary'} ${highlight ? 'highlighted' : ''}`}
        href={props.centralC}
        style={{ cursor: 'pointer' }}
        onClick={MostrarMaisInfo}
      >
        <i className="fa fa-info-circle"></i>  {openMaisInfoMenosInfo ? 'Menos info ' : 'Mais info '}

      </a>

      {openMaisInfoMenosInfo && (
        <div className='text-justify mt-2'>
          <div id={props.centralC}>
            {props.text}
          </div>
          <div className="text-center mt-5">
            <a
              className={`text-${highlight || openMaisInfoMenosInfo ? 'danger' : 'primary'} ${highlight ? 'highlighted' : ''}`}
              href={props.centralC}
              onClick={MostrarMaisInfo}
              style={{ cursor: 'pointer' }}
            >
              <i className="fa fa-info-circle"></i> {openMaisInfoMenosInfo ? 'Menos info ' : 'Mais info '}

            </a>
          </div>
        </div>
      )}

      <style>{`
        .highlighted {
          border: 2px solid red;
          border-radius: 5px;
          padding: 2px;
          animation: blink 1s infinite; /* Efeito de piscar */
        }

        @keyframes blink {
          0% { opacity: 1; }
          50% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}</style>
    </div>
  );
}

export default MaisInfoMenosInfo;
