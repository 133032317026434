import React from 'react';

const ProspeccaoClientesTelefoneInfo = () => {
  return (
    <div className="prospeccao-clientes-telefone-info">
      <h5>Instruções para Prospecção via Telefone</h5>
      <p>Preencha o formulário abaixo para prospecção de clientes via telefone.</p>
      <ul>
        <li>Selecione o tipo de comunicação que deseja utilizar (WhatsApp, Mensagem ou Ambos).</li>
        <li>No campo "Números de telefone (separados por vírgula)", insira os números de telefone dos destinatários separados por vírgula.</li>
        <li>No campo "Mensagem", escreva a mensagem que deseja enviar aos clientes.</li>
        <li>Clique no botão "Enviar" para iniciar o processo de prospecção via telefone.</li>
      </ul>
      <p>Os resultados do processo serão exibidos após o envio das mensagens.</p>
    </div>
  );
};

export default ProspeccaoClientesTelefoneInfo;
