import { ButtonCadastrar, TitleL1, TitleL2 } from '../../../../../../components';
import { Divider } from 'antd';
const ArticleSubmissionRequirements = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className={`py-5 rounded align-items-center  col-md-12  text-justify`}>
                <p className='text-center'> <i className="fa-researchgate fab text-center"> THOT I.A. CORP.</i><br />
                </p>
                <TitleL1>
                    Como fazer publicações
                </TitleL1>
                <h5>Se você está interessado em compartilhar seu conhecimento e contribuir para a comunidade através da nossa plataforma, estamos ansiosos para receber seus artigos. Aqui estão os requisitos resumidos e depois detalhados que garantirão que seu conteúdo seja bem recebido e apreciado:</h5>

                <div className="d-flex flex-wrap justify-content-center border rounded mt-5 py-2">
                    <div className='col-md-12'>
                        <TitleL2>Artigos Requisitos</TitleL2>

                    </div>
                    <Divider />
                    <div className='col-md-6'>
                        <TitleL2 id="originality">Originalidade e Qualidade</TitleL2>
                        <h5>Valorizamos a <strong>originalidade</strong> dos artigos submetidos. Buscamos conteúdos que apresentem ideias e perspectivas únicas, contribuindo para a expansão do conhecimento na área.
                            Temos uma Política Anti-Plágio forte combatemos veemente o plágio para garantir que todos os artigos submetidos sejam verificados quanto à originalidade.
                            A comissão editorial adota que abordagens originais também têm mais probabilidade de serem bem recebidos por nossa comunidade de leitores</h5>
                        <h5>Além disso os artigos devem passar por um processo rigoroso
                            de revisão por pares, onde especialistas no campo
                            avaliam a qualidade, precisão e validade dos conteúdos submetidos.
                            Os artigos publicados devem ser de alta qualidade, com contribuições significativas para o campo de conhecimento
                        </h5>
                    </div>
                    <div className='col-md-6'>
                        <TitleL2 id="depth">Profundidade de Conteúdo</TitleL2>
                        <h5>Incentivamos a profundidade em suas explanações. Artigos que vão além de superficialidades e mergulham profundamente
                            em um tópico são altamente valorizados. Ao explorar detalhes, oferecer exemplos concretos e fornecer análises aprofundadas
                            , você enriquecerá a experiência de aprendizado de nossos leitores</h5>

                        <TitleL2 id="scientific">Embasamento Científico</TitleL2>
                        <h5>Se o seu artigo se basear em pesquisa, dados ou informações técnicas, é
                            fundamental fornecer um embasamento científico sólido. Referências a fontes confiáveis,
                            estudos relevantes e dados verificáveis ajudam a fortalecer suas argumentações e aumentar a credibilidade do seu conteúdo</h5>
                    </div>
                    <Divider />
                    <div className='col-md-6 mt-3'>
                        <TitleL2 id="contribution">Contribuição Significativa</TitleL2>
                        <h5>Buscamos artigos que tragam contribuições significativas para o conhecimento existente. Ao compartilhar
                            novas perspectivas, insights inovadores ou abordagens práticas, você enriquece a comunidade acadêmica e profissional.
                            Certifique-se de que seu artigo realmente agregue valor e marque uma diferença</h5>

                    </div>
                    <div className='col-md-6 mt-3'>
                        <TitleL2 id="clarity">Clareza e Coerência</TitleL2>
                        <h5>A clareza é fundamental para uma comunicação eficaz. Certifique-se de que seu artigo
                            seja escrito de maneira clara e organizada. Estruture suas ideias de forma lógica, use parágrafos bem elaborados
                            e evite jargões excessivamente técnicos, facilitando assim a compreensão do seu público</h5>
                    </div>
                    <div className='col-md-6'>
                        <TitleL2 id="format">Formato e Estilo</TitleL2>
                        <h5>Mantenha um estilo de escrita profissional e adequado ao público-alvo do seu artigo. Utilize uma linguagem acessível
                            e evite ambiguidades. Além disso, siga uma formatação consistente, incluindo títulos, subtítulos, parágrafos e listas
                            quando apropriado, para garantir uma leitura fluida e agradável</h5>
                    </div>
                    <div className='col-md-6'>
                        <TitleL2 id="images">Imagens e Ilustrações</TitleL2>
                        <h5>Se relevante, o uso de imagens, gráficos ou ilustrações pode enriquecer seu artigo,
                            tornando-o mais visualmente atrativo e compreensível. Certifique-se de que esses elementos
                            estejam em alta resolução e sejam relevantes para o conteúdo que você está compartilhando</h5>
                    </div>
                    <div className='col-md-6'>
                        <TitleL2 id="extension">Extensão Adequada</TitleL2>
                        <h5>Embora não haja um limite estrito para o tamanho do artigo, recomendamos uma extensão que
                            permita uma exploração completa do tema. Geralmente, artigos entre 1000 e 3000 palavras
                            são adequados para oferecer informações abrangentes sem sobrecarregar os leitores</h5>
                    </div>
                    <div className='col-md-6'>
                        <TitleL2 id="review">Processo de Revisão</TitleL2>
                        <h5>Após o envio, seu artigo passará por uma avaliação rigorosa por parte de nossa equipe
                            de revisores. Eles avaliarão o conteúdo, a formatação, a precisão das informações e a aderência
                            aos requisitos mencionados. O processo de revisão garante que apenas conteúdos de alta qualidade sejam publicados</h5>
                    </div>
                    <Divider />
                    <div className='col-md-12'>
                        <TitleL2 id="consent">Consentimento de Uso</TitleL2>
                        <h5>Ao submeter um artigo, você concorda que a THOT. I.A. CORP. terá o direito de utilizar e distribuir
                            o conteúdo em nossa plataforma. Isso inclui a publicação, promoção e compartilhamento do seu artigo.
                            Mantemos os devidos créditos ao autor e garantimos que sua contribuição seja valorizada</h5>
                    </div>
                </div>
            </div>
            <ButtonCadastrar/>
        </section>
    </>);
};

export default ArticleSubmissionRequirements;
