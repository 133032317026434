import React from 'react';
import { BioterioForteParcerias } from '../../../Produtos/partials/layout/vender/PreLancamento/BioterioForteParcerias'
import video from '../assets/videos/DesenvolvimentoBioterioForte.mp4'
import { TitleL1 } from '../../../../../components';
export const EquipamentosPesquisasAnimais = () => {
    return (<>
        <section className='ml-3 mr-3'style={{ minHeight: '95dvh' }}>

            <div className="mx-auto rounded d-flex ">
                <video className="w-100 rounded" controls >
                    <source src={video} type="video/mp4" alt='Parceria para treinamento de software de visão computacional' />
                </video>
            </div>
            <TitleL1
            >
                Termografia Veterinária
            </TitleL1>
            <h5 className='text-justify'>
                Pesquisas farmacêuticas são comumente testadas em roedores.
                Esses animais são mantidos em caixas (moradia) geralmente sem recursos tecnológicos.
                O monitoramento contínuo com auxílio de diferentes sensores e opções de automatização pode,
                por exemplo, detectar precocemente a ineficiência de medicamentos, economizando meses de pesquisa.
                Sendo assim, foi proposto o desenvolvimento deste sistema capaz de sensoriar, monitorar remotamente e agregar
                inteligência às caixas moradia
                <br />   <br />
                O protótipo funcional proposto trata-se de um hardware composto por uma placa de aquisição de dados,
                câmeras, 1 termográfica e outra de espectro visível, microfone, sensores de iluminação,
                umidade e temperatura. O protótipo funcional acompanha um software com soluções na área de visão computacional,
                como termografia acoplada e registro de informações.
                A placa de aquisição e o software de interface com o usuário já foram desenvolvidos e uma câmera termográfica
                com custo adequado à comercialização no protótipo funcional está sendo faturada para testes de compatibilidade.
                Já possuímos testes em ambiente laboratorial. A tecnologia envolvida no protótipo funcional é dominada pela equipe.

            </h5>
            <BioterioForteParcerias />
            <h5 className="text-center mt-5">
                <a className=" align-itens-center mx-auto  font-weight-bold"
                    target={'_blank'} rel={'noopener noreferrer'}
                    href={'https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20adiquirir%20o%20protótipo%20funcional%20do%20equipamento%Biotério%20Forte%20'}><i
                        className="ion-social-whatsapp  text-success fa-3x"></i> </a>
                <br /><br />   <br /><br />Compra uso e treinamento - entre em contato com a equipe no ícone acima
            </h5>

        </section >
    </>)
}