import React, { useState } from 'react';
export const LocalizacaoButton = (props) => {
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const handleToggle = () => {
    setIsLocationEnabled(!isLocationEnabled);
    props.onToggle(!isLocationEnabled);
  };
  return (
    <div className='d-flex col-md-12 mt-3'>
      <small >{props.text}</small>
      <input
        className='ml-2'
        id="toggle-location"
        type="checkbox"
        defaultChecked={isLocationEnabled}
        onChange={handleToggle}
      />
    </div>
  );
};
export default LocalizacaoButton;