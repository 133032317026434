import React, { useState } from 'react';

import { InputPadrao } from '../../../../components';

export const ThemeContextSecondary = ({ onChange }) => {
  const [colorSecondary, setColorSecondary] = useState('');

  const handleColorChangeSecondary= (event) => {
    const newColorSecondary = event.target.value;
    setColorSecondary(newColorSecondary);
    onChange(newColorSecondary);
  };

  return (
    <InputPadrao title={'Cor Secundária'}
      type="color"
      value={colorSecondary}
      onChange={handleColorChangeSecondary}
    />
  );
};

export default ThemeContextSecondary;
