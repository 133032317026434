import { Map } from './components/Map/Map'
import imageBIO from './BioterioForte/BioterioForteSF.png';
import imageINF from './BioterioForte/InfinityLifeHealthcareSF.png';
import { useState, useEffect, useCallback } from 'react';
import { BioterioFortePesquisas } from './BioterioForte/components/Pesquisas/BioterioFortePesquisas';
import { NavBreadcrumb, FullScreenImage, ButtonAPI, InputPadrao } from '../../../../components';

import {baseURL} from '../../../../services/api';

export const Index = () => {
  // state variables to store input values
  const [searchTerm, setSearchTerm] = useState('');
  const [newAnimalName, setNewAnimalName] = useState('');
  const [folders, setFolders] = useState([]);
  const [validationMessage, setValidationMessage] = useState('');
  const [filteredFolders, setFilteredFolders] = useState([]);
  const [selectedAnimal, setSelectedAnimal] = useState(null);
  const [isVisible, setIsVisible] = useState(null);
  // fetch existing folder list from server on component mount
  useEffect(() => {
    fetch(`${baseURL}searchPathBioterioForte`)
      .then(response => response.json())
      .then(data => setFolders(data.folders))
      .catch(error => console.error(error));
  }, []);
  // function to handle search term input change
  const handleSearchTermChange = async (event, folder) => {
    event.preventDefault();
    if (folder) {
      const message = await checkFolderExists(folder);
      if (message) {
        setValidationMessage(message);
        return;
      }
    }
    setSearchTerm(folder || '');
    setValidationMessage('');
    setSelectedAnimal(folder || null);
  };


  const checkFolderExists = async (folderName) => {
    try {
      const response = await fetch(`${baseURL}searchPathBioterioForte/${encodeURIComponent(folderName)}`)
      const data = await response.json();
      return data.exists ? `` : '';
    } catch (error) {
      console.error(error);
      return "Erro ao checar I.A.";
    }
  };
  // function to handle new animal name input change
  const handleNewAnimalNameChange = (event) => {
    setNewAnimalName(event.target.value);
  };
  // function to handle animal submission
  const handleNewAnimalSubmit = () => {
    setIsLoading(true)
    // create new folder in backend with newAnimalName as name
    fetch(`${baseURL}createFolderPathBioterioForte`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: newAnimalName }),

    })
      .then(response => {
        if (response.ok) {
          // if folder was created successfully, add it to the list of folders
          setFolders([...folders, newAnimalName]);
          // reset newAnimalName input value
          setNewAnimalName('');
          setIsLoading(false)
        } else {
          throw new Error('Erro ao criar I.A.');
        }
      })
      .catch(error => console.error(error));

  };

  // filter folders list based on search term
  const filterFolders = useCallback((foldersList, searchTerm) => {
    // filter folders list based on search term
    const filtered = foldersList.filter(folder => folder && folder.toLowerCase && folder.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''));
    setFilteredFolders(filtered);
  }, []);

  useEffect(() => {
    filterFolders(folders, searchTerm);
  }, [searchTerm, folders, filterFolders]);


  const [bioterioCollapsed, setBioterioCollapsed] = useState(false);
  const [identificarDoencasCollapsed, setIdentificarDoencasCollapsed] = useState(true);
  const [identificarDoencasCampoCollapsed, setIdentificarDoencasCampoCollapsed] = useState(true);
  const [realizarPesquisasCollapsed, setRealizarPesquisasCollapsed] = useState(true);
  const [realizarPesquisasCampoCollapsed, setRealizarPesquisasCampoCollapsed] = useState(true);

  const handleBioterioClick = () => {
    setBioterioCollapsed(!bioterioCollapsed);
    setIdentificarDoencasCollapsed(!identificarDoencasCollapsed);
    setRealizarPesquisasCollapsed(!realizarPesquisasCollapsed);
    setIdentificarDoencasCampoCollapsed(true);
  };
  const handleBioterioIdentificarDoencaClick = () => {
    setBioterioCollapsed(true);
    setIdentificarDoencasCollapsed(false);
    setRealizarPesquisasCollapsed(true);
    setIdentificarDoencasCampoCollapsed(false);
  };
  const handleBioterioPesquisasClick = () => {
    setBioterioCollapsed(true);
    setIdentificarDoencasCollapsed(true);
    setRealizarPesquisasCollapsed(false);
    setRealizarPesquisasCampoCollapsed(!realizarPesquisasCampoCollapsed);

  };
  const handleVoltar = () => {
    setBioterioCollapsed(!bioterioCollapsed);
    setIdentificarDoencasCollapsed(true);
    setRealizarPesquisasCollapsed(true);
    setIdentificarDoencasCampoCollapsed(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className=' w-100' >
        <NavBreadcrumb icon={'user-md fa'} text={'Meus Equipamentos'} />
        <div className="py-3">
          <div className="text-center">
            <div className="container-fluid">
              <div className="row d-inline-flex justify-content-center w-100">
                <Map
                  text={"Biotério Forte"}
          
                  image={imageBIO}
                  onClick={handleBioterioClick}
                  propStyle={{
                    transform: bioterioCollapsed ? "scale(0.5)" : ""
                  }}
                  className={bioterioCollapsed ? "col-md-4  mt-3 " : "col-md-4  mt-3 "}

                />
                <Map
                  text={"Infifinity Life"}
                  image={imageINF}
                  onClick={handleBioterioClick}
                  className={bioterioCollapsed ? "collapse" : "col-md-4  mt-3"}
                />
                <Map
                  text={"Identificar Doenças"}
                                image={"../../../../Images/BioterioForte/OtherAnimals3.jpeg"}
                  onClick={handleBioterioIdentificarDoencaClick}
                  propStyle={{
                    transform: identificarDoencasCampoCollapsed ? "" : "scale(0.5)"
                  }}
                  className={identificarDoencasCollapsed ? "collapse" : "col-md-4  mt-3"}

                />
                <Map
                  text={"Realizar Pesquisas"}
             
                  image={'./universoSaude.jpg'}
                  onClick={handleBioterioPesquisasClick}
                  propStyle={{
                    transform: identificarDoencasCollapsed ? "scale(0.5)" : ""
                  }}
                  className={realizarPesquisasCollapsed ? "collapse" : "col-md-4  mt-3"}
                />
                {/* Search input */}
                <div className={identificarDoencasCampoCollapsed ? 'collapse' : 'col-md-12 d-flex'}>
                  <div className="col-md-6 text-center py-4">
                    <InputPadrao
                      title={'Procurar I.A. por nome dos animais'}
                      type="text"
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)} />
                    {/* List of filtered folders */}
                    <ul>
                      {filteredFolders.map(folder => (
                        <li className='fixed' key={folder} onClick={(event) => {
                          handleSearchTermChange(event, folder);
                          setSearchTerm(folder);
                          setIsVisible(true);
                        }}>{folder}</li>
                      ))}
                    </ul>
                    {validationMessage && <p className="text-dark font-weight-bold">{validationMessage}</p>}
                  </div>

                  {/* New animal input */}
                  <div className="col-md-6 text-center py-4">
                    <div className="form-group">
                      <InputPadrao
                        title={'Criar I.A. por nome dos animais'}
                        type="text"
                        id="newAnimalInput"
                        value={newAnimalName}
                        onChange={handleNewAnimalNameChange} />
                      <ButtonAPI
                        isLoading={isLoading}
                        onClick={handleNewAnimalSubmit} >
                        CRIAR
                      </ButtonAPI>
                    </div>
                  </div>
                </div>
              </div>
              <div className={realizarPesquisasCampoCollapsed ? 'collapse' : 'col-md-12'}>
                <BioterioFortePesquisas className={'col-md-12'} />
              </div>
              <div className={bioterioCollapsed ? 'col-md-12 d-flex justify-content-center mx-auto align-items-center mt-5' : 'collapse'}>
                <button
                  className="btn btn-primary btn-block w-50 mb-2 mt-3 mx-auto a-89 text-center"
                  onClick={handleVoltar} style={{ boxShadow: "inset 0px -6px 3px 5px rgba(0, 0, 0, 0.5)" }}>VOLTAR</button>
              </div>
            </div>
            <div style={{ display: selectedAnimal ? '' : 'collapse' }}>
              <FullScreenImage folder={searchTerm} setSelectedAnimal={setSelectedAnimal} isVisible={isVisible} setIsVisible={setIsVisible} />
            </div>
          </div>
        </div>
      </div >


    </>
  )
}