import { NavBreadcrumb, CardDirecionamento } from "../../../../../components";
export const Index = () => {
  return (<>
    <div className="w-100 ">
      <NavBreadcrumb text={"Minhas Consultas"} icon={"hand-paper-o fa"} />
      <div className="py-3 ">
        <div className="text-center">
          <div className="container-fluid">
            <div className="row d-inline-flex justify-content-center w-100">
              <CardDirecionamento
                text={"Marcar"}
                icon={"mt-3 fa fa-3x fa-calendar text-white"}
                href={"./buscar-paciente"}
              />
              <CardDirecionamento
                text={"Marcadas"}
                icon={"mt-3 fa fa-3x fa-location-arrow text-white"}
                href={"./consultas/marcadas"}
              />
              <CardDirecionamento
                text={"Finalizadas"}
                icon={"mt-3 fa fa-database fa-3x text-white"}
                href={"./consultas/finalizadas"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
