import React, { useState, useEffect, useContext, useRef } from 'react';
import { baseURL } from '../../../../../../../../services/api';
import { ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '../../../../../../../../components';
import { AuthContext } from '../../../../../../../../contexts/auth';
import CBOInfo from '../info/CBOInfo';
import { axiosHeadersToken } from '../../../../../../../../services/contexts';
import { buscarCBO } from '../../../../../../../../services';
export const CBO = ({ profissional_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [occupations, setOccupations] = useState([]);
  const [newCBO, setNewCBO] = useState(false);
  const [selectedOccupations, setSelectedOccupations] = useState([]);
  const [newOccupations, setNewOccupations] = useState([]);
  const [deletedOccupations, setDeletedOccupations] = useState([]);
  const [hasLoadedStoredCBOs, setHasLoadedStoredCBOs] = useState(false);
  const { getUserId } = useContext(AuthContext);
  const id = getUserId();
  const ulRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    buscarCBO(setOccupations, searchTerm, id);
  }, [searchTerm, id]);
  const handleAddOccupation = (occupation) => {
    setSelectedOccupations((prevOccupations) => [...prevOccupations, occupation]);
    setSearchTerm('');
    setNewCBO(true);
    setNewOccupations((prevNewOccupations) => [...prevNewOccupations, occupation]);
  };
  const handleDeleteSelectedOccupation = (occupationId) => {
    setNewCBO(true)
    setSelectedOccupations((prevOccupations) =>
      prevOccupations.filter((occupation) => occupation.id !== occupationId)
    );
    setDeletedOccupations((prevDeletedOccupations) => [
      ...prevDeletedOccupations,
      { id: occupationId },
    ]);
  };
  const handleSendOccupations = async (userId) => {
    setIsLoading(true);
    try {
      const dataToSend = {
        userId,
        selectedOccupations,
        newOccupations,
        deletedOccupations,
      };
      const response = await axiosHeadersToken().post(`${baseURL}post_user_cbo/${id}`, dataToSend);
      if (response.status === 200) {
        alert('Ocupações adicionadas com sucesso');
        setNewCBO(false)
        setNewOccupations([]); // Limpa as novas ocupações
        setDeletedOccupations([]); // Limpa as ocupações deletadas
      } else {
        alert('Erro ao adicionar ocupações:', response.statusText);
      }
    } catch (error) {
      alert('Erro ao enviar ocupações selecionadas:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const isSelected = (occupation) => {
    return selectedOccupations.some((selectedOccupation) => selectedOccupation.id === occupation.id);
  };
  const handleInputFocus = () => {
    ulRef.current.style.display = 'block';
  };
  const handleClickOutside = (event) => {
    if (!ulRef.current.contains(event.target) && event.target !== inputRef.current && event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON') {
      ulRef.current.style.display = 'none';
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const storedProfessionalCBO = JSON.parse(localStorage.getItem("professionalCBO"));
    if (storedProfessionalCBO && !hasLoadedStoredCBOs) {
      const newSelectedOccupations = Object.entries(storedProfessionalCBO).map(([id, titulo]) => ({
        id: id,
        titulo: titulo,
      }));
      setSelectedOccupations((prevOccupations) => [
        ...prevOccupations,
        ...newSelectedOccupations.filter((occupation) =>
          !prevOccupations.some((prevOccupation) => prevOccupation.id === occupation.id)
        )
      ]);
      setHasLoadedStoredCBOs(true);
    }
  }, [hasLoadedStoredCBOs]);
  return (<>
    <div className='mt-3'>
      <div className='py-3'>
        <InputPadraoTitle
          title='CBO'
          style={
            newCBO
              ? { backgroundColor: '#ffc107' }
              : null
          }
        />
        <input
          className="form-control text-center col-md-12 border border-dark"
          title='CBO'
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={handleInputFocus}
          ref={inputRef}
          placeholder="Nome da ocupação (profissão)"
        />
        <small>Classificação Brasileira de Ocupação - (CBO) - Procurar
        </small>
        <ul ref={ulRef} style={{ display: 'none' }}>
          {occupations.map((occupation, index) => (
            <li
              className={`col-md-12 mt-2 rounded d-flex mx-auto align-items-center justify-content-center ${index % 2 === 0 ? '' : 'bg-light'}`}
              key={occupation.id}
            >
              {occupation.titulo}{' '}
              {isSelected(occupation) ? (
                <button onClick={() => handleDeleteSelectedOccupation(occupation.id)}>
                  <i className="fa a fa-trash  text-danger  rounded ml-3 mr-3 align-right" aria-hidden="true">&nbsp;Excluir</i>
                </button>
              ) : (
                <button onClick={() => handleAddOccupation(occupation)}>
                  <i className="fa fa-plus text-primary rounded mr-3 align-right" aria-hidden="true">&nbsp; Adicionar</i>
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
      {selectedOccupations.length > 0 ? (<>
        <div className='col-md-12 py-3  border border-dark rounded'>
          <small>Ocupações {profissional_id ? "Cadastradas" : ""}</small>
          <div className='col-md-12'>
            {selectedOccupations.length > 0 ? (<ul>
              {selectedOccupations.map((occupation) => (
                <li className='col-md-12 font-weight-bold d-flex  align-items-center justify-content-center mt-1' key={occupation.id}>
                  {occupation.titulo}{' '}
                  <button onClick={() => handleDeleteSelectedOccupation(occupation.id)}>  <i className="fa fa-trash  text-danger  mb-2  align-right" aria-hidden="true">&nbsp; Excluir</i></button>
                </li>
              ))}
            </ul>
            ) : (
              <p className='bg-warning'>Nenhuma área selecionada.</p>
            )}
          </div>
        </div>
        <div className='col-md-12 text-center'>
          <MaisInfoMenosInfo text={<CBOInfo />}></MaisInfoMenosInfo>
        </div>
      </>) : (
        null
      )}
      {newCBO || deletedOccupations.length > 0 ? (
        <div className='mb-3'>
          <ButtonAPI disabled={isLoading} onClick={() => handleSendOccupations(id)}>
            {isLoading ? 'Enviando...' : `${profissional_id ? 'Salvar edição ' : 'Cadastrar info. '}`}
          </ButtonAPI>

        </div>) : (
        null
      )}
    </div>

  </>);
};
export default CBO;