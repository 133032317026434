import React from 'react';
import { Divider } from 'antd';
import { MdHome, MdRoomService, MdWidgets, MdHandshake, MdHistoryEdu, MdAddShoppingCart, MdAccountCircle, MdOutlinePersonAddAlt } from 'react-icons/md';
import { FaResearchgate } from 'react-icons/fa';
import '../styles/EcosystemMenu.css'; // Assumindo que você tem um arquivo CSS para estilos

export const EcosystemMenu = () => {
  return (
    <div className='EcosystemMenu-links col-md-6 align-items-left text-left'>
      <div className='col-md-12 text-center' style={{padding:'0px'}}>
        <small>Ecossistema (RSP) - [ I.A. Integrada ]</small>
        <Divider className='' style={{ margin: '0px', borderColor: 'white', borderWidth: '3px', marginBottom: '3px' }} />
      </div>
      <a href='../' className='EcosystemMenu-link'>
        <MdHome className='EcosystemMenu' />
        <span className='text-white'>Home</span>
      </a>
      <a href='../servicos' className='EcosystemMenu-link'>
        <MdRoomService className='EcosystemMenu' />
        <span className='text-white'>Serviços</span>
      </a>
      <a href='../produtos' className='EcosystemMenu-link'>
        <MdWidgets className='EcosystemMenu' />
        <span className='text-white'>Produtos</span>
      </a>
      <a href='../artigos' className='EcosystemMenu-link'>
        <FaResearchgate className='EcosystemMenu'  />
        <span className='text-white'>Artigos</span>
      </a>
      <a href='../parcerias' className='EcosystemMenu-link'>
        <MdHandshake className='EcosystemMenu' />
        <span className='text-white'>Parcerias</span>
      </a>
      <a href='../sobre' className='EcosystemMenu-link'>
        <MdHistoryEdu className='EcosystemMenu' />
        <span className='text-white'>Sobre</span>
      </a>
      <a href='../carrinho' className='EcosystemMenu-link'>
        <MdAddShoppingCart className='EcosystemMenu' />
        <span className='text-white'>Carrinho</span>
      </a>
      <a href='../login' className='EcosystemMenu-link'>
        <MdAccountCircle className='EcosystemMenu' />
        <span className='text-white'>Login</span>
      </a>
      <a href='../cadastrar' className='EcosystemMenu-link'>
        <MdOutlinePersonAddAlt className='EcosystemMenu' />
        <span className='text-white'>Cadastrar</span>
      </a>
    </div>
  );
};

export default EcosystemMenu;
