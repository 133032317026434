import { ControllerMedicamentos } from "./ControllerMedicamentos";
import { FooterUser, Nav, NavUserMain } from "../../../../components";
export const Medicamentos = () => {
    return (<>
     <Nav/>
        <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
            <NavUserMain />
            <section className="w-100">
                <ControllerMedicamentos />
                <FooterUser />
            </section>
        </div>
    </>)
}