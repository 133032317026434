import React, { useEffect, useState } from "react";
import "../styles/Emitente.css";

const IdentificacaoEmitente = ({ emitente, crm, crmUf, enderecoEmitente, cidade, uf, telefone, assinaturaMedico }) => {
  const [dataAtual, setDataAtual] = useState("");

  useEffect(() => {
    const getDataAtual = () => {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      const data = new Date().toLocaleDateString('pt-BR', options); 
      return data;
    };

    setDataAtual(getDataAtual());
  }, []);

  return (
    <div className="principal-emit">
      <div className="div-title1">
        <h3 className="title1">IDENTIFICAÇÃO DO EMITENTE</h3>
      </div>
      <div className="div-logo">
        <div className="div-content1">
          <p>NOME COMPLETO: {emitente}</p>
          <div className="div-crm">
            <p>CRM: {crm}</p>
            <p>UF: {crmUf}</p>
          </div>
          <p>ENDEREÇO COMPLETO: {enderecoEmitente}</p>
          <div className="div-city">
            <p>CIDADE: {cidade}</p>
            <p>UF: {uf}</p>
          </div>
          <div className="phone-data">
            <p>TELEFONE: {telefone}</p>
            <p>DATA: {dataAtual}</p> 
          </div>
        </div>
        <div className="div-title2">
          <p>ASSINATURA MÉDICO(A)</p>
        </div>
      </div>
    </div>
  );
};

export default IdentificacaoEmitente;
