
export const data = [
    ["Element", "Density", { role: "style" }],
    ["Javascript", 93.3, "gold"],
    ["CSS", 6.5, "#563d7c"],
    ["HTML", 0.2, "red"]
];


export const dataBackEnd = [
    ["Element", "Density", { role: "style" }],
    ["Javascript", 88.7, "gold"],  
    ["HTML", 5.7, "red"],
    ["Python", 5.6, "blue"],
];

