


export const MasterRenderArtigos = () => {
    return (
        <>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-12 text-center mt-2"><label id="titulo"></label></div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-left mt-3"><b>RESUMO</b></div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-justify mt-2"><label id="resumo"></label></div>
                </div>
                <div className="row">

                    <div className="col">
                        <div className="row">
                            <div className="col-md-12 text-left mt-3"><b>UNIDADE E DATA</b></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-justify mt-2"><label id="unidadeData"></label></div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-left mt-3"><b>AUTOR(ES)</b></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-justify mt-2"><label>...</label></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-left mt-3"><b>ÁREA</b></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-justify mt-2"><label id="areaCentralEspecifica"></label></div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-md-12 text-left mt-3"><b>ESPÉCIE</b></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-justify mt-2"><label id="reinoEspecie"></label></div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-left mt-3"><b>SUBSTÂNCIA</b></div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-justify mt-2"><label id="tipoNomeSubstancia">...</label></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mt-5 text-center  d-flex  justify-content-center boxHeader2">
                <h5 className="text-white mt-2 text-center">PESQUISA NA ÍNTEGRA</h5>
            </div>
            <div className="col-md-12 text-justify mt-5"><label>...</label></div>
            <div className="col-md-12 mt-5 boxHeader2 text-center  d-flex  justify-content-center">
                <h5 className="text-white mt-2 text-center">ANÁLISES REALIZADAS PELO EQUIPAMENTO BIOTÉRIO FORTE
                </h5>
            </div>
            <image alt='testando remover pelo certo' width="300" className="img-fluid d-block mx-auto mt-3"
                src="IMG/2_EIXO_CORRETO-removebg-preview-removebg-preview.png" />
            <div className=" col-md-12  text-center">
                <h5 className=" text-dark"><b>Bioterio Forte</b></h5>
                <h5 className=" text-dark"><b>Versão: Roedores Pequeno Porte - 1.0</b></h5>
                <h5 className=" text-dark"><b>Área destinada a verificação das pesquisas</b></h5>
                <h5 className=" text-dark"><b>Aquisição do equipamento e outras informações técnicas: </b></h5>
              
            </div>
            <div className="col-md-12 mt-5 boxFooter text-center  d-flex  justify-content-center bg-success">
                <h5 className="text-white mt-2 text-center">CONFIGURAÇÕES INICIAIS DO(S) EQUIPAMENTO(S)
                </h5>
            </div>
            <div className="container collapse" id="config_ini">
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h5>Demais informativos fornecidos pelo autor no formulário inicial de uso do equipamento
                        </h5>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12 text-center mt-5">
                                    <h5>Parametros da Pesquisa</h5>

                                </div>
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>N</th>
                                                <th>VARIÁVEIS</th>
                                                <th>CONFIG</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>5</td>
                                                <td>QUANTIDADE SUBSTÂNCIA</td>
                                                <td id="quantidadeSubstancia"></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>INICIO APLICAÇÃO SUBSTANCIA</td>
                                                <td id="horaInicioAplicacaoSubstancia"></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>INTERVALO REAPLICAÇÃO SUBSTÂNCIA</td>
                                                <td id="tempoAplicacaoSubstancia"></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>AJUSTE ALIM.</td>
                                                <td id="AJValue">.....</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>CONT. AMBIENTAL</td>
                                                <td id="CTAMBValue">.....</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>FUSO HORARIO</td>
                                                <td id="fusoHorario"></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-5"><button href='./' className="btn btn-primary btn-lg btn-block disabled"
                                >SELECIONE O GRUPO</button></div>
                            <div className="col-md-12 text-center">
                                <div className="form-group">
                                    <select className="custom-select text-center" name="AJ" id="selectGrupo">
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-dark">
                                <thead>
                                    <tr>
                                        <th>N</th>
                                        <th>VARIÁVEIS</th>
                                        <th>CONFIG</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Dias Adaptação</td>
                                        <td id="diasAdaptacaoPrimaria"></td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>TEMPERATURA DIA</td>
                                        <td id="temperaturaDia">.....</td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>INICIO HORA LUZ DIA</td>
                                        <td id="horaInicialLuzDia">.....</td>
                                    </tr>
                                    <tr>
                                        <td>19</td>
                                        <td>LUMENS DIA</td>
                                        <td id="potenciaLuzDia"></td>
                                    </tr>
                                    <tr>
                                        <td>20</td>
                                        <td>ENREQUECIMENTO RGB DIA</td>
                                        <td id="ENRQRBGDValue">.....</td>
                                    </tr>
                                    <tr>
                                        <td>18</td>
                                        <td>TEMPERATURA NOITE</td>
                                        <td id="temperaturaNoite">.....</td>
                                    </tr>
                                    <tr>
                                        <td>21</td>
                                        <td>INICIO HORA LUZ NOITE</td>
                                        <td id="horaInicialLuzNoite">.....</td>
                                    </tr>
                                    <tr>
                                        <td>22</td>
                                        <td>LUMENS NOITE</td>
                                        <td id="potenciaLuzNoite">.....</td>
                                    </tr>
                                    <tr>
                                        <td>23</td>
                                        <td>ENREQUECIMENTO RGB NOITE</td>
                                        <td id="ENRQRBGNValue">.....</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 text-center mt-2"><button className="btn btn-success rounded-circle" data-toggle="collapse"
                href="./"  aria-expanded="false" aria-controls="config_ini">VER</button></div>
            <div className="col-md-12 mt-2 boxHeader text-center  d-flex  justify-content-center bg-success"></div>
            <div className="col-md-12 mt-5 mb-5 text-center  d-flex  justify-content-center boxFooter">
                <h5 className="text-white mt-2 text-center">STREAM AO VIVO</h5>
            </div>
            <div className='d-flex col-md-12'>
                <div className="col-md-4 align-items-center justify-content-center">
                    <div className="col-md-12 mt-5 mb-2 text-center  d-flex  justify-content-center boxFooter">
                        <h5 className="text-white mt-2 text-center">G1</h5>
                    </div>
                    <div className="embed-responsive embed-responsive-16by9">
                        <video src="https://static.pingendo.com/video-placeholder.mp4" className="embed-responsive-item"
                            controls="controls"> Your browser does not support
                            HTML5 video. </video>
                    </div>
                    <div id="chart_div" className="col-md-4 mt-2"></div>
                    <div className="col-md-12 mt-2 mb-5 text-center  d-flex  justify-content-center boxHeader"></div>
                </div>
                <div className="col-md-4 align-items-center justify-content-center">
                    <div className="col-md-12 mt-5 mb-2 text-center  d-flex  justify-content-center boxFooter">
                        <h5 className="text-white mt-2 text-center">G2</h5>
                    </div>
                    <div className="embed-responsive embed-responsive-16by9">
                        <video src="https://static.pingendo.com/video-placeholder.mp4" className="embed-responsive-item"
                            controls="controls"> Your browser does not support
                            HTML5 video. </video>
                    </div>

                    <div id="chart_div2" className="col-md-4 mt-2"></div>
                    <div className="col-md-12 mt-2 mb-5 text-center  d-flex  justify-content-center boxHeader"></div>
                </div>
                <div className="col-md-4 align-items-center justify-content-center">
                    <div className="col-md-12 mt-5 mb-2 text-center  d-flex  justify-content-center boxFooter">
                        <h5 className="text-white mt-2 text-center">G3</h5>
                    </div>
                    <div className="embed-responsive embed-responsive-16by9">
                        <video src="https://static.pingendo.com/video-placeholder.mp4" className="embed-responsive-item"
                            controls="controls"> Your browser does not support
                            HTML5 video. </video>
                    </div>

                    <div id="chart_div3" className="col-md-4 mt-2"></div>
                    <div className="col-md-12 mt-2 mb-5 text-center  d-flex  justify-content-center boxHeader"></div>
                </div>
            </div>
            <h5 className="text-center">Informativos ao pesquisador <i className="fa fa-eye"></i> </h5>
            <div className="col-md-12 mt-5 mb-5 text-center  d-flex  justify-content-center boxHeader"></div>
            <div className="col-md-12 mt-5 text-center  d-flex  justify-content-center boxFooter bg-success">
                <h5 className="text-white mt-2 text-center">ANÁLISES LONGITUDIONAIS</h5>
            </div>

            <div className="col-md-12 mt-5 text-center  d-flex  justify-content-center boxHeader2">
                <h5 className="text-white mt-2 text-center">ANÁLISES DE DIFERENCIAL TÉRMICO</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row text-center">
                        <div className="col-md-4 mt-3">
                            <div className="table-responsive">
                                <table className="table table-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th className="text-center">G1</th>
                                            <th className="text-center">DIA</th>
                                            <th className="text-center">NOITE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>MÁXIMO</td>
                                            <td className="text-center" id="TP1TERMCABDValue">...</td>
                                            <td className="text-center" id="TP1TERMCABNValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>MÍNIMO</td>
                                            <td className="text-center" id="TP1TERMCORPDValue">...</td>
                                            <td className="text-center" id="TP1TERMCORPNValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>MÉDIA</td>
                                            <td className="text-center" id="TP1TERMCAUDADValue">...</td>
                                            <td className="text-center" id="TP1TERMCAUDANValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>TOTAL</td>
                                            <td className="text-center" id="">...</td>
                                            <td className="text-center" id="">...</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="table-responsive">
                                <table className="table table-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>G2</th>
                                            <th className="text-center">DIA</th>
                                            <th className="text-center">NOITE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>MÁXIMO</td>
                                            <td className="text-center" id="TP2TERMCABDValue">...</td>
                                            <td className="text-center" id="TP2TERMCABNValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>MÍNIMO</td>
                                            <td className="text-center" id="TP2TERMCORPDValue">...</td>
                                            <td className="text-center" id="TP2TERMCORPNValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>MÉDIA</td>
                                            <td className="text-center" id="TP2TERMCAUDADValue">...</td>
                                            <td className="text-center" id="TP2TERMCAUDANValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>TOTAL</td>
                                            <td className="text-center" id="">...</td>
                                            <td className="text-center" id="">...</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="table-responsive">
                                <table className="table table-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>G3</th>
                                            <th className="text-center">DIA</th>
                                            <th className="text-center">NOITE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>MÁXIMO</td>
                                            <td className="text-center" id="TP3TERMCABDValue">...</td>
                                            <td className="text-center" id="TP3TERMCABNValue">...</td>
                                        </tr>
                                        <td>2</td>
                                        <td>MÍNIMO</td>
                                        <td className="text-center" id="TP3TERMCORPDValue">...</td>
                                        <td className="text-center" id="TP3TERMCORPNValue">...</td>
                                        <tr>
                                            <td>3</td>
                                            <td>MÉDIA</td>
                                            <td className="text-center" id="TP3TERMCAUDADValue">...</td>
                                            <td className="text-center" id="TP3TERMCAUDANValue">...</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>TOTAL</td>
                                            <td className="text-center" id="">...</td>
                                            <td className="text-center" id="">...</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center mt-5">
                            <h5 className="text-center w-50 mx-auto bg-primary">MOVIMENTOS</h5>
                        </div>
                        <div className="col-md-12 text-center mt-5">
                            <b>TOTAL</b>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G1-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP1MOVDISTDValue">...</td>
                                                    <td className="text-center" id="TP1MOVDISTNValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP1MOVINCLIDValue">...</td>
                                                    <td className="text-center" id="TP1MOVINCLINValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP1MOVREPDValue">...</td>
                                                    <td className="text-center" id="TP1MOVREPNValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP1MOVINTDValue">...</td>
                                                    <td className="text-center" id="TP1MOVINTNValue">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G2-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP2MOVDISTDValue">...</td>
                                                    <td className="text-center" id="TP2MOVDISTNValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP2MOVINCLIDValue">...</td>
                                                    <td className="text-center" id="TP2MOVINCLINValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP2MOVREPDValue">...</td>
                                                    <td className="text-center" id="TP2MOVREPNValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP2MOVINTDValue">...</td>
                                                    <td className="text-center" id="TP2MOVINTNValue">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G3-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP3MOVDISTDValue">...</td>
                                                    <td className="text-center" id="TP3MOVDISTNValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP3MOVINCLIDValue">...</td>
                                                    <td className="text-center" id="TP3MOVINCLINValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP3MOVREPDValue">...</td>
                                                    <td className="text-center" id="TP3MOVREPNValue">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP3MOVINTDValue">...</td>
                                                    <td className="text-center" id="TP3MOVINTNValue">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-5">
                        <b>MÉDIA</b>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G1-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP1MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP1MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP1MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP1MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G2-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP2MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP2MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP2MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP2MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>

                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G3-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP3MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP3MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP3MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP3MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >
                    <div className="col-md-12 text-center mt-5">
                        <b>MÁXIMO</b>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G1-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP1MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP1MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP1MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP1MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G2-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP2MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP2MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP2MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP2MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>

                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G3-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP3MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP3MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP3MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP3MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div >
                    </div >
                    <div className="col-md-12 text-center mt-5">
                        <b>MÍNIMO</b>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G1-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP1MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP1MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP1MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP1MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP1MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G2-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP2MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP2MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP2MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP2MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP2MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="table-responsive">
                                        <table className="table table-dark">
                                            <thead>

                                                <tr>
                                                    <th>#</th>
                                                    <th className="text-center">G3-PARTE</th>
                                                    <th className="text-center">DIA</th>
                                                    <th className="text-center">NOITE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>DISTÂNCIA</td>
                                                    <td className="text-center" id="TP3MOVDISTDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVDISTNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>INCLI. VERTICAL</td>
                                                    <td className="text-center" id="TP3MOVINCLIDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVINCLINValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>REPOUSO</td>
                                                    <td className="text-center" id="TP3MOVREPDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVREPNValueM">...</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>INTERAÇÃO</td>
                                                    <td className="text-center" id="TP3MOVINTDValueM">...</td>
                                                    <td className="text-center" id="TP3MOVINTNValueM">...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div >

                    </div >
                </div >
            </div >
            <div className="py-3">

                <div className="col-md-12 mt-5 text-center  d-flex  justify-content-center boxHeader2">
                    <h5 className="text-white mt-2 text-center">FATORES DIETÉTICOS</h5>
                </div>

                <div className="col-md-12 text-center mt-5">
                    <b>MÁXIMO</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="text-center">G1-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G2-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G3-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>

                </div >
                <div className="col-md-12 text-center mt-5">
                    <b>MÍNIMO</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="text-center">G1-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G2-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G3-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div >

                </div >
                <div className="col-md-12 text-center mt-5">
                    <b>MÉDIA</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="text-center">G1-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G2-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G3-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div >
                </div >
                <div className="col-md-12 text-center mt-5">
                    <b>TOTAL</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="text-center">G1-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G2-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>G3-PARTE</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>ÁGUA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>RAÇÃO</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div >

                </div >
            </div >
            <div className="py-3">


                <div className="col-md-12 mt-5 text-center  d-flex  justify-content-center boxHeader2">
                    <h5 className="text-white mt-2 text-center">FATORES AMBIENTAIS</h5>
                </div>
                <div className="col-md-12 text-center mt-5">
                    <b>MÁXIMO</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G1</th>
                                                <th className="text-center">FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G2</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G3</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
                <div className="col-md-12 text-center mt-5">
                    <b>MÍNIMO</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G1</th>
                                                <th className="text-center">FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G2</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G3</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div >
                </div >
                <div className="col-md-12 text-center mt-5">
                    <b>MÉDIA</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G1</th>
                                                <th className="text-center">FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G2</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G3</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div >

                </div >
                <div className="col-md-12 text-center mt-5">
                    <b>TOTAL</b>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G1</th>
                                                <th className="text-center">FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G2</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="table-responsive">
                                    <table className="table table-dark">
                                        <thead>
                                            <tr>
                                                <th>G3</th>
                                                <th>FATORES</th>
                                                <th className="text-center">DIA</th>
                                                <th className="text-center">NOITE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>LUZ</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>TEMPERATURA</td>
                                                <td className="text-center">....</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>HUMIDADE</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>SOM</td>
                                                <td className="text-center">...</td>
                                                <td className="text-center">...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div >
                </div >
            </div ><div className="py-3">
                <div className="row">
                    <div className="col-md-12"><image alt='testando remover pelo certo' width="300" className="img-fluid d-block mx-auto" src="IMG\robo.png" />
                    </div>
                </div>
            </div>
            <div className="text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"><button href='./' className="btn btn-success rounded-circle" >SIMULAR</button></div>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <div className="row">
                    <div className="col-md-12 text-justify mt-5"><label>Criamos este robo com o intuito de simular 3
                        equipamentos em funcionamento para testes. Quando o usuário está testando o sistema se
                        ele clica no botão simular são inseridos nos campos das tabelas os valores 1 para todos
                        os valores do grupo G1; 2 para todos G2; e 3 para todos do grupo G3</label></div>
                </div>
            </div>
            <div className="py-3">
                <div className="row">
                    <div className="col-md-12 mt-5"><button href='./' className="btn btn-primary btn-lg btn-block disabled"
                        >COMPARATIVOS
                        AUTOMATIZADOS</button></div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <h5>Está pesquisa ainda não têm comparativos, adiquira já comparativos automatos para está
                            pesquisa!!!</h5>
                    </div>
                </div>
                <div className="text-center">
                    <div className="row mx-auto">
                        <div className="text-center mx-auto mt-5 col-md-12" id="columnchart_material"></div>
                    </div>
                    <div className="text-center">
                        <div className="row mx-auto">
                            <div className="text-center mx-auto mt-5 col-md-12" id="wordtree_explicit"></div>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5"><button href='./' className="btn btn-primary btn-lg btn-block disabled"
                                >COMPARAR</button></div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 p-4">
                                <div className="row">
                                    <div className="col-3 p-0  align-items-center"> <image alt='testando remover pelo certo' className="img-fluid d-block"
                                        src="https://static.pingendo.com/img-placeholder-1.svg" /><input
                                            type="checkbox" id="myCheck" />
                                    </div>
                                    <div className="col-9">
                                        <p className="lead mb-1"> <b>SIMPLES</b> </p>
                                        <h5 className="mb-0 text-justify">Essa opção faz a comparação desta pesquisa com a
                                            pesquisa da mesma area considerada como termografia padrão ouro de
                                            tratamento pelo servidor.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 p-4 align-items-center">
                                <div className="row">
                                    <div className="col-3 p-0  align-items-center"> <image alt='testando remover pelo certo' className="img-fluid d-block"
                                        src="https://static.pingendo.com/img-placeholder-4.svg" /> <input
                                            type="checkbox" id="myCheck" /></div>
                                    <div className="col-9">
                                        <p className="lead mb-1"> <b>IA</b> </p>
                                        <h5 className="mb-0 text-justify">Comparativo IA; essa opção faz a comparação desta
                                            pesquisa com todas as pesquisas consideradas como termografia padrão ouro de
                                            tratamento pelo servidor, possa ser que o servidor sugira o teste do padrão
                                            ouro de outra área
                                            para esta.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-left mt-5">
                        <h5>Qualquer um pode adquirir uma comparação para essa pesquisa; ao adquirir está comparação
                            será de informativo aberto com a DATA da comparação e se existem pesquisas novas para
                            serem comparadas .</h5>
                    </div>
                </div>
                <div className="py-3">
                    <div className="row">
                        <div className="col-md-12 text-center"><button href='./' className="btn btn-primary ml-auto mx-auto"
                            >COMPARAR</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-3">
                <div className="row">
                    <div className="col-md-12 mt-5"><button href='./' className="btn btn-primary btn-lg btn-block disabled"
                        >DOWNLOAD</button>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-4 p-4">
                            <div className="row">
                                <div className="col-3 p-0 align-items-center"> <image alt='testando remover pelo certo' className="img-fluid d-block"
                                    src="https://static.pingendo.com/img-placeholder-1.svg" /> </div>
                                <div className="col-9">
                                    <p className="lead mb-1"> <b>SIMPLES</b> </p>
                                    <p className="mb-0 text-justify">Documentos desta pesquisa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 p-4">
                            <div className="row">
                                <div className="col-3 p-0 align-items-center"> <image alt='testando remover pelo certo' className="img-fluid d-block"
                                    src="https://static.pingendo.com/img-placeholder-4.svg" /> </div>
                                <div className="col-9">
                                    <p className="lead mb-1"> <b>ASSOCIAÇÃO SIMPLES</b> </p>
                                    <p className="mb-0">Documentos desta pesquisa + doc. associação simples.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 p-4">
                            <div className="row">
                                <div className="col-3 p-0 align-items-center"> <image alt='testando remover pelo certo' className="img-fluid d-block"
                                    src="https://static.pingendo.com/img-placeholder-4.svg" /> </div>
                                <div className="col-9">
                                    <p className="lead mb-1"><b>ASSOCIAÇÃO IA</b> </p>
                                    <p className="mb-0">Documentos desta pesquisa + doc. associação IA. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center mt-5"><button href='./' className="btn btn-primary ml-auto mx-auto"
                        >DOWNLOAD</button>
                    </div>
                </div>
            </div>

        </>
    )
}
