

export const Apresentacao = () => {

    return (
        <>
          
                <div className=" col-md-12  text-center">
                    <small className=" text-dark font-weight-bold"><b>Bioterio Forte - Versão: 1.0</b></small><br/>
                    <small className=" text-dark font-weight-bold"><b>Área destinada a cadastro de pesquisas</b></small><br/>
                    <small className=" text-dark font-weight-bold"><b>Aquisições e/ou outras informações técnicas do equipamento: </b></small><br/>
                    
                </div>
        </>

    )

}