import React from 'react';
import { Table } from 'antd';

const AntTable = ({ columns, data, pagination, onChange }) => {
  // Adicione uma chave única a cada item de dados
  const dataWithKeys = data.map((item, index) => ({ ...item, key: index }));

  return (
    <Table
      columns={columns}
      dataSource={dataWithKeys}
      pagination={pagination}
      onChange={onChange}
    />
  );
};

export default AntTable;
