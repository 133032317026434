import React from 'react';
import { Button } from 'antd';

 export const AntButton = ({ label, onClick, type, size, icon, className, shape, danger }) => {
  return (
    <Button
      type={type}// Define a cor do buttoon
      size={size}// Define o tamanho do button
      className={className}// Define a classe do button
      shape={shape} // Defina a forma do botão
      onClick={onClick}
      danger={danger}
    >
      {icon && <span className="ant-button-icon">{icon}</span>} {/*Define o icon do button */}
      {label}{/*Define o nome do button*/}
    </Button>
  );
}

export default AntButton;