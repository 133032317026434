import React from 'react';
import { TitleL2, TitleL3 } from '../../../../../components';


export const FinancialEssentials = () => {
  return (<>
    <TitleL2 className='mt-5'>Definição dos Objetivos</TitleL2>
    <div className='row' style={{ padding: '20px', marginTop: '15px', textAlign: 'justify' }}>
      <div className='col-md-4'>
        <TitleL3 >Como nos tornamos financeiramente essenciais !</TitleL3>
        <p>&nbsp;
          Primeiro
          identificamos as necessidades específicas do nosso setor e desenvolvemos
          habilidades que atendem a essas demandas. Na THOT I.A. CORP., acreditamos na
          importância da especialização em áreas críticas, como análise de dados, gestão de projetos e inovação.
          Além disso, ofereçemos soluções que impactam diretamente os resultados financeiros dos parceiros, estabelecendo relacionamentos sólidos
          com stakeholders-chave. Essa proatividade em aprender e adaptar-se às mudanças do mercado têm sido crucial para garantir nossa relevância
        </p>
      </div><div className='col-md-4'>
        <TitleL3 >Quando começamos a buscar essa posição de importância financeira?</TitleL3>
        <p>&nbsp;
          Na THOT I.A. CORP., encorajamos todos os colaboradores a começarem essa busca imediatamente.
          O ambiente de negócios está em constante evolução, e estar preparado para ser financeiramente essencial é um processo contínuo.
          Isso envolve investir em desenvolvimento profissional desde o início da carreira e aproveitar as oportunidades de crescimento dentro da organização.
          Quanto mais cedo um profissional se posicionar como um colaborador valioso, mais rapidamente poderá contribuir para o sucesso da empresa e garantir sua relevância a longo prazo
        </p>
      </div><div className='col-md-4'>
        <TitleL3 >Por que é importante ser financeiramente essencial?</TitleL3>
        <p>&nbsp;
          Ser financeiramente essencial é vital para garantir a estabilidade e o progresso na carreira de cada um de nossos colaboradores. Na ThotiaCorp, entendemos que, ao nos tornarmos indispensáveis, não apenas fortalecemos nossa posição dentro da empresa, mas também contribuímos para a sustentabilidade e o crescimento do negócio. Isso nos coloca em uma posição estratégica, onde podemos influenciar decisões importantes e ter maior poder de negociação em relação a promoções e aumentos salariais     </p>
      </div>
    </div>
  </>);
};

export default FinancialEssentials;
