import React from "react";


export function TableContent({title,dark, id}) {

  return (
    <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="section-content" id={id}>
      <h1>{title}</h1>      
    </div>
  </div>
  )
}
