import { InputPadraoTitle } from "../../../../../components";
export const CadastroAddress = ({ latitude, setLatitude,
    longitude, setLongitude, getLocalizacao }) => {
    return (
        <div className="form-group col-md-5">
            <InputPadraoTitle title={'Endereço de moradia'} />
            <div className="input-group">
                <input
                    className="form-control text-center"
                    disabled
                    type="text"
                    style={{border:1}}
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    id="Latitude"
                    placeholder="LATITUDE"
                />
                <input
                    className="form-control text-center col-md-12"
                    disabled
                    type="text"
                    style={{border:1}}
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    name="address"
                    placeholder="LONGITUDE"
                    id="Longitude"
                />
                <div className="input-group-append">
                    <button
                        type="button"
                        className="btn btn-primary"
                        id="get-location"
                        onClick={getLocalizacao}
                    >
                        <i className="fa fa-map-marker"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CadastroAddress;