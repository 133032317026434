import { useEffect, useState } from "react";
import { NavBreadcrumb } from "../../../../components"
import ProductList from "../../../Public/Produtos/partials/layout/comprar/ProdutoList"
import ApresentacaoProdutosPublic from "../../../Public/Produtos/partials/layout/vender/Apresentacao/layout/ApresentacaoProdutosPublic";
export const Index = () => {
    const [currentSection, setCurrentSection] = useState(
        window.location.hash || "#produtos" // Define #servicos como padrão se não houver hash
    );

    // Monitorar mudanças na URL (hash)
    useEffect(() => {
        const handleHashChange = () => {
            setCurrentSection(window.location.hash);
        };
        // Adiciona um listener para mudanças no hash
        window.addEventListener("hashchange", handleHashChange);
        // Remove o listener ao desmontar o componente
        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };
    }, []);
    return (<>
        <NavBreadcrumb icon={'user fa'} />
        {currentSection === "#produtos" && <ProductList />}
        {currentSection === "#apresentacao" && <ApresentacaoProdutosPublic />}

    </>)
}