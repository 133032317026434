import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav } from '../../../components/index';
import Index from './partials/components/nav/Index';
export const Carrinho = () => {
  const location = useLocation();
  const isCarrinhoPage = location.pathname.startsWith("/carrinho");
  return (<>
    {isCarrinhoPage && <> <Nav /> </>}
    <div><Index /></div>
  </>);
};
export default Carrinho;