import React, { useState, useRef, useEffect, useCallback } from "react";
import Select from "react-select";
import { Input, Space } from "antd";
import AntTable from "../datatable/AntTable";
const { Search } = Input;
const defaultColumns = [
  { title: "Nome", dataIndex: "name", key: "name" },
  { title: "CPF", dataIndex: "cpf", key: "cpf" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Contato", dataIndex: "phone", key: "phone" },
];
export const BuscarPacientes = ({
  props,
  updateSelectedOption,
  selectedOption,
  updateSearchValue,
  searchValue,
  pagination,
  setPagination,
  title,
}) => {
  const { onSearch, customColumns, data, dataTableVisible } = props;
  const [inputMask, setInputMask] = useState(false);
  const inputRef = useRef(null);
 
  const { current: paginationCurrent, pageSize: paginationPageSize, total: paginationTotal } = pagination;
  const columns = [...defaultColumns, ...customColumns];
  
  const handleCampoChange = useCallback((selectedOption) => {
    setInputMask(selectedOption?.value === "CPF");
    updateSearchValue("");
    updateSelectedOption(selectedOption);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [updateSelectedOption, updateSearchValue]);

  useEffect(() => {
    handleCampoChange(selectedOption);
  }, [selectedOption, handleCampoChange]);

  const handleSearch = () => {
    const searchQuery = inputMask ? searchValue.replace(/\D/g, "") : searchValue;
    onSearch && onSearch(searchQuery, 1); // Sempre reinicia a busca da primeira página
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    updateSearchValue(inputMask ? formatCPF(inputValue) : inputValue);
  };
  const formatCPF = (value) => {
    const regex = /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/;
    const groups = value.replace(/[^0-9]/g, "").match(regex);
    if (groups) {
      const formattedValue = `${groups[1]}${groups[1] && groups[2] ? "." : ""}${groups[2]}${groups[2] && groups[3] ? "." : ""}${groups[3]}${groups[3] ? "-" : ""}${groups[4]}`;
      return formattedValue;
    } else {
      return value;
    }
  };
  const handlePageChange = (newPage) => {
    setPagination({
      ...pagination,
      current: newPage,
    });
    const searchQuery = inputMask ? searchValue.replace(/\D/g, "") : searchValue;
    onSearch && onSearch(searchQuery, newPage);
  };

  const renderSearchInput = () => {
    return (
      <Search
        placeholder={`Pesquisar por ${selectedOption ? selectedOption.value : "campo"}`}
        onSearch={handleSearch}
        enterButton
        size="large"
        ref={inputRef}
        value={searchValue}
        onChange={handleInputChange}
      />
    );
  };

  return (
    <div className="d-flex">
      <div className="w-100">
        <div className="Atestado-Paciente">
          <div className="Atestado-titulo">{title}</div>
        </div>
        <div className="Atestado-input-select-container-container-com-borda">
          <Select
            className="Atestado-custom-camp"
            value={selectedOption}
            onChange={handleCampoChange}
            options={[
              { value: "CPF", label: "CPF" },
              { value: "Nome", label: "Nome" },
              { value: "Identificador", label: "Identificador Único" },
            ]}
            isSearchable={false}
            inputRef={inputRef}
          />
          <div className="Atestado-input-container">
            {renderSearchInput()}
            <Space />
            {dataTableVisible && (
              <div className="Atestado-Data-table-container">
                <AntTable
                  columns={columns}
                  data={data}
                  pagination={{
                    current: paginationCurrent,
                    pageSize: paginationPageSize,
                    total: paginationTotal,
                  }}
                  onChange={(pagination) => handlePageChange(pagination.current)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BuscarPacientes;
