import  { useEffect } from 'react';
import axios from 'axios';
import { baseURL } from '../../services/api';

const AnalyticsComponent = () => {
  useEffect(() => {
    const analyticsBuffer = [];
    let timeoutId;

    const sendAnalyticsData = (data) => {
      axios.post(`${baseURL}post_analytics`, data)
        .then(response => {
          if (!response.status === 200) {
            throw new Error('Erro ao enviar os dados de análise');
          }
        })
        .catch(error => {
          console.error('Erro ao enviar os dados de análise:', error);
        });
    };

    const flushAnalyticsBuffer = () => {
      sendAnalyticsData(analyticsBuffer);
      analyticsBuffer.length = 0;
      clearTimeout(timeoutId);
      timeoutId = null;
    };

    const addToAnalyticsBuffer = (data) => {
      analyticsBuffer.push(data);
      if (!timeoutId) {
        timeoutId = setTimeout(flushAnalyticsBuffer, 5000);
      }
    };

    const sendPageLoadData = () => {
      const pageLoadData = {
        type: 'page-load',
        url: window.location.href,
        timestamp: new Date().toISOString(),
      };
      addToAnalyticsBuffer(pageLoadData);
    };

    const handleClick = (event) => {
      const clickedElement = event.target;
      const clickedElementTag = clickedElement.tagName.toLowerCase();

      if (clickedElementTag === 'button' || clickedElement.classList.contains('ant-breadcrumb-link') || clickedElement.classList.contains('nav-link') || clickedElement.classList.contains('ant-card') || clickedElementTag === 'a') {
        const clickedText = clickedElement.textContent.trim();
        const clickData = {
          type: 'button-click',
          text: clickedText,
          url: window.location.href,
          timestamp: new Date().toISOString(),
        };
        addToAnalyticsBuffer(clickData);
      }
    };

    document.addEventListener('click', handleClick);
    sendPageLoadData();

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return null; // Este componente não renderiza nada visível
};

export default AnalyticsComponent;
