import React from 'react';
import { Link } from 'react-router-dom';
import { MaisInfoMenosInfo } from '../../../../../../../../components';
import MasterCadastroCompanyInfo from '../info/MasterCadastroCompanyInfo';
import { Image } from 'antd';
export const MasterCadastroCompany = ({
    professionalOwnerCompany,
    handleDriveProfessionalUnit,
    handleDriveProfessionalEdit,
    handleExcludeProfessional }) => {
    return (<>
        {professionalOwnerCompany && professionalOwnerCompany.length > 0 && (
            <> <div >
                <small className='font-weight-bold'>Master Cadastro Company</small><br />
                <div className="container-fluid">
                    <div className="row d-inline-flex justify-content-center w-100">
                        {professionalOwnerCompany.map((item, index) => (
                            <div
                                className=' mt-3'
                                key={index}
                                style={{
                                    padding: '10px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                }}>
                                {item && (<>
                                    <div className=' py-2'>
                                        <Image height={200} width={200} className='rounded' src={item.imageUrls} alt="Professional Image" preview={false} /><br />
                                    </div>
                                    <div>
                                        <small className='font-weight-bold'>Nome Empresa:<small>{item.company_name}</small></small><br />
                                        <Link onClick={() => handleDriveProfessionalUnit(item)}><i className={`fa fa-suitcase text-dark mr-2 ml-2 ${localStorage.getItem('drive') === JSON.stringify(item.company) ? 'text-success' : ''}`}></i></Link>
                                        <Link onClick={() => handleDriveProfessionalEdit(item.id)}><i className='fa fa-edit text-warning mr-2 ml-2'></i></Link>
                                        <Link onClick={() => handleExcludeProfessional(item.id)}><i className='fa fa-trash text-danger mr-2 ml-2'></i></Link>
                                    </div>
                                </>)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
                <MaisInfoMenosInfo title={'Mais info'} text={<><MasterCadastroCompanyInfo /></>} />
                <hr />
                <br /><br />
            </>)}
    </>);
};
export default MasterCadastroCompany;