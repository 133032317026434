import React from 'react';
import { TitleL1 } from '../../../../../../../components';
import ButtonCadastrar from '../../../../../../../components/button/ButtonCadastrar'
export const ImagensSistemaServicos = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className="d-flex flex-wrap justify-content-center">
                <div className="col-md-5 border mt-2 " style={{
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}>
                    <img className="col-md-12"
                        src={require('../assets/ImagensSistemaServicosUser.png')}
                        style={{ minHeight: '196px', maxHeight: '196px', maxWidth: '450px' }}
                        alt='Imagem mostrando a vista interna de usuários do tipo profissionais, 
                        empresas,instituições e equipamentos na plataforma da THOT I.A. CORP.. 
                        Vista sendo exibida em uma tela de computador para mostrar a alta adaptabilidade do software em vários tamanhos de tela' />
                    <small>Vista Interna  </small>
                    <TitleL1>Gerenciamento Jurídico</TitleL1>
                    <h5>
                        ✅ Profissionais
                        <br />
                        ✅ Empresas
                        <br />
                        ✅ Instituições
                        <br />
                        ✅ Equipamentos
                    </h5>
                </div>
                <div className='col-md-1' />
                <div className="col-md-5 border mt-2 " style={{
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    flexShrink: '0',
                    borderRadius: '33px',
                }}
                >
                    <img className=" col-md-12"
                        src={require('../assets/ImagensSistemaServicosUser.png')}
                        style={{ minHeight: '196px', maxHeight: '196px', maxWidth: '450px' }}
                        alt='Imagem mostrando a vista interna de usuários
                         e de "GADGETS". "GADGETS" são equipamentos IoT.
                          Mostrando vista interna da plataforma da THOT I.A. CORP.
                        como se fosse vista utilizando um tablet/celular' />
                    <small> Vista interna</small>
                    <TitleL1>Gerenciamento  <br />Fisíco</TitleL1>
                    <h5>
                        ✅ Usuários
                        <br />
                        ✅ Gadgets
                        <br /> <br />
                    </h5>
                </div>
            </div>
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
            <ButtonCadastrar />
            <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </section>
    </>);
};

export default ImagensSistemaServicos;
