import { NavProfissionalMain } from "../../../../../../components";
import ProspeccaoClientesMaster from "./layout/ProspeccaoClientesMaster";
export const ProspeccaoClientes = () => {
    return (<>
        <div className="d-flex">
            <NavProfissionalMain />
            <div className="w-100">
                <ProspeccaoClientesMaster />
            </div>
        </div>
    </>)
}
export default ProspeccaoClientes;