import React, { useState } from 'react';
import axios from 'axios'
import LocalizacaoButton from './LocalizacaoButton';
import { baseURL } from '../../../../../../../../../services/api';
const CanaisConfiguracao = ({ tipoAviso, configuracoes, handleCanalChange, handleAddCampo, handleRemoveCampo }) => {
  return (
    <div className='col-md-12 flex-wrap py-2'>
      <label>Canais de comunicação:</label>
      {configuracoes.map((canal, index) => (
        <div className='mt-3 col-md-12' key={index}>
          <input
            type="text"
            name="nome"
            placeholder='Nome'
            value={canal.nome}
            onChange={(event) => handleCanalChange(tipoAviso, index, event)}
          />
          <input
            type="text"
            name="valor"
            placeholder='Contato'
            value={canal.valor}
            onChange={(event) => handleCanalChange(tipoAviso, index, event)}
          />
          {index > 0 && (
            <button
              style={{
                background: 'none',
                border: 'none',
                padding: '0',
                cursor: 'pointer',
                color: 'red',
              }}
              onClick={() => handleRemoveCampo(tipoAviso, index)}
            >
              <i className='fa fa-trash'></i>
            </button>
          )}
        </div>
      ))}
      <button className="btn btn-primary mt-3" onClick={() => handleAddCampo(tipoAviso)}>
        Adicionar Canal
      </button>
    </div>
  );
};
export const ConfiguracoesAvisoList = ({ moduleNames }) => {
  const [configuracoes, setConfiguracoes] = useState([]);
  const [selectAtual, setSelectAtual] = useState('');
  const [selectNomeModulo, setSelectNomeModulo] = useState('');
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const handleTipoAvisoChange = (event) => {
    const { value } = event.target;
    setConfiguracoes((prevConfiguracoes) => {
      const configIndex = prevConfiguracoes.findIndex((config) => config.tipoAviso === value);
      if (configIndex !== -1) {
        return prevConfiguracoes;
      } else {
        const newConfiguracao = {
          tipoAviso: value,
          configuracoes: [{ nome: '', valor: '' }],
          mensagem: '',
        };
        return [...prevConfiguracoes, newConfiguracao];
      }
    });
    setSelectAtual(value);
  };
  const handleNomeModuloChange = (event) => {
    const { value } = event.target;
    setSelectNomeModulo(value);
  };
  const handleCanalChange = (tipoAviso, index, event) => {
    setConfiguracoes((prevConfiguracoes) => {
      const updatedConfiguracoes = prevConfiguracoes.map((configuracao) => {
        if (configuracao.tipoAviso === tipoAviso) {
          const configuracoes = [...configuracao.configuracoes];
          configuracoes[index] = {
            ...configuracoes[index],
            [event.target.name]: event.target.value,
          };
          return { ...configuracao, configuracoes };
        }
        return configuracao;
      });
      return updatedConfiguracoes;
    });
  };
  const handleAddCampo = (tipoAviso) => {
    setConfiguracoes((prevConfiguracoes) => {
      const updatedConfiguracoes = prevConfiguracoes.map((configuracao) => {
        if (configuracao.tipoAviso === tipoAviso) {
          const configuracoes = [...configuracao.configuracoes, { nome: '', valor: '' }];
          return { ...configuracao, configuracoes };
        }
        return configuracao;
      });
      return updatedConfiguracoes;
    });
  };
  const handleRemoveCampo = (tipoAviso, index) => {
    setConfiguracoes((prevConfiguracoes) => {
      const updatedConfiguracoes = prevConfiguracoes.map((configuracao) => {
        if (configuracao.tipoAviso === tipoAviso) {
          const configuracoes = [...configuracao.configuracoes];
          configuracoes.splice(index, 1);
          return { ...configuracao, configuracoes };
        }
        return configuracao;
      });
      return updatedConfiguracoes;
    });
  };
  const handleMensagemChange = (tipoAviso, event) => {
    setConfiguracoes((prevConfiguracoes) => {
      const updatedConfiguracoes = prevConfiguracoes.map((configuracao) => {
        if (configuracao.tipoAviso === tipoAviso) {
          return { ...configuracao, mensagem: event.target.value };
        }
        return configuracao;
      });
      return updatedConfiguracoes;
    });
  };
  const handleSaveConfiguracao = () => {
    alert(JSON.stringify(configuracoes))
    axios
      .post(`${baseURL}configuracoes`, { configuracoes })
      .then((response) => {
        alert(response, configuracoes);
        alert('Configurações salvas com sucesso');
      })
      .catch((error) => {
        alert('Erro ao salvar as configurações:', error);
      });
    alert('Configuração salva:', configuracoes);
  };
  const handleToggleLocation = (isEnabled) => {
    setIsLocationEnabled(isEnabled);
  };
  return (
    <>
      <div className='flex-wrap d-flex '>
        <div className=' mx-auto justify-content-center '>
          <h5 className='font-weight-bold  mt-3'>Nome da Classe</h5>
          {Array.isArray(moduleNames) ? (
            <select
              value={selectNomeModulo}
              onChange={handleNomeModuloChange}
              className="custom-select text-center border border-dark mx-auto font-weight-bold rounded col-md-12  mt-1"
            >
              <option value="">Selecione</option>
              {moduleNames.map((moduleName, index) => (
                <option key={index} value={moduleName}>
                  {moduleName}
                </option>
              ))}
            </select>
          ) : (
            <p>No module names available.</p>
          )}
        </div>
        <LocalizacaoButton text={'Obter informações de localização do aparelho:'} onToggle={handleToggleLocation} />
        {isLocationEnabled}
        <LocalizacaoButton text={'Obter informações ambientais do aparelho:'} onToggle={handleToggleLocation} />
        <h5 className='font-weight-bold  mt-5'>Configurações de aviso adicional</h5>
        <div className='col-md-12 py-2'>
          <label>Tipo de Aviso:</label>
          <select
            value={selectAtual}
            onChange={handleTipoAvisoChange}
            className="custom-select text-center border border-dark mx-auto font-weight-bold rounded col-md-12  mt-1"
          >
            <option value="">Selecione</option>
            <option value="tipo1">SMS Celular</option>
            <option value="tipo2">WhatsApp</option>
            <option value="tipo3">E-mail</option>
          </select>
        </div>
        {configuracoes.map((configuracao) => (
          <div className='col-md-12 mt-2' key={configuracao.tipoAviso}>
            {configuracao.tipoAviso === selectAtual && (
              <>
                <CanaisConfiguracao
                  tipoAviso={configuracao.tipoAviso}
                  configuracoes={configuracao.configuracoes}
                  handleCanalChange={handleCanalChange}
                  handleAddCampo={handleAddCampo}
                  handleRemoveCampo={handleRemoveCampo}
                />
                <div className='col-md-12 py-2'>
                  <label>Mensagem:</label>
                  <textarea
                    className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
                    rows="4"
                    cols="50"
                    type="text"
                    value={configuracao.mensagem}
                    onChange={(event) => handleMensagemChange(configuracao.tipoAviso, event)}
                  />
                </div>
              </>
            )}
          </div>
        ))}
        <div className="col-md-12 d-flex justify-content-center align-items-center py-3 mb-3">
          <button className='btn btn-success' onClick={handleSaveConfiguracao}>Salvar</button>
        </div>
      </div>
    </>
  );
};
export default ConfiguracoesAvisoList;