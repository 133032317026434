import React from 'react';
import "./styles/ApresentacaoServicos.css";
import { ButtonCadastrar, LinkHref, TitleL1 } from '../../../../../../components';
export const ApresentacaoServicos = () => {
    return (<>
        <section className='mr-3 ml-3'style={{ minHeight: '95dvh' }}>
            <div style={{ overflow: 'hidden', borderRadius: '0px' }}>
                <div className="Absolute jutify">
                    <p className='text-center '>  <img
                        src={require('./assets/IndependenciaFinanceira.png')}
                        alt="Liberdade Financeira"
                        style={{ maxHeight: '280px', maxWidth: '350px' }}
                        className='col-md-12'
                    />
                    </p>
                    <TitleL1 >Liberdade Financeira</TitleL1>
                    <h5 className='text-justify'>
                        <b>Alcance sua Liberdade Financeira</b> com nosso software. Seja você um profisssional, empresa ou instituição, temos o plano correto para você alcançar a liberdade financeira.&nbsp;
                        <LinkHref to={'#PlanosPrecosServicos'} href={'PlanosPrecosServicos'}>[ Veja nossos planos e preços ]</LinkHref>
                    </h5>
                    <p className='text-center mt-5'>  <img
                        src={require('./assets/GerenciamentoFinanceiro.png')}
                        alt="Liberdade Financeira"
                        style={{ maxHeight: '280px', maxWidth: '450px' }}
                        className='mt-5 col-md-12'
                    />
                    </p>
                    <h5 className='mb-5  text-justify'>
                        <b>  Explore várias opções de gerenciamento profissional e co-criação de sistemas com dois cliques</b>
                         ERP + E-commerce + Dropshipping - White Label. Além disso você pode ter comunicação com seus clientes no CRM configurando Inteligências Artificiais I.A. de Business Intelligence B.I. para fazer suas vendas recorrentes
                         <LinkHref to={'#SolucaoGerencimamentoAvancado'} href={'SolucaoGerencimamentoAvancado'}>[ Veja nossos sistema de gerenciamento altamente eficiente  ]</LinkHref>
                    </h5>
                    <p className='text-center mt-5 py-5'>  <img
                        src={require('./assets/IAImagem.png')}
                        alt="Liberdade Financeira"
                        style={{ maxHeight: '280px', maxWidth: '450px' }}
                        className='mt-5 col-md-12'
                    />
                    </p>
                    <h5 className='mb-5 text-justify'>
                        <b>Venda os mais diversos exames de imagem laudados por Inteligência Artificial (I.A.)</b>. 
                        Se o exame estiver indisponível você pode criar a Inteligência Artificial para lida com ele com dois cliques. I.A para classificação de imagens dinâmica de propósito geral de fácil uso dispensa conhecimento
                         em programação. Pode verificar de condições médicas a linhas industriais literalmente qualquer coisa.   <LinkHref to={'#SolucaoIAAvancadaServicos'} href={'SolucaoIAAvancadaServicos'}>[ Veja nossos sistema de I.A. integrada  ]</LinkHref>
                    </h5>
                </div>
            </div>
            <ButtonCadastrar href={'/profissionais/servicos'}/>
        </section>
    </>)
}