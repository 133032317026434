import { useEffect } from 'react';
import { Nav, Footer } from '../../../components/index'
import Main from './partials/layout/Main'
export const Home = () => {
  useEffect(() => {
    const visitasPaginas = JSON.parse(localStorage.getItem('visitasPaginas')) || {};
    if (visitasPaginas['Home']) {
      visitasPaginas['Home'] += 1;
      if (visitasPaginas['Home'] === 10) {
        visitasPaginas['Home'] = 0;
      }
    } else {
      visitasPaginas['Home'] = 1;
    }
    localStorage.setItem('visitasPaginas', JSON.stringify(visitasPaginas));
  }, []);
  useEffect(() => {
    const visitasPaginas = JSON.parse(localStorage.getItem('visitasPaginas')) || {};
    if (visitasPaginas['Home'] === 2) {
      localStorage.setItem('propagandaDesabilitada', 'true');
    }
  }, []);
  return (<>
    <Nav />
    <Main/>
    <Footer />
  </>)
}