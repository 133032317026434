import imageAC from '../icons/Area Chart.png'
import imageBC from '../icons/Bar Chart.jpg';
import imageBP from '../icons/Box Plot.png';
import imageBUC from '../icons/Buble Chart.png';
import imageCC from '../icons/Candlestick Chart.png';
import imageDC from '../icons/Doughnut Chart.png';
import imageGC from '../icons/Grouped Bar Chart.png';
import imageLC from '../icons/Line Chart.jpg';
import imagePC from '../icons/Pie Chart.jpg';
import imagePAC from '../icons/Polar Area Chart.png';
import imageRC from '../icons/Radar Chart.jpg';
import imageSP from '../icons/Scatter Plots.png';
import imageSAC from '../icons/Stacked Area Chart.jpg';
import imageWC from '../icons/Waterfall Charts.png';
import imageSBC from '../icons/Stacked Bar Chart.png';
import imageDNC from '../icons/Donut Chart.jpg';
import imageMX from '../icons/mixed chart.jpg';




// Importe as outras imagens aqui

export const images = {
    imageBC,
    imageAC,  
    imageBP,  
    imageBUC,  
    imageCC,  
    imageDC,  
    imageDNC,
    imageMX,
    imageGC,  
    imageLC,  
    imagePC,  
    imagePAC,  
    imageRC,  
    imageSP,  
    imageSAC,  
    imageWC,
    imageSBC   
  // Adicione outras imagens aqui
};


export default images;