import { NavBreadcrumb, CardDirecionamento } from '../../../../components'
export const Index = () => {
    return (<>
        <div className=' w-100' >
            <NavBreadcrumb icon={'user-md fa'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'I.A. Texto'}  icon={'mt-3 fa fa-book fa-3x text-white'} href={'../profissionais/IA/text'} />
                            <CardDirecionamento text={'I.A. Imagem '}  icon={'mt-3 fa fa-camera fa-3x text-white'} href={'../profissionais/IA/imagem'} />
                            <CardDirecionamento text={'I.A. Prospecção'} icon={'mt-3 fa fa-rocket fa-3x text-white'} href={'../profissionais/prospeccaoIA'} />
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </>)
}