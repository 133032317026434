import React, { useContext, useState } from 'react';
import { ButtonAPI, MaisInfoMenosInfo } from '../../../../../../../components';
import axios from 'axios';
import { baseURL } from '../../../../../../../services/api';
import { AuthContext } from '../../../../../../../contexts/auth';
import CreateTrainIATextStage1Info from '../info/CreateTrainIATextStage1Info';

export function CreateTrainIATextStage1({
  setTrainMessage,
  trainMessage,
  isLoading,
  setIsLoading,
  nameIAText,
  setNameIAText,
  fragmentSize,
  wordCount,
  epoch }) {


  const [selectedFiles, setSelectedFiles] = useState([]); // Adicione o estado para os arquivos selecionados
  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
    //alert(fragmentSize)
  };

  const handleUpload = () => {
    setIsLoading(true);
    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append('files', file);
    }
    formData.append('name', nameIAText); // Adicione o token ao FormData
    formData.append('user_id', user_id);
    formData.append('fragment_size', fragmentSize);
    formData.append('word_count', wordCount);
    formData.append('epoch', epoch);
    const existingDriveIAData = JSON.parse(localStorage.getItem('driveIA'));
    if (existingDriveIAData) {
      formData.append('driveIA', existingDriveIAData.token);
      formData.append('insertedId', existingDriveIAData.insertedId);
    }
    axios
      .post(`${baseURL}cadastrarIAText`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setTrainMessage(response.data.message);
        setIsLoading(false);
        const { insertedId, token, versionNumber } = response.data;
        const driveIAData = {
          insertedId,
          token,
          versionNumber,
        };
        localStorage.setItem('driveIA', JSON.stringify(driveIAData));
      })
      //alert(JSON.stringify(response.data))
      .catch((error) => {
        setTrainMessage('Error uploading files.');
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="col-md-5 mt-2 rounded"
      style={{
        backgroundColor: '#f1f1f1', /* Cor do background */
        padding: '10px', /* Espaçamento interno em todos os lados */
        marginLeft: '10px', // Add margin of 5 pixels to the left
        marginRight: "10px", // Add margin of 5 pixels to the right
      }}>
      <h5 className='font-weight-bold  mt-3 mb-3'>Criar I.A. - Stage 1 - Insira Arquivos PDF</h5>
      <div className="mt-2">
        <label className="custom-file-upload  text-dark rounded">
          <input type="file" multiple onChange={handleFileChange} name="files" />
        </label>
      </div>
      <input
        className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
        type="text"
        placeholder="Nome da I.A."
        value={nameIAText}
        onChange={(e) => setNameIAText(e.target.value)}
      />
      <ButtonAPI isLoading={isLoading} className="btn btn-primary" onClick={handleUpload}>
        Enviar Arquivos
      </ButtonAPI>
      {trainMessage}
    <MaisInfoMenosInfo text={<CreateTrainIATextStage1Info />}></MaisInfoMenosInfo> 
    </div>
  );
}

export default CreateTrainIATextStage1;
