import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ProductAlert from '../../../../Produtos/partials/layout/comprar/ProductAlert';
import { Button, InputNumber } from 'antd';
export const ArtigosCard = ({ product_id, products_name, image, description, value, products_type, product_code_id, company_unit_id, onAddToCart, company_name }) => {
  const [quantidade, setQuantidade] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const handleAddToCart = () => {
    const productToAdd = {
      product_id,
      products_name,
      image,
      description,
      value,
      products_type,
      product_code_id,
      company_unit_id,
      quantity: quantidade,
    };
    onAddToCart(productToAdd);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };
  const handleComprarAgora = () => {
    const productToAdd = {
      product_id,
      products_name,
      image,
      description,
      value,
      products_type,
      product_code_id,
      company_unit_id,
      quantity: quantidade,
    };
    onAddToCart(productToAdd);
    const urlCarrinho = '/carrinho';
    window.location.href = urlCarrinho + search;
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const location = useLocation();
  const { search } = location;
  return (<>
    <div className="col-md-3 mt-5 rounded py-1"
      style={{
        backgroundColor: '#f1f1f1',
        padding: '5px',
        marginLeft: '5px',
        marginRight: "5px",
      }}>
      <a href={`/artigos/${product_id}${search}`}><img className="w-100 rounded image-fluid" style={{ minHeight: '270px', maxHeight: '270px' }} src={image} alt="Artigos Imagem" />
        <h4 className='font-weight-bold mx-auto d-flex justify-content-center align-items-center text-center mt-2' style={{ maxHeight: '68px' }}>{products_name}</h4>
      </a>
      <a href={`/artigos/${product_id}${search}`}><strong>Valor: </strong> R${value.toFixed(2)}<br />
        <strong>Tipo:</strong> {products_type}
        <p className='text-justify'><b className='font-weight-bold'>Empresa:</b> {company_name}</p></a>
      <small>Entregas de dados:   &nbsp; </small>
      <abbr title="Download"><i className="fa fa-download text-success" aria-hidden="true"></i></abbr>     
      <div className='text-center'>
        <label className='font-weight-bold mt-1' htmlFor={`quantidade-input-${product_id}`}>Quantidade</label><br />
        <div className='d-flex align-items-center justify-content-center'>
          <InputNumber
            className='text-center'
            style={{ borderRadius: '20px', marginRight: '8px' }}
            id={`quantidade-input-${product_id}`}
            value={quantidade}
            onChange={(value) => setQuantidade(value)}
            minLength={0} // Define o valor mínimo permitido (ajuste conforme necessário)
            min={0}
          />
          {quantidade > 0 && (
            <Button onClick={() => setQuantidade(quantidade - 1)}>-</Button>
          )}
          <Button onClick={() => setQuantidade(quantidade + 1)}>+</Button>
        </div>
      </div>
      <div className='d-flex mt-2  mx-auto justify-content-center'>
        <button className='btn btn-primary col-md-6 btn-rounded '
          onClick={() => handleAddToCart(quantidade)}>
          <i className="fa text-white flip-horizontal">🛒</i> Adicionar ao Carrinho
        </button>
        &nbsp;  &nbsp;  &nbsp;
        <button className='btn btn-success col-md-6 btn-rounded no-drag button-with-triangles' onClick={() => handleComprarAgora(quantidade)}>Comprar Agora 👆 </button>
      </div>
      <div className='text-center mt-2'>
      </div>
      <div className="button text-center d-flex mx-auto justify-content-center">
        <div className="button__text text-center">  <Link className='text-primary font-weight-bold mt-2 ' to={`/artigos/${product_id}${search}`}>Mais info <i className='fa fa-info-circle'></i></Link></div>
        <div className="button__wrapper">
          <div className="button__arrow"></div>
          <div className="button__border-circle"></div>
          <div className="button__mask-circle">
            <div className="button__small-circle"></div>
          </div>
        </div>
      </div>
    </div>
    <ProductAlert
      show={showAlert}
      onHide={handleCloseAlert}
      products_name={products_name}
      products_type={products_type}
      value={value}
      quantity={quantidade}
    />
  </>);
};
export default ArtigosCard;