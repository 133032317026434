import React from 'react';

const EVAComponentInfo = () => {
  return (
    <div className="text-justify col-md-12">
      <h5>Como Funciona a Escala Visual Analógica para Dor</h5>
      <p>
        A Escala Visual Analógica (EVA) é uma ferramenta usada para medir a intensidade da dor. Veja como funciona:
      </p>
      <hr />
      <ol className="ml-3">
        <li><strong>Mova o Controle Deslizante:</strong> Use o controle deslizante abaixo para indicar o nível de dor que você está sentindo. O valor varia de 0 (sem dor) a 10 (dor extrema).</li>
        <li><strong>Classificação de Dor:</strong> Com base no valor selecionado, a dor será classificada como:</li>
        <ul>
          <li>0 a 3: Leve (Azul)</li>
          <li>3 a 5: Moderada (Verde)</li>
          <li>5 a 8: Moderada (Amarelo)</li>
          <li>8 a 10: Intensa (Vermelho)</li>
        </ul>
      </ol>
      <hr />
      <p>
        Após selecionar o nível de dor desejado, a classificação será exibida, e a cor de fundo do componente refletirá a intensidade da dor.
      </p>
      <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
    </div>
  );
};

export default EVAComponentInfo;
