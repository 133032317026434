import React from 'react';
import Chart from './config/Chart';

const MainCanvas = ({ createdCharts, onChartConfigure, onChartDelete }) => {
  return (
    <div className="main-canvas">
      {createdCharts.map((chart, index) => (
        <Chart
          key={index}
          type={chart.type}
          config={chart.config}
          onConfigure={(config) => onChartConfigure(index, config)}
          onDelete={() => onChartDelete(index)}
        />
      ))}
    </div>
  );
};

export default MainCanvas;
