import { FooterProfissional, NavProfissionalMain } from '../../../../../../../components';
export const AtestadosProfissionaisAtivo = () => {
    return (<>
        <div className="d-flex">
            <NavProfissionalMain />
            <div className="w-100">
               <p>Insira conteudo</p>
                <FooterProfissional />
            </div>
        </div>
    </>)
};