import axios from "axios";
import { baseURL } from "../api.js";
export const axiosHeadersToken = () => {
  const token = localStorage.getItem('token');  
  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return instance;
};
export default axiosHeadersToken;