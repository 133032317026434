import React, { useState } from 'react';

import { InputPadrao } from '../../../../components';

export const ThemeContextTextPrimary = ({ onChange }) => {
  const [colorTextPrimary, setColorTextPrimary] = useState('');

  const handleColorChangeTextPrimary= (event) => {
    const newColorTextPrimary = event.target.value;
    setColorTextPrimary(newColorTextPrimary);
    onChange(newColorTextPrimary);
  };

  return (
    <InputPadrao title={'Cor Primária'}
      type="color"
      value={colorTextPrimary}
      onChange={handleColorChangeTextPrimary}
    />
  );
};

export default ThemeContextTextPrimary;
