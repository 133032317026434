import React from 'react';
import { TitleL2 } from '../../../../../../../components';

export const GerenciamentoAvancadoInfo = () => {
    return (
        <>          
            <TitleL2>Descubra mais vantagens incríveis de adotar o Sistema Único de Gerenciamento Avançado</TitleL2>
            <br />
            <ul className='ml-3 mr-3'>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>ERP e E-commerce - Gerenciamento Simplificado: </strong>
                    Otimize suas operações com um sistema intuitivo e eficiente.
                    Tenha controle total sobre as operações do seu negócio através de um sistema de gerenciamento simplificado.
                    Uma interface intuitiva permite que você monitore e coordene tarefas de forma eficiente,
                    resultando em uma administração mais ágil e assertiva
                </li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>White Label - Personalização Flexível: </strong>
                    Adapte a plataforma às necessidades específicas da sua empresa.
                    Customize a plataforma de acordo com as características e requisitos únicos da sua empresa.
                    Desde a aparência até as funcionalidades, a flexibilidade de personalização garante que a solução atenda precisamente às suas demandas
                </li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>CRM - Integração com Usuário:</strong>
                    Aumente a produtividade com a integração de um CRM de última geração. Amplie o relacionamento com seus clientes ao integrar um Customer Relationship Management (CRM) avançado.
                    Acompanhe interações, histórico de compras e preferências, permitindo uma personalização eficaz do atendimento e impulsionando a fidelização
                </li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Dashboard Dinâmico e Integrável - Análise de Dados: </strong>
                    Tome decisões embasadas em insights valiosos gerados pela análise de dados.
                    Aproveite dados coletados para obter insights valiosos sobre o comportamento do cliente, tendências do mercado e desempenho do negócio.
                    Decisões informadas impulsionam a estratégia e o crescimento sustentável
                </li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Meta Criação de I.A. com Dois Cliques: </strong>
                    Crie e treine I.A. de Texto e Imagem facilmente, mesmo sem conhecimento em programação, com apenas dois cliques
                </li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Automatização de Processos: </strong>Reduza erros e economize tempo com processos automatizados. Automatize tarefas repetitivas e propensas a erros, aumentando a eficiência operacional. A automação não apenas economiza tempo, mas também diminui a probabilidade de falhas humanas, resultando em processos mais confiáveis.</li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Expansão Online:</strong> Leve sua empresa para o mundo digital com uma plataforma e-commerce sólida. Estenda o alcance do seu negócio ao criar uma plataforma e-commerce robusta. Alcance um público global, aumente as vendas e proporcione aos clientes uma experiência de compra online conveniente</li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Suporte Técnico:</strong> Conte com suporte especializado para garantir o melhor desempenho. Receba assistência técnica especializada sempre que necessário. O suporte técnico dedicado está disponível para resolver problemas, esclarecer dúvidas e garantir que a plataforma funcione sem interrupções.</li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Segurança Avançada: </strong>Proteja informações sensíveis e dados dos seus clientes. Mantenha a segurança dos dados da sua empresa e dos seus clientes como prioridade. Implemente medidas de segurança avançadas para evitar violações e assegurar a privacidade das informações.</li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Economia de Recursos:</strong> Reduza custos operacionais e maximize os lucros. Otimize a alocação de recursos e reduza custos operacionais desnecessários. A automação de processos e a eficiência operacional resultam em economia de recursos, contribuindo para a maximização dos lucros.</li>
                <li className='mt-3 text-justify'><i className=' fa fa-check-square text-success'></i>&nbsp;<strong>Atualizações Contínuas:</strong> Mantenha-se à frente com melhorias e novos recursos constantes. Permaneça atualizado com as últimas tendências e tecnologias. Com atualizações regulares, sua plataforma se beneficia de melhorias contínuas e incorpora novos recursos que acompanham a evolução do mercado.</li>
            </ul>

        </>
    );
};

export default GerenciamentoAvancadoInfo;
