import TitleL3 from "../text/TitleL3";

export const CardVerde = (props) => {

  return (
    <div className="parent">
      <div className="card-verde">
        <div className="logo">
          <span className="circle circle1"></span>
          <span className="circle circle2"></span>
          <span className="circle circle3"></span>
          <span className="circle circle4"></span>
          <span className="circle circle5">
            {props.icon}
          </span>
        </div>
        <div className="glass">
          <div className="col-md-12  align-items-center  row"style={{ marginTop: '45%', marginBottom: '0px' }}>
            <TitleL3  className="title text-success">{props.title}</TitleL3>
            <p ><span className="text-success">{props.text}</span></p>
          </div></div>
        <div className="bottom">

          <div className="social-buttons-container">
            <button className="social-button .social-button1" data-name={props.icon_one_data_name}>
              <a href={props.href_icon_one} >{props.icon_one}</a>
            </button>
            <button className="social-button .social-button2" data-name={props.icon_two_data_name}>
              <a href={props.href_icon_two} >  {props.icon_two}</a>
            </button>
            <button className="social-button .social-button3" data-name={props.icon_tre_data_name}>
              <a href={props.href_icon_tre} >  {props.icon_tre}</a>
            </button>
          </div>
          <div className="view-more">
            <button className="view-more-button" onClick={props.onClick}>Ver mais</button>
            <svg className="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round"><path d="m6 9 6 6 6-6"></path></svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardVerde;