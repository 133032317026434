import { CardDirecionamento, NavBreadcrumb } from '../../../../../components';
import { useLocation } from 'react-router-dom';
export const Main = () => {
    const location = useLocation();
    const { search } = location;
    return (<>
        <section style={{ minHeight: "95dvh" }}>
            <div className='w-100'>
                <NavBreadcrumb icon={'user fa'} text={'Cadastro'} />
                <div >
                    <div>
                        <div className="text-center">
                            <div className="container-fluid">
                                <div className="row d-inline-flex justify-content-center w-100">
                                    <CardDirecionamento text={'Usuario'} icon={'mt-3 fa fa-user fa-3x text-white'} href={`./usuario${search}`} />
                                    <CardDirecionamento text={'Profissional'} icon={'mt-3 fa fa-user-plus fa-3x text-white'} href={`./profissional${search}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default Main;