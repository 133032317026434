import { Image } from 'antd';
import React, { useState } from 'react';
const ProfessionalUnitCard = ({ professional, onDelete, onStatusChange, onEdit, onAddProfessional }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedCBOId, setSelectedCBOId] = useState('');
  const professionalTypes = ['Diretor', 'Administrador', 'Profissional', 'Aluno', 'Outro'];
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType);
    alert(`Tipo selecionado: ${selectedType}`);
    onAddProfessional(professional.professional_id, selectedType, selectedCBOId);
  };
  const handleCBOChange = (e) => {
    const selectedCBOId = e.target.value;
    setSelectedCBOId(selectedCBOId);
    console.log('ID da ocupação selecionada:', selectedCBOId);
  };
  return (<>
    <div className="col-md-2 mt-2 rounded"
      style={{
        backgroundColor: '#fff',
        padding: '10px',
        marginLeft: '5px',
        marginRight: "5px",
      }}
      key={professional.professional_id}>
      <Image className="w-100 rounded" src={professional.image_url} alt="Professional Image" style={{ minHeight: '100px', maxHeight: '100px' }} />
      <h5 className="card-title mt-1"><b>{professional.professional_name}</b></h5>
      {professional.cbos && professional.cbos.length > 0 && (
        <div>
          <h5 className=""><b>Ocupação:</b></h5>
          <select className="custom-select text-center border border-dark mx-auto font-weight-bold rounded" onChange={handleCBOChange}>
            <option value="">Selecione uma ocupação</option>
            {professional.cbos.map((cbo, index) => (
              <option key={index} value={cbo.id}>
                {cbo.title}
              </option>
            ))}
          </select>
        </div>
      )}
      <button className='text-primary py-2' style={{ border: "0px", backgroundColor: '#fff' }} onClick={openModal}>
        <i className='fa fa-info-circle'></i>&nbsp; Ver mais info ...
      </button>
      <div>
      </div>
      <div className="dropdown mt-2">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {selectedType ? selectedType : 'Adicionar profissional'}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {professionalTypes.map((type, index) => (
            <button
              key={index}
              className="dropdown-item"
              value={type}
              onClick={(e) => {
                handleTypeChange(e);
              }}
            >
              {type}
            </button>
          ))}
        </div>
      </div>
      {modalOpen && (
        <div className="modal d-block">
          <div className="modal-dialog w-100 modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{professional.professional_name}</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scrollable">
                <Image className="w-100" src={professional.image_url} alt="Professional Image" />
                <h5 className="card-title"><b>{professional.professional_name}</b></h5>
                <h5 className=""><b>Ocupação: </b>{professional.state}</h5>
                <h6 className='font-weight-bold'>Endereço:</h6>
                <p>{professional.address}</p>
                <p>{professional.number}</p>
                <p>{professional.complement}</p>
                <p>{professional.neighborhood}</p>
                <p>{professional.city}</p>
                <p>{professional.zip_code}</p>
                <h6 className='font-weight-bold'>Área de Atuação:</h6>
                <p>{professional.grande_area}</p>
                <p>{professional.area}</p>
                <p>{professional.sub_area}</p>
                <p>{professional.especialidade}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div >
  </>);
};
export default ProfessionalUnitCard;