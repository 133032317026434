export const customTheme = {
  token: {
    colorPrimary: "#000080", // Cor primária personalizada
    colorLink: "#000080",
    colorInfo: "#000080",
    // colorError: '#000080',
    // fontSize: 14, //Definir o tamanho da font de todo component que se utiliza no ANT DESIGN.
    // Input: { // Se eu quiser modificar só para um tipo de component
    //   borderRadiusLG: 0,// Definir a border do component.
    // }
        
  },
};
