import React from 'react';
import { Modal, Button } from 'antd';

export const ModalSimples = (props) => {
  return (
    <Modal
      visible={props.showModal}
      onCancel={props.handleClose}
      centered
      style={{
        wordWrap: 'break-word',
        maxWidth: '100%'
      }}
      footer={[
        <Button key="fechar" className="bg-danger" onClick={props.handleClose}>
          Fechar
        </Button>
      ]}    >
      {props.children}
    </Modal>
  );
};
export default ModalSimples;