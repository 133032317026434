
import React, { useState } from 'react'
import CadastrarProdutoUnitario from './Unitario/CadastrarProdutoUnitario';
import CadastrarProdutosNfeXML from './Multiplo/CadastrarProdutoXML/CadastrarProdutosNfeXML';
import { FooterProfissional, NavProfissionalMain, Nav, NavBreadcrumb } from '../../../../../../../components';
export const ProdutosCadastrar = () => {
    const [selectedOption, setSelectedOption] = useState('selectCadastroProdutoUnitario');
    return (<>
        <Nav />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <section>
                <div className="w-100" >
                    <section style={{ minHeight: "95dvh" }}>
                        <NavBreadcrumb icon={'user fa'} />
                        <div className='w-100'>
                            <div style={{ minHeight: "100%" }}>
                                <div className="form-group col-md-10 mb-5 mx-auto ">
                                    <select id='selectAreaInteresse' className='custom-select  text-center border border-dark mx-auto font-weight-bold rounded' type='select' value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                        <option className='text-center mx-auto' value='selectCadastroProdutoUnitario'>Cadastro Unitário</option>
                                        <option className='text-center mx-auto' value='selectCadastroProdutoMultiplo'>Cadastro Multiplo</option>
                                    </select>
                                </div>
                                <div id='selectCadastroProdutoUnitario' style={{ display: selectedOption === 'selectCadastroProdutoUnitario' ? 'block' : 'none' }}>
                                    <CadastrarProdutoUnitario />
                                </div>
                                <div id='selectCadastroProdutoMultiplo' style={{ display: selectedOption === 'selectCadastroProdutoMultiplo' ? 'block' : 'none' }}>
                                    <CadastrarProdutosNfeXML />
                                </div>|
                            </div>
                        </div>
                    </section>
                    <FooterProfissional />
                </div>
            </section>
        </div>
    </>)
}
export default ProdutosCadastrar;