import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { ReferenciaLocal, NavBreadcrumb, InputPadrao, InputPadraoTitle, ButtonAPI } from '../../../../components';
import FormularioDinamicoMedicacao from './FormularioDinamicoMedicacao';
import ReceitaControleEspecial from './ReceitaControleEspecial';
import { useReactToPrint } from 'react-to-print';
// import { AuthContext } from '../../../../contexts/auth';
import { baseURL } from '../../../../services/api';

export const MasterMedicacao = () => {
  const [opcaoSelecionada, setOpcaoSelecionada] = useState('');
  const [nomePaciente, setNomePaciente] = useState('');
  const [pacientesEncontrados, setPacientesEncontrados] = useState([]);
  const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
  const [medicamentos, setMedicamentos] = useState([]);
  const [enderecoPaciente, setEnderecoPaciente] = useState('');
  const [cidadePaciente, setCidadePaciente] = useState('');
  const [estadoPaciente, setEstadpPaciente] = useState('')
  const [telefonePaciente, setTelefonePaciente] = useState('')
  // const id = useContext(AuthContext);
  // const driveData = JSON.parse(localStorage.getItem('drive'));
  // Cria a referencia ao receitaControle
  const componentRef = useRef();
  // Funcao de impressao 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (pacienteSelecionado) {
      setEnderecoPaciente(pacienteSelecionado.address);
      setCidadePaciente(pacienteSelecionado.city);
      setEstadpPaciente(pacienteSelecionado.state);
      setTelefonePaciente(pacienteSelecionado.phone);
    }
  }, [pacienteSelecionado]);
  const handlePrintButtonClick = () => {
    try {
      handlePrint();
    } catch (error) {
      console.error('Error while printing:', error);
    }
  };
  const buscarUsuarios = useCallback(async () => {
    try {
      const response = await axios.get(`${baseURL}buscarUsuarios/${nomePaciente}`);
      console.log('Resposta do servidor:', response.data);
      setPacientesEncontrados(response.data.pacientes);
    } catch (error) {
      console.error('Erro ao buscar pacientes:', error);
      setPacientesEncontrados([]);
    }
  }, [nomePaciente, setPacientesEncontrados]); // Dependências necessárias

  useEffect(() => {
    if (nomePaciente) {
      console.log('Nome do paciente a ser buscado:', nomePaciente, enderecoPaciente);
      buscarUsuarios();
    } else {
      setPacientesEncontrados([]);
    }
  }, [nomePaciente, enderecoPaciente, buscarUsuarios]); // Dependências
  const handleSelecionarOpcao = (event) => {
    setOpcaoSelecionada(event.target.value);
    setPacienteSelecionado(null); // Limpe o paciente selecionado quando a opção for alterada
    setMedicamentos([]); // Limpe a lista de medicamentos ao mudar a opção
  };
  // Função para lidar com a seleção de um paciente na lista
  const handleSelecionarPaciente = (paciente) => {
    setPacienteSelecionado(paciente);
    setNomePaciente(paciente.name);
    setEnderecoPaciente(paciente.address);
    setCidadePaciente(paciente.city);
    setEstadpPaciente(paciente.state);
    setTelefonePaciente(paciente.phone);
  };
  return (<>
    <div>
      <NavBreadcrumb icon={'capsules fa'} text={'Emitir Receita'} />
      <div className="row d-inline-flex justify-content-center w-100">
        <div className="col-md-10 mt-3 mx-auto">
          <InputPadraoTitle title={"Tipo de Receita"} />
          <select className="custom-select" value={opcaoSelecionada} onChange={handleSelecionarOpcao}>
            <option value="">Selecione...</option>
            <option value="opcao1">Opção 1</option>
            <option value="opcao2">Opção 2</option>
            <option value="opcao3">Opção 3</option>
            <option value="receitaControleEspecial">Receita de Controle Especial</option>
          </select>
        </div>
        <InputPadrao title={"Identificador único"} />
        <InputPadrao
          title={"Nome Paciente"}
          value={nomePaciente}
          onChange={(e) => setNomePaciente(e.target.value)}
        />
        {/* Lista de pacientes encontrados */}
        <div className="col-md-12">
          <ul>
            {pacientesEncontrados.map((paciente, index) => (
              <li key={index} onClick={() => handleSelecionarPaciente(paciente)}>
                {paciente.name}
              </li>
            ))}
          </ul>
        </div>
        <FormularioDinamicoMedicacao
          medicamentos={medicamentos}
          setMedicamentos={setMedicamentos}
          onPrescricaoAtualizada={(medicamentos) => {
            // Faça algo com a nova prescrição, se necessário
            console.log('Nova Prescrição:',);
            console.log(medicamentos);
          }}
        />
        <ReferenciaLocal text={"Pré-visualizar Receita"} />
      </div>
      <div className="col-md-12">
        {/* Render the component ReceitaControleEspecial if the type is 'receitaControleEspecial' */}
        {opcaoSelecionada === 'receitaControleEspecial' && pacienteSelecionado && (
          <div>
            {/* Use a ref to reference the ReceitaControleEspecial component */}
            <ReceitaControleEspecial
              ref={componentRef}
              emitente="Nome do Emitente"
              crm="12345"
              crmUf="UF"
              enderecoEmitente="Endereço do Emitente"
              cidade={cidadePaciente}
              uf={estadoPaciente}
              telefone={telefonePaciente}
              nomePaciente={pacienteSelecionado.name}
              enderecoPaciente={enderecoPaciente}
              medicamentos={medicamentos} /* Pass the list of medications */
              data="01/09/2023"
              assinaturaMedico="Assinatura do Médico"
            />
          </div>
        )}
      </div>
      {/* Add an onClick event to trigger the printing */}
      <ButtonAPI onClick={handlePrintButtonClick}>Visualizar Impressão</ButtonAPI>
    </div>
  </>);
};

export default MasterMedicacao;
