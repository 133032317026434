export function calcularPontoMaisAlto(landmark) {
    if (landmark.length === 0) {
        return null;
    }
    let pontoMaisAlto = landmark[0];
    for (let i = 1; i < landmark.length; i++) {
        if (landmark[i].y > pontoMaisAlto.y) {
            pontoMaisAlto = landmark[i];
        }
    }
    return pontoMaisAlto;
}