import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { NavBreadcrumb, CardDirecionamento, InputPadraoSearch } from '../../../../components';
const cards = [
    { text: 'Vendas', icon: 'fa fa-money', href: 'vendas' },
    { text: 'Agenda', icon: 'fa fa-calendar', href: 'agenda' },
    { text: 'Gráficos', icon: 'fa fa-line-chart', href: 'graficos' },
    { text: 'E-commerce', icon: 'fa fa-money', href: 'ecommerce' },
    { text: 'Serviços', icon: 'fa fa-concierge-bell', href: 'servicos' },
    { text: 'Produtos', icon: 'fa fa-cubes', href: 'produtos' },
    { text: 'Equipamentos', icon: 'fa fa-book', href: 'equipamentos' },
    { text: 'Receitas', icon: 'fa fa-capsules', href: 'medicacao' },
    { text: 'Atestados', icon: 'fa fa-bed', href: 'atestados' },
    { text: 'Encaminhamento', icon: 'fa fa-ambulance', href: 'encaminhamento' },
    { text: 'Exames', icon: 'fa fa-flask', href: 'exames' },
    { text: 'Carrinho', icon: 'fa fa-cart-plus', href: 'carrinho' },
    { text: 'NFe', icon: 'fa fa-barcode', href: 'nfe' },
    { text: 'I.A', icon: 'fa fa-rocket', href: 'IA' },
    { text: 'Pesquisas', icon: 'fa fa-lightbulb-o', href: 'pesquisas' },
    { text: 'Cadastro', icon: 'fa fa-id-card', href: 'cadastro' },
    { text: 'Preferências', icon: 'fa fa-cogs', href: 'preferencias' },
];
export const Index = () => {
    const location = useLocation();
    const { search } = location;
    const [searchTerm, setSearchTerm] = useState("");
    const filteredCards = cards.filter(card => card.text.toLowerCase().includes(searchTerm.toLowerCase()));
    return (<>
        <div className='w-100'>
            <NavBreadcrumb icon={'suitcase fa'} text={'Início Profissional'} />
            <div className="text-center">
                <InputPadraoSearch
                    type="text"
                    placeholder="Filtre páginas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="row d-inline-flex justify-content-center w-100">
                    {filteredCards.map((card, index) => (
                        <CardDirecionamento
                            key={index}
                            text={card.text}
                            icon={`mt-1 fa ${card.icon} fa-3x text-white`}
                            href={`../profissionais/${card.href}${search}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    </>);
}
export default Index;