import { jsPDF } from 'jspdf';
import qrCode from 'qrcode';
import addHeader from '../../../../../../../../../../../components/pdf/addHeader';
import { useEffect, useState } from 'react';
import { drawBlueSquareWithArrowDown, drawBlueSquareWithArrow, drawSquareWithCheckmark, calcularPontoMaisBaixo, calcularPontoMaisAlto } from "../utils"
import ButtonDownload from '../../../../../../../../../../../components/button/ButtonDownload';
const fetchImageAsBase64 = (url, callback) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = url;
    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg');
        callback(dataURL);
    };
    img.onerror = (error) => {
        console.error('Error loading image:', error);
        callback(null);
    };
};
export function PDFGenerator({
    modifiedLandmarks,
    pontoDestacado,
    image,
    landmarks,
    dark_pixel_coords,
    occupied_percentage_rugas_testa,
    user,
    idade,
    rightImage,
    leftImage,
    sugestao,
    faceShape,
    predictionsOthers
}) {
    const [rightImageData, setRightImageData] = useState(null);
    const [leftImageData, setLeftImageData] = useState(null);
    const [oculosUsarImg, setOculosUsarImg] = useState([]);
    const [oculosNUsarImg, setOculosNUsarImg] = useState([]);
    const [professionalInfo, setProfessionalInfo] = useState({
        profissional: '',
        empresa: '',
        enderecoEletronico: '',
        telefone: ''
    });

    useEffect(() => {
        // Recupera os dados do localStorage
        const storedData = localStorage.getItem('professionalData');
        if (storedData) {
            // Converte o JSON para um objeto JavaScript
            const data = JSON.parse(storedData);
            // Atualiza o estado com os dados do localStorage
            setProfessionalInfo({
                profissional: data.profissional || '',
                empresa: data.empresa || '',
                enderecoEletronico: data.enderecoEletronico || '',
                telefone: data.telefone || ''
            });
        }
    }, []);
    useEffect(() => {
        fetchImageAsBase64(rightImage, setRightImageData);
        fetchImageAsBase64(leftImage, setLeftImageData);
        if (sugestao && sugestao.oculos_usar_img) {
            const promises = sugestao.oculos_usar_img.map(url =>
                new Promise((resolve) => fetchImageAsBase64(url, resolve))
            );

            // Aguarda todas as imagens serem carregadas e então define o estado
            Promise.all(promises).then((base64Images) => setOculosUsarImg(base64Images));
        }

        if (sugestao && sugestao.oculos_n_usar_img) {
            const promises = sugestao.oculos_n_usar_img.map(url =>
                new Promise((resolve) => fetchImageAsBase64(url, resolve))
            );

            Promise.all(promises).then((base64Images) => setOculosNUsarImg(base64Images));
        }
    }, [rightImage, leftImage, sugestao]);
    function calcularVolumeLandmarks(landmarks) {
        landmarks.sort((a, b) => a.x - b.x);
        const slices = landmarks.length - 1;
        let volume = 0;
        for (let i = 0; i < slices; i++) {
            const width = landmarks[i + 1].x - landmarks[i].x;
            const height = landmarks[i + 1].y;
            volume += Math.PI * Math.pow(width / 2, 2) * height;
        }
        return volume;
    }
    const handleDownloadPDF = () => {
        console.log(modifiedLandmarks)
        // Congif gerais        
        const doc = new jsPDF();
        // Imagem de Fundo 
        doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
        // Título
        doc.setFont(undefined, 'bold');
        doc.setFontSize(25);
        doc.text('Relatório de Análise Facial', 100, 20, { align: 'center' });
        // Header de indentificação 
        addHeader({ doc, user, idade })
        // Footer de indentificação  
        doc.setFont(undefined, '');
        const addFooter = () => {
            doc.setFont(undefined, 'bold');
            doc.setFontSize(8);
            doc.text('CONTROLADORIA', 100, 275, { align: 'center' });
            doc.text('THOT I.A. CORP. - Inteligência Artifical Ilimitada', 100, 280, { align: 'center' });
            doc.text('Alto Araguaia - Mato Grosso - Brasil', 100, 285, { align: 'center' });
            doc.setTextColor(0, 0, 255);
            doc.text('https://thotiacorp.com.br', 100, 290, { align: 'center' });
            doc.setTextColor(0, 0, 0);
            doc.text('+55 66 996675486', 100, 295, { align: 'center' });
            doc.setFont(undefined, '');
            doc.setFont(undefined, 'bold');
            doc.setFontSize(8);
            doc.text('EXAME (V-1.0.0)', 29, 275, { align: 'center' });
            const qrCodeData = 'https://thotiacorp.com.br';
            qrCode.toDataURL(qrCodeData, { errorCorrectionLevel: 'H' }, (err, qrCodeImage) => {
                if (err) {
                    console.error(err);
                    return;
                }
                doc.addImage(qrCodeImage, 'PNG', 19, 276, 20, 20);
            });
            doc.setTextColor(0, 0, 0);
            doc.setFont(undefined, '');
            doc.setFont(undefined, 'bold');
            doc.setFontSize(8);
            doc.text('VALIDADOR', 170, 275, { align: 'center' });
            doc.text(professionalInfo.empresa, 170, 280, { align: 'center' });
            doc.text(professionalInfo.profissional, 170, 285, { align: 'center' });
            doc.setTextColor(0, 0, 255);
            doc.text(professionalInfo.enderecoEletronico, 170, 290, { align: 'center' });
            doc.setTextColor(0, 0, 0);
            doc.text(professionalInfo.telefone, 170, 295, { align: 'center' });
            doc.setFont(undefined, '');
        };


        // Pagina - 1 - Imagem e landmarks
        const img = new Image();
        img.onload = () => {
            const imgWidth = 180;
            const imgHeight = 190;
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = imgWidth;
            canvas.height = imgHeight;
            context.drawImage(img, 0, 0, imgWidth, imgHeight);
            const imageData = canvas.toDataURL('image/jpeg');
            doc.addImage(imageData, 'JPEG', 15, 70, imgWidth, imgHeight);
            modifiedLandmarks.forEach((landmark, index) => {
                if (landmark && (pontoDestacado === null || pontoDestacado === index)) {
                    const x = 15 + landmark.x * imgWidth;
                    const y = 70 + landmark.y * imgHeight;
                    doc.setDrawColor(255, 0, 0);
                    doc.setFillColor(255, 0, 0);
                    doc.circle(x, y, 0.5, 'F');
                    doc.setFontSize(5);
                    doc.setTextColor(0, 0, 255);
                    doc.text(`${index + 1}`, x + 0, y + 0);
                }
            });
            doc.setTextColor(0, 0, 0);
            addFooter();
            doc.addPage();
            doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            const imgWidthHalf = imgWidth / 2;
            const imgHeightHalf = (imgHeight / imgWidth) * imgWidthHalf;
            const imageX = 5;
            const imageY = 30;
            doc.addImage(imageData, 'JPEG', imageX, imageY, imgWidthHalf, imgHeightHalf);
            //Lábio superior
            const point186 = modifiedLandmarks[185];
            const point41 = modifiedLandmarks[40];
            const point40 = modifiedLandmarks[39];
            const point38 = modifiedLandmarks[37];
            const point1 = modifiedLandmarks[0];
            const point268 = modifiedLandmarks[267];
            const point270 = modifiedLandmarks[269];
            const point271 = modifiedLandmarks[270];
            const point408 = modifiedLandmarks[407];
            const point273 = modifiedLandmarks[272];
            const point272 = modifiedLandmarks[271];
            const point269 = modifiedLandmarks[268];
            const point14 = modifiedLandmarks[13];
            const point39 = modifiedLandmarks[38];
            const point42 = modifiedLandmarks[41];
            const point43 = modifiedLandmarks[42];
            const point79 = modifiedLandmarks[78];
            //Lábio Inferior
            const point96 = modifiedLandmarks[95];
            const point89 = modifiedLandmarks[88];
            const point88 = modifiedLandmarks[87];
            const point318 = modifiedLandmarks[317];
            const point403 = modifiedLandmarks[402];
            const point319 = modifiedLandmarks[318];
            const point325 = modifiedLandmarks[324];
            const point62 = modifiedLandmarks[61];
            const point147 = modifiedLandmarks[146];
            const point107 = modifiedLandmarks[106];
            const point183 = modifiedLandmarks[182];
            const point84 = modifiedLandmarks[83];
            const point19 = modifiedLandmarks[18];
            const point314 = modifiedLandmarks[313];
            const point407 = modifiedLandmarks[406];
            const point322 = modifiedLandmarks[321];
            const point376 = modifiedLandmarks[375];
            const point292 = modifiedLandmarks[292];
            //Linhas
            const point3 = modifiedLandmarks[2];
            const point5 = modifiedLandmarks[8];
            const point9 = modifiedLandmarks[8];
            const point15 = modifiedLandmarks[14];
            const point44 = modifiedLandmarks[43];
            const point47 = modifiedLandmarks[46];
            const point49 = modifiedLandmarks[48];
            const point53 = modifiedLandmarks[52];
            const point54 = modifiedLandmarks[53];
            const point56 = modifiedLandmarks[55];
            const point128 = modifiedLandmarks[127];
            const point136 = modifiedLandmarks[135];
            const point133 = modifiedLandmarks[132];
            const point64 = modifiedLandmarks[63];
            const point67 = modifiedLandmarks[66];
            const point71 = modifiedLandmarks[70];
            const point101 = modifiedLandmarks[100];
            const point105 = modifiedLandmarks[104];
            const point106 = modifiedLandmarks[105];
            const point117 = modifiedLandmarks[116];
            const point131 = modifiedLandmarks[130];
            const point134 = modifiedLandmarks[133];
            const point153 = modifiedLandmarks[152];
            const point157 = modifiedLandmarks[156];
            const point161 = modifiedLandmarks[160];
            const point177 = modifiedLandmarks[176];
            const point222 = modifiedLandmarks[221];
            const point208 = modifiedLandmarks[207];
            const point223 = modifiedLandmarks[222];
            const point227 = modifiedLandmarks[226];
            const point248 = modifiedLandmarks[247];
            const point264 = modifiedLandmarks[263];
            const point274 = modifiedLandmarks[273];
            const point279 = modifiedLandmarks[278];
            const point286 = modifiedLandmarks[285];
            const point294 = modifiedLandmarks[293];
            const point297 = modifiedLandmarks[296];
            const point301 = modifiedLandmarks[300];
            const point330 = modifiedLandmarks[329];
            const point335 = modifiedLandmarks[334];
            const point341 = modifiedLandmarks[340];
            const point354 = modifiedLandmarks[353];
            const point360 = modifiedLandmarks[359];
            const point363 = modifiedLandmarks[362];
            const point375 = modifiedLandmarks[374];
            const point384 = modifiedLandmarks[383];
            const point388 = modifiedLandmarks[387];
            const point398 = modifiedLandmarks[397];
            const point401 = modifiedLandmarks[400];
            const point402 = modifiedLandmarks[401];
            const point428 = modifiedLandmarks[427];
            const point447 = modifiedLandmarks[446];
            const point448 = modifiedLandmarks[447];
            const point442 = modifiedLandmarks[441];
            const point443 = modifiedLandmarks[442];
            const point444 = modifiedLandmarks[443];
            const point445 = modifiedLandmarks[444];
            const point446 = modifiedLandmarks[445];
            const point469 = modifiedLandmarks[469];
            const y5 = imageY + point5.y * imgHeightHalf;
            const x3 = imageX + point3.x * imgWidthHalf;
            const y3 = imageY + point3.y * imgHeightHalf;
            const x375 = imageX + point375.x * imgWidthHalf;
            const y375 = imageY + point375.y * imgHeightHalf;
            const x9 = imageX + point9.x * imgWidthHalf;
            const y9 = imageY + point9.y * imgHeightHalf;
            const y469 = imageY + point469.y * imgHeightHalf;
            const x469 = imageX + point469.x * imgWidthHalf;
            const x177 = imageX + point177.x * imgWidthHalf;
            const y177 = imageY + point177.y * imgHeightHalf;
            const y15 = imageY + point15.y * imgHeightHalf;
         
            const x153 = imageX + point153.x * imgWidthHalf;
            const y153 = imageY + point153.y * imgHeightHalf;
            const y294 = imageY + point294.y * imgHeightHalf;
            const sobrancelhaEsquerda = [
                { nome: '157', x: imageX + point157.x * imgWidthHalf, y: imageY + point157.y * imgHeightHalf / 2 },
                { nome: '47', x: imageX + point47.x * imgWidthHalf, y: imageY + point47.y * imgHeightHalf / 2 },
                { nome: '54', x: imageX + point54.x * imgWidthHalf, y: imageY + point54.y * imgHeightHalf / 2 },
                { nome: '53', x: imageX + point53.x * imgWidthHalf, y: imageY + point53.y * imgHeightHalf / 2 },
                { nome: '223', x: imageX + point223.x * imgWidthHalf, y: imageY + point223.y * imgHeightHalf / 2 },
                { nome: '222', x: imageX + point222.x * imgWidthHalf, y: imageY + point222.y * imgHeightHalf / 2 },
                { nome: '56', x: imageX + point56.x * imgWidthHalf, y: imageY + point56.y * imgHeightHalf / 2 },
                { nome: '67', x: imageX + point67.x * imgWidthHalf, y: imageY + point67.y * imgHeightHalf / 2 },
                { nome: '106', x: imageX + point106.x * imgWidthHalf, y: imageY + point106.y * imgHeightHalf / 2 },
                { nome: '64', x: imageX + point64.x * imgWidthHalf, y: imageY + point64.y * imgHeightHalf / 2 },
                { nome: '71', x: imageX + point71.x * imgWidthHalf, y: imageY + point71.y * imgHeightHalf / 2 },
                { nome: '157', x: imageX + point157.x * imgWidthHalf, y: imageY + point157.y * imgHeightHalf / 2 }
            ];
            const sobrancelhaDireita = [
                { nome: '286', x: imageX + point286.x * imgWidthHalf, y: imageY + point286.y * imgHeightHalf / 2 },
                { nome: '442', x: imageX + point442.x * imgWidthHalf, y: imageY + point442.y * imgHeightHalf / 2 },
                { nome: '443', x: imageX + point443.x * imgWidthHalf, y: imageY + point443.y * imgHeightHalf / 2 },
                { nome: '444', x: imageX + point444.x * imgWidthHalf, y: imageY + point444.y * imgHeightHalf / 2 },
                { nome: '445', x: imageX + point445.x * imgWidthHalf, y: imageY + point445.y * imgHeightHalf / 2 },
                { nome: '446', x: imageX + point446.x * imgWidthHalf, y: imageY + point446.y * imgHeightHalf / 2 },
                { nome: '354', x: imageX + point354.x * imgWidthHalf, y: imageY + point354.y * imgHeightHalf / 2 },
                { nome: '384', x: imageX + point384.x * imgWidthHalf, y: imageY + point384.y * imgHeightHalf / 2 },
                { nome: '301', x: imageX + point301.x * imgWidthHalf, y: imageY + point301.y * imgHeightHalf / 2 },
                { nome: '294', x: imageX + point294.x * imgWidthHalf, y: imageY + point294.y * imgHeightHalf / 2 },
                { nome: '335', x: imageX + point335.x * imgWidthHalf, y: imageY + point335.y * imgHeightHalf / 2 },
                { nome: '297', x: imageX + point297.x * imgWidthHalf, y: imageY + point297.y * imgHeightHalf / 2 },
            ];
            // Pontos mais alto sobrancelha
            const pontoMaisBaixoSombrancelhaEsquerda = calcularPontoMaisAlto(sobrancelhaEsquerda);
            const pontoMaisBaixoSobrancelhaEsquerdaY = pontoMaisBaixoSombrancelhaEsquerda.y
            const pontoMaisBaixoSombrancelhaDireita = calcularPontoMaisAlto(sobrancelhaDireita);
            const pontoMaisBaixoSombrancelhaDireitaY = pontoMaisBaixoSombrancelhaDireita.y
            // Pontos mais baixo sobrancelha
            const pontoMaisAltoSombrancelhaEsquerda = calcularPontoMaisBaixo(sobrancelhaEsquerda);
            const pontoMaisAltosobrancelhaEsquerdaY = pontoMaisAltoSombrancelhaEsquerda.y
            const pontoMaisAltoSombrancelhaDireita = calcularPontoMaisBaixo(sobrancelhaDireita);
            const pontoMaisAltoSombrancelhaDireitaY = pontoMaisAltoSombrancelhaDireita.y
            // Página - 2 - Análises horizontais
            if (point3 && point469 && point153 && point9) {
                const totalImageWidth = imgWidthHalf;
                const halfImageWidth = totalImageWidth / 2;
                doc.setLineWidth(0.2);
                doc.setDrawColor(255, 0, 0);
                const distanciaEsquerda = 5;
                doc.line(distanciaEsquerda, y3, distanciaEsquerda + totalImageWidth, y3);
                doc.line(distanciaEsquerda, y469, distanciaEsquerda + totalImageWidth, y469);
                doc.line(distanciaEsquerda, y9, distanciaEsquerda + totalImageWidth, y9);
                doc.line(distanciaEsquerda, y153, distanciaEsquerda + totalImageWidth, y153);
                doc.setLineWidth(0.2);
                const textX = imageX - halfImageWidth;
                const textY = imageY + imgHeightHalf + 5;
                doc.setFontSize(12);
                const textXRight = textX + 45;
                doc.setTextColor(0, 0, 0);
                const lineLength9to11 = Math.sqrt(Math.pow(x9 - x469, 2) + Math.pow(y469 - y9, 2));
                const distancePixels = Math.sqrt(Math.pow(x3 - x9, 2) + Math.pow(y3 - y9, 2));
                const lineLength3to153 = Math.sqrt(Math.pow(x153 - x3, 2) + Math.pow(y153 - y3, 2));
                const totalLength = lineLength9to11 + distancePixels + lineLength3to153;
                const percentTercoSuperior = (lineLength9to11 / totalLength) * 100;
                const percentTercoMedio = (distancePixels / totalLength) * 100;
                const percentTercoInferior = (lineLength3to153 / totalLength) * 100;
                doc.setTextColor(0, 0, 0);
                const lineLength9to11Text = `Terço Superior - Tam.: ${lineLength9to11.toFixed(2)} unidades (${percentTercoSuperior.toFixed(2)}%)`;
                if (percentTercoSuperior >= 32 && percentTercoSuperior <= 34) {
                    drawSquareWithCheckmark(doc, textXRight + 85, textY + 5, 5);
                } else if (percentTercoSuperior > 34) {
                    drawBlueSquareWithArrow(doc, textXRight + 85, textY + 5, 5);
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 85, textY + 5, 5);
                }
                const distanceText = `Terço Médio - Tam.: ${distancePixels.toFixed(2)} unidades (${percentTercoMedio.toFixed(2)}%)`;
                let equilibrioTercoHorizontal = "Equilibrado";
                if (percentTercoMedio >= 32 && percentTercoMedio <= 34) {
                    drawSquareWithCheckmark(doc, textXRight + 85, textY + 15, 5);
                } else if (percentTercoMedio > 34) {
                    drawBlueSquareWithArrow(doc, textXRight + 85, textY + 15, 5);
                    equilibrioTercoHorizontal = "Desequilibrado"
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 85, textY + 15, 5);
                    equilibrioTercoHorizontal = "Desequilibrado"
                }
                const lineLength3to153Text = `Terço Inferior - Tam.: ${lineLength3to153.toFixed(2)} unidades (${percentTercoInferior.toFixed(2)}%)`;
                if (percentTercoInferior >= 32 && percentTercoInferior <= 34) {
                    drawSquareWithCheckmark(doc, textXRight + 85, textY + 25, 5);
                } else if (percentTercoInferior > 34) {
                    drawBlueSquareWithArrow(doc, textXRight + 85, textY + 25, 5);
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 85, textY + 25, 5);
                }
                doc.setFontSize(12);
                doc.setFont(undefined, 'bold');
                doc.text(`Análises dos Terços Horizontais:`, 5, textY + 2);
                if (equilibrioTercoHorizontal === "Desequilibrado") {
                    doc.setTextColor(255, 0, 0); // Vermelho para desequilibrado
                } else {
                    doc.setTextColor(0, 128, 0); // Verde para equilibrado
                }
                doc.text(`${equilibrioTercoHorizontal}`, 65, textY + 2);
                doc.setTextColor(0, 0, 0);
                doc.setFont(undefined, '');
                doc.text(lineLength9to11Text, textXRight, textY + 9);
                doc.setTextColor(255, 0, 0);
                doc.setFontSize(8);
                doc.text(`Ref - Dist. entre Ponto 469 e Ponto 9 `, textXRight, textY + 13);
                doc.setFontSize(12);
                doc.setTextColor(0, 0, 0);
                doc.text(distanceText, textXRight, textY + 19);
                doc.setTextColor(255, 0, 0);
                doc.setFontSize(8);
                doc.text('Ref - Dist. entre Ponto 9 e Ponto 3', textXRight, textY + 23);
                doc.setFontSize(12);
                doc.setTextColor(0, 0, 0);
                doc.text(lineLength3to153Text, textXRight, textY + 29);
                doc.setTextColor(255, 0, 0);
                doc.setFontSize(8);
                doc.text('Ref - Dist. entre Ponto 3 e Ponto 153', textXRight, textY + 33);
                doc.setTextColor(0, 0, 0);
                doc.text('Os terços devem ter a mesma % entre si para harmonia facial, proporção áurea. ', textXRight, textY + 37);
                doc.text('Nesse caso o padrão ouro seria 33.33% em cada 1/3. Nos  caucasianos moder-', textXRight, textY + 40);
                doc.text('nos o terço inferior  da face é geralmente um pouco maior que  o terço médio. ', textXRight, textY + 43);
                doc.setFont(undefined, '');
                doc.text('Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 167', textXRight, textY + 47);
                doc.setFontSize(12);


                // Página - 2 Análise horizontal do terço superior
                //Segunda imagem lado esquerdo          
                doc.addImage(imageData, 'JPEG', imageX, imageY + 150, imgWidthHalf, imgHeightHalf - 50);
                doc.setFontSize(12);
                doc.text('Análises Terço Horizontal Superior - Sobrancelha ', textXRight, textY + 100);
                doc.setTextColor(0, 0, 0);
                doc.setFont(undefined, '');
                sobrancelhaEsquerda.forEach((landmark, index) => {
                    const { x, y } = landmark;
                    const newY = y + imageY + 119;
                    doc.setDrawColor(255, 0, 0);
                    doc.setFillColor(255, 0, 0);
                    doc.circle(x, newY, 0.1, 'F');
                    doc.setFontSize(5);
                    doc.setTextColor(0, 0, 255);
                    // doc.text(`${index + 1}`, x + 0, newY + 0);
                    if (index === 0) {
                        const tenthLandmark = sobrancelhaEsquerda[11];
                        doc.setDrawColor(255, 0, 0);
                        doc.line(x, newY, tenthLandmark.x, tenthLandmark.y + imageY + 119);
                    }
                    if (index < sobrancelhaEsquerda.length - 1) {
                        const nextLandmark = sobrancelhaEsquerda[index + 1];
                        doc.setDrawColor(255, 0, 0);
                        doc.line(x, newY, nextLandmark.x, nextLandmark.y + imageY + 119);
                    }
                });
                sobrancelhaDireita.forEach((landmark2, index) => {
                    const { x, y } = landmark2;
                    const newY = y + imageY + 119;
                    doc.setDrawColor(255, 0, 0);
                    doc.setFillColor(255, 0, 0);
                    doc.circle(x, newY, 0.1, 'F');
                    doc.setFontSize(5);
                    doc.setTextColor(0, 0, 255);
                    // doc.text(`${index + 11}`, x + 0, newY + 0);
                    if (index === 0) {
                        const twentySecondLandmark = sobrancelhaDireita[11];
                        doc.setDrawColor(0, 0, 0);
                        doc.line(x, newY, twentySecondLandmark.x, twentySecondLandmark.y + imageY + 119);
                    }
                    if (index < sobrancelhaDireita.length - 1) {
                        const nextLandmark = sobrancelhaDireita[index + 1];
                        doc.setDrawColor(0, 0, 0);
                        doc.line(x, newY, nextLandmark.x, nextLandmark.y + imageY + 119);
                    }
                });
                // Calcular volume sobrancelha
                const volumesobrancelhaEsquerda = calcularVolumeLandmarks(sobrancelhaEsquerda);
                const volumesobrancelhaDireita = calcularVolumeLandmarks(sobrancelhaDireita);
                const volumeTotalSobrancelhas = volumesobrancelhaEsquerda + volumesobrancelhaDireita
                const percentsobrancelhaDireita = 100 * (volumesobrancelhaDireita / volumeTotalSobrancelhas);
                const percentsobrancelhaEsquerda = 100 * (volumesobrancelhaEsquerda / volumeTotalSobrancelhas);
                const diferenca = percentsobrancelhaDireita - percentsobrancelhaEsquerda;
                doc.setTextColor(0, 0, 0);
                doc.setFontSize(8);
                doc.setFont(undefined, 'bold');
                doc.text('Implantação', textXRight, textY + 104);
                doc.setFont(undefined, '');
                const diferencaYSobrancelhaPontoMaisAlto = Math.abs(sobrancelhaDireita[1].y - sobrancelhaEsquerda[1].y);
                doc.text(`Sup. dif.: ${diferencaYSobrancelhaPontoMaisAlto.toFixed(4)}`, textXRight, textY + 107);
                doc.text('', textXRight + 10, textY + 107);
                //Todo Diferença de implantação horizontal das sobrancelhas 
                if (percentsobrancelhaDireita >= 40 && percentsobrancelhaDireita <= 60) {
                    drawSquareWithCheckmark(doc, textXRight + 20, textY + 104, 3);
                } else if (percentsobrancelhaDireita > 60) {
                    drawBlueSquareWithArrow(doc, textXRight + 20, textY + 104, 3);
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 20, textY + 104, 3);
                }
                if (percentsobrancelhaDireita >= 40 && percentsobrancelhaDireita <= 60) {
                    drawSquareWithCheckmark(doc, textXRight + 50, textY + 104, 3);
                } else if (percentsobrancelhaDireita > 60) {
                    drawBlueSquareWithArrow(doc, textXRight + 50, textY + 104, 3);
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 50, textY + 104, 3);
                }
                const diferencaYSobrancelhaPontoMaisBaixo = Math.abs(sobrancelhaDireita[9].y - sobrancelhaEsquerda[11].y);
                doc.text(`Inf. dif.:${diferencaYSobrancelhaPontoMaisBaixo.toFixed(4)}`, textXRight + 30, textY + 107);
                doc.setFont(undefined, 'bold');
                doc.text('Volume', textXRight, textY + 111);
                doc.setFont(undefined, '');
                doc.text('Volume da Sobrancelha Esq.: ' + volumesobrancelhaDireita.toFixed(2) + ' (' + percentsobrancelhaDireita.toFixed(2) + '%)', textXRight, textY + 115);
                if (percentsobrancelhaDireita >= 40 && percentsobrancelhaDireita <= 60) {
                    drawSquareWithCheckmark(doc, textXRight + 86, textY + 112, 3);
                } else if (percentsobrancelhaDireita > 60) {
                    drawBlueSquareWithArrow(doc, textXRight + 86, textY + 112, 3);
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 86, textY + 112, 3);
                }
                doc.text('Volume da Sobrancelha Dir.: ' + volumesobrancelhaEsquerda.toFixed(2) + ' (' + percentsobrancelhaEsquerda.toFixed(2) + '%)', textXRight, textY + 119);
                if (percentsobrancelhaEsquerda >= 40 && percentsobrancelhaEsquerda <= 60) {
                    drawSquareWithCheckmark(doc, textXRight + 86, textY + 116, 3);
                } else if (percentsobrancelhaEsquerda > 60) {
                    drawBlueSquareWithArrow(doc, textXRight + 86, textY + 116, 3);
                } else {
                    drawBlueSquareWithArrowDown(doc, textXRight + 86, textY + 116, 3);
                }

                doc.setFont(undefined, 'bold');
                doc.text('Diferença Volumétrica da Sobrancelha: ' + diferenca.toFixed(2), textXRight, textY + 124);
                doc.setFont(undefined, '');
                doc.text('A diferença volumétrica da sobrancelha deve ser a menor possível.', textXRight, textY + 129);
                doc.text('Padrão de porporção áurea os lados precisam ser semelhante; iguais. ', textXRight, textY + 133);
                doc.setTextColor(0, 0, 0);
                addFooter();
            }
            const imageXDir = 105;
            const imageYDir = 30;
            const linhaMediaPag = imageXDir - 5;
            const yStartVerticalLineMediaPag = 30;
            const yEndVerticalLineMediaPag = 75 + imgHeight;
            const x5 = imageXDir + point5.x * imgWidthHalf;
            const x297 = imageXDir + point297.x * imgWidthHalf;
            const x44 = imageXDir + point44.x * imgWidthHalf;
            const y44 = imageYDir + point44.y * imgHeightHalf;
            const x49 = imageXDir + point49.x * imgWidthHalf;
            const y49 = imageYDir + point49.y * imgHeightHalf;
            const x56 = imageXDir + point56.x * imgWidthHalf;
            const y56 = imageYDir + point56.y * imgHeightHalf;
            const x133 = imageXDir + point133.x * imgWidthHalf;
            const y133 = imageYDir + point133.y * imgHeightHalf;
            const x134 = imageXDir + point134.x * imgWidthHalf;
            const y134 = imageYDir + point134.y * imgHeightHalf;
            const x136 = imageXDir + point136.x * imgWidthHalf;
            const y136 = imageYDir + point136.y * imgHeightHalf;
            const x128 = imageXDir + point128.x * imgWidthHalf;
            const y128 = imageYDir + point128.y * imgHeightHalf;
            const x67 = imageXDir + point67.x * imgWidthHalf;
            const x71 = imageXDir + point71.x * imgWidthHalf;
            const x105 = imageXDir + point106.x * imgWidthHalf;
            const x106 = imageXDir + point105.x * imgWidthHalf;
            const x131 = imageXDir + point131.x * imgWidthHalf;
            const x161 = imageXDir + point161.x * imgWidthHalf;
            const y161 = imageYDir + point161.y * imgHeightHalf;
            const x248 = imageXDir + point248.x * imgWidthHalf;
            const y248 = imageYDir + point248.y * imgHeightHalf;
            const x227 = imageXDir + point227.x * imgWidthHalf;
            const x264 = imageXDir + point264.x * imgWidthHalf;
            const x274 = imageXDir + point274.x * imgWidthHalf;
            const y274 = imageYDir + point274.y * imgHeightHalf;
            const x279 = imageXDir + point279.x * imgWidthHalf;
            const y279 = imageYDir + point279.y * imgHeightHalf;
            const x286 = imageXDir + point286.x * imgWidthHalf;
            const y286 = imageYDir + point286.y * imgHeightHalf;
            const x301 = imageXDir + point301.x * imgWidthHalf;
            const x330 = imageXDir + point330.x * imgWidthHalf;
            const y330 = imageYDir + point330.y * imgHeightHalf;
            const x360 = imageXDir + point360.x * imgWidthHalf;
            const y360 = imageYDir + point360.y * imgHeightHalf;
            const x363 = imageXDir + point363.x * imgWidthHalf;
            const y363 = imageYDir + point363.y * imgHeightHalf;
            const x388 = imageXDir + point388.x * imgWidthHalf;
            const y388 = imageYDir + point388.y * imgHeightHalf;
            const x398 = imageXDir + point398.x * imgWidthHalf;
            const y398 = imageYDir + point398.y * imgHeightHalf;
            const y117 = imageYDir + point117.y * imgHeightHalf;
            const x117 = imageXDir + point117.x * imgWidthHalf;
            const x208 = imageXDir + point208.x * imgWidthHalf;
            const y208 = imageYDir + point208.y * imgHeightHalf;
            const x101 = imageXDir + point101.x * imgWidthHalf;
            const y101 = imageYDir + point101.y * imgHeightHalf;
            const x335 = imageXDir + point335.x * imgWidthHalf;
            const x341 = imageXDir + point341.x * imgWidthHalf;
            const y341 = imageYDir + point341.y * imgHeightHalf;
            const x401 = imageXDir + point401.x * imgWidthHalf;
            const y401 = imageYDir + point401.y * imgHeightHalf;
            const x402 = imageXDir + point402.x * imgWidthHalf;
            const y402 = imageYDir + point402.y * imgHeightHalf;
            const x428 = imageXDir + point428.x * imgWidthHalf;
            const y428 = imageYDir + point428.y * imgHeightHalf;
            const x447 = imageXDir + point447.x * imgWidthHalf;
            const x448 = imageXDir + point448.x * imgWidthHalf;
            const y448 = imageYDir + point448.y * imgHeightHalf;
            const projecaoMalarEsq = [
                { x: imageX + point117.x * imgWidthHalf, y: imageY + point117.y * imgHeightHalf },
                { x: imageX + point208.x * imgWidthHalf, y: imageY + point208.y * imgHeightHalf },
                { x: imageX + point101.x * imgWidthHalf, y: imageY + point101.y * imgHeightHalf },
                { x: imageX + point117.x * imgWidthHalf, y: imageY + point117.y * imgHeightHalf },
            ];
            const projecaoMalarDir = [
                { x: imageX + point330.x * imgWidthHalf, y: imageY + point330.y * imgHeightHalf },
                { x: imageX + point428.x * imgWidthHalf, y: imageY + point428.y * imgHeightHalf },
                { x: imageX + point341.x * imgWidthHalf, y: imageY + point341.y * imgHeightHalf },
                { x: imageX + point330.x * imgWidthHalf, y: imageY + point330.y * imgHeightHalf },
            ];

            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            doc.line(linhaMediaPag, yStartVerticalLineMediaPag, linhaMediaPag, yEndVerticalLineMediaPag + 5);

            // Página - 2  imagem 1 Análise do quintos verticais
            doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(x49, yStartVerticalLineMediaPag, x49, yEndVerticalLineMediaPag - 140); // Quinto Central
            doc.line(x279, yStartVerticalLineMediaPag, x279, yEndVerticalLineMediaPag - 140); // Quinto Central
            doc.line(x131, yStartVerticalLineMediaPag, x131, yEndVerticalLineMediaPag - 140); // Quinto Central
            doc.line(x264, yStartVerticalLineMediaPag, x264, yEndVerticalLineMediaPag - 140); // Quinto Central
            // Calcular a distância entre as linhas "Quinto Central"
            const distanciaQuintoCentral = x131 - x49;
            const distanciaQuintoCentralDir = x264 - x279;
            doc.line(distanciaQuintoCentral + x131, yStartVerticalLineMediaPag, distanciaQuintoCentral + x131, yEndVerticalLineMediaPag - 140);
            doc.line(distanciaQuintoCentralDir + x264, yStartVerticalLineMediaPag, distanciaQuintoCentralDir + x264, yEndVerticalLineMediaPag - 140);
            const lineLength49to279 = Math.abs(x49 - x279);
            const lineLength49to131 = Math.abs(x49 - x131);
            const lineLength131to264 = Math.abs(x264 - x279);
            const totalLength = lineLength49to279 + lineLength49to131 + lineLength49to131 + lineLength131to264 + lineLength131to264;
            const percentQuintoCentral = (lineLength49to279 / totalLength) * 100;
            const percentQuintoCentralEsq = (lineLength49to131 / totalLength) * 100;
            const percentQuintoCentralDir = (lineLength131to264 / totalLength) * 100;
            const textY = imageYDir + imgHeightHalf + 5;


            // Página - 2  texto da Análise do quintos verticais
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            let equilibrioQuintosVerticais = "Equilibrado"
            doc.text('Análises dos Quintos Verticais:', imageXDir, textY + 2);
            doc.setFont(undefined, '');
            doc.text('Quinto Cent. Tam.: ' + lineLength49to279.toFixed(2) + ' unidades (' + percentQuintoCentral.toFixed(2) + '%)', imageXDir, textY + 29);
            if (percentQuintoCentral >= 19 && percentQuintoCentral <= 21) {
                drawSquareWithCheckmark(doc, imageXDir + 85, textY + 25, 5);
            } else if (percentQuintoCentral > 21) {
                drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 25, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            } else {
                drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 25, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            }
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Dist. entre Ponto 49 e Ponto 279', imageXDir, textY + 33);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.text('Quinto Cent. Esq. Tam.: ' + lineLength49to131.toFixed(2) + ' unidades (' + percentQuintoCentralEsq.toFixed(2) + '%)', imageXDir, textY + 19);
            if (percentQuintoCentralEsq >= 19 && percentQuintoCentralEsq <= 21) {
                drawSquareWithCheckmark(doc, imageXDir + 85, textY + 15, 5);
            } else if (percentQuintoCentralEsq > 21) {
                drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 15, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            } else {
                drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 15, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            }
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Dist. entre Ponto 49 e Ponto 131', imageXDir, textY + 23);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.text('Quinto Esq. Tam.: ' + lineLength49to131.toFixed(2) + ' unidades (' + percentQuintoCentralEsq.toFixed(2) + '%)', imageXDir, textY + 9);
            if (percentQuintoCentralEsq >= 19 && percentQuintoCentralEsq <= 21) {
                drawSquareWithCheckmark(doc, imageXDir + 85, textY + 5, 5);
            } else if (percentQuintoCentralEsq > 21) {
                drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 5, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            } else {
                drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 5, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            }
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Mesma dist. entre Ponto 49 e Ponto 131', imageXDir, textY + 13);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.text('Quinto Cent. Dir. Tam.: ' + lineLength131to264.toFixed(2) + ' unidades (' + percentQuintoCentralDir.toFixed(2) + '%)', imageXDir, textY + 39);
            if (percentQuintoCentralDir >= 19 && percentQuintoCentralDir <= 21) {
                drawSquareWithCheckmark(doc, imageXDir + 85, textY + 35, 5);
            } else if (percentQuintoCentralDir > 21) {
                drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 35, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            } else {
                drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 35, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            }
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Dist. entre Ponto 279 e Ponto 264', imageXDir, textY + 43);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.text('Quinto Dir. Tam.: ' + lineLength131to264.toFixed(2) + ' unidades (' + percentQuintoCentralDir.toFixed(2) + '%)', imageXDir, textY + 49);
            if (percentQuintoCentralDir >= 19 && percentQuintoCentralDir <= 21) {
                drawSquareWithCheckmark(doc, imageXDir + 85, textY + 45, 5);
            } else if (percentQuintoCentralDir > 21) {
                drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 45, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            } else {
                drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 45, 5);
                equilibrioQuintosVerticais = "Desequilibrado"
            }
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Mesma dist. entre Ponto 279 e Ponto 264', imageXDir, textY + 53);
            doc.setFontSize(8);
            doc.setTextColor(0, 0, 0);
            doc.text('Cada 1/5 deve ter 20%  da largura total facial. Deve ser notado que o rosto deve ', imageXDir, textY + 59);
            doc.text('enquadrar nos traços vermelhos. Devios de normalidade  no  quadrante  médio ', imageXDir, textY + 63);
            doc.text('base do nariz podem deixar as orelhas fora das linhas - nariz largo', imageXDir, textY + 67);
            doc.text('Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 164', imageXDir, textY + 71);
            if (equilibrioQuintosVerticais === "Desequilibrado") {
                doc.setTextColor(255, 0, 0); // Vermelho para desequilibrado
            } else {
                doc.setTextColor(0, 128, 0); // Verde para equilibrado
            }
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text(`${equilibrioQuintosVerticais}`, imageXDir + 58, textY + 2);
            doc.setTextColor(0, 0, 0); // Verde para equilibrado
            // Página - 2 imagem 2 esquerda
            doc.addImage(imageData, 'JPEG', imageXDir, imageY + 175, imgWidthHalf, imgHeightHalf - 50);
            const totalImageWidth = imgWidthHalf;
            const distanciaEsquerda = 5;
            // Linhas da implantação horizontal dos olhos
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(distanciaEsquerda, pontoMaisAltosobrancelhaEsquerdaY + 149, distanciaEsquerda + totalImageWidth, pontoMaisAltosobrancelhaEsquerdaY + 149);
            doc.line(distanciaEsquerda, pontoMaisBaixoSobrancelhaEsquerdaY + 149, distanciaEsquerda + totalImageWidth, pontoMaisBaixoSobrancelhaEsquerdaY + 149);
            doc.setLineWidth(0.2);
            doc.setDrawColor(0, 0, 0);
            doc.line(distanciaEsquerda, pontoMaisAltoSombrancelhaDireitaY + 149, distanciaEsquerda + totalImageWidth, pontoMaisAltoSombrancelhaDireitaY + 149);
            doc.line(distanciaEsquerda, pontoMaisBaixoSombrancelhaDireitaY + 149, distanciaEsquerda + totalImageWidth, pontoMaisBaixoSombrancelhaDireitaY + 149);
            //Linhas ideais para sombracelha
            doc.setDrawColor(16, 173, 34);
            doc.line(x49, yStartVerticalLineMediaPag + 175, x49, yStartVerticalLineMediaPag + 175 + y49 - 50); // Asa do nariz
            doc.line(x279, yStartVerticalLineMediaPag + 175, x279, yStartVerticalLineMediaPag + 175 + y279 - 50); // Asa do nariz
            doc.line(x161, y161 + 150, x161, y161 + 160); // Iris externo
            doc.line(x388, y388 + 150, x388, y388 + 160); // Iris externo
            // doc.line(x264, yStartVerticalLineMediaPag + 149, x264, yEndVerticalLineMediaPag + 140); // Quinto Lateral
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text('Desvio Linha Média - Sobrancelha', imageXDir, textY + 125);
            doc.setFont(undefined, '');
            doc.setFontSize(8);
            doc.text('Linhas:', imageXDir, textY + 129);
            doc.setTextColor(255, 0, 0)
            doc.text('__', imageXDir + 10, textY + 128);
            doc.text('__________', imageXDir + 15, textY + 129);
            doc.text('Vermelha', imageXDir + 15, textY + 132);
            doc.setTextColor(0, 0, 0)
            doc.text('Média e Final', imageXDir + 14, textY + 129);
            doc.text('__', imageXDir + 35, textY + 128);
            doc.text('_____________', imageXDir + 38, textY + 129);
            doc.text('Preta', imageXDir + 45, textY + 132);
            doc.setTextColor(0, 0, 0)
            doc.text('____________', imageXDir + 40, textY + 129);
            doc.text('Início e Arq. G.', imageXDir + 39, textY + 129);
            doc.setTextColor(107, 63, 160)
            doc.text('__', imageXDir + 64, textY + 128);
            doc.text('____________', imageXDir + 68, textY + 129);
            doc.text('Roxa', imageXDir + 74, textY + 132);
            doc.setTextColor(0, 0, 0)
            doc.text('Reta e Arq. P.', imageXDir + 69, textY + 129);
            doc.text('Ref: PETERSON, L. J. et al. Cirurgia oral e maxilofacial contemporânea. 6. ed.', imageXDir, textY + 136);
            doc.text('Rio de Janeiro: Elsevier, 2014. p. 1244', imageXDir, textY + 139);
            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            doc.line(linhaMediaPag, yStartVerticalLineMediaPag + 175, linhaMediaPag, yEndVerticalLineMediaPag - 15);
            // Página - 2 imagem 2 direita
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            // Linha média da face
            doc.line(x5, yStartVerticalLineMediaPag + 175, x5, yEndVerticalLineMediaPag - 15);
            const distanciaX56toX5 = Math.abs(x56 - x5);
            const distanciaX286toX5 = Math.abs(x286 - x5);
            const distanciaX67toX5 = Math.abs(x67 - x5);
            const distanciaX297toX5 = Math.abs(x297 - x5);
            const distanciaX106toX5 = Math.abs(x106 - x5);
            const distanciaX335toX5 = Math.abs(x335 - x5);
            const distanciaX227toX5 = Math.abs(x227 - x5);
            const distanciaX447toX5 = Math.abs(x447 - x5);
            const distanciaX71toX5 = Math.abs(x71 - x5);
            const distanciaX301toX5 = Math.abs(x301 - x5);
            //Inicio sobrancelha
            doc.setLineWidth(0.1);
            doc.setDrawColor(0, 0, 0)
            doc.line(x56, yStartVerticalLineMediaPag + 175, x56, yEndVerticalLineMediaPag - 43); //Inicio sobrancelha esq.    
            doc.line(x286, yStartVerticalLineMediaPag + 175, x286, yEndVerticalLineMediaPag - 43); //Inicio sobrancelha dir. 
            doc.line(x56, yStartVerticalLineMediaPag + 175, x286, yStartVerticalLineMediaPag + 175);
            doc.setDrawColor(107, 63, 160)
            doc.line(x67, yStartVerticalLineMediaPag + 174.6, x67, yEndVerticalLineMediaPag - 45); //Inicio arqueamento interno sobrancelha esq.
            doc.line(x297, yStartVerticalLineMediaPag + 174.6, x297, yEndVerticalLineMediaPag - 45); //Inicio arqueamento interno sobrancelha dir.
            doc.line(x67, yStartVerticalLineMediaPag + 174.6, x297, yStartVerticalLineMediaPag + 174.6);
            doc.setDrawColor(0, 0, 0)
            doc.line(x106, yStartVerticalLineMediaPag + 174.4, x106, yEndVerticalLineMediaPag - 44); //Arqueamento g. sobrancelha esq.          
            doc.line(x335, yStartVerticalLineMediaPag + 174.4, x335, yEndVerticalLineMediaPag - 44); //Arqueamento g. sobrancelha dir.
            doc.line(x106, yStartVerticalLineMediaPag + 174.4, x335, yStartVerticalLineMediaPag + 174.4);
            doc.setDrawColor(107, 63, 160)
            doc.line(x227, yStartVerticalLineMediaPag + 174.2, x227, yEndVerticalLineMediaPag - 44);//Arqueamento peq. sobrancelha
            doc.line(x447, yStartVerticalLineMediaPag + 174.2, x447, yEndVerticalLineMediaPag - 44); //Arqueamento peq. sobrancelha
            doc.line(x227, yStartVerticalLineMediaPag + 174.2, x447, yStartVerticalLineMediaPag + 174.2);
            doc.setDrawColor(255, 0, 0)
            doc.line(x71, yStartVerticalLineMediaPag + 174, x71, yEndVerticalLineMediaPag - 43);//Final da sobrancelha esq.
            doc.line(x301, yStartVerticalLineMediaPag + 174, x301, yEndVerticalLineMediaPag - 43);//Final da sobrancelha dir.
            doc.line(x71, yStartVerticalLineMediaPag + 174, x301, yStartVerticalLineMediaPag + 174);
            doc.setFontSize(5);
            doc.setTextColor(0, 0, 0);
            doc.text(`${distanciaX56toX5.toFixed(0)}`, x56 + 1, yEndVerticalLineMediaPag - 58);
            doc.text(`${distanciaX286toX5.toFixed(0)}`, x5 + 1, yEndVerticalLineMediaPag - 58);
            doc.setTextColor(107, 63, 160);
            doc.text(`${distanciaX67toX5.toFixed(0)}`, x67 + 1, yEndVerticalLineMediaPag - 57);
            doc.text(`${distanciaX297toX5.toFixed(0)}`, x286 + 1, yEndVerticalLineMediaPag - 57);
            doc.setTextColor(0, 0, 0);
            doc.text(`${distanciaX106toX5.toFixed(0)}`, x106 + 1, yEndVerticalLineMediaPag - 58);
            doc.text(`${distanciaX335toX5.toFixed(0)}`, x297 + 1, yEndVerticalLineMediaPag - 58);
            doc.setTextColor(107, 63, 160);
            doc.text(`${distanciaX227toX5.toFixed(0)}`, x227 + 1, yEndVerticalLineMediaPag - 57);
            doc.text(`${distanciaX447toX5.toFixed(0)}`, x335 + 1, yEndVerticalLineMediaPag - 57);
            doc.setTextColor(255, 0, 0);
            doc.text(`${distanciaX71toX5.toFixed(0)}`, x71 + 1, yEndVerticalLineMediaPag - 58);
            doc.text(`${distanciaX301toX5.toFixed(0)}`, x447, yEndVerticalLineMediaPag - 58);


            // Página - 3
            doc.addPage();
            doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            // Página - 3 imagem 1 lado esquerdo
            doc.addImage(imageData, 'JPEG', imageX, imageY, imgWidthHalf, imgHeightHalf);
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(distanciaEsquerda, y469, distanciaEsquerda + totalImageWidth, y469);
            doc.line(distanciaEsquerda, y9, distanciaEsquerda + totalImageWidth, y9);
            let parsedCoords;
            if (dark_pixel_coords !== undefined && dark_pixel_coords !== null) {
                try {
                    // Converta a string JSON corrigida em um array de objetos
                    parsedCoords = JSON.parse(dark_pixel_coords.replace(/'/g, '"'));
                } catch (error) {
                    console.error("Erro ao fazer o parse de dark_pixel_coords:", error);
                }
                if (Array.isArray(parsedCoords)) {
                    parsedCoords.forEach((coord, index) => {
                        // Função para ajustar o divisor baseado na quantidade de dígitos
                        // Calcule as posições x e y com base nas dimensões da imagem
                        const x = 5 + coord.x / 1000 * imgWidthHalf;
                        const y = 30 + coord.y / 1000 * imgHeightHalf;
                        // Configura a cor e o preenchimento para desenhar o ponto
                        doc.setDrawColor(255, 0, 0);
                        doc.setFillColor(255, 0, 0);
                        // Desenha um círculo no ponto calculado
                        doc.circle(x, y, 0.3, 'F');
                        // Configura o tamanho da fonte e a cor do texto
                        doc.setFontSize(5);
                        doc.setTextColor(0, 0, 255);
                        // Desenha o número do ponto próximo ao círculo
                        // doc.text(`${index + 1}`, x, y);
                    });
                } else {
                    console.error("dark_pixel_coords não é um array.");
                }
            } else {
                console.error("dark_pixel_coords está indefinido ou nulo.");
            }
            doc.setLineWidth(0.2);
            doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text(`Análises dos Terços Horizontais Superior - Man-`, 5, textY - 1);
            doc.text(`chas, Pintas e Rugas: ${occupied_percentage_rugas_testa.toFixed(0)} %  Idade Ap.:${predictionsOthers.age}`, 5, textY + 3);
            doc.setTextColor(0, 0, 0);
            doc.setFont(undefined, '');
            doc.setFontSize(8);
            doc.text(`Monitore constantemente essas variáveis a cada seis meses. Utilize estratégias`, 5, textY + 6);
            doc.text(`de manutenção ou rejuvenescimento. Mascare as imperfeições com produtos  `, 5, textY + 9);
            doc.setFontSize(5);
            doc.text(`Ref: Ayres, E. L., & Sandoval, M. H. L. (2016). Toxina botulínica na dermatologia: guia prático de técnicas e produtos. 1. ed.  `, 5, textY + 12);
            doc.text(`Rio de Janeiro: Guanabara Koogan.`, 5, textY + 14);

            // Página - 3 imagem 1 lado esquerdo
            doc.addImage(imageData, 'JPEG', imageX, imageY + 115, imgWidthHalf, imgHeightHalf);
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(distanciaEsquerda, y375 + 115, distanciaEsquerda + totalImageWidth, y375 + 115); // Iris inferior direita
            doc.line(distanciaEsquerda, y9 + 115, distanciaEsquerda + totalImageWidth, y9 + 115); //Glabela
            doc.setLineWidth(0.2);


            // Página - 3 imagem 2 lado esquerdo
            doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text(`Análises dos Terços Horizontais Médio - Olhos`, 5, textY + 115);
            doc.setFontSize(8);
            doc.setFont(undefined, '');
            doc.text(`Os olhos devem estar implantados entre as linhas `, 5, textY + 119);
            doc.setTextColor(0, 0, 0);
            doc.setFont(undefined, '');
            doc.setFontSize(12);


            // Página - 3 imagem 1 lado direito
            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            doc.line(linhaMediaPag, yStartVerticalLineMediaPag, linhaMediaPag, yEndVerticalLineMediaPag + 5);
            doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(x5, yStartVerticalLineMediaPag, x5, yEndVerticalLineMediaPag - 140); // Linha média
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            const tamanhoNarizEsq = x5 - x49
            const tamanhoNarizDir = x279 - x5
            const distanciaIntercantalEsq = x5 - x134
            const distanciaIntercantalDir = x363 - x5
            const distanciaIntercantalExternaEsq = x5 - x248
            const distanciaIntercantalExternaDir = x360 - x5
            doc.line(x49, y49, x279, y279); // Base do nariz
            doc.line(x134, y134, x363, y363); // Intercantal
            doc.line(x248, y248 - 8, x360, y360 - 9); // Intercantal externo
            doc.line(x248, y248, x248, y248 - 8); // Intercantal externo
            doc.line(x360, y360, x360, y360 - 9); // Intercantal externo
            doc.setFont(undefined, 'bold');
            doc.setFontSize(8);
            doc.text(tamanhoNarizEsq.toString().substring(0, 3), x49, y49);
            doc.text(tamanhoNarizDir.toString().substring(0, 3), x279 - 3, y279);
            doc.text(distanciaIntercantalEsq.toString().substring(0, 3), x134, y134);
            doc.text(distanciaIntercantalDir.toString().substring(0, 3), x363 - 3, y363);
            doc.text(distanciaIntercantalExternaEsq.toString().substring(0, 4), x248, y248 - 8);
            doc.text(distanciaIntercantalExternaDir.toString().substring(0, 4), x360 - 3, y360 - 9);
            doc.setTextColor(0, 0, 0);
            doc.setLineWidth(0.1);
            doc.setDrawColor(255, 255, 255);
            doc.line(x330, y330, x428, y428); //Projeção malar dir.
            doc.line(x341, y341, x428, y428); //Projeção malar dir.          
            doc.line(x330, y330, x341, y341,); //Projeção malar dir.
            doc.line(x117, y117, x208, y208); //Projeção malar esq.
            doc.line(x208, y208, x101, y101); //Projeção malar esq.          
            doc.line(x117, y117, x101, y101,); //Projeção malar esq.
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text('Desvios da Linha Média - Olhos, Nariz e Projeção', imageXDir, textY - 1);
            doc.text('Malar com Volume', imageXDir, textY + 3);
            doc.setFont(undefined, '');
            doc.setTextColor(255, 0, 0);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(12);
            const volumeMalarEsq = calcularVolumeLandmarks(projecaoMalarEsq);
            const volumeMalarDir = calcularVolumeLandmarks(projecaoMalarDir);
            // Verifica qual é maior e ajusta o texto para "P" e "G"
            let textoEsq = volumeMalarEsq > volumeMalarDir ? "G" : "P";
            let textoDir = volumeMalarDir > volumeMalarEsq ? "G" : "P";
            // Exibe "P" ou "G" em vez dos números
            doc.text(textoEsq, x208 - 2, y208 + 4);
            doc.text(textoDir, x428 - 1, y428 + 4);
            doc.setTextColor(0, 0, 0);
            doc.setFont(undefined, '');
            doc.setFontSize(8);
            doc.text(`Para mimetizar um dos lados repare na cor das projeções malares. Em menor`, imageXDir, textY + 7);
            doc.text(`grau homogenize a cor da pele para espalhar a luz `, imageXDir, textY + 10);
            doc.text(`Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 166 `, imageXDir, textY + 14);


            // Página - 3 imagem 2 lado direito
            doc.addImage(imageData, 'JPEG', imageXDir, imageY + 115, imgWidthHalf, imgHeightHalf);
            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            doc.line(linhaMediaPag, yStartVerticalLineMediaPag + 115, linhaMediaPag, yEndVerticalLineMediaPag - 25);
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(x5, yStartVerticalLineMediaPag + 115, x5, yEndVerticalLineMediaPag - 25); // Linha média
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            const tamanhoMentoEsq = x5 - x177 - imageXDir + 5
            const tamanhoMentoDir = x401 - x5
            const tamanhoBocaEsq = x5 - x44
            const tamanhoBocaDir = x274 - x5
            doc.line(x274, yStartVerticalLineMediaPag + 115, x274, yStartVerticalLineMediaPag + 85 + y274); //boca dir. para cima
            doc.line(x44, yStartVerticalLineMediaPag + 115, x44, yStartVerticalLineMediaPag + 85 + y44); //boca esq. para cima
            doc.line(x274, yStartVerticalLineMediaPag + 85 + y274, x44, yStartVerticalLineMediaPag + 85 + y44); //boca
            doc.line(x401, yStartVerticalLineMediaPag + 85 + y401, x177 + imageXDir - 5, yStartVerticalLineMediaPag + 85 + y177); //mento
            doc.text(tamanhoMentoDir.toString().substring(0, 3), x401 - 3, y401 + 115);
            doc.text(tamanhoMentoEsq.toString().substring(0, 3), x177 + imageXDir - 5, y177 + 115);
            doc.text(tamanhoBocaEsq.toString().substring(0, 3), x44, y44 + 115);
            doc.text(tamanhoBocaDir.toString().substring(0, 3), x274 - 3, y274 + 115);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text(`Desvio da Linha Média - Boca e Mento `, imageXDir, textY + 115);
            doc.setFontSize(8);
            doc.setFont(undefined, '');
            doc.text('Em termos de harmonia facial, existe uma regra clássica de proporção que afir-', imageXDir, textY + 119);
            doc.text('ma que os cantos dos lábios (ou comissuras) devem estar alinhados com a dis-', imageXDir, textY + 122);
            doc.text('tância interna das íris dos olhos ou pupilas', imageXDir, textY + 125);
            doc.setFontSize(5);
            doc.text('Refs: PALUD Alice, et al. Management of otitis media with effusion in cleft palate children. Acta Otorhinolaryngologica Ita-', imageXDir, textY + 129);
            doc.text('lica, [S.l.], v. 29, n. 1, p. 38-42, 2009. Disponível em https://www.actaitalica.it/article/view/38', imageXDir, textY + 132);
            doc.text('Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 167', imageXDir, textY + 135);
            addFooter();

            // Página - 4
            doc.addPage();
            doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            doc.setLineWidth(0.5);
            doc.setDrawColor(0, 0, 0);
            doc.line(linhaMediaPag, yStartVerticalLineMediaPag, linhaMediaPag, yEndVerticalLineMediaPag + 5);// Linha média pag.
            addFooter();

            doc.addImage(imageData, 'JPEG', 5, imageYDir, imgWidthHalf, imgHeightHalf);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.setDrawColor(255, 0, 0);

            doc.text('Análises Verticais Do Terço Inferior: ', 5, textY + 80);
            doc.setFont(undefined, '');
            const lineLength3to15 = Math.abs(y15 - y3);
            const lineLength3to153 = Math.sqrt(Math.pow(x153 - x3, 2) + Math.pow(y153 - y3, 2));
            const percentTercoInferior = (lineLength3to153 / totalLength) * 100;
            const percentTercoInferiorS = 100 * (lineLength3to15 / percentTercoInferior);


            if (percentTercoInferiorS >= 32 && percentTercoInferiorS <= 34) {
                drawSquareWithCheckmark(doc, 5 + 85, textY + 81, 5);
            } else if (percentTercoInferiorS > 34) {
                drawBlueSquareWithArrow(doc, 5 + 85, textY + 81, 5);
            } else {
                drawBlueSquareWithArrowDown(doc, 5 + 85, textY + 81, 5);
            }
            const lineLength3to15Text = `Terço Inf. Sup. - Tam.: ${lineLength3to15.toFixed(2)} unidades (${percentTercoInferiorS.toFixed(2)}%)`;
            const valorRestante = 100 - percentTercoInferiorS;
            const lineLength15to153 = Math.abs(y153 - y15);

            const lineLength15to153Text = `Terço Inf. Inf. - Tam.: ${lineLength15to153.toFixed(2)} unidades (${valorRestante.toFixed(2)}%)`;
            if (valorRestante >= 65 && valorRestante <= 67) {
                drawSquareWithCheckmark(doc, 5 + 85, textY + 92, 5);
            } else if (valorRestante > 67) {
                drawBlueSquareWithArrow(doc, 5 + 85, textY + 92, 5);
            } else {
                drawBlueSquareWithArrowDown(doc, 5 + 85, textY + 92, 5);
            }
            doc.text(lineLength3to15Text, 5, textY + 85);
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Dist. entre Ponto 3 e Ponto 15', 5, textY + 90);
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.text(lineLength15to153Text, 5, textY + 95);
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(8);
            doc.text('Ref - Dist. entre Ponto 15 e Ponto 153', 5, textY + 100);
            doc.setTextColor(0, 0, 0);
            doc.setFont(undefined, 'bold');
            doc.text('Tam. total: ' + lineLength3to153.toFixed(2), 5, textY + 105);
            doc.setFont(undefined, '');
            doc.text('O terço inf. sup. deve ter 1/3 33.3% e o inf.inf. 2/3 66.6% entre si para harmonia', 5, textY + 109);
            doc.text('facial,  proporções áureas. Assim o terço  inferior pode ser dividido  em terços:', 5, textY + 112);
            doc.text('a boca deve estar  a um  terço da  distância entre a base do nariz e o queixo', 5, textY + 115);
            doc.text('Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 167', 5, textY + 126);
            doc.setFontSize(12);
            doc.setLineWidth(0.2);
            doc.setFont(undefined, 'bold');
            doc.text('Análises Horizontais Terço Inferior Lábios - Vo-', 5, imgHeightHalf + 34,);
            doc.text('lume', 5, imgHeightHalf + 38,);
            const labioInferior = [
                { x: imageX + point62.x * imgWidthHalf, y: imageY + point62.y * imgHeightHalf },
                { x: imageX + point147.x * imgWidthHalf, y: imageY + point147.y * imgHeightHalf },
                { x: imageX + point107.x * imgWidthHalf, y: imageY + point107.y * imgHeightHalf },
                { x: imageX + point183.x * imgWidthHalf, y: imageY + point183.y * imgHeightHalf },
                { x: imageX + point84.x * imgWidthHalf, y: imageY + point84.y * imgHeightHalf },
                { x: imageX + point19.x * imgWidthHalf, y: imageY + point19.y * imgHeightHalf },
                { x: imageX + point314.x * imgWidthHalf, y: imageY + point314.y * imgHeightHalf },
                { x: imageX + point407.x * imgWidthHalf, y: imageY + point407.y * imgHeightHalf },
                { x: imageX + point322.x * imgWidthHalf, y: imageY + point322.y * imgHeightHalf },
                { x: imageX + point376.x * imgWidthHalf, y: imageY + point376.y * imgHeightHalf },
                { x: imageX + point292.x * imgWidthHalf, y: imageY + point292.y * imgHeightHalf },
                { x: imageX + point325.x * imgWidthHalf, y: imageY + point325.y * imgHeightHalf },
                { x: imageX + point319.x * imgWidthHalf, y: imageY + point319.y * imgHeightHalf },
                { x: imageX + point403.x * imgWidthHalf, y: imageY + point403.y * imgHeightHalf },
                { x: imageX + point318.x * imgWidthHalf, y: imageY + point318.y * imgHeightHalf },
                { x: imageX + point15.x * imgWidthHalf, y: imageY + point15.y * imgHeightHalf },
                { x: imageX + point88.x * imgWidthHalf, y: imageY + point88.y * imgHeightHalf },
                { x: imageX + point89.x * imgWidthHalf, y: imageY + point89.y * imgHeightHalf },
                { x: imageX + point96.x * imgWidthHalf, y: imageY + point96.y * imgHeightHalf },
                { x: imageX + point62.x * imgWidthHalf, y: imageY + point62.y * imgHeightHalf },
            ];
            const pontoMaisAlto = calcularPontoMaisBaixo(labioInferior);
            const pontoMaisAltoY = pontoMaisAlto.y
            labioInferior.forEach((landmark, index) => {
                const { x, y } = landmark;
                const newY = y;
                doc.setDrawColor(255, 0, 0);
                doc.setFillColor(255, 0, 0);
                // doc.circle(x, newY, 0.5, 'F');
                doc.setFontSize(5);
                doc.setTextColor(0, 0, 255);
                // doc.text(`${index + 1}`, x + 0, newY + 0);
                if (index < labioInferior.length - 1) {
                    const nextLandmark = labioInferior[index + 1];
                    doc.setDrawColor(0, 0, 0);
                    doc.line(x, newY, nextLandmark.x, nextLandmark.y);
                }
            });
            const labioSuperior = [
                { x: imageX + point62.x * imgWidthHalf, y: imageY + point62.y * imgHeightHalf },
                { x: imageX + point186.x * imgWidthHalf, y: imageY + point186.y * imgHeightHalf },
                { x: imageX + point41.x * imgWidthHalf, y: imageY + point41.y * imgHeightHalf },
                { x: imageX + point40.x * imgWidthHalf, y: imageY + point40.y * imgHeightHalf },
                { x: imageX + point38.x * imgWidthHalf, y: imageY + point38.y * imgHeightHalf },
                { x: imageX + point1.x * imgWidthHalf, y: imageY + point1.y * imgHeightHalf },
                { x: imageX + point268.x * imgWidthHalf, y: imageY + point268.y * imgHeightHalf },
                { x: imageX + point270.x * imgWidthHalf, y: imageY + point270.y * imgHeightHalf },
                { x: imageX + point271.x * imgWidthHalf, y: imageY + point271.y * imgHeightHalf },
                { x: imageX + point408.x * imgWidthHalf, y: imageY + point408.y * imgHeightHalf },
                { x: imageX + point273.x * imgWidthHalf, y: imageY + point273.y * imgHeightHalf },
                { x: imageX + point272.x * imgWidthHalf, y: imageY + point272.y * imgHeightHalf },
                { x: imageX + point269.x * imgWidthHalf, y: imageY + point269.y * imgHeightHalf },
                { x: imageX + point14.x * imgWidthHalf, y: imageY + point14.y * imgHeightHalf },
                { x: imageX + point39.x * imgWidthHalf, y: imageY + point39.y * imgHeightHalf },
                { x: imageX + point42.x * imgWidthHalf, y: imageY + point42.y * imgHeightHalf },
                { x: imageX + point43.x * imgWidthHalf, y: imageY + point43.y * imgHeightHalf },
                { x: imageX + point79.x * imgWidthHalf, y: imageY + point79.y * imgHeightHalf },
                { x: imageX + point62.x * imgWidthHalf, y: imageY + point62.y * imgHeightHalf },
            ];
            labioSuperior.forEach((landmark, index) => {
                const { x, y } = landmark;
                const newY = y;
                doc.setDrawColor(255, 0, 0);
                doc.setFillColor(255, 0, 0);
                // doc.circle(x, newY, 0.5, 'F');
                doc.setFontSize(5);
                doc.setTextColor(0, 0, 255);
                // doc.text(`${index + 1}`, x + 0, newY + 0);
                if (index < labioSuperior.length - 1) {
                    const nextLandmark = labioSuperior[index + 1];
                    doc.setDrawColor(0, 0, 0);
                    doc.line(x, newY, nextLandmark.x, nextLandmark.y);
                }
            });
            const volumelabioSuperior = calcularVolumeLandmarks(labioSuperior);
            const volumelabioInferior = calcularVolumeLandmarks(labioInferior);
            const volumeTotalLabios = volumelabioSuperior + volumelabioInferior;
            let pontoMaisBaixoY = Infinity;
            labioSuperior.forEach((landmark) => {
                const { y } = landmark;
                const newY = y + 60 - pontoMaisAltoY;
                if (newY < pontoMaisBaixoY) {
                    pontoMaisBaixoY = newY;
                }
            });
            const percentLabioSuperior = 100 * (volumelabioSuperior / volumeTotalLabios);
            const percentLabioInferior = 100 * (volumelabioInferior / volumeTotalLabios);
            doc.setLineWidth(0.5);
            doc.setDrawColor(255, 0, 0);
            doc.setFont(undefined, '');
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(8);
            doc.text('Lábio Sup.: ' + volumelabioSuperior.toFixed(2) + ' (' + percentLabioSuperior.toFixed(2) + '%)', 5, imgHeightHalf + 40 + 3);
            if (percentLabioSuperior >= 32 && percentLabioSuperior <= 34) {
                drawSquareWithCheckmark(doc, 91, imgHeightHalf + 37 + 2, 3);
            } else if (percentLabioSuperior > 34) {
                drawBlueSquareWithArrow(doc, 91, imgHeightHalf + 37 + 2, 3);
            } else {
                drawBlueSquareWithArrowDown(doc, 91, imgHeightHalf + 37 + 2, 3);
            }
            doc.text('Lábio Inf.: ' + volumelabioInferior.toFixed(2) + ' (' + percentLabioInferior.toFixed(2) + '%)', 5, imgHeightHalf + 40 + 7);
            if (percentLabioInferior >= 65 && percentLabioInferior <= 67) {
                drawSquareWithCheckmark(doc, 91, imgHeightHalf + 37 + 6, 3);
            } else if (percentLabioInferior > 67) {
                drawBlueSquareWithArrow(doc, 91, imgHeightHalf + 37 + 6, 3);
            } else {
                drawBlueSquareWithArrowDown(doc, 91, imgHeightHalf + 37 + 6, 3);
            }
            doc.setFont(undefined, 'bold');
            doc.text('Volume total dos lábios: ' + volumeTotalLabios.toFixed(2), 5, imgHeightHalf + 51);
            doc.setFont(undefined, '');
            doc.text('A diferença volumétrica do lábio deve lábio sup. 1:1.6.  A aparência dos lábios', 5, imgHeightHalf + 40 + 15);
            doc.text('determinam em parte a atratividade do rosto de uma pessoa', 5, imgHeightHalf + 40 + 18);
            doc.setFontSize(5);
            doc.text('Refs: PALUD Alice, et al. Management of otitis media with effusion in cleft palate children. Acta Otorhinolaryngologica Ita-', 5, imgHeightHalf + 40 + 21);
            doc.text('lica, [S.l.], v. 29, n. 1, p. 38-42, 2009. Disponível em https://www.actaitalica.it/article/view/38', 5, imgHeightHalf + 40 + 23);
            doc.setFontSize(8);
            doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
            function calcularAnguloEntreLinhas(x1, y1, x2, y2, x3, y3, x4, y4) {
                // Vetores das duas linhas
                const vetor1 = { x: x2 - x1, y: y2 - y1 };
                const vetor2 = { x: x4 - x3, y: y4 - y3 };
                // Produto escalar dos vetores
                const produtoEscalar = (vetor1.x * vetor2.x) + (vetor1.y * vetor2.y);
                // Magnitudes dos vetores (comprimento)
                const magnitudeVetor1 = Math.sqrt(Math.pow(vetor1.x, 2) + Math.pow(vetor1.y, 2));
                const magnitudeVetor2 = Math.sqrt(Math.pow(vetor2.x, 2) + Math.pow(vetor2.y, 2));
                // Cálculo do ângulo em radianos
                const cosTheta = produtoEscalar / (magnitudeVetor1 * magnitudeVetor2);
                const anguloRadianos = Math.acos(cosTheta);
                // Convertendo para graus
                const anguloGraus = (anguloRadianos * 180) / Math.PI;
                return anguloGraus;
            }
            // Coordenadas das linhas
            const anguloMentoEqs = calcularAnguloEntreLinhas(x177 + imageXDir, y177, x401, y401, x398, y398, x401, y401);
            const anguloMentoDir = calcularAnguloEntreLinhas(x401, y401, x177 + imageXDir, y177, x136, y136, x177 + imageXDir, y177);
            const anguloMandibulaEqs = calcularAnguloEntreLinhas(x133, y133, x136, y136, x177 + imageXDir, y177, x136, y136);
            const anguloMandibulaDir = calcularAnguloEntreLinhas(x401, y401, x398, y398, x402, y402, x398, y398);
            doc.setFontSize(8);
            doc.setFont(undefined, 'bold');
            doc.setTextColor(255, 0, 0);
            doc.text(` ${anguloMentoDir.toFixed(2)}°`, x401, y401 + 3);
            doc.text(` ${anguloMentoEqs.toFixed(2)}°`, x177 + imageXDir - 5, y177 + 3);
            doc.text(` ${anguloMandibulaEqs.toFixed(2)}°`, x136, y136 + 3);
            doc.text(` ${anguloMandibulaDir.toFixed(2)}°`, x398, y398 + 3);
            doc.setLineWidth(0.1);
            doc.setDrawColor(255, 255, 255);
            doc.line(x401, y401, x177 + imageXDir - 5, y177); //mento
            doc.line(x136, y136, x177 + imageXDir - 5, y177); //mento ang. mandibula
            doc.line(x401, y401, x398, y398); //mento ang. mandíbula
            doc.line(x136, y136, x133, y133); //ang. mandíbula esq
            doc.line(x398, y398, x402, y402); //ang. mandíbula dir
            doc.setLineWidth(0.2);
            doc.setDrawColor(255, 0, 0);
            doc.line(x448, yStartVerticalLineMediaPag, x448, yEndVerticalLineMediaPag - 140); // Linha externa da face
            doc.line(x128, yStartVerticalLineMediaPag, x128, yEndVerticalLineMediaPag - 140); // Linha externa da face
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);
            doc.setFont(undefined, 'bold');
            doc.text(`Análises dos Ângulos da Mandíbula e Mento`, imageXDir, textY);
            doc.setTextColor(0, 0, 0);
            doc.setFont(undefined, '');
            doc.setFontSize(12);
            doc.addImage(imageData, 'JPEG', imageX, imageY + 130, imgWidthHalf, imgHeightHalf - 50);
            // Cálculo para diminuir 50% da distância Y
            doc.line(5, yStartVerticalLineMediaPag + 105 + pontoMaisBaixoY, distanciaEsquerda + totalImageWidth, yStartVerticalLineMediaPag + 105 + pontoMaisBaixoY); //boca
            const novoY153 = y153 / 2; // Subtrai 50% da distância Y original
            const novoY3 = y3 / 2;     // Subtrai 50% da distância Y original
            // Linhas ajustadas com 50% da distância Y
            doc.line(distanciaEsquerda, novoY153 + 143, distanciaEsquerda + totalImageWidth, novoY153 + 143); // Baixo do queixo ajustado
            doc.line(distanciaEsquerda, novoY3 + 143, distanciaEsquerda + totalImageWidth, novoY3 + 143);     // Base do nariz ajustado



            addFooter();
            doc.addPage();
            doc.setLineWidth(0.5);
            doc.setFont(undefined, 'bold');
            doc.setDrawColor(0, 0, 0);
            doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            doc.text('Espelhamento: Composições fotográficas são a melhor maneira de perceber assimetrias faciais normais. Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.)', 5, 20,);
            doc.text('Elsevier, 2018. pag. 165', 5, 24,);

            doc.setFont(undefined, '');
            doc.text('Esquerdo', 27, imageY + 65,);
            doc.text('Normal', 93, imageY + 65,);
            doc.text('Direito', 160, imageY + 65,);

            if (rightImageData) {
                doc.addImage(rightImageData, 'JPEG', imageX, imageY - 5, imgWidthHalf - 30, imgHeightHalf - 30);
            }

            if (leftImageData) {
                doc.addImage(leftImageData, 'JPEG', imageX + 130, imageY - 5, imgWidthHalf - 30, imgHeightHalf - 30);
            }

            doc.addImage(imageData, 'JPEG', imageX + 65, imageY - 5, imgWidthHalf - 30, imgHeightHalf - 30);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(12);
            doc.text('[ Indicações de Acordo com Formato Atual da Face ]', 50, imageY + 70);
            doc.setFontSize(8);
            doc.text('Formato do rosto:', 5, imageY + 75);
            doc.text(faceShape, 29, imageY + 75);
            doc.setFont(undefined, '');
            // Adiciona a descrição
            doc.text(sugestao.descricao, 5, imageY + 80);
            sugestao.recomendacoes.forEach((item, index) => {
                doc.text(`- ${item}`, 5, imageY + 85 + (index * 5));
            });
            doc.setFont(undefined, 'bold');
            // Adiciona técnicas
            doc.text('Técnicas de Harmonização Facial:', 5, 115 + (sugestao.recomendacoes.length * 5));
            doc.setFont(undefined, '');
            sugestao.tecnicas.forEach((item, index) => {
                doc.text(`- ${item}`, 5, 120 + (sugestao.recomendacoes.length * 5) + (index * 5));
            });
            doc.setFont(undefined, 'bold');
            // Adiciona óculos a usar e suas imagens
            doc.text('Óculos a Usar:', 5, 130 + (sugestao.tecnicas.length * 5));
            doc.text(sugestao.oculos_usar, 25, 130 + (sugestao.tecnicas.length * 5));
            // Adiciona as imagens dos óculos a usar
            if (oculosUsarImg && oculosUsarImg.length > 0) {
                oculosUsarImg.forEach((img, index) => {
                    if (img) { // Verifica se a imagem não é nula ou indefinida
                        doc.addImage(img, 'JPEG', 10, 110 + (index * 10), 10, 10);
                    } else {
                        console.warn(`Imagem na posição ${index} está nula.`);
                    }
                });
            } else {
                console.warn('oculos_usar_img está nulo, vazio ou não contém imagens.');
            }



            // Adiciona óculos a não usar e suas imagens
            doc.text('Óculos a Não Usar:', 5, 135 + (sugestao.oculos_usar_img.length * 10));
            doc.text(sugestao.oculos_n_usar, 30, 135 + (sugestao.oculos_usar_img.length * 10));

            // Adiciona as imagens dos óculos a não usar
            if (oculosNUsarImg) {
                oculosNUsarImg.forEach((oculosNUsarImg, index) => {
                    if (oculosNUsarImg) { // Verifica se a imagem não é nula
                        doc.addImage(oculosNUsarImg, 'JPEG', 10, 135 + (index * 10), 10, 10);
                    }
                });
            } else {
                console.warn('oculos_usar_img está nulo ou vazio.');
            }

            // Adiciona sobrancelha
            doc.text('Sobrancelha:', 5, 170 + (sugestao.oculos_n_usar_img.length * 10));
            doc.text(sugestao.sobrancelha, 25, 170 + (sugestao.oculos_n_usar_img.length * 10));

            // Adiciona maquiagem
            doc.text('Maquiagem:', 5, 190 + (sugestao.sobrancelha ? 5 : 0));
            Object.keys(sugestao.maquiagem).forEach((key, index) => {
                doc.text(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${sugestao.maquiagem[key]}`, 25, 190 + (index * 5) + (sugestao.sobrancelha ? 5 : 0));
            });
            doc.setFont(undefined, '');

            const addLegendaCores = () => {
                doc.setFontSize(12);
                doc.setTextColor(0, 0, 0);
                doc.setFont(undefined, 'bold');
                doc.text('Legenda de Cores e Simbolos:', 5, yEndVerticalLineMediaPag - 30);
                doc.setFont(undefined, '');
                doc.text('Desvio positivo', 5 + 6, yEndVerticalLineMediaPag - 24);
                doc.text('Desvio negativo', 5 + 6, yEndVerticalLineMediaPag - 18);
                doc.text('Ideal ou muito próximo', 5 + 6, yEndVerticalLineMediaPag - 12);
                drawBlueSquareWithArrow(doc, 5, yEndVerticalLineMediaPag - 28, 5);
                drawBlueSquareWithArrowDown(doc, 5, yEndVerticalLineMediaPag - 22, 5);
                drawSquareWithCheckmark(doc, 5, yEndVerticalLineMediaPag - 16, 5);
                addFooter()
            }
            addLegendaCores();
            doc.setTextColor(0, 0, 0);
            doc.addPage();
            let pageNumber = 1;
            doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            addFooter();
            let yPosition = 20;
            const lineHeight = 3;
            const numColumns = 6;
            const marginLeft = 4;
            const columnWidth = (doc.internal.pageSize.width - marginLeft) / numColumns;
            let columnIndex = 0;
            let titleAdded = false;
            landmarks.forEach((landmark, index) => {
                if (yPosition + lineHeight > doc.internal.pageSize.height - 20) {
                    doc.setTextColor(0, 0, 0);
                    doc.addPage();
                    pageNumber++;
                    yPosition = 10;
                    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
                    doc.setTextColor(0, 0, 0);
                    addFooter();
                    titleAdded = false;
                }
                if (!titleAdded) {
                    doc.setFontSize(12);
                    doc.setFont(undefined, 'bold');
                    doc.text('Pontos de Referência - Identidade Facial', 65, 20,);
                    doc.setFontSize(6);
                    doc.setFont(undefined, '');
                    yPosition += 10;
                    titleAdded = true;
                }
                const x = landmark.x !== undefined ? landmark.x.toFixed(2) : 'N/A';
                const y = landmark.y !== undefined ? landmark.y.toFixed(2) : 'N/A';
                const z = landmark.z !== undefined ? landmark.z.toFixed(2) : 'N/A';
                const xPosition = marginLeft + columnIndex * columnWidth;
                doc.text(`P. ${index + 1}: X=${x}, Y=${y}, Z=${z}`, xPosition, yPosition);
                columnIndex++;
                if (columnIndex >= numColumns) {
                    columnIndex = 0;
                    yPosition += lineHeight;
                }
            });
            doc.save(`Analise-Facial-${pageNumber}.pdf`);
        };
        // Definir a origem da imagem
        if (typeof image === 'string') {
            // Se for uma URL
            img.src = image;
        } else if (image instanceof Blob) {
            // Se for um Blob
            img.src = URL.createObjectURL(image);
        } else {
            console.error("Tipo de fonte de imagem não suportado");
        }
    };
    return (
        <>
            {image && landmarks && (
                <div>
                    <button
                        className="btn btn-primary w-100"
                        onClick={handleDownloadPDF}
                        id="downloadPDFAnaliseFacial"
                    >
                        Baixar Análise em PDF <i className="fa fa-download" aria-hidden="true"></i>
                    </button>
                    <ButtonDownload onClick={handleDownloadPDF}/>
                </div>
            )}
        </>);
}