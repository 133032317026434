import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import RemoveMarkdown from '../../../../../components/markdown/RemoveMarkdown';
import { Carousel } from 'antd';
import { Link } from 'react-router-dom';
export const SearchMain = ({
  searchTerm,
  searching,
  results,
  error,
  loadMore,
  hasMore,
  info
}) => {
  const location = useLocation();
  const isSearchPage = location.pathname.startsWith("/search");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(4); // Default results per page
  const { search } = location;
  const carouselRefs = useRef([]);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);
  const parseImages = (imagesString) => {
    try {
      const parsedImages = JSON.parse(imagesString);
      return Array.isArray(parsedImages) ? parsedImages : [];
    } catch (e) {
      return [];
    }
  };
  const paginateResults = (results, page, perPage) => {
    const startIndex = (page - 1) * perPage;
    return results.slice(startIndex, startIndex + perPage);
  };

  const handleResize = () => {
    if (window.innerWidth <= 1058) {
      setResultsPerPage(1); // Show 1 item on smaller screens
    } else {
      setResultsPerPage(4); // Default items per page
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const paginatedResults = paginateResults(results, currentPage, resultsPerPage);
  const totalPages = Math.ceil(results.length / resultsPerPage);
  return (
    <section style={{ minHeight: `${!isSearchPage ? '' : '85dvh'}` }}>
      <div className='col-md-12'>
        <h5 className='mt-5 ml-3'><b>{info}</b> {searchTerm}</h5>
        {searching && results.length === 0 && <p>Carregando...</p>}
        {error && <p className="text-danger">Erro: {error.message}</p>}
        {!error && results.length === 0 && <p className='mt-5 ml-3'>Nenhum resultado encontrado</p>}
        <ul className={`${!isSearchPage ? 'd-flex flex-wrap justify-content-center align-items-center' : ''}`} style={{ overflow: 'hidden' }}>
          {paginatedResults.map((result, index) => {
            const images = parseImages(result.products_image);
            return (
              <li key={index} className={`${!isSearchPage ? 'py-2 border col-md-3 rounded d-flex' : 'py-2 d-flex'}`} style={{ boxShadow: "0 4px px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)" }}>
                <li className='text-center align-items-center mr-2 ml-2 justify-content-center rounded d-flex align-items-center mx-auto flex-wrap'>
                  &nbsp;
                  {images.length > 0 &&
                    <div style={{ position: "relative" }}>
                      <Link
                        style={{
                          position: "absolute",
                          top: "30%",
                          left: "0",
                          transform: "translateY(-50%)",
                          zIndex: 1,
                          width: '5px',
                          height: '5px',
                          border: 'none',
                          background: 'none',
                        }}
                        onClick={() => carouselRefs.current[index]?.prev()}
                      >
                        ◀
                      </Link>
                      <Carousel style={{ width: '80px' }} ref={el => carouselRefs.current[index] = el}>
                        {images.map((img, imgIndex) => (
                          <div key={imgIndex}>
                            <a
                              href={`${location.pathname.includes("usuario")
                                ? `../../../usuario/produtos/${result.id}${search}`
                                : `/produtos/${result.id}${search}`
                                }`}
                            >
                              <img
                                className="w-100 rounded image-fluid"
                                style={{ minHeight: "80px", maxHeight: "80px", minWidth: '80px' }}
                                src={img.url}
                                alt="Produtos Imagem"
                              />
                            </a>
                          </div>
                        ))}
                      </Carousel>
                      <Link
                        style={{
                          position: "absolute",
                          top: "30%",
                          right: "7px",
                          transform: "translateY(-50%)",
                          zIndex: 1,
                          width: '5px',
                          height: '5px',
                          border: 'none',
                          background: 'none'
                        }}
                        onClick={() => carouselRefs.current[index]?.next()}
                      >
                        ▶
                      </Link>
                    </div>
                  }
                  <strong> R$: </strong>{result.value}
                </li>
                <a className='d-flex align-items-center mx-auto justify-content-left' href={`../produtos/${result.id}`}>
                  <div className='mr-2 mr-2 ml-2'>
                    <h5 className='font-weight-bold'
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}

                    >{result.products_name}</h5>
                    <ul
                      className='text-justify text-primary mr-2'
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      <RemoveMarkdown description={result.products_description} />
                    </ul>
                    {result.company_name &&
                      <ul className='text-justify text-dark mt-2 ' style={{ whiteSpace: 'nowrap' }}>
                        <strong>Empresa: </strong>  {result.company_name}
                      </ul>
                    }
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
        {!isSearchPage && (
          <div className="ad-controls text-center py-3">
            <button className='btn btn-secondary mr-2 ' style={{ visibility: currentPage > 1 ? 'visible' : 'hidden' }} onClick={() => setCurrentPage(currentPage - 1)}>
              👈 Anterior
            </button>
            <button className="btn btn-secondary" style={{ visibility: currentPage < totalPages ? 'visible' : 'hidden' }} onClick={() => setCurrentPage(currentPage + 1)}>
              Próximo 👉
            </button>
          </div>
        )}
        {isSearchPage && hasMore && (
          <p className='text-center'>
            <button className="btn btn-primary mt-3" onClick={loadMore}>
              Ver Mais
            </button>
          </p>
        )}
      </div>
    </section>
  );
};
export default SearchMain;