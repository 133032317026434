import { axiosHeadersToken } from '../../../../../services/contexts';

export const post_cart = async (product, cart, setCart, user_id, baseURL) => {
  try {
    const existingProductIndex = cart.findIndex((item) => item.product_code_id === product.product_code_id);

    let updatedCart;

    if (existingProductIndex !== -1) {
      // Update existing product quantity
      updatedCart = [...cart];
      const existingQuantity = parseInt(updatedCart[existingProductIndex].quantity, 10);
      const newQuantity = parseInt(product.quantity, 10);
      updatedCart[existingProductIndex].quantity = existingQuantity + newQuantity;
    } else {
      // Add new product to cart
      updatedCart = [...cart, product];
    }

    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));

    if (user_id) {
      const cartWithTable = updatedCart.map(item => ({ ...item }));
      const response = await axiosHeadersToken().post(`${baseURL}post_cart/${user_id}`, { cart: cartWithTable }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('Resposta ao adicionar ao carrinho:', response.data);
      localStorage.setItem('cart', JSON.stringify(response.data.cartItems));
    }
  } catch (error) {
    console.error('Erro ao adicionar ao carrinho:', error);
  }
};
