import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { MdDownload } from 'react-icons/md';

export const ButtonDownload = (props) => {
  const [visible, setVisible] = useState(false);
  const [iconColor, setIconColor] = useState('#fff'); // Cor padrão do ícone

  return (
    <div style={{ position: 'fixed', bottom: '100px', right: '10px', zIndex: '1000' }}>
      <Tooltip
        title="Baixar o laudo? Clique aqui"
        visible={visible}
        onMouseEnter={() => { setVisible(true); setIconColor('#000'); }} // Muda a cor para preto ao passar o mouse
        onMouseLeave={() => { setVisible(false); setIconColor('#fff'); }} // Restaura a cor original ao remover o mouse
      >
        <Button
          shape="circle"
          className='btn-primary'
          style={{
            color: '#fff',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -4px 5px rgba(255, 255, 255, 0.4)',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            width: '80px',
            height: '80px',
          }}
          onClick={props.onClick}
        >
          <MdDownload size={40} color={iconColor} /> {/* Controle da cor do ícone via estado */}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ButtonDownload;
