import { ReferenciaLocal, NavBreadcrumb } from '../../../../components'
import { CompartilhamentoDeDados } from './components/CompartilhamentoDeDados/CompartilhamentoDeDados'
import { ModuloVisualConvencional } from './components/ModuloVisualConvencional/ModuloVisualConvencional'
import { ModuloVisualImersivo } from './components/ModuloVisualImersivo/ModuloVisualImersivo'
export const Index = () => {
    return (<>
        <section>
            <div className=' w-100' >
                <NavBreadcrumb icon={'user fa'} text={'Preferências'} />
                <div className="py-3">
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                                {/* Complexo A - sintonia NAV */}
                                <ReferenciaLocal text={'Compartilhamento de Dados'} />
                                <CompartilhamentoDeDados />
                                <ReferenciaLocal text={'Modulo Visual Convencional'} />
                                <ModuloVisualConvencional />
                                <ReferenciaLocal text={'Modulo Visual Imersivo'} />
                                <ModuloVisualImersivo />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}