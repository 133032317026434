import React from 'react';
import { useLocation } from 'react-router-dom';
export function LinkHref(props) {
    const location = useLocation();
    const { search } = location;
    const destinationWithSearch = `${props.to}${search}`;
    const clicarNoId = () => {
        const elementoClicado = document.getElementById(props.href);
        if (elementoClicado) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            elementoClicado.click();
        }
    };
    return (<>
        <a className={'mx-auto text-center text-secondary '}
            type={props.type}
            href={destinationWithSearch}
            style={{ textDecorationLine: 'underline', textDecorationSkipInk: ' none', cursor: 'pointer' }}
            onClick={clicarNoId}            >
            <b>{props.children}</b>
        </a>
    </>);
}
export default LinkHref;
