import React, { useCallback, useContext, useEffect, useState } from 'react';
import { baseURL } from '../../../../../../../../services/api.js';
import { MaisInfoMenosInfo } from '../../../../../../../../components';
import MasterCadastroCompany from './MasterCadastroCompany';
// import MasterCadastroCompanyUnit from './MasterCadastroCompanyUnit';
import { Image } from 'antd';
import { AuthContext } from '../../../../../../../../contexts/auth';
import { axiosHeadersToken } from '../../../../../../../../services/contexts';
import MasterProfissionalDeleted from './MasterProfissionalDeleted.jsx';
export const MasterControlsProfissional = () => {
  const { getUserId } = useContext(AuthContext);
  const professionalDataString = localStorage.getItem('professionalData');
  const professionalData = professionalDataString && professionalDataString !== 'undefined' ? JSON.parse(professionalDataString) : null;
  const [professionalCBOData, setProfessionalCBOData] = useState({});
  const { renewToken } = useContext(AuthContext);
  const { logoutProfessional } = useContext(AuthContext);
  const professionalOwnerCompanyString = localStorage.getItem("professionalOwnerCompany");
  const professionalOwnerCompany = professionalOwnerCompanyString ? JSON.parse(professionalOwnerCompanyString) : null;
  const [apiError, setApiError] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleDriveProfessionalUnit = (item) => {
    try {
      const companyInfo = {
        ...item,
      };
      localStorage.setItem('drive', JSON.stringify(companyInfo));
      window.location.reload();
      alert(`Informações da empresa e cargo adicionadas`);
    } catch (error) {
      alert('Erro ao adicionar informações', error);
    }
  };
  const handleDriveProfessionalEdit = async () => {
    alert(`Estamos trabalhando nessa função
        1º coisa verifica que página esta por causa do modal
        - In loco guarda informação no locoal storage setSelectBox automo scroll até o local do nome 
        - Em roam redirect pagina
            - note a origem do click
            - pega a origem e levar ate o tipo de cadastro
                - isso quer dizer se /user continua /user[ cadastro(tipo) ]
                - isso quer dizer se /profissional continua /profissional[ cadastro(tipo) ] 

            - `)
  };
  const origin = window.location.pathname; // Obtém a URL atual

  const handleDriveMasterProfessionalEdit = async (professionalId) => {
    try {
      let redirectURL = '';
      if (origin.includes('/Pacientes') || origin.includes('/usuario')) {
        if (window.location.pathname !== '/usuario/cadastro/profissional') {
          localStorage.setItem("Scroll", professionalId);
          redirectURL = `/usuario/cadastro/profissional`;
          window.location.href = redirectURL;
        }
        if (window.location.pathname === '/Usuarios/cadastro/profissional') {
          // alert('Você já está na página de cadastro de profissional.');
          scrollToCheckbox()
          const closeButton = document.querySelector('.ant-modal-close');
          if (closeButton) {
            const event = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              clientX: 0,
              clientY: 0,
            });
            closeButton.dispatchEvent(event);
          }
          return
        } else {
          //alert('Você já está na página de cadastro de profissional.');      
          scrollToCheckbox()
          const closeButton = document.querySelector('.ant-modal-close');
          if (closeButton) {
            const event = new MouseEvent('click', {
              bubbles: true,
              cancelable: true,
              clientX: 0,
              clientY: 0,
            });
            closeButton.dispatchEvent(event);
          }
          return; // Interrompe a execução do restante do código
        }
      } if (origin.includes('/profissionais')) {
        redirectURL = `/profissionais/edit/${professionalId}`;
      }
      // Redireciona para a página de edição
      window.location.href = redirectURL;
    } catch (error) {
      alert(apiError)
      console.error('Erro ao editar profissional:', error);
      alert('Erro ao editar profissional. Consulte o console para mais detalhes.');
    }
  };
  const fetchData = useCallback(async () => {
    const id = getUserId();
    setLoading(true);
    try {
      const response = await axiosHeadersToken().get(`${baseURL}get_professionalDeletado/${id}`);
      const responseData = response.data;
      //  alert(responseData)
      setApiData(responseData);
    } catch (error) {
      console.error('Erro ao buscar estado na API:', error);
      setApiData(null);
      setApiError(true);
    } finally {
      setLoading(false);
    }
  }, [getUserId]);
  const handleExcludeProfessional = useCallback(async () => {
    const id = getUserId();
    const confirmMessage = 'Você irá excluir todos os vínculos, sendo necessários reestabelecer novamente. Tem certeza?';
    if (!window.confirm(confirmMessage)) {
      return;
    }
    try {
      const response = await axiosHeadersToken().put(`${baseURL}put_professional_state/${id}`);
      if (response.status === 200) {
        alert(`Profissional marcado como excluído na API`);
        fetchData();
        renewToken();
        logoutProfessional();
      }
    } catch (error) {
      alert('Erro ao atualizar o estado do profissional:', error);
    }
  }, [getUserId, fetchData, renewToken, logoutProfessional]);
  const handleAtivarCadastroProfissional = useCallback(async () => {
    const id = getUserId();
    try {
      const response = await axiosHeadersToken().put(`${baseURL}put_professional_state/${id}`);
      if (response.status === 200) {
        alert('Cadastro profissional ativado com sucesso!');
        renewToken();
        fetchData();
        window.location.reload();
      } else {
        console.error('Erro ao ativar o cadastro profissional. Resposta:', response);
        alert('Erro ao ativar o cadastro profissional. Status não esperado.');
      }
    } catch (error) {
      console.error('Erro ao ativar o cadastro profissional:', error);
      alert('Erro ao ativar o cadastro profissional. Consulte o console para mais detalhes.');
    }
  }, [getUserId, renewToken, fetchData]);
  useEffect(() => {
    renewToken();
    fetchData();
  }, [fetchData, renewToken]);
  useEffect(() => {
    const storedProfessionalCBOData = localStorage.getItem('professionalCBO');
    if (storedProfessionalCBOData === 'undefined') {
      setProfessionalCBOData({});
    } else {
      const parsedData = JSON.parse(storedProfessionalCBOData);
      setProfessionalCBOData(parsedData);
    }
  }, []);
  const handleCadastroProfissional = () => {
    localStorage.setItem('cadastroProfissional', 'SIM');
    window.location.href = '../../../usuario';
  };
  const nameProfissional = document.getElementById('input_name_profissional_autonomo');

  useEffect(() => {


    setTimeout(() => {
      if (nameProfissional) {
        nameProfissional.scrollIntoView({ behavior: 'smooth', block: 'center' });
        localStorage.removeItem("Scroll");
      }
    }, 600);
  }, [nameProfissional]);
  const scrollToCheckbox = useCallback(() => {
    const checkboxElement = document.getElementById('profissional_autônomo');
    const nameProfissional = document.getElementById('input_name_profissional_autonomo');
    const scrollTo = localStorage.getItem("Scroll")
    if (scrollTo) {
      setTimeout(() => {
        if (checkboxElement instanceof HTMLInputElement) {
          if (checkboxElement instanceof HTMLInputElement && !checkboxElement.checked) {
            checkboxElement.click();
          }
        }
      }, 400)
    }
    if (checkboxElement instanceof HTMLInputElement) {
      if (checkboxElement instanceof HTMLInputElement && !checkboxElement.checked) {
        checkboxElement.click();

      }
      setTimeout(() => {
        if (nameProfissional) {
          nameProfissional.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 600);
    }
  }, []);
  useEffect(() => {
    scrollToCheckbox();
  }, [scrollToCheckbox]);

  const isOnCadastroProfissionalPage =
    window.location.pathname.includes('/usuario/cadastro/profissional') ||
    window.location.pathname.includes('/Usuarios/cadastro/profissional') ||
    window.location.pathname.includes('/Usuarios/Cadastro/profissional') ||
    window.location.pathname.includes('/Usuarios/Cadastro/Profissional') ||
    window.location.pathname.includes('/profissionais/cadastro/profissional');
  if (loading) {
    return <p>Carregando...</p>;
  }

  if (apiData) {
    const item = apiData;
    return (
      <MasterProfissionalDeleted
        item={item}
        handleAtivarCadastroProfissional={handleAtivarCadastroProfissional}
      />
    );
  }
  // Função para rolar até o checkbox 'profissional_autônomo'

  if (professionalData) {
    return (
      <div>
        {professionalData.filter((item) => item !== undefined).map((item) => (
          <div className='bg-light py-3 rounded mt-3 mb-5 col-md-12' key={item.id}>
            <div className='container'>
              <div className=' mt-1'>
                <small className='font-weight-bold'>Master Cadastro Profissional</small><br /> <hr />
                <div className="container-fluid">
                  <div className="row d-inline-flex justify-content-center w-100">
                    <div
                      className=' mt-3'
                      style={{
                        padding: '10px',
                        marginLeft: '5px',
                        marginRight: '5px',
                      }}>  <Image height={200} className='rounded' src={item.imageUrls} alt="Professional Image" preview={false} /><br />
                      <small className='font-weight-bold'>Nome Profissional:<small>   {item.professional_name} </small></small><br />
                      <button style={{ border: '0px' }} onClick={() => handleDriveProfessionalUnit(item)}><i className={`fa fa-suitcase text-dark mr-2 ml-2 ${localStorage.getItem('drive') === JSON.stringify(item.company) ? 'text-success' : ''}`}></i></button>
                      <button style={{ border: '0px' }} onClick={() => handleDriveMasterProfessionalEdit(item.id)}><i className='fa fa-edit text-warning mr-2 ml-2'></i></button>
                      <button style={{ border: '0px' }} onClick={() => handleExcludeProfessional(item.id)}><i className='fa fa-trash text-danger mr-2 ml-2'></i></button>
                      <MaisInfoMenosInfo text={<>
                        <small className='font-weight-bold'>Classificação Brasileira de Ocupações - CBO</small><br />
                        <div className='mt-3 mb-3'>
                          {Object.entries(professionalCBOData).map(([key, value]) => (
                            <div className='mt-1 bg-white rounded py-2' key={key}>
                              <small className='font-weight-bold ml-1 text-dark'>Descrição: </small><small>{value}</small><br />
                              <small className='font-weight-bold ml-1 text-dark'>Código CBO: </small><small>{key}</small>
                            </div>
                          ))}
                        </div>
                        <small className='font-weight-bold'>Informações Gerais</small><br />
                        {item.user_id && <small><strong>Identificação Única de Usuário:</strong> {item.user_id}</small>}<br />
                        {item.id && <small><strong>Identificação Única de Profissional:</strong> {item.id}</small>}<br />
                        {item.address_id && <small><strong>Identificação Única Endereço:</strong> {item.address_id}</small>}<br />
                        {item.created_at && <small><strong>Data de criação:</strong> {item.created_at}</small>}<br />
                        {item.state && <small><strong>Estado do cadastro: </strong>{item.state}</small>}<br />
                      </>} />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <hr />
                <MasterCadastroCompany
                  professionalOwnerCompany={professionalOwnerCompany[0]}
                  item={item}
                  handleDriveProfessionalUnit={handleDriveProfessionalUnit}
                  handleDriveProfessionalEdit={handleDriveProfessionalEdit}
                  handleExcludeProfessional={handleExcludeProfessional}
                />
                {/* <MasterCadastroCompanyUnit
                                        professionalOwnerCompanyUnit={professionalOwnerCompany[0]}
                                        item={item}
                                        handleDriveProfessionalUnit={handleDriveProfessionalUnit}
                                        handleDriveProfessionalEdit={handleDriveProfessionalEdit}
                                        handleExcludeProfessional={handleExcludeProfessional}
                                    /> */}
              </div>
            </div>
          </div>
        ))}
        {!isOnCadastroProfissionalPage && (
          <div className='col-md-12 mx-auto d-flex justify-content-center py-3'>
            <button className='btn btn-primary' onClick={handleCadastroProfissional}>
              Administrar ou Cadastrar Profissional
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return <>
      <div className='col-md-12 mx-auto d-flex justify-content-center py-3'>
        <button className='btn btn-primary' onClick={handleCadastroProfissional}>Cadastrar Profissional</button>
      </div>
      <p>Nenhum profissional ativo encontrado.
      </p>;
    </>
  }
};
export default MasterControlsProfissional;