import React from 'react';
import { TitleL1, TitleL2 } from '../../../../../components';
export const OportunidadesDeParcerias = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <TitleL1>
                Pesquisadores Parcerias
            </TitleL1>
            <div className='col-md-12  mx-auto mt-5'>
                <h5 className='text-justify text-dark mt-2 mb-5'>
                    Nesta secção apresentamos oportunidades para o público pesquisador
                    fazer participação nos nossos projetos em escala. Uma das formas de participação que oferecemos é a possibilidade de validar pesquisas conosco ou ainda adquirir
                    ou obter a concessão de um equipamento a um preço de custo extremamente acessível.
                    Isso significa que você pode escolher a modalidade que melhor se adequa às suas necessidades, seja colaborando nas validações ou
                    adquirindo os equipamentos em validação
                    <br /> <br />
                    Estamos comprometidos em tornar nossos projetos e tecnologias acessíveis a um público mais amplo e diversificado, e essas oportunidades são um reflexo desse compromisso
                </h5>
                <TitleL2>Benefícios Gerais</TitleL2>
                <h5 className="opportunities-list mb-5 text-justify">
                    <ul>
                    <li>✅Bolsas</li>
                    <li>✅Publicações</li>
                    <li>✅Equipamento a preço de custo</li>
                    <li>✅Acesso conveniente às nossas tecnologias inovadoras</li>
                    <li>✅Participar de projetos escalonados conosco</li>
                    </ul>
                </h5>
                
            </div>
        </section>
    </>)
}