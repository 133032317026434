import { Carousel3D, TitleL2 } from '../../../../../../../components';
import "./Styles/PreLancamento.css"
import imageBIO from '../../../assets/BioterioForte/BioterioForteSF.png';
import {
    imagesBIO1, cellCountBIO1, imagesBIO2, cellCountBIO2
} from './functions/DataImagens';
import { Divider } from 'antd';
export const BioterioForteParcerias = () => {
    return (<>
        <div className="" style={{ overflowX: 'hidden' }} >
            <div className=' border py-2 mt-5 rounded mb-5'>
                <div className='d-flex mx-auto col-md-12  justify-content-center  align-items-center ' >
                    <Carousel3D images={imagesBIO2} cellCount={cellCountBIO2} />
                </div>
                <Divider />
                <div className='row'>
                    <div className='col-md-6 d-flex'>
                        <img className=" col-md-12 mx-auto py-3"
                            src={imageBIO}
                            alt='Empresa desenvolvedora de softwwares e equipamentos para saúde' />
                    </div>
                    <div className='col-md-6 '>
                        <TitleL2>Detalhes</TitleL2>
                        <h5 className='text-justify'><strong>Versão monitoramento de pequenos animais</strong>
                            <br /><br />
                            <strong>  Monitoramento Termográfico  <br /></strong>
                            Multi-animais <br />
                            Monitoramento individual ou
                            múltiplo na mesma caixa <br />
                            Homogeneização de grupos <br />
                            Detecção e monitoramento de doenças <br />
                            Detecção e monitoramento de tratamentos <br /><br />
                            <strong>    Monitoramento da Alimentação <br /></strong>
                            Água <br />
                            Ração <br /><br />
                            <strong>Monitoramento do Ambiente  <br /></strong>
                            Temperatura <br />
                            Umidade <br />
                            Luminosidade <br /><br />
                            <strong> Controle do Ambiente  <br /></strong>
                            Temperatura <br />
                            Luminosidade <br /><br />
                            <strong>  Software<br /></strong>
                            Visão computacional + Big Data + I.A possível redução do animais utilizados em pesquisa em até 3/4<br /><br />
                            <strong>  Compatibilidade caixas moradia <br /></strong>
                            41 x 34 x 16 cm <br />
                            41 x 34 x 18 cm <br />
                            49 x 34 x 16 cm <br />

                        </h5></div>
                </div>
            </div>
            <div className=' border py-2 mt-5 rounded mb-5'>
                <div className='d-flex mx-auto col-md-12  justify-content-center  align-items-center ' >
                    <Carousel3D images={imagesBIO1} cellCount={cellCountBIO1} />
                </div>
                <Divider />

                <div className='row'>
                    <div className='col-md-6 d-flex'>
                        <img className=" mx-auto py-3"
                         
                            alt='Sem imagem' />
                    </div>
                    <div className='col-md-6 '>
                        <TitleL2>Detalhes</TitleL2>
                        <h5 className='ml-2 mr-2'> Versão móvel ideal para campo ou laboratórios</h5>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default BioterioForteParcerias;