import React, { useState } from 'react';
import './style/InputOnOff.css';

export const InputOnOff = () => {
  const [checked, setChecked] = useState(false);
  const [workSchedule, setWorkSchedule] = useState([]);

  const handleToggle = () => {
    setChecked(!checked);
  };

  const handleAddPeriod = () => {
    setWorkSchedule([...workSchedule, { day: '', startTime: '', endTime: '' }]);
  };

  const handleRemovePeriod = (index) => {
    const updatedSchedule = [...workSchedule];
    updatedSchedule.splice(index, 1);
    setWorkSchedule(updatedSchedule);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(weekday, startTime, endTime);
    //  // Coloque a lógica de envio de dados aqui

    //  // Reset das variáveis após o envio
    //   setWeekday("");
    //   setStartTime("");
    //   setEndTime("");
  };

  return (
    <div>
      <div className={`toggle-switch ${checked ? 'on' : 'off'}`} onClick={handleToggle}>
        <div className="toggle-switch-track">
          <div className="toggle-switch-handle"></div>
        </div>
      </div>
      {checked && (
        <div>
          <div id='periodo'>
            <button className='periodo' onClick={handleAddPeriod}>
              Adicionar Período
            </button>
          </div>
          {workSchedule.map((period, index) => (
            <div key={index}>
              <select
                defaultValue={period.day}
                onChange={(event) => {
                  const selectedDay = event.target.value;
                  const updatedSchedule = [...workSchedule];
                  updatedSchedule[index].day = selectedDay;
                  setWorkSchedule(updatedSchedule);
                }}
              >
                <option disabled>Selecione um dia</option>
                <option value="Segunda-feira">Segunda-feira</option>
                <option value="Terça-feira">Terça-feira</option>
                <option value="Quarta-feira">Quarta-feira</option>
                <option value="Quinta-feira">Quinta-feira</option>
                <option value="Sexta-feira">Sexta-feira</option>
              </select>

              <div className='startTime'>
                <h5>De</h5>
                <input
                  type="time"
                  value={period.startTime}
                  onChange={(event) => {
                    const selectedStartTime = event.target.value;
                    const updatedSchedule = [...workSchedule];
                    updatedSchedule[index].startTime = selectedStartTime;
                    setWorkSchedule(updatedSchedule);
                  }}
                />
              </div>
              <div className="endTime">
                <h5>Às</h5>
                <input
                  className=""
                  type="time"
                  value={period.endTime}
                  onChange={(event) => {
                    const selectedEndTime = event.target.value;
                    const updatedSchedule = [...workSchedule];
                    updatedSchedule[index].endTime = selectedEndTime;
                    setWorkSchedule(updatedSchedule);
                  }}
                />
              </div>

              <button
                id='remover'
                className='remove-button'
                onClick={() => handleRemovePeriod(index)}
              >
                Remover
              </button>
            </div>
          ))}
          <button className='send' onClick={handleSubmit}>
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default InputOnOff;
