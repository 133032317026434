
import './MenuItem.css'

export function DownItem({name}) {

  return (
    <div className='lista-container-menuitem'>
      <li className='titulo-menuitem' onClick={(e) => {
        const listaMenuItem = e.target.closest('.lista-container-menuitem');
        const itensListaMenuItem = listaMenuItem.querySelector('.itens-lista-menuitem');
    
        itensListaMenuItem.classList.toggle('hidden');
        document.getElementById(name.split(' ')[1]).classList.toggle('icon-arrow');

      }}> <span>{name}</span> <span id={name.split(' ')[1]} size='1em'/></li>
      <ul className='itens-lista-menuitem hidden'>
        <li className='texto-itens-menuitem'>Planejamento</li>
        <li className='texto-itens-menuitem'>Revisão</li>
      </ul>
    </div>
  )
}
