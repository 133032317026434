import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonCadastrar, TitleL2 } from "../../../../../components"
import { baseURL } from '../../../../../services/api';
import background from '../assets/images/background.jpg'; // Importe sua imagem aqui
import axios from 'axios';
import { Divider } from 'antd';
const Main = () => {
  const location = useLocation();
  const { search } = location;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    // Verifica se há parâmetros de consulta na URL
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('user_id')) {
      const userId = queryParams.get('user_id');
      console.log('Query parameter "user_id" exists:', userId);
      fetchUserData(userId);
    } else {
      console.log('Query parameter "user_id" does not exist');
    }
  }, [search]);
  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
  };
  const fetchUserData = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}get_users/${userId}`);
      setUserInfo(response.data.pacientes[0]);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };
  return (<>
    <section style={{
      width: "100%",
      minHeight: "85dvh",
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      justifyContent: 'center'
    }}>
      <div className='w-100'>
        {!loading ? (<>
          <div className="d-flex mx-auto justify-content-center" style={{ position: 'relative', width: '100%', minHeight: '700px' }}>
            <img src={background}
              alt="Professional social network background"
              style={{ width: '100%', minHeight: '700px', height: '100%', objectFit: 'cover', zIndex: -1, }} />
            <div style={{ position: 'absolute', textAlign: 'center', zIndex: 1, width: '100%', height: '100%' }}>
              <h1 className='text-left font-weight-bold mt-4 col-md-6' style={{ marginBottom: '0px' }}>
                <strong className='text-white' style={{ fontWeight: '800' }}> Rede Social Profissional {screenWidth <= 470 && <br />}</strong>
              </h1>
              <div className='col-md-6' style={{ marginTop: '0px' }}> <Divider className='' style={{ margin: '0px', borderColor: 'white', borderWidth: '3px', marginBottom: '10px' }} />  </div>
              <h5 className='text-justify  mb-4 col-md-6  text-white font-weight-bold' style={{ background: screenWidth <= 1058 && 'rgba(13, 71, 161, 0.8)' }}>Gerenciamento altamente eficiente. A plataforma para o seu sucesso profissional !
                Fature muito mais aqui, alcance novos patamares, construa seu futuro próspero com gerenciamento multiplo e vendas massivas recorrentes ! Comece agora mesmo :</h5>
              <div className='col-md-6 py-3'><ButtonCadastrar style={{ color: 'white' }} /></div>
            </div>
          </div>
          <div className='text-center py-2'>
            <a href={'./servicos#apresentacaoServicos#SolucaoIAAvancadaServicos' + search}
              className='text-secondary mt-3 mb-2'
              style={{ textDecorationLine: 'underline', textDecorationSkipInk: ' none', cursor: 'pointer' }}>
              <strong  >[ &nbsp;Conheça nosso sistema de Inteligência Artificial Integrada &nbsp;]</strong>
            </a>
          </div>
          <div className="bg-light py-5 mt-5 mb-5">
            <div className="d-flex flex-wrap justify-content-center bg-light col-md-12 mt-1">
              <div
                className="col-md-4 mt-4 "
              > <p className='text-center'><i className="fas fa-user fa-3x"></i></p>
                <h3 className="text-center font-weight-bold"><strong className='' style={{ fontFamily: ' Arial, Helvetica', fontWeight: '900' }}>Para Usuários</strong></h3>
                <p className="text-justify mt-2 mb-4">
                  Compre serviços e produtos de alta qualidade com recorrência mantenha-se sempre seguro, histórico
                </p>
                <p className={`text-center mt-1 ${screenWidth <= 1058 && 'mb-5'}`}> <a href={'./servicos' + search} className="btn-primary rounded mr-2 ml-2 py-2 no-drag mt-5" style={{ cursor: 'pointer' }}>
                  &nbsp;👉<b>  Saiba mais </b> 👈&nbsp;
                </a></p>
              </div>
              <div
                className="col-md-4 mt-4 "
              > <p className='text-center'><i className="fas fa-briefcase fa-3x"></i></p>
                <h3 className="text-center font-weight-bold"><strong style={{ fontFamily: ' Arial, Helvetica', fontWeight: '900' }}>Para Profissionais</strong></h3>
                <p className="text-justify mt-2 mb-4">
                  Atenda seus clientes com esse software personalizado, tenha dropshipping, conte com várias I.As para auxílio
                </p>
                <p className={`text-center mt-1 ${screenWidth <= 1058 && 'mb-5'}`}> <a href={'./servicos#apresentacaoServicos' + search} className="btn-primary rounded mr-2 ml-2 py-2 no-drag mt-5" style={{ cursor: 'pointer' }}>
                  &nbsp;👉<b>Saiba mais</b>  👈&nbsp;
                </a></p>
              </div>
              <div
                className="col-md-4 mt-4 "
              > <p className='text-center'><i className="fas fa-building fa-3x"></i></p>
                <h3 className="text-center font-weight-bold"><strong style={{ fontFamily: ' Arial, Helvetica', fontWeight: '900' }}>Para Empresas</strong></h3>
                <p className="text-justify mt-2 mb-4">
                  Seja um representante, fornecedor ou aprimore, desenvolva softwares e equipamentos com a THOT I.A. CORP.
                </p>
                <p className={`text-center mt-1 ${screenWidth <= 1058 && 'mb-5'}`}>
                  <a href={'./parcerias' + search} className=" btn-primary rounded mr-2 ml-2 py-2 no-drag mt-5" style={{ cursor: 'pointer' }}>
                    &nbsp;👉<b>  Saiba mais </b> 👈&nbsp;
                  </a></p>
              </div>
            </div>
          </div></>) : (<>
            {userInfo && userInfo.fotoFace &&
              <div className='col-md-12 text-center mt-5'>
                <img
                  src={`${baseURL}imagem?diretorio=../../services/pages/Private/Usuario/Cadastro/FotoFace/assets/&image=${encodeURIComponent(userInfo.fotoFace)}`}
                  className="rounded-circle"
                  alt={userInfo.name}
                  style={{ maxHeight: '200px', minHeight: '200px', maxWidth: '200px', minWidth: '200px' }}
                /></div>
            }
            {userInfo && (
              <div className="col-md-12 text-center mt-1">
                <TitleL2>{userInfo.name}</TitleL2>
                <p>{userInfo.unique_identification}</p>
                {/* Renderize outras propriedades de userInfo conforme necessário */}
              </div>
            )}
          </>)}
      </div>
    </section>
  </>);
};
export default Main;