import React, { useState } from 'react';

import { InputPadrao } from '../../../../components';

export const ThemeContextTextSecondary = ({ onChange }) => {
  const [colorTextSecondary, setColorTextSecondary] = useState('');

  const handleColorChangeTextSecondary= (event) => {
    const newColorTextSecondary = event.target.value;
    setColorTextSecondary(newColorTextSecondary);
    onChange(newColorTextSecondary);
  };

  return (
    <InputPadrao title={'Cor Secundária'}
      type="color"
      value={colorTextSecondary}
      onChange={handleColorChangeTextSecondary}
    />
  );
};

export default ThemeContextTextSecondary;
