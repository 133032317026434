import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { Input, Select, Button, message } from 'antd';
import { baseURL } from '../../../../../../../../services/api';
import { AuthContext } from '../../../../../../../../contexts/auth';
import ProfissionalVinculadoCard from './ProfissionalVinculadoCard';
import { axiosHeadersToken } from '../../../../../../../../services/contexts';
const { Option } = Select;
const ProfissionalVinculado = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [unitTypes, setUnitTypes] = useState([]);
    const { getUserId, getProfessionalId } = useContext(AuthContext);
    const professional_id = getProfessionalId();
    const user_id = getUserId()
    const [cbo, setCbo] = useState([]);
    const [selectedCBO, setSelectedCBO] = useState([]);
    const [type, setType] = useState([]);
    useEffect(() => {
        const cboLocalStorage = localStorage.getItem('professionalCBO');
        if (cboLocalStorage) {
            const cbo = JSON.parse(cboLocalStorage);
            setCbo(cbo);
        }
    }, []);

    const fetchCompanies = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseURL}get_company`, {
                params: {
                    page: currentPage,
                    type: selectedType,
                    search: searchText,
                }
            });
            const { data } = response;
            const companiesToShow = data.companies;
            const totalPages = data.totalPages;
            setCompanies(companiesToShow);
            setFilteredCompanies(companiesToShow.slice(0, 12));
            setTotalPages(totalPages);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            message.error('Erro ao buscar empresas.');
        }
    }, [currentPage, selectedType, searchText]);

    const fetchUnitTypes = useCallback(() => {
        const types = companies.map(company => company.unit_type);
        const uniqueTypes = Array.from(new Set(types));
        setUnitTypes(uniqueTypes);
    }, [companies]);
    useEffect(() => {
        fetchCompanies();
        fetchUnitTypes();
    }, [fetchCompanies, fetchUnitTypes]);
    const handleTypeChange = value => {
        setSelectedType(value);
        setCurrentPage(1);
    };
    const handleSearchTextChange = e => {
        setSearchText(e.target.value);
        setCurrentPage(1);
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleDeleteCompanyUnit = companyId => {
    };
    const handleAdicionarClick = (companyId, type, selectedCBO) => {
        cadastrarProfissionalUnit(companyId, type, selectedCBO);
    };
    const cadastrarProfissionalUnit = async (companyId, type, selectedCBO) => {
        setIsLoading(true);
        try {
            const response = await axiosHeadersToken().post(`${baseURL}post_professional_unit/${user_id}/${companyId}`, {
                professionals: [
                    {
                        professional_id: professional_id,
                        professional_type: type,
                        professional_cbo: selectedCBO,
                        company_unit_id: companyId,
                    },
                ],
            });
            alert(response.data.message);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            message.error('Erro ao cadastrar o profissional');
            setIsLoading(false);
        }
    };
    return (<>
        <div className={`text-center d-flex justify-content-center col-lg-12 boxFooter 
                        ${professional_id ? 'bg-success' : 'bg-primary'
            } TridimencionalSupLat`}>
            <h5 className="text-white mt-2">
                <b>{props.title}</b>
            </h5>
        </div>
        <div className="py-3 CardTHOT bg-light">
            <div className='input-group-append'>
                <Input
                    className='form-control mt-1 form-control-lg text-dark'
                    placeholder="Buscar empresas"
                    value={searchText}
                    onChange={handleSearchTextChange}
                />
                <Select
                    className="custom-select text-center border border-dark mx-auto font-weight-bold rounded mt-1"
                    value={selectedType}
                    onChange={handleTypeChange}
                >
                    <Option value="">Selecione o Tipo</Option>
                    {unitTypes.map(cbo => (
                        <Option key={cbo} value={cbo}>
                            {cbo}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="col-md-12">
                <div className="d-flex flex-wrap justify-content-center">
                    {filteredCompanies.map(company => (
                        <ProfissionalVinculadoCard
                            key={company.id}
                            companyUnit={company}
                            onDelete={handleDeleteCompanyUnit}
                            onAdicionar={handleAdicionarClick}
                            title={props.typo}
                            cboOptions={cbo}
                            selectedCBO={selectedCBO}
                            setSelectedCBO={setSelectedCBO}
                            setType={setType}
                            allowedOptions={props.allowedOptions}
                            type={type}
                        />
                    ))}
                </div>
            </div>
            <div className="col-md-12 text-center mt-4">
                <Button onClick={handlePreviousPage} disabled={currentPage === 1 || isLoading}>
                    {isLoading ? 'Buscando...' : 'Anterior'}
                </Button>
                <span className='mr-1 ml-1 text-dark'>{currentPage}</span>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages || isLoading}>
                    {isLoading ? 'Buscando...' : 'Próxima'}
                </Button>
                <div className='mt-2'>
                    <small>Página atual: {currentPage}</small>
                    <small className='ml-2'>Total Pag.: {totalPages}</small>
                </div>
            </div>
        </div>
        <div className={` text-center  d-flex  justify-content-center align-items-center  
                         ${professional_id ? 'bg-success' : 'bg-primary'
            } TridimencionalInfLagerLat py-2`}>
            <h5 className="text-white rounded  mt-1 justify-content-center align-items-center d-flex">
                <b>{professional_id ? <i className="fa fa-check" aria-hidden="true"></i> : '...'}</b>
            </h5>
        </div>
    </>);
};
export default ProfissionalVinculado;