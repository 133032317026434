import React from 'react';
import { ApresentacaoParcerias } from '../layout/ApresentacaoParcerias';
import { EquipamentosMedicos } from '../layout/EquipamentosMedicos';
import { EquipamentosPesquisasAnimais } from '../layout/EquipamentosPesquisasAnimais';
import { CadastroParcerias } from '../layout/CadastroParcerias';
import { DadosDeProjetos } from '../layout/DadosDeProjetos';
import { OportunidadesDeParcerias } from '../layout/OportunidadesDeParcerias';
import RepresentacaoComercial from '../layout/RepresentacaoComercial';
import SoftwareHouse from '../layout/SoftwareHouse';
import Fornecedores from '../layout/Fornecedores';
import ColaboreConosco from '../layout/ColaboreConosco';
export const items = [
  {
    id: 'apresentacaoParcerias',
    title: 'Parcerias',
    component: <ApresentacaoParcerias />,
    subItems: [
    ],
  },
  {
    id: 'representacao',
    title: 'Representação Comercial',
    component: <RepresentacaoComercial />,
    subItems: [
    ],
  },
  {
    id: 'SoftwareHouse',
    title: 'Software House',
    component: <SoftwareHouse />,
    subItems: [
      { id: 'dadosDeParcerias', title: 'Dados de Projetos', component: <DadosDeProjetos /> },
      {
        id: 'cadastroParcerias', title: 'Cadastro de Projetos', component: <CadastroParcerias />,
      },
    ],
  },
  {
    id: 'Pesquisadores',
    title: 'Pesquisadores',
    component: <OportunidadesDeParcerias />, // You can replace this with the actual component you want to render.
    subItems: [
      { id: 'equipamentosMedicos', title: 'Termografia Médica', component: <EquipamentosMedicos /> },
      { id: 'equipamentosPesquisasAnimais', title: 'Termografia Veterinária', component: <EquipamentosPesquisasAnimais /> },
      // Add more sub-items here...
    ],
  },
  {
    id: 'fornecedores',
    title: 'Fornecedores',
    component: <Fornecedores />,
    subItems: [
    ],
  },
  {
    id: 'ColaboreConosco',
    title: 'Colabore Conosco',
    component: <ColaboreConosco />,
    subItems: [
    ],
  },
];
export default items;