import React, { useState, useEffect, useContext } from 'react';
import { Table, Space, Modal, Form, Input, Button } from 'antd';
import { NavBreadcrumb } from '../../../../../../../components';
import './editarArmazenados.css';
import { AuthContext } from '../../../../../../../contexts/auth';
import {buscarAtestadosService, editarAtestadosService} from '../../../../../../../services';

const ViewAtestadosArmazenados = () => {
  const [atestados, setAtestados] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editAtestadoData, setEditAtestadoData] = useState({});
  const [form] = Form.useForm();
  const [filteredInfo, setFilteredInfo] = useState({});
  const { getProfessionalId } = useContext(AuthContext);
  const professional_id = getProfessionalId();
  useEffect(() => {
    buscarAtestadosService({ setAtestados, professional_id });
  }, [filteredInfo, professional_id]);

  const handleEdit = (record) => {
    setEditAtestadoData(record);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };
  


  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Filtrar ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<i className="fas fa-search"></i>}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset()} size="small" style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: <i className="fas fa-search" />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleReset = () => {
    setFilteredInfo({});
  };


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id', 'ID'),
    },
    {
      title: 'Data de Emissão',
      dataIndex: 'data_emissao',
      key: 'data_emissao',
      ...getColumnSearchProps('data_emissao', 'Data de Emissão'),
    },
    {
      title: 'Data de Início',
      dataIndex: 'data_inicio',
      key: 'data_inicio',
      ...getColumnSearchProps('data_inicio', 'Data de Início'),
    },
    {
      title: 'Data de Fim',
      dataIndex: 'data_fim',
      key: 'data_fim',
      ...getColumnSearchProps('data_Fim', 'Data de Fim'),

    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
      ...getColumnSearchProps('descricao', 'Descricao'),

    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      ...getColumnSearchProps('estado', 'Estado'),

    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)}>Editar</Button>
          {/* outros botões, se necessário */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="d-flex">

        <div className="w-100">

          <NavBreadcrumb text={' Atestados'} icon={'user fa'} />
          <div className="Atestado-Paciente-Editar">
            <div className="Atestado-titulo-Editar">Editar Atestados</div>
          </div>

          <div className="Atestado-Data-table-container-Editar">
            <Table
              columns={columns}
              dataSource={atestados}
              onChange={(pagination, filters) => setFilteredInfo(filters)}
            />
            <Modal
              title="Editar Atestado"
              visible={modalVisible}
              onCancel={handleCancel}
              footer={null}
            >
              <Form
                form={form}
                onFinish={(values) =>
                  editarAtestadosService(
                    values,
                    setModalVisible,
                    professional_id,
                    setAtestados
                  )
                }
                initialValues={{
                  id: editAtestadoData.id,
                  data_emissao: editAtestadoData.data_emissao,
                  data_inicio: editAtestadoData.data_inicio,
                  data_fim: editAtestadoData.data_fim,
                  descricao: editAtestadoData.descricao,
                  estado: editAtestadoData.estado,
                }}
              >
                <Form.Item label="ID" name="id" hidden>
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Data de Emissão" name="data_emissao">
                  <Input />
                </Form.Item>
                <Form.Item label="Data de Início" name="data_inicio">
                  <Input />
                </Form.Item>
                <Form.Item label="Data de Fim" name="data_fim">
                  <Input />
                </Form.Item>
                <Form.Item label="Descrição" name="descricao">
                  <Input />
                </Form.Item>
                <Form.Item label="Estado" name="estado">
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Salvar
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAtestadosArmazenados;