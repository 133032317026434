import NavHeader from "../NavHeader/NavHeader";
import {NavUser} from "./NavUser";
export const NavUserMain = () => {
    return (<>
        <NavHeader
            HTML={
                <NavUser />
            }
        />
    </>)
}