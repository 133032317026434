import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAdicionarCampo, MaisInfoMenosInfo, ButtonRemoverCampo, ButtonEditarCampo } from '../../../../../../components';

import ProntuarioProblemasCoracaoInfo from '../info/ProntuarioProblemasCoracaoInfo';

export const ProntuarioProblemasCoracao = () => {
  const [temProblemaCoracao, setTemProblemaCoracao] = useState(false);
  const [problemasCoracao, setProblemasCoracao] = useState([]);
  const [novoProblemaCoracao, setNovoProblemaCoracao] = useState('');
  const [indiceEditando, setIndiceEditando] = useState(-1);

  const handleCheckboxChange = (e) => {
    setTemProblemaCoracao(e.target.checked);
  };

  const handleNovoProblemaCoracaoChange = (e) => {
    setNovoProblemaCoracao(e.target.value);
  };

  const handleAdicionarProblemaCoracao = () => {
    if (novoProblemaCoracao.trim() !== '') {
      if (indiceEditando === -1) {
        setProblemasCoracao([...problemasCoracao, novoProblemaCoracao]);
      } else {
        const novosProblemasCoracao = [...problemasCoracao];
        novosProblemasCoracao[indiceEditando] = novoProblemaCoracao;
        setProblemasCoracao(novosProblemasCoracao);
        setIndiceEditando(-1);
      }
      setNovoProblemaCoracao('');
    }
  };

  const handleEditarProblemaCoracao = (index) => {
    setNovoProblemaCoracao(problemasCoracao[index]);
    setIndiceEditando(index);
  };

  const removerProblemaCoracao = (index) => {
    const novosProblemasCoracao = [...problemasCoracao];
    novosProblemasCoracao.splice(index, 1);
    setProblemasCoracao(novosProblemasCoracao);
  };


  return (
    <>
      <h5 style={{ fontWeight: "bold" }} className="mt-3">
        Tem ou teve algum problema de coração?      </h5>
      <label>
        <input type="checkbox" onChange={handleCheckboxChange} /> Sim
      </label>
      <br />
      <label>
        <input type="checkbox" onChange={() => setTemProblemaCoracao(false)} /> Não
      </label>
      <br />
      <label>
        <input type="checkbox" onChange={() => setTemProblemaCoracao(true)} /> Não Sei
      </label>
      {temProblemaCoracao && (
        <div>
          *Se Sim, quais?        <br />
          <input
            type="text"
            value={novoProblemaCoracao}
            onChange={handleNovoProblemaCoracaoChange}
            placeholder="Especifique o problema de coração"
          />
          <ButtonAdicionarCampo type={'button'} onClick={handleAdicionarProblemaCoracao} />
        </div>
      )}
      {temProblemaCoracao && problemasCoracao.length > 0 && (
        <div>        <br />
          <h6><strong>Problemas de Coração Adicionados:</strong></h6>
          <ul>
            {problemasCoracao.map((problema, index) => (
              <li key={index}>{problema}
                <ButtonEditarCampo onClick={() => handleEditarProblemaCoracao(index)} />
                <ButtonRemoverCampo onClick={() => removerProblemaCoracao(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="col-md-12 mt-2 mb-5 d-flex">
        <Link className="btn btn-primary mx-auto col-md-6" href="#">
        Enviar Informações
        </Link>
      </div>
      <MaisInfoMenosInfo text={<ProntuarioProblemasCoracaoInfo />}></MaisInfoMenosInfo> 
    </>
  );
};

export default ProntuarioProblemasCoracao;
