import { NavUserMain, NavBreadcrumb, FooterUser, Nav, } from "../../../../components";
import Index from "../../../Public/Carrinho/Carrinho";
export const CarrinhoPaciente = () => {
  return (<>
    <Nav />
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      <NavUserMain />
      <section className="w-100 ">
        <NavBreadcrumb icon={'user fa'} text={'Carrinho'} />
        <Index />
        <FooterUser />
      </section>
    </div>
  </>)
}