import React, { useContext, useState } from 'react';
import { Form, Input, Button, Divider, Tooltip, message } from 'antd';
import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { ButtonCadastrar, MaisInfoMenosInfo, TitleL1, TitleL2 } from '../../../../../components';
import RedesSociais from './RedesSociais';
import RepresentacaoComercialInfo from '../info/RepresentacaoComercialInfo';
import { AuthContext } from '../../../../../contexts/auth';
import { axiosHeadersToken } from '../../../../../services/contexts';
import { baseURL } from '../../../../../services/api';
const { TextArea } = Input;
const RepresentacaoComercial = () => {
    const [fields, setFields] = useState([{ name: '', url: '', otherName: '' }]);
    const { getUserId, getProfessionalId } = useContext(AuthContext);
    const [convite, setConvite] = useState('');
    const professional_id = getProfessionalId();
    const user_id = getUserId();
    const onFinish = async (values) => {
        const conviteToken = await enviarConvite(values, user_id, professional_id);
        setConvite(conviteToken);
        if (values.social_networks[0].name) {
            alert(JSON.stringify(values))
            try {
                const response = await axiosHeadersToken().post(`${baseURL}user_social_network`, {
                    professional_id: professional_id,
                    user_id: user_id,
                    social_networks: values,
                });
                console.log('Resposta da rede social:', response.data);
            } catch (error) {
                console.error('Erro ao enviar dados para a rede social:', error);
            }
        };
    }
    const enviarConvite = async (values, user_id, professional_id) => {
        if (user_id) {
            try {
                const conviteResponse = await axiosHeadersToken().post(`${baseURL}post_convite/${user_id}`, {
                    owner_user_id: user_id,
                    owner_professional_id: professional_id,
                    owner_professional_unit_id: null, // Adicione os outros campos necessários aqui
                    owner_company_id: null,
                    owner_company_unit_id: null,
                    user_id: null,
                    professional_id: null,
                    company_unit_id: null,
                    observation: values.message,
                });
                console.log('Resposta do convite:', conviteResponse.data);
                return conviteResponse.data.token;
            } catch (error) {
                console.error('Erro ao enviar convite:', error);
                throw error; // Propaga o erro para o chamador
            }
        };
        alert('Fazer login precisamos da identificação')
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(`https://thotiacorp.com.br/?convite_code=${convite}`).then(() => {
            message.success('Link copiado com sucesso!');
        }).catch(err => {
            message.error('Erro ao copiar o link!');
        });
    };
    return (<>
        <section className='mr-3 ml-3' style={{ minHeight: '95dvh' }}>
            <p className='text-center'>
                <img
                    className="d-block image-fluid mx-auto col-md-12"
                    style={{ maxHeight: '450px', maxWidth: '450px', }}
                    src={require('../assets/RepresentacaoComercial.png')}
                    alt='Imagem de lâmpada moderna para representar a ideia e o desenvolvimento'
                />
            </p>
            <TitleL1>Representação Comercial</TitleL1>
            <h5 className='text-justify'>
                [ Representação Comercial Descentralizada Regional ] Buscamos atender nossos clientes de forma mais próxima e eficiente.
                Nossa abordagem descentralizada nos permite entender muito melhor as necessidades específicas de cada região e cliente,
                oferecendo soluções personalizadas e adaptadas, além de, fazer a captação de novos usuários de forma capilarizada
                <br /><br />
                Para captação de novos usuários capilarizada compartilhamos lucros vinculados ao usuário nível profissional ou (+) com nossos representantes no modo convite único - [ eterno ] - enquanto
                o usuário / profissional ou (+) for vinculado a plataforma o representante terá lucros passivos. <strong>Em números estamos compartilhamento 10% dos lucros da Rede Social Profissional sobre o profissional ou (+) com o representante comercial</strong>
                <br /><br />
                <strong className='text-danger'>  Existe uma observação forte na regra acima - Atenção - </strong>caso o profissional  ou (+) seja totalmente dependente de uma unidade exemplo governo ou empresas o lucro da indicação é do magistral dono do convite de usuário
                do governante maior ou do proprietário da company. Nesse caso para ganhar o company, company_unit, e profissional_unit precisa ter indicado o dono da company.
                Isso não descarta o ganho de movimentação desses mesmos usuários professional_unit como profissional independente ou dono de company
            </h5>
            <h5 className='mt-5 font-weight-bold text-justify'><strong>Programa de parceria recomendado para:</strong></h5>
            <div className=' mt-3 text-justify'>
                <h5>✅ Startups</h5>
                <h5>✅ Empreendedores Digitais</h5>
                <h5>✅ Digital Influencers</h5>
                <h5>✅ Agências de Publicidade e Propaganda</h5>
                <h5>✅ Instituições de Ensino e Educação</h5>
                <h5>✅ Organizações Sem Fins Lucrativos</h5>
            </div>
            <MaisInfoMenosInfo text={<><RepresentacaoComercialInfo /></>} />
            <Form
                name="RepresentacaoComercial-regional-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                className='mt-5 border bg-light rounded py-2 col-md-12 mb-3'
            >
                <div className='text-center'>
                    <TitleL2>Dados para Representar </TitleL2>
                    <Divider />
                    <Form.Item
                        label="Mensagem"
                        name="message"
                        rules={[{ required: true, message: 'Por favor, insira uma mensagem!' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <RedesSociais fields={fields} setFields={setFields} />
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Gerar Link
                        </Button>
                    </Form.Item>
                </div>
                {convite && (
                    <div className='mt-3 mb-3 col-md-12 text-center py-2' style={{ backgroundColor: 'rgba(183, 235, 143, 0.2)' }}>

                        <CheckCircleOutlined className='text-success' style={{ fontSize: '48px', }} />
                        <TitleL2 style={{ color: "green" }}>Link gerado</TitleL2>
                        <h5 style={{ color: 'blue', wordWrap: 'break-word' }}>https://thotiacorp.com.br/?convite_code={convite}</h5>
                        <Tooltip title="Copiar link">
                            <Button icon={<CopyOutlined />} onClick={handleCopy} />
                        </Tooltip>
                    </div>
                )}
            </Form>
            <ButtonCadastrar/>
        </section>
    </>);
};
export default RepresentacaoComercial;