import React, { useState } from 'react';

import {
  InputPadraoTitle,
  ButtonAdicionarCampo,
  MaisInfoMenosInfo,
  ButtonRemoverCampo,
  ButtonEditarCampo
} from '../../../../../../../components';
import ProntuarioQueixasPrincipaisInfo from '../../info/ProntuarioQueixasPrincipaisInfo';


export const ProntuarioQueixasPrincipais = ({ info }) => {
  const [queixa, setQueixa] = useState('');
  const [queixasPrincipais, setQueixasPrincipais] = useState([]);
  const [indiceEditando, setIndiceEditando] = useState(-1);
  const handleQueixaChange = (e) => {
    setQueixa(e.target.value);
  };
  const adicionarQueixa = () => {
    if (queixa.trim() !== '') {
      if (indiceEditando === -1) {
        setQueixasPrincipais([...queixasPrincipais, queixa]);
      } else {
        const novasQueixas = [...queixasPrincipais];
        novasQueixas[indiceEditando] = queixa;
        setQueixasPrincipais(novasQueixas);
        setIndiceEditando(-1);
      }
      setQueixa('');
    }
  };
  const removerQueixa = (index) => {
    const novasQueixas = [...queixasPrincipais];
    novasQueixas.splice(index, 1);
    setQueixasPrincipais(novasQueixas);
  };
  const handleEditarQueixa = (index) => {
    setQueixa(queixasPrincipais[index]);
    setIndiceEditando(index);
  };
  return (
    <>

      <div>
        <InputPadraoTitle htmlFor={'Queixas principais'} title={'Queixas principais'} />
        <textarea
          className="form-control text-center col-md-12 border border-dark"

          type="text"
          value={queixa}
          onChange={handleQueixaChange}
          placeholder="Informe uma queixa principal"
        />
        &nbsp;Quais suas queixas principais frente ao profissional<strong> {info}</strong>
        <div className="col-md-12 mt-2 d-flex">
          <ButtonAdicionarCampo type={'button'} onClick={adicionarQueixa} />
        </div>
      </div>
      {queixasPrincipais.length > 0 && (
        <div>
          <h6>Queixas Principais Adicionadas:</h6>
          <ul>
            {queixasPrincipais.map((item, index) => (
              <li key={index}>
                {item}{' '}
                <ButtonRemoverCampo onClick={() => removerQueixa(index)} />
                <ButtonEditarCampo onClick={() => handleEditarQueixa(index)} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className='py-4'>
        <MaisInfoMenosInfo text={<ProntuarioQueixasPrincipaisInfo />}></MaisInfoMenosInfo>
      </div>
    </>
  );
};

export default ProntuarioQueixasPrincipais;
