import React, { useContext } from 'react';
import MainCarrinhoPublic from '../../layout/MainCarrinhoPublic';
import CartOrder from '../../layout/CartOrder';
import { AuthContext } from '../../../../../../contexts/auth';

export const ItemsList = () => {
  const { getUserId } = useContext(AuthContext);
  const user_id = getUserId();

  const items = [
    {
      id: 'NavPublicCarrinhoItens',
      title: 'Itens no carrinho',
      component: <MainCarrinhoPublic />,
      subItems: [],
    },
  ];

  if (user_id) {
    items.push({
      id: 'NavPublicCarrinhoOrder',
      title: 'Pedidos',
      component: <CartOrder />,
      subItems: [],
    });
  }

  return items;
};

export default ItemsList;
