import React from 'react';

const PeriodSelect = ({ value, onChange }) => {
  return (
    <select name="periodo_grafico" value={value} onChange={(e) => onChange({ value: e.target.value }, 'periodo_grafico')}>
      <option value="periodozero"></option>
      <option value="Por Hora">Por Hora</option>
      <option value="Diariamente">Diariamente</option>
      <option value="Semanalmente">Semanalmente</option>
      <option value="Mensalmente">Mensalmente</option>
      <option value="Trimestralmente">Trimestralmente</option>
      <option value="Anualmente">Anualmente</option>
    </select>
  );
};

export default PeriodSelect;
