import { Input } from "antd";
export function InputPadraoSearch(props) {
    return (<>
        <div className={`generate-container ${props.className}`}>
            <div className="input-container">
                <Input
                    className={`input-field form-control form-control-lg text-dark text-center`}
                    id={props.id}
                    type={props.type}
                    value={props.value}
                    onKeyDown={props.onKeyDown}
                    onChange={props.onChange}
                    validate={props.validate}
                    placeholder={props.placeholder}
                    style={{ borderRadius: '20px 20px 20px 20px', borderSolid: '1px' }}
                />
                <i onClick={props.onClick} className="generate-button input-filled fa fa-search mt-1 flip-horizontal text-dark" aria-label="Buscar" ></i>
            </div>
        </div>
    </>);
}
export default InputPadraoSearch;