import {NavBreadcrumb, CardDirecionamento} from '../../../../../../components'
export const Main = () => {
    return (<>
        <div className=' w-100'>
            <NavBreadcrumb icon={'user-md fa'} text={'Produtos'} />
            <div className="text-center">
                <div className="container-fluid">
                    <div className="row d-inline-flex justify-content-center w-100">
                        <CardDirecionamento text={'Cadastrar'} icon={'mt-3 fa fa-line-chart fa-3x text-white'} href={`./cadastrar`} />
                        <CardDirecionamento text={'Estoque'} icon={'mt-3 fa fa-line-chart fa-3x text-white'} href={`./estoque`} />
                        <CardDirecionamento text={'Vendidos'} icon={'mt-3 fa fa-line-chart fa-3x text-white'} href={`./vendidos`} />
                    </div>
                </div>
            </div>
        </div>
    </>)
}