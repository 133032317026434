import {  NavBreadcrumb } from '../../../../../../../components';
export const Index = () => {
    return (<>
        <div className='w-100'>
            <NavBreadcrumb icon={'suitcase fa'} text={'Serviços'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                           </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}