import { Tag } from "antd";
import { TitleL2 } from "../../../../../../../../../components";
import { WarningOutlined } from "@ant-design/icons";
export const AvisoIA = () => {
  return (<>
    <Tag className='mt-3 mb-3 d-flex col-md-12' style={{
      width: '100%',
      padding: '60px',
      whiteSpace: 'pre-line'
    }} icon={<WarningOutlined style={{ fontSize: '48px', paddingInlineEnd: '50px' }} />} color="warning">

      <TitleL2 style={{ textAlign: "left" }} className='text-danger'>Aviso importante:</TitleL2>
      <li className='text-dark mt-3'><strong>Escolha ou crie uma I.A. para liberar a vizualização deste componente </strong></li>
    </Tag>
  </>);
};
export default AvisoIA;