import React, { useState, useRef } from 'react';
import { Card, Button, message } from 'antd';
import Webcam from 'react-webcam';
import { MaisInfoMenosInfo } from '../../../../../../../../../../../components';

const FaceVideoRecorder = ({ onVideoBlob, piscaMaisInfo }) => {
  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showCamera, setShowCamera] = useState(false); // Controle de visibilidade da câmera
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  // Inicia a gravação do vídeo
  const startRecording = () => {
    setRecording(true);
    setShowCamera(true); // Mostrar a câmera quando a gravação começar
    
    if (webcamRef.current && webcamRef.current.stream) {
      const stream = webcamRef.current.stream;
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
        onVideoBlob(blob); // Passar o blob de vídeo para o componente pai
        setShowCamera(false); // Ocultar a câmera após parar a gravação
      };

      mediaRecorder.start();
    } else {
      message.error('A câmera não está disponível.');
      setRecording(false);
    }
  };

  // Para a gravação do vídeo
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    } else {
      message.error('Nenhuma gravação em andamento.');
    }
  };

  return (
    <Card title="Conversão Vídeo - 3D" style={{ marginBottom: 16 }}>
      {showCamera && (
        <Webcam
          audio
          ref={webcamRef}
          style={{ width: '100%' }}
        />
      )}
      {videoUrl && (
        <div style={{ marginBottom: 16 }}>
          <video src={videoUrl} controls style={{ width: '80px' }} />
        </div>
      )}
      <Button
        onClick={recording ? stopRecording : startRecording}
      
      >
        {recording ? 'Parar Gravação' : 'Iniciar Gravação'}
      </Button>
      <MaisInfoMenosInfo destacar={piscaMaisInfo}/>
    </Card>
  );
};

export default FaceVideoRecorder;
