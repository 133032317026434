import { baseURL } from "../../../../api.js";
import { axiosHeadersToken } from "../../../../contexts";
export const atualizarCadastroPaciente = async (
  id,
  email,
  name,
  password,
  passwordAtualizado,
  cpf,
  phone,
  dataNascimento,
  address,
  setIsLoading
) => {
  setIsLoading(true);
  try {
   const response = await axiosHeadersToken().put(`${baseURL}put_user/${id}`, {
    user_id: id,
    email,
    name,
    password,
    passwordAtualizado,
    cpf,
    phone,
    dataNascimento,
    address,
});
    alert(response.data.message);
    setIsLoading(false);
  } catch (error) {
    if (error.response) {
      alert(error.response.data.message);
    } else if (error.request) {
      alert('Request error');
    } else {
      alert('Unknown error');
    }
    setIsLoading(false);
  }
};
export default atualizarCadastroPaciente;