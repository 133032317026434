
import { FooterProfissional, NavProfissionalMain } from "../../../../../../components";
import TextIAMain from './layout/TextIAMain';
export const TextIA = () => {
    return (<>
        <div className="d-flex">
            <NavProfissionalMain />
            <div className="w-100">
                <TextIAMain />
                <FooterProfissional />
            </div>
        </div>
    </>)
};