import ProdutoList from '../layout/comprar/ProdutoList';
import ApresentacaoProdutosPublic from '../layout/vender/Apresentacao/layout/ApresentacaoProdutosPublic';
export const items = [
  {
    id: 'produtos', title: 'Comprar Produtos', component: <ProdutoList />,
    subItems: [],
  },
  {
    id: 'apresentacao', title: 'Vender Produtos', component: <ApresentacaoProdutosPublic />,
    subItems: [],
  },
];
export default items;