import { baseURL } from "../../../../api.js";
import { axiosHeadersToken } from "../../../../contexts";
export const buscarCBO = async ( setOccupations, searchTerm, id ) => {
    try {
        const response = await axiosHeadersToken().get(`${baseURL}get_user_cbo_info/${id}`, {
            params: {
                search: searchTerm,
            },
        });
        const data = response.data;
        setOccupations(data);
    } catch (error) {
        console.error('Error fetching CBO:', error);
    }
};
export default buscarCBO;
