import { useState, useEffect } from 'react';

export const useDriveUser = () => {
  const [driveUser, setDriveUser] = useState(JSON.parse(localStorage.getItem('drive_user')));

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'drive_user') {
        setDriveUser(JSON.parse(event.newValue));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const updateDriveUser = (user) => {
    localStorage.setItem('drive_user', JSON.stringify(user));
    setDriveUser(user);
  };

  const clearDriveUser = () => {
    localStorage.removeItem('drive_user');
    setDriveUser(null);
  };

  return {
    driveUser,
    updateDriveUser,
    clearDriveUser
  };
};
