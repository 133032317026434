import React, { useRef, useState } from 'react';
import { InputPadrao, InputPadraoSearch, TitleL2 } from '../../../../../components';
import CarrinhoVazio from './CarrinhoVazio';
import { Divider, Image, Select } from 'antd';
const { Option } = Select;
export const ItemsNoCarrinho = ({
    cart,
    searchTerm,
    setSearchTerm,
    InputPadraoTitle,
    selectedType,
    handleTypeChange,
    types,
    currentItems,
    handlequantityChange,
    handleDeleteItem,
    pageButtons
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const buttonRef = useRef(null);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        if (!isExpanded) {
            buttonRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const parseImage = (image) => {
        try {
            return JSON.parse(image);
        } catch {
            return image;
        }
    };
    return (<>
        <section>
            {cart.length === 0 ? (
                <CarrinhoVazio />
            ) : (<>
                <InputPadraoSearch
                    id="inlineFormInputGroup"
                    placeholder="Busca itens no carrinho..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    typeButton="button"
                />
                <div className='d-flex mx-auto justify-content-center align-items-center'></div>
                <div id="mostraMaisOpcoesBusca" className={`collapse align-items-center justify-content-center ${isExpanded ? 'show' : ''}`}>
                    <div className='flex-wapper col-md-3 mt-2'>
                        <InputPadraoTitle title={'Filtrar por tipo'} />
                        <Select
                            className="w-100 text-dark text-center"
                            value={selectedType}
                            onChange={handleTypeChange}
                        >
                            {types.map((type, index) => (
                                <Option key={index} value={type}>
                                    {type}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className='col-md-12 py-2'>
                    <button
                        ref={buttonRef}
                        className={`${isExpanded ? "text-danger" : 'text-dark'}`}
                        onClick={toggleExpand}
                        style={{ border: 'none', background: 'none', padding: 0, margin: 0 }}
                    >
                        {`${isExpanded ? 'Menos opções de filtros' : 'Mais opções de filtros'}`} ... <i className="fa fa-search flip-horizontal mr-2"></i>
                    </button>
                    <div className='row'>
                        {currentItems.map((item, index) => {
                            const images = parseImage(item.image);
                            return (
                                <div className="col-md-12" key={index}>
                                    <div className="row mt-2 rounded align-items-center">
                                        <div className='col-md-1 text-center justify-content-center mx-auto'>
                                            {Array.isArray(images) ? (
                                                <div >
                                                    <Image
                                                        className="w-100 col-md-12"
                                                        height={50} width={80} src={images[0]?.url || ""}
                                                        alt="Produtos services_imagem"
                                                    />
                                                </div>
                                            ) : (
                                                <Image
                                                    className="w-100 rounded  image-fluid"
                                                    style={{ minHeight: '50px', maxHeight: '50px', minWidth: '50px', maxWidth: '50px' }}
                                                    src={images}
                                                    alt="Produtos services_imagem"
                                                />
                                            )}
                                            <small className='font-weight-bold' style={{ padding: '0px' }}>
                                                <strong>
                                                    {new Intl.NumberFormat("pt-BR", {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }).format(item.value)}
                                                    &nbsp;
                                                </strong>
                                            </small>
                                        </div>
                                        <div className='col-md-3 text-center' style={{ padding: '0px', margin: '0px' }}>
                                            {item.products_name && (
                                                <TitleL2 style={{
                                                    padding: '0px',
                                                    fontWeight: 600,
                                                    overflow: 'hidden',
                                                    marginBottom: '0px',
                                                    textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                                                }}>{item.products_name}
                                                </TitleL2>
                                            )}
                                            {item.services_name && (
                                                <TitleL2 style={{
                                                    padding: '0px',
                                                    fontWeight: 600,
                                                    overflow: 'hidden',
                                                    marginBottom: '0px',
                                                    textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                                                }}>{item.services_name}
                                                </TitleL2>
                                            )}
                                            <p className="card-text" style={{
                                                overflow: 'hidden',
                                                width: '100%',
                                                textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                                            }}>{item.description}</p>
                                        </div>
                                        <div className='col-md-2 mt-1 text-center'>
                                            {new Intl.NumberFormat("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            }).format(item.value * item.quantity)}
                                            &nbsp;<br /> <small>Valor acumulado </small>
                                        </div>
                                        <div className='col-md-2'>
                                            <InputPadrao
                                                title={'Quantidade'}
                                                className="col-md-4 mt-1 text-dark text-center"
                                                type="number"
                                                min="0"
                                                value={item.quantity}
                                                onChange={(event) =>
                                                    handlequantityChange(index, event)
                                                }
                                            />
                                        </div>
                                        {item.options && (
                                            <div className="col-md-2">
                                                <InputPadrao
                                                    title={'Opções'}
                                                    className="col-md-4 mt-1 text-dark text-center"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-1 d-flex text-center justify-content-center align-items-center"><br />
                                            <button
                                                className="text-danger d-flex"
                                                onClick={() => handleDeleteItem(index)}
                                                style={{ border: '0px', backgroundColor: 'none', color: 'none' }}
                                            >
                                                <i className='fa fa-trash'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <Divider style={{ padding: '0px', margin: '0px' }} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="d-flex justify-content-center text-center mx-auto mb-3 mt-2">
                        {pageButtons}
                    </div>
                </div>
            </>
            )}
        </section>
    </>);
};
export default ItemsNoCarrinho;