import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Table, Button, Input, Space, message } from 'antd';
import axios from 'axios';
import { NavBreadcrumb, NavUserMain } from '../../../../../../components';
import './armazenados.css';
import { baseURL } from '../../../../../../services/api';
import { AuthContext } from '../../../../../../contexts/auth';
import jsPDF from 'jspdf';
export const ViewAtestadosArmazenados = () => {
  const { getUserId } = useContext(AuthContext);
  const id = getUserId();
  const [atestados, setAtestados] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const buscarAtestadosAPI = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const response = await axios.get(`${baseURL}get_atestados_user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setAtestados(data.atestados);
    } catch (error) {
      console.error('Error fetching atestados:', error);
    }
  },[id])

  useEffect(() => {
    buscarAtestadosAPI();
  }, [buscarAtestadosAPI]);
  const imprimirAtestado = async (id) => {
    try {
      // Busca os detalhes específicos do atestado pelo ID
      const atestado = atestados.find((item) => item.id === id);
      if (!atestado) {
        console.error(`Atestado com ID ${id} não encontrado.`);
        return;
      }
      const pdf = new jsPDF();
      const borderMargin = 10;
      pdf.rect(borderMargin, borderMargin, pdf.internal.pageSize.width - 2 * borderMargin, pdf.internal.pageSize.height - 2 * borderMargin);
      const body = 75;
      let verticalSpacing = 10;
      pdf.setFont(undefined, 'bold')
      pdf.setFontSize(15)
      pdf.text('ATESTADO', 100, body + verticalSpacing, { align: 'center' });
      verticalSpacing += 15;
      pdf.setFont(undefined, '')
      pdf.setFontSize(12)
      pdf.text(`Profissional: ${atestado.professional_name}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`Paciente: ${atestado.name}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`ID: ${atestado.id}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`Descrição: ${atestado.descricao}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`CID: ${atestado.titulo}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`Data de Emissão: ${atestado.data_emissao}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`Data de Início: ${atestado.data_inicio}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`Data de Fim: ${atestado.data_fim}`, 15, body + verticalSpacing);
      verticalSpacing += 10;
      pdf.text(`Estado: ${atestado.estado}`, 15, body + verticalSpacing);
      alert(JSON.stringify(atestados))
      // Salva o PDF
      pdf.save(`atestado_${atestado.id}.pdf`);
    } catch (error) {
      console.error('Erro ao imprimir atestado:', error);
    }
  };
  const handleCompartilharAtestado = (id) => {
    try {
      const atestado = atestados.find((item) => item.id === id);
      if (!atestado) {
        console.error(`Atestado com ID ${id} não encontrado.`);
        return;
      }
      if (navigator.share) {
        navigator.share({
          title: 'Compartilhar Atestado',
          text: `Atestado emitido em ${atestado.data_emissao}`,
          url: `${baseURL}pacientes/atestados${id}`, // Substitua pelo URL real do seu site
        })
          .then(() => {
            message.success(`Atestado ${id} compartilhado com sucesso.`);
          })
          .catch((error) => {
            console.error('Erro ao compartilhar atestado:', error);
          });
      } else {
        // Caso o navegador não suporte navigator.share
        const shareUrl = `${baseURL}pacientes/atestados${id}`; // Substitua pelo URL real do seu site
        const messageText = `Atestado emitido em ${atestado.data_emissao}. Compartilhe o link: ${shareUrl}`;
        const fallbackPrompt = 'Pressione Ctrl+C para copiar o link';

        // Lógica de fallback para copiar o link para a área de transferência
        const textArea = document.createElement('textarea');
        textArea.value = `${messageText}\n${fallbackPrompt}`;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        message.success(`Atestado ${id} compartilhado. Link copiado para a área de transferência.`);
      }
    } catch (error) {
      console.error('Erro ao compartilhar atestado:', error);
    }
  };
  const getColumnSearchProps = (dataIndex, columnName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Filtrar ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<i className="fas fa-search"></i>}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset()} size="small" style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: <i className="fas fa-search" />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleReset = () => {
    setFilteredInfo({});
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id', 'ID'),
    },
    {
      title: 'Data de Emissão',
      dataIndex: 'data_emissao',
      key: 'data_emissao',
      ...getColumnSearchProps('data_emissao', 'Data de Emissão'),
    },
    {
      title: 'Data de Início',
      dataIndex: 'data_inicio',
      key: 'data_inicio',
      ...getColumnSearchProps('data_inicio', 'Data de Início'),
    },
    {
      title: 'Data de Fim',
      dataIndex: 'data_fim',
      key: 'data_fim',
      ...getColumnSearchProps('data_Fim', 'Data de Fim'),
    },
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      key: 'descricao',
      ...getColumnSearchProps('descricao', 'Descricao'),
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      ...getColumnSearchProps('estado', 'Estado'),
    },
    {
      title: 'Compartilhar',
      key: 'compartilhar',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleCompartilharAtestado(record.id)}>
          Compartilhar
        </Button>
      ),
    },
    {
      title: 'Imprimir',
      key: 'imprimir',
      render: (_, record) => (
        <Button type="primary" onClick={() => { console.log(record.id); imprimirAtestado(record.id); }}>
          Imprimir
        </Button>

      ),
    },
  ];
  return (<>
    <div className="d-flex">
      <NavUserMain />
      <div className="w-100">
        <NavBreadcrumb text={' Atestados'} icon={'user fa'} />
        <div className="Atestado-Paciente-Editar">
          <div className="Atestado-titulo-Editar">Armazenados</div>
        </div>{filteredInfo}
        <div className="Atestado-Data-table-container-Editar">
          <Table
            columns={columns}
            dataSource={atestados}
            onChange={(pagination, filters) => setFilteredInfo(filters)}
          />
        </div>
      </div>
    </div>
  </>);
};
export default ViewAtestadosArmazenados;